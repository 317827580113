body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local('Roboto Thin '), local('Roboto-Thin'),
    url(./assets/roboto-latin-100.7370c367.woff2) format('woff2'),
    url(./assets/roboto-latin-100.5cb7edfc.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local('Roboto Thin italic'), local('Roboto-Thinitalic'),
    url(./assets/roboto-latin-100italic.f8b1df51.woff2) format('woff2'),
    url(./assets/roboto-latin-100italic.f9e8e590.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Roboto Light '), local('Roboto-Light'),
    url(./assets/roboto-latin-300.ef7c6637.woff2) format('woff2'),
    url(./assets/roboto-latin-300.b00849e0.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local('Roboto Light italic'), local('Roboto-Lightitalic'),
    url(./assets/roboto-latin-300italic.14286f3b.woff2) format('woff2'),
    url(./assets/roboto-latin-300italic.4df32891.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto Regular '), local('Roboto-Regular'),
    url(./assets/roboto-latin-400.479970ff.woff2) format('woff2'),
    url(./assets/roboto-latin-400.60fa3c06.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto Regular italic'), local('Roboto-Regularitalic'),
    url(./assets/roboto-latin-400italic.51521a2a.woff2) format('woff2'),
    url(./assets/roboto-latin-400italic.fe65b833.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Roboto Medium '), local('Roboto-Medium'),
    url(./assets/roboto-latin-500.020c97dc.woff2) format('woff2'),
    url(./assets/roboto-latin-500.87284894.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('Roboto Medium italic'), local('Roboto-Mediumitalic'),
    url(./assets/roboto-latin-500italic.db4a2a23.woff2) format('woff2'),
    url(./assets/roboto-latin-500italic.288ad9c6.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto Bold '), local('Roboto-Bold'),
    url(./assets/roboto-latin-700.2735a3a6.woff2) format('woff2'),
    url(./assets/roboto-latin-700.adcde98f.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto Bold italic'), local('Roboto-Bolditalic'),
    url(./assets/roboto-latin-700italic.da0e7178.woff2) format('woff2'),
    url(./assets/roboto-latin-700italic.81f57861.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('Roboto Black '), local('Roboto-Black'),
    url(./assets/roboto-latin-900.9b3766ef.woff2) format('woff2'),
    url(./assets/roboto-latin-900.bb1e4dc6.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local('Roboto Black italic'), local('Roboto-Blackitalic'),
    url(./assets/roboto-latin-900italic.ebf6d164.woff2) format('woff2'),
    url(./assets/roboto-latin-900italic.28f91510.woff) format('woff');
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;










  --body-background: #121729;
    --background: #1E2741;
    --background50: rgba(18, 23, 41, 0.7);
    --input-background: #2F3A5C;
    --input-text: #FFFFFF;
    --body-text: #A4A7C1;
    --header-text: #FFFFFF;
    --primary: #212EEE;
    --primary-hover: #4551FF;
    --secondary: #626BFF;
    /* --secondary: #fc0; */
    --secondary-hover: #8A91FF;
    --secondary-dark: #2F3A5C;
    --tertiary: #FFFFFF;
    --tertiary-hover: #FFFFFF;
    --success: #1BE56D;
    --error: #FF430E;
    --button-text: #FFFFFF;
    --outlined-button-text: #FFFFFF;
    --custom-loader: #FFFFFF;
    --background-blue: linear-gradient(0deg, #212EEE 0%, #626BFF 100%);
    --background-silver: linear-gradient(0deg,#484848 0%,#ACACAC 100%);
    --background-gold: linear-gradient(0deg,#FFC425 0%,#FFDF8A 100%);
    --background-platinum: linear-gradient(0deg,#959595 0%,#F8F8F8 100%);
    --background-vip: linear-gradient(0deg, #5725ED 0%,#C521EE 100%);
    --rewards-dark-blue: #212EEE;
    --rewards-light-blue: #53f5f5;
    --skeleton-loader-from: #2F3A5C;
    --skeleton-loader-to: rgba(47,58,92,0.25);
    --tx-history-background: #525D7C;
    --background-btc: linear-gradient(180deg, #F7931A 0%, #F74F1A 100%);
/* } */
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}
hr {
  height: 0;
  color: inherit;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo,
    monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
button {
  background-color: transparent;
  background-image: none;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
fieldset,
ol,
ul {
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
}
body {
  font-family: inherit;
  line-height: inherit;
}
*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}
hr {
  border-top-width: 1px;
}
img {
  border-style: solid;
}
textarea {
  resize: vertical;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9ca3af;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  color: #9ca3af;
}
[role='button'],
button {
  cursor: pointer;
}
table {
  border-collapse: collapse;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
[multiple]:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='email']:focus,
[type='month']:focus,
[type='number']:focus,
[type='password']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='text']:focus,
[type='time']:focus,
[type='url']:focus,
[type='week']:focus,
select:focus,
textarea:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  border-color: #2563eb;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
[multiple] {
  background-image: none;
  background-position: 0 0;
  background-repeat: unset;
  background-size: auto auto;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
}
[type='checkbox'],
[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}
[type='checkbox'] {
  border-radius: 0;
}
[type='radio'] {
  border-radius: 100%;
}
[type='checkbox']:focus,
[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
[type='checkbox']:checked,
[type='radio']:checked {
  border-color: transparent;
  background-color: #fc0;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
}
[type='radio']:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}
[type='checkbox']:checked:focus,
[type='checkbox']:checked:hover,
[type='radio']:checked:focus,
[type='radio']:checked:hover {
  border-color: transparent;
  background-color: #fc0;
}
[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E");
  border-color: transparent;
  background-color: #fc0;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
[type='checkbox']:indeterminate:focus,
[type='checkbox']:indeterminate:hover {
  border-color: transparent;
  background-color: #fc0;
}
[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type='file']:focus {
  outline: 1px auto -webkit-focus-ring-color;
}
.container {
  width: 100%;
}
@media (min-width: 260px) {
  .container {
    max-width: 260px;
  }
}
@media (min-width: 360px) {
  .container {
    max-width: 360px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.prose {
  color: #374151;
  max-width: 65ch;
}
.prose [class~='lead'] {
  color: #4b5563;
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose a {
  color: #6d728b;
  text-decoration: underline;
  font-weight: 500;
}
.prose strong {
  color: #6d728b;
  font-weight: 600;
}
.prose ol {
  counter-reset: list-counter;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose ol > li {
  position: relative;
  counter-increment: list-counter;
  padding-left: 1.75em;
}
.prose ol > li:before {
  content: counter(list-counter) '.';
  position: absolute;
  font-weight: 400;
  color: #6b7280;
  left: 0;
}
.prose ul > li {
  position: relative;
  padding-left: 1.75em;
}
.prose ul > li:before {
  content: '';
  position: absolute;
  background-color: rgba(98, 107, 255, 0.25098039215686274);
  border-radius: 50%;
  width: 0.375em;
  height: 0.375em;
  top: 0.6875em;
  left: 0.25em;
}
.prose hr {
  border-color: #e5e7eb;
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}
.prose blockquote {
  font-weight: 500;
  font-style: italic;
  color: #6d728b;
  border-left-width: 0.25rem;
  border-left-color: #e5e7eb;
  quotes: '\201C''\201D''\2018''\2019';
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}
.prose blockquote p:first-of-type:before {
  content: open-quote;
}
.prose blockquote p:last-of-type:after {
  content: close-quote;
}
.prose h1 {
  color: #6d728b;
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.prose h2 {
  color: #6d728b;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.prose h3 {
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.prose h3,
.prose h4 {
  color: #6d728b;
  font-weight: 600;
}
.prose h4 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.prose figure figcaption {
  color: #6b7280;
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.prose code {
  color: #6d728b;
  font-weight: 600;
  font-size: 0.875em;
}
.prose code:after,
.prose code:before {
  content: '`';
}
.prose a code {
  color: #6d728b;
}
.prose pre {
  color: #e5e7eb;
  background-color: #1f2937;
  overflow-x: auto;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding: 0.8571429em 1.1428571em;
}
.prose pre code {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 400;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.prose pre code:after,
.prose pre code:before {
  content: '';
}
.prose table {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.prose thead {
  color: #6d728b;
  font-weight: 600;
  border-bottom-width: 1px;
  border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
}
.prose thead th {
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.prose tbody tr {
  border-bottom-width: 1px;
  border-bottom-color: #e5e7eb;
}
.prose tbody tr:last-child {
  border-bottom-width: 0;
}
.prose tbody td {
  vertical-align: top;
  padding: 0.5714286em;
}
.prose {
  font-size: 1rem;
  line-height: 1.75;
}
.prose p {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose figure,
.prose img,
.prose video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose h2 code {
  font-size: 0.875em;
}
.prose h3 code {
  font-size: 0.9em;
}
.prose ul {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose > ul > li p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose > ul > li > :first-child {
  margin-top: 1.25em;
}
.prose > ul > li > :last-child {
  margin-bottom: 1.25em;
}
.prose > ol > li > :first-child {
  margin-top: 1.25em;
}
.prose > ol > li > :last-child {
  margin-bottom: 1.25em;
}
.prose ol ol,
.prose ol ul,
.prose ul ol,
.prose ul ul {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose h2 + *,
.prose h3 + *,
.prose h4 + *,
.prose hr + * {
  margin-top: 0;
}
.prose thead th:first-child {
  padding-left: 0;
}
.prose thead th:last-child {
  padding-right: 0;
}
.prose tbody td:first-child {
  padding-left: 0;
}
.prose tbody td:last-child {
  padding-right: 0;
}
.prose > :first-child {
  margin-top: 0;
}
.prose > :last-child {
  margin-bottom: 0;
}
.prose-sm {
  font-size: 0.875rem;
  line-height: 1.7142857;
}
.prose-sm p {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm [class~='lead'] {
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.prose-sm blockquote {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.1111111em;
}
.prose-sm h1 {
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}
.prose-sm h2 {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}
.prose-sm h3 {
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.prose-sm h4 {
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}
.prose-sm figure,
.prose-sm img,
.prose-sm video {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose-sm figure figcaption {
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}
.prose-sm code {
  font-size: 0.8571429em;
}
.prose-sm h2 code {
  font-size: 0.9em;
}
.prose-sm h3 code {
  font-size: 0.8888889em;
}
.prose-sm pre {
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding: 0.6666667em 1em;
}
.prose-sm ol,
.prose-sm ul {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm li {
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}
.prose-sm ol > li {
  padding-left: 1.5714286em;
}
.prose-sm ol > li:before {
  left: 0;
}
.prose-sm ul > li {
  padding-left: 1.5714286em;
}
.prose-sm ul > li:before {
  height: 0.3571429em;
  width: 0.3571429em;
  top: 0.67857em;
  left: 0.2142857em;
}
.prose-sm > ul > li p {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm > ul > li > :first-child {
  margin-top: 1.1428571em;
}
.prose-sm > ul > li > :last-child {
  margin-bottom: 1.1428571em;
}
.prose-sm > ol > li > :first-child {
  margin-top: 1.1428571em;
}
.prose-sm > ol > li > :last-child {
  margin-bottom: 1.1428571em;
}
.prose-sm ol ol,
.prose-sm ol ul,
.prose-sm ul ol,
.prose-sm ul ul {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm hr {
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}
.prose-sm h2 + *,
.prose-sm h3 + *,
.prose-sm h4 + *,
.prose-sm hr + * {
  margin-top: 0;
}
.prose-sm table {
  font-size: 0.8571429em;
  line-height: 1.5;
}
.prose-sm thead th {
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.prose-sm thead th:first-child {
  padding-left: 0;
}
.prose-sm thead th:last-child {
  padding-right: 0;
}
.prose-sm tbody td {
  padding: 0.6666667em 1em;
}
.prose-sm tbody td:first-child {
  padding-left: 0;
}
.prose-sm tbody td:last-child {
  padding-right: 0;
}
.prose-sm > :first-child {
  margin-top: 0;
}
.prose-sm > :last-child {
  margin-bottom: 0;
}
.prose-lg {
  font-size: 1.125rem;
  line-height: 1.7777778;
}
.prose-lg p {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.prose-lg [class~='lead'] {
  font-size: 1.2222222em;
  line-height: 1.4545455;
  margin-top: 1.0909091em;
  margin-bottom: 1.0909091em;
}
.prose-lg blockquote {
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  padding-left: 1em;
}
.prose-lg h1 {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.8333333em;
  line-height: 1;
}
.prose-lg h2 {
  font-size: 1.6666667em;
  margin-top: 1.8666667em;
  margin-bottom: 1.0666667em;
  line-height: 1.3333333;
}
.prose-lg h3 {
  font-size: 1.3333333em;
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}
.prose-lg h4 {
  margin-top: 1.7777778em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.prose-lg figure,
.prose-lg img,
.prose-lg video {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}
.prose-lg figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose-lg figure figcaption {
  font-size: 0.8888889em;
  line-height: 1.5;
  margin-top: 1em;
}
.prose-lg code {
  font-size: 0.8888889em;
}
.prose-lg h2 code {
  font-size: 0.8666667em;
}
.prose-lg h3 code {
  font-size: 0.875em;
}
.prose-lg pre {
  font-size: 0.8888889em;
  line-height: 1.75;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.375rem;
  padding: 1em 1.5em;
}
.prose-lg ol,
.prose-lg ul {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.prose-lg li {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}
.prose-lg ol > li {
  padding-left: 1.6666667em;
}
.prose-lg ol > li:before {
  left: 0;
}
.prose-lg ul > li {
  padding-left: 1.6666667em;
}
.prose-lg ul > li:before {
  width: 0.3333333em;
  height: 0.3333333em;
  top: 0.72222em;
  left: 0.2222222em;
}
.prose-lg > ul > li p {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.prose-lg > ul > li > :first-child {
  margin-top: 1.3333333em;
}
.prose-lg > ul > li > :last-child {
  margin-bottom: 1.3333333em;
}
.prose-lg > ol > li > :first-child {
  margin-top: 1.3333333em;
}
.prose-lg > ol > li > :last-child {
  margin-bottom: 1.3333333em;
}
.prose-lg ol ol,
.prose-lg ol ul,
.prose-lg ul ol,
.prose-lg ul ul {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.prose-lg hr {
  margin-top: 3.1111111em;
  margin-bottom: 3.1111111em;
}
.prose-lg h2 + *,
.prose-lg h3 + *,
.prose-lg h4 + *,
.prose-lg hr + * {
  margin-top: 0;
}
.prose-lg table {
  font-size: 0.8888889em;
  line-height: 1.5;
}
.prose-lg thead th {
  padding-right: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 0.75em;
}
.prose-lg thead th:first-child {
  padding-left: 0;
}
.prose-lg thead th:last-child {
  padding-right: 0;
}
.prose-lg tbody td {
  padding: 0.75em;
}
.prose-lg tbody td:first-child {
  padding-left: 0;
}
.prose-lg tbody td:last-child {
  padding-right: 0;
}
.prose-lg > :first-child {
  margin-top: 0;
}
.prose-lg > :last-child {
  margin-bottom: 0;
}
.prose-xl {
  font-size: 1.25rem;
  line-height: 1.8;
}
.prose-xl p {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose-xl [class~='lead'] {
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em;
}
.prose-xl blockquote {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1.0666667em;
}
.prose-xl h1 {
  font-size: 2.8em;
  margin-top: 0;
  margin-bottom: 0.8571429em;
  line-height: 1;
}
.prose-xl h2 {
  font-size: 1.8em;
  margin-top: 1.5555556em;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.prose-xl h3 {
  font-size: 1.5em;
  margin-top: 1.6em;
  margin-bottom: 0.6666667em;
  line-height: 1.3333333;
}
.prose-xl h4 {
  margin-top: 1.8em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.prose-xl figure,
.prose-xl img,
.prose-xl video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose-xl figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose-xl figure figcaption {
  font-size: 0.9em;
  line-height: 1.5555556;
  margin-top: 1em;
}
.prose-xl code {
  font-size: 0.9em;
}
.prose-xl h2 code {
  font-size: 0.8611111em;
}
.prose-xl h3 code,
.prose-xl pre {
  font-size: 0.9em;
}
.prose-xl pre {
  line-height: 1.7777778;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.5rem;
  padding: 1.1111111em 1.3333333em;
}
.prose-xl ol,
.prose-xl ul {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose-xl li {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}
.prose-xl ol > li {
  padding-left: 1.8em;
}
.prose-xl ol > li:before {
  left: 0;
}
.prose-xl ul > li {
  padding-left: 1.8em;
}
.prose-xl ul > li:before {
  width: 0.35em;
  height: 0.35em;
  top: 0.725em;
  left: 0.25em;
}
.prose-xl > ul > li p {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.prose-xl > ul > li > :first-child {
  margin-top: 1.2em;
}
.prose-xl > ul > li > :last-child {
  margin-bottom: 1.2em;
}
.prose-xl > ol > li > :first-child {
  margin-top: 1.2em;
}
.prose-xl > ol > li > :last-child {
  margin-bottom: 1.2em;
}
.prose-xl ol ol,
.prose-xl ol ul,
.prose-xl ul ol,
.prose-xl ul ul {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.prose-xl hr {
  margin-top: 2.8em;
  margin-bottom: 2.8em;
}
.prose-xl h2 + *,
.prose-xl h3 + *,
.prose-xl h4 + *,
.prose-xl hr + * {
  margin-top: 0;
}
.prose-xl table {
  font-size: 0.9em;
  line-height: 1.5555556;
}
.prose-xl thead th {
  padding-right: 0.6666667em;
  padding-bottom: 0.8888889em;
  padding-left: 0.6666667em;
}
.prose-xl thead th:first-child {
  padding-left: 0;
}
.prose-xl thead th:last-child {
  padding-right: 0;
}
.prose-xl tbody td {
  padding: 0.8888889em 0.6666667em;
}
.prose-xl tbody td:first-child {
  padding-left: 0;
}
.prose-xl tbody td:last-child {
  padding-right: 0;
}
.prose-xl > :first-child {
  margin-top: 0;
}
.prose-xl > :last-child {
  margin-bottom: 0;
}
.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.6666667;
}
.prose-2xl p {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.prose-2xl [class~='lead'] {
  font-size: 1.25em;
  line-height: 1.4666667;
  margin-top: 1.0666667em;
  margin-bottom: 1.0666667em;
}
.prose-2xl blockquote {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
  padding-left: 1.1111111em;
}
.prose-2xl h1 {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.875em;
  line-height: 1;
}
.prose-2xl h2 {
  font-size: 2em;
  margin-top: 1.5em;
  margin-bottom: 0.8333333em;
  line-height: 1.0833333;
}
.prose-2xl h3 {
  font-size: 1.5em;
  margin-top: 1.5555556em;
  margin-bottom: 0.6666667em;
  line-height: 1.2222222;
}
.prose-2xl h4 {
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}
.prose-2xl figure,
.prose-2xl img,
.prose-2xl video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose-2xl figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose-2xl figure figcaption {
  font-size: 0.8333333em;
  line-height: 1.6;
  margin-top: 1em;
}
.prose-2xl code {
  font-size: 0.8333333em;
}
.prose-2xl h2 code {
  font-size: 0.875em;
}
.prose-2xl h3 code {
  font-size: 0.8888889em;
}
.prose-2xl pre {
  font-size: 0.8333333em;
  line-height: 1.8;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.5rem;
  padding: 1.2em 1.6em;
}
.prose-2xl ol,
.prose-2xl ul {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.prose-2xl li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose-2xl ol > li {
  padding-left: 1.6666667em;
}
.prose-2xl ol > li:before {
  left: 0;
}
.prose-2xl ul > li {
  padding-left: 1.6666667em;
}
.prose-2xl ul > li:before {
  width: 0.3333333em;
  height: 0.3333333em;
  top: 0.66667em;
  left: 0.25em;
}
.prose-2xl > ul > li p {
  margin-top: 0.8333333em;
  margin-bottom: 0.8333333em;
}
.prose-2xl > ul > li > :first-child {
  margin-top: 1.3333333em;
}
.prose-2xl > ul > li > :last-child {
  margin-bottom: 1.3333333em;
}
.prose-2xl > ol > li > :first-child {
  margin-top: 1.3333333em;
}
.prose-2xl > ol > li > :last-child {
  margin-bottom: 1.3333333em;
}
.prose-2xl ol ol,
.prose-2xl ol ul,
.prose-2xl ul ol,
.prose-2xl ul ul {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}
.prose-2xl hr {
  margin-top: 3em;
  margin-bottom: 3em;
}
.prose-2xl h2 + *,
.prose-2xl h3 + *,
.prose-2xl h4 + *,
.prose-2xl hr + * {
  margin-top: 0;
}
.prose-2xl table {
  font-size: 0.8333333em;
  line-height: 1.4;
}
.prose-2xl thead th {
  padding-right: 0.6em;
  padding-bottom: 0.8em;
  padding-left: 0.6em;
}
.prose-2xl thead th:first-child {
  padding-left: 0;
}
.prose-2xl thead th:last-child {
  padding-right: 0;
}
.prose-2xl tbody td {
  padding: 0.8em 0.6em;
}
.prose-2xl tbody td:first-child {
  padding-left: 0;
}
.prose-2xl tbody td:last-child {
  padding-right: 0;
}
.prose-2xl > :first-child {
  margin-top: 0;
}
.prose-2xl > :last-child {
  margin-bottom: 0;
}
.aspect-w-1,
.aspect-w-2,
.aspect-w-3,
.aspect-w-4,
.aspect-w-5,
.aspect-w-6,
.aspect-w-7,
.aspect-w-8,
.aspect-w-9,
.aspect-w-10,
.aspect-w-11,
.aspect-w-12,
.aspect-w-13,
.aspect-w-14,
.aspect-w-15,
.aspect-w-16 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}
.aspect-w-1 > *,
.aspect-w-2 > *,
.aspect-w-3 > *,
.aspect-w-4 > *,
.aspect-w-5 > *,
.aspect-w-6 > *,
.aspect-w-7 > *,
.aspect-w-8 > *,
.aspect-w-9 > *,
.aspect-w-10 > *,
.aspect-w-11 > *,
.aspect-w-12 > *,
.aspect-w-13 > *,
.aspect-w-14 > *,
.aspect-w-15 > *,
.aspect-w-16 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.aspect-none {
  position: static;
  padding-bottom: 0;
}
.aspect-none > * {
  position: static;
  height: auto;
  width: auto;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
.aspect-w-1 {
  --tw-aspect-w: 1;
}
.aspect-w-2 {
  --tw-aspect-w: 2;
}
.aspect-w-3 {
  --tw-aspect-w: 3;
}
.aspect-w-4 {
  --tw-aspect-w: 4;
}
.aspect-w-5 {
  --tw-aspect-w: 5;
}
.aspect-w-6 {
  --tw-aspect-w: 6;
}
.aspect-w-7 {
  --tw-aspect-w: 7;
}
.aspect-w-8 {
  --tw-aspect-w: 8;
}
.aspect-w-9 {
  --tw-aspect-w: 9;
}
.aspect-w-10 {
  --tw-aspect-w: 10;
}
.aspect-w-11 {
  --tw-aspect-w: 11;
}
.aspect-w-12 {
  --tw-aspect-w: 12;
}
.aspect-w-13 {
  --tw-aspect-w: 13;
}
.aspect-w-14 {
  --tw-aspect-w: 14;
}
.aspect-w-15 {
  --tw-aspect-w: 15;
}
.aspect-w-16 {
  --tw-aspect-w: 16;
}
.aspect-h-1 {
  --tw-aspect-h: 1;
}
.aspect-h-2 {
  --tw-aspect-h: 2;
}
.aspect-h-3 {
  --tw-aspect-h: 3;
}
.aspect-h-4 {
  --tw-aspect-h: 4;
}
.aspect-h-5 {
  --tw-aspect-h: 5;
}
.aspect-h-6 {
  --tw-aspect-h: 6;
}
.aspect-h-7 {
  --tw-aspect-h: 7;
}
.aspect-h-8 {
  --tw-aspect-h: 8;
}
.aspect-h-9 {
  --tw-aspect-h: 9;
}
.aspect-h-10 {
  --tw-aspect-h: 10;
}
.aspect-h-11 {
  --tw-aspect-h: 11;
}
.aspect-h-12 {
  --tw-aspect-h: 12;
}
.aspect-h-13 {
  --tw-aspect-h: 13;
}
.aspect-h-14 {
  --tw-aspect-h: 14;
}
.aspect-h-15 {
  --tw-aspect-h: 15;
}
.aspect-h-16 {
  --tw-aspect-h: 16;
}
body,
html {
  font-size: 13px;
  color: var(--body-text);
  font-family: Bozon, sans-serif;
  font-weight: 400;
  padding: 0 !important;
  font-family: 'Bozon', sans-serif;
}
#root,
body,
html {
  height: 100vh;
}
.numbers {
  font-family: Roboto, sans-serif;
}
h1 {
  font-size: 2.1333333333333333em;
}
h1,
h2 {
  color: var(--header-text);
}
h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.4666666666666666em;
}
h3,
h4 {
  color: var(--header-text);
}
h4 {
  font-size: 1.3333333333333333em;
}
h5 {
  font-size: 1.2em;
}
h5,
h6 {
  color: var(--header-text);
}
h6 {
  font-size: 1.0666666666666667em;
}
.caption {
  font-size: 0.8em;
}
@media (min-width: 640px) {
  body,
  html {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  body,
  html {
    font-size: 16px;
  }
  h1 {
    font-size: 2.625em;
  }
  h2 {
    font-size: 2.25em;
  }
  h3 {
    font-size: 1.75em;
  }
  h4 {
    font-size: 1.625em;
  }
  h5 {
    font-size: 1.375em;
  }
  h6 {
    font-size: 1.125em;
  }
  .caption {
    font-size: 0.875em;
  }
}
.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}
.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}
.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}
.space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}
.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}
.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}
.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}
.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}
.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}
.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}
.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}
.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}
.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}
.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}
.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}
.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}
.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}
.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}
.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}
.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}
.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
}
.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}
.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}
.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
}
.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}
.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}
.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}
.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}
.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}
.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}
.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}
.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}
.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}
.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}
.-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}
.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}
.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}
.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}
.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}
.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}
.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}
.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}
.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}
.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}
.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}
.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}
.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}
.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}
.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}
.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}
.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}
.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}
.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}
.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
}
.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
}
.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
}
.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
}
.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
}
.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
}
.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
}
.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}
.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}
.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}
.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
}
.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}
.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
}
.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}
.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
}
.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}
.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
}
.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}
.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}
.divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent;
}
.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}
.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
}
.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}
.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
}
.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}
.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
}
.divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
}
.divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
}
.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
}
.divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
}
.divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
}
.divide-red-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
}
.divide-red-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
}
.divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
}
.divide-red-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
}
.divide-red-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
}
.divide-red-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
}
.divide-red-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
}
.divide-red-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
}
.divide-red-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
}
.divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
}
.divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
}
.divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
}
.divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
}
.divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
}
.divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
}
.divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
}
.divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
}
.divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
}
.divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
}
.divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
}
.divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
}
.divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
}
.divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
}
.divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
}
.divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
}
.divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
}
.divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
}
.divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
}
.divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
}
.divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
}
.divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
}
.divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
}
.divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
}
.divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
}
.divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
}
.divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
}
.divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
}
.divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
}
.divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
}
.divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
}
.divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
}
.divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
}
.divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
}
.divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
}
.divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
}
.divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
}
.divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
}
.divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
}
.divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
}
.divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
}
.divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
}
.divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
}
.divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
}
.divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
}
.divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
}
.divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
}
.divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
}
.divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
}
.divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
}
.divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
}
.divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
}
.divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
}
.divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
}
.divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
}
.divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
}
.divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
}
.divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
}
.divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
}
.divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
}
.divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
}
.divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgba(144, 150, 246, 0.5);
}
.divide-background-primary > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--background-primary);
}
.divide-main-background > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
}
.divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
}
.divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
}
.divide-text-grey > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
}
.divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
}
.divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
}
.divide-btc > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
}
.divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
}
.divide-paxg > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
}
.divide-usdc > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
}
.divide-black-navy > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
}
.divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
}
.divide-spring-green > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
}
.divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
}
.divide-body-background > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--body-background);
}
.divide-background > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--background);
}
.divide-input-background > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--input-background);
}
.divide-body-text > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--body-text);
}
.divide-header-text > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--header-text);
}
.divide-primary > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--primary);
}
.divide-secondary > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--secondary);
}
.divide-tertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--tertiary);
}
.divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--primary-hover);
}
.divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--secondary-hover);
}
.divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--tertiary-hover);
}
.divide-input-text > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--input-text);
}
.divide-success > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--success);
}
.divide-error > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--error);
}
.divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--custom-loader);
}
.divide-violet > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
}
.divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
}
.divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--skeleton-loader-from);
}
.divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--skeleton-loader-to);
}
.divide-background50 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--background50);
}
.divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
}
.divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
}
.divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
}
.divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
}
.divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
}
.divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
}
.divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
}
.divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
}
.divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
}
.divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
}
.divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
}
.divide-light-grey > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}
.divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
}
.divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
}
.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}
.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}
.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}
.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}
.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}
.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}
.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.05;
}
.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1;
}
.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2;
}
.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25;
}
.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.3;
}
.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.4;
}
.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5;
}
.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.6;
}
.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.7;
}
.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75;
}
.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.8;
}
.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.9;
}
.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.95;
}
.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.focus-within\:sr-only:focus-within {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.focus-within\:not-sr-only:focus-within {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-local {
  background-attachment: local;
}
.bg-scroll {
  background-attachment: scroll;
}
.bg-clip-border {
  background-clip: border-box;
}
.bg-clip-padding {
  background-clip: padding-box;
}
.bg-clip-content {
  background-clip: content-box;
}
.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}
.bg-transparent {
  background-color: transparent;
}
.bg-current {
  background-color: currentColor;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-300 {
  background-color: rgba(98, 107, 255, 0.25098039215686274);
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
}
.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.bg-vivid-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.bg-vivid-blue-500 {
  background-color: rgba(144, 150, 246, 0.5);
}
.bg-background-primary {
  background-color: var(--background-primary);
}
.bg-main-background {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
}
.bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
}
.bg-blue-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
}
.bg-text-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
}
.bg-pale-grey-two {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
}
.bg-pale-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
}
.bg-btc {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
}
.bg-btc-symbol {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
}
.bg-paxg {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
}
.bg-usdc {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
}
.bg-black-navy {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
}
.bg-ghost-white {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}
.bg-spring-green {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
}
.bg-light-slate-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
}
.bg-body-background {
  background-color: var(--body-background);
}
.bg-background {
  background-color: var(--background);
}
.bg-input-background {
  background-color: var(--input-background);
}
.bg-body-text {
  background-color: var(--body-text);
}
.bg-header-text {
  background-color: var(--header-text);
}
.bg-primary {
  background-color: var(--primary);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-tertiary {
  background-color: var(--tertiary);
}
.bg-primary-hover {
  background-color: var(--primary-hover);
}
.bg-secondary-hover {
  background-color: var(--secondary-hover);
}
.bg-tertiary-hover {
  background-color: var(--tertiary-hover);
}
.bg-input-text {
  background-color: var(--input-text);
}
.bg-success {
  background-color: var(--success);
}
.bg-error {
  background-color: var(--error);
}
.bg-custom-loader {
  background-color: var(--custom-loader);
}
.bg-violet {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
}
.bg-violet-hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
}
.bg-skeleton-loader-from {
  background-color: var(--skeleton-loader-from);
}
.bg-skeleton-loader-to {
  background-color: var(--skeleton-loader-to);
}
.bg-background50 {
  background-color: var(--background50);
}
.bg-reward-blue-start {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
}
.bg-reward-blue-end {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.bg-reward-silver-start {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
}
.bg-reward-silver-end {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
}
.bg-reward-gold-start {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
}
.bg-reward-gold-end {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
}
.bg-reward-platinum-start {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
}
.bg-reward-platinum-end {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}
.bg-reward-vip-start {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.bg-reward-vip-end {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
}
.bg-fee-breakdown-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
}
.bg-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-disabled-button-background {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
}
.bg-disabled-button-text {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-transparent {
  background-color: transparent;
}
.group:hover .group-hover\:bg-current {
  background-color: currentColor;
}
.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-300 {
  background-color: rgba(98, 107, 255, 0.25098039215686274);
}
.group:hover .group-hover\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-vivid-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-vivid-blue-500 {
  background-color: rgba(144, 150, 246, 0.5);
}
.group:hover .group-hover\:bg-background-primary {
  background-color: var(--background-primary);
}
.group:hover .group-hover\:bg-main-background {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-text-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pale-grey-two {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pale-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-btc {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-btc-symbol {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-paxg {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-usdc {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-black-navy {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-ghost-white {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-spring-green {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-light-slate-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-body-background {
  background-color: var(--body-background);
}
.group:hover .group-hover\:bg-background {
  background-color: var(--background);
}
.group:hover .group-hover\:bg-input-background {
  background-color: var(--input-background);
}
.group:hover .group-hover\:bg-body-text {
  background-color: var(--body-text);
}
.group:hover .group-hover\:bg-header-text {
  background-color: var(--header-text);
}
.group:hover .group-hover\:bg-primary {
  background-color: var(--primary);
}
.group:hover .group-hover\:bg-secondary {
  background-color: var(--secondary);
}
.group:hover .group-hover\:bg-tertiary {
  background-color: var(--tertiary);
}
.group:hover .group-hover\:bg-primary-hover {
  background-color: var(--primary-hover);
}
.group:hover .group-hover\:bg-secondary-hover {
  background-color: var(--secondary-hover);
}
.group:hover .group-hover\:bg-tertiary-hover {
  background-color: var(--tertiary-hover);
}
.group:hover .group-hover\:bg-input-text {
  background-color: var(--input-text);
}
.group:hover .group-hover\:bg-success {
  background-color: var(--success);
}
.group:hover .group-hover\:bg-error {
  background-color: var(--error);
}
.group:hover .group-hover\:bg-custom-loader {
  background-color: var(--custom-loader);
}
.group:hover .group-hover\:bg-violet {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-violet-hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-skeleton-loader-from {
  background-color: var(--skeleton-loader-from);
}
.group:hover .group-hover\:bg-skeleton-loader-to {
  background-color: var(--skeleton-loader-to);
}
.group:hover .group-hover\:bg-background50 {
  background-color: var(--background50);
}
.group:hover .group-hover\:bg-reward-blue-start {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-blue-end {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-silver-start {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-silver-end {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-gold-start {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-gold-end {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-platinum-start {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-platinum-end {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-vip-start {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-reward-vip-end {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-fee-breakdown-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-disabled-button-background {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-disabled-button-text {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
}
.focus-within\:bg-transparent:focus-within {
  background-color: transparent;
}
.focus-within\:bg-current:focus-within {
  background-color: currentColor;
}
.focus-within\:bg-black:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.focus-within\:bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-300:focus-within {
  background-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus-within\:bg-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
}
.focus-within\:bg-red-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.focus-within\:bg-red-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.focus-within\:bg-red-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.focus-within\:bg-red-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.focus-within\:bg-red-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.focus-within\:bg-red-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.focus-within\:bg-red-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.focus-within\:bg-red-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.focus-within\:bg-red-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.focus-within\:bg-red-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.focus-within\:bg-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.focus-within\:bg-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.focus-within\:bg-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.focus-within\:bg-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.focus-within\:bg-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.focus-within\:bg-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.focus-within\:bg-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.focus-within\:bg-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.focus-within\:bg-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.focus-within\:bg-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.focus-within\:bg-vivid-blue:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus-within\:bg-vivid-blue-500:focus-within {
  background-color: rgba(144, 150, 246, 0.5);
}
.focus-within\:bg-background-primary:focus-within {
  background-color: var(--background-primary);
}
.focus-within\:bg-main-background:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
}
.focus-within\:bg-dark-blue:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-grey:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
}
.focus-within\:bg-text-grey:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
}
.focus-within\:bg-pale-grey-two:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-pale-grey:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
}
.focus-within\:bg-btc:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
}
.focus-within\:bg-btc-symbol:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
}
.focus-within\:bg-paxg:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
}
.focus-within\:bg-usdc:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
}
.focus-within\:bg-black-navy:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
}
.focus-within\:bg-ghost-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-spring-green:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
}
.focus-within\:bg-light-slate-gray:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
}
.focus-within\:bg-body-background:focus-within {
  background-color: var(--body-background);
}
.focus-within\:bg-background:focus-within {
  background-color: var(--background);
}
.focus-within\:bg-input-background:focus-within {
  background-color: var(--input-background);
}
.focus-within\:bg-body-text:focus-within {
  background-color: var(--body-text);
}
.focus-within\:bg-header-text:focus-within {
  background-color: var(--header-text);
}
.focus-within\:bg-primary:focus-within {
  background-color: var(--primary);
}
.focus-within\:bg-secondary:focus-within {
  background-color: var(--secondary);
}
.focus-within\:bg-tertiary:focus-within {
  background-color: var(--tertiary);
}
.focus-within\:bg-primary-hover:focus-within {
  background-color: var(--primary-hover);
}
.focus-within\:bg-secondary-hover:focus-within {
  background-color: var(--secondary-hover);
}
.focus-within\:bg-tertiary-hover:focus-within {
  background-color: var(--tertiary-hover);
}
.focus-within\:bg-input-text:focus-within {
  background-color: var(--input-text);
}
.focus-within\:bg-success:focus-within {
  background-color: var(--success);
}
.focus-within\:bg-error:focus-within {
  background-color: var(--error);
}
.focus-within\:bg-custom-loader:focus-within {
  background-color: var(--custom-loader);
}
.focus-within\:bg-violet:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
}
.focus-within\:bg-violet-hover:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
}
.focus-within\:bg-skeleton-loader-from:focus-within {
  background-color: var(--skeleton-loader-from);
}
.focus-within\:bg-skeleton-loader-to:focus-within {
  background-color: var(--skeleton-loader-to);
}
.focus-within\:bg-background50:focus-within {
  background-color: var(--background50);
}
.focus-within\:bg-reward-blue-start:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-blue-end:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-silver-start:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-silver-end:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-gold-start:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-gold-end:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-platinum-start:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-platinum-end:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-vip-start:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus-within\:bg-reward-vip-end:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
}
.focus-within\:bg-fee-breakdown-bg:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
}
.focus-within\:bg-light-grey:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-disabled-button-background:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
}
.focus-within\:bg-disabled-button-text:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
}
.hover\:bg-transparent:hover {
  background-color: transparent;
}
.hover\:bg-current:hover {
  background-color: currentColor;
}
.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-gray-300:hover {
  background-color: rgba(98, 107, 255, 0.25098039215686274);
}
.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
}
.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.hover\:bg-vivid-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.hover\:bg-vivid-blue-500:hover {
  background-color: rgba(144, 150, 246, 0.5);
}
.hover\:bg-background-primary:hover {
  background-color: var(--background-primary);
}
.hover\:bg-main-background:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
}
.hover\:bg-dark-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
}
.hover\:bg-blue-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
}
.hover\:bg-text-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
}
.hover\:bg-pale-grey-two:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
}
.hover\:bg-pale-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
}
.hover\:bg-btc:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
}
.hover\:bg-btc-symbol:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
}
.hover\:bg-paxg:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
}
.hover\:bg-usdc:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
}
.hover\:bg-black-navy:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
}
.hover\:bg-ghost-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}
.hover\:bg-spring-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
}
.hover\:bg-light-slate-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
}
.hover\:bg-body-background:hover {
  background-color: var(--body-background);
}
.hover\:bg-background:hover {
  background-color: var(--background);
}
.hover\:bg-input-background:hover {
  background-color: var(--input-background);
}
.hover\:bg-body-text:hover {
  background-color: var(--body-text);
}
.hover\:bg-header-text:hover {
  background-color: var(--header-text);
}
.hover\:bg-primary:hover {
  background-color: var(--primary);
}
.hover\:bg-secondary:hover {
  background-color: var(--secondary);
}
.hover\:bg-tertiary:hover {
  background-color: var(--tertiary);
}
.hover\:bg-primary-hover:hover {
  background-color: var(--primary-hover);
}
.hover\:bg-secondary-hover:hover {
  background-color: var(--secondary-hover);
}
.hover\:bg-tertiary-hover:hover {
  background-color: var(--tertiary-hover);
}
.hover\:bg-input-text:hover {
  background-color: var(--input-text);
}
.hover\:bg-success:hover {
  background-color: var(--success);
}
.hover\:bg-error:hover {
  background-color: var(--error);
}
.hover\:bg-custom-loader:hover {
  background-color: var(--custom-loader);
}
.hover\:bg-violet:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
}
.hover\:bg-violet-hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
}
.hover\:bg-skeleton-loader-from:hover {
  background-color: var(--skeleton-loader-from);
}
.hover\:bg-skeleton-loader-to:hover {
  background-color: var(--skeleton-loader-to);
}
.hover\:bg-background50:hover {
  background-color: var(--background50);
}
.hover\:bg-reward-blue-start:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
}
.hover\:bg-reward-blue-end:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.hover\:bg-reward-silver-start:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
}
.hover\:bg-reward-silver-end:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
}
.hover\:bg-reward-gold-start:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
}
.hover\:bg-reward-gold-end:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
}
.hover\:bg-reward-platinum-start:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
}
.hover\:bg-reward-platinum-end:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}
.hover\:bg-reward-vip-start:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.hover\:bg-reward-vip-end:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
}
.hover\:bg-fee-breakdown-bg:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
}
.hover\:bg-light-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-disabled-button-background:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
}
.hover\:bg-disabled-button-text:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
}
.focus\:bg-transparent:focus {
  background-color: transparent;
}
.focus\:bg-current:focus {
  background-color: currentColor;
}
.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.focus\:bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus\:bg-gray-300:focus {
  background-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.focus\:bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.focus\:bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.focus\:bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.focus\:bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
}
.focus\:bg-red-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.focus\:bg-red-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.focus\:bg-red-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.focus\:bg-red-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.focus\:bg-red-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.focus\:bg-red-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.focus\:bg-red-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.focus\:bg-red-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.focus\:bg-red-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.focus\:bg-red-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.focus\:bg-yellow-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.focus\:bg-yellow-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.focus\:bg-yellow-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.focus\:bg-yellow-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.focus\:bg-yellow-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.focus\:bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.focus\:bg-yellow-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.focus\:bg-yellow-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.focus\:bg-yellow-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.focus\:bg-yellow-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.focus\:bg-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.focus\:bg-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.focus\:bg-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.focus\:bg-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.focus\:bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.focus\:bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.focus\:bg-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.focus\:bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.focus\:bg-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.focus\:bg-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.focus\:bg-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.focus\:bg-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.focus\:bg-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.focus\:bg-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.focus\:bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.focus\:bg-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.focus\:bg-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.focus\:bg-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.focus\:bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.focus\:bg-indigo-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.focus\:bg-indigo-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.focus\:bg-indigo-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.focus\:bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.focus\:bg-indigo-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.focus\:bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.focus\:bg-indigo-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.focus\:bg-indigo-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.focus\:bg-indigo-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.focus\:bg-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.focus\:bg-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.focus\:bg-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.focus\:bg-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.focus\:bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.focus\:bg-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.focus\:bg-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.focus\:bg-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.focus\:bg-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.focus\:bg-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.focus\:bg-pink-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.focus\:bg-pink-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.focus\:bg-pink-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.focus\:bg-pink-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.focus\:bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.focus\:bg-pink-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.focus\:bg-pink-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.focus\:bg-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.focus\:bg-pink-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.focus\:bg-pink-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.focus\:bg-vivid-blue:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus\:bg-vivid-blue-500:focus {
  background-color: rgba(144, 150, 246, 0.5);
}
.focus\:bg-background-primary:focus {
  background-color: var(--background-primary);
}
.focus\:bg-main-background:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
}
.focus\:bg-dark-blue:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
}
.focus\:bg-blue-grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
}
.focus\:bg-text-grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
}
.focus\:bg-pale-grey-two:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
}
.focus\:bg-pale-grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
}
.focus\:bg-btc:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
}
.focus\:bg-btc-symbol:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
}
.focus\:bg-paxg:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
}
.focus\:bg-usdc:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
}
.focus\:bg-black-navy:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
}
.focus\:bg-ghost-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}
.focus\:bg-spring-green:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
}
.focus\:bg-light-slate-gray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
}
.focus\:bg-body-background:focus {
  background-color: var(--body-background);
}
.focus\:bg-background:focus {
  background-color: var(--background);
}
.focus\:bg-input-background:focus {
  background-color: var(--input-background);
}
.focus\:bg-body-text:focus {
  background-color: var(--body-text);
}
.focus\:bg-header-text:focus {
  background-color: var(--header-text);
}
.focus\:bg-primary:focus {
  background-color: var(--primary);
}
.focus\:bg-secondary:focus {
  background-color: var(--secondary);
}
.focus\:bg-tertiary:focus {
  background-color: var(--tertiary);
}
.focus\:bg-primary-hover:focus {
  background-color: var(--primary-hover);
}
.focus\:bg-secondary-hover:focus {
  background-color: var(--secondary-hover);
}
.focus\:bg-tertiary-hover:focus {
  background-color: var(--tertiary-hover);
}
.focus\:bg-input-text:focus {
  background-color: var(--input-text);
}
.focus\:bg-success:focus {
  background-color: var(--success);
}
.focus\:bg-error:focus {
  background-color: var(--error);
}
.focus\:bg-custom-loader:focus {
  background-color: var(--custom-loader);
}
.focus\:bg-violet:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
}
.focus\:bg-violet-hover:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
}
.focus\:bg-skeleton-loader-from:focus {
  background-color: var(--skeleton-loader-from);
}
.focus\:bg-skeleton-loader-to:focus {
  background-color: var(--skeleton-loader-to);
}
.focus\:bg-background50:focus {
  background-color: var(--background50);
}
.focus\:bg-reward-blue-start:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
}
.focus\:bg-reward-blue-end:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus\:bg-reward-silver-start:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
}
.focus\:bg-reward-silver-end:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
}
.focus\:bg-reward-gold-start:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
}
.focus\:bg-reward-gold-end:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
}
.focus\:bg-reward-platinum-start:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
}
.focus\:bg-reward-platinum-end:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}
.focus\:bg-reward-vip-start:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
}
.focus\:bg-reward-vip-end:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
}
.focus\:bg-fee-breakdown-bg:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
}
.focus\:bg-light-grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus\:bg-disabled-button-background:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
}
.focus\:bg-disabled-button-text:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
}
.bg-none {
  background-image: none;
}
.bg-gradient-to-t {
  background-image: linear-gradient(0deg, var(--tw-gradient-stops));
}
.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}
.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.bg-gradient-to-b {
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
}
.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}
.bg-gradient-to-l {
  background-image: linear-gradient(270deg, var(--tw-gradient-stops));
}
.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}
.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-black {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-gray-50 {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.from-gray-100 {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.from-gray-200 {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.from-gray-300 {
  --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.from-gray-400 {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.from-gray-500 {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.from-gray-600 {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.from-gray-700 {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.from-gray-800 {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.from-gray-900 {
  --tw-gradient-from: #6d728b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.from-red-50 {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.from-red-100 {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.from-red-200 {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.from-red-300 {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.from-red-400 {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.from-red-500 {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.from-red-600 {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.from-red-700 {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.from-red-800 {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.from-red-900 {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.from-yellow-50 {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.from-yellow-100 {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.from-yellow-200 {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.from-yellow-300 {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.from-yellow-400 {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.from-yellow-500 {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.from-yellow-600 {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.from-yellow-700 {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.from-yellow-800 {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.from-yellow-900 {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.from-green-50 {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.from-green-100 {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.from-green-200 {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.from-green-300 {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.from-green-400 {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.from-green-500 {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.from-green-600 {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.from-green-700 {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.from-green-800 {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.from-green-900 {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.from-blue-50 {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.from-blue-100 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.from-blue-300 {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.from-blue-400 {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.from-blue-700 {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.from-blue-800 {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.from-indigo-50 {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.from-indigo-100 {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.from-indigo-200 {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.from-indigo-400 {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.from-indigo-600 {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.from-indigo-700 {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.from-indigo-800 {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.from-indigo-900 {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.from-purple-50 {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.from-purple-100 {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.from-purple-200 {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.from-purple-300 {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.from-purple-400 {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.from-purple-500 {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.from-purple-600 {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.from-purple-700 {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.from-purple-800 {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.from-purple-900 {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.from-pink-50 {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.from-pink-100 {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.from-pink-200 {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.from-pink-300 {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.from-pink-400 {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.from-pink-500 {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.from-pink-600 {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.from-pink-700 {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.from-pink-800 {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.from-pink-900 {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.from-vivid-blue {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.from-vivid-blue-500 {
  --tw-gradient-from: rgba(144, 150, 246, 0.5);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.from-background-primary {
  --tw-gradient-from: var(--background-primary);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-main-background {
  --tw-gradient-from: #121729;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.from-dark-blue {
  --tw-gradient-from: #1e2741;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.from-blue-grey {
  --tw-gradient-from: #2f3a5c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.from-text-grey {
  --tw-gradient-from: #a4a7c1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.from-pale-grey-two {
  --tw-gradient-from: #eeebff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.from-pale-grey {
  --tw-gradient-from: #a8afcc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.from-btc {
  --tw-gradient-from: #f7931a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.from-btc-symbol {
  --tw-gradient-from: #f5b300;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.from-paxg {
  --tw-gradient-from: #ffc54e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.from-usdc {
  --tw-gradient-from: #2775ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.from-black-navy {
  --tw-gradient-from: #101239;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.from-ghost-white {
  --tw-gradient-from: #f6f5ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.from-spring-green {
  --tw-gradient-from: #1be56d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.from-light-slate-gray {
  --tw-gradient-from: #888b94;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.from-body-background {
  --tw-gradient-from: var(--body-background);
}
.from-background,
.from-body-background {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-background {
  --tw-gradient-from: var(--background);
}
.from-input-background {
  --tw-gradient-from: var(--input-background);
}
.from-body-text,
.from-input-background {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-body-text {
  --tw-gradient-from: var(--body-text);
}
.from-header-text {
  --tw-gradient-from: var(--header-text);
}
.from-header-text,
.from-primary {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-primary {
  --tw-gradient-from: var(--primary);
}
.from-secondary {
  --tw-gradient-from: var(--secondary);
}
.from-secondary,
.from-tertiary {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-tertiary {
  --tw-gradient-from: var(--tertiary);
}
.from-primary-hover {
  --tw-gradient-from: var(--primary-hover);
}
.from-primary-hover,
.from-secondary-hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-secondary-hover {
  --tw-gradient-from: var(--secondary-hover);
}
.from-tertiary-hover {
  --tw-gradient-from: var(--tertiary-hover);
}
.from-input-text,
.from-tertiary-hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-input-text {
  --tw-gradient-from: var(--input-text);
}
.from-success {
  --tw-gradient-from: var(--success);
}
.from-error,
.from-success {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-error {
  --tw-gradient-from: var(--error);
}
.from-custom-loader {
  --tw-gradient-from: var(--custom-loader);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-violet {
  --tw-gradient-from: #9421ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.from-violet-hover {
  --tw-gradient-from: #550b8e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.from-skeleton-loader-from {
  --tw-gradient-from: var(--skeleton-loader-from);
}
.from-skeleton-loader-from,
.from-skeleton-loader-to {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-skeleton-loader-to {
  --tw-gradient-from: var(--skeleton-loader-to);
}
.from-background50 {
  --tw-gradient-from: var(--background50);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.from-reward-blue-start {
  --tw-gradient-from: #626bff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.from-reward-blue-end {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.from-reward-silver-start {
  --tw-gradient-from: #484848;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.from-reward-silver-end {
  --tw-gradient-from: #acacac;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.from-reward-gold-start {
  --tw-gradient-from: #ffdf8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.from-reward-gold-end {
  --tw-gradient-from: #ffc425;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.from-reward-platinum-start {
  --tw-gradient-from: #959595;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.from-reward-platinum-end {
  --tw-gradient-from: #f8f8f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.from-reward-vip-start {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.from-reward-vip-end {
  --tw-gradient-from: #c521ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.from-fee-breakdown-bg {
  --tw-gradient-from: #182139;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.from-light-grey {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.from-disabled-button-background {
  --tw-gradient-from: #1f2a98;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.from-disabled-button-text {
  --tw-gradient-from: #8e93a0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, transparent);
}
.via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, transparent);
}
.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.via-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.via-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from),
    rgba(98, 107, 255, 0.25098039215686274),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.via-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.via-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.via-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.via-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.via-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.via-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.via-red-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.via-red-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.via-red-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.via-red-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.via-red-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.via-red-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.via-red-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.via-red-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.via-red-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.via-red-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.via-yellow-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.via-yellow-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.via-yellow-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.via-yellow-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.via-yellow-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.via-yellow-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.via-yellow-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.via-yellow-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.via-yellow-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.via-yellow-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.via-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.via-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.via-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.via-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.via-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.via-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.via-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.via-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.via-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.via-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.via-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.via-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.via-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.via-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.via-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.via-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.via-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.via-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.via-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.via-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.via-indigo-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.via-indigo-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.via-indigo-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.via-indigo-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.via-indigo-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.via-indigo-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.via-indigo-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.via-indigo-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.via-indigo-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.via-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.via-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.via-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.via-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.via-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.via-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.via-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.via-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.via-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.via-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.via-pink-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.via-pink-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.via-pink-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.via-pink-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.via-pink-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.via-pink-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.via-pink-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.via-pink-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.via-vivid-blue {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.via-vivid-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.via-background-primary {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-main-background {
  --tw-gradient-stops: var(--tw-gradient-from), #121729,
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.via-dark-blue {
  --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.via-blue-grey {
  --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.via-text-grey {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.via-pale-grey-two {
  --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.via-pale-grey {
  --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.via-btc {
  --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.via-btc-symbol {
  --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.via-paxg {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.via-usdc {
  --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.via-black-navy {
  --tw-gradient-stops: var(--tw-gradient-from), #101239,
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.via-ghost-white {
  --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.via-spring-green {
  --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.via-light-slate-gray {
  --tw-gradient-stops: var(--tw-gradient-from), #888b94,
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.via-body-background {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-background {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-input-background {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-body-text {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-header-text {
  --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-primary {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-secondary {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-tertiary {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-primary-hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-secondary-hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-tertiary-hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-input-text {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-success {
  --tw-gradient-stops: var(--tw-gradient-from), var(--success),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-error {
  --tw-gradient-stops: var(--tw-gradient-from), var(--error),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-custom-loader {
  --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-violet {
  --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.via-violet-hover {
  --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.via-skeleton-loader-from {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-skeleton-loader-to {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-background50 {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.via-reward-blue-start {
  --tw-gradient-stops: var(--tw-gradient-from), #626bff,
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.via-reward-blue-end {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.via-reward-silver-start {
  --tw-gradient-stops: var(--tw-gradient-from), #484848,
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.via-reward-silver-end {
  --tw-gradient-stops: var(--tw-gradient-from), #acacac,
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.via-reward-gold-start {
  --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.via-reward-gold-end {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.via-reward-platinum-start {
  --tw-gradient-stops: var(--tw-gradient-from), #959595,
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.via-reward-platinum-end {
  --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.via-reward-vip-start {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.via-reward-vip-end {
  --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.via-fee-breakdown-bg {
  --tw-gradient-stops: var(--tw-gradient-from), #182139,
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.via-light-grey {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.via-disabled-button-background {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.via-disabled-button-text {
  --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.to-transparent {
  --tw-gradient-to: transparent;
}
.to-current {
  --tw-gradient-to: currentColor;
}
.to-black {
  --tw-gradient-to: #000;
}
.to-white {
  --tw-gradient-to: #fff;
}
.to-gray-50 {
  --tw-gradient-to: #f9fafb;
}
.to-gray-100 {
  --tw-gradient-to: #f3f4f6;
}
.to-gray-200 {
  --tw-gradient-to: #e5e7eb;
}
.to-gray-300 {
  --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
}
.to-gray-400 {
  --tw-gradient-to: #9ca3af;
}
.to-gray-500 {
  --tw-gradient-to: #6b7280;
}
.to-gray-600 {
  --tw-gradient-to: #4b5563;
}
.to-gray-700 {
  --tw-gradient-to: #374151;
}
.to-gray-800 {
  --tw-gradient-to: #1f2937;
}
.to-gray-900 {
  --tw-gradient-to: #6d728b;
}
.to-red-50 {
  --tw-gradient-to: #fef2f2;
}
.to-red-100 {
  --tw-gradient-to: #fee2e2;
}
.to-red-200 {
  --tw-gradient-to: #fecaca;
}
.to-red-300 {
  --tw-gradient-to: #fca5a5;
}
.to-red-400 {
  --tw-gradient-to: #f87171;
}
.to-red-500 {
  --tw-gradient-to: #ef4444;
}
.to-red-600 {
  --tw-gradient-to: #dc2626;
}
.to-red-700 {
  --tw-gradient-to: #b91c1c;
}
.to-red-800 {
  --tw-gradient-to: #991b1b;
}
.to-red-900 {
  --tw-gradient-to: #7f1d1d;
}
.to-yellow-50 {
  --tw-gradient-to: #fffbeb;
}
.to-yellow-100 {
  --tw-gradient-to: #fef3c7;
}
.to-yellow-200 {
  --tw-gradient-to: #fde68a;
}
.to-yellow-300 {
  --tw-gradient-to: #fcd34d;
}
.to-yellow-400 {
  --tw-gradient-to: #fbbf24;
}
.to-yellow-500 {
  --tw-gradient-to: #f59e0b;
}
.to-yellow-600 {
  --tw-gradient-to: #d97706;
}
.to-yellow-700 {
  --tw-gradient-to: #b45309;
}
.to-yellow-800 {
  --tw-gradient-to: #92400e;
}
.to-yellow-900 {
  --tw-gradient-to: #78350f;
}
.to-green-50 {
  --tw-gradient-to: #ecfdf5;
}
.to-green-100 {
  --tw-gradient-to: #d1fae5;
}
.to-green-200 {
  --tw-gradient-to: #a7f3d0;
}
.to-green-300 {
  --tw-gradient-to: #6ee7b7;
}
.to-green-400 {
  --tw-gradient-to: #34d399;
}
.to-green-500 {
  --tw-gradient-to: #10b981;
}
.to-green-600 {
  --tw-gradient-to: #059669;
}
.to-green-700 {
  --tw-gradient-to: #047857;
}
.to-green-800 {
  --tw-gradient-to: #065f46;
}
.to-green-900 {
  --tw-gradient-to: #064e3b;
}
.to-blue-50 {
  --tw-gradient-to: #eff6ff;
}
.to-blue-100 {
  --tw-gradient-to: #dbeafe;
}
.to-blue-200 {
  --tw-gradient-to: #bfdbfe;
}
.to-blue-300 {
  --tw-gradient-to: #93c5fd;
}
.to-blue-400 {
  --tw-gradient-to: #60a5fa;
}
.to-blue-500 {
  --tw-gradient-to: #3b82f6;
}
.to-blue-600 {
  --tw-gradient-to: #2563eb;
}
.to-blue-700 {
  --tw-gradient-to: #1d4ed8;
}
.to-blue-800 {
  --tw-gradient-to: #1e40af;
}
.to-blue-900 {
  --tw-gradient-to: #1e3a8a;
}
.to-indigo-50 {
  --tw-gradient-to: #eef2ff;
}
.to-indigo-100 {
  --tw-gradient-to: #e0e7ff;
}
.to-indigo-200 {
  --tw-gradient-to: #c7d2fe;
}
.to-indigo-300 {
  --tw-gradient-to: #a5b4fc;
}
.to-indigo-400 {
  --tw-gradient-to: #818cf8;
}
.to-indigo-500 {
  --tw-gradient-to: #6366f1;
}
.to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}
.to-indigo-700 {
  --tw-gradient-to: #4338ca;
}
.to-indigo-800 {
  --tw-gradient-to: #3730a3;
}
.to-indigo-900 {
  --tw-gradient-to: #312e81;
}
.to-purple-50 {
  --tw-gradient-to: #f5f3ff;
}
.to-purple-100 {
  --tw-gradient-to: #ede9fe;
}
.to-purple-200 {
  --tw-gradient-to: #ddd6fe;
}
.to-purple-300 {
  --tw-gradient-to: #c4b5fd;
}
.to-purple-400 {
  --tw-gradient-to: #a78bfa;
}
.to-purple-500 {
  --tw-gradient-to: #8b5cf6;
}
.to-purple-600 {
  --tw-gradient-to: #7c3aed;
}
.to-purple-700 {
  --tw-gradient-to: #6d28d9;
}
.to-purple-800 {
  --tw-gradient-to: #5b21b6;
}
.to-purple-900 {
  --tw-gradient-to: #4c1d95;
}
.to-pink-50 {
  --tw-gradient-to: #fdf2f8;
}
.to-pink-100 {
  --tw-gradient-to: #fce7f3;
}
.to-pink-200 {
  --tw-gradient-to: #fbcfe8;
}
.to-pink-300 {
  --tw-gradient-to: #f9a8d4;
}
.to-pink-400 {
  --tw-gradient-to: #f472b6;
}
.to-pink-500 {
  --tw-gradient-to: #ec4899;
}
.to-pink-600 {
  --tw-gradient-to: #db2777;
}
.to-pink-700 {
  --tw-gradient-to: #be185d;
}
.to-pink-800 {
  --tw-gradient-to: #9d174d;
}
.to-pink-900 {
  --tw-gradient-to: #831843;
}
.to-vivid-blue {
  --tw-gradient-to: #212eee;
}
.to-vivid-blue-500 {
  --tw-gradient-to: rgba(144, 150, 246, 0.5);
}
.to-background-primary {
  --tw-gradient-to: var(--background-primary);
}
.to-main-background {
  --tw-gradient-to: #121729;
}
.to-dark-blue {
  --tw-gradient-to: #1e2741;
}
.to-blue-grey {
  --tw-gradient-to: #2f3a5c;
}
.to-text-grey {
  --tw-gradient-to: #a4a7c1;
}
.to-pale-grey-two {
  --tw-gradient-to: #eeebff;
}
.to-pale-grey {
  --tw-gradient-to: #a8afcc;
}
.to-btc {
  --tw-gradient-to: #f7931a;
}
.to-btc-symbol {
  --tw-gradient-to: #f5b300;
}
.to-paxg {
  --tw-gradient-to: #ffc54e;
}
.to-usdc {
  --tw-gradient-to: #2775ca;
}
.to-black-navy {
  --tw-gradient-to: #101239;
}
.to-ghost-white {
  --tw-gradient-to: #f6f5ff;
}
.to-spring-green {
  --tw-gradient-to: #1be56d;
}
.to-light-slate-gray {
  --tw-gradient-to: #888b94;
}
.to-body-background {
  --tw-gradient-to: var(--body-background);
}
.to-background {
  --tw-gradient-to: var(--background);
}
.to-input-background {
  --tw-gradient-to: var(--input-background);
}
.to-body-text {
  --tw-gradient-to: var(--body-text);
}
.to-header-text {
  --tw-gradient-to: var(--header-text);
}
.to-primary {
  --tw-gradient-to: var(--primary);
}
.to-secondary {
  --tw-gradient-to: var(--secondary);
}
.to-tertiary {
  --tw-gradient-to: var(--tertiary);
}
.to-primary-hover {
  --tw-gradient-to: var(--primary-hover);
}
.to-secondary-hover {
  --tw-gradient-to: var(--secondary-hover);
}
.to-tertiary-hover {
  --tw-gradient-to: var(--tertiary-hover);
}
.to-input-text {
  --tw-gradient-to: var(--input-text);
}
.to-success {
  --tw-gradient-to: var(--success);
}
.to-error {
  --tw-gradient-to: var(--error);
}
.to-custom-loader {
  --tw-gradient-to: var(--custom-loader);
}
.to-violet {
  --tw-gradient-to: #9421ee;
}
.to-violet-hover {
  --tw-gradient-to: #550b8e;
}
.to-skeleton-loader-from {
  --tw-gradient-to: var(--skeleton-loader-from);
}
.to-skeleton-loader-to {
  --tw-gradient-to: var(--skeleton-loader-to);
}
.to-background50 {
  --tw-gradient-to: var(--background50);
}
.to-reward-blue-start {
  --tw-gradient-to: #626bff;
}
.to-reward-blue-end {
  --tw-gradient-to: #212eee;
}
.to-reward-silver-start {
  --tw-gradient-to: #484848;
}
.to-reward-silver-end {
  --tw-gradient-to: #acacac;
}
.to-reward-gold-start {
  --tw-gradient-to: #ffdf8a;
}
.to-reward-gold-end {
  --tw-gradient-to: #ffc425;
}
.to-reward-platinum-start {
  --tw-gradient-to: #959595;
}
.to-reward-platinum-end {
  --tw-gradient-to: #f8f8f8;
}
.to-reward-vip-start {
  --tw-gradient-to: #212eee;
}
.to-reward-vip-end {
  --tw-gradient-to: #c521ee;
}
.to-fee-breakdown-bg {
  --tw-gradient-to: #182139;
}
.to-light-grey {
  --tw-gradient-to: #e5e7eb;
}
.to-disabled-button-background {
  --tw-gradient-to: #1f2a98;
}
.to-disabled-button-text {
  --tw-gradient-to: #8e93a0;
}
.hover\:from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.hover\:from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-black:hover {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.hover\:from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-gray-50:hover {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.hover\:from-gray-100:hover {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.hover\:from-gray-200:hover {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:from-gray-300:hover {
  --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.hover\:from-gray-400:hover {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.hover\:from-gray-500:hover {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.hover\:from-gray-600:hover {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.hover\:from-gray-700:hover {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.hover\:from-gray-800:hover {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.hover\:from-gray-900:hover {
  --tw-gradient-from: #6d728b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.hover\:from-red-50:hover {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.hover\:from-red-100:hover {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.hover\:from-red-200:hover {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.hover\:from-red-300:hover {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.hover\:from-red-400:hover {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.hover\:from-red-500:hover {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.hover\:from-red-600:hover {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.hover\:from-red-700:hover {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.hover\:from-red-800:hover {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.hover\:from-red-900:hover {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.hover\:from-yellow-50:hover {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.hover\:from-yellow-100:hover {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.hover\:from-yellow-200:hover {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.hover\:from-yellow-300:hover {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.hover\:from-yellow-400:hover {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.hover\:from-yellow-500:hover {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.hover\:from-yellow-600:hover {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.hover\:from-yellow-700:hover {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.hover\:from-yellow-800:hover {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.hover\:from-yellow-900:hover {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.hover\:from-green-50:hover {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.hover\:from-green-100:hover {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.hover\:from-green-200:hover {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.hover\:from-green-300:hover {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.hover\:from-green-400:hover {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.hover\:from-green-500:hover {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.hover\:from-green-600:hover {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.hover\:from-green-700:hover {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.hover\:from-green-800:hover {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.hover\:from-green-900:hover {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.hover\:from-blue-50:hover {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.hover\:from-blue-100:hover {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.hover\:from-blue-200:hover {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.hover\:from-blue-300:hover {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.hover\:from-blue-400:hover {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.hover\:from-blue-500:hover {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.hover\:from-blue-600:hover {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.hover\:from-blue-700:hover {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.hover\:from-blue-800:hover {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.hover\:from-blue-900:hover {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.hover\:from-indigo-50:hover {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.hover\:from-indigo-100:hover {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.hover\:from-indigo-200:hover {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.hover\:from-indigo-300:hover {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.hover\:from-indigo-400:hover {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.hover\:from-indigo-500:hover {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.hover\:from-indigo-600:hover {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.hover\:from-indigo-700:hover {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.hover\:from-indigo-800:hover {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.hover\:from-indigo-900:hover {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.hover\:from-purple-50:hover {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.hover\:from-purple-100:hover {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.hover\:from-purple-200:hover {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.hover\:from-purple-300:hover {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.hover\:from-purple-400:hover {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.hover\:from-purple-500:hover {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.hover\:from-purple-600:hover {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.hover\:from-purple-700:hover {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.hover\:from-purple-800:hover {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.hover\:from-purple-900:hover {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.hover\:from-pink-50:hover {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.hover\:from-pink-100:hover {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.hover\:from-pink-200:hover {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.hover\:from-pink-300:hover {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.hover\:from-pink-400:hover {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.hover\:from-pink-500:hover {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.hover\:from-pink-600:hover {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.hover\:from-pink-700:hover {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.hover\:from-pink-800:hover {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.hover\:from-pink-900:hover {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.hover\:from-vivid-blue:hover {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:from-vivid-blue-500:hover {
  --tw-gradient-from: rgba(144, 150, 246, 0.5);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.hover\:from-background-primary:hover {
  --tw-gradient-from: var(--background-primary);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-main-background:hover {
  --tw-gradient-from: #121729;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.hover\:from-dark-blue:hover {
  --tw-gradient-from: #1e2741;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.hover\:from-blue-grey:hover {
  --tw-gradient-from: #2f3a5c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.hover\:from-text-grey:hover {
  --tw-gradient-from: #a4a7c1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.hover\:from-pale-grey-two:hover {
  --tw-gradient-from: #eeebff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.hover\:from-pale-grey:hover {
  --tw-gradient-from: #a8afcc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.hover\:from-btc:hover {
  --tw-gradient-from: #f7931a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.hover\:from-btc-symbol:hover {
  --tw-gradient-from: #f5b300;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.hover\:from-paxg:hover {
  --tw-gradient-from: #ffc54e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.hover\:from-usdc:hover {
  --tw-gradient-from: #2775ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.hover\:from-black-navy:hover {
  --tw-gradient-from: #101239;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.hover\:from-ghost-white:hover {
  --tw-gradient-from: #f6f5ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.hover\:from-spring-green:hover {
  --tw-gradient-from: #1be56d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.hover\:from-light-slate-gray:hover {
  --tw-gradient-from: #888b94;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.hover\:from-body-background:hover {
  --tw-gradient-from: var(--body-background);
}
.hover\:from-background:hover,
.hover\:from-body-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-background:hover {
  --tw-gradient-from: var(--background);
}
.hover\:from-input-background:hover {
  --tw-gradient-from: var(--input-background);
}
.hover\:from-body-text:hover,
.hover\:from-input-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-body-text:hover {
  --tw-gradient-from: var(--body-text);
}
.hover\:from-header-text:hover {
  --tw-gradient-from: var(--header-text);
}
.hover\:from-header-text:hover,
.hover\:from-primary:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-primary:hover {
  --tw-gradient-from: var(--primary);
}
.hover\:from-secondary:hover {
  --tw-gradient-from: var(--secondary);
}
.hover\:from-secondary:hover,
.hover\:from-tertiary:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-tertiary:hover {
  --tw-gradient-from: var(--tertiary);
}
.hover\:from-primary-hover:hover {
  --tw-gradient-from: var(--primary-hover);
}
.hover\:from-primary-hover:hover,
.hover\:from-secondary-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-secondary-hover:hover {
  --tw-gradient-from: var(--secondary-hover);
}
.hover\:from-tertiary-hover:hover {
  --tw-gradient-from: var(--tertiary-hover);
}
.hover\:from-input-text:hover,
.hover\:from-tertiary-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-input-text:hover {
  --tw-gradient-from: var(--input-text);
}
.hover\:from-success:hover {
  --tw-gradient-from: var(--success);
}
.hover\:from-error:hover,
.hover\:from-success:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-error:hover {
  --tw-gradient-from: var(--error);
}
.hover\:from-custom-loader:hover {
  --tw-gradient-from: var(--custom-loader);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-violet:hover {
  --tw-gradient-from: #9421ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.hover\:from-violet-hover:hover {
  --tw-gradient-from: #550b8e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.hover\:from-skeleton-loader-from:hover {
  --tw-gradient-from: var(--skeleton-loader-from);
}
.hover\:from-skeleton-loader-from:hover,
.hover\:from-skeleton-loader-to:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-skeleton-loader-to:hover {
  --tw-gradient-from: var(--skeleton-loader-to);
}
.hover\:from-background50:hover {
  --tw-gradient-from: var(--background50);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:from-reward-blue-start:hover {
  --tw-gradient-from: #626bff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.hover\:from-reward-blue-end:hover {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:from-reward-silver-start:hover {
  --tw-gradient-from: #484848;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.hover\:from-reward-silver-end:hover {
  --tw-gradient-from: #acacac;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.hover\:from-reward-gold-start:hover {
  --tw-gradient-from: #ffdf8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.hover\:from-reward-gold-end:hover {
  --tw-gradient-from: #ffc425;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.hover\:from-reward-platinum-start:hover {
  --tw-gradient-from: #959595;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.hover\:from-reward-platinum-end:hover {
  --tw-gradient-from: #f8f8f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.hover\:from-reward-vip-start:hover {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:from-reward-vip-end:hover {
  --tw-gradient-from: #c521ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.hover\:from-fee-breakdown-bg:hover {
  --tw-gradient-from: #182139;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.hover\:from-light-grey:hover {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:from-disabled-button-background:hover {
  --tw-gradient-from: #1f2a98;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.hover\:from-disabled-button-text:hover {
  --tw-gradient-from: #8e93a0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.hover\:via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, transparent);
}
.hover\:via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, transparent);
}
.hover\:via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.hover\:via-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.hover\:via-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:via-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from),
    rgba(98, 107, 255, 0.25098039215686274),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.hover\:via-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.hover\:via-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.hover\:via-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.hover\:via-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.hover\:via-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.hover\:via-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.hover\:via-red-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.hover\:via-red-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.hover\:via-red-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.hover\:via-red-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.hover\:via-red-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.hover\:via-red-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.hover\:via-red-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.hover\:via-red-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.hover\:via-red-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.hover\:via-red-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.hover\:via-yellow-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.hover\:via-yellow-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.hover\:via-yellow-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.hover\:via-yellow-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.hover\:via-yellow-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.hover\:via-yellow-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.hover\:via-yellow-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.hover\:via-yellow-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.hover\:via-yellow-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.hover\:via-yellow-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.hover\:via-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.hover\:via-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.hover\:via-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.hover\:via-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.hover\:via-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.hover\:via-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.hover\:via-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.hover\:via-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.hover\:via-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.hover\:via-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.hover\:via-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.hover\:via-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.hover\:via-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.hover\:via-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.hover\:via-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.hover\:via-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.hover\:via-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.hover\:via-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.hover\:via-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.hover\:via-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.hover\:via-indigo-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.hover\:via-indigo-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.hover\:via-indigo-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.hover\:via-indigo-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.hover\:via-indigo-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.hover\:via-indigo-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.hover\:via-indigo-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.hover\:via-indigo-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.hover\:via-indigo-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.hover\:via-indigo-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.hover\:via-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.hover\:via-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.hover\:via-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.hover\:via-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.hover\:via-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.hover\:via-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.hover\:via-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.hover\:via-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.hover\:via-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.hover\:via-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.hover\:via-pink-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.hover\:via-pink-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.hover\:via-pink-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.hover\:via-pink-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.hover\:via-pink-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.hover\:via-pink-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.hover\:via-pink-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.hover\:via-pink-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.hover\:via-pink-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.hover\:via-pink-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.hover\:via-vivid-blue:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:via-vivid-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.hover\:via-background-primary:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-main-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #121729,
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.hover\:via-dark-blue:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.hover\:via-blue-grey:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.hover\:via-text-grey:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.hover\:via-pale-grey-two:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.hover\:via-pale-grey:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.hover\:via-btc:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.hover\:via-btc-symbol:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.hover\:via-paxg:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.hover\:via-usdc:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.hover\:via-black-navy:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #101239,
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.hover\:via-ghost-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.hover\:via-spring-green:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.hover\:via-light-slate-gray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #888b94,
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.hover\:via-body-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-input-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-body-text:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-header-text:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-primary:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-secondary:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-tertiary:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-primary-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-secondary-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-tertiary-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-input-text:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-success:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--success),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-error:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--error),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-custom-loader:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-violet:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.hover\:via-violet-hover:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.hover\:via-skeleton-loader-from:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-skeleton-loader-to:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-background50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.hover\:via-reward-blue-start:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #626bff,
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.hover\:via-reward-blue-end:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:via-reward-silver-start:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #484848,
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.hover\:via-reward-silver-end:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #acacac,
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.hover\:via-reward-gold-start:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.hover\:via-reward-gold-end:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.hover\:via-reward-platinum-start:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #959595,
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.hover\:via-reward-platinum-end:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.hover\:via-reward-vip-start:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.hover\:via-reward-vip-end:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.hover\:via-fee-breakdown-bg:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #182139,
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.hover\:via-light-grey:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:via-disabled-button-background:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.hover\:via-disabled-button-text:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.hover\:to-transparent:hover {
  --tw-gradient-to: transparent;
}
.hover\:to-current:hover {
  --tw-gradient-to: currentColor;
}
.hover\:to-black:hover {
  --tw-gradient-to: #000;
}
.hover\:to-white:hover {
  --tw-gradient-to: #fff;
}
.hover\:to-gray-50:hover {
  --tw-gradient-to: #f9fafb;
}
.hover\:to-gray-100:hover {
  --tw-gradient-to: #f3f4f6;
}
.hover\:to-gray-200:hover {
  --tw-gradient-to: #e5e7eb;
}
.hover\:to-gray-300:hover {
  --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
}
.hover\:to-gray-400:hover {
  --tw-gradient-to: #9ca3af;
}
.hover\:to-gray-500:hover {
  --tw-gradient-to: #6b7280;
}
.hover\:to-gray-600:hover {
  --tw-gradient-to: #4b5563;
}
.hover\:to-gray-700:hover {
  --tw-gradient-to: #374151;
}
.hover\:to-gray-800:hover {
  --tw-gradient-to: #1f2937;
}
.hover\:to-gray-900:hover {
  --tw-gradient-to: #6d728b;
}
.hover\:to-red-50:hover {
  --tw-gradient-to: #fef2f2;
}
.hover\:to-red-100:hover {
  --tw-gradient-to: #fee2e2;
}
.hover\:to-red-200:hover {
  --tw-gradient-to: #fecaca;
}
.hover\:to-red-300:hover {
  --tw-gradient-to: #fca5a5;
}
.hover\:to-red-400:hover {
  --tw-gradient-to: #f87171;
}
.hover\:to-red-500:hover {
  --tw-gradient-to: #ef4444;
}
.hover\:to-red-600:hover {
  --tw-gradient-to: #dc2626;
}
.hover\:to-red-700:hover {
  --tw-gradient-to: #b91c1c;
}
.hover\:to-red-800:hover {
  --tw-gradient-to: #991b1b;
}
.hover\:to-red-900:hover {
  --tw-gradient-to: #7f1d1d;
}
.hover\:to-yellow-50:hover {
  --tw-gradient-to: #fffbeb;
}
.hover\:to-yellow-100:hover {
  --tw-gradient-to: #fef3c7;
}
.hover\:to-yellow-200:hover {
  --tw-gradient-to: #fde68a;
}
.hover\:to-yellow-300:hover {
  --tw-gradient-to: #fcd34d;
}
.hover\:to-yellow-400:hover {
  --tw-gradient-to: #fbbf24;
}
.hover\:to-yellow-500:hover {
  --tw-gradient-to: #f59e0b;
}
.hover\:to-yellow-600:hover {
  --tw-gradient-to: #d97706;
}
.hover\:to-yellow-700:hover {
  --tw-gradient-to: #b45309;
}
.hover\:to-yellow-800:hover {
  --tw-gradient-to: #92400e;
}
.hover\:to-yellow-900:hover {
  --tw-gradient-to: #78350f;
}
.hover\:to-green-50:hover {
  --tw-gradient-to: #ecfdf5;
}
.hover\:to-green-100:hover {
  --tw-gradient-to: #d1fae5;
}
.hover\:to-green-200:hover {
  --tw-gradient-to: #a7f3d0;
}
.hover\:to-green-300:hover {
  --tw-gradient-to: #6ee7b7;
}
.hover\:to-green-400:hover {
  --tw-gradient-to: #34d399;
}
.hover\:to-green-500:hover {
  --tw-gradient-to: #10b981;
}
.hover\:to-green-600:hover {
  --tw-gradient-to: #059669;
}
.hover\:to-green-700:hover {
  --tw-gradient-to: #047857;
}
.hover\:to-green-800:hover {
  --tw-gradient-to: #065f46;
}
.hover\:to-green-900:hover {
  --tw-gradient-to: #064e3b;
}
.hover\:to-blue-50:hover {
  --tw-gradient-to: #eff6ff;
}
.hover\:to-blue-100:hover {
  --tw-gradient-to: #dbeafe;
}
.hover\:to-blue-200:hover {
  --tw-gradient-to: #bfdbfe;
}
.hover\:to-blue-300:hover {
  --tw-gradient-to: #93c5fd;
}
.hover\:to-blue-400:hover {
  --tw-gradient-to: #60a5fa;
}
.hover\:to-blue-500:hover {
  --tw-gradient-to: #3b82f6;
}
.hover\:to-blue-600:hover {
  --tw-gradient-to: #2563eb;
}
.hover\:to-blue-700:hover {
  --tw-gradient-to: #1d4ed8;
}
.hover\:to-blue-800:hover {
  --tw-gradient-to: #1e40af;
}
.hover\:to-blue-900:hover {
  --tw-gradient-to: #1e3a8a;
}
.hover\:to-indigo-50:hover {
  --tw-gradient-to: #eef2ff;
}
.hover\:to-indigo-100:hover {
  --tw-gradient-to: #e0e7ff;
}
.hover\:to-indigo-200:hover {
  --tw-gradient-to: #c7d2fe;
}
.hover\:to-indigo-300:hover {
  --tw-gradient-to: #a5b4fc;
}
.hover\:to-indigo-400:hover {
  --tw-gradient-to: #818cf8;
}
.hover\:to-indigo-500:hover {
  --tw-gradient-to: #6366f1;
}
.hover\:to-indigo-600:hover {
  --tw-gradient-to: #4f46e5;
}
.hover\:to-indigo-700:hover {
  --tw-gradient-to: #4338ca;
}
.hover\:to-indigo-800:hover {
  --tw-gradient-to: #3730a3;
}
.hover\:to-indigo-900:hover {
  --tw-gradient-to: #312e81;
}
.hover\:to-purple-50:hover {
  --tw-gradient-to: #f5f3ff;
}
.hover\:to-purple-100:hover {
  --tw-gradient-to: #ede9fe;
}
.hover\:to-purple-200:hover {
  --tw-gradient-to: #ddd6fe;
}
.hover\:to-purple-300:hover {
  --tw-gradient-to: #c4b5fd;
}
.hover\:to-purple-400:hover {
  --tw-gradient-to: #a78bfa;
}
.hover\:to-purple-500:hover {
  --tw-gradient-to: #8b5cf6;
}
.hover\:to-purple-600:hover {
  --tw-gradient-to: #7c3aed;
}
.hover\:to-purple-700:hover {
  --tw-gradient-to: #6d28d9;
}
.hover\:to-purple-800:hover {
  --tw-gradient-to: #5b21b6;
}
.hover\:to-purple-900:hover {
  --tw-gradient-to: #4c1d95;
}
.hover\:to-pink-50:hover {
  --tw-gradient-to: #fdf2f8;
}
.hover\:to-pink-100:hover {
  --tw-gradient-to: #fce7f3;
}
.hover\:to-pink-200:hover {
  --tw-gradient-to: #fbcfe8;
}
.hover\:to-pink-300:hover {
  --tw-gradient-to: #f9a8d4;
}
.hover\:to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}
.hover\:to-pink-500:hover {
  --tw-gradient-to: #ec4899;
}
.hover\:to-pink-600:hover {
  --tw-gradient-to: #db2777;
}
.hover\:to-pink-700:hover {
  --tw-gradient-to: #be185d;
}
.hover\:to-pink-800:hover {
  --tw-gradient-to: #9d174d;
}
.hover\:to-pink-900:hover {
  --tw-gradient-to: #831843;
}
.hover\:to-vivid-blue:hover {
  --tw-gradient-to: #212eee;
}
.hover\:to-vivid-blue-500:hover {
  --tw-gradient-to: rgba(144, 150, 246, 0.5);
}
.hover\:to-background-primary:hover {
  --tw-gradient-to: var(--background-primary);
}
.hover\:to-main-background:hover {
  --tw-gradient-to: #121729;
}
.hover\:to-dark-blue:hover {
  --tw-gradient-to: #1e2741;
}
.hover\:to-blue-grey:hover {
  --tw-gradient-to: #2f3a5c;
}
.hover\:to-text-grey:hover {
  --tw-gradient-to: #a4a7c1;
}
.hover\:to-pale-grey-two:hover {
  --tw-gradient-to: #eeebff;
}
.hover\:to-pale-grey:hover {
  --tw-gradient-to: #a8afcc;
}
.hover\:to-btc:hover {
  --tw-gradient-to: #f7931a;
}
.hover\:to-btc-symbol:hover {
  --tw-gradient-to: #f5b300;
}
.hover\:to-paxg:hover {
  --tw-gradient-to: #ffc54e;
}
.hover\:to-usdc:hover {
  --tw-gradient-to: #2775ca;
}
.hover\:to-black-navy:hover {
  --tw-gradient-to: #101239;
}
.hover\:to-ghost-white:hover {
  --tw-gradient-to: #f6f5ff;
}
.hover\:to-spring-green:hover {
  --tw-gradient-to: #1be56d;
}
.hover\:to-light-slate-gray:hover {
  --tw-gradient-to: #888b94;
}
.hover\:to-body-background:hover {
  --tw-gradient-to: var(--body-background);
}
.hover\:to-background:hover {
  --tw-gradient-to: var(--background);
}
.hover\:to-input-background:hover {
  --tw-gradient-to: var(--input-background);
}
.hover\:to-body-text:hover {
  --tw-gradient-to: var(--body-text);
}
.hover\:to-header-text:hover {
  --tw-gradient-to: var(--header-text);
}
.hover\:to-primary:hover {
  --tw-gradient-to: var(--primary);
}
.hover\:to-secondary:hover {
  --tw-gradient-to: var(--secondary);
}
.hover\:to-tertiary:hover {
  --tw-gradient-to: var(--tertiary);
}
.hover\:to-primary-hover:hover {
  --tw-gradient-to: var(--primary-hover);
}
.hover\:to-secondary-hover:hover {
  --tw-gradient-to: var(--secondary-hover);
}
.hover\:to-tertiary-hover:hover {
  --tw-gradient-to: var(--tertiary-hover);
}
.hover\:to-input-text:hover {
  --tw-gradient-to: var(--input-text);
}
.hover\:to-success:hover {
  --tw-gradient-to: var(--success);
}
.hover\:to-error:hover {
  --tw-gradient-to: var(--error);
}
.hover\:to-custom-loader:hover {
  --tw-gradient-to: var(--custom-loader);
}
.hover\:to-violet:hover {
  --tw-gradient-to: #9421ee;
}
.hover\:to-violet-hover:hover {
  --tw-gradient-to: #550b8e;
}
.hover\:to-skeleton-loader-from:hover {
  --tw-gradient-to: var(--skeleton-loader-from);
}
.hover\:to-skeleton-loader-to:hover {
  --tw-gradient-to: var(--skeleton-loader-to);
}
.hover\:to-background50:hover {
  --tw-gradient-to: var(--background50);
}
.hover\:to-reward-blue-start:hover {
  --tw-gradient-to: #626bff;
}
.hover\:to-reward-blue-end:hover {
  --tw-gradient-to: #212eee;
}
.hover\:to-reward-silver-start:hover {
  --tw-gradient-to: #484848;
}
.hover\:to-reward-silver-end:hover {
  --tw-gradient-to: #acacac;
}
.hover\:to-reward-gold-start:hover {
  --tw-gradient-to: #ffdf8a;
}
.hover\:to-reward-gold-end:hover {
  --tw-gradient-to: #ffc425;
}
.hover\:to-reward-platinum-start:hover {
  --tw-gradient-to: #959595;
}
.hover\:to-reward-platinum-end:hover {
  --tw-gradient-to: #f8f8f8;
}
.hover\:to-reward-vip-start:hover {
  --tw-gradient-to: #212eee;
}
.hover\:to-reward-vip-end:hover {
  --tw-gradient-to: #c521ee;
}
.hover\:to-fee-breakdown-bg:hover {
  --tw-gradient-to: #182139;
}
.hover\:to-light-grey:hover {
  --tw-gradient-to: #e5e7eb;
}
.hover\:to-disabled-button-background:hover {
  --tw-gradient-to: #1f2a98;
}
.hover\:to-disabled-button-text:hover {
  --tw-gradient-to: #8e93a0;
}
.focus\:from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.focus\:from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-black:focus {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
}
.focus\:from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-gray-50:focus {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.focus\:from-gray-100:focus {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.focus\:from-gray-200:focus {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:from-gray-300:focus {
  --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.focus\:from-gray-400:focus {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.focus\:from-gray-500:focus {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.focus\:from-gray-600:focus {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.focus\:from-gray-700:focus {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.focus\:from-gray-800:focus {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.focus\:from-gray-900:focus {
  --tw-gradient-from: #6d728b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.focus\:from-red-50:focus {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.focus\:from-red-100:focus {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.focus\:from-red-200:focus {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.focus\:from-red-300:focus {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.focus\:from-red-400:focus {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.focus\:from-red-500:focus {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.focus\:from-red-600:focus {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.focus\:from-red-700:focus {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.focus\:from-red-800:focus {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.focus\:from-red-900:focus {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.focus\:from-yellow-50:focus {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.focus\:from-yellow-100:focus {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.focus\:from-yellow-200:focus {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.focus\:from-yellow-300:focus {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.focus\:from-yellow-400:focus {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.focus\:from-yellow-500:focus {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.focus\:from-yellow-600:focus {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.focus\:from-yellow-700:focus {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.focus\:from-yellow-800:focus {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.focus\:from-yellow-900:focus {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.focus\:from-green-50:focus {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.focus\:from-green-100:focus {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.focus\:from-green-200:focus {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.focus\:from-green-300:focus {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.focus\:from-green-400:focus {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.focus\:from-green-500:focus {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.focus\:from-green-600:focus {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.focus\:from-green-700:focus {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.focus\:from-green-800:focus {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.focus\:from-green-900:focus {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.focus\:from-blue-50:focus {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.focus\:from-blue-100:focus {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.focus\:from-blue-200:focus {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.focus\:from-blue-300:focus {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.focus\:from-blue-400:focus {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.focus\:from-blue-500:focus {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.focus\:from-blue-600:focus {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.focus\:from-blue-700:focus {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.focus\:from-blue-800:focus {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.focus\:from-blue-900:focus {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.focus\:from-indigo-50:focus {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.focus\:from-indigo-100:focus {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.focus\:from-indigo-200:focus {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.focus\:from-indigo-300:focus {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.focus\:from-indigo-400:focus {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.focus\:from-indigo-500:focus {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.focus\:from-indigo-600:focus {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.focus\:from-indigo-700:focus {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.focus\:from-indigo-800:focus {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.focus\:from-indigo-900:focus {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.focus\:from-purple-50:focus {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.focus\:from-purple-100:focus {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.focus\:from-purple-200:focus {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.focus\:from-purple-300:focus {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.focus\:from-purple-400:focus {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.focus\:from-purple-500:focus {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.focus\:from-purple-600:focus {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.focus\:from-purple-700:focus {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.focus\:from-purple-800:focus {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.focus\:from-purple-900:focus {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.focus\:from-pink-50:focus {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.focus\:from-pink-100:focus {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.focus\:from-pink-200:focus {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.focus\:from-pink-300:focus {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.focus\:from-pink-400:focus {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.focus\:from-pink-500:focus {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.focus\:from-pink-600:focus {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.focus\:from-pink-700:focus {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.focus\:from-pink-800:focus {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.focus\:from-pink-900:focus {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.focus\:from-vivid-blue:focus {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:from-vivid-blue-500:focus {
  --tw-gradient-from: rgba(144, 150, 246, 0.5);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.focus\:from-background-primary:focus {
  --tw-gradient-from: var(--background-primary);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-main-background:focus {
  --tw-gradient-from: #121729;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.focus\:from-dark-blue:focus {
  --tw-gradient-from: #1e2741;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.focus\:from-blue-grey:focus {
  --tw-gradient-from: #2f3a5c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.focus\:from-text-grey:focus {
  --tw-gradient-from: #a4a7c1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.focus\:from-pale-grey-two:focus {
  --tw-gradient-from: #eeebff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.focus\:from-pale-grey:focus {
  --tw-gradient-from: #a8afcc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.focus\:from-btc:focus {
  --tw-gradient-from: #f7931a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.focus\:from-btc-symbol:focus {
  --tw-gradient-from: #f5b300;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.focus\:from-paxg:focus {
  --tw-gradient-from: #ffc54e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.focus\:from-usdc:focus {
  --tw-gradient-from: #2775ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.focus\:from-black-navy:focus {
  --tw-gradient-from: #101239;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.focus\:from-ghost-white:focus {
  --tw-gradient-from: #f6f5ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.focus\:from-spring-green:focus {
  --tw-gradient-from: #1be56d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.focus\:from-light-slate-gray:focus {
  --tw-gradient-from: #888b94;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.focus\:from-body-background:focus {
  --tw-gradient-from: var(--body-background);
}
.focus\:from-background:focus,
.focus\:from-body-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-background:focus {
  --tw-gradient-from: var(--background);
}
.focus\:from-input-background:focus {
  --tw-gradient-from: var(--input-background);
}
.focus\:from-body-text:focus,
.focus\:from-input-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-body-text:focus {
  --tw-gradient-from: var(--body-text);
}
.focus\:from-header-text:focus {
  --tw-gradient-from: var(--header-text);
}
.focus\:from-header-text:focus,
.focus\:from-primary:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-primary:focus {
  --tw-gradient-from: var(--primary);
}
.focus\:from-secondary:focus {
  --tw-gradient-from: var(--secondary);
}
.focus\:from-secondary:focus,
.focus\:from-tertiary:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-tertiary:focus {
  --tw-gradient-from: var(--tertiary);
}
.focus\:from-primary-hover:focus {
  --tw-gradient-from: var(--primary-hover);
}
.focus\:from-primary-hover:focus,
.focus\:from-secondary-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-secondary-hover:focus {
  --tw-gradient-from: var(--secondary-hover);
}
.focus\:from-tertiary-hover:focus {
  --tw-gradient-from: var(--tertiary-hover);
}
.focus\:from-input-text:focus,
.focus\:from-tertiary-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-input-text:focus {
  --tw-gradient-from: var(--input-text);
}
.focus\:from-success:focus {
  --tw-gradient-from: var(--success);
}
.focus\:from-error:focus,
.focus\:from-success:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-error:focus {
  --tw-gradient-from: var(--error);
}
.focus\:from-custom-loader:focus {
  --tw-gradient-from: var(--custom-loader);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-violet:focus {
  --tw-gradient-from: #9421ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.focus\:from-violet-hover:focus {
  --tw-gradient-from: #550b8e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.focus\:from-skeleton-loader-from:focus {
  --tw-gradient-from: var(--skeleton-loader-from);
}
.focus\:from-skeleton-loader-from:focus,
.focus\:from-skeleton-loader-to:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-skeleton-loader-to:focus {
  --tw-gradient-from: var(--skeleton-loader-to);
}
.focus\:from-background50:focus {
  --tw-gradient-from: var(--background50);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:from-reward-blue-start:focus {
  --tw-gradient-from: #626bff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.focus\:from-reward-blue-end:focus {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:from-reward-silver-start:focus {
  --tw-gradient-from: #484848;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.focus\:from-reward-silver-end:focus {
  --tw-gradient-from: #acacac;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.focus\:from-reward-gold-start:focus {
  --tw-gradient-from: #ffdf8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.focus\:from-reward-gold-end:focus {
  --tw-gradient-from: #ffc425;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.focus\:from-reward-platinum-start:focus {
  --tw-gradient-from: #959595;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.focus\:from-reward-platinum-end:focus {
  --tw-gradient-from: #f8f8f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.focus\:from-reward-vip-start:focus {
  --tw-gradient-from: #212eee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:from-reward-vip-end:focus {
  --tw-gradient-from: #c521ee;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.focus\:from-fee-breakdown-bg:focus {
  --tw-gradient-from: #182139;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.focus\:from-light-grey:focus {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:from-disabled-button-background:focus {
  --tw-gradient-from: #1f2a98;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.focus\:from-disabled-button-text:focus {
  --tw-gradient-from: #8e93a0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.focus\:via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, transparent);
}
.focus\:via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, transparent);
}
.focus\:via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.focus\:via-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.focus\:via-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:via-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from),
    rgba(98, 107, 255, 0.25098039215686274),
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.focus\:via-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.focus\:via-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.focus\:via-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.focus\:via-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.focus\:via-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.focus\:via-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
    var(--tw-gradient-to, rgba(109, 114, 139, 0));
}
.focus\:via-red-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.focus\:via-red-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.focus\:via-red-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.focus\:via-red-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.focus\:via-red-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.focus\:via-red-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.focus\:via-red-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.focus\:via-red-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.focus\:via-red-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.focus\:via-red-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.focus\:via-yellow-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.focus\:via-yellow-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.focus\:via-yellow-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.focus\:via-yellow-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.focus\:via-yellow-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.focus\:via-yellow-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.focus\:via-yellow-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.focus\:via-yellow-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.focus\:via-yellow-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.focus\:via-yellow-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.focus\:via-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.focus\:via-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.focus\:via-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.focus\:via-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.focus\:via-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.focus\:via-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.focus\:via-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.focus\:via-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.focus\:via-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.focus\:via-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.focus\:via-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.focus\:via-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.focus\:via-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.focus\:via-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.focus\:via-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.focus\:via-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.focus\:via-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.focus\:via-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.focus\:via-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.focus\:via-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.focus\:via-indigo-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.focus\:via-indigo-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.focus\:via-indigo-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.focus\:via-indigo-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.focus\:via-indigo-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.focus\:via-indigo-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.focus\:via-indigo-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.focus\:via-indigo-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.focus\:via-indigo-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.focus\:via-indigo-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.focus\:via-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.focus\:via-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.focus\:via-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.focus\:via-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.focus\:via-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.focus\:via-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.focus\:via-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.focus\:via-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.focus\:via-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.focus\:via-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.focus\:via-pink-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.focus\:via-pink-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.focus\:via-pink-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.focus\:via-pink-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.focus\:via-pink-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.focus\:via-pink-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.focus\:via-pink-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.focus\:via-pink-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.focus\:via-pink-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.focus\:via-pink-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.focus\:via-vivid-blue:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:via-vivid-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
    var(--tw-gradient-to, rgba(144, 150, 246, 0));
}
.focus\:via-background-primary:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-main-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #121729,
    var(--tw-gradient-to, rgba(18, 23, 41, 0));
}
.focus\:via-dark-blue:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
    var(--tw-gradient-to, rgba(30, 39, 65, 0));
}
.focus\:via-blue-grey:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
    var(--tw-gradient-to, rgba(47, 58, 92, 0));
}
.focus\:via-text-grey:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
    var(--tw-gradient-to, rgba(164, 167, 193, 0));
}
.focus\:via-pale-grey-two:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
    var(--tw-gradient-to, rgba(238, 235, 255, 0));
}
.focus\:via-pale-grey:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
    var(--tw-gradient-to, rgba(168, 175, 204, 0));
}
.focus\:via-btc:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
    var(--tw-gradient-to, rgba(247, 147, 26, 0));
}
.focus\:via-btc-symbol:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
    var(--tw-gradient-to, rgba(245, 179, 0, 0));
}
.focus\:via-paxg:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
    var(--tw-gradient-to, rgba(255, 197, 78, 0));
}
.focus\:via-usdc:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
    var(--tw-gradient-to, rgba(39, 117, 202, 0));
}
.focus\:via-black-navy:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #101239,
    var(--tw-gradient-to, rgba(16, 18, 57, 0));
}
.focus\:via-ghost-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
    var(--tw-gradient-to, rgba(246, 245, 255, 0));
}
.focus\:via-spring-green:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
    var(--tw-gradient-to, rgba(27, 229, 109, 0));
}
.focus\:via-light-slate-gray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #888b94,
    var(--tw-gradient-to, rgba(136, 139, 148, 0));
}
.focus\:via-body-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-input-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-body-text:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-header-text:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-primary:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-secondary:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-tertiary:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-primary-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-secondary-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-tertiary-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-input-text:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-success:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--success),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-error:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--error),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-custom-loader:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-violet:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
    var(--tw-gradient-to, rgba(148, 33, 238, 0));
}
.focus\:via-violet-hover:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
    var(--tw-gradient-to, rgba(85, 11, 142, 0));
}
.focus\:via-skeleton-loader-from:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-skeleton-loader-to:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-background50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
    var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
}
.focus\:via-reward-blue-start:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #626bff,
    var(--tw-gradient-to, rgba(98, 107, 255, 0));
}
.focus\:via-reward-blue-end:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:via-reward-silver-start:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #484848,
    var(--tw-gradient-to, rgba(72, 72, 72, 0));
}
.focus\:via-reward-silver-end:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #acacac,
    var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
}
.focus\:via-reward-gold-start:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
    var(--tw-gradient-to, rgba(255, 223, 138, 0));
}
.focus\:via-reward-gold-end:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
    var(--tw-gradient-to, rgba(255, 196, 37, 0));
}
.focus\:via-reward-platinum-start:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #959595,
    var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
}
.focus\:via-reward-platinum-end:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
    var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
}
.focus\:via-reward-vip-start:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #212eee,
    var(--tw-gradient-to, rgba(33, 46, 238, 0));
}
.focus\:via-reward-vip-end:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
    var(--tw-gradient-to, rgba(197, 33, 238, 0));
}
.focus\:via-fee-breakdown-bg:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #182139,
    var(--tw-gradient-to, rgba(24, 33, 57, 0));
}
.focus\:via-light-grey:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:via-disabled-button-background:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
    var(--tw-gradient-to, rgba(31, 42, 152, 0));
}
.focus\:via-disabled-button-text:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
    var(--tw-gradient-to, rgba(142, 147, 160, 0));
}
.focus\:to-transparent:focus {
  --tw-gradient-to: transparent;
}
.focus\:to-current:focus {
  --tw-gradient-to: currentColor;
}
.focus\:to-black:focus {
  --tw-gradient-to: #000;
}
.focus\:to-white:focus {
  --tw-gradient-to: #fff;
}
.focus\:to-gray-50:focus {
  --tw-gradient-to: #f9fafb;
}
.focus\:to-gray-100:focus {
  --tw-gradient-to: #f3f4f6;
}
.focus\:to-gray-200:focus {
  --tw-gradient-to: #e5e7eb;
}
.focus\:to-gray-300:focus {
  --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:to-gray-400:focus {
  --tw-gradient-to: #9ca3af;
}
.focus\:to-gray-500:focus {
  --tw-gradient-to: #6b7280;
}
.focus\:to-gray-600:focus {
  --tw-gradient-to: #4b5563;
}
.focus\:to-gray-700:focus {
  --tw-gradient-to: #374151;
}
.focus\:to-gray-800:focus {
  --tw-gradient-to: #1f2937;
}
.focus\:to-gray-900:focus {
  --tw-gradient-to: #6d728b;
}
.focus\:to-red-50:focus {
  --tw-gradient-to: #fef2f2;
}
.focus\:to-red-100:focus {
  --tw-gradient-to: #fee2e2;
}
.focus\:to-red-200:focus {
  --tw-gradient-to: #fecaca;
}
.focus\:to-red-300:focus {
  --tw-gradient-to: #fca5a5;
}
.focus\:to-red-400:focus {
  --tw-gradient-to: #f87171;
}
.focus\:to-red-500:focus {
  --tw-gradient-to: #ef4444;
}
.focus\:to-red-600:focus {
  --tw-gradient-to: #dc2626;
}
.focus\:to-red-700:focus {
  --tw-gradient-to: #b91c1c;
}
.focus\:to-red-800:focus {
  --tw-gradient-to: #991b1b;
}
.focus\:to-red-900:focus {
  --tw-gradient-to: #7f1d1d;
}
.focus\:to-yellow-50:focus {
  --tw-gradient-to: #fffbeb;
}
.focus\:to-yellow-100:focus {
  --tw-gradient-to: #fef3c7;
}
.focus\:to-yellow-200:focus {
  --tw-gradient-to: #fde68a;
}
.focus\:to-yellow-300:focus {
  --tw-gradient-to: #fcd34d;
}
.focus\:to-yellow-400:focus {
  --tw-gradient-to: #fbbf24;
}
.focus\:to-yellow-500:focus {
  --tw-gradient-to: #f59e0b;
}
.focus\:to-yellow-600:focus {
  --tw-gradient-to: #d97706;
}
.focus\:to-yellow-700:focus {
  --tw-gradient-to: #b45309;
}
.focus\:to-yellow-800:focus {
  --tw-gradient-to: #92400e;
}
.focus\:to-yellow-900:focus {
  --tw-gradient-to: #78350f;
}
.focus\:to-green-50:focus {
  --tw-gradient-to: #ecfdf5;
}
.focus\:to-green-100:focus {
  --tw-gradient-to: #d1fae5;
}
.focus\:to-green-200:focus {
  --tw-gradient-to: #a7f3d0;
}
.focus\:to-green-300:focus {
  --tw-gradient-to: #6ee7b7;
}
.focus\:to-green-400:focus {
  --tw-gradient-to: #34d399;
}
.focus\:to-green-500:focus {
  --tw-gradient-to: #10b981;
}
.focus\:to-green-600:focus {
  --tw-gradient-to: #059669;
}
.focus\:to-green-700:focus {
  --tw-gradient-to: #047857;
}
.focus\:to-green-800:focus {
  --tw-gradient-to: #065f46;
}
.focus\:to-green-900:focus {
  --tw-gradient-to: #064e3b;
}
.focus\:to-blue-50:focus {
  --tw-gradient-to: #eff6ff;
}
.focus\:to-blue-100:focus {
  --tw-gradient-to: #dbeafe;
}
.focus\:to-blue-200:focus {
  --tw-gradient-to: #bfdbfe;
}
.focus\:to-blue-300:focus {
  --tw-gradient-to: #93c5fd;
}
.focus\:to-blue-400:focus {
  --tw-gradient-to: #60a5fa;
}
.focus\:to-blue-500:focus {
  --tw-gradient-to: #3b82f6;
}
.focus\:to-blue-600:focus {
  --tw-gradient-to: #2563eb;
}
.focus\:to-blue-700:focus {
  --tw-gradient-to: #1d4ed8;
}
.focus\:to-blue-800:focus {
  --tw-gradient-to: #1e40af;
}
.focus\:to-blue-900:focus {
  --tw-gradient-to: #1e3a8a;
}
.focus\:to-indigo-50:focus {
  --tw-gradient-to: #eef2ff;
}
.focus\:to-indigo-100:focus {
  --tw-gradient-to: #e0e7ff;
}
.focus\:to-indigo-200:focus {
  --tw-gradient-to: #c7d2fe;
}
.focus\:to-indigo-300:focus {
  --tw-gradient-to: #a5b4fc;
}
.focus\:to-indigo-400:focus {
  --tw-gradient-to: #818cf8;
}
.focus\:to-indigo-500:focus {
  --tw-gradient-to: #6366f1;
}
.focus\:to-indigo-600:focus {
  --tw-gradient-to: #4f46e5;
}
.focus\:to-indigo-700:focus {
  --tw-gradient-to: #4338ca;
}
.focus\:to-indigo-800:focus {
  --tw-gradient-to: #3730a3;
}
.focus\:to-indigo-900:focus {
  --tw-gradient-to: #312e81;
}
.focus\:to-purple-50:focus {
  --tw-gradient-to: #f5f3ff;
}
.focus\:to-purple-100:focus {
  --tw-gradient-to: #ede9fe;
}
.focus\:to-purple-200:focus {
  --tw-gradient-to: #ddd6fe;
}
.focus\:to-purple-300:focus {
  --tw-gradient-to: #c4b5fd;
}
.focus\:to-purple-400:focus {
  --tw-gradient-to: #a78bfa;
}
.focus\:to-purple-500:focus {
  --tw-gradient-to: #8b5cf6;
}
.focus\:to-purple-600:focus {
  --tw-gradient-to: #7c3aed;
}
.focus\:to-purple-700:focus {
  --tw-gradient-to: #6d28d9;
}
.focus\:to-purple-800:focus {
  --tw-gradient-to: #5b21b6;
}
.focus\:to-purple-900:focus {
  --tw-gradient-to: #4c1d95;
}
.focus\:to-pink-50:focus {
  --tw-gradient-to: #fdf2f8;
}
.focus\:to-pink-100:focus {
  --tw-gradient-to: #fce7f3;
}
.focus\:to-pink-200:focus {
  --tw-gradient-to: #fbcfe8;
}
.focus\:to-pink-300:focus {
  --tw-gradient-to: #f9a8d4;
}
.focus\:to-pink-400:focus {
  --tw-gradient-to: #f472b6;
}
.focus\:to-pink-500:focus {
  --tw-gradient-to: #ec4899;
}
.focus\:to-pink-600:focus {
  --tw-gradient-to: #db2777;
}
.focus\:to-pink-700:focus {
  --tw-gradient-to: #be185d;
}
.focus\:to-pink-800:focus {
  --tw-gradient-to: #9d174d;
}
.focus\:to-pink-900:focus {
  --tw-gradient-to: #831843;
}
.focus\:to-vivid-blue:focus {
  --tw-gradient-to: #212eee;
}
.focus\:to-vivid-blue-500:focus {
  --tw-gradient-to: rgba(144, 150, 246, 0.5);
}
.focus\:to-background-primary:focus {
  --tw-gradient-to: var(--background-primary);
}
.focus\:to-main-background:focus {
  --tw-gradient-to: #121729;
}
.focus\:to-dark-blue:focus {
  --tw-gradient-to: #1e2741;
}
.focus\:to-blue-grey:focus {
  --tw-gradient-to: #2f3a5c;
}
.focus\:to-text-grey:focus {
  --tw-gradient-to: #a4a7c1;
}
.focus\:to-pale-grey-two:focus {
  --tw-gradient-to: #eeebff;
}
.focus\:to-pale-grey:focus {
  --tw-gradient-to: #a8afcc;
}
.focus\:to-btc:focus {
  --tw-gradient-to: #f7931a;
}
.focus\:to-btc-symbol:focus {
  --tw-gradient-to: #f5b300;
}
.focus\:to-paxg:focus {
  --tw-gradient-to: #ffc54e;
}
.focus\:to-usdc:focus {
  --tw-gradient-to: #2775ca;
}
.focus\:to-black-navy:focus {
  --tw-gradient-to: #101239;
}
.focus\:to-ghost-white:focus {
  --tw-gradient-to: #f6f5ff;
}
.focus\:to-spring-green:focus {
  --tw-gradient-to: #1be56d;
}
.focus\:to-light-slate-gray:focus {
  --tw-gradient-to: #888b94;
}
.focus\:to-body-background:focus {
  --tw-gradient-to: var(--body-background);
}
.focus\:to-background:focus {
  --tw-gradient-to: var(--background);
}
.focus\:to-input-background:focus {
  --tw-gradient-to: var(--input-background);
}
.focus\:to-body-text:focus {
  --tw-gradient-to: var(--body-text);
}
.focus\:to-header-text:focus {
  --tw-gradient-to: var(--header-text);
}
.focus\:to-primary:focus {
  --tw-gradient-to: var(--primary);
}
.focus\:to-secondary:focus {
  --tw-gradient-to: var(--secondary);
}
.focus\:to-tertiary:focus {
  --tw-gradient-to: var(--tertiary);
}
.focus\:to-primary-hover:focus {
  --tw-gradient-to: var(--primary-hover);
}
.focus\:to-secondary-hover:focus {
  --tw-gradient-to: var(--secondary-hover);
}
.focus\:to-tertiary-hover:focus {
  --tw-gradient-to: var(--tertiary-hover);
}
.focus\:to-input-text:focus {
  --tw-gradient-to: var(--input-text);
}
.focus\:to-success:focus {
  --tw-gradient-to: var(--success);
}
.focus\:to-error:focus {
  --tw-gradient-to: var(--error);
}
.focus\:to-custom-loader:focus {
  --tw-gradient-to: var(--custom-loader);
}
.focus\:to-violet:focus {
  --tw-gradient-to: #9421ee;
}
.focus\:to-violet-hover:focus {
  --tw-gradient-to: #550b8e;
}
.focus\:to-skeleton-loader-from:focus {
  --tw-gradient-to: var(--skeleton-loader-from);
}
.focus\:to-skeleton-loader-to:focus {
  --tw-gradient-to: var(--skeleton-loader-to);
}
.focus\:to-background50:focus {
  --tw-gradient-to: var(--background50);
}
.focus\:to-reward-blue-start:focus {
  --tw-gradient-to: #626bff;
}
.focus\:to-reward-blue-end:focus {
  --tw-gradient-to: #212eee;
}
.focus\:to-reward-silver-start:focus {
  --tw-gradient-to: #484848;
}
.focus\:to-reward-silver-end:focus {
  --tw-gradient-to: #acacac;
}
.focus\:to-reward-gold-start:focus {
  --tw-gradient-to: #ffdf8a;
}
.focus\:to-reward-gold-end:focus {
  --tw-gradient-to: #ffc425;
}
.focus\:to-reward-platinum-start:focus {
  --tw-gradient-to: #959595;
}
.focus\:to-reward-platinum-end:focus {
  --tw-gradient-to: #f8f8f8;
}
.focus\:to-reward-vip-start:focus {
  --tw-gradient-to: #212eee;
}
.focus\:to-reward-vip-end:focus {
  --tw-gradient-to: #c521ee;
}
.focus\:to-fee-breakdown-bg:focus {
  --tw-gradient-to: #182139;
}
.focus\:to-light-grey:focus {
  --tw-gradient-to: #e5e7eb;
}
.focus\:to-disabled-button-background:focus {
  --tw-gradient-to: #1f2a98;
}
.focus\:to-disabled-button-text:focus {
  --tw-gradient-to: #8e93a0;
}
.bg-opacity-0 {
  --tw-bg-opacity: 0;
}
.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}
.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}
.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}
.bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}
.bg-opacity-100 {
  --tw-bg-opacity: 1;
}
.group:hover .group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0;
}
.group:hover .group-hover\:bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.group:hover .group-hover\:bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}
.group:hover .group-hover\:bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.group:hover .group-hover\:bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.group:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}
.group:hover .group-hover\:bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}
.group:hover .group-hover\:bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.group:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.group:hover .group-hover\:bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}
.group:hover .group-hover\:bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.group:hover .group-hover\:bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.group:hover .group-hover\:bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}
.group:hover .group-hover\:bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}
.group:hover .group-hover\:bg-opacity-100 {
  --tw-bg-opacity: 1;
}
.focus-within\:bg-opacity-0:focus-within {
  --tw-bg-opacity: 0;
}
.focus-within\:bg-opacity-5:focus-within {
  --tw-bg-opacity: 0.05;
}
.focus-within\:bg-opacity-10:focus-within {
  --tw-bg-opacity: 0.1;
}
.focus-within\:bg-opacity-20:focus-within {
  --tw-bg-opacity: 0.2;
}
.focus-within\:bg-opacity-25:focus-within {
  --tw-bg-opacity: 0.25;
}
.focus-within\:bg-opacity-30:focus-within {
  --tw-bg-opacity: 0.3;
}
.focus-within\:bg-opacity-40:focus-within {
  --tw-bg-opacity: 0.4;
}
.focus-within\:bg-opacity-50:focus-within {
  --tw-bg-opacity: 0.5;
}
.focus-within\:bg-opacity-60:focus-within {
  --tw-bg-opacity: 0.6;
}
.focus-within\:bg-opacity-70:focus-within {
  --tw-bg-opacity: 0.7;
}
.focus-within\:bg-opacity-75:focus-within {
  --tw-bg-opacity: 0.75;
}
.focus-within\:bg-opacity-80:focus-within {
  --tw-bg-opacity: 0.8;
}
.focus-within\:bg-opacity-90:focus-within {
  --tw-bg-opacity: 0.9;
}
.focus-within\:bg-opacity-95:focus-within {
  --tw-bg-opacity: 0.95;
}
.focus-within\:bg-opacity-100:focus-within {
  --tw-bg-opacity: 1;
}
.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}
.hover\:bg-opacity-5:hover {
  --tw-bg-opacity: 0.05;
}
.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}
.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}
.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: 0.25;
}
.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}
.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: 0.4;
}
.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: 0.5;
}
.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: 0.6;
}
.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: 0.7;
}
.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75;
}
.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: 0.8;
}
.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}
.hover\:bg-opacity-95:hover {
  --tw-bg-opacity: 0.95;
}
.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}
.focus\:bg-opacity-0:focus {
  --tw-bg-opacity: 0;
}
.focus\:bg-opacity-5:focus {
  --tw-bg-opacity: 0.05;
}
.focus\:bg-opacity-10:focus {
  --tw-bg-opacity: 0.1;
}
.focus\:bg-opacity-20:focus {
  --tw-bg-opacity: 0.2;
}
.focus\:bg-opacity-25:focus {
  --tw-bg-opacity: 0.25;
}
.focus\:bg-opacity-30:focus {
  --tw-bg-opacity: 0.3;
}
.focus\:bg-opacity-40:focus {
  --tw-bg-opacity: 0.4;
}
.focus\:bg-opacity-50:focus {
  --tw-bg-opacity: 0.5;
}
.focus\:bg-opacity-60:focus {
  --tw-bg-opacity: 0.6;
}
.focus\:bg-opacity-70:focus {
  --tw-bg-opacity: 0.7;
}
.focus\:bg-opacity-75:focus {
  --tw-bg-opacity: 0.75;
}
.focus\:bg-opacity-80:focus {
  --tw-bg-opacity: 0.8;
}
.focus\:bg-opacity-90:focus {
  --tw-bg-opacity: 0.9;
}
.focus\:bg-opacity-95:focus {
  --tw-bg-opacity: 0.95;
}
.focus\:bg-opacity-100:focus {
  --tw-bg-opacity: 1;
}
.bg-bottom {
  background-position: bottom;
}
.bg-center {
  background-position: 50%;
}
.bg-left {
  background-position: 0;
}
.bg-left-bottom {
  background-position: 0 100%;
}
.bg-left-top {
  background-position: 0 0;
}
.bg-right {
  background-position: 100%;
}
.bg-right-bottom {
  background-position: 100% 100%;
}
.bg-right-top {
  background-position: 100% 0;
}
.bg-top {
  background-position: top;
}
.bg-repeat {
  background-repeat: repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-repeat-x {
  background-repeat: repeat-x;
}
.bg-repeat-y {
  background-repeat: repeat-y;
}
.bg-repeat-round {
  background-repeat: round;
}
.bg-repeat-space {
  background-repeat: space;
}
.bg-auto {
  background-size: auto;
}
.bg-cover {
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}
.border-collapse {
  border-collapse: collapse;
}
.border-separate {
  border-collapse: separate;
}
.border-transparent {
  border-color: transparent;
}
.border-current {
  border-color: currentColor;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-300 {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-border-opacity));
}
.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.border-vivid-blue {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.border-vivid-blue-500 {
  border-color: rgba(144, 150, 246, 0.5);
}
.border-background-primary {
  border-color: var(--background-primary);
}
.border-main-background {
  --tw-border-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-border-opacity));
}
.border-dark-blue {
  --tw-border-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-border-opacity));
}
.border-blue-grey {
  --tw-border-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-border-opacity));
}
.border-text-grey {
  --tw-border-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-border-opacity));
}
.border-pale-grey-two {
  --tw-border-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-border-opacity));
}
.border-pale-grey {
  --tw-border-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-border-opacity));
}
.border-btc {
  --tw-border-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-border-opacity));
}
.border-btc-symbol {
  --tw-border-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-border-opacity));
}
.border-paxg {
  --tw-border-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-border-opacity));
}
.border-usdc {
  --tw-border-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-border-opacity));
}
.border-black-navy {
  --tw-border-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-border-opacity));
}
.border-ghost-white {
  --tw-border-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-border-opacity));
}
.border-spring-green {
  --tw-border-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-border-opacity));
}
.border-light-slate-gray {
  --tw-border-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-border-opacity));
}
.border-body-background {
  border-color: var(--body-background);
}
.border-background {
  border-color: var(--background);
}
.border-input-background {
  border-color: var(--input-background);
}
.border-body-text {
  border-color: var(--body-text);
}
.border-header-text {
  border-color: var(--header-text);
}
.border-primary {
  border-color: var(--primary);
}
.border-secondary {
  border-color: var(--secondary);
}
.border-tertiary {
  border-color: var(--tertiary);
}
.border-primary-hover {
  border-color: var(--primary-hover);
}
.border-secondary-hover {
  border-color: var(--secondary-hover);
}
.border-tertiary-hover {
  border-color: var(--tertiary-hover);
}
.border-input-text {
  border-color: var(--input-text);
}
.border-success {
  border-color: var(--success);
}
.border-error {
  border-color: var(--error);
}
.border-custom-loader {
  border-color: var(--custom-loader);
}
.border-violet {
  --tw-border-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-border-opacity));
}
.border-violet-hover {
  --tw-border-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-border-opacity));
}
.border-skeleton-loader-from {
  border-color: var(--skeleton-loader-from);
}
.border-skeleton-loader-to {
  border-color: var(--skeleton-loader-to);
}
.border-background50 {
  border-color: var(--background50);
}
.border-reward-blue-start {
  --tw-border-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-border-opacity));
}
.border-reward-blue-end {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.border-reward-silver-start {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-border-opacity));
}
.border-reward-silver-end {
  --tw-border-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-border-opacity));
}
.border-reward-gold-start {
  --tw-border-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-border-opacity));
}
.border-reward-gold-end {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-border-opacity));
}
.border-reward-platinum-start {
  --tw-border-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-border-opacity));
}
.border-reward-platinum-end {
  --tw-border-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-border-opacity));
}
.border-reward-vip-start {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.border-reward-vip-end {
  --tw-border-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-border-opacity));
}
.border-fee-breakdown-bg {
  --tw-border-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-border-opacity));
}
.border-light-grey {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-disabled-button-background {
  --tw-border-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-border-opacity));
}
.border-disabled-button-text {
  --tw-border-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-transparent {
  border-color: transparent;
}
.group:hover .group-hover\:border-current {
  border-color: currentColor;
}
.group:hover .group-hover\:border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-300 {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-vivid-blue {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-vivid-blue-500 {
  border-color: rgba(144, 150, 246, 0.5);
}
.group:hover .group-hover\:border-background-primary {
  border-color: var(--background-primary);
}
.group:hover .group-hover\:border-main-background {
  --tw-border-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-dark-blue {
  --tw-border-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-grey {
  --tw-border-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-text-grey {
  --tw-border-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pale-grey-two {
  --tw-border-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pale-grey {
  --tw-border-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-btc {
  --tw-border-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-btc-symbol {
  --tw-border-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-paxg {
  --tw-border-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-usdc {
  --tw-border-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-black-navy {
  --tw-border-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-ghost-white {
  --tw-border-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-spring-green {
  --tw-border-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-light-slate-gray {
  --tw-border-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-body-background {
  border-color: var(--body-background);
}
.group:hover .group-hover\:border-background {
  border-color: var(--background);
}
.group:hover .group-hover\:border-input-background {
  border-color: var(--input-background);
}
.group:hover .group-hover\:border-body-text {
  border-color: var(--body-text);
}
.group:hover .group-hover\:border-header-text {
  border-color: var(--header-text);
}
.group:hover .group-hover\:border-primary {
  border-color: var(--primary);
}
.group:hover .group-hover\:border-secondary {
  border-color: var(--secondary);
}
.group:hover .group-hover\:border-tertiary {
  border-color: var(--tertiary);
}
.group:hover .group-hover\:border-primary-hover {
  border-color: var(--primary-hover);
}
.group:hover .group-hover\:border-secondary-hover {
  border-color: var(--secondary-hover);
}
.group:hover .group-hover\:border-tertiary-hover {
  border-color: var(--tertiary-hover);
}
.group:hover .group-hover\:border-input-text {
  border-color: var(--input-text);
}
.group:hover .group-hover\:border-success {
  border-color: var(--success);
}
.group:hover .group-hover\:border-error {
  border-color: var(--error);
}
.group:hover .group-hover\:border-custom-loader {
  border-color: var(--custom-loader);
}
.group:hover .group-hover\:border-violet {
  --tw-border-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-violet-hover {
  --tw-border-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-skeleton-loader-from {
  border-color: var(--skeleton-loader-from);
}
.group:hover .group-hover\:border-skeleton-loader-to {
  border-color: var(--skeleton-loader-to);
}
.group:hover .group-hover\:border-background50 {
  border-color: var(--background50);
}
.group:hover .group-hover\:border-reward-blue-start {
  --tw-border-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-blue-end {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-silver-start {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-silver-end {
  --tw-border-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-gold-start {
  --tw-border-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-gold-end {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-platinum-start {
  --tw-border-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-platinum-end {
  --tw-border-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-vip-start {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-reward-vip-end {
  --tw-border-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-fee-breakdown-bg {
  --tw-border-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-light-grey {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-disabled-button-background {
  --tw-border-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-disabled-button-text {
  --tw-border-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-border-opacity));
}
.focus-within\:border-transparent:focus-within {
  border-color: transparent;
}
.focus-within\:border-current:focus-within {
  border-color: currentColor;
}
.focus-within\:border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus-within\:border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus-within\:border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus-within\:border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus-within\:border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus-within\:border-gray-300:focus-within {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus-within\:border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus-within\:border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus-within\:border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus-within\:border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus-within\:border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-border-opacity));
}
.focus-within\:border-red-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus-within\:border-red-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus-within\:border-red-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus-within\:border-red-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus-within\:border-red-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus-within\:border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus-within\:border-red-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-red-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus-within\:border-red-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus-within\:border-red-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus-within\:border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus-within\:border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus-within\:border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus-within\:border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus-within\:border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus-within\:border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus-within\:border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus-within\:border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus-within\:border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus-within\:border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus-within\:border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus-within\:border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus-within\:border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus-within\:border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus-within\:border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus-within\:border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus-within\:border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus-within\:border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus-within\:border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus-within\:border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus-within\:border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus-within\:border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus-within\:border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus-within\:border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus-within\:border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus-within\:border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus-within\:border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus-within\:border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus-within\:border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus-within\:border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus-within\:border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus-within\:border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus-within\:border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus-within\:border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus-within\:border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus-within\:border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus-within\:border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus-within\:border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus-within\:border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus-within\:border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus-within\:border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus-within\:border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus-within\:border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus-within\:border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus-within\:border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus-within\:border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus-within\:border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus-within\:border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus-within\:border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus-within\:border-vivid-blue:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus-within\:border-vivid-blue-500:focus-within {
  border-color: rgba(144, 150, 246, 0.5);
}
.focus-within\:border-background-primary:focus-within {
  border-color: var(--background-primary);
}
.focus-within\:border-main-background:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-border-opacity));
}
.focus-within\:border-dark-blue:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-border-opacity));
}
.focus-within\:border-blue-grey:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-border-opacity));
}
.focus-within\:border-text-grey:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-border-opacity));
}
.focus-within\:border-pale-grey-two:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-border-opacity));
}
.focus-within\:border-pale-grey:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-border-opacity));
}
.focus-within\:border-btc:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-border-opacity));
}
.focus-within\:border-btc-symbol:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-border-opacity));
}
.focus-within\:border-paxg:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-border-opacity));
}
.focus-within\:border-usdc:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-border-opacity));
}
.focus-within\:border-black-navy:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-border-opacity));
}
.focus-within\:border-ghost-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-border-opacity));
}
.focus-within\:border-spring-green:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-border-opacity));
}
.focus-within\:border-light-slate-gray:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-border-opacity));
}
.focus-within\:border-body-background:focus-within {
  border-color: var(--body-background);
}
.focus-within\:border-background:focus-within {
  border-color: var(--background);
}
.focus-within\:border-input-background:focus-within {
  border-color: var(--input-background);
}
.focus-within\:border-body-text:focus-within {
  border-color: var(--body-text);
}
.focus-within\:border-header-text:focus-within {
  border-color: var(--header-text);
}
.focus-within\:border-primary:focus-within {
  border-color: var(--primary);
}
.focus-within\:border-secondary:focus-within {
  border-color: var(--secondary);
}
.focus-within\:border-tertiary:focus-within {
  border-color: var(--tertiary);
}
.focus-within\:border-primary-hover:focus-within {
  border-color: var(--primary-hover);
}
.focus-within\:border-secondary-hover:focus-within {
  border-color: var(--secondary-hover);
}
.focus-within\:border-tertiary-hover:focus-within {
  border-color: var(--tertiary-hover);
}
.focus-within\:border-input-text:focus-within {
  border-color: var(--input-text);
}
.focus-within\:border-success:focus-within {
  border-color: var(--success);
}
.focus-within\:border-error:focus-within {
  border-color: var(--error);
}
.focus-within\:border-custom-loader:focus-within {
  border-color: var(--custom-loader);
}
.focus-within\:border-violet:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-border-opacity));
}
.focus-within\:border-violet-hover:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-border-opacity));
}
.focus-within\:border-skeleton-loader-from:focus-within {
  border-color: var(--skeleton-loader-from);
}
.focus-within\:border-skeleton-loader-to:focus-within {
  border-color: var(--skeleton-loader-to);
}
.focus-within\:border-background50:focus-within {
  border-color: var(--background50);
}
.focus-within\:border-reward-blue-start:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-border-opacity));
}
.focus-within\:border-reward-blue-end:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus-within\:border-reward-silver-start:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-border-opacity));
}
.focus-within\:border-reward-silver-end:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-border-opacity));
}
.focus-within\:border-reward-gold-start:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-border-opacity));
}
.focus-within\:border-reward-gold-end:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-border-opacity));
}
.focus-within\:border-reward-platinum-start:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-border-opacity));
}
.focus-within\:border-reward-platinum-end:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-border-opacity));
}
.focus-within\:border-reward-vip-start:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus-within\:border-reward-vip-end:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-border-opacity));
}
.focus-within\:border-fee-breakdown-bg:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-border-opacity));
}
.focus-within\:border-light-grey:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus-within\:border-disabled-button-background:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-border-opacity));
}
.focus-within\:border-disabled-button-text:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-border-opacity));
}
.hover\:border-transparent:hover {
  border-color: transparent;
}
.hover\:border-current:hover {
  border-color: currentColor;
}
.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.hover\:border-gray-300:hover {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-border-opacity));
}
.hover\:border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.hover\:border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.hover\:border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.hover\:border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.hover\:border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.hover\:border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.hover\:border-vivid-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.hover\:border-vivid-blue-500:hover {
  border-color: rgba(144, 150, 246, 0.5);
}
.hover\:border-background-primary:hover {
  border-color: var(--background-primary);
}
.hover\:border-main-background:hover {
  --tw-border-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-border-opacity));
}
.hover\:border-dark-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-border-opacity));
}
.hover\:border-blue-grey:hover {
  --tw-border-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-border-opacity));
}
.hover\:border-text-grey:hover {
  --tw-border-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-border-opacity));
}
.hover\:border-pale-grey-two:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-border-opacity));
}
.hover\:border-pale-grey:hover {
  --tw-border-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-border-opacity));
}
.hover\:border-btc:hover {
  --tw-border-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-border-opacity));
}
.hover\:border-btc-symbol:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-border-opacity));
}
.hover\:border-paxg:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-border-opacity));
}
.hover\:border-usdc:hover {
  --tw-border-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-border-opacity));
}
.hover\:border-black-navy:hover {
  --tw-border-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-border-opacity));
}
.hover\:border-ghost-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-border-opacity));
}
.hover\:border-spring-green:hover {
  --tw-border-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-border-opacity));
}
.hover\:border-light-slate-gray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-border-opacity));
}
.hover\:border-body-background:hover {
  border-color: var(--body-background);
}
.hover\:border-background:hover {
  border-color: var(--background);
}
.hover\:border-input-background:hover {
  border-color: var(--input-background);
}
.hover\:border-body-text:hover {
  border-color: var(--body-text);
}
.hover\:border-header-text:hover {
  border-color: var(--header-text);
}
.hover\:border-primary:hover {
  border-color: var(--primary);
}
.hover\:border-secondary:hover {
  border-color: var(--secondary);
}
.hover\:border-tertiary:hover {
  border-color: var(--tertiary);
}
.hover\:border-primary-hover:hover {
  border-color: var(--primary-hover);
}
.hover\:border-secondary-hover:hover {
  border-color: var(--secondary-hover);
}
.hover\:border-tertiary-hover:hover {
  border-color: var(--tertiary-hover);
}
.hover\:border-input-text:hover {
  border-color: var(--input-text);
}
.hover\:border-success:hover {
  border-color: var(--success);
}
.hover\:border-error:hover {
  border-color: var(--error);
}
.hover\:border-custom-loader:hover {
  border-color: var(--custom-loader);
}
.hover\:border-violet:hover {
  --tw-border-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-border-opacity));
}
.hover\:border-violet-hover:hover {
  --tw-border-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-border-opacity));
}
.hover\:border-skeleton-loader-from:hover {
  border-color: var(--skeleton-loader-from);
}
.hover\:border-skeleton-loader-to:hover {
  border-color: var(--skeleton-loader-to);
}
.hover\:border-background50:hover {
  border-color: var(--background50);
}
.hover\:border-reward-blue-start:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-border-opacity));
}
.hover\:border-reward-blue-end:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.hover\:border-reward-silver-start:hover {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-border-opacity));
}
.hover\:border-reward-silver-end:hover {
  --tw-border-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-border-opacity));
}
.hover\:border-reward-gold-start:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-border-opacity));
}
.hover\:border-reward-gold-end:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-border-opacity));
}
.hover\:border-reward-platinum-start:hover {
  --tw-border-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-border-opacity));
}
.hover\:border-reward-platinum-end:hover {
  --tw-border-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-border-opacity));
}
.hover\:border-reward-vip-start:hover {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.hover\:border-reward-vip-end:hover {
  --tw-border-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-border-opacity));
}
.hover\:border-fee-breakdown-bg:hover {
  --tw-border-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-border-opacity));
}
.hover\:border-light-grey:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.hover\:border-disabled-button-background:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-border-opacity));
}
.hover\:border-disabled-button-text:hover {
  --tw-border-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-border-opacity));
}
.focus\:border-transparent:focus {
  border-color: transparent;
}
.focus\:border-current:focus {
  border-color: currentColor;
}
.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus\:border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus\:border-gray-300:focus {
  border-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 114, 139, var(--tw-border-opacity));
}
.focus\:border-red-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus\:border-red-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus\:border-red-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus\:border-red-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus\:border-red-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus\:border-red-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus\:border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus\:border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus\:border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus\:border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus\:border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus\:border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus\:border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus\:border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus\:border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus\:border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus\:border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus\:border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus\:border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus\:border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus\:border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus\:border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus\:border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus\:border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus\:border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus\:border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus\:border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus\:border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus\:border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus\:border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus\:border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus\:border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus\:border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus\:border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus\:border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus\:border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus\:border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus\:border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus\:border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus\:border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus\:border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus\:border-vivid-blue:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus\:border-vivid-blue-500:focus {
  border-color: rgba(144, 150, 246, 0.5);
}
.focus\:border-background-primary:focus {
  border-color: var(--background-primary);
}
.focus\:border-main-background:focus {
  --tw-border-opacity: 1;
  border-color: rgba(18, 23, 41, var(--tw-border-opacity));
}
.focus\:border-dark-blue:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 39, 65, var(--tw-border-opacity));
}
.focus\:border-blue-grey:focus {
  --tw-border-opacity: 1;
  border-color: rgba(47, 58, 92, var(--tw-border-opacity));
}
.focus\:border-text-grey:focus {
  --tw-border-opacity: 1;
  border-color: rgba(164, 167, 193, var(--tw-border-opacity));
}
.focus\:border-pale-grey-two:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 235, 255, var(--tw-border-opacity));
}
.focus\:border-pale-grey:focus {
  --tw-border-opacity: 1;
  border-color: rgba(168, 175, 204, var(--tw-border-opacity));
}
.focus\:border-btc:focus {
  --tw-border-opacity: 1;
  border-color: rgba(247, 147, 26, var(--tw-border-opacity));
}
.focus\:border-btc-symbol:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 179, 0, var(--tw-border-opacity));
}
.focus\:border-paxg:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 197, 78, var(--tw-border-opacity));
}
.focus\:border-usdc:focus {
  --tw-border-opacity: 1;
  border-color: rgba(39, 117, 202, var(--tw-border-opacity));
}
.focus\:border-black-navy:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 18, 57, var(--tw-border-opacity));
}
.focus\:border-ghost-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(246, 245, 255, var(--tw-border-opacity));
}
.focus\:border-spring-green:focus {
  --tw-border-opacity: 1;
  border-color: rgba(27, 229, 109, var(--tw-border-opacity));
}
.focus\:border-light-slate-gray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(136, 139, 148, var(--tw-border-opacity));
}
.focus\:border-body-background:focus {
  border-color: var(--body-background);
}
.focus\:border-background:focus {
  border-color: var(--background);
}
.focus\:border-input-background:focus {
  border-color: var(--input-background);
}
.focus\:border-body-text:focus {
  border-color: var(--body-text);
}
.focus\:border-header-text:focus {
  border-color: var(--header-text);
}
.focus\:border-primary:focus {
  border-color: var(--primary);
}
.focus\:border-secondary:focus {
  border-color: var(--secondary);
}
.focus\:border-tertiary:focus {
  border-color: var(--tertiary);
}
.focus\:border-primary-hover:focus {
  border-color: var(--primary-hover);
}
.focus\:border-secondary-hover:focus {
  border-color: var(--secondary-hover);
}
.focus\:border-tertiary-hover:focus {
  border-color: var(--tertiary-hover);
}
.focus\:border-input-text:focus {
  border-color: var(--input-text);
}
.focus\:border-success:focus {
  border-color: var(--success);
}
.focus\:border-error:focus {
  border-color: var(--error);
}
.focus\:border-custom-loader:focus {
  border-color: var(--custom-loader);
}
.focus\:border-violet:focus {
  --tw-border-opacity: 1;
  border-color: rgba(148, 33, 238, var(--tw-border-opacity));
}
.focus\:border-violet-hover:focus {
  --tw-border-opacity: 1;
  border-color: rgba(85, 11, 142, var(--tw-border-opacity));
}
.focus\:border-skeleton-loader-from:focus {
  border-color: var(--skeleton-loader-from);
}
.focus\:border-skeleton-loader-to:focus {
  border-color: var(--skeleton-loader-to);
}
.focus\:border-background50:focus {
  border-color: var(--background50);
}
.focus\:border-reward-blue-start:focus {
  --tw-border-opacity: 1;
  border-color: rgba(98, 107, 255, var(--tw-border-opacity));
}
.focus\:border-reward-blue-end:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus\:border-reward-silver-start:focus {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 72, var(--tw-border-opacity));
}
.focus\:border-reward-silver-end:focus {
  --tw-border-opacity: 1;
  border-color: rgba(172, 172, 172, var(--tw-border-opacity));
}
.focus\:border-reward-gold-start:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 223, 138, var(--tw-border-opacity));
}
.focus\:border-reward-gold-end:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 196, 37, var(--tw-border-opacity));
}
.focus\:border-reward-platinum-start:focus {
  --tw-border-opacity: 1;
  border-color: rgba(149, 149, 149, var(--tw-border-opacity));
}
.focus\:border-reward-platinum-end:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 248, 248, var(--tw-border-opacity));
}
.focus\:border-reward-vip-start:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 46, 238, var(--tw-border-opacity));
}
.focus\:border-reward-vip-end:focus {
  --tw-border-opacity: 1;
  border-color: rgba(197, 33, 238, var(--tw-border-opacity));
}
.focus\:border-fee-breakdown-bg:focus {
  --tw-border-opacity: 1;
  border-color: rgba(24, 33, 57, var(--tw-border-opacity));
}
.focus\:border-light-grey:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus\:border-disabled-button-background:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 42, 152, var(--tw-border-opacity));
}
.focus\:border-disabled-button-text:focus {
  --tw-border-opacity: 1;
  border-color: rgba(142, 147, 160, var(--tw-border-opacity));
}
.border-opacity-0 {
  --tw-border-opacity: 0;
}
.border-opacity-5 {
  --tw-border-opacity: 0.05;
}
.border-opacity-10 {
  --tw-border-opacity: 0.1;
}
.border-opacity-20 {
  --tw-border-opacity: 0.2;
}
.border-opacity-25 {
  --tw-border-opacity: 0.25;
}
.border-opacity-30 {
  --tw-border-opacity: 0.3;
}
.border-opacity-40 {
  --tw-border-opacity: 0.4;
}
.border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.border-opacity-60 {
  --tw-border-opacity: 0.6;
}
.border-opacity-70 {
  --tw-border-opacity: 0.7;
}
.border-opacity-75 {
  --tw-border-opacity: 0.75;
}
.border-opacity-80 {
  --tw-border-opacity: 0.8;
}
.border-opacity-90 {
  --tw-border-opacity: 0.9;
}
.border-opacity-95 {
  --tw-border-opacity: 0.95;
}
.border-opacity-100 {
  --tw-border-opacity: 1;
}
.group:hover .group-hover\:border-opacity-0 {
  --tw-border-opacity: 0;
}
.group:hover .group-hover\:border-opacity-5 {
  --tw-border-opacity: 0.05;
}
.group:hover .group-hover\:border-opacity-10 {
  --tw-border-opacity: 0.1;
}
.group:hover .group-hover\:border-opacity-20 {
  --tw-border-opacity: 0.2;
}
.group:hover .group-hover\:border-opacity-25 {
  --tw-border-opacity: 0.25;
}
.group:hover .group-hover\:border-opacity-30 {
  --tw-border-opacity: 0.3;
}
.group:hover .group-hover\:border-opacity-40 {
  --tw-border-opacity: 0.4;
}
.group:hover .group-hover\:border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.group:hover .group-hover\:border-opacity-60 {
  --tw-border-opacity: 0.6;
}
.group:hover .group-hover\:border-opacity-70 {
  --tw-border-opacity: 0.7;
}
.group:hover .group-hover\:border-opacity-75 {
  --tw-border-opacity: 0.75;
}
.group:hover .group-hover\:border-opacity-80 {
  --tw-border-opacity: 0.8;
}
.group:hover .group-hover\:border-opacity-90 {
  --tw-border-opacity: 0.9;
}
.group:hover .group-hover\:border-opacity-95 {
  --tw-border-opacity: 0.95;
}
.group:hover .group-hover\:border-opacity-100 {
  --tw-border-opacity: 1;
}
.focus-within\:border-opacity-0:focus-within {
  --tw-border-opacity: 0;
}
.focus-within\:border-opacity-5:focus-within {
  --tw-border-opacity: 0.05;
}
.focus-within\:border-opacity-10:focus-within {
  --tw-border-opacity: 0.1;
}
.focus-within\:border-opacity-20:focus-within {
  --tw-border-opacity: 0.2;
}
.focus-within\:border-opacity-25:focus-within {
  --tw-border-opacity: 0.25;
}
.focus-within\:border-opacity-30:focus-within {
  --tw-border-opacity: 0.3;
}
.focus-within\:border-opacity-40:focus-within {
  --tw-border-opacity: 0.4;
}
.focus-within\:border-opacity-50:focus-within {
  --tw-border-opacity: 0.5;
}
.focus-within\:border-opacity-60:focus-within {
  --tw-border-opacity: 0.6;
}
.focus-within\:border-opacity-70:focus-within {
  --tw-border-opacity: 0.7;
}
.focus-within\:border-opacity-75:focus-within {
  --tw-border-opacity: 0.75;
}
.focus-within\:border-opacity-80:focus-within {
  --tw-border-opacity: 0.8;
}
.focus-within\:border-opacity-90:focus-within {
  --tw-border-opacity: 0.9;
}
.focus-within\:border-opacity-95:focus-within {
  --tw-border-opacity: 0.95;
}
.focus-within\:border-opacity-100:focus-within {
  --tw-border-opacity: 1;
}
.hover\:border-opacity-0:hover {
  --tw-border-opacity: 0;
}
.hover\:border-opacity-5:hover {
  --tw-border-opacity: 0.05;
}
.hover\:border-opacity-10:hover {
  --tw-border-opacity: 0.1;
}
.hover\:border-opacity-20:hover {
  --tw-border-opacity: 0.2;
}
.hover\:border-opacity-25:hover {
  --tw-border-opacity: 0.25;
}
.hover\:border-opacity-30:hover {
  --tw-border-opacity: 0.3;
}
.hover\:border-opacity-40:hover {
  --tw-border-opacity: 0.4;
}
.hover\:border-opacity-50:hover {
  --tw-border-opacity: 0.5;
}
.hover\:border-opacity-60:hover {
  --tw-border-opacity: 0.6;
}
.hover\:border-opacity-70:hover {
  --tw-border-opacity: 0.7;
}
.hover\:border-opacity-75:hover {
  --tw-border-opacity: 0.75;
}
.hover\:border-opacity-80:hover {
  --tw-border-opacity: 0.8;
}
.hover\:border-opacity-90:hover {
  --tw-border-opacity: 0.9;
}
.hover\:border-opacity-95:hover {
  --tw-border-opacity: 0.95;
}
.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1;
}
.focus\:border-opacity-0:focus {
  --tw-border-opacity: 0;
}
.focus\:border-opacity-5:focus {
  --tw-border-opacity: 0.05;
}
.focus\:border-opacity-10:focus {
  --tw-border-opacity: 0.1;
}
.focus\:border-opacity-20:focus {
  --tw-border-opacity: 0.2;
}
.focus\:border-opacity-25:focus {
  --tw-border-opacity: 0.25;
}
.focus\:border-opacity-30:focus {
  --tw-border-opacity: 0.3;
}
.focus\:border-opacity-40:focus {
  --tw-border-opacity: 0.4;
}
.focus\:border-opacity-50:focus {
  --tw-border-opacity: 0.5;
}
.focus\:border-opacity-60:focus {
  --tw-border-opacity: 0.6;
}
.focus\:border-opacity-70:focus {
  --tw-border-opacity: 0.7;
}
.focus\:border-opacity-75:focus {
  --tw-border-opacity: 0.75;
}
.focus\:border-opacity-80:focus {
  --tw-border-opacity: 0.8;
}
.focus\:border-opacity-90:focus {
  --tw-border-opacity: 0.9;
}
.focus\:border-opacity-95:focus {
  --tw-border-opacity: 0.95;
}
.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}
.rounded-none {
  border-radius: 0;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-card {
  border-radius: 20px;
}
.rounded-time-item {
  border-radius: 100px;
}
.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-t-sm {
  border-top-left-radius: 0.125rem;
}
.rounded-r-sm,
.rounded-t-sm {
  border-top-right-radius: 0.125rem;
}
.rounded-b-sm,
.rounded-r-sm {
  border-bottom-right-radius: 0.125rem;
}
.rounded-b-sm,
.rounded-l-sm {
  border-bottom-left-radius: 0.125rem;
}
.rounded-l-sm {
  border-top-left-radius: 0.125rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
}
.rounded-r,
.rounded-t {
  border-top-right-radius: 0.25rem;
}
.rounded-b,
.rounded-r {
  border-bottom-right-radius: 0.25rem;
}
.rounded-b,
.rounded-l {
  border-bottom-left-radius: 0.25rem;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem;
}
.rounded-r-md,
.rounded-t-md {
  border-top-right-radius: 0.375rem;
}
.rounded-b-md,
.rounded-r-md {
  border-bottom-right-radius: 0.375rem;
}
.rounded-b-md,
.rounded-l-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-l-md {
  border-top-left-radius: 0.375rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-r-lg,
.rounded-t-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-b-lg,
.rounded-r-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-b-lg,
.rounded-l-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-l-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
}
.rounded-r-xl,
.rounded-t-xl {
  border-top-right-radius: 0.75rem;
}
.rounded-b-xl,
.rounded-r-xl {
  border-bottom-right-radius: 0.75rem;
}
.rounded-b-xl,
.rounded-l-xl {
  border-bottom-left-radius: 0.75rem;
}
.rounded-l-xl {
  border-top-left-radius: 0.75rem;
}
.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.rounded-r-2xl {
  border-top-right-radius: 1rem;
}
.rounded-b-2xl,
.rounded-r-2xl {
  border-bottom-right-radius: 1rem;
}
.rounded-b-2xl,
.rounded-l-2xl {
  border-bottom-left-radius: 1rem;
}
.rounded-l-2xl {
  border-top-left-radius: 1rem;
}
.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
}
.rounded-r-3xl,
.rounded-t-3xl {
  border-top-right-radius: 1.5rem;
}
.rounded-b-3xl,
.rounded-r-3xl {
  border-bottom-right-radius: 1.5rem;
}
.rounded-b-3xl,
.rounded-l-3xl {
  border-bottom-left-radius: 1.5rem;
}
.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
}
.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}
.rounded-r-full {
  border-top-right-radius: 9999px;
}
.rounded-b-full,
.rounded-r-full {
  border-bottom-right-radius: 9999px;
}
.rounded-b-full,
.rounded-l-full {
  border-bottom-left-radius: 9999px;
}
.rounded-l-full {
  border-top-left-radius: 9999px;
}
.rounded-t-card {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.rounded-r-card {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rounded-b-card {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rounded-l-card {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rounded-t-time-item {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.rounded-r-time-item {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.rounded-b-time-item {
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.rounded-l-time-item {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.rounded-tl-none {
  border-top-left-radius: 0;
}
.rounded-tr-none {
  border-top-right-radius: 0;
}
.rounded-br-none {
  border-bottom-right-radius: 0;
}
.rounded-bl-none {
  border-bottom-left-radius: 0;
}
.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}
.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}
.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}
.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}
.rounded-tl {
  border-top-left-radius: 0.25rem;
}
.rounded-tr {
  border-top-right-radius: 0.25rem;
}
.rounded-br {
  border-bottom-right-radius: 0.25rem;
}
.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}
.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}
.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}
.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}
.rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}
.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}
.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}
.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}
.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}
.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}
.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}
.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}
.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}
.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}
.rounded-tl-full {
  border-top-left-radius: 9999px;
}
.rounded-tr-full {
  border-top-right-radius: 9999px;
}
.rounded-br-full {
  border-bottom-right-radius: 9999px;
}
.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}
.rounded-tl-card {
  border-top-left-radius: 20px;
}
.rounded-tr-card {
  border-top-right-radius: 20px;
}
.rounded-br-card {
  border-bottom-right-radius: 20px;
}
.rounded-bl-card {
  border-bottom-left-radius: 20px;
}
.rounded-tl-time-item {
  border-top-left-radius: 100px;
}
.rounded-tr-time-item {
  border-top-right-radius: 100px;
}
.rounded-br-time-item {
  border-bottom-right-radius: 100px;
}
.rounded-bl-time-item {
  border-bottom-left-radius: 100px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-double {
  border-style: double;
}
.border-none {
  border-style: none;
}
.border-0 {
  border-width: 0;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border {
  border-width: 1px;
}
.border-t-0 {
  border-top-width: 0;
}
.border-r-0 {
  border-right-width: 0;
}
.border-b-0 {
  border-bottom-width: 0;
}
.border-l-0 {
  border-left-width: 0;
}
.border-t-2 {
  border-top-width: 2px;
}
.border-r-2 {
  border-right-width: 2px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-t-4 {
  border-top-width: 4px;
}
.border-r-4 {
  border-right-width: 4px;
}
.border-b-4 {
  border-bottom-width: 4px;
}
.border-l-4 {
  border-left-width: 4px;
}
.border-t-8 {
  border-top-width: 8px;
}
.border-r-8 {
  border-right-width: 8px;
}
.border-b-8 {
  border-bottom-width: 8px;
}
.border-l-8 {
  border-left-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.box-border {
  box-sizing: border-box;
}
.box-content {
  box-sizing: content-box;
}
.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.table {
  display: table;
}
.table-caption {
  display: table-caption;
}
.table-cell {
  display: table-cell;
}
.table-column {
  display: table-column;
}
.table-column-group {
  display: table-column-group;
}
.table-footer-group {
  display: table-footer-group;
}
.table-header-group {
  display: table-header-group;
}
.table-row-group {
  display: table-row-group;
}
.table-row {
  display: table-row;
}
.flow-root {
  display: flow-root;
}
.grid {
  display: grid;
}
.inline-grid {
  display: inline-grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.flex-row {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.flex-col {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-col-reverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.place-items-auto {
  place-items: auto;
}
.place-items-start {
  place-items: start;
}
.place-items-end {
  place-items: end;
}
.place-items-center {
  place-items: center;
}
.place-items-stretch {
  place-items: stretch;
}
.place-content-center {
  place-content: center;
}
.place-content-start {
  place-content: start;
}
.place-content-end {
  place-content: end;
}
.place-content-between {
  place-content: space-between;
}
.place-content-around {
  place-content: space-around;
}
.place-content-evenly {
  place-content: space-evenly;
}
.place-content-stretch {
  place-content: stretch;
}
.place-self-auto {
  place-self: auto;
}
.place-self-start {
  place-self: start;
}
.place-self-end {
  place-self: end;
}
.place-self-center {
  place-self: center;
}
.place-self-stretch {
  place-self: stretch;
}
.items-start {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.items-end {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.items-center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.items-baseline {
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.items-stretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}
.content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.content-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}
.content-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}
.content-evenly {
  -ms-flex-line-pack: space-evenly;
  -webkit-align-content: space-evenly;
  align-content: space-evenly;
}
.self-auto {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.justify-items-auto {
  justify-items: auto;
}
.justify-items-start {
  justify-items: start;
}
.justify-items-end {
  justify-items: end;
}
.justify-items-center {
  justify-items: center;
}
.justify-items-stretch {
  justify-items: stretch;
}
.justify-start {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.justify-evenly {
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.justify-self-auto {
  justify-self: auto;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-end {
  justify-self: end;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-stretch {
  justify-self: stretch;
}
.flex-1 {
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1;
}
.flex-auto {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-initial {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.flex-none {
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.flex-grow-0 {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.flex-grow {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.order-1 {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12;
}
.order-first {
  -webkit-order: -9999;
  -ms-flex-order: -9999;
  order: -9999;
}
.order-last {
  -webkit-order: 9999;
  -ms-flex-order: 9999;
  order: 9999;
}
.order-none {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.float-none {
  float: none;
}
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}
.clear-both {
  clear: both;
}
.clear-none {
  clear: none;
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.font-serif {
  font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
}
.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
.font-numbers {
  font-family: Roboto, sans-serif;
}
.font-text {
  font-family: Bozon, sans-serif;
}
.font-thin {
  font-weight: 100;
}
.font-extralight {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.hover\:font-thin:hover {
  font-weight: 100;
}
.hover\:font-extralight:hover {
  font-weight: 200;
}
.hover\:font-light:hover {
  font-weight: 300;
}
.hover\:font-normal:hover {
  font-weight: 400;
}
.hover\:font-medium:hover {
  font-weight: 500;
}
.hover\:font-semibold:hover {
  font-weight: 600;
}
.hover\:font-bold:hover {
  font-weight: 700;
}
.hover\:font-extrabold:hover {
  font-weight: 800;
}
.hover\:font-black:hover {
  font-weight: 900;
}
.focus\:font-thin:focus {
  font-weight: 100;
}
.focus\:font-extralight:focus {
  font-weight: 200;
}
.focus\:font-light:focus {
  font-weight: 300;
}
.focus\:font-normal:focus {
  font-weight: 400;
}
.focus\:font-medium:focus {
  font-weight: 500;
}
.focus\:font-semibold:focus {
  font-weight: 600;
}
.focus\:font-bold:focus {
  font-weight: 700;
}
.focus\:font-extrabold:focus {
  font-weight: 800;
}
.focus\:font-black:focus {
  font-weight: 900;
}
.h-0 {
  height: 0;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-28 {
  height: 7rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-52 {
  height: 13rem;
}
.h-56 {
  height: 14rem;
}
.h-60 {
  height: 15rem;
}
.h-64 {
  height: 16rem;
}
.h-72 {
  height: 18rem;
}
.h-80 {
  height: 20rem;
}
.h-96 {
  height: 24rem;
}
.h-105 {
  height: 105px;
}
.h-188 {
  height: 188px;
}
.h-344 {
  height: 344px;
}
.h-425 {
  height: 425px;
}
.h-627 {
  height: 627px;
}
.h-auto {
  height: auto;
}
.h-px {
  height: 1px;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-1\.5 {
  height: 0.375rem;
}
.h-2\.5 {
  height: 0.625rem;
}
.h-3\.5 {
  height: 0.875rem;
}
.h-1\/2 {
  height: 50%;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-1\/4 {
  height: 25%;
}
.h-2\/4 {
  height: 50%;
}
.h-3\/4 {
  height: 75%;
}
.h-1\/5 {
  height: 20%;
}
.h-2\/5 {
  height: 40%;
}
.h-3\/5 {
  height: 60%;
}
.h-4\/5 {
  height: 80%;
}
.h-1\/6 {
  height: 16.666667%;
}
.h-2\/6 {
  height: 33.333333%;
}
.h-3\/6 {
  height: 50%;
}
.h-4\/6 {
  height: 66.666667%;
}
.h-5\/6 {
  height: 83.333333%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1em;
}
.text-lg {
  font-size: 1.125rem;
}
.text-lg,
.text-xl {
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 5rem;
  line-height: 5rem;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}
.text-m-sm {
  font-size: 0.8em;
}
.text-m-lg {
  font-size: 1.0666666666666667em;
}
.text-m-xl {
  font-size: 1.2em;
}
.text-m-2xl {
  font-size: 1.3333333333333333em;
}
.text-m-3xl {
  font-size: 1.4666666666666666em;
}
.text-m-4xl {
  font-size: 1.6em;
}
.text-m-5xl {
  font-size: 2.1333333333333333em;
}
.text-d-sm {
  font-size: 0.875em;
}
.text-d-lg {
  font-size: 1.125em;
}
.text-d-xl {
  font-size: 1.375em;
}
.text-d-2xl {
  font-size: 1.625em;
}
.text-d-3xl {
  font-size: 1.75em;
}
.text-d-4xl {
  font-size: 2.25em;
}
.text-d-5xl {
  font-size: 2.625em;
}
.text-d-11xl {
  font-size: 16em;
}
.text-1\.06x1 {
  font-size: 1.0666666666666667em;
}
.text-1\.46x1 {
  font-size: 1.4666666666666666em;
}
.text-2\.5x1 {
  font-size: 1.75rem;
}
.leading-3 {
  line-height: 0.75rem;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.leading-snug {
  line-height: 1.375;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-loose {
  line-height: 2;
}
.list-inside {
  list-style-position: inside;
}
.list-outside {
  list-style-position: outside;
}
.list-none {
  list-style-type: none;
}
.list-disc {
  list-style-type: disc;
}
.list-decimal {
  list-style-type: decimal;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-7 {
  margin: 1.75rem;
}
.m-8 {
  margin: 2rem;
}
.m-9 {
  margin: 2.25rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-11 {
  margin: 2.75rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-24 {
  margin: 6rem;
}
.m-28 {
  margin: 7rem;
}
.m-32 {
  margin: 8rem;
}
.m-36 {
  margin: 9rem;
}
.m-40 {
  margin: 10rem;
}
.m-44 {
  margin: 11rem;
}
.m-48 {
  margin: 12rem;
}
.m-52 {
  margin: 13rem;
}
.m-56 {
  margin: 14rem;
}
.m-60 {
  margin: 15rem;
}
.m-64 {
  margin: 16rem;
}
.m-72 {
  margin: 18rem;
}
.m-80 {
  margin: 20rem;
}
.m-96 {
  margin: 24rem;
}
.m-auto {
  margin: auto;
}
.m-px {
  margin: 1px;
}
.m-0\.5 {
  margin: 0.125rem;
}
.m-1\.5 {
  margin: 0.375rem;
}
.m-2\.5 {
  margin: 0.625rem;
}
.m-3\.5 {
  margin: 0.875rem;
}
.-m-0 {
  margin: 0;
}
.-m-1 {
  margin: -0.25rem;
}
.-m-2 {
  margin: -0.5rem;
}
.-m-3 {
  margin: -0.75rem;
}
.-m-4 {
  margin: -1rem;
}
.-m-5 {
  margin: -1.25rem;
}
.-m-6 {
  margin: -1.5rem;
}
.-m-7 {
  margin: -1.75rem;
}
.-m-8 {
  margin: -2rem;
}
.-m-9 {
  margin: -2.25rem;
}
.-m-10 {
  margin: -2.5rem;
}
.-m-11 {
  margin: -2.75rem;
}
.-m-12 {
  margin: -3rem;
}
.-m-14 {
  margin: -3.5rem;
}
.-m-16 {
  margin: -4rem;
}
.-m-20 {
  margin: -5rem;
}
.-m-24 {
  margin: -6rem;
}
.-m-28 {
  margin: -7rem;
}
.-m-32 {
  margin: -8rem;
}
.-m-36 {
  margin: -9rem;
}
.-m-40 {
  margin: -10rem;
}
.-m-44 {
  margin: -11rem;
}
.-m-48 {
  margin: -12rem;
}
.-m-52 {
  margin: -13rem;
}
.-m-56 {
  margin: -14rem;
}
.-m-60 {
  margin: -15rem;
}
.-m-64 {
  margin: -16rem;
}
.-m-72 {
  margin: -18rem;
}
.-m-80 {
  margin: -20rem;
}
.-m-96 {
  margin: -24rem;
}
.-m-px {
  margin: -1px;
}
.-m-0\.5 {
  margin: -0.125rem;
}
.-m-1\.5 {
  margin: -0.375rem;
}
.-m-2\.5 {
  margin: -0.625rem;
}
.-m-3\.5 {
  margin: -0.875rem;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}
.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}
.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}
.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}
.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}
.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}
.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}
.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}
.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}
.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}
.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}
.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}
.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}
.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}
.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}
.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}
.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}
.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}
.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}
.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}
.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}
.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}
.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}
.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}
.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}
.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}
.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}
.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}
.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}
.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}
.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}
.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}
.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}
.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}
.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}
.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}
.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}
.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}
.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}
.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}
.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}
.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}
.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}
.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}
.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}
.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}
.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}
.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}
.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}
.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}
.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}
.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}
.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}
.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}
.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}
.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}
.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}
.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}
.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}
.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}
.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}
.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}
.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}
.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}
.-my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}
.-mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}
.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}
.-my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.-my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}
.-mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.ml-7 {
  margin-left: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mr-9 {
  margin-right: 2.25rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.ml-9 {
  margin-left: 2.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.mt-11 {
  margin-top: 2.75rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mb-11 {
  margin-bottom: 2.75rem;
}
.ml-11 {
  margin-left: 2.75rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.ml-12 {
  margin-left: 3rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mr-14 {
  margin-right: 3.5rem;
}
.mb-14 {
  margin-bottom: 3.5rem;
}
.ml-14 {
  margin-left: 3.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.ml-16 {
  margin-left: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.ml-20 {
  margin-left: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mr-24 {
  margin-right: 6rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.ml-24 {
  margin-left: 6rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mr-28 {
  margin-right: 7rem;
}
.mb-28 {
  margin-bottom: 7rem;
}
.ml-28 {
  margin-left: 7rem;
}
.mt-32 {
  margin-top: 8rem;
}
.mr-32 {
  margin-right: 8rem;
}
.mb-32 {
  margin-bottom: 8rem;
}
.ml-32 {
  margin-left: 8rem;
}
.mt-36 {
  margin-top: 9rem;
}
.mr-36 {
  margin-right: 9rem;
}
.mb-36 {
  margin-bottom: 9rem;
}
.ml-36 {
  margin-left: 9rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mr-40 {
  margin-right: 10rem;
}
.mb-40 {
  margin-bottom: 10rem;
}
.ml-40 {
  margin-left: 10rem;
}
.mt-44 {
  margin-top: 11rem;
}
.mr-44 {
  margin-right: 11rem;
}
.mb-44 {
  margin-bottom: 11rem;
}
.ml-44 {
  margin-left: 11rem;
}
.mt-48 {
  margin-top: 12rem;
}
.mr-48 {
  margin-right: 12rem;
}
.mb-48 {
  margin-bottom: 12rem;
}
.ml-48 {
  margin-left: 12rem;
}
.mt-52 {
  margin-top: 13rem;
}
.mr-52 {
  margin-right: 13rem;
}
.mb-52 {
  margin-bottom: 13rem;
}
.ml-52 {
  margin-left: 13rem;
}
.mt-56 {
  margin-top: 14rem;
}
.mr-56 {
  margin-right: 14rem;
}
.mb-56 {
  margin-bottom: 14rem;
}
.ml-56 {
  margin-left: 14rem;
}
.mt-60 {
  margin-top: 15rem;
}
.mr-60 {
  margin-right: 15rem;
}
.mb-60 {
  margin-bottom: 15rem;
}
.ml-60 {
  margin-left: 15rem;
}
.mt-64 {
  margin-top: 16rem;
}
.mr-64 {
  margin-right: 16rem;
}
.mb-64 {
  margin-bottom: 16rem;
}
.ml-64 {
  margin-left: 16rem;
}
.mt-72 {
  margin-top: 18rem;
}
.mr-72 {
  margin-right: 18rem;
}
.mb-72 {
  margin-bottom: 18rem;
}
.ml-72 {
  margin-left: 18rem;
}
.mt-80 {
  margin-top: 20rem;
}
.mr-80 {
  margin-right: 20rem;
}
.mb-80 {
  margin-bottom: 20rem;
}
.ml-80 {
  margin-left: 20rem;
}
.mt-96 {
  margin-top: 24rem;
}
.mr-96 {
  margin-right: 24rem;
}
.mb-96 {
  margin-bottom: 24rem;
}
.ml-96 {
  margin-left: 24rem;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mt-px {
  margin-top: 1px;
}
.mr-px {
  margin-right: 1px;
}
.mb-px {
  margin-bottom: 1px;
}
.ml-px {
  margin-left: 1px;
}
.mt-0\.5 {
  margin-top: 0.125rem;
}
.mr-0\.5 {
  margin-right: 0.125rem;
}
.mb-0\.5 {
  margin-bottom: 0.125rem;
}
.ml-0\.5 {
  margin-left: 0.125rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mr-1\.5 {
  margin-right: 0.375rem;
}
.mb-1\.5 {
  margin-bottom: 0.375rem;
}
.ml-1\.5 {
  margin-left: 0.375rem;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mr-2\.5 {
  margin-right: 0.625rem;
}
.mb-2\.5 {
  margin-bottom: 0.625rem;
}
.ml-2\.5 {
  margin-left: 0.625rem;
}
.mt-3\.5 {
  margin-top: 0.875rem;
}
.mr-3\.5 {
  margin-right: 0.875rem;
}
.mb-3\.5 {
  margin-bottom: 0.875rem;
}
.ml-3\.5 {
  margin-left: 0.875rem;
}
.-mt-0 {
  margin-top: 0;
}
.-mr-0 {
  margin-right: 0;
}
.-mb-0 {
  margin-bottom: 0;
}
.-ml-0 {
  margin-left: 0;
}
.-mt-1 {
  margin-top: -0.25rem;
}
.-mr-1 {
  margin-right: -0.25rem;
}
.-mb-1 {
  margin-bottom: -0.25rem;
}
.-ml-1 {
  margin-left: -0.25rem;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.-mr-2 {
  margin-right: -0.5rem;
}
.-mb-2 {
  margin-bottom: -0.5rem;
}
.-ml-2 {
  margin-left: -0.5rem;
}
.-mt-3 {
  margin-top: -0.75rem;
}
.-mr-3 {
  margin-right: -0.75rem;
}
.-mb-3 {
  margin-bottom: -0.75rem;
}
.-ml-3 {
  margin-left: -0.75rem;
}
.-mt-4 {
  margin-top: -1rem;
}
.-mr-4 {
  margin-right: -1rem;
}
.-mb-4 {
  margin-bottom: -1rem;
}
.-ml-4 {
  margin-left: -1rem;
}
.-mt-5 {
  margin-top: -1.25rem;
}
.-mr-5 {
  margin-right: -1.25rem;
}
.-mb-5 {
  margin-bottom: -1.25rem;
}
.-ml-5 {
  margin-left: -1.25rem;
}
.-mt-6 {
  margin-top: -1.5rem;
}
.-mr-6 {
  margin-right: -1.5rem;
}
.-mb-6 {
  margin-bottom: -1.5rem;
}
.-ml-6 {
  margin-left: -1.5rem;
}
.-mt-7 {
  margin-top: -1.75rem;
}
.-mr-7 {
  margin-right: -1.75rem;
}
.-mb-7 {
  margin-bottom: -1.75rem;
}
.-ml-7 {
  margin-left: -1.75rem;
}
.-mt-8 {
  margin-top: -2rem;
}
.-mr-8 {
  margin-right: -2rem;
}
.-mb-8 {
  margin-bottom: -2rem;
}
.-ml-8 {
  margin-left: -2rem;
}
.-mt-9 {
  margin-top: -2.25rem;
}
.-mr-9 {
  margin-right: -2.25rem;
}
.-mb-9 {
  margin-bottom: -2.25rem;
}
.-ml-9 {
  margin-left: -2.25rem;
}
.-mt-10 {
  margin-top: -2.5rem;
}
.-mr-10 {
  margin-right: -2.5rem;
}
.-mb-10 {
  margin-bottom: -2.5rem;
}
.-ml-10 {
  margin-left: -2.5rem;
}
.-mt-11 {
  margin-top: -2.75rem;
}
.-mr-11 {
  margin-right: -2.75rem;
}
.-mb-11 {
  margin-bottom: -2.75rem;
}
.-ml-11 {
  margin-left: -2.75rem;
}
.-mt-12 {
  margin-top: -3rem;
}
.-mr-12 {
  margin-right: -3rem;
}
.-mb-12 {
  margin-bottom: -3rem;
}
.-ml-12 {
  margin-left: -3rem;
}
.-mt-14 {
  margin-top: -3.5rem;
}
.-mr-14 {
  margin-right: -3.5rem;
}
.-mb-14 {
  margin-bottom: -3.5rem;
}
.-ml-14 {
  margin-left: -3.5rem;
}
.-mt-16 {
  margin-top: -4rem;
}
.-mr-16 {
  margin-right: -4rem;
}
.-mb-16 {
  margin-bottom: -4rem;
}
.-ml-16 {
  margin-left: -4rem;
}
.-mt-20 {
  margin-top: -5rem;
}
.-mr-20 {
  margin-right: -5rem;
}
.-mb-20 {
  margin-bottom: -5rem;
}
.-ml-20 {
  margin-left: -5rem;
}
.-mt-24 {
  margin-top: -6rem;
}
.-mr-24 {
  margin-right: -6rem;
}
.-mb-24 {
  margin-bottom: -6rem;
}
.-ml-24 {
  margin-left: -6rem;
}
.-mt-28 {
  margin-top: -7rem;
}
.-mr-28 {
  margin-right: -7rem;
}
.-mb-28 {
  margin-bottom: -7rem;
}
.-ml-28 {
  margin-left: -7rem;
}
.-mt-32 {
  margin-top: -8rem;
}
.-mr-32 {
  margin-right: -8rem;
}
.-mb-32 {
  margin-bottom: -8rem;
}
.-ml-32 {
  margin-left: -8rem;
}
.-mt-36 {
  margin-top: -9rem;
}
.-mr-36 {
  margin-right: -9rem;
}
.-mb-36 {
  margin-bottom: -9rem;
}
.-ml-36 {
  margin-left: -9rem;
}
.-mt-40 {
  margin-top: -10rem;
}
.-mr-40 {
  margin-right: -10rem;
}
.-mb-40 {
  margin-bottom: -10rem;
}
.-ml-40 {
  margin-left: -10rem;
}
.-mt-44 {
  margin-top: -11rem;
}
.-mr-44 {
  margin-right: -11rem;
}
.-mb-44 {
  margin-bottom: -11rem;
}
.-ml-44 {
  margin-left: -11rem;
}
.-mt-48 {
  margin-top: -12rem;
}
.-mr-48 {
  margin-right: -12rem;
}
.-mb-48 {
  margin-bottom: -12rem;
}
.-ml-48 {
  margin-left: -12rem;
}
.-mt-52 {
  margin-top: -13rem;
}
.-mr-52 {
  margin-right: -13rem;
}
.-mb-52 {
  margin-bottom: -13rem;
}
.-ml-52 {
  margin-left: -13rem;
}
.-mt-56 {
  margin-top: -14rem;
}
.-mr-56 {
  margin-right: -14rem;
}
.-mb-56 {
  margin-bottom: -14rem;
}
.-ml-56 {
  margin-left: -14rem;
}
.-mt-60 {
  margin-top: -15rem;
}
.-mr-60 {
  margin-right: -15rem;
}
.-mb-60 {
  margin-bottom: -15rem;
}
.-ml-60 {
  margin-left: -15rem;
}
.-mt-64 {
  margin-top: -16rem;
}
.-mr-64 {
  margin-right: -16rem;
}
.-mb-64 {
  margin-bottom: -16rem;
}
.-ml-64 {
  margin-left: -16rem;
}
.-mt-72 {
  margin-top: -18rem;
}
.-mr-72 {
  margin-right: -18rem;
}
.-mb-72 {
  margin-bottom: -18rem;
}
.-ml-72 {
  margin-left: -18rem;
}
.-mt-80 {
  margin-top: -20rem;
}
.-mr-80 {
  margin-right: -20rem;
}
.-mb-80 {
  margin-bottom: -20rem;
}
.-ml-80 {
  margin-left: -20rem;
}
.-mt-96 {
  margin-top: -24rem;
}
.-mr-96 {
  margin-right: -24rem;
}
.-mb-96 {
  margin-bottom: -24rem;
}
.-ml-96 {
  margin-left: -24rem;
}
.-mt-px {
  margin-top: -1px;
}
.-mr-px {
  margin-right: -1px;
}
.-mb-px {
  margin-bottom: -1px;
}
.-ml-px {
  margin-left: -1px;
}
.-mt-0\.5 {
  margin-top: -0.125rem;
}
.-mr-0\.5 {
  margin-right: -0.125rem;
}
.-mb-0\.5 {
  margin-bottom: -0.125rem;
}
.-ml-0\.5 {
  margin-left: -0.125rem;
}
.-mt-1\.5 {
  margin-top: -0.375rem;
}
.-mr-1\.5 {
  margin-right: -0.375rem;
}
.-mb-1\.5 {
  margin-bottom: -0.375rem;
}
.-ml-1\.5 {
  margin-left: -0.375rem;
}
.-mt-2\.5 {
  margin-top: -0.625rem;
}
.-mr-2\.5 {
  margin-right: -0.625rem;
}
.-mb-2\.5 {
  margin-bottom: -0.625rem;
}
.-ml-2\.5 {
  margin-left: -0.625rem;
}
.-mt-3\.5 {
  margin-top: -0.875rem;
}
.-mr-3\.5 {
  margin-right: -0.875rem;
}
.-mb-3\.5 {
  margin-bottom: -0.875rem;
}
.-ml-3\.5 {
  margin-left: -0.875rem;
}
.max-h-0 {
  max-height: 0;
}
.max-h-1 {
  max-height: 0.25rem;
}
.max-h-2 {
  max-height: 0.5rem;
}
.max-h-3 {
  max-height: 0.75rem;
}
.max-h-4 {
  max-height: 1rem;
}
.max-h-5 {
  max-height: 1.25rem;
}
.max-h-6 {
  max-height: 1.5rem;
}
.max-h-7 {
  max-height: 1.75rem;
}
.max-h-8 {
  max-height: 2rem;
}
.max-h-9 {
  max-height: 2.25rem;
}
.max-h-10 {
  max-height: 2.5rem;
}
.max-h-11 {
  max-height: 2.75rem;
}
.max-h-12 {
  max-height: 3rem;
}
.max-h-14 {
  max-height: 3.5rem;
}
.max-h-16 {
  max-height: 4rem;
}
.max-h-20 {
  max-height: 5rem;
}
.max-h-24 {
  max-height: 6rem;
}
.max-h-28 {
  max-height: 7rem;
}
.max-h-32 {
  max-height: 8rem;
}
.max-h-36 {
  max-height: 9rem;
}
.max-h-40 {
  max-height: 10rem;
}
.max-h-44 {
  max-height: 11rem;
}
.max-h-48 {
  max-height: 12rem;
}
.max-h-52 {
  max-height: 13rem;
}
.max-h-56 {
  max-height: 14rem;
}
.max-h-60 {
  max-height: 15rem;
}
.max-h-64 {
  max-height: 16rem;
}
.max-h-72 {
  max-height: 18rem;
}
.max-h-80 {
  max-height: 20rem;
}
.max-h-96 {
  max-height: 24rem;
}
.max-h-px {
  max-height: 1px;
}
.max-h-0\.5 {
  max-height: 0.125rem;
}
.max-h-1\.5 {
  max-height: 0.375rem;
}
.max-h-2\.5 {
  max-height: 0.625rem;
}
.max-h-3\.5 {
  max-height: 0.875rem;
}
.max-h-full {
  max-height: 100%;
}
.max-h-screen {
  max-height: 100vh;
}
.max-h-1\/4 {
  max-height: 25%;
}
.max-h-1\/2 {
  max-height: 50%;
}
.max-h-3\/4 {
  max-height: 75%;
}
.max-h-10\/12 {
  max-height: 80%;
}
.max-h-enormous {
  max-height: 4000px;
}
.max-w-0 {
  max-width: 0;
}
.max-w-110 {
  max-width: 110px;
}
.max-w-473 {
  max-width: 473px;
}
.max-w-none {
  max-width: none;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-5xl {
  max-width: 64rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-min {
  max-width: -webkit-min-content;
  max-width: min-content;
}
.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}
.max-w-prose {
  max-width: 65ch;
}
.max-w-screen-sm {
  max-width: 640px;
}
.max-w-screen-md {
  max-width: 768px;
}
.max-w-screen-lg {
  max-width: 1024px;
}
.max-w-screen-xl {
  max-width: 1280px;
}
.max-w-screen-2xl {
  max-width: 1536px;
}
.max-w-screen-xxs {
  max-width: 260px;
}
.max-w-screen-xs {
  max-width: 360px;
}
.max-w-screen-xxl {
  max-width: 1440px;
}
.min-h-0 {
  min-height: 0;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.min-w-0 {
  min-width: 0;
}
.min-w-10 {
  min-width: 2.5rem;
}
.min-w-12 {
  min-width: 3rem;
}
.min-w-14 {
  min-width: 3.5rem;
}
.min-w-full {
  min-width: 100%;
}
.min-w-min {
  min-width: -webkit-min-content;
  min-width: min-content;
}
.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}
.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.object-fill {
  object-fit: fill;
}
.object-none {
  object-fit: none;
}
.object-scale-down {
  object-fit: scale-down;
}
.object-bottom {
  object-position: bottom;
}
.object-center {
  object-position: center;
}
.object-left {
  object-position: left;
}
.object-left-bottom {
  object-position: left bottom;
}
.object-left-top {
  object-position: left top;
}
.object-right {
  object-position: right;
}
.object-right-bottom {
  object-position: right bottom;
}
.object-right-top {
  object-position: right top;
}
.object-top {
  object-position: top;
}
.opacity-0 {
  opacity: 0;
}
.opacity-5 {
  opacity: 0.05;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-95 {
  opacity: 0.95;
}
.opacity-100 {
  opacity: 1;
}
.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}
.group:hover .group-hover\:opacity-5 {
  opacity: 0.05;
}
.group:hover .group-hover\:opacity-10 {
  opacity: 0.1;
}
.group:hover .group-hover\:opacity-20 {
  opacity: 0.2;
}
.group:hover .group-hover\:opacity-25 {
  opacity: 0.25;
}
.group:hover .group-hover\:opacity-30 {
  opacity: 0.3;
}
.group:hover .group-hover\:opacity-40 {
  opacity: 0.4;
}
.group:hover .group-hover\:opacity-50 {
  opacity: 0.5;
}
.group:hover .group-hover\:opacity-60 {
  opacity: 0.6;
}
.group:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}
.group:hover .group-hover\:opacity-75 {
  opacity: 0.75;
}
.group:hover .group-hover\:opacity-80 {
  opacity: 0.8;
}
.group:hover .group-hover\:opacity-90 {
  opacity: 0.9;
}
.group:hover .group-hover\:opacity-95 {
  opacity: 0.95;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.focus-within\:opacity-0:focus-within {
  opacity: 0;
}
.focus-within\:opacity-5:focus-within {
  opacity: 0.05;
}
.focus-within\:opacity-10:focus-within {
  opacity: 0.1;
}
.focus-within\:opacity-20:focus-within {
  opacity: 0.2;
}
.focus-within\:opacity-25:focus-within {
  opacity: 0.25;
}
.focus-within\:opacity-30:focus-within {
  opacity: 0.3;
}
.focus-within\:opacity-40:focus-within {
  opacity: 0.4;
}
.focus-within\:opacity-50:focus-within {
  opacity: 0.5;
}
.focus-within\:opacity-60:focus-within {
  opacity: 0.6;
}
.focus-within\:opacity-70:focus-within {
  opacity: 0.7;
}
.focus-within\:opacity-75:focus-within {
  opacity: 0.75;
}
.focus-within\:opacity-80:focus-within {
  opacity: 0.8;
}
.focus-within\:opacity-90:focus-within {
  opacity: 0.9;
}
.focus-within\:opacity-95:focus-within {
  opacity: 0.95;
}
.focus-within\:opacity-100:focus-within {
  opacity: 1;
}
.hover\:opacity-0:hover {
  opacity: 0;
}
.hover\:opacity-5:hover {
  opacity: 0.05;
}
.hover\:opacity-10:hover {
  opacity: 0.1;
}
.hover\:opacity-20:hover {
  opacity: 0.2;
}
.hover\:opacity-25:hover {
  opacity: 0.25;
}
.hover\:opacity-30:hover {
  opacity: 0.3;
}
.hover\:opacity-40:hover {
  opacity: 0.4;
}
.hover\:opacity-50:hover {
  opacity: 0.5;
}
.hover\:opacity-60:hover {
  opacity: 0.6;
}
.hover\:opacity-70:hover {
  opacity: 0.7;
}
.hover\:opacity-75:hover {
  opacity: 0.75;
}
.hover\:opacity-80:hover {
  opacity: 0.8;
}
.hover\:opacity-90:hover {
  opacity: 0.9;
}
.hover\:opacity-95:hover {
  opacity: 0.95;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.focus\:opacity-0:focus {
  opacity: 0;
}
.focus\:opacity-5:focus {
  opacity: 0.05;
}
.focus\:opacity-10:focus {
  opacity: 0.1;
}
.focus\:opacity-20:focus {
  opacity: 0.2;
}
.focus\:opacity-25:focus {
  opacity: 0.25;
}
.focus\:opacity-30:focus {
  opacity: 0.3;
}
.focus\:opacity-40:focus {
  opacity: 0.4;
}
.focus\:opacity-50:focus {
  opacity: 0.5;
}
.focus\:opacity-60:focus {
  opacity: 0.6;
}
.focus\:opacity-70:focus {
  opacity: 0.7;
}
.focus\:opacity-75:focus {
  opacity: 0.75;
}
.focus\:opacity-80:focus {
  opacity: 0.8;
}
.focus\:opacity-90:focus {
  opacity: 0.9;
}
.focus\:opacity-95:focus {
  opacity: 0.95;
}
.focus\:opacity-100:focus {
  opacity: 1;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-white {
  outline: 2px dotted #fff;
  outline-offset: 2px;
}
.outline-black {
  outline: 2px dotted #000;
  outline-offset: 2px;
}
.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-within\:outline-white:focus-within {
  outline: 2px dotted #fff;
  outline-offset: 2px;
}
.focus-within\:outline-black:focus-within {
  outline: 2px dotted #000;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-white:focus {
  outline: 2px dotted #fff;
  outline-offset: 2px;
}
.focus\:outline-black:focus {
  outline: 2px dotted #000;
  outline-offset: 2px;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overscroll-auto {
  -ms-scroll-chaining: chained;
  overscroll-behavior: auto;
}
.overscroll-contain {
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}
.overscroll-none {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}
.overscroll-y-auto {
  overscroll-behavior-y: auto;
}
.overscroll-y-contain {
  overscroll-behavior-y: contain;
}
.overscroll-y-none {
  overscroll-behavior-y: none;
}
.overscroll-x-auto {
  overscroll-behavior-x: auto;
}
.overscroll-x-contain {
  overscroll-behavior-x: contain;
}
.overscroll-x-none {
  overscroll-behavior-x: none;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-9 {
  padding: 2.25rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-11 {
  padding: 2.75rem;
}
.p-12 {
  padding: 3rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}
.p-24 {
  padding: 6rem;
}
.p-28 {
  padding: 7rem;
}
.p-32 {
  padding: 8rem;
}
.p-36 {
  padding: 9rem;
}
.p-40 {
  padding: 10rem;
}
.p-44 {
  padding: 11rem;
}
.p-48 {
  padding: 12rem;
}
.p-52 {
  padding: 13rem;
}
.p-56 {
  padding: 14rem;
}
.p-60 {
  padding: 15rem;
}
.p-64 {
  padding: 16rem;
}
.p-72 {
  padding: 18rem;
}
.p-80 {
  padding: 20rem;
}
.p-96 {
  padding: 24rem;
}
.p-px {
  padding: 1px;
}
.p-0\.5 {
  padding: 0.125rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3\.5 {
  padding: 0.875rem;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}
.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}
.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}
.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}
.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}
.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}
.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}
.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}
.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}
.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}
.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}
.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}
.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}
.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}
.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}
.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}
.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}
.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pb-7 {
  padding-bottom: 1.75rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pt-9 {
  padding-top: 2.25rem;
}
.pr-9 {
  padding-right: 2.25rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pt-11 {
  padding-top: 2.75rem;
}
.pr-11 {
  padding-right: 2.75rem;
}
.pb-11 {
  padding-bottom: 2.75rem;
}
.pl-11 {
  padding-left: 2.75rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pr-14 {
  padding-right: 3.5rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pl-14 {
  padding-left: 3.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pr-24 {
  padding-right: 6rem;
}
.pb-24 {
  padding-bottom: 6rem;
}
.pl-24 {
  padding-left: 6rem;
}
.pt-28 {
  padding-top: 7rem;
}
.pr-28 {
  padding-right: 7rem;
}
.pb-28 {
  padding-bottom: 7rem;
}
.pl-28 {
  padding-left: 7rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pr-32 {
  padding-right: 8rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pl-32 {
  padding-left: 8rem;
}
.pt-36 {
  padding-top: 9rem;
}
.pr-36 {
  padding-right: 9rem;
}
.pb-36 {
  padding-bottom: 9rem;
}
.pl-36 {
  padding-left: 9rem;
}
.pt-40 {
  padding-top: 10rem;
}
.pr-40 {
  padding-right: 10rem;
}
.pb-40 {
  padding-bottom: 10rem;
}
.pl-40 {
  padding-left: 10rem;
}
.pt-44 {
  padding-top: 11rem;
}
.pr-44 {
  padding-right: 11rem;
}
.pb-44 {
  padding-bottom: 11rem;
}
.pl-44 {
  padding-left: 11rem;
}
.pt-48 {
  padding-top: 12rem;
}
.pr-48 {
  padding-right: 12rem;
}
.pb-48 {
  padding-bottom: 12rem;
}
.pl-48 {
  padding-left: 12rem;
}
.pt-52 {
  padding-top: 13rem;
}
.pr-52 {
  padding-right: 13rem;
}
.pb-52 {
  padding-bottom: 13rem;
}
.pl-52 {
  padding-left: 13rem;
}
.pt-56 {
  padding-top: 14rem;
}
.pr-56 {
  padding-right: 14rem;
}
.pb-56 {
  padding-bottom: 14rem;
}
.pl-56 {
  padding-left: 14rem;
}
.pt-60 {
  padding-top: 15rem;
}
.pr-60 {
  padding-right: 15rem;
}
.pb-60 {
  padding-bottom: 15rem;
}
.pl-60 {
  padding-left: 15rem;
}
.pt-64 {
  padding-top: 16rem;
}
.pr-64 {
  padding-right: 16rem;
}
.pb-64 {
  padding-bottom: 16rem;
}
.pl-64 {
  padding-left: 16rem;
}
.pt-72 {
  padding-top: 18rem;
}
.pr-72 {
  padding-right: 18rem;
}
.pb-72 {
  padding-bottom: 18rem;
}
.pl-72 {
  padding-left: 18rem;
}
.pt-80 {
  padding-top: 20rem;
}
.pr-80 {
  padding-right: 20rem;
}
.pb-80 {
  padding-bottom: 20rem;
}
.pl-80 {
  padding-left: 20rem;
}
.pt-96 {
  padding-top: 24rem;
}
.pr-96 {
  padding-right: 24rem;
}
.pb-96 {
  padding-bottom: 24rem;
}
.pl-96 {
  padding-left: 24rem;
}
.pt-px {
  padding-top: 1px;
}
.pr-px {
  padding-right: 1px;
}
.pb-px {
  padding-bottom: 1px;
}
.pl-px {
  padding-left: 1px;
}
.pt-0\.5 {
  padding-top: 0.125rem;
}
.pr-0\.5 {
  padding-right: 0.125rem;
}
.pb-0\.5 {
  padding-bottom: 0.125rem;
}
.pl-0\.5 {
  padding-left: 0.125rem;
}
.pt-1\.5 {
  padding-top: 0.375rem;
}
.pr-1\.5 {
  padding-right: 0.375rem;
}
.pb-1\.5 {
  padding-bottom: 0.375rem;
}
.pl-1\.5 {
  padding-left: 0.375rem;
}
.pt-2\.5 {
  padding-top: 0.625rem;
}
.pr-2\.5 {
  padding-right: 0.625rem;
}
.pb-2\.5 {
  padding-bottom: 0.625rem;
}
.pl-2\.5 {
  padding-left: 0.625rem;
}
.pt-3\.5 {
  padding-top: 0.875rem;
}
.pr-3\.5 {
  padding-right: 0.875rem;
}
.pb-3\.5 {
  padding-bottom: 0.875rem;
}
.pl-3\.5 {
  padding-left: 0.875rem;
}
.placeholder-transparent::-webkit-input-placeholder {
  color: transparent;
}
.placeholder-transparent:-ms-input-placeholder {
  color: transparent;
}
.placeholder-transparent::placeholder {
  color: transparent;
}
.placeholder-current::-webkit-input-placeholder {
  color: currentColor;
}
.placeholder-current:-ms-input-placeholder {
  color: currentColor;
}
.placeholder-current::placeholder {
  color: currentColor;
}
.placeholder-black::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.placeholder-black:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.placeholder-white::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.placeholder-white:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.placeholder-gray-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.placeholder-gray-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.placeholder-gray-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.placeholder-gray-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.placeholder-gray-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-gray-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-gray-300::-webkit-input-placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.placeholder-gray-300:-ms-input-placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.placeholder-gray-300::placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.placeholder-gray-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.placeholder-gray-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.placeholder-gray-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.placeholder-gray-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.placeholder-gray-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.placeholder-gray-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.placeholder-gray-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.placeholder-gray-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.placeholder-gray-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.placeholder-red-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.placeholder-red-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.placeholder-red-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.placeholder-red-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.placeholder-red-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.placeholder-red-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.placeholder-red-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.placeholder-red-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.placeholder-red-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.placeholder-red-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.placeholder-red-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.placeholder-red-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.placeholder-red-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.placeholder-red-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.placeholder-red-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.placeholder-red-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.placeholder-red-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.placeholder-red-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.placeholder-red-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.placeholder-red-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.placeholder-red-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.placeholder-red-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.placeholder-red-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.placeholder-red-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.placeholder-red-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.placeholder-yellow-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.placeholder-yellow-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.placeholder-yellow-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.placeholder-yellow-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.placeholder-yellow-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.placeholder-yellow-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.placeholder-yellow-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.placeholder-yellow-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.placeholder-yellow-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.placeholder-yellow-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.placeholder-yellow-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.placeholder-yellow-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.placeholder-yellow-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.placeholder-yellow-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.placeholder-yellow-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.placeholder-yellow-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.placeholder-yellow-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.placeholder-yellow-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.placeholder-yellow-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.placeholder-yellow-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.placeholder-green-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.placeholder-green-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.placeholder-green-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.placeholder-green-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.placeholder-green-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.placeholder-green-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.placeholder-green-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.placeholder-green-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.placeholder-green-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.placeholder-green-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.placeholder-green-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.placeholder-green-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.placeholder-green-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.placeholder-green-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.placeholder-green-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.placeholder-green-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.placeholder-green-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.placeholder-green-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.placeholder-green-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.placeholder-green-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.placeholder-blue-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.placeholder-blue-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.placeholder-blue-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.placeholder-blue-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.placeholder-blue-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.placeholder-blue-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.placeholder-blue-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.placeholder-blue-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.placeholder-blue-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.placeholder-blue-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.placeholder-blue-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.placeholder-blue-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.placeholder-blue-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.placeholder-blue-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.placeholder-blue-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.placeholder-blue-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.placeholder-indigo-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.placeholder-indigo-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.placeholder-indigo-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.placeholder-indigo-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.placeholder-indigo-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.placeholder-indigo-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.placeholder-indigo-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.placeholder-indigo-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.placeholder-indigo-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.placeholder-indigo-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.placeholder-indigo-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.placeholder-indigo-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.placeholder-indigo-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.placeholder-indigo-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.placeholder-indigo-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.placeholder-indigo-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.placeholder-purple-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.placeholder-purple-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.placeholder-purple-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.placeholder-purple-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.placeholder-purple-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.placeholder-purple-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.placeholder-purple-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.placeholder-purple-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.placeholder-purple-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.placeholder-purple-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.placeholder-purple-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.placeholder-purple-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.placeholder-purple-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.placeholder-purple-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.placeholder-purple-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.placeholder-purple-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.placeholder-pink-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.placeholder-pink-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.placeholder-pink-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.placeholder-pink-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.placeholder-pink-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.placeholder-pink-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.placeholder-pink-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.placeholder-pink-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.placeholder-pink-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.placeholder-pink-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.placeholder-pink-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.placeholder-pink-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.placeholder-pink-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.placeholder-pink-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.placeholder-pink-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.placeholder-pink-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.placeholder-pink-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.placeholder-pink-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.placeholder-pink-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.placeholder-pink-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.placeholder-vivid-blue::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-vivid-blue:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-vivid-blue::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-vivid-blue-500::-webkit-input-placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.placeholder-vivid-blue-500:-ms-input-placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.placeholder-vivid-blue-500::placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.placeholder-background-primary::-webkit-input-placeholder {
  color: var(--background-primary);
}
.placeholder-background-primary:-ms-input-placeholder {
  color: var(--background-primary);
}
.placeholder-background-primary::placeholder {
  color: var(--background-primary);
}
.placeholder-main-background::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.placeholder-main-background:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.placeholder-main-background::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.placeholder-dark-blue::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.placeholder-dark-blue:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.placeholder-dark-blue::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.placeholder-blue-grey::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.placeholder-blue-grey:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.placeholder-blue-grey::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.placeholder-text-grey::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.placeholder-text-grey:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.placeholder-text-grey::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey-two::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey-two:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey-two::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.placeholder-pale-grey::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.placeholder-btc::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.placeholder-btc:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.placeholder-btc::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.placeholder-btc-symbol::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.placeholder-btc-symbol:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.placeholder-btc-symbol::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.placeholder-paxg::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.placeholder-paxg:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.placeholder-paxg::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.placeholder-usdc::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.placeholder-usdc:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.placeholder-usdc::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.placeholder-black-navy::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.placeholder-black-navy:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.placeholder-black-navy::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.placeholder-ghost-white::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.placeholder-ghost-white:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.placeholder-ghost-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.placeholder-spring-green::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.placeholder-spring-green:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.placeholder-spring-green::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.placeholder-light-slate-gray::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.placeholder-light-slate-gray:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.placeholder-light-slate-gray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.placeholder-body-background::-webkit-input-placeholder {
  color: var(--body-background);
}
.placeholder-body-background:-ms-input-placeholder {
  color: var(--body-background);
}
.placeholder-body-background::placeholder {
  color: var(--body-background);
}
.placeholder-background::-webkit-input-placeholder {
  color: var(--background);
}
.placeholder-background:-ms-input-placeholder {
  color: var(--background);
}
.placeholder-background::placeholder {
  color: var(--background);
}
.placeholder-input-background::-webkit-input-placeholder {
  color: var(--input-background);
}
.placeholder-input-background:-ms-input-placeholder {
  color: var(--input-background);
}
.placeholder-input-background::placeholder {
  color: var(--input-background);
}
.placeholder-body-text::-webkit-input-placeholder {
  color: var(--body-text);
}
.placeholder-body-text:-ms-input-placeholder {
  color: var(--body-text);
}
.placeholder-body-text::placeholder {
  color: var(--body-text);
}
.placeholder-header-text::-webkit-input-placeholder {
  color: var(--header-text);
}
.placeholder-header-text:-ms-input-placeholder {
  color: var(--header-text);
}
.placeholder-header-text::placeholder {
  color: var(--header-text);
}
.placeholder-primary::-webkit-input-placeholder {
  color: var(--primary);
}
.placeholder-primary:-ms-input-placeholder {
  color: var(--primary);
}
.placeholder-primary::placeholder {
  color: var(--primary);
}
.placeholder-secondary::-webkit-input-placeholder {
  color: var(--secondary);
}
.placeholder-secondary:-ms-input-placeholder {
  color: var(--secondary);
}
.placeholder-secondary::placeholder {
  color: var(--secondary);
}
.placeholder-tertiary::-webkit-input-placeholder {
  color: var(--tertiary);
}
.placeholder-tertiary:-ms-input-placeholder {
  color: var(--tertiary);
}
.placeholder-tertiary::placeholder {
  color: var(--tertiary);
}
.placeholder-primary-hover::-webkit-input-placeholder {
  color: var(--primary-hover);
}
.placeholder-primary-hover:-ms-input-placeholder {
  color: var(--primary-hover);
}
.placeholder-primary-hover::placeholder {
  color: var(--primary-hover);
}
.placeholder-secondary-hover::-webkit-input-placeholder {
  color: var(--secondary-hover);
}
.placeholder-secondary-hover:-ms-input-placeholder {
  color: var(--secondary-hover);
}
.placeholder-secondary-hover::placeholder {
  color: var(--secondary-hover);
}
.placeholder-tertiary-hover::-webkit-input-placeholder {
  color: var(--tertiary-hover);
}
.placeholder-tertiary-hover:-ms-input-placeholder {
  color: var(--tertiary-hover);
}
.placeholder-tertiary-hover::placeholder {
  color: var(--tertiary-hover);
}
.placeholder-input-text::-webkit-input-placeholder {
  color: var(--input-text);
}
.placeholder-input-text:-ms-input-placeholder {
  color: var(--input-text);
}
.placeholder-input-text::placeholder {
  color: var(--input-text);
}
.placeholder-success::-webkit-input-placeholder {
  color: var(--success);
}
.placeholder-success:-ms-input-placeholder {
  color: var(--success);
}
.placeholder-success::placeholder {
  color: var(--success);
}
.placeholder-error::-webkit-input-placeholder {
  color: var(--error);
}
.placeholder-error:-ms-input-placeholder {
  color: var(--error);
}
.placeholder-error::placeholder {
  color: var(--error);
}
.placeholder-custom-loader::-webkit-input-placeholder {
  color: var(--custom-loader);
}
.placeholder-custom-loader:-ms-input-placeholder {
  color: var(--custom-loader);
}
.placeholder-custom-loader::placeholder {
  color: var(--custom-loader);
}
.placeholder-violet::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-violet:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-violet::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-violet-hover::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.placeholder-violet-hover:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.placeholder-violet-hover::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.placeholder-skeleton-loader-from::-webkit-input-placeholder {
  color: var(--skeleton-loader-from);
}
.placeholder-skeleton-loader-from:-ms-input-placeholder {
  color: var(--skeleton-loader-from);
}
.placeholder-skeleton-loader-from::placeholder {
  color: var(--skeleton-loader-from);
}
.placeholder-skeleton-loader-to::-webkit-input-placeholder {
  color: var(--skeleton-loader-to);
}
.placeholder-skeleton-loader-to:-ms-input-placeholder {
  color: var(--skeleton-loader-to);
}
.placeholder-skeleton-loader-to::placeholder {
  color: var(--skeleton-loader-to);
}
.placeholder-background50::-webkit-input-placeholder {
  color: var(--background50);
}
.placeholder-background50:-ms-input-placeholder {
  color: var(--background50);
}
.placeholder-background50::placeholder {
  color: var(--background50);
}
.placeholder-reward-blue-start::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.placeholder-reward-blue-start:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.placeholder-reward-blue-start::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.placeholder-reward-blue-end::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-blue-end:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-blue-end::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-start::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-start:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-start::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-end::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-end:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.placeholder-reward-silver-end::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-start::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-start:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-start::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-end::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-end:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.placeholder-reward-gold-end::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-start::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-start:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-start::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-end::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-end:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.placeholder-reward-platinum-end::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-start::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-start:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-start::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-end::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-end:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-reward-vip-end::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.placeholder-fee-breakdown-bg::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.placeholder-fee-breakdown-bg:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.placeholder-fee-breakdown-bg::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.placeholder-light-grey::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-light-grey:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-light-grey::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-background::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-background:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-background::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-text::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-text:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.placeholder-disabled-button-text::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.focus\:placeholder-transparent:focus::-webkit-input-placeholder {
  color: transparent;
}
.focus\:placeholder-transparent:focus:-ms-input-placeholder {
  color: transparent;
}
.focus\:placeholder-transparent:focus::placeholder {
  color: transparent;
}
.focus\:placeholder-current:focus::-webkit-input-placeholder {
  color: currentColor;
}
.focus\:placeholder-current:focus:-ms-input-placeholder {
  color: currentColor;
}
.focus\:placeholder-current:focus::placeholder {
  color: currentColor;
}
.focus\:placeholder-black:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-black:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-white:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-white:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:placeholder-gray-300:focus:-ms-input-placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:placeholder-gray-300:focus::placeholder {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-vivid-blue:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.focus\:placeholder-vivid-blue-500:focus::placeholder {
  color: rgba(144, 150, 246, 0.5);
}
.focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
  color: var(--background-primary);
}
.focus\:placeholder-background-primary:focus:-ms-input-placeholder {
  color: var(--background-primary);
}
.focus\:placeholder-background-primary:focus::placeholder {
  color: var(--background-primary);
}
.focus\:placeholder-main-background:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.focus\:placeholder-main-background:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.focus\:placeholder-main-background:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
}
.focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.focus\:placeholder-dark-blue:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-grey:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
}
.focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.focus\:placeholder-text-grey:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.focus\:placeholder-text-grey:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey-two:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pale-grey:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-btc-symbol:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-paxg:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.focus\:placeholder-paxg:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.focus\:placeholder-paxg:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
}
.focus\:placeholder-usdc:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-usdc:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-usdc:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-black-navy:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-black-navy:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-ghost-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.focus\:placeholder-spring-green:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.focus\:placeholder-spring-green:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-slate-gray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
}
.focus\:placeholder-body-background:focus::-webkit-input-placeholder {
  color: var(--body-background);
}
.focus\:placeholder-body-background:focus:-ms-input-placeholder {
  color: var(--body-background);
}
.focus\:placeholder-body-background:focus::placeholder {
  color: var(--body-background);
}
.focus\:placeholder-background:focus::-webkit-input-placeholder {
  color: var(--background);
}
.focus\:placeholder-background:focus:-ms-input-placeholder {
  color: var(--background);
}
.focus\:placeholder-background:focus::placeholder {
  color: var(--background);
}
.focus\:placeholder-input-background:focus::-webkit-input-placeholder {
  color: var(--input-background);
}
.focus\:placeholder-input-background:focus:-ms-input-placeholder {
  color: var(--input-background);
}
.focus\:placeholder-input-background:focus::placeholder {
  color: var(--input-background);
}
.focus\:placeholder-body-text:focus::-webkit-input-placeholder {
  color: var(--body-text);
}
.focus\:placeholder-body-text:focus:-ms-input-placeholder {
  color: var(--body-text);
}
.focus\:placeholder-body-text:focus::placeholder {
  color: var(--body-text);
}
.focus\:placeholder-header-text:focus::-webkit-input-placeholder {
  color: var(--header-text);
}
.focus\:placeholder-header-text:focus:-ms-input-placeholder {
  color: var(--header-text);
}
.focus\:placeholder-header-text:focus::placeholder {
  color: var(--header-text);
}
.focus\:placeholder-primary:focus::-webkit-input-placeholder {
  color: var(--primary);
}
.focus\:placeholder-primary:focus:-ms-input-placeholder {
  color: var(--primary);
}
.focus\:placeholder-primary:focus::placeholder {
  color: var(--primary);
}
.focus\:placeholder-secondary:focus::-webkit-input-placeholder {
  color: var(--secondary);
}
.focus\:placeholder-secondary:focus:-ms-input-placeholder {
  color: var(--secondary);
}
.focus\:placeholder-secondary:focus::placeholder {
  color: var(--secondary);
}
.focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
  color: var(--tertiary);
}
.focus\:placeholder-tertiary:focus:-ms-input-placeholder {
  color: var(--tertiary);
}
.focus\:placeholder-tertiary:focus::placeholder {
  color: var(--tertiary);
}
.focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
  color: var(--primary-hover);
}
.focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
  color: var(--primary-hover);
}
.focus\:placeholder-primary-hover:focus::placeholder {
  color: var(--primary-hover);
}
.focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
  color: var(--secondary-hover);
}
.focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
  color: var(--secondary-hover);
}
.focus\:placeholder-secondary-hover:focus::placeholder {
  color: var(--secondary-hover);
}
.focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
  color: var(--tertiary-hover);
}
.focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
  color: var(--tertiary-hover);
}
.focus\:placeholder-tertiary-hover:focus::placeholder {
  color: var(--tertiary-hover);
}
.focus\:placeholder-input-text:focus::-webkit-input-placeholder {
  color: var(--input-text);
}
.focus\:placeholder-input-text:focus:-ms-input-placeholder {
  color: var(--input-text);
}
.focus\:placeholder-input-text:focus::placeholder {
  color: var(--input-text);
}
.focus\:placeholder-success:focus::-webkit-input-placeholder {
  color: var(--success);
}
.focus\:placeholder-success:focus:-ms-input-placeholder {
  color: var(--success);
}
.focus\:placeholder-success:focus::placeholder {
  color: var(--success);
}
.focus\:placeholder-error:focus::-webkit-input-placeholder {
  color: var(--error);
}
.focus\:placeholder-error:focus:-ms-input-placeholder {
  color: var(--error);
}
.focus\:placeholder-error:focus::placeholder {
  color: var(--error);
}
.focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
  color: var(--custom-loader);
}
.focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
  color: var(--custom-loader);
}
.focus\:placeholder-custom-loader:focus::placeholder {
  color: var(--custom-loader);
}
.focus\:placeholder-violet:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-violet:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-violet:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.focus\:placeholder-violet-hover:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
}
.focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
  color: var(--skeleton-loader-from);
}
.focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
  color: var(--skeleton-loader-from);
}
.focus\:placeholder-skeleton-loader-from:focus::placeholder {
  color: var(--skeleton-loader-from);
}
.focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
  color: var(--skeleton-loader-to);
}
.focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
  color: var(--skeleton-loader-to);
}
.focus\:placeholder-skeleton-loader-to:focus::placeholder {
  color: var(--skeleton-loader-to);
}
.focus\:placeholder-background50:focus::-webkit-input-placeholder {
  color: var(--background50);
}
.focus\:placeholder-background50:focus:-ms-input-placeholder {
  color: var(--background50);
}
.focus\:placeholder-background50:focus::placeholder {
  color: var(--background50);
}
.focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-blue-start:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-blue-end:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-start:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-silver-end:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-start:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-gold-end:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-start:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-platinum-end:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-start:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-reward-vip-end:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
}
.focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-fee-breakdown-bg:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-grey:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-light-grey:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-background:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.focus\:placeholder-disabled-button-text:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
}
.placeholder-opacity-0::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0;
}
.placeholder-opacity-0:-ms-input-placeholder {
  --tw-placeholder-opacity: 0;
}
.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}
.placeholder-opacity-5::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}
.placeholder-opacity-5:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}
.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: 0.05;
}
.placeholder-opacity-10::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}
.placeholder-opacity-10:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}
.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: 0.1;
}
.placeholder-opacity-20::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}
.placeholder-opacity-20:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}
.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: 0.2;
}
.placeholder-opacity-25::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}
.placeholder-opacity-25:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}
.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25;
}
.placeholder-opacity-30::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}
.placeholder-opacity-30:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}
.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: 0.3;
}
.placeholder-opacity-40::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}
.placeholder-opacity-40:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}
.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: 0.4;
}
.placeholder-opacity-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}
.placeholder-opacity-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}
.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5;
}
.placeholder-opacity-60::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}
.placeholder-opacity-60:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}
.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: 0.6;
}
.placeholder-opacity-70::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}
.placeholder-opacity-70:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}
.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: 0.7;
}
.placeholder-opacity-75::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}
.placeholder-opacity-75:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}
.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75;
}
.placeholder-opacity-80::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}
.placeholder-opacity-80:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}
.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: 0.8;
}
.placeholder-opacity-90::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}
.placeholder-opacity-90:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}
.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: 0.9;
}
.placeholder-opacity-95::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}
.placeholder-opacity-95:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}
.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: 0.95;
}
.placeholder-opacity-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
}
.placeholder-opacity-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
}
.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}
.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0;
}
.focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0;
}
.focus\:placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0;
}
.focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}
.focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}
.focus\:placeholder-opacity-5:focus::placeholder {
  --tw-placeholder-opacity: 0.05;
}
.focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}
.focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}
.focus\:placeholder-opacity-10:focus::placeholder {
  --tw-placeholder-opacity: 0.1;
}
.focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}
.focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}
.focus\:placeholder-opacity-20:focus::placeholder {
  --tw-placeholder-opacity: 0.2;
}
.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}
.focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}
.focus\:placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: 0.25;
}
.focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}
.focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}
.focus\:placeholder-opacity-30:focus::placeholder {
  --tw-placeholder-opacity: 0.3;
}
.focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}
.focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}
.focus\:placeholder-opacity-40:focus::placeholder {
  --tw-placeholder-opacity: 0.4;
}
.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}
.focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}
.focus\:placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: 0.5;
}
.focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}
.focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}
.focus\:placeholder-opacity-60:focus::placeholder {
  --tw-placeholder-opacity: 0.6;
}
.focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}
.focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}
.focus\:placeholder-opacity-70:focus::placeholder {
  --tw-placeholder-opacity: 0.7;
}
.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}
.focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}
.focus\:placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: 0.75;
}
.focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}
.focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}
.focus\:placeholder-opacity-80:focus::placeholder {
  --tw-placeholder-opacity: 0.8;
}
.focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}
.focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}
.focus\:placeholder-opacity-90:focus::placeholder {
  --tw-placeholder-opacity: 0.9;
}
.focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}
.focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}
.focus\:placeholder-opacity-95:focus::placeholder {
  --tw-placeholder-opacity: 0.95;
}
.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
}
.focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
}
.focus\:placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.inset-1 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
}
.inset-2 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
}
.inset-3 {
  top: 0.75rem;
  right: 0.75rem;
  bottom: 0.75rem;
  left: 0.75rem;
}
.inset-4 {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}
.inset-5 {
  top: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  left: 1.25rem;
}
.inset-6 {
  top: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem;
}
.inset-7 {
  top: 1.75rem;
  right: 1.75rem;
  bottom: 1.75rem;
  left: 1.75rem;
}
.inset-8 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
}
.inset-9 {
  top: 2.25rem;
  right: 2.25rem;
  bottom: 2.25rem;
  left: 2.25rem;
}
.inset-10 {
  top: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
}
.inset-11 {
  top: 2.75rem;
  right: 2.75rem;
  bottom: 2.75rem;
  left: 2.75rem;
}
.inset-12 {
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem;
}
.inset-14 {
  top: 3.5rem;
  right: 3.5rem;
  bottom: 3.5rem;
  left: 3.5rem;
}
.inset-16 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem;
}
.inset-20 {
  top: 5rem;
  right: 5rem;
  bottom: 5rem;
  left: 5rem;
}
.inset-24 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem;
}
.inset-28 {
  top: 7rem;
  right: 7rem;
  bottom: 7rem;
  left: 7rem;
}
.inset-32 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem;
}
.inset-36 {
  top: 9rem;
  right: 9rem;
  bottom: 9rem;
  left: 9rem;
}
.inset-40 {
  top: 10rem;
  right: 10rem;
  bottom: 10rem;
  left: 10rem;
}
.inset-44 {
  top: 11rem;
  right: 11rem;
  bottom: 11rem;
  left: 11rem;
}
.inset-48 {
  top: 12rem;
  right: 12rem;
  bottom: 12rem;
  left: 12rem;
}
.inset-52 {
  top: 13rem;
  right: 13rem;
  bottom: 13rem;
  left: 13rem;
}
.inset-56 {
  top: 14rem;
  right: 14rem;
  bottom: 14rem;
  left: 14rem;
}
.inset-60 {
  top: 15rem;
  right: 15rem;
  bottom: 15rem;
  left: 15rem;
}
.inset-64 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem;
}
.inset-72 {
  top: 18rem;
  right: 18rem;
  bottom: 18rem;
  left: 18rem;
}
.inset-80 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem;
}
.inset-96 {
  top: 24rem;
  right: 24rem;
  bottom: 24rem;
  left: 24rem;
}
.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
.inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
}
.inset-0\.5 {
  top: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
  left: 0.125rem;
}
.inset-1\.5 {
  top: 0.375rem;
  right: 0.375rem;
  bottom: 0.375rem;
  left: 0.375rem;
}
.inset-2\.5 {
  top: 0.625rem;
  right: 0.625rem;
  bottom: 0.625rem;
  left: 0.625rem;
}
.inset-3\.5 {
  top: 0.875rem;
  right: 0.875rem;
  bottom: 0.875rem;
  left: 0.875rem;
}
.-inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.-inset-1 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
}
.-inset-2 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
}
.-inset-3 {
  top: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem;
}
.-inset-4 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
}
.-inset-5 {
  top: -1.25rem;
  right: -1.25rem;
  bottom: -1.25rem;
  left: -1.25rem;
}
.-inset-6 {
  top: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem;
}
.-inset-7 {
  top: -1.75rem;
  right: -1.75rem;
  bottom: -1.75rem;
  left: -1.75rem;
}
.-inset-8 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem;
}
.-inset-9 {
  top: -2.25rem;
  right: -2.25rem;
  bottom: -2.25rem;
  left: -2.25rem;
}
.-inset-10 {
  top: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
  left: -2.5rem;
}
.-inset-11 {
  top: -2.75rem;
  right: -2.75rem;
  bottom: -2.75rem;
  left: -2.75rem;
}
.-inset-12 {
  top: -3rem;
  right: -3rem;
  bottom: -3rem;
  left: -3rem;
}
.-inset-14 {
  top: -3.5rem;
  right: -3.5rem;
  bottom: -3.5rem;
  left: -3.5rem;
}
.-inset-16 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem;
}
.-inset-20 {
  top: -5rem;
  right: -5rem;
  bottom: -5rem;
  left: -5rem;
}
.-inset-24 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem;
}
.-inset-28 {
  top: -7rem;
  right: -7rem;
  bottom: -7rem;
  left: -7rem;
}
.-inset-32 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem;
}
.-inset-36 {
  top: -9rem;
  right: -9rem;
  bottom: -9rem;
  left: -9rem;
}
.-inset-40 {
  top: -10rem;
  right: -10rem;
  bottom: -10rem;
  left: -10rem;
}
.-inset-44 {
  top: -11rem;
  right: -11rem;
  bottom: -11rem;
  left: -11rem;
}
.-inset-48 {
  top: -12rem;
  right: -12rem;
  bottom: -12rem;
  left: -12rem;
}
.-inset-52 {
  top: -13rem;
  right: -13rem;
  bottom: -13rem;
  left: -13rem;
}
.-inset-56 {
  top: -14rem;
  right: -14rem;
  bottom: -14rem;
  left: -14rem;
}
.-inset-60 {
  top: -15rem;
  right: -15rem;
  bottom: -15rem;
  left: -15rem;
}
.-inset-64 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem;
}
.-inset-72 {
  top: -18rem;
  right: -18rem;
  bottom: -18rem;
  left: -18rem;
}
.-inset-80 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem;
}
.-inset-96 {
  top: -24rem;
  right: -24rem;
  bottom: -24rem;
  left: -24rem;
}
.-inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}
.-inset-0\.5 {
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
}
.-inset-1\.5 {
  top: -0.375rem;
  right: -0.375rem;
  bottom: -0.375rem;
  left: -0.375rem;
}
.-inset-2\.5 {
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
  left: -0.625rem;
}
.-inset-3\.5 {
  top: -0.875rem;
  right: -0.875rem;
  bottom: -0.875rem;
  left: -0.875rem;
}
.inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}
.inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%;
}
.inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%;
}
.inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%;
}
.inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}
.inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%;
}
.inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%;
}
.-inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}
.-inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%;
}
.-inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%;
}
.-inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%;
}
.-inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}
.-inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%;
}
.-inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
}
.inset-y-0 {
  top: 0;
  bottom: 0;
}
.inset-x-0 {
  right: 0;
  left: 0;
}
.inset-y-1 {
  top: 0.25rem;
  bottom: 0.25rem;
}
.inset-x-1 {
  right: 0.25rem;
  left: 0.25rem;
}
.inset-y-2 {
  top: 0.5rem;
  bottom: 0.5rem;
}
.inset-x-2 {
  right: 0.5rem;
  left: 0.5rem;
}
.inset-y-3 {
  top: 0.75rem;
  bottom: 0.75rem;
}
.inset-x-3 {
  right: 0.75rem;
  left: 0.75rem;
}
.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}
.inset-x-4 {
  right: 1rem;
  left: 1rem;
}
.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}
.inset-x-5 {
  right: 1.25rem;
  left: 1.25rem;
}
.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}
.inset-x-6 {
  right: 1.5rem;
  left: 1.5rem;
}
.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}
.inset-x-7 {
  right: 1.75rem;
  left: 1.75rem;
}
.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}
.inset-x-8 {
  right: 2rem;
  left: 2rem;
}
.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}
.inset-x-9 {
  right: 2.25rem;
  left: 2.25rem;
}
.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}
.inset-x-10 {
  right: 2.5rem;
  left: 2.5rem;
}
.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}
.inset-x-11 {
  right: 2.75rem;
  left: 2.75rem;
}
.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}
.inset-x-12 {
  right: 3rem;
  left: 3rem;
}
.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}
.inset-x-14 {
  right: 3.5rem;
  left: 3.5rem;
}
.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}
.inset-x-16 {
  right: 4rem;
  left: 4rem;
}
.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}
.inset-x-20 {
  right: 5rem;
  left: 5rem;
}
.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}
.inset-x-24 {
  right: 6rem;
  left: 6rem;
}
.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}
.inset-x-28 {
  right: 7rem;
  left: 7rem;
}
.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}
.inset-x-32 {
  right: 8rem;
  left: 8rem;
}
.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}
.inset-x-36 {
  right: 9rem;
  left: 9rem;
}
.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}
.inset-x-40 {
  right: 10rem;
  left: 10rem;
}
.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}
.inset-x-44 {
  right: 11rem;
  left: 11rem;
}
.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}
.inset-x-48 {
  right: 12rem;
  left: 12rem;
}
.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}
.inset-x-52 {
  right: 13rem;
  left: 13rem;
}
.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}
.inset-x-56 {
  right: 14rem;
  left: 14rem;
}
.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}
.inset-x-60 {
  right: 15rem;
  left: 15rem;
}
.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}
.inset-x-64 {
  right: 16rem;
  left: 16rem;
}
.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}
.inset-x-72 {
  right: 18rem;
  left: 18rem;
}
.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}
.inset-x-80 {
  right: 20rem;
  left: 20rem;
}
.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}
.inset-x-96 {
  right: 24rem;
  left: 24rem;
}
.inset-y-auto {
  top: auto;
  bottom: auto;
}
.inset-x-auto {
  right: auto;
  left: auto;
}
.inset-y-px {
  top: 1px;
  bottom: 1px;
}
.inset-x-px {
  right: 1px;
  left: 1px;
}
.inset-y-0\.5 {
  top: 0.125rem;
  bottom: 0.125rem;
}
.inset-x-0\.5 {
  right: 0.125rem;
  left: 0.125rem;
}
.inset-y-1\.5 {
  top: 0.375rem;
  bottom: 0.375rem;
}
.inset-x-1\.5 {
  right: 0.375rem;
  left: 0.375rem;
}
.inset-y-2\.5 {
  top: 0.625rem;
  bottom: 0.625rem;
}
.inset-x-2\.5 {
  right: 0.625rem;
  left: 0.625rem;
}
.inset-y-3\.5 {
  top: 0.875rem;
  bottom: 0.875rem;
}
.inset-x-3\.5 {
  right: 0.875rem;
  left: 0.875rem;
}
.-inset-y-0 {
  top: 0;
  bottom: 0;
}
.-inset-x-0 {
  right: 0;
  left: 0;
}
.-inset-y-1 {
  top: -0.25rem;
  bottom: -0.25rem;
}
.-inset-x-1 {
  right: -0.25rem;
  left: -0.25rem;
}
.-inset-y-2 {
  top: -0.5rem;
  bottom: -0.5rem;
}
.-inset-x-2 {
  right: -0.5rem;
  left: -0.5rem;
}
.-inset-y-3 {
  top: -0.75rem;
  bottom: -0.75rem;
}
.-inset-x-3 {
  right: -0.75rem;
  left: -0.75rem;
}
.-inset-y-4 {
  top: -1rem;
  bottom: -1rem;
}
.-inset-x-4 {
  right: -1rem;
  left: -1rem;
}
.-inset-y-5 {
  top: -1.25rem;
  bottom: -1.25rem;
}
.-inset-x-5 {
  right: -1.25rem;
  left: -1.25rem;
}
.-inset-y-6 {
  top: -1.5rem;
  bottom: -1.5rem;
}
.-inset-x-6 {
  right: -1.5rem;
  left: -1.5rem;
}
.-inset-y-7 {
  top: -1.75rem;
  bottom: -1.75rem;
}
.-inset-x-7 {
  right: -1.75rem;
  left: -1.75rem;
}
.-inset-y-8 {
  top: -2rem;
  bottom: -2rem;
}
.-inset-x-8 {
  right: -2rem;
  left: -2rem;
}
.-inset-y-9 {
  top: -2.25rem;
  bottom: -2.25rem;
}
.-inset-x-9 {
  right: -2.25rem;
  left: -2.25rem;
}
.-inset-y-10 {
  top: -2.5rem;
  bottom: -2.5rem;
}
.-inset-x-10 {
  right: -2.5rem;
  left: -2.5rem;
}
.-inset-y-11 {
  top: -2.75rem;
  bottom: -2.75rem;
}
.-inset-x-11 {
  right: -2.75rem;
  left: -2.75rem;
}
.-inset-y-12 {
  top: -3rem;
  bottom: -3rem;
}
.-inset-x-12 {
  right: -3rem;
  left: -3rem;
}
.-inset-y-14 {
  top: -3.5rem;
  bottom: -3.5rem;
}
.-inset-x-14 {
  right: -3.5rem;
  left: -3.5rem;
}
.-inset-y-16 {
  top: -4rem;
  bottom: -4rem;
}
.-inset-x-16 {
  right: -4rem;
  left: -4rem;
}
.-inset-y-20 {
  top: -5rem;
  bottom: -5rem;
}
.-inset-x-20 {
  right: -5rem;
  left: -5rem;
}
.-inset-y-24 {
  top: -6rem;
  bottom: -6rem;
}
.-inset-x-24 {
  right: -6rem;
  left: -6rem;
}
.-inset-y-28 {
  top: -7rem;
  bottom: -7rem;
}
.-inset-x-28 {
  right: -7rem;
  left: -7rem;
}
.-inset-y-32 {
  top: -8rem;
  bottom: -8rem;
}
.-inset-x-32 {
  right: -8rem;
  left: -8rem;
}
.-inset-y-36 {
  top: -9rem;
  bottom: -9rem;
}
.-inset-x-36 {
  right: -9rem;
  left: -9rem;
}
.-inset-y-40 {
  top: -10rem;
  bottom: -10rem;
}
.-inset-x-40 {
  right: -10rem;
  left: -10rem;
}
.-inset-y-44 {
  top: -11rem;
  bottom: -11rem;
}
.-inset-x-44 {
  right: -11rem;
  left: -11rem;
}
.-inset-y-48 {
  top: -12rem;
  bottom: -12rem;
}
.-inset-x-48 {
  right: -12rem;
  left: -12rem;
}
.-inset-y-52 {
  top: -13rem;
  bottom: -13rem;
}
.-inset-x-52 {
  right: -13rem;
  left: -13rem;
}
.-inset-y-56 {
  top: -14rem;
  bottom: -14rem;
}
.-inset-x-56 {
  right: -14rem;
  left: -14rem;
}
.-inset-y-60 {
  top: -15rem;
  bottom: -15rem;
}
.-inset-x-60 {
  right: -15rem;
  left: -15rem;
}
.-inset-y-64 {
  top: -16rem;
  bottom: -16rem;
}
.-inset-x-64 {
  right: -16rem;
  left: -16rem;
}
.-inset-y-72 {
  top: -18rem;
  bottom: -18rem;
}
.-inset-x-72 {
  right: -18rem;
  left: -18rem;
}
.-inset-y-80 {
  top: -20rem;
  bottom: -20rem;
}
.-inset-x-80 {
  right: -20rem;
  left: -20rem;
}
.-inset-y-96 {
  top: -24rem;
  bottom: -24rem;
}
.-inset-x-96 {
  right: -24rem;
  left: -24rem;
}
.-inset-y-px {
  top: -1px;
  bottom: -1px;
}
.-inset-x-px {
  right: -1px;
  left: -1px;
}
.-inset-y-0\.5 {
  top: -0.125rem;
  bottom: -0.125rem;
}
.-inset-x-0\.5 {
  right: -0.125rem;
  left: -0.125rem;
}
.-inset-y-1\.5 {
  top: -0.375rem;
  bottom: -0.375rem;
}
.-inset-x-1\.5 {
  right: -0.375rem;
  left: -0.375rem;
}
.-inset-y-2\.5 {
  top: -0.625rem;
  bottom: -0.625rem;
}
.-inset-x-2\.5 {
  right: -0.625rem;
  left: -0.625rem;
}
.-inset-y-3\.5 {
  top: -0.875rem;
  bottom: -0.875rem;
}
.-inset-x-3\.5 {
  right: -0.875rem;
  left: -0.875rem;
}
.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}
.inset-x-1\/2 {
  right: 50%;
  left: 50%;
}
.inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%;
}
.inset-x-1\/3 {
  right: 33.333333%;
  left: 33.333333%;
}
.inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%;
}
.inset-x-2\/3 {
  right: 66.666667%;
  left: 66.666667%;
}
.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}
.inset-x-1\/4 {
  right: 25%;
  left: 25%;
}
.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}
.inset-x-2\/4 {
  right: 50%;
  left: 50%;
}
.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}
.inset-x-3\/4 {
  right: 75%;
  left: 75%;
}
.inset-y-full {
  top: 100%;
  bottom: 100%;
}
.inset-x-full {
  right: 100%;
  left: 100%;
}
.-inset-y-1\/2 {
  top: -50%;
  bottom: -50%;
}
.-inset-x-1\/2 {
  right: -50%;
  left: -50%;
}
.-inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%;
}
.-inset-x-1\/3 {
  right: -33.333333%;
  left: -33.333333%;
}
.-inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%;
}
.-inset-x-2\/3 {
  right: -66.666667%;
  left: -66.666667%;
}
.-inset-y-1\/4 {
  top: -25%;
  bottom: -25%;
}
.-inset-x-1\/4 {
  right: -25%;
  left: -25%;
}
.-inset-y-2\/4 {
  top: -50%;
  bottom: -50%;
}
.-inset-x-2\/4 {
  right: -50%;
  left: -50%;
}
.-inset-y-3\/4 {
  top: -75%;
  bottom: -75%;
}
.-inset-x-3\/4 {
  right: -75%;
  left: -75%;
}
.-inset-y-full {
  top: -100%;
  bottom: -100%;
}
.-inset-x-full {
  right: -100%;
  left: -100%;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.top-1 {
  top: 0.25rem;
}
.right-1 {
  right: 0.25rem;
}
.bottom-1 {
  bottom: 0.25rem;
}
.left-1 {
  left: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.left-2 {
  left: 0.5rem;
}
.top-3 {
  top: 0.75rem;
}
.right-3 {
  right: 0.75rem;
}
.bottom-3 {
  bottom: 0.75rem;
}
.left-3 {
  left: 0.75rem;
}
.top-4 {
  top: 1rem;
}
.right-4 {
  right: 1rem;
}
.bottom-4 {
  bottom: 1rem;
}
.left-4 {
  left: 1rem;
}
.top-5 {
  top: 1.25rem;
}
.right-5 {
  right: 1.25rem;
}
.bottom-5 {
  bottom: 1.25rem;
}
.left-5 {
  left: 1.25rem;
}
.top-6 {
  top: 1.5rem;
}
.right-6 {
  right: 1.5rem;
}
.bottom-6 {
  bottom: 1.5rem;
}
.left-6 {
  left: 1.5rem;
}
.top-7 {
  top: 1.75rem;
}
.right-7 {
  right: 1.75rem;
}
.bottom-7 {
  bottom: 1.75rem;
}
.left-7 {
  left: 1.75rem;
}
.top-8 {
  top: 2rem;
}
.right-8 {
  right: 2rem;
}
.bottom-8 {
  bottom: 2rem;
}
.left-8 {
  left: 2rem;
}
.top-9 {
  top: 2.25rem;
}
.right-9 {
  right: 2.25rem;
}
.bottom-9 {
  bottom: 2.25rem;
}
.left-9 {
  left: 2.25rem;
}
.top-10 {
  top: 2.5rem;
}
.right-10 {
  right: 2.5rem;
}
.bottom-10 {
  bottom: 2.5rem;
}
.left-10 {
  left: 2.5rem;
}
.top-11 {
  top: 2.75rem;
}
.right-11 {
  right: 2.75rem;
}
.bottom-11 {
  bottom: 2.75rem;
}
.left-11 {
  left: 2.75rem;
}
.top-12 {
  top: 3rem;
}
.right-12 {
  right: 3rem;
}
.bottom-12 {
  bottom: 3rem;
}
.left-12 {
  left: 3rem;
}
.top-14 {
  top: 3.5rem;
}
.right-14 {
  right: 3.5rem;
}
.bottom-14 {
  bottom: 3.5rem;
}
.left-14 {
  left: 3.5rem;
}
.top-16 {
  top: 4rem;
}
.right-16 {
  right: 4rem;
}
.bottom-16 {
  bottom: 4rem;
}
.left-16 {
  left: 4rem;
}
.top-20 {
  top: 5rem;
}
.right-20 {
  right: 5rem;
}
.bottom-20 {
  bottom: 5rem;
}
.left-20 {
  left: 5rem;
}
.top-24 {
  top: 6rem;
}
.right-24 {
  right: 6rem;
}
.bottom-24 {
  bottom: 6rem;
}
.left-24 {
  left: 6rem;
}
.top-28 {
  top: 7rem;
}
.right-28 {
  right: 7rem;
}
.bottom-28 {
  bottom: 7rem;
}
.left-28 {
  left: 7rem;
}
.top-32 {
  top: 8rem;
}
.right-32 {
  right: 8rem;
}
.bottom-32 {
  bottom: 8rem;
}
.left-32 {
  left: 8rem;
}
.top-36 {
  top: 9rem;
}
.right-36 {
  right: 9rem;
}
.bottom-36 {
  bottom: 9rem;
}
.left-36 {
  left: 9rem;
}
.top-40 {
  top: 10rem;
}
.right-40 {
  right: 10rem;
}
.bottom-40 {
  bottom: 10rem;
}
.left-40 {
  left: 10rem;
}
.top-44 {
  top: 11rem;
}
.right-44 {
  right: 11rem;
}
.bottom-44 {
  bottom: 11rem;
}
.left-44 {
  left: 11rem;
}
.top-48 {
  top: 12rem;
}
.right-48 {
  right: 12rem;
}
.bottom-48 {
  bottom: 12rem;
}
.left-48 {
  left: 12rem;
}
.top-52 {
  top: 13rem;
}
.right-52 {
  right: 13rem;
}
.bottom-52 {
  bottom: 13rem;
}
.left-52 {
  left: 13rem;
}
.top-56 {
  top: 14rem;
}
.right-56 {
  right: 14rem;
}
.bottom-56 {
  bottom: 14rem;
}
.left-56 {
  left: 14rem;
}
.top-60 {
  top: 15rem;
}
.right-60 {
  right: 15rem;
}
.bottom-60 {
  bottom: 15rem;
}
.left-60 {
  left: 15rem;
}
.top-64 {
  top: 16rem;
}
.right-64 {
  right: 16rem;
}
.bottom-64 {
  bottom: 16rem;
}
.left-64 {
  left: 16rem;
}
.top-72 {
  top: 18rem;
}
.right-72 {
  right: 18rem;
}
.bottom-72 {
  bottom: 18rem;
}
.left-72 {
  left: 18rem;
}
.top-80 {
  top: 20rem;
}
.right-80 {
  right: 20rem;
}
.bottom-80 {
  bottom: 20rem;
}
.left-80 {
  left: 20rem;
}
.top-96 {
  top: 24rem;
}
.right-96 {
  right: 24rem;
}
.bottom-96 {
  bottom: 24rem;
}
.left-96 {
  left: 24rem;
}
.top-auto {
  top: auto;
}
.right-auto {
  right: auto;
}
.bottom-auto {
  bottom: auto;
}
.left-auto {
  left: auto;
}
.top-px {
  top: 1px;
}
.right-px {
  right: 1px;
}
.bottom-px {
  bottom: 1px;
}
.left-px {
  left: 1px;
}
.top-0\.5 {
  top: 0.125rem;
}
.right-0\.5 {
  right: 0.125rem;
}
.bottom-0\.5 {
  bottom: 0.125rem;
}
.left-0\.5 {
  left: 0.125rem;
}
.top-1\.5 {
  top: 0.375rem;
}
.right-1\.5 {
  right: 0.375rem;
}
.bottom-1\.5 {
  bottom: 0.375rem;
}
.left-1\.5 {
  left: 0.375rem;
}
.top-2\.5 {
  top: 0.625rem;
}
.right-2\.5 {
  right: 0.625rem;
}
.bottom-2\.5 {
  bottom: 0.625rem;
}
.left-2\.5 {
  left: 0.625rem;
}
.top-3\.5 {
  top: 0.875rem;
}
.right-3\.5 {
  right: 0.875rem;
}
.bottom-3\.5 {
  bottom: 0.875rem;
}
.left-3\.5 {
  left: 0.875rem;
}
.-top-0 {
  top: 0;
}
.-right-0 {
  right: 0;
}
.-bottom-0 {
  bottom: 0;
}
.-left-0 {
  left: 0;
}
.-top-1 {
  top: -0.25rem;
}
.-right-1 {
  right: -0.25rem;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.-left-1 {
  left: -0.25rem;
}
.-top-2 {
  top: -0.5rem;
}
.-right-2 {
  right: -0.5rem;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.-left-2 {
  left: -0.5rem;
}
.-top-3 {
  top: -0.75rem;
}
.-right-3 {
  right: -0.75rem;
}
.-bottom-3 {
  bottom: -0.75rem;
}
.-left-3 {
  left: -0.75rem;
}
.-top-4 {
  top: -1rem;
}
.-right-4 {
  right: -1rem;
}
.-bottom-4 {
  bottom: -1rem;
}
.-left-4 {
  left: -1rem;
}
.-top-5 {
  top: -1.25rem;
}
.-right-5 {
  right: -1.25rem;
}
.-bottom-5 {
  bottom: -1.25rem;
}
.-left-5 {
  left: -1.25rem;
}
.-top-6 {
  top: -1.5rem;
}
.-right-6 {
  right: -1.5rem;
}
.-bottom-6 {
  bottom: -1.5rem;
}
.-left-6 {
  left: -1.5rem;
}
.-top-7 {
  top: -1.75rem;
}
.-right-7 {
  right: -1.75rem;
}
.-bottom-7 {
  bottom: -1.75rem;
}
.-left-7 {
  left: -1.75rem;
}
.-top-8 {
  top: -2rem;
}
.-right-8 {
  right: -2rem;
}
.-bottom-8 {
  bottom: -2rem;
}
.-left-8 {
  left: -2rem;
}
.-top-9 {
  top: -2.25rem;
}
.-right-9 {
  right: -2.25rem;
}
.-bottom-9 {
  bottom: -2.25rem;
}
.-left-9 {
  left: -2.25rem;
}
.-top-10 {
  top: -2.5rem;
}
.-right-10 {
  right: -2.5rem;
}
.-bottom-10 {
  bottom: -2.5rem;
}
.-left-10 {
  left: -2.5rem;
}
.-top-11 {
  top: -2.75rem;
}
.-right-11 {
  right: -2.75rem;
}
.-bottom-11 {
  bottom: -2.75rem;
}
.-left-11 {
  left: -2.75rem;
}
.-top-12 {
  top: -3rem;
}
.-right-12 {
  right: -3rem;
}
.-bottom-12 {
  bottom: -3rem;
}
.-left-12 {
  left: -3rem;
}
.-top-14 {
  top: -3.5rem;
}
.-right-14 {
  right: -3.5rem;
}
.-bottom-14 {
  bottom: -3.5rem;
}
.-left-14 {
  left: -3.5rem;
}
.-top-16 {
  top: -4rem;
}
.-right-16 {
  right: -4rem;
}
.-bottom-16 {
  bottom: -4rem;
}
.-left-16 {
  left: -4rem;
}
.-top-20 {
  top: -5rem;
}
.-right-20 {
  right: -5rem;
}
.-bottom-20 {
  bottom: -5rem;
}
.-left-20 {
  left: -5rem;
}
.-top-24 {
  top: -6rem;
}
.-right-24 {
  right: -6rem;
}
.-bottom-24 {
  bottom: -6rem;
}
.-left-24 {
  left: -6rem;
}
.-top-28 {
  top: -7rem;
}
.-right-28 {
  right: -7rem;
}
.-bottom-28 {
  bottom: -7rem;
}
.-left-28 {
  left: -7rem;
}
.-top-32 {
  top: -8rem;
}
.-right-32 {
  right: -8rem;
}
.-bottom-32 {
  bottom: -8rem;
}
.-left-32 {
  left: -8rem;
}
.-top-36 {
  top: -9rem;
}
.-right-36 {
  right: -9rem;
}
.-bottom-36 {
  bottom: -9rem;
}
.-left-36 {
  left: -9rem;
}
.-top-40 {
  top: -10rem;
}
.-right-40 {
  right: -10rem;
}
.-bottom-40 {
  bottom: -10rem;
}
.-left-40 {
  left: -10rem;
}
.-top-44 {
  top: -11rem;
}
.-right-44 {
  right: -11rem;
}
.-bottom-44 {
  bottom: -11rem;
}
.-left-44 {
  left: -11rem;
}
.-top-48 {
  top: -12rem;
}
.-right-48 {
  right: -12rem;
}
.-bottom-48 {
  bottom: -12rem;
}
.-left-48 {
  left: -12rem;
}
.-top-52 {
  top: -13rem;
}
.-right-52 {
  right: -13rem;
}
.-bottom-52 {
  bottom: -13rem;
}
.-left-52 {
  left: -13rem;
}
.-top-56 {
  top: -14rem;
}
.-right-56 {
  right: -14rem;
}
.-bottom-56 {
  bottom: -14rem;
}
.-left-56 {
  left: -14rem;
}
.-top-60 {
  top: -15rem;
}
.-right-60 {
  right: -15rem;
}
.-bottom-60 {
  bottom: -15rem;
}
.-left-60 {
  left: -15rem;
}
.-top-64 {
  top: -16rem;
}
.-right-64 {
  right: -16rem;
}
.-bottom-64 {
  bottom: -16rem;
}
.-left-64 {
  left: -16rem;
}
.-top-72 {
  top: -18rem;
}
.-right-72 {
  right: -18rem;
}
.-bottom-72 {
  bottom: -18rem;
}
.-left-72 {
  left: -18rem;
}
.-top-80 {
  top: -20rem;
}
.-right-80 {
  right: -20rem;
}
.-bottom-80 {
  bottom: -20rem;
}
.-left-80 {
  left: -20rem;
}
.-top-96 {
  top: -24rem;
}
.-right-96 {
  right: -24rem;
}
.-bottom-96 {
  bottom: -24rem;
}
.-left-96 {
  left: -24rem;
}
.-top-px {
  top: -1px;
}
.-right-px {
  right: -1px;
}
.-bottom-px {
  bottom: -1px;
}
.-left-px {
  left: -1px;
}
.-top-0\.5 {
  top: -0.125rem;
}
.-right-0\.5 {
  right: -0.125rem;
}
.-bottom-0\.5 {
  bottom: -0.125rem;
}
.-left-0\.5 {
  left: -0.125rem;
}
.-top-1\.5 {
  top: -0.375rem;
}
.-right-1\.5 {
  right: -0.375rem;
}
.-bottom-1\.5 {
  bottom: -0.375rem;
}
.-left-1\.5 {
  left: -0.375rem;
}
.-top-2\.5 {
  top: -0.625rem;
}
.-right-2\.5 {
  right: -0.625rem;
}
.-bottom-2\.5 {
  bottom: -0.625rem;
}
.-left-2\.5 {
  left: -0.625rem;
}
.-top-3\.5 {
  top: -0.875rem;
}
.-right-3\.5 {
  right: -0.875rem;
}
.-bottom-3\.5 {
  bottom: -0.875rem;
}
.-left-3\.5 {
  left: -0.875rem;
}
.top-1\/2 {
  top: 50%;
}
.right-1\/2 {
  right: 50%;
}
.bottom-1\/2 {
  bottom: 50%;
}
.left-1\/2 {
  left: 50%;
}
.top-1\/3 {
  top: 33.333333%;
}
.right-1\/3 {
  right: 33.333333%;
}
.bottom-1\/3 {
  bottom: 33.333333%;
}
.left-1\/3 {
  left: 33.333333%;
}
.top-2\/3 {
  top: 66.666667%;
}
.right-2\/3 {
  right: 66.666667%;
}
.bottom-2\/3 {
  bottom: 66.666667%;
}
.left-2\/3 {
  left: 66.666667%;
}
.top-1\/4 {
  top: 25%;
}
.right-1\/4 {
  right: 25%;
}
.bottom-1\/4 {
  bottom: 25%;
}
.left-1\/4 {
  left: 25%;
}
.top-2\/4 {
  top: 50%;
}
.right-2\/4 {
  right: 50%;
}
.bottom-2\/4 {
  bottom: 50%;
}
.left-2\/4 {
  left: 50%;
}
.top-3\/4 {
  top: 75%;
}
.right-3\/4 {
  right: 75%;
}
.bottom-3\/4 {
  bottom: 75%;
}
.left-3\/4 {
  left: 75%;
}
.top-full {
  top: 100%;
}
.right-full {
  right: 100%;
}
.bottom-full {
  bottom: 100%;
}
.left-full {
  left: 100%;
}
.-top-1\/2 {
  top: -50%;
}
.-right-1\/2 {
  right: -50%;
}
.-bottom-1\/2 {
  bottom: -50%;
}
.-left-1\/2 {
  left: -50%;
}
.-top-1\/3 {
  top: -33.333333%;
}
.-right-1\/3 {
  right: -33.333333%;
}
.-bottom-1\/3 {
  bottom: -33.333333%;
}
.-left-1\/3 {
  left: -33.333333%;
}
.-top-2\/3 {
  top: -66.666667%;
}
.-right-2\/3 {
  right: -66.666667%;
}
.-bottom-2\/3 {
  bottom: -66.666667%;
}
.-left-2\/3 {
  left: -66.666667%;
}
.-top-1\/4 {
  top: -25%;
}
.-right-1\/4 {
  right: -25%;
}
.-bottom-1\/4 {
  bottom: -25%;
}
.-left-1\/4 {
  left: -25%;
}
.-top-2\/4 {
  top: -50%;
}
.-right-2\/4 {
  right: -50%;
}
.-bottom-2\/4 {
  bottom: -50%;
}
.-left-2\/4 {
  left: -50%;
}
.-top-3\/4 {
  top: -75%;
}
.-right-3\/4 {
  right: -75%;
}
.-bottom-3\/4 {
  bottom: -75%;
}
.-left-3\/4 {
  left: -75%;
}
.-top-full {
  top: -100%;
}
.-right-full {
  right: -100%;
}
.-bottom-full {
  bottom: -100%;
}
.-left-full {
  left: -100%;
}
.resize-none {
  resize: none;
}
.resize-y {
  resize: vertical;
}
.resize-x {
  resize: horizontal;
}
.resize {
  resize: both;
}
* {
  --tw-shadow: 0 0 transparent;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow,
.shadow-sm {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg,
.shadow-md {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl,
.shadow-xl {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-inner,
.shadow-none {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 transparent;
}
.shadow-vivid-blue {
  --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
}
.shadow-bottom-nav,
.shadow-vivid-blue {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-bottom-nav {
  --tw-shadow: 0px -10px 20px #171e34;
}
.shadow-mobile-buttons {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
}
.group:hover .group-hover\:shadow-sm,
.shadow-mobile-buttons {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.group:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.group:hover .group-hover\:shadow,
.group:hover .group-hover\:shadow-md {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.group:hover .group-hover\:shadow-lg,
.group:hover .group-hover\:shadow-xl {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.group:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.group:hover .group-hover\:shadow-2xl,
.group:hover .group-hover\:shadow-inner {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.group:hover .group-hover\:shadow-none {
  --tw-shadow: 0 0 transparent;
}
.group:hover .group-hover\:shadow-none,
.group:hover .group-hover\:shadow-vivid-blue {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-vivid-blue {
  --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
}
.group:hover .group-hover\:shadow-bottom-nav {
  --tw-shadow: 0px -10px 20px #171e34;
}
.group:hover .group-hover\:shadow-bottom-nav,
.group:hover .group-hover\:shadow-mobile-buttons {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-mobile-buttons {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
}
.focus-within\:shadow-sm:focus-within {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow:focus-within {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-md:focus-within {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-lg:focus-within {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-xl:focus-within {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-2xl:focus-within {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-inner:focus-within {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-vivid-blue:focus-within {
  --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-bottom-nav:focus-within {
  --tw-shadow: 0px -10px 20px #171e34;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus-within\:shadow-mobile-buttons:focus-within {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.hover\:shadow-sm:hover,
.hover\:shadow:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.hover\:shadow-lg:hover,
.hover\:shadow-md:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.hover\:shadow-2xl:hover,
.hover\:shadow-xl:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.hover\:shadow-inner:hover,
.hover\:shadow-none:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow-none:hover {
  --tw-shadow: 0 0 transparent;
}
.hover\:shadow-vivid-blue:hover {
  --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
}
.hover\:shadow-bottom-nav:hover,
.hover\:shadow-vivid-blue:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:shadow-bottom-nav:hover {
  --tw-shadow: 0px -10px 20px #171e34;
}
.hover\:shadow-mobile-buttons:hover {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
}
.focus\:shadow-sm:focus,
.hover\:shadow-mobile-buttons:hover {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.focus\:shadow-md:focus,
.focus\:shadow:focus {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.focus\:shadow-lg:focus,
.focus\:shadow-xl:focus {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.focus\:shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.focus\:shadow-2xl:focus,
.focus\:shadow-inner:focus {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.focus\:shadow-none:focus {
  --tw-shadow: 0 0 transparent;
}
.focus\:shadow-none:focus,
.focus\:shadow-vivid-blue:focus {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-vivid-blue:focus {
  --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
}
.focus\:shadow-bottom-nav:focus {
  --tw-shadow: 0px -10px 20px #171e34;
}
.focus\:shadow-bottom-nav:focus,
.focus\:shadow-mobile-buttons:focus {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.focus\:shadow-mobile-buttons:focus {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
}
* {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}
.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.ring-0,
.ring-1 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-2,
.ring-4 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring,
.ring-8 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-inset {
  --tw-ring-inset: inset;
}
.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.focus\:ring-0:focus,
.focus\:ring-1:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-2:focus,
.focus\:ring-4:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-8:focus,
.focus\:ring:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}
.ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}
.ring-offset-current {
  --tw-ring-offset-color: currentColor;
}
.ring-offset-black {
  --tw-ring-offset-color: #000;
}
.ring-offset-white {
  --tw-ring-offset-color: #fff;
}
.ring-offset-gray-50 {
  --tw-ring-offset-color: #f9fafb;
}
.ring-offset-gray-100 {
  --tw-ring-offset-color: #f3f4f6;
}
.ring-offset-gray-200 {
  --tw-ring-offset-color: #e5e7eb;
}
.ring-offset-gray-300 {
  --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
}
.ring-offset-gray-400 {
  --tw-ring-offset-color: #9ca3af;
}
.ring-offset-gray-500 {
  --tw-ring-offset-color: #6b7280;
}
.ring-offset-gray-600 {
  --tw-ring-offset-color: #4b5563;
}
.ring-offset-gray-700 {
  --tw-ring-offset-color: #374151;
}
.ring-offset-gray-800 {
  --tw-ring-offset-color: #1f2937;
}
.ring-offset-gray-900 {
  --tw-ring-offset-color: #6d728b;
}
.ring-offset-red-50 {
  --tw-ring-offset-color: #fef2f2;
}
.ring-offset-red-100 {
  --tw-ring-offset-color: #fee2e2;
}
.ring-offset-red-200 {
  --tw-ring-offset-color: #fecaca;
}
.ring-offset-red-300 {
  --tw-ring-offset-color: #fca5a5;
}
.ring-offset-red-400 {
  --tw-ring-offset-color: #f87171;
}
.ring-offset-red-500 {
  --tw-ring-offset-color: #ef4444;
}
.ring-offset-red-600 {
  --tw-ring-offset-color: #dc2626;
}
.ring-offset-red-700 {
  --tw-ring-offset-color: #b91c1c;
}
.ring-offset-red-800 {
  --tw-ring-offset-color: #991b1b;
}
.ring-offset-red-900 {
  --tw-ring-offset-color: #7f1d1d;
}
.ring-offset-yellow-50 {
  --tw-ring-offset-color: #fffbeb;
}
.ring-offset-yellow-100 {
  --tw-ring-offset-color: #fef3c7;
}
.ring-offset-yellow-200 {
  --tw-ring-offset-color: #fde68a;
}
.ring-offset-yellow-300 {
  --tw-ring-offset-color: #fcd34d;
}
.ring-offset-yellow-400 {
  --tw-ring-offset-color: #fbbf24;
}
.ring-offset-yellow-500 {
  --tw-ring-offset-color: #f59e0b;
}
.ring-offset-yellow-600 {
  --tw-ring-offset-color: #d97706;
}
.ring-offset-yellow-700 {
  --tw-ring-offset-color: #b45309;
}
.ring-offset-yellow-800 {
  --tw-ring-offset-color: #92400e;
}
.ring-offset-yellow-900 {
  --tw-ring-offset-color: #78350f;
}
.ring-offset-green-50 {
  --tw-ring-offset-color: #ecfdf5;
}
.ring-offset-green-100 {
  --tw-ring-offset-color: #d1fae5;
}
.ring-offset-green-200 {
  --tw-ring-offset-color: #a7f3d0;
}
.ring-offset-green-300 {
  --tw-ring-offset-color: #6ee7b7;
}
.ring-offset-green-400 {
  --tw-ring-offset-color: #34d399;
}
.ring-offset-green-500 {
  --tw-ring-offset-color: #10b981;
}
.ring-offset-green-600 {
  --tw-ring-offset-color: #059669;
}
.ring-offset-green-700 {
  --tw-ring-offset-color: #047857;
}
.ring-offset-green-800 {
  --tw-ring-offset-color: #065f46;
}
.ring-offset-green-900 {
  --tw-ring-offset-color: #064e3b;
}
.ring-offset-blue-50 {
  --tw-ring-offset-color: #eff6ff;
}
.ring-offset-blue-100 {
  --tw-ring-offset-color: #dbeafe;
}
.ring-offset-blue-200 {
  --tw-ring-offset-color: #bfdbfe;
}
.ring-offset-blue-300 {
  --tw-ring-offset-color: #93c5fd;
}
.ring-offset-blue-400 {
  --tw-ring-offset-color: #60a5fa;
}
.ring-offset-blue-500 {
  --tw-ring-offset-color: #3b82f6;
}
.ring-offset-blue-600 {
  --tw-ring-offset-color: #2563eb;
}
.ring-offset-blue-700 {
  --tw-ring-offset-color: #1d4ed8;
}
.ring-offset-blue-800 {
  --tw-ring-offset-color: #1e40af;
}
.ring-offset-blue-900 {
  --tw-ring-offset-color: #1e3a8a;
}
.ring-offset-indigo-50 {
  --tw-ring-offset-color: #eef2ff;
}
.ring-offset-indigo-100 {
  --tw-ring-offset-color: #e0e7ff;
}
.ring-offset-indigo-200 {
  --tw-ring-offset-color: #c7d2fe;
}
.ring-offset-indigo-300 {
  --tw-ring-offset-color: #a5b4fc;
}
.ring-offset-indigo-400 {
  --tw-ring-offset-color: #818cf8;
}
.ring-offset-indigo-500 {
  --tw-ring-offset-color: #6366f1;
}
.ring-offset-indigo-600 {
  --tw-ring-offset-color: #4f46e5;
}
.ring-offset-indigo-700 {
  --tw-ring-offset-color: #4338ca;
}
.ring-offset-indigo-800 {
  --tw-ring-offset-color: #3730a3;
}
.ring-offset-indigo-900 {
  --tw-ring-offset-color: #312e81;
}
.ring-offset-purple-50 {
  --tw-ring-offset-color: #f5f3ff;
}
.ring-offset-purple-100 {
  --tw-ring-offset-color: #ede9fe;
}
.ring-offset-purple-200 {
  --tw-ring-offset-color: #ddd6fe;
}
.ring-offset-purple-300 {
  --tw-ring-offset-color: #c4b5fd;
}
.ring-offset-purple-400 {
  --tw-ring-offset-color: #a78bfa;
}
.ring-offset-purple-500 {
  --tw-ring-offset-color: #8b5cf6;
}
.ring-offset-purple-600 {
  --tw-ring-offset-color: #7c3aed;
}
.ring-offset-purple-700 {
  --tw-ring-offset-color: #6d28d9;
}
.ring-offset-purple-800 {
  --tw-ring-offset-color: #5b21b6;
}
.ring-offset-purple-900 {
  --tw-ring-offset-color: #4c1d95;
}
.ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8;
}
.ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3;
}
.ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8;
}
.ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4;
}
.ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6;
}
.ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899;
}
.ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777;
}
.ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d;
}
.ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d;
}
.ring-offset-pink-900 {
  --tw-ring-offset-color: #831843;
}
.ring-offset-vivid-blue {
  --tw-ring-offset-color: #212eee;
}
.ring-offset-vivid-blue-500 {
  --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
}
.ring-offset-background-primary {
  --tw-ring-offset-color: var(--background-primary);
}
.ring-offset-main-background {
  --tw-ring-offset-color: #121729;
}
.ring-offset-dark-blue {
  --tw-ring-offset-color: #1e2741;
}
.ring-offset-blue-grey {
  --tw-ring-offset-color: #2f3a5c;
}
.ring-offset-text-grey {
  --tw-ring-offset-color: #a4a7c1;
}
.ring-offset-pale-grey-two {
  --tw-ring-offset-color: #eeebff;
}
.ring-offset-pale-grey {
  --tw-ring-offset-color: #a8afcc;
}
.ring-offset-btc {
  --tw-ring-offset-color: #f7931a;
}
.ring-offset-btc-symbol {
  --tw-ring-offset-color: #f5b300;
}
.ring-offset-paxg {
  --tw-ring-offset-color: #ffc54e;
}
.ring-offset-usdc {
  --tw-ring-offset-color: #2775ca;
}
.ring-offset-black-navy {
  --tw-ring-offset-color: #101239;
}
.ring-offset-ghost-white {
  --tw-ring-offset-color: #f6f5ff;
}
.ring-offset-spring-green {
  --tw-ring-offset-color: #1be56d;
}
.ring-offset-light-slate-gray {
  --tw-ring-offset-color: #888b94;
}
.ring-offset-body-background {
  --tw-ring-offset-color: var(--body-background);
}
.ring-offset-background {
  --tw-ring-offset-color: var(--background);
}
.ring-offset-input-background {
  --tw-ring-offset-color: var(--input-background);
}
.ring-offset-body-text {
  --tw-ring-offset-color: var(--body-text);
}
.ring-offset-header-text {
  --tw-ring-offset-color: var(--header-text);
}
.ring-offset-primary {
  --tw-ring-offset-color: var(--primary);
}
.ring-offset-secondary {
  --tw-ring-offset-color: var(--secondary);
}
.ring-offset-tertiary {
  --tw-ring-offset-color: var(--tertiary);
}
.ring-offset-primary-hover {
  --tw-ring-offset-color: var(--primary-hover);
}
.ring-offset-secondary-hover {
  --tw-ring-offset-color: var(--secondary-hover);
}
.ring-offset-tertiary-hover {
  --tw-ring-offset-color: var(--tertiary-hover);
}
.ring-offset-input-text {
  --tw-ring-offset-color: var(--input-text);
}
.ring-offset-success {
  --tw-ring-offset-color: var(--success);
}
.ring-offset-error {
  --tw-ring-offset-color: var(--error);
}
.ring-offset-custom-loader {
  --tw-ring-offset-color: var(--custom-loader);
}
.ring-offset-violet {
  --tw-ring-offset-color: #9421ee;
}
.ring-offset-violet-hover {
  --tw-ring-offset-color: #550b8e;
}
.ring-offset-skeleton-loader-from {
  --tw-ring-offset-color: var(--skeleton-loader-from);
}
.ring-offset-skeleton-loader-to {
  --tw-ring-offset-color: var(--skeleton-loader-to);
}
.ring-offset-background50 {
  --tw-ring-offset-color: var(--background50);
}
.ring-offset-reward-blue-start {
  --tw-ring-offset-color: #626bff;
}
.ring-offset-reward-blue-end {
  --tw-ring-offset-color: #212eee;
}
.ring-offset-reward-silver-start {
  --tw-ring-offset-color: #484848;
}
.ring-offset-reward-silver-end {
  --tw-ring-offset-color: #acacac;
}
.ring-offset-reward-gold-start {
  --tw-ring-offset-color: #ffdf8a;
}
.ring-offset-reward-gold-end {
  --tw-ring-offset-color: #ffc425;
}
.ring-offset-reward-platinum-start {
  --tw-ring-offset-color: #959595;
}
.ring-offset-reward-platinum-end {
  --tw-ring-offset-color: #f8f8f8;
}
.ring-offset-reward-vip-start {
  --tw-ring-offset-color: #212eee;
}
.ring-offset-reward-vip-end {
  --tw-ring-offset-color: #c521ee;
}
.ring-offset-fee-breakdown-bg {
  --tw-ring-offset-color: #182139;
}
.ring-offset-light-grey {
  --tw-ring-offset-color: #e5e7eb;
}
.ring-offset-disabled-button-background {
  --tw-ring-offset-color: #1f2a98;
}
.ring-offset-disabled-button-text {
  --tw-ring-offset-color: #8e93a0;
}
.focus-within\:ring-offset-transparent:focus-within {
  --tw-ring-offset-color: transparent;
}
.focus-within\:ring-offset-current:focus-within {
  --tw-ring-offset-color: currentColor;
}
.focus-within\:ring-offset-black:focus-within {
  --tw-ring-offset-color: #000;
}
.focus-within\:ring-offset-white:focus-within {
  --tw-ring-offset-color: #fff;
}
.focus-within\:ring-offset-gray-50:focus-within {
  --tw-ring-offset-color: #f9fafb;
}
.focus-within\:ring-offset-gray-100:focus-within {
  --tw-ring-offset-color: #f3f4f6;
}
.focus-within\:ring-offset-gray-200:focus-within {
  --tw-ring-offset-color: #e5e7eb;
}
.focus-within\:ring-offset-gray-300:focus-within {
  --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus-within\:ring-offset-gray-400:focus-within {
  --tw-ring-offset-color: #9ca3af;
}
.focus-within\:ring-offset-gray-500:focus-within {
  --tw-ring-offset-color: #6b7280;
}
.focus-within\:ring-offset-gray-600:focus-within {
  --tw-ring-offset-color: #4b5563;
}
.focus-within\:ring-offset-gray-700:focus-within {
  --tw-ring-offset-color: #374151;
}
.focus-within\:ring-offset-gray-800:focus-within {
  --tw-ring-offset-color: #1f2937;
}
.focus-within\:ring-offset-gray-900:focus-within {
  --tw-ring-offset-color: #6d728b;
}
.focus-within\:ring-offset-red-50:focus-within {
  --tw-ring-offset-color: #fef2f2;
}
.focus-within\:ring-offset-red-100:focus-within {
  --tw-ring-offset-color: #fee2e2;
}
.focus-within\:ring-offset-red-200:focus-within {
  --tw-ring-offset-color: #fecaca;
}
.focus-within\:ring-offset-red-300:focus-within {
  --tw-ring-offset-color: #fca5a5;
}
.focus-within\:ring-offset-red-400:focus-within {
  --tw-ring-offset-color: #f87171;
}
.focus-within\:ring-offset-red-500:focus-within {
  --tw-ring-offset-color: #ef4444;
}
.focus-within\:ring-offset-red-600:focus-within {
  --tw-ring-offset-color: #dc2626;
}
.focus-within\:ring-offset-red-700:focus-within {
  --tw-ring-offset-color: #b91c1c;
}
.focus-within\:ring-offset-red-800:focus-within {
  --tw-ring-offset-color: #991b1b;
}
.focus-within\:ring-offset-red-900:focus-within {
  --tw-ring-offset-color: #7f1d1d;
}
.focus-within\:ring-offset-yellow-50:focus-within {
  --tw-ring-offset-color: #fffbeb;
}
.focus-within\:ring-offset-yellow-100:focus-within {
  --tw-ring-offset-color: #fef3c7;
}
.focus-within\:ring-offset-yellow-200:focus-within {
  --tw-ring-offset-color: #fde68a;
}
.focus-within\:ring-offset-yellow-300:focus-within {
  --tw-ring-offset-color: #fcd34d;
}
.focus-within\:ring-offset-yellow-400:focus-within {
  --tw-ring-offset-color: #fbbf24;
}
.focus-within\:ring-offset-yellow-500:focus-within {
  --tw-ring-offset-color: #f59e0b;
}
.focus-within\:ring-offset-yellow-600:focus-within {
  --tw-ring-offset-color: #d97706;
}
.focus-within\:ring-offset-yellow-700:focus-within {
  --tw-ring-offset-color: #b45309;
}
.focus-within\:ring-offset-yellow-800:focus-within {
  --tw-ring-offset-color: #92400e;
}
.focus-within\:ring-offset-yellow-900:focus-within {
  --tw-ring-offset-color: #78350f;
}
.focus-within\:ring-offset-green-50:focus-within {
  --tw-ring-offset-color: #ecfdf5;
}
.focus-within\:ring-offset-green-100:focus-within {
  --tw-ring-offset-color: #d1fae5;
}
.focus-within\:ring-offset-green-200:focus-within {
  --tw-ring-offset-color: #a7f3d0;
}
.focus-within\:ring-offset-green-300:focus-within {
  --tw-ring-offset-color: #6ee7b7;
}
.focus-within\:ring-offset-green-400:focus-within {
  --tw-ring-offset-color: #34d399;
}
.focus-within\:ring-offset-green-500:focus-within {
  --tw-ring-offset-color: #10b981;
}
.focus-within\:ring-offset-green-600:focus-within {
  --tw-ring-offset-color: #059669;
}
.focus-within\:ring-offset-green-700:focus-within {
  --tw-ring-offset-color: #047857;
}
.focus-within\:ring-offset-green-800:focus-within {
  --tw-ring-offset-color: #065f46;
}
.focus-within\:ring-offset-green-900:focus-within {
  --tw-ring-offset-color: #064e3b;
}
.focus-within\:ring-offset-blue-50:focus-within {
  --tw-ring-offset-color: #eff6ff;
}
.focus-within\:ring-offset-blue-100:focus-within {
  --tw-ring-offset-color: #dbeafe;
}
.focus-within\:ring-offset-blue-200:focus-within {
  --tw-ring-offset-color: #bfdbfe;
}
.focus-within\:ring-offset-blue-300:focus-within {
  --tw-ring-offset-color: #93c5fd;
}
.focus-within\:ring-offset-blue-400:focus-within {
  --tw-ring-offset-color: #60a5fa;
}
.focus-within\:ring-offset-blue-500:focus-within {
  --tw-ring-offset-color: #3b82f6;
}
.focus-within\:ring-offset-blue-600:focus-within {
  --tw-ring-offset-color: #2563eb;
}
.focus-within\:ring-offset-blue-700:focus-within {
  --tw-ring-offset-color: #1d4ed8;
}
.focus-within\:ring-offset-blue-800:focus-within {
  --tw-ring-offset-color: #1e40af;
}
.focus-within\:ring-offset-blue-900:focus-within {
  --tw-ring-offset-color: #1e3a8a;
}
.focus-within\:ring-offset-indigo-50:focus-within {
  --tw-ring-offset-color: #eef2ff;
}
.focus-within\:ring-offset-indigo-100:focus-within {
  --tw-ring-offset-color: #e0e7ff;
}
.focus-within\:ring-offset-indigo-200:focus-within {
  --tw-ring-offset-color: #c7d2fe;
}
.focus-within\:ring-offset-indigo-300:focus-within {
  --tw-ring-offset-color: #a5b4fc;
}
.focus-within\:ring-offset-indigo-400:focus-within {
  --tw-ring-offset-color: #818cf8;
}
.focus-within\:ring-offset-indigo-500:focus-within {
  --tw-ring-offset-color: #6366f1;
}
.focus-within\:ring-offset-indigo-600:focus-within {
  --tw-ring-offset-color: #4f46e5;
}
.focus-within\:ring-offset-indigo-700:focus-within {
  --tw-ring-offset-color: #4338ca;
}
.focus-within\:ring-offset-indigo-800:focus-within {
  --tw-ring-offset-color: #3730a3;
}
.focus-within\:ring-offset-indigo-900:focus-within {
  --tw-ring-offset-color: #312e81;
}
.focus-within\:ring-offset-purple-50:focus-within {
  --tw-ring-offset-color: #f5f3ff;
}
.focus-within\:ring-offset-purple-100:focus-within {
  --tw-ring-offset-color: #ede9fe;
}
.focus-within\:ring-offset-purple-200:focus-within {
  --tw-ring-offset-color: #ddd6fe;
}
.focus-within\:ring-offset-purple-300:focus-within {
  --tw-ring-offset-color: #c4b5fd;
}
.focus-within\:ring-offset-purple-400:focus-within {
  --tw-ring-offset-color: #a78bfa;
}
.focus-within\:ring-offset-purple-500:focus-within {
  --tw-ring-offset-color: #8b5cf6;
}
.focus-within\:ring-offset-purple-600:focus-within {
  --tw-ring-offset-color: #7c3aed;
}
.focus-within\:ring-offset-purple-700:focus-within {
  --tw-ring-offset-color: #6d28d9;
}
.focus-within\:ring-offset-purple-800:focus-within {
  --tw-ring-offset-color: #5b21b6;
}
.focus-within\:ring-offset-purple-900:focus-within {
  --tw-ring-offset-color: #4c1d95;
}
.focus-within\:ring-offset-pink-50:focus-within {
  --tw-ring-offset-color: #fdf2f8;
}
.focus-within\:ring-offset-pink-100:focus-within {
  --tw-ring-offset-color: #fce7f3;
}
.focus-within\:ring-offset-pink-200:focus-within {
  --tw-ring-offset-color: #fbcfe8;
}
.focus-within\:ring-offset-pink-300:focus-within {
  --tw-ring-offset-color: #f9a8d4;
}
.focus-within\:ring-offset-pink-400:focus-within {
  --tw-ring-offset-color: #f472b6;
}
.focus-within\:ring-offset-pink-500:focus-within {
  --tw-ring-offset-color: #ec4899;
}
.focus-within\:ring-offset-pink-600:focus-within {
  --tw-ring-offset-color: #db2777;
}
.focus-within\:ring-offset-pink-700:focus-within {
  --tw-ring-offset-color: #be185d;
}
.focus-within\:ring-offset-pink-800:focus-within {
  --tw-ring-offset-color: #9d174d;
}
.focus-within\:ring-offset-pink-900:focus-within {
  --tw-ring-offset-color: #831843;
}
.focus-within\:ring-offset-vivid-blue:focus-within {
  --tw-ring-offset-color: #212eee;
}
.focus-within\:ring-offset-vivid-blue-500:focus-within {
  --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
}
.focus-within\:ring-offset-background-primary:focus-within {
  --tw-ring-offset-color: var(--background-primary);
}
.focus-within\:ring-offset-main-background:focus-within {
  --tw-ring-offset-color: #121729;
}
.focus-within\:ring-offset-dark-blue:focus-within {
  --tw-ring-offset-color: #1e2741;
}
.focus-within\:ring-offset-blue-grey:focus-within {
  --tw-ring-offset-color: #2f3a5c;
}
.focus-within\:ring-offset-text-grey:focus-within {
  --tw-ring-offset-color: #a4a7c1;
}
.focus-within\:ring-offset-pale-grey-two:focus-within {
  --tw-ring-offset-color: #eeebff;
}
.focus-within\:ring-offset-pale-grey:focus-within {
  --tw-ring-offset-color: #a8afcc;
}
.focus-within\:ring-offset-btc:focus-within {
  --tw-ring-offset-color: #f7931a;
}
.focus-within\:ring-offset-btc-symbol:focus-within {
  --tw-ring-offset-color: #f5b300;
}
.focus-within\:ring-offset-paxg:focus-within {
  --tw-ring-offset-color: #ffc54e;
}
.focus-within\:ring-offset-usdc:focus-within {
  --tw-ring-offset-color: #2775ca;
}
.focus-within\:ring-offset-black-navy:focus-within {
  --tw-ring-offset-color: #101239;
}
.focus-within\:ring-offset-ghost-white:focus-within {
  --tw-ring-offset-color: #f6f5ff;
}
.focus-within\:ring-offset-spring-green:focus-within {
  --tw-ring-offset-color: #1be56d;
}
.focus-within\:ring-offset-light-slate-gray:focus-within {
  --tw-ring-offset-color: #888b94;
}
.focus-within\:ring-offset-body-background:focus-within {
  --tw-ring-offset-color: var(--body-background);
}
.focus-within\:ring-offset-background:focus-within {
  --tw-ring-offset-color: var(--background);
}
.focus-within\:ring-offset-input-background:focus-within {
  --tw-ring-offset-color: var(--input-background);
}
.focus-within\:ring-offset-body-text:focus-within {
  --tw-ring-offset-color: var(--body-text);
}
.focus-within\:ring-offset-header-text:focus-within {
  --tw-ring-offset-color: var(--header-text);
}
.focus-within\:ring-offset-primary:focus-within {
  --tw-ring-offset-color: var(--primary);
}
.focus-within\:ring-offset-secondary:focus-within {
  --tw-ring-offset-color: var(--secondary);
}
.focus-within\:ring-offset-tertiary:focus-within {
  --tw-ring-offset-color: var(--tertiary);
}
.focus-within\:ring-offset-primary-hover:focus-within {
  --tw-ring-offset-color: var(--primary-hover);
}
.focus-within\:ring-offset-secondary-hover:focus-within {
  --tw-ring-offset-color: var(--secondary-hover);
}
.focus-within\:ring-offset-tertiary-hover:focus-within {
  --tw-ring-offset-color: var(--tertiary-hover);
}
.focus-within\:ring-offset-input-text:focus-within {
  --tw-ring-offset-color: var(--input-text);
}
.focus-within\:ring-offset-success:focus-within {
  --tw-ring-offset-color: var(--success);
}
.focus-within\:ring-offset-error:focus-within {
  --tw-ring-offset-color: var(--error);
}
.focus-within\:ring-offset-custom-loader:focus-within {
  --tw-ring-offset-color: var(--custom-loader);
}
.focus-within\:ring-offset-violet:focus-within {
  --tw-ring-offset-color: #9421ee;
}
.focus-within\:ring-offset-violet-hover:focus-within {
  --tw-ring-offset-color: #550b8e;
}
.focus-within\:ring-offset-skeleton-loader-from:focus-within {
  --tw-ring-offset-color: var(--skeleton-loader-from);
}
.focus-within\:ring-offset-skeleton-loader-to:focus-within {
  --tw-ring-offset-color: var(--skeleton-loader-to);
}
.focus-within\:ring-offset-background50:focus-within {
  --tw-ring-offset-color: var(--background50);
}
.focus-within\:ring-offset-reward-blue-start:focus-within {
  --tw-ring-offset-color: #626bff;
}
.focus-within\:ring-offset-reward-blue-end:focus-within {
  --tw-ring-offset-color: #212eee;
}
.focus-within\:ring-offset-reward-silver-start:focus-within {
  --tw-ring-offset-color: #484848;
}
.focus-within\:ring-offset-reward-silver-end:focus-within {
  --tw-ring-offset-color: #acacac;
}
.focus-within\:ring-offset-reward-gold-start:focus-within {
  --tw-ring-offset-color: #ffdf8a;
}
.focus-within\:ring-offset-reward-gold-end:focus-within {
  --tw-ring-offset-color: #ffc425;
}
.focus-within\:ring-offset-reward-platinum-start:focus-within {
  --tw-ring-offset-color: #959595;
}
.focus-within\:ring-offset-reward-platinum-end:focus-within {
  --tw-ring-offset-color: #f8f8f8;
}
.focus-within\:ring-offset-reward-vip-start:focus-within {
  --tw-ring-offset-color: #212eee;
}
.focus-within\:ring-offset-reward-vip-end:focus-within {
  --tw-ring-offset-color: #c521ee;
}
.focus-within\:ring-offset-fee-breakdown-bg:focus-within {
  --tw-ring-offset-color: #182139;
}
.focus-within\:ring-offset-light-grey:focus-within {
  --tw-ring-offset-color: #e5e7eb;
}
.focus-within\:ring-offset-disabled-button-background:focus-within {
  --tw-ring-offset-color: #1f2a98;
}
.focus-within\:ring-offset-disabled-button-text:focus-within {
  --tw-ring-offset-color: #8e93a0;
}
.focus\:ring-offset-transparent:focus {
  --tw-ring-offset-color: transparent;
}
.focus\:ring-offset-current:focus {
  --tw-ring-offset-color: currentColor;
}
.focus\:ring-offset-black:focus {
  --tw-ring-offset-color: #000;
}
.focus\:ring-offset-white:focus {
  --tw-ring-offset-color: #fff;
}
.focus\:ring-offset-gray-50:focus {
  --tw-ring-offset-color: #f9fafb;
}
.focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}
.focus\:ring-offset-gray-200:focus {
  --tw-ring-offset-color: #e5e7eb;
}
.focus\:ring-offset-gray-300:focus {
  --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:ring-offset-gray-400:focus {
  --tw-ring-offset-color: #9ca3af;
}
.focus\:ring-offset-gray-500:focus {
  --tw-ring-offset-color: #6b7280;
}
.focus\:ring-offset-gray-600:focus {
  --tw-ring-offset-color: #4b5563;
}
.focus\:ring-offset-gray-700:focus {
  --tw-ring-offset-color: #374151;
}
.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #1f2937;
}
.focus\:ring-offset-gray-900:focus {
  --tw-ring-offset-color: #6d728b;
}
.focus\:ring-offset-red-50:focus {
  --tw-ring-offset-color: #fef2f2;
}
.focus\:ring-offset-red-100:focus {
  --tw-ring-offset-color: #fee2e2;
}
.focus\:ring-offset-red-200:focus {
  --tw-ring-offset-color: #fecaca;
}
.focus\:ring-offset-red-300:focus {
  --tw-ring-offset-color: #fca5a5;
}
.focus\:ring-offset-red-400:focus {
  --tw-ring-offset-color: #f87171;
}
.focus\:ring-offset-red-500:focus {
  --tw-ring-offset-color: #ef4444;
}
.focus\:ring-offset-red-600:focus {
  --tw-ring-offset-color: #dc2626;
}
.focus\:ring-offset-red-700:focus {
  --tw-ring-offset-color: #b91c1c;
}
.focus\:ring-offset-red-800:focus {
  --tw-ring-offset-color: #991b1b;
}
.focus\:ring-offset-red-900:focus {
  --tw-ring-offset-color: #7f1d1d;
}
.focus\:ring-offset-yellow-50:focus {
  --tw-ring-offset-color: #fffbeb;
}
.focus\:ring-offset-yellow-100:focus {
  --tw-ring-offset-color: #fef3c7;
}
.focus\:ring-offset-yellow-200:focus {
  --tw-ring-offset-color: #fde68a;
}
.focus\:ring-offset-yellow-300:focus {
  --tw-ring-offset-color: #fcd34d;
}
.focus\:ring-offset-yellow-400:focus {
  --tw-ring-offset-color: #fbbf24;
}
.focus\:ring-offset-yellow-500:focus {
  --tw-ring-offset-color: #f59e0b;
}
.focus\:ring-offset-yellow-600:focus {
  --tw-ring-offset-color: #d97706;
}
.focus\:ring-offset-yellow-700:focus {
  --tw-ring-offset-color: #b45309;
}
.focus\:ring-offset-yellow-800:focus {
  --tw-ring-offset-color: #92400e;
}
.focus\:ring-offset-yellow-900:focus {
  --tw-ring-offset-color: #78350f;
}
.focus\:ring-offset-green-50:focus {
  --tw-ring-offset-color: #ecfdf5;
}
.focus\:ring-offset-green-100:focus {
  --tw-ring-offset-color: #d1fae5;
}
.focus\:ring-offset-green-200:focus {
  --tw-ring-offset-color: #a7f3d0;
}
.focus\:ring-offset-green-300:focus {
  --tw-ring-offset-color: #6ee7b7;
}
.focus\:ring-offset-green-400:focus {
  --tw-ring-offset-color: #34d399;
}
.focus\:ring-offset-green-500:focus {
  --tw-ring-offset-color: #10b981;
}
.focus\:ring-offset-green-600:focus {
  --tw-ring-offset-color: #059669;
}
.focus\:ring-offset-green-700:focus {
  --tw-ring-offset-color: #047857;
}
.focus\:ring-offset-green-800:focus {
  --tw-ring-offset-color: #065f46;
}
.focus\:ring-offset-green-900:focus {
  --tw-ring-offset-color: #064e3b;
}
.focus\:ring-offset-blue-50:focus {
  --tw-ring-offset-color: #eff6ff;
}
.focus\:ring-offset-blue-100:focus {
  --tw-ring-offset-color: #dbeafe;
}
.focus\:ring-offset-blue-200:focus {
  --tw-ring-offset-color: #bfdbfe;
}
.focus\:ring-offset-blue-300:focus {
  --tw-ring-offset-color: #93c5fd;
}
.focus\:ring-offset-blue-400:focus {
  --tw-ring-offset-color: #60a5fa;
}
.focus\:ring-offset-blue-500:focus {
  --tw-ring-offset-color: #3b82f6;
}
.focus\:ring-offset-blue-600:focus {
  --tw-ring-offset-color: #2563eb;
}
.focus\:ring-offset-blue-700:focus {
  --tw-ring-offset-color: #1d4ed8;
}
.focus\:ring-offset-blue-800:focus {
  --tw-ring-offset-color: #1e40af;
}
.focus\:ring-offset-blue-900:focus {
  --tw-ring-offset-color: #1e3a8a;
}
.focus\:ring-offset-indigo-50:focus {
  --tw-ring-offset-color: #eef2ff;
}
.focus\:ring-offset-indigo-100:focus {
  --tw-ring-offset-color: #e0e7ff;
}
.focus\:ring-offset-indigo-200:focus {
  --tw-ring-offset-color: #c7d2fe;
}
.focus\:ring-offset-indigo-300:focus {
  --tw-ring-offset-color: #a5b4fc;
}
.focus\:ring-offset-indigo-400:focus {
  --tw-ring-offset-color: #818cf8;
}
.focus\:ring-offset-indigo-500:focus {
  --tw-ring-offset-color: #6366f1;
}
.focus\:ring-offset-indigo-600:focus {
  --tw-ring-offset-color: #4f46e5;
}
.focus\:ring-offset-indigo-700:focus {
  --tw-ring-offset-color: #4338ca;
}
.focus\:ring-offset-indigo-800:focus {
  --tw-ring-offset-color: #3730a3;
}
.focus\:ring-offset-indigo-900:focus {
  --tw-ring-offset-color: #312e81;
}
.focus\:ring-offset-purple-50:focus {
  --tw-ring-offset-color: #f5f3ff;
}
.focus\:ring-offset-purple-100:focus {
  --tw-ring-offset-color: #ede9fe;
}
.focus\:ring-offset-purple-200:focus {
  --tw-ring-offset-color: #ddd6fe;
}
.focus\:ring-offset-purple-300:focus {
  --tw-ring-offset-color: #c4b5fd;
}
.focus\:ring-offset-purple-400:focus {
  --tw-ring-offset-color: #a78bfa;
}
.focus\:ring-offset-purple-500:focus {
  --tw-ring-offset-color: #8b5cf6;
}
.focus\:ring-offset-purple-600:focus {
  --tw-ring-offset-color: #7c3aed;
}
.focus\:ring-offset-purple-700:focus {
  --tw-ring-offset-color: #6d28d9;
}
.focus\:ring-offset-purple-800:focus {
  --tw-ring-offset-color: #5b21b6;
}
.focus\:ring-offset-purple-900:focus {
  --tw-ring-offset-color: #4c1d95;
}
.focus\:ring-offset-pink-50:focus {
  --tw-ring-offset-color: #fdf2f8;
}
.focus\:ring-offset-pink-100:focus {
  --tw-ring-offset-color: #fce7f3;
}
.focus\:ring-offset-pink-200:focus {
  --tw-ring-offset-color: #fbcfe8;
}
.focus\:ring-offset-pink-300:focus {
  --tw-ring-offset-color: #f9a8d4;
}
.focus\:ring-offset-pink-400:focus {
  --tw-ring-offset-color: #f472b6;
}
.focus\:ring-offset-pink-500:focus {
  --tw-ring-offset-color: #ec4899;
}
.focus\:ring-offset-pink-600:focus {
  --tw-ring-offset-color: #db2777;
}
.focus\:ring-offset-pink-700:focus {
  --tw-ring-offset-color: #be185d;
}
.focus\:ring-offset-pink-800:focus {
  --tw-ring-offset-color: #9d174d;
}
.focus\:ring-offset-pink-900:focus {
  --tw-ring-offset-color: #831843;
}
.focus\:ring-offset-vivid-blue:focus {
  --tw-ring-offset-color: #212eee;
}
.focus\:ring-offset-vivid-blue-500:focus {
  --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
}
.focus\:ring-offset-background-primary:focus {
  --tw-ring-offset-color: var(--background-primary);
}
.focus\:ring-offset-main-background:focus {
  --tw-ring-offset-color: #121729;
}
.focus\:ring-offset-dark-blue:focus {
  --tw-ring-offset-color: #1e2741;
}
.focus\:ring-offset-blue-grey:focus {
  --tw-ring-offset-color: #2f3a5c;
}
.focus\:ring-offset-text-grey:focus {
  --tw-ring-offset-color: #a4a7c1;
}
.focus\:ring-offset-pale-grey-two:focus {
  --tw-ring-offset-color: #eeebff;
}
.focus\:ring-offset-pale-grey:focus {
  --tw-ring-offset-color: #a8afcc;
}
.focus\:ring-offset-btc:focus {
  --tw-ring-offset-color: #f7931a;
}
.focus\:ring-offset-btc-symbol:focus {
  --tw-ring-offset-color: #f5b300;
}
.focus\:ring-offset-paxg:focus {
  --tw-ring-offset-color: #ffc54e;
}
.focus\:ring-offset-usdc:focus {
  --tw-ring-offset-color: #2775ca;
}
.focus\:ring-offset-black-navy:focus {
  --tw-ring-offset-color: #101239;
}
.focus\:ring-offset-ghost-white:focus {
  --tw-ring-offset-color: #f6f5ff;
}
.focus\:ring-offset-spring-green:focus {
  --tw-ring-offset-color: #1be56d;
}
.focus\:ring-offset-light-slate-gray:focus {
  --tw-ring-offset-color: #888b94;
}
.focus\:ring-offset-body-background:focus {
  --tw-ring-offset-color: var(--body-background);
}
.focus\:ring-offset-background:focus {
  --tw-ring-offset-color: var(--background);
}
.focus\:ring-offset-input-background:focus {
  --tw-ring-offset-color: var(--input-background);
}
.focus\:ring-offset-body-text:focus {
  --tw-ring-offset-color: var(--body-text);
}
.focus\:ring-offset-header-text:focus {
  --tw-ring-offset-color: var(--header-text);
}
.focus\:ring-offset-primary:focus {
  --tw-ring-offset-color: var(--primary);
}
.focus\:ring-offset-secondary:focus {
  --tw-ring-offset-color: var(--secondary);
}
.focus\:ring-offset-tertiary:focus {
  --tw-ring-offset-color: var(--tertiary);
}
.focus\:ring-offset-primary-hover:focus {
  --tw-ring-offset-color: var(--primary-hover);
}
.focus\:ring-offset-secondary-hover:focus {
  --tw-ring-offset-color: var(--secondary-hover);
}
.focus\:ring-offset-tertiary-hover:focus {
  --tw-ring-offset-color: var(--tertiary-hover);
}
.focus\:ring-offset-input-text:focus {
  --tw-ring-offset-color: var(--input-text);
}
.focus\:ring-offset-success:focus {
  --tw-ring-offset-color: var(--success);
}
.focus\:ring-offset-error:focus {
  --tw-ring-offset-color: var(--error);
}
.focus\:ring-offset-custom-loader:focus {
  --tw-ring-offset-color: var(--custom-loader);
}
.focus\:ring-offset-violet:focus {
  --tw-ring-offset-color: #9421ee;
}
.focus\:ring-offset-violet-hover:focus {
  --tw-ring-offset-color: #550b8e;
}
.focus\:ring-offset-skeleton-loader-from:focus {
  --tw-ring-offset-color: var(--skeleton-loader-from);
}
.focus\:ring-offset-skeleton-loader-to:focus {
  --tw-ring-offset-color: var(--skeleton-loader-to);
}
.focus\:ring-offset-background50:focus {
  --tw-ring-offset-color: var(--background50);
}
.focus\:ring-offset-reward-blue-start:focus {
  --tw-ring-offset-color: #626bff;
}
.focus\:ring-offset-reward-blue-end:focus {
  --tw-ring-offset-color: #212eee;
}
.focus\:ring-offset-reward-silver-start:focus {
  --tw-ring-offset-color: #484848;
}
.focus\:ring-offset-reward-silver-end:focus {
  --tw-ring-offset-color: #acacac;
}
.focus\:ring-offset-reward-gold-start:focus {
  --tw-ring-offset-color: #ffdf8a;
}
.focus\:ring-offset-reward-gold-end:focus {
  --tw-ring-offset-color: #ffc425;
}
.focus\:ring-offset-reward-platinum-start:focus {
  --tw-ring-offset-color: #959595;
}
.focus\:ring-offset-reward-platinum-end:focus {
  --tw-ring-offset-color: #f8f8f8;
}
.focus\:ring-offset-reward-vip-start:focus {
  --tw-ring-offset-color: #212eee;
}
.focus\:ring-offset-reward-vip-end:focus {
  --tw-ring-offset-color: #c521ee;
}
.focus\:ring-offset-fee-breakdown-bg:focus {
  --tw-ring-offset-color: #182139;
}
.focus\:ring-offset-light-grey:focus {
  --tw-ring-offset-color: #e5e7eb;
}
.focus\:ring-offset-disabled-button-background:focus {
  --tw-ring-offset-color: #1f2a98;
}
.focus\:ring-offset-disabled-button-text:focus {
  --tw-ring-offset-color: #8e93a0;
}
.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}
.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}
.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}
.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}
.ring-offset-8 {
  --tw-ring-offset-width: 8px;
}
.focus-within\:ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px;
}
.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}
.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}
.focus-within\:ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px;
}
.focus-within\:ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px;
}
.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}
.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}
.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}
.focus\:ring-offset-8:focus {
  --tw-ring-offset-width: 8px;
}
.ring-transparent {
  --tw-ring-color: transparent;
}
.ring-current {
  --tw-ring-color: currentColor;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.ring-gray-300 {
  --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
}
.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
}
.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.ring-vivid-blue {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.ring-vivid-blue-500 {
  --tw-ring-color: rgba(144, 150, 246, 0.5);
}
.ring-background-primary {
  --tw-ring-color: var(--background-primary);
}
.ring-main-background {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
}
.ring-dark-blue {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
}
.ring-blue-grey {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
}
.ring-text-grey {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
}
.ring-pale-grey-two {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
}
.ring-pale-grey {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
}
.ring-btc {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
}
.ring-btc-symbol {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
}
.ring-paxg {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
}
.ring-usdc {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
}
.ring-black-navy {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
}
.ring-ghost-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
}
.ring-spring-green {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
}
.ring-light-slate-gray {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
}
.ring-body-background {
  --tw-ring-color: var(--body-background);
}
.ring-background {
  --tw-ring-color: var(--background);
}
.ring-input-background {
  --tw-ring-color: var(--input-background);
}
.ring-body-text {
  --tw-ring-color: var(--body-text);
}
.ring-header-text {
  --tw-ring-color: var(--header-text);
}
.ring-primary {
  --tw-ring-color: var(--primary);
}
.ring-secondary {
  --tw-ring-color: var(--secondary);
}
.ring-tertiary {
  --tw-ring-color: var(--tertiary);
}
.ring-primary-hover {
  --tw-ring-color: var(--primary-hover);
}
.ring-secondary-hover {
  --tw-ring-color: var(--secondary-hover);
}
.ring-tertiary-hover {
  --tw-ring-color: var(--tertiary-hover);
}
.ring-input-text {
  --tw-ring-color: var(--input-text);
}
.ring-success {
  --tw-ring-color: var(--success);
}
.ring-error {
  --tw-ring-color: var(--error);
}
.ring-custom-loader {
  --tw-ring-color: var(--custom-loader);
}
.ring-violet {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
}
.ring-violet-hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
}
.ring-skeleton-loader-from {
  --tw-ring-color: var(--skeleton-loader-from);
}
.ring-skeleton-loader-to {
  --tw-ring-color: var(--skeleton-loader-to);
}
.ring-background50 {
  --tw-ring-color: var(--background50);
}
.ring-reward-blue-start {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
}
.ring-reward-blue-end {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.ring-reward-silver-start {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
}
.ring-reward-silver-end {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
}
.ring-reward-gold-start {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
}
.ring-reward-gold-end {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
}
.ring-reward-platinum-start {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
}
.ring-reward-platinum-end {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
}
.ring-reward-vip-start {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.ring-reward-vip-end {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
}
.ring-fee-breakdown-bg {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
}
.ring-light-grey {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.ring-disabled-button-background {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
}
.ring-disabled-button-text {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
}
.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent;
}
.focus-within\:ring-current:focus-within {
  --tw-ring-color: currentColor;
}
.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-300:focus-within {
  --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus-within\:ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
}
.focus-within\:ring-red-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-red-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus-within\:ring-red-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-red-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus-within\:ring-red-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus-within\:ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus-within\:ring-red-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-red-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus-within\:ring-red-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-red-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus-within\:ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus-within\:ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus-within\:ring-vivid-blue:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-vivid-blue-500:focus-within {
  --tw-ring-color: rgba(144, 150, 246, 0.5);
}
.focus-within\:ring-background-primary:focus-within {
  --tw-ring-color: var(--background-primary);
}
.focus-within\:ring-main-background:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
}
.focus-within\:ring-dark-blue:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-grey:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
}
.focus-within\:ring-text-grey:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
}
.focus-within\:ring-pale-grey-two:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-pale-grey:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
}
.focus-within\:ring-btc:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
}
.focus-within\:ring-btc-symbol:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
}
.focus-within\:ring-paxg:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
}
.focus-within\:ring-usdc:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-black-navy:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
}
.focus-within\:ring-ghost-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-spring-green:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
}
.focus-within\:ring-light-slate-gray:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
}
.focus-within\:ring-body-background:focus-within {
  --tw-ring-color: var(--body-background);
}
.focus-within\:ring-background:focus-within {
  --tw-ring-color: var(--background);
}
.focus-within\:ring-input-background:focus-within {
  --tw-ring-color: var(--input-background);
}
.focus-within\:ring-body-text:focus-within {
  --tw-ring-color: var(--body-text);
}
.focus-within\:ring-header-text:focus-within {
  --tw-ring-color: var(--header-text);
}
.focus-within\:ring-primary:focus-within {
  --tw-ring-color: var(--primary);
}
.focus-within\:ring-secondary:focus-within {
  --tw-ring-color: var(--secondary);
}
.focus-within\:ring-tertiary:focus-within {
  --tw-ring-color: var(--tertiary);
}
.focus-within\:ring-primary-hover:focus-within {
  --tw-ring-color: var(--primary-hover);
}
.focus-within\:ring-secondary-hover:focus-within {
  --tw-ring-color: var(--secondary-hover);
}
.focus-within\:ring-tertiary-hover:focus-within {
  --tw-ring-color: var(--tertiary-hover);
}
.focus-within\:ring-input-text:focus-within {
  --tw-ring-color: var(--input-text);
}
.focus-within\:ring-success:focus-within {
  --tw-ring-color: var(--success);
}
.focus-within\:ring-error:focus-within {
  --tw-ring-color: var(--error);
}
.focus-within\:ring-custom-loader:focus-within {
  --tw-ring-color: var(--custom-loader);
}
.focus-within\:ring-violet:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-hover:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
}
.focus-within\:ring-skeleton-loader-from:focus-within {
  --tw-ring-color: var(--skeleton-loader-from);
}
.focus-within\:ring-skeleton-loader-to:focus-within {
  --tw-ring-color: var(--skeleton-loader-to);
}
.focus-within\:ring-background50:focus-within {
  --tw-ring-color: var(--background50);
}
.focus-within\:ring-reward-blue-start:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-blue-end:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-silver-start:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-silver-end:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-gold-start:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-gold-end:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-platinum-start:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-platinum-end:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-vip-start:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-reward-vip-end:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-fee-breakdown-bg:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
}
.focus-within\:ring-light-grey:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-disabled-button-background:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
}
.focus-within\:ring-disabled-button-text:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
}
.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}
.focus\:ring-current:focus {
  --tw-ring-color: currentColor;
}
.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus\:ring-gray-300:focus {
  --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus\:ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
}
.focus\:ring-red-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus\:ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus\:ring-red-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus\:ring-red-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus\:ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus\:ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus\:ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus\:ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus\:ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus\:ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus\:ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus\:ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus\:ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus\:ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus\:ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus\:ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus\:ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus\:ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus\:ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus\:ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus\:ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus\:ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus\:ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus\:ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus\:ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus\:ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus\:ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus\:ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus\:ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus\:ring-vivid-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus\:ring-vivid-blue-500:focus {
  --tw-ring-color: rgba(144, 150, 246, 0.5);
}
.focus\:ring-background-primary:focus {
  --tw-ring-color: var(--background-primary);
}
.focus\:ring-main-background:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
}
.focus\:ring-dark-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
}
.focus\:ring-blue-grey:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
}
.focus\:ring-text-grey:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
}
.focus\:ring-pale-grey-two:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
}
.focus\:ring-pale-grey:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
}
.focus\:ring-btc:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
}
.focus\:ring-btc-symbol:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
}
.focus\:ring-paxg:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
}
.focus\:ring-usdc:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
}
.focus\:ring-black-navy:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
}
.focus\:ring-ghost-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
}
.focus\:ring-spring-green:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
}
.focus\:ring-light-slate-gray:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
}
.focus\:ring-body-background:focus {
  --tw-ring-color: var(--body-background);
}
.focus\:ring-background:focus {
  --tw-ring-color: var(--background);
}
.focus\:ring-input-background:focus {
  --tw-ring-color: var(--input-background);
}
.focus\:ring-body-text:focus {
  --tw-ring-color: var(--body-text);
}
.focus\:ring-header-text:focus {
  --tw-ring-color: var(--header-text);
}
.focus\:ring-primary:focus {
  --tw-ring-color: var(--primary);
}
.focus\:ring-secondary:focus {
  --tw-ring-color: var(--secondary);
}
.focus\:ring-tertiary:focus {
  --tw-ring-color: var(--tertiary);
}
.focus\:ring-primary-hover:focus {
  --tw-ring-color: var(--primary-hover);
}
.focus\:ring-secondary-hover:focus {
  --tw-ring-color: var(--secondary-hover);
}
.focus\:ring-tertiary-hover:focus {
  --tw-ring-color: var(--tertiary-hover);
}
.focus\:ring-input-text:focus {
  --tw-ring-color: var(--input-text);
}
.focus\:ring-success:focus {
  --tw-ring-color: var(--success);
}
.focus\:ring-error:focus {
  --tw-ring-color: var(--error);
}
.focus\:ring-custom-loader:focus {
  --tw-ring-color: var(--custom-loader);
}
.focus\:ring-violet:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
}
.focus\:ring-violet-hover:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
}
.focus\:ring-skeleton-loader-from:focus {
  --tw-ring-color: var(--skeleton-loader-from);
}
.focus\:ring-skeleton-loader-to:focus {
  --tw-ring-color: var(--skeleton-loader-to);
}
.focus\:ring-background50:focus {
  --tw-ring-color: var(--background50);
}
.focus\:ring-reward-blue-start:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
}
.focus\:ring-reward-blue-end:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus\:ring-reward-silver-start:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
}
.focus\:ring-reward-silver-end:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
}
.focus\:ring-reward-gold-start:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
}
.focus\:ring-reward-gold-end:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
}
.focus\:ring-reward-platinum-start:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
}
.focus\:ring-reward-platinum-end:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
}
.focus\:ring-reward-vip-start:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
}
.focus\:ring-reward-vip-end:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
}
.focus\:ring-fee-breakdown-bg:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
}
.focus\:ring-light-grey:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus\:ring-disabled-button-background:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
}
.focus\:ring-disabled-button-text:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
}
.ring-opacity-0 {
  --tw-ring-opacity: 0;
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.ring-opacity-10 {
  --tw-ring-opacity: 0.1;
}
.ring-opacity-20 {
  --tw-ring-opacity: 0.2;
}
.ring-opacity-25 {
  --tw-ring-opacity: 0.25;
}
.ring-opacity-30 {
  --tw-ring-opacity: 0.3;
}
.ring-opacity-40 {
  --tw-ring-opacity: 0.4;
}
.ring-opacity-50 {
  --tw-ring-opacity: 0.5;
}
.ring-opacity-60 {
  --tw-ring-opacity: 0.6;
}
.ring-opacity-70 {
  --tw-ring-opacity: 0.7;
}
.ring-opacity-75 {
  --tw-ring-opacity: 0.75;
}
.ring-opacity-80 {
  --tw-ring-opacity: 0.8;
}
.ring-opacity-90 {
  --tw-ring-opacity: 0.9;
}
.ring-opacity-95 {
  --tw-ring-opacity: 0.95;
}
.ring-opacity-100 {
  --tw-ring-opacity: 1;
}
.focus-within\:ring-opacity-0:focus-within {
  --tw-ring-opacity: 0;
}
.focus-within\:ring-opacity-5:focus-within {
  --tw-ring-opacity: 0.05;
}
.focus-within\:ring-opacity-10:focus-within {
  --tw-ring-opacity: 0.1;
}
.focus-within\:ring-opacity-20:focus-within {
  --tw-ring-opacity: 0.2;
}
.focus-within\:ring-opacity-25:focus-within {
  --tw-ring-opacity: 0.25;
}
.focus-within\:ring-opacity-30:focus-within {
  --tw-ring-opacity: 0.3;
}
.focus-within\:ring-opacity-40:focus-within {
  --tw-ring-opacity: 0.4;
}
.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5;
}
.focus-within\:ring-opacity-60:focus-within {
  --tw-ring-opacity: 0.6;
}
.focus-within\:ring-opacity-70:focus-within {
  --tw-ring-opacity: 0.7;
}
.focus-within\:ring-opacity-75:focus-within {
  --tw-ring-opacity: 0.75;
}
.focus-within\:ring-opacity-80:focus-within {
  --tw-ring-opacity: 0.8;
}
.focus-within\:ring-opacity-90:focus-within {
  --tw-ring-opacity: 0.9;
}
.focus-within\:ring-opacity-95:focus-within {
  --tw-ring-opacity: 0.95;
}
.focus-within\:ring-opacity-100:focus-within {
  --tw-ring-opacity: 1;
}
.focus\:ring-opacity-0:focus {
  --tw-ring-opacity: 0;
}
.focus\:ring-opacity-5:focus {
  --tw-ring-opacity: 0.05;
}
.focus\:ring-opacity-10:focus {
  --tw-ring-opacity: 0.1;
}
.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: 0.2;
}
.focus\:ring-opacity-25:focus {
  --tw-ring-opacity: 0.25;
}
.focus\:ring-opacity-30:focus {
  --tw-ring-opacity: 0.3;
}
.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: 0.4;
}
.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}
.focus\:ring-opacity-60:focus {
  --tw-ring-opacity: 0.6;
}
.focus\:ring-opacity-70:focus {
  --tw-ring-opacity: 0.7;
}
.focus\:ring-opacity-75:focus {
  --tw-ring-opacity: 0.75;
}
.focus\:ring-opacity-80:focus {
  --tw-ring-opacity: 0.8;
}
.focus\:ring-opacity-90:focus {
  --tw-ring-opacity: 0.9;
}
.focus\:ring-opacity-95:focus {
  --tw-ring-opacity: 0.95;
}
.focus\:ring-opacity-100:focus {
  --tw-ring-opacity: 1;
}
.fill-current {
  fill: currentColor;
}
.stroke-current {
  stroke: currentColor;
}
.stroke-0 {
  stroke-width: 0;
}
.stroke-1 {
  stroke-width: 1;
}
.stroke-2 {
  stroke-width: 2;
}
.table-auto {
  table-layout: auto;
}
.table-fixed {
  table-layout: fixed;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-transparent {
  color: transparent;
}
.text-current {
  color: currentColor;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-300 {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-text-opacity));
}
.text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.text-vivid-blue {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.text-vivid-blue-500 {
  color: rgba(144, 150, 246, 0.5);
}
.text-background-primary {
  color: var(--background-primary);
}
.text-main-background {
  --tw-text-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-text-opacity));
}
.text-dark-blue {
  --tw-text-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-text-opacity));
}
.text-blue-grey {
  --tw-text-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-text-opacity));
}
.text-text-grey {
  --tw-text-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-text-opacity));
}
.text-pale-grey-two {
  --tw-text-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-text-opacity));
}
.text-pale-grey {
  --tw-text-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-text-opacity));
}
.text-btc {
  --tw-text-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-text-opacity));
}
.text-btc-symbol {
  --tw-text-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-text-opacity));
}
.text-paxg {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-text-opacity));
}
.text-usdc {
  --tw-text-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-text-opacity));
}
.text-black-navy {
  --tw-text-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-text-opacity));
}
.text-ghost-white {
  --tw-text-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-text-opacity));
}
.text-spring-green {
  --tw-text-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-text-opacity));
}
.text-light-slate-gray {
  --tw-text-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-text-opacity));
}
.text-body-background {
  color: var(--body-background);
}
.text-background {
  color: var(--background);
}
.text-input-background {
  color: var(--input-background);
}
.text-body-text {
  color: var(--body-text);
}
.text-header-text {
  color: var(--header-text);
}
.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary);
}
.text-tertiary {
  color: var(--tertiary);
}
.text-primary-hover {
  color: var(--primary-hover);
}
.text-secondary-hover {
  color: var(--secondary-hover);
}
.text-tertiary-hover {
  color: var(--tertiary-hover);
}
.text-input-text {
  color: var(--input-text);
}
.text-success {
  color: var(--success);
}
.text-error {
  color: var(--error);
}
.text-custom-loader {
  color: var(--custom-loader);
}
.text-violet {
  --tw-text-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-text-opacity));
}
.text-violet-hover {
  --tw-text-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-text-opacity));
}
.text-skeleton-loader-from {
  color: var(--skeleton-loader-from);
}
.text-skeleton-loader-to {
  color: var(--skeleton-loader-to);
}
.text-background50 {
  color: var(--background50);
}
.text-reward-blue-start {
  --tw-text-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-text-opacity));
}
.text-reward-blue-end {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.text-reward-silver-start {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-text-opacity));
}
.text-reward-silver-end {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-text-opacity));
}
.text-reward-gold-start {
  --tw-text-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-text-opacity));
}
.text-reward-gold-end {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-text-opacity));
}
.text-reward-platinum-start {
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-text-opacity));
}
.text-reward-platinum-end {
  --tw-text-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-text-opacity));
}
.text-reward-vip-start {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.text-reward-vip-end {
  --tw-text-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-text-opacity));
}
.text-fee-breakdown-bg {
  --tw-text-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-text-opacity));
}
.text-light-grey {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-disabled-button-background {
  --tw-text-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-text-opacity));
}
.text-disabled-button-text {
  --tw-text-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-transparent {
  color: transparent;
}
.group:hover .group-hover\:text-current {
  color: currentColor;
}
.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-300 {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.group:hover .group-hover\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-vivid-blue {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-vivid-blue-500 {
  color: rgba(144, 150, 246, 0.5);
}
.group:hover .group-hover\:text-background-primary {
  color: var(--background-primary);
}
.group:hover .group-hover\:text-main-background {
  --tw-text-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-dark-blue {
  --tw-text-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-grey {
  --tw-text-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-text-grey {
  --tw-text-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pale-grey-two {
  --tw-text-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pale-grey {
  --tw-text-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-btc {
  --tw-text-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-btc-symbol {
  --tw-text-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-paxg {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-usdc {
  --tw-text-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-black-navy {
  --tw-text-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-ghost-white {
  --tw-text-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-spring-green {
  --tw-text-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-light-slate-gray {
  --tw-text-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-body-background {
  color: var(--body-background);
}
.group:hover .group-hover\:text-background {
  color: var(--background);
}
.group:hover .group-hover\:text-input-background {
  color: var(--input-background);
}
.group:hover .group-hover\:text-body-text {
  color: var(--body-text);
}
.group:hover .group-hover\:text-header-text {
  color: var(--header-text);
}
.group:hover .group-hover\:text-primary {
  color: var(--primary);
}
.group:hover .group-hover\:text-secondary {
  color: var(--secondary);
}
.group:hover .group-hover\:text-tertiary {
  color: var(--tertiary);
}
.group:hover .group-hover\:text-primary-hover {
  color: var(--primary-hover);
}
.group:hover .group-hover\:text-secondary-hover {
  color: var(--secondary-hover);
}
.group:hover .group-hover\:text-tertiary-hover {
  color: var(--tertiary-hover);
}
.group:hover .group-hover\:text-input-text {
  color: var(--input-text);
}
.group:hover .group-hover\:text-success {
  color: var(--success);
}
.group:hover .group-hover\:text-error {
  color: var(--error);
}
.group:hover .group-hover\:text-custom-loader {
  color: var(--custom-loader);
}
.group:hover .group-hover\:text-violet {
  --tw-text-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-violet-hover {
  --tw-text-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-skeleton-loader-from {
  color: var(--skeleton-loader-from);
}
.group:hover .group-hover\:text-skeleton-loader-to {
  color: var(--skeleton-loader-to);
}
.group:hover .group-hover\:text-background50 {
  color: var(--background50);
}
.group:hover .group-hover\:text-reward-blue-start {
  --tw-text-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-blue-end {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-silver-start {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-silver-end {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-gold-start {
  --tw-text-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-gold-end {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-platinum-start {
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-platinum-end {
  --tw-text-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-vip-start {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-reward-vip-end {
  --tw-text-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-fee-breakdown-bg {
  --tw-text-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-light-grey {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-disabled-button-background {
  --tw-text-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-disabled-button-text {
  --tw-text-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-text-opacity));
}
.focus-within\:text-transparent:focus-within {
  color: transparent;
}
.focus-within\:text-current:focus-within {
  color: currentColor;
}
.focus-within\:text-black:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.focus-within\:text-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus-within\:text-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.focus-within\:text-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.focus-within\:text-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus-within\:text-gray-300:focus-within {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus-within\:text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.focus-within\:text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.focus-within\:text-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.focus-within\:text-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-text-opacity));
}
.focus-within\:text-red-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.focus-within\:text-red-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.focus-within\:text-red-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.focus-within\:text-red-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.focus-within\:text-red-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.focus-within\:text-red-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.focus-within\:text-red-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.focus-within\:text-red-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.focus-within\:text-red-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.focus-within\:text-red-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.focus-within\:text-yellow-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.focus-within\:text-yellow-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.focus-within\:text-yellow-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.focus-within\:text-yellow-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.focus-within\:text-yellow-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.focus-within\:text-yellow-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.focus-within\:text-yellow-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.focus-within\:text-yellow-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.focus-within\:text-yellow-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.focus-within\:text-yellow-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.focus-within\:text-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.focus-within\:text-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.focus-within\:text-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.focus-within\:text-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.focus-within\:text-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.focus-within\:text-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.focus-within\:text-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.focus-within\:text-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.focus-within\:text-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.focus-within\:text-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.focus-within\:text-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.focus-within\:text-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.focus-within\:text-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.focus-within\:text-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.focus-within\:text-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.focus-within\:text-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.focus-within\:text-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.focus-within\:text-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.focus-within\:text-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.focus-within\:text-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.focus-within\:text-indigo-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.focus-within\:text-indigo-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.focus-within\:text-indigo-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.focus-within\:text-indigo-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.focus-within\:text-indigo-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.focus-within\:text-indigo-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.focus-within\:text-indigo-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.focus-within\:text-indigo-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.focus-within\:text-indigo-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.focus-within\:text-indigo-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.focus-within\:text-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.focus-within\:text-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.focus-within\:text-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.focus-within\:text-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.focus-within\:text-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.focus-within\:text-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.focus-within\:text-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.focus-within\:text-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.focus-within\:text-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.focus-within\:text-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.focus-within\:text-pink-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.focus-within\:text-pink-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.focus-within\:text-pink-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.focus-within\:text-pink-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.focus-within\:text-pink-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.focus-within\:text-pink-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.focus-within\:text-pink-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.focus-within\:text-pink-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.focus-within\:text-pink-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.focus-within\:text-pink-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.focus-within\:text-vivid-blue:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus-within\:text-vivid-blue-500:focus-within {
  color: rgba(144, 150, 246, 0.5);
}
.focus-within\:text-background-primary:focus-within {
  color: var(--background-primary);
}
.focus-within\:text-main-background:focus-within {
  --tw-text-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-text-opacity));
}
.focus-within\:text-dark-blue:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-text-opacity));
}
.focus-within\:text-blue-grey:focus-within {
  --tw-text-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-text-opacity));
}
.focus-within\:text-text-grey:focus-within {
  --tw-text-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-text-opacity));
}
.focus-within\:text-pale-grey-two:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-text-opacity));
}
.focus-within\:text-pale-grey:focus-within {
  --tw-text-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-text-opacity));
}
.focus-within\:text-btc:focus-within {
  --tw-text-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-text-opacity));
}
.focus-within\:text-btc-symbol:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-text-opacity));
}
.focus-within\:text-paxg:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-text-opacity));
}
.focus-within\:text-usdc:focus-within {
  --tw-text-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-text-opacity));
}
.focus-within\:text-black-navy:focus-within {
  --tw-text-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-text-opacity));
}
.focus-within\:text-ghost-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-text-opacity));
}
.focus-within\:text-spring-green:focus-within {
  --tw-text-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-text-opacity));
}
.focus-within\:text-light-slate-gray:focus-within {
  --tw-text-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-text-opacity));
}
.focus-within\:text-body-background:focus-within {
  color: var(--body-background);
}
.focus-within\:text-background:focus-within {
  color: var(--background);
}
.focus-within\:text-input-background:focus-within {
  color: var(--input-background);
}
.focus-within\:text-body-text:focus-within {
  color: var(--body-text);
}
.focus-within\:text-header-text:focus-within {
  color: var(--header-text);
}
.focus-within\:text-primary:focus-within {
  color: var(--primary);
}
.focus-within\:text-secondary:focus-within {
  color: var(--secondary);
}
.focus-within\:text-tertiary:focus-within {
  color: var(--tertiary);
}
.focus-within\:text-primary-hover:focus-within {
  color: var(--primary-hover);
}
.focus-within\:text-secondary-hover:focus-within {
  color: var(--secondary-hover);
}
.focus-within\:text-tertiary-hover:focus-within {
  color: var(--tertiary-hover);
}
.focus-within\:text-input-text:focus-within {
  color: var(--input-text);
}
.focus-within\:text-success:focus-within {
  color: var(--success);
}
.focus-within\:text-error:focus-within {
  color: var(--error);
}
.focus-within\:text-custom-loader:focus-within {
  color: var(--custom-loader);
}
.focus-within\:text-violet:focus-within {
  --tw-text-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-text-opacity));
}
.focus-within\:text-violet-hover:focus-within {
  --tw-text-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-text-opacity));
}
.focus-within\:text-skeleton-loader-from:focus-within {
  color: var(--skeleton-loader-from);
}
.focus-within\:text-skeleton-loader-to:focus-within {
  color: var(--skeleton-loader-to);
}
.focus-within\:text-background50:focus-within {
  color: var(--background50);
}
.focus-within\:text-reward-blue-start:focus-within {
  --tw-text-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-text-opacity));
}
.focus-within\:text-reward-blue-end:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus-within\:text-reward-silver-start:focus-within {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-text-opacity));
}
.focus-within\:text-reward-silver-end:focus-within {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-text-opacity));
}
.focus-within\:text-reward-gold-start:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-text-opacity));
}
.focus-within\:text-reward-gold-end:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-text-opacity));
}
.focus-within\:text-reward-platinum-start:focus-within {
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-text-opacity));
}
.focus-within\:text-reward-platinum-end:focus-within {
  --tw-text-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-text-opacity));
}
.focus-within\:text-reward-vip-start:focus-within {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus-within\:text-reward-vip-end:focus-within {
  --tw-text-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-text-opacity));
}
.focus-within\:text-fee-breakdown-bg:focus-within {
  --tw-text-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-text-opacity));
}
.focus-within\:text-light-grey:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus-within\:text-disabled-button-background:focus-within {
  --tw-text-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-text-opacity));
}
.focus-within\:text-disabled-button-text:focus-within {
  --tw-text-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-text-opacity));
}
.hover\:text-transparent:hover {
  color: transparent;
}
.hover\:text-current:hover {
  color: currentColor;
}
.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.hover\:text-gray-300:hover {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-text-opacity));
}
.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.hover\:text-vivid-blue:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.hover\:text-vivid-blue-500:hover {
  color: rgba(144, 150, 246, 0.5);
}
.hover\:text-background-primary:hover {
  color: var(--background-primary);
}
.hover\:text-main-background:hover {
  --tw-text-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-text-opacity));
}
.hover\:text-dark-blue:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-text-opacity));
}
.hover\:text-blue-grey:hover {
  --tw-text-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-text-opacity));
}
.hover\:text-text-grey:hover {
  --tw-text-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-text-opacity));
}
.hover\:text-pale-grey-two:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-text-opacity));
}
.hover\:text-pale-grey:hover {
  --tw-text-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-text-opacity));
}
.hover\:text-btc:hover {
  --tw-text-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-text-opacity));
}
.hover\:text-btc-symbol:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-text-opacity));
}
.hover\:text-paxg:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-text-opacity));
}
.hover\:text-usdc:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-text-opacity));
}
.hover\:text-black-navy:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-text-opacity));
}
.hover\:text-ghost-white:hover {
  --tw-text-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-text-opacity));
}
.hover\:text-spring-green:hover {
  --tw-text-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-text-opacity));
}
.hover\:text-light-slate-gray:hover {
  --tw-text-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-text-opacity));
}
.hover\:text-body-background:hover {
  color: var(--body-background);
}
.hover\:text-background:hover {
  color: var(--background);
}
.hover\:text-input-background:hover {
  color: var(--input-background);
}
.hover\:text-body-text:hover {
  color: var(--body-text);
}
.hover\:text-header-text:hover {
  color: var(--header-text);
}
.hover\:text-primary:hover {
  color: var(--primary);
}
.hover\:text-secondary:hover {
  color: var(--secondary);
}
.hover\:text-tertiary:hover {
  color: var(--tertiary);
}
.hover\:text-primary-hover:hover {
  color: var(--primary-hover);
}
.hover\:text-secondary-hover:hover {
  color: var(--secondary-hover);
}
.hover\:text-tertiary-hover:hover {
  color: var(--tertiary-hover);
}
.hover\:text-input-text:hover {
  color: var(--input-text);
}
.hover\:text-success:hover {
  color: var(--success);
}
.hover\:text-error:hover {
  color: var(--error);
}
.hover\:text-custom-loader:hover {
  color: var(--custom-loader);
}
.hover\:text-violet:hover {
  --tw-text-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-text-opacity));
}
.hover\:text-violet-hover:hover {
  --tw-text-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-text-opacity));
}
.hover\:text-skeleton-loader-from:hover {
  color: var(--skeleton-loader-from);
}
.hover\:text-skeleton-loader-to:hover {
  color: var(--skeleton-loader-to);
}
.hover\:text-background50:hover {
  color: var(--background50);
}
.hover\:text-reward-blue-start:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-text-opacity));
}
.hover\:text-reward-blue-end:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.hover\:text-reward-silver-start:hover {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-text-opacity));
}
.hover\:text-reward-silver-end:hover {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-text-opacity));
}
.hover\:text-reward-gold-start:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-text-opacity));
}
.hover\:text-reward-gold-end:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-text-opacity));
}
.hover\:text-reward-platinum-start:hover {
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-text-opacity));
}
.hover\:text-reward-platinum-end:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-text-opacity));
}
.hover\:text-reward-vip-start:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.hover\:text-reward-vip-end:hover {
  --tw-text-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-text-opacity));
}
.hover\:text-fee-breakdown-bg:hover {
  --tw-text-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-text-opacity));
}
.hover\:text-light-grey:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.hover\:text-disabled-button-background:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-text-opacity));
}
.hover\:text-disabled-button-text:hover {
  --tw-text-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-text-opacity));
}
.focus\:text-transparent:focus {
  color: transparent;
}
.focus\:text-current:focus {
  color: currentColor;
}
.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:text-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.focus\:text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.focus\:text-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus\:text-gray-300:focus {
  color: rgba(98, 107, 255, 0.25098039215686274);
}
.focus\:text-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.focus\:text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.focus\:text-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.focus\:text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 114, 139, var(--tw-text-opacity));
}
.focus\:text-red-50:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.focus\:text-red-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.focus\:text-red-200:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.focus\:text-red-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.focus\:text-red-400:focus {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.focus\:text-red-500:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.focus\:text-red-600:focus {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.focus\:text-red-800:focus {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.focus\:text-red-900:focus {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.focus\:text-yellow-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.focus\:text-yellow-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.focus\:text-yellow-200:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.focus\:text-yellow-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.focus\:text-yellow-400:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.focus\:text-yellow-500:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.focus\:text-yellow-600:focus {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.focus\:text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.focus\:text-yellow-800:focus {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.focus\:text-yellow-900:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.focus\:text-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.focus\:text-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.focus\:text-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.focus\:text-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.focus\:text-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.focus\:text-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.focus\:text-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.focus\:text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.focus\:text-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.focus\:text-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.focus\:text-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.focus\:text-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.focus\:text-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.focus\:text-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.focus\:text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.focus\:text-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.focus\:text-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.focus\:text-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.focus\:text-indigo-50:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.focus\:text-indigo-100:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.focus\:text-indigo-200:focus {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.focus\:text-indigo-300:focus {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.focus\:text-indigo-400:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.focus\:text-indigo-500:focus {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.focus\:text-indigo-600:focus {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.focus\:text-indigo-900:focus {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.focus\:text-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.focus\:text-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.focus\:text-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.focus\:text-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.focus\:text-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.focus\:text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.focus\:text-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.focus\:text-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.focus\:text-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.focus\:text-pink-50:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.focus\:text-pink-100:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.focus\:text-pink-200:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.focus\:text-pink-300:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.focus\:text-pink-400:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.focus\:text-pink-500:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.focus\:text-pink-600:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.focus\:text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.focus\:text-pink-800:focus {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.focus\:text-pink-900:focus {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.focus\:text-vivid-blue:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus\:text-vivid-blue-500:focus {
  color: rgba(144, 150, 246, 0.5);
}
.focus\:text-background-primary:focus {
  color: var(--background-primary);
}
.focus\:text-main-background:focus {
  --tw-text-opacity: 1;
  color: rgba(18, 23, 41, var(--tw-text-opacity));
}
.focus\:text-dark-blue:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 39, 65, var(--tw-text-opacity));
}
.focus\:text-blue-grey:focus {
  --tw-text-opacity: 1;
  color: rgba(47, 58, 92, var(--tw-text-opacity));
}
.focus\:text-text-grey:focus {
  --tw-text-opacity: 1;
  color: rgba(164, 167, 193, var(--tw-text-opacity));
}
.focus\:text-pale-grey-two:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 235, 255, var(--tw-text-opacity));
}
.focus\:text-pale-grey:focus {
  --tw-text-opacity: 1;
  color: rgba(168, 175, 204, var(--tw-text-opacity));
}
.focus\:text-btc:focus {
  --tw-text-opacity: 1;
  color: rgba(247, 147, 26, var(--tw-text-opacity));
}
.focus\:text-btc-symbol:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 179, 0, var(--tw-text-opacity));
}
.focus\:text-paxg:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 78, var(--tw-text-opacity));
}
.focus\:text-usdc:focus {
  --tw-text-opacity: 1;
  color: rgba(39, 117, 202, var(--tw-text-opacity));
}
.focus\:text-black-navy:focus {
  --tw-text-opacity: 1;
  color: rgba(16, 18, 57, var(--tw-text-opacity));
}
.focus\:text-ghost-white:focus {
  --tw-text-opacity: 1;
  color: rgba(246, 245, 255, var(--tw-text-opacity));
}
.focus\:text-spring-green:focus {
  --tw-text-opacity: 1;
  color: rgba(27, 229, 109, var(--tw-text-opacity));
}
.focus\:text-light-slate-gray:focus {
  --tw-text-opacity: 1;
  color: rgba(136, 139, 148, var(--tw-text-opacity));
}
.focus\:text-body-background:focus {
  color: var(--body-background);
}
.focus\:text-background:focus {
  color: var(--background);
}
.focus\:text-input-background:focus {
  color: var(--input-background);
}
.focus\:text-body-text:focus {
  color: var(--body-text);
}
.focus\:text-header-text:focus {
  color: var(--header-text);
}
.focus\:text-primary:focus {
  color: var(--primary);
}
.focus\:text-secondary:focus {
  color: var(--secondary);
}
.focus\:text-tertiary:focus {
  color: var(--tertiary);
}
.focus\:text-primary-hover:focus {
  color: var(--primary-hover);
}
.focus\:text-secondary-hover:focus {
  color: var(--secondary-hover);
}
.focus\:text-tertiary-hover:focus {
  color: var(--tertiary-hover);
}
.focus\:text-input-text:focus {
  color: var(--input-text);
}
.focus\:text-success:focus {
  color: var(--success);
}
.focus\:text-error:focus {
  color: var(--error);
}
.focus\:text-custom-loader:focus {
  color: var(--custom-loader);
}
.focus\:text-violet:focus {
  --tw-text-opacity: 1;
  color: rgba(148, 33, 238, var(--tw-text-opacity));
}
.focus\:text-violet-hover:focus {
  --tw-text-opacity: 1;
  color: rgba(85, 11, 142, var(--tw-text-opacity));
}
.focus\:text-skeleton-loader-from:focus {
  color: var(--skeleton-loader-from);
}
.focus\:text-skeleton-loader-to:focus {
  color: var(--skeleton-loader-to);
}
.focus\:text-background50:focus {
  color: var(--background50);
}
.focus\:text-reward-blue-start:focus {
  --tw-text-opacity: 1;
  color: rgba(98, 107, 255, var(--tw-text-opacity));
}
.focus\:text-reward-blue-end:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus\:text-reward-silver-start:focus {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 72, var(--tw-text-opacity));
}
.focus\:text-reward-silver-end:focus {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 172, var(--tw-text-opacity));
}
.focus\:text-reward-gold-start:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 223, 138, var(--tw-text-opacity));
}
.focus\:text-reward-gold-end:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 196, 37, var(--tw-text-opacity));
}
.focus\:text-reward-platinum-start:focus {
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, var(--tw-text-opacity));
}
.focus\:text-reward-platinum-end:focus {
  --tw-text-opacity: 1;
  color: rgba(248, 248, 248, var(--tw-text-opacity));
}
.focus\:text-reward-vip-start:focus {
  --tw-text-opacity: 1;
  color: rgba(33, 46, 238, var(--tw-text-opacity));
}
.focus\:text-reward-vip-end:focus {
  --tw-text-opacity: 1;
  color: rgba(197, 33, 238, var(--tw-text-opacity));
}
.focus\:text-fee-breakdown-bg:focus {
  --tw-text-opacity: 1;
  color: rgba(24, 33, 57, var(--tw-text-opacity));
}
.focus\:text-light-grey:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus\:text-disabled-button-background:focus {
  --tw-text-opacity: 1;
  color: rgba(31, 42, 152, var(--tw-text-opacity));
}
.focus\:text-disabled-button-text:focus {
  --tw-text-opacity: 1;
  color: rgba(142, 147, 160, var(--tw-text-opacity));
}
.text-opacity-0 {
  --tw-text-opacity: 0;
}
.text-opacity-5 {
  --tw-text-opacity: 0.05;
}
.text-opacity-10 {
  --tw-text-opacity: 0.1;
}
.text-opacity-20 {
  --tw-text-opacity: 0.2;
}
.text-opacity-25 {
  --tw-text-opacity: 0.25;
}
.text-opacity-30 {
  --tw-text-opacity: 0.3;
}
.text-opacity-40 {
  --tw-text-opacity: 0.4;
}
.text-opacity-50 {
  --tw-text-opacity: 0.5;
}
.text-opacity-60 {
  --tw-text-opacity: 0.6;
}
.text-opacity-70 {
  --tw-text-opacity: 0.7;
}
.text-opacity-75 {
  --tw-text-opacity: 0.75;
}
.text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.text-opacity-90 {
  --tw-text-opacity: 0.9;
}
.text-opacity-95 {
  --tw-text-opacity: 0.95;
}
.text-opacity-100 {
  --tw-text-opacity: 1;
}
.group:hover .group-hover\:text-opacity-0 {
  --tw-text-opacity: 0;
}
.group:hover .group-hover\:text-opacity-5 {
  --tw-text-opacity: 0.05;
}
.group:hover .group-hover\:text-opacity-10 {
  --tw-text-opacity: 0.1;
}
.group:hover .group-hover\:text-opacity-20 {
  --tw-text-opacity: 0.2;
}
.group:hover .group-hover\:text-opacity-25 {
  --tw-text-opacity: 0.25;
}
.group:hover .group-hover\:text-opacity-30 {
  --tw-text-opacity: 0.3;
}
.group:hover .group-hover\:text-opacity-40 {
  --tw-text-opacity: 0.4;
}
.group:hover .group-hover\:text-opacity-50 {
  --tw-text-opacity: 0.5;
}
.group:hover .group-hover\:text-opacity-60 {
  --tw-text-opacity: 0.6;
}
.group:hover .group-hover\:text-opacity-70 {
  --tw-text-opacity: 0.7;
}
.group:hover .group-hover\:text-opacity-75 {
  --tw-text-opacity: 0.75;
}
.group:hover .group-hover\:text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.group:hover .group-hover\:text-opacity-90 {
  --tw-text-opacity: 0.9;
}
.group:hover .group-hover\:text-opacity-95 {
  --tw-text-opacity: 0.95;
}
.group:hover .group-hover\:text-opacity-100 {
  --tw-text-opacity: 1;
}
.focus-within\:text-opacity-0:focus-within {
  --tw-text-opacity: 0;
}
.focus-within\:text-opacity-5:focus-within {
  --tw-text-opacity: 0.05;
}
.focus-within\:text-opacity-10:focus-within {
  --tw-text-opacity: 0.1;
}
.focus-within\:text-opacity-20:focus-within {
  --tw-text-opacity: 0.2;
}
.focus-within\:text-opacity-25:focus-within {
  --tw-text-opacity: 0.25;
}
.focus-within\:text-opacity-30:focus-within {
  --tw-text-opacity: 0.3;
}
.focus-within\:text-opacity-40:focus-within {
  --tw-text-opacity: 0.4;
}
.focus-within\:text-opacity-50:focus-within {
  --tw-text-opacity: 0.5;
}
.focus-within\:text-opacity-60:focus-within {
  --tw-text-opacity: 0.6;
}
.focus-within\:text-opacity-70:focus-within {
  --tw-text-opacity: 0.7;
}
.focus-within\:text-opacity-75:focus-within {
  --tw-text-opacity: 0.75;
}
.focus-within\:text-opacity-80:focus-within {
  --tw-text-opacity: 0.8;
}
.focus-within\:text-opacity-90:focus-within {
  --tw-text-opacity: 0.9;
}
.focus-within\:text-opacity-95:focus-within {
  --tw-text-opacity: 0.95;
}
.focus-within\:text-opacity-100:focus-within {
  --tw-text-opacity: 1;
}
.hover\:text-opacity-0:hover {
  --tw-text-opacity: 0;
}
.hover\:text-opacity-5:hover {
  --tw-text-opacity: 0.05;
}
.hover\:text-opacity-10:hover {
  --tw-text-opacity: 0.1;
}
.hover\:text-opacity-20:hover {
  --tw-text-opacity: 0.2;
}
.hover\:text-opacity-25:hover {
  --tw-text-opacity: 0.25;
}
.hover\:text-opacity-30:hover {
  --tw-text-opacity: 0.3;
}
.hover\:text-opacity-40:hover {
  --tw-text-opacity: 0.4;
}
.hover\:text-opacity-50:hover {
  --tw-text-opacity: 0.5;
}
.hover\:text-opacity-60:hover {
  --tw-text-opacity: 0.6;
}
.hover\:text-opacity-70:hover {
  --tw-text-opacity: 0.7;
}
.hover\:text-opacity-75:hover {
  --tw-text-opacity: 0.75;
}
.hover\:text-opacity-80:hover {
  --tw-text-opacity: 0.8;
}
.hover\:text-opacity-90:hover {
  --tw-text-opacity: 0.9;
}
.hover\:text-opacity-95:hover {
  --tw-text-opacity: 0.95;
}
.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}
.focus\:text-opacity-0:focus {
  --tw-text-opacity: 0;
}
.focus\:text-opacity-5:focus {
  --tw-text-opacity: 0.05;
}
.focus\:text-opacity-10:focus {
  --tw-text-opacity: 0.1;
}
.focus\:text-opacity-20:focus {
  --tw-text-opacity: 0.2;
}
.focus\:text-opacity-25:focus {
  --tw-text-opacity: 0.25;
}
.focus\:text-opacity-30:focus {
  --tw-text-opacity: 0.3;
}
.focus\:text-opacity-40:focus {
  --tw-text-opacity: 0.4;
}
.focus\:text-opacity-50:focus {
  --tw-text-opacity: 0.5;
}
.focus\:text-opacity-60:focus {
  --tw-text-opacity: 0.6;
}
.focus\:text-opacity-70:focus {
  --tw-text-opacity: 0.7;
}
.focus\:text-opacity-75:focus {
  --tw-text-opacity: 0.75;
}
.focus\:text-opacity-80:focus {
  --tw-text-opacity: 0.8;
}
.focus\:text-opacity-90:focus {
  --tw-text-opacity: 0.9;
}
.focus\:text-opacity-95:focus {
  --tw-text-opacity: 0.95;
}
.focus\:text-opacity-100:focus {
  --tw-text-opacity: 1;
}
.truncate {
  overflow: hidden;
  white-space: nowrap;
}
.overflow-ellipsis,
.truncate {
  text-overflow: ellipsis;
}
.overflow-clip {
  text-overflow: clip;
}
.group:hover .group-hover\:truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group:hover .group-hover\:overflow-ellipsis {
  text-overflow: ellipsis;
}
.group:hover .group-hover\:overflow-clip {
  text-overflow: clip;
}
.italic {
  font-style: italic;
}
.not-italic {
  font-style: normal;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.no-underline {
  text-decoration: none;
}
.group:hover .group-hover\:underline {
  text-decoration: underline;
}
.group:hover .group-hover\:line-through {
  text-decoration: line-through;
}
.group:hover .group-hover\:no-underline {
  text-decoration: none;
}
.focus-within\:underline:focus-within {
  text-decoration: underline;
}
.focus-within\:line-through:focus-within {
  text-decoration: line-through;
}
.focus-within\:no-underline:focus-within {
  text-decoration: none;
}
.hover\:underline:hover {
  text-decoration: underline;
}
.hover\:line-through:hover {
  text-decoration: line-through;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.focus\:underline:focus {
  text-decoration: underline;
}
.focus\:line-through:focus {
  text-decoration: line-through;
}
.focus\:no-underline:focus {
  text-decoration: none;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.diagonal-fractions,
.lining-nums,
.oldstyle-nums,
.ordinal,
.proportional-nums,
.slashed-zero,
.stacked-fractions,
.tabular-nums {
  --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
  font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.normal-nums {
  font-feature-settings: normal;
  font-variant-numeric: normal;
}
.ordinal {
  --tw-ordinal: ordinal;
}
.slashed-zero {
  --tw-slashed-zero: slashed-zero;
}
.lining-nums {
  --tw-numeric-figure: lining-nums;
}
.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}
.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}
.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}
.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}
.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}
.tracking-tighter {
  letter-spacing: -0.05em;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-normal {
  letter-spacing: 0;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.tracking-widest {
  letter-spacing: 0.1em;
}
.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select-text {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.select-all {
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.select-auto {
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.align-baseline {
  vertical-align: baseline;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.align-text-top {
  vertical-align: text-top;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre {
  white-space: pre;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}
.break-words {
  overflow-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
.group:hover .group-hover\:break-normal {
  overflow-wrap: normal;
  word-break: normal;
}
.group:hover .group-hover\:break-words {
  overflow-wrap: break-word;
}
.group:hover .group-hover\:break-all {
  word-break: break-all;
}
.w-0 {
  width: 0;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-72 {
  width: 18rem;
}
.w-80 {
  width: 20rem;
}
.w-96 {
  width: 24rem;
}
.w-150 {
  width: 150px;
}
.w-199 {
  width: 199px;
}
.w-286 {
  width: 286px;
}
.w-512 {
  width: 512px;
}
.w-560 {
  width: 560px;
}
.w-650 {
  width: 650px;
}
.w-auto {
  width: auto;
}
.w-px {
  width: 1px;
}
.w-0\.5 {
  width: 0.125rem;
}
.w-1\.5 {
  width: 0.375rem;
}
.w-2\.5 {
  width: 0.625rem;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/4 {
  width: 50%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-2\/6 {
  width: 33.333333%;
}
.w-3\/6 {
  width: 50%;
}
.w-4\/6 {
  width: 66.666667%;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.w-min {
  width: -webkit-min-content;
  width: min-content;
}
.w-max {
  width: -webkit-max-content;
  width: max-content;
}
.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-auto {
  z-index: auto;
}
.focus-within\:z-0:focus-within {
  z-index: 0;
}
.focus-within\:z-10:focus-within {
  z-index: 10;
}
.focus-within\:z-20:focus-within {
  z-index: 20;
}
.focus-within\:z-30:focus-within {
  z-index: 30;
}
.focus-within\:z-40:focus-within {
  z-index: 40;
}
.focus-within\:z-50:focus-within {
  z-index: 50;
}
.focus-within\:z-auto:focus-within {
  z-index: auto;
}
.focus\:z-0:focus {
  z-index: 0;
}
.focus\:z-10:focus {
  z-index: 10;
}
.focus\:z-20:focus {
  z-index: 20;
}
.focus\:z-30:focus {
  z-index: 30;
}
.focus\:z-40:focus {
  z-index: 40;
}
.focus\:z-50:focus {
  z-index: 50;
}
.focus\:z-auto:focus {
  z-index: auto;
}
.gap-0 {
  grid-gap: 0;
  gap: 0;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.gap-7 {
  grid-gap: 1.75rem;
  gap: 1.75rem;
}
.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}
.gap-9 {
  grid-gap: 2.25rem;
  gap: 2.25rem;
}
.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}
.gap-11 {
  grid-gap: 2.75rem;
  gap: 2.75rem;
}
.gap-12 {
  grid-gap: 3rem;
  gap: 3rem;
}
.gap-14 {
  grid-gap: 3.5rem;
  gap: 3.5rem;
}
.gap-16 {
  grid-gap: 4rem;
  gap: 4rem;
}
.gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}
.gap-24 {
  grid-gap: 6rem;
  gap: 6rem;
}
.gap-28 {
  grid-gap: 7rem;
  gap: 7rem;
}
.gap-32 {
  grid-gap: 8rem;
  gap: 8rem;
}
.gap-36 {
  grid-gap: 9rem;
  gap: 9rem;
}
.gap-40 {
  grid-gap: 10rem;
  gap: 10rem;
}
.gap-44 {
  grid-gap: 11rem;
  gap: 11rem;
}
.gap-48 {
  grid-gap: 12rem;
  gap: 12rem;
}
.gap-52 {
  grid-gap: 13rem;
  gap: 13rem;
}
.gap-56 {
  grid-gap: 14rem;
  gap: 14rem;
}
.gap-60 {
  grid-gap: 15rem;
  gap: 15rem;
}
.gap-64 {
  grid-gap: 16rem;
  gap: 16rem;
}
.gap-72 {
  grid-gap: 18rem;
  gap: 18rem;
}
.gap-80 {
  grid-gap: 20rem;
  gap: 20rem;
}
.gap-96 {
  grid-gap: 24rem;
  gap: 24rem;
}
.gap-px {
  grid-gap: 1px;
  gap: 1px;
}
.gap-0\.5 {
  grid-gap: 0.125rem;
  gap: 0.125rem;
}
.gap-1\.5 {
  grid-gap: 0.375rem;
  gap: 0.375rem;
}
.gap-2\.5 {
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.gap-3\.5 {
  grid-gap: 0.875rem;
  gap: 0.875rem;
}
.gap-x-0 {
  -webkit-column-gap: 0;
  grid-column-gap: 0;
  column-gap: 0;
}
.gap-x-1 {
  -webkit-column-gap: 0.25rem;
  grid-column-gap: 0.25rem;
  column-gap: 0.25rem;
}
.gap-x-2 {
  -webkit-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}
.gap-x-3 {
  -webkit-column-gap: 0.75rem;
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem;
}
.gap-x-4 {
  -webkit-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}
.gap-x-5 {
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
}
.gap-x-6 {
  -webkit-column-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;
}
.gap-x-7 {
  -webkit-column-gap: 1.75rem;
  grid-column-gap: 1.75rem;
  column-gap: 1.75rem;
}
.gap-x-8 {
  -webkit-column-gap: 2rem;
  grid-column-gap: 2rem;
  column-gap: 2rem;
}
.gap-x-9 {
  -webkit-column-gap: 2.25rem;
  grid-column-gap: 2.25rem;
  column-gap: 2.25rem;
}
.gap-x-10 {
  -webkit-column-gap: 2.5rem;
  grid-column-gap: 2.5rem;
  column-gap: 2.5rem;
}
.gap-x-11 {
  -webkit-column-gap: 2.75rem;
  grid-column-gap: 2.75rem;
  column-gap: 2.75rem;
}
.gap-x-12 {
  -webkit-column-gap: 3rem;
  grid-column-gap: 3rem;
  column-gap: 3rem;
}
.gap-x-14 {
  -webkit-column-gap: 3.5rem;
  grid-column-gap: 3.5rem;
  column-gap: 3.5rem;
}
.gap-x-16 {
  -webkit-column-gap: 4rem;
  grid-column-gap: 4rem;
  column-gap: 4rem;
}
.gap-x-20 {
  -webkit-column-gap: 5rem;
  grid-column-gap: 5rem;
  column-gap: 5rem;
}
.gap-x-24 {
  -webkit-column-gap: 6rem;
  grid-column-gap: 6rem;
  column-gap: 6rem;
}
.gap-x-28 {
  -webkit-column-gap: 7rem;
  grid-column-gap: 7rem;
  column-gap: 7rem;
}
.gap-x-32 {
  -webkit-column-gap: 8rem;
  grid-column-gap: 8rem;
  column-gap: 8rem;
}
.gap-x-36 {
  -webkit-column-gap: 9rem;
  grid-column-gap: 9rem;
  column-gap: 9rem;
}
.gap-x-40 {
  -webkit-column-gap: 10rem;
  grid-column-gap: 10rem;
  column-gap: 10rem;
}
.gap-x-44 {
  -webkit-column-gap: 11rem;
  grid-column-gap: 11rem;
  column-gap: 11rem;
}
.gap-x-48 {
  -webkit-column-gap: 12rem;
  grid-column-gap: 12rem;
  column-gap: 12rem;
}
.gap-x-52 {
  -webkit-column-gap: 13rem;
  grid-column-gap: 13rem;
  column-gap: 13rem;
}
.gap-x-56 {
  -webkit-column-gap: 14rem;
  grid-column-gap: 14rem;
  column-gap: 14rem;
}
.gap-x-60 {
  -webkit-column-gap: 15rem;
  grid-column-gap: 15rem;
  column-gap: 15rem;
}
.gap-x-64 {
  -webkit-column-gap: 16rem;
  grid-column-gap: 16rem;
  column-gap: 16rem;
}
.gap-x-72 {
  -webkit-column-gap: 18rem;
  grid-column-gap: 18rem;
  column-gap: 18rem;
}
.gap-x-80 {
  -webkit-column-gap: 20rem;
  grid-column-gap: 20rem;
  column-gap: 20rem;
}
.gap-x-96 {
  -webkit-column-gap: 24rem;
  grid-column-gap: 24rem;
  column-gap: 24rem;
}
.gap-x-px {
  -webkit-column-gap: 1px;
  grid-column-gap: 1px;
  column-gap: 1px;
}
.gap-x-0\.5 {
  -webkit-column-gap: 0.125rem;
  grid-column-gap: 0.125rem;
  column-gap: 0.125rem;
}
.gap-x-1\.5 {
  -webkit-column-gap: 0.375rem;
  grid-column-gap: 0.375rem;
  column-gap: 0.375rem;
}
.gap-x-2\.5 {
  -webkit-column-gap: 0.625rem;
  grid-column-gap: 0.625rem;
  column-gap: 0.625rem;
}
.gap-x-3\.5 {
  -webkit-column-gap: 0.875rem;
  grid-column-gap: 0.875rem;
  column-gap: 0.875rem;
}
.gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0;
}
.gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}
.gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}
.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}
.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}
.gap-y-7 {
  grid-row-gap: 1.75rem;
  row-gap: 1.75rem;
}
.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}
.gap-y-9 {
  grid-row-gap: 2.25rem;
  row-gap: 2.25rem;
}
.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}
.gap-y-11 {
  grid-row-gap: 2.75rem;
  row-gap: 2.75rem;
}
.gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}
.gap-y-14 {
  grid-row-gap: 3.5rem;
  row-gap: 3.5rem;
}
.gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}
.gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}
.gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}
.gap-y-28 {
  grid-row-gap: 7rem;
  row-gap: 7rem;
}
.gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}
.gap-y-36 {
  grid-row-gap: 9rem;
  row-gap: 9rem;
}
.gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}
.gap-y-44 {
  grid-row-gap: 11rem;
  row-gap: 11rem;
}
.gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}
.gap-y-52 {
  grid-row-gap: 13rem;
  row-gap: 13rem;
}
.gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}
.gap-y-60 {
  grid-row-gap: 15rem;
  row-gap: 15rem;
}
.gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}
.gap-y-72 {
  grid-row-gap: 18rem;
  row-gap: 18rem;
}
.gap-y-80 {
  grid-row-gap: 20rem;
  row-gap: 20rem;
}
.gap-y-96 {
  grid-row-gap: 24rem;
  row-gap: 24rem;
}
.gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}
.gap-y-0\.5 {
  grid-row-gap: 0.125rem;
  row-gap: 0.125rem;
}
.gap-y-1\.5 {
  grid-row-gap: 0.375rem;
  row-gap: 0.375rem;
}
.gap-y-2\.5 {
  grid-row-gap: 0.625rem;
  row-gap: 0.625rem;
}
.gap-y-3\.5 {
  grid-row-gap: 0.875rem;
  row-gap: 0.875rem;
}
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.grid-flow-col-dense {
  grid-auto-flow: column dense;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-none {
  grid-template-columns: none;
}
.auto-cols-auto {
  grid-auto-columns: auto;
}
.auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
}
.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
}
.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}
.col-auto {
  grid-column: auto;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.col-span-8 {
  grid-column: span 8 / span 8;
}
.col-span-9 {
  grid-column: span 9 / span 9;
}
.col-span-10 {
  grid-column: span 10 / span 10;
}
.col-span-11 {
  grid-column: span 11 / span 11;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.col-span-full {
  grid-column: 1/-1;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3 {
  grid-column-start: 3;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-start-5 {
  grid-column-start: 5;
}
.col-start-6 {
  grid-column-start: 6;
}
.col-start-7 {
  grid-column-start: 7;
}
.col-start-8 {
  grid-column-start: 8;
}
.col-start-9 {
  grid-column-start: 9;
}
.col-start-10 {
  grid-column-start: 10;
}
.col-start-11 {
  grid-column-start: 11;
}
.col-start-12 {
  grid-column-start: 12;
}
.col-start-13 {
  grid-column-start: 13;
}
.col-start-auto {
  grid-column-start: auto;
}
.col-end-1 {
  grid-column-end: 1;
}
.col-end-2 {
  grid-column-end: 2;
}
.col-end-3 {
  grid-column-end: 3;
}
.col-end-4 {
  grid-column-end: 4;
}
.col-end-5 {
  grid-column-end: 5;
}
.col-end-6 {
  grid-column-end: 6;
}
.col-end-7 {
  grid-column-end: 7;
}
.col-end-8 {
  grid-column-end: 8;
}
.col-end-9 {
  grid-column-end: 9;
}
.col-end-10 {
  grid-column-end: 10;
}
.col-end-11 {
  grid-column-end: 11;
}
.col-end-12 {
  grid-column-end: 12;
}
.col-end-13 {
  grid-column-end: 13;
}
.col-end-auto {
  grid-column-end: auto;
}
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.grid-rows-none {
  grid-template-rows: none;
}
.auto-rows-auto {
  grid-auto-rows: auto;
}
.auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}
.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}
.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}
.row-auto {
  grid-row: auto;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
.row-span-5 {
  grid-row: span 5 / span 5;
}
.row-span-6 {
  grid-row: span 6 / span 6;
}
.row-span-full {
  grid-row: 1/-1;
}
.row-start-1 {
  grid-row-start: 1;
}
.row-start-2 {
  grid-row-start: 2;
}
.row-start-3 {
  grid-row-start: 3;
}
.row-start-4 {
  grid-row-start: 4;
}
.row-start-5 {
  grid-row-start: 5;
}
.row-start-6 {
  grid-row-start: 6;
}
.row-start-7 {
  grid-row-start: 7;
}
.row-start-auto {
  grid-row-start: auto;
}
.row-end-1 {
  grid-row-end: 1;
}
.row-end-2 {
  grid-row-end: 2;
}
.row-end-3 {
  grid-row-end: 3;
}
.row-end-4 {
  grid-row-end: 4;
}
.row-end-5 {
  grid-row-end: 5;
}
.row-end-6 {
  grid-row-end: 6;
}
.row-end-7 {
  grid-row-end: 7;
}
.row-end-auto {
  grid-row-end: auto;
}
.transform {
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform,
.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.transform-gpu {
  -webkit-transform: translate3d(
      var(--tw-translate-x),
      var(--tw-translate-y),
      0
    )
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-none {
  -webkit-transform: none;
  transform: none;
}
.origin-center {
  -webkit-transform-origin: center;
  transform-origin: center;
}
.origin-top {
  -webkit-transform-origin: top;
  transform-origin: top;
}
.origin-top-right {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.origin-right {
  -webkit-transform-origin: right;
  transform-origin: right;
}
.origin-bottom-right {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}
.origin-bottom {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.origin-bottom-left {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.origin-left {
  -webkit-transform-origin: left;
  transform-origin: left;
}
.origin-top-left {
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.scale-50 {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.scale-75 {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.scale-90 {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.scale-95 {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.scale-x-0 {
  --tw-scale-x: 0;
}
.scale-x-50 {
  --tw-scale-x: 0.5;
}
.scale-x-75 {
  --tw-scale-x: 0.75;
}
.scale-x-90 {
  --tw-scale-x: 0.9;
}
.scale-x-95 {
  --tw-scale-x: 0.95;
}
.scale-x-100 {
  --tw-scale-x: 1;
}
.scale-x-105 {
  --tw-scale-x: 1.05;
}
.scale-x-110 {
  --tw-scale-x: 1.1;
}
.scale-x-125 {
  --tw-scale-x: 1.25;
}
.scale-x-150 {
  --tw-scale-x: 1.5;
}
.scale-y-0 {
  --tw-scale-y: 0;
}
.scale-y-50 {
  --tw-scale-y: 0.5;
}
.scale-y-75 {
  --tw-scale-y: 0.75;
}
.scale-y-90 {
  --tw-scale-y: 0.9;
}
.scale-y-95 {
  --tw-scale-y: 0.95;
}
.scale-y-100 {
  --tw-scale-y: 1;
}
.scale-y-105 {
  --tw-scale-y: 1.05;
}
.scale-y-110 {
  --tw-scale-y: 1.1;
}
.scale-y-125 {
  --tw-scale-y: 1.25;
}
.scale-y-150 {
  --tw-scale-y: 1.5;
}
.hover\:scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.hover\:scale-50:hover {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.hover\:scale-75:hover {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.hover\:scale-90:hover {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.hover\:scale-95:hover {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.hover\:scale-x-0:hover {
  --tw-scale-x: 0;
}
.hover\:scale-x-50:hover {
  --tw-scale-x: 0.5;
}
.hover\:scale-x-75:hover {
  --tw-scale-x: 0.75;
}
.hover\:scale-x-90:hover {
  --tw-scale-x: 0.9;
}
.hover\:scale-x-95:hover {
  --tw-scale-x: 0.95;
}
.hover\:scale-x-100:hover {
  --tw-scale-x: 1;
}
.hover\:scale-x-105:hover {
  --tw-scale-x: 1.05;
}
.hover\:scale-x-110:hover {
  --tw-scale-x: 1.1;
}
.hover\:scale-x-125:hover {
  --tw-scale-x: 1.25;
}
.hover\:scale-x-150:hover {
  --tw-scale-x: 1.5;
}
.hover\:scale-y-0:hover {
  --tw-scale-y: 0;
}
.hover\:scale-y-50:hover {
  --tw-scale-y: 0.5;
}
.hover\:scale-y-75:hover {
  --tw-scale-y: 0.75;
}
.hover\:scale-y-90:hover {
  --tw-scale-y: 0.9;
}
.hover\:scale-y-95:hover {
  --tw-scale-y: 0.95;
}
.hover\:scale-y-100:hover {
  --tw-scale-y: 1;
}
.hover\:scale-y-105:hover {
  --tw-scale-y: 1.05;
}
.hover\:scale-y-110:hover {
  --tw-scale-y: 1.1;
}
.hover\:scale-y-125:hover {
  --tw-scale-y: 1.25;
}
.hover\:scale-y-150:hover {
  --tw-scale-y: 1.5;
}
.focus\:scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.focus\:scale-50:focus {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.focus\:scale-75:focus {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.focus\:scale-90:focus {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.focus\:scale-95:focus {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.focus\:scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.focus\:scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.focus\:scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.focus\:scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.focus\:scale-x-0:focus {
  --tw-scale-x: 0;
}
.focus\:scale-x-50:focus {
  --tw-scale-x: 0.5;
}
.focus\:scale-x-75:focus {
  --tw-scale-x: 0.75;
}
.focus\:scale-x-90:focus {
  --tw-scale-x: 0.9;
}
.focus\:scale-x-95:focus {
  --tw-scale-x: 0.95;
}
.focus\:scale-x-100:focus {
  --tw-scale-x: 1;
}
.focus\:scale-x-105:focus {
  --tw-scale-x: 1.05;
}
.focus\:scale-x-110:focus {
  --tw-scale-x: 1.1;
}
.focus\:scale-x-125:focus {
  --tw-scale-x: 1.25;
}
.focus\:scale-x-150:focus {
  --tw-scale-x: 1.5;
}
.focus\:scale-y-0:focus {
  --tw-scale-y: 0;
}
.focus\:scale-y-50:focus {
  --tw-scale-y: 0.5;
}
.focus\:scale-y-75:focus {
  --tw-scale-y: 0.75;
}
.focus\:scale-y-90:focus {
  --tw-scale-y: 0.9;
}
.focus\:scale-y-95:focus {
  --tw-scale-y: 0.95;
}
.focus\:scale-y-100:focus {
  --tw-scale-y: 1;
}
.focus\:scale-y-105:focus {
  --tw-scale-y: 1.05;
}
.focus\:scale-y-110:focus {
  --tw-scale-y: 1.1;
}
.focus\:scale-y-125:focus {
  --tw-scale-y: 1.25;
}
.focus\:scale-y-150:focus {
  --tw-scale-y: 1.5;
}
.rotate-0 {
  --tw-rotate: 0deg;
}
.rotate-1 {
  --tw-rotate: 1deg;
}
.rotate-2 {
  --tw-rotate: 2deg;
}
.rotate-3 {
  --tw-rotate: 3deg;
}
.rotate-6 {
  --tw-rotate: 6deg;
}
.rotate-12 {
  --tw-rotate: 12deg;
}
.rotate-45 {
  --tw-rotate: 45deg;
}
.rotate-90 {
  --tw-rotate: 90deg;
}
.rotate-180 {
  --tw-rotate: 180deg;
}
.-rotate-180 {
  --tw-rotate: -180deg;
}
.-rotate-90 {
  --tw-rotate: -90deg;
}
.-rotate-45 {
  --tw-rotate: -45deg;
}
.-rotate-12 {
  --tw-rotate: -12deg;
}
.-rotate-6 {
  --tw-rotate: -6deg;
}
.-rotate-3 {
  --tw-rotate: -3deg;
}
.-rotate-2 {
  --tw-rotate: -2deg;
}
.-rotate-1 {
  --tw-rotate: -1deg;
}
.hover\:rotate-0:hover {
  --tw-rotate: 0deg;
}
.hover\:rotate-1:hover {
  --tw-rotate: 1deg;
}
.hover\:rotate-2:hover {
  --tw-rotate: 2deg;
}
.hover\:rotate-3:hover {
  --tw-rotate: 3deg;
}
.hover\:rotate-6:hover {
  --tw-rotate: 6deg;
}
.hover\:rotate-12:hover {
  --tw-rotate: 12deg;
}
.hover\:rotate-45:hover {
  --tw-rotate: 45deg;
}
.hover\:rotate-90:hover {
  --tw-rotate: 90deg;
}
.hover\:rotate-180:hover {
  --tw-rotate: 180deg;
}
.hover\:-rotate-180:hover {
  --tw-rotate: -180deg;
}
.hover\:-rotate-90:hover {
  --tw-rotate: -90deg;
}
.hover\:-rotate-45:hover {
  --tw-rotate: -45deg;
}
.hover\:-rotate-12:hover {
  --tw-rotate: -12deg;
}
.hover\:-rotate-6:hover {
  --tw-rotate: -6deg;
}
.hover\:-rotate-3:hover {
  --tw-rotate: -3deg;
}
.hover\:-rotate-2:hover {
  --tw-rotate: -2deg;
}
.hover\:-rotate-1:hover {
  --tw-rotate: -1deg;
}
.focus\:rotate-0:focus {
  --tw-rotate: 0deg;
}
.focus\:rotate-1:focus {
  --tw-rotate: 1deg;
}
.focus\:rotate-2:focus {
  --tw-rotate: 2deg;
}
.focus\:rotate-3:focus {
  --tw-rotate: 3deg;
}
.focus\:rotate-6:focus {
  --tw-rotate: 6deg;
}
.focus\:rotate-12:focus {
  --tw-rotate: 12deg;
}
.focus\:rotate-45:focus {
  --tw-rotate: 45deg;
}
.focus\:rotate-90:focus {
  --tw-rotate: 90deg;
}
.focus\:rotate-180:focus {
  --tw-rotate: 180deg;
}
.focus\:-rotate-180:focus {
  --tw-rotate: -180deg;
}
.focus\:-rotate-90:focus {
  --tw-rotate: -90deg;
}
.focus\:-rotate-45:focus {
  --tw-rotate: -45deg;
}
.focus\:-rotate-12:focus {
  --tw-rotate: -12deg;
}
.focus\:-rotate-6:focus {
  --tw-rotate: -6deg;
}
.focus\:-rotate-3:focus {
  --tw-rotate: -3deg;
}
.focus\:-rotate-2:focus {
  --tw-rotate: -2deg;
}
.focus\:-rotate-1:focus {
  --tw-rotate: -1deg;
}
.translate-x-0 {
  --tw-translate-x: 0px;
}
.translate-x-1 {
  --tw-translate-x: 0.25rem;
}
.translate-x-2 {
  --tw-translate-x: 0.5rem;
}
.translate-x-3 {
  --tw-translate-x: 0.75rem;
}
.translate-x-4 {
  --tw-translate-x: 1rem;
}
.translate-x-5 {
  --tw-translate-x: 1.25rem;
}
.translate-x-6 {
  --tw-translate-x: 1.5rem;
}
.translate-x-7 {
  --tw-translate-x: 1.75rem;
}
.translate-x-8 {
  --tw-translate-x: 2rem;
}
.translate-x-9 {
  --tw-translate-x: 2.25rem;
}
.translate-x-10 {
  --tw-translate-x: 2.5rem;
}
.translate-x-11 {
  --tw-translate-x: 2.75rem;
}
.translate-x-12 {
  --tw-translate-x: 3rem;
}
.translate-x-14 {
  --tw-translate-x: 3.5rem;
}
.translate-x-16 {
  --tw-translate-x: 4rem;
}
.translate-x-20 {
  --tw-translate-x: 5rem;
}
.translate-x-24 {
  --tw-translate-x: 6rem;
}
.translate-x-28 {
  --tw-translate-x: 7rem;
}
.translate-x-32 {
  --tw-translate-x: 8rem;
}
.translate-x-36 {
  --tw-translate-x: 9rem;
}
.translate-x-40 {
  --tw-translate-x: 10rem;
}
.translate-x-44 {
  --tw-translate-x: 11rem;
}
.translate-x-48 {
  --tw-translate-x: 12rem;
}
.translate-x-52 {
  --tw-translate-x: 13rem;
}
.translate-x-56 {
  --tw-translate-x: 14rem;
}
.translate-x-60 {
  --tw-translate-x: 15rem;
}
.translate-x-64 {
  --tw-translate-x: 16rem;
}
.translate-x-72 {
  --tw-translate-x: 18rem;
}
.translate-x-80 {
  --tw-translate-x: 20rem;
}
.translate-x-96 {
  --tw-translate-x: 24rem;
}
.translate-x-px {
  --tw-translate-x: 1px;
}
.translate-x-0\.5 {
  --tw-translate-x: 0.125rem;
}
.translate-x-1\.5 {
  --tw-translate-x: 0.375rem;
}
.translate-x-2\.5 {
  --tw-translate-x: 0.625rem;
}
.translate-x-3\.5 {
  --tw-translate-x: 0.875rem;
}
.-translate-x-0 {
  --tw-translate-x: 0px;
}
.-translate-x-1 {
  --tw-translate-x: -0.25rem;
}
.-translate-x-2 {
  --tw-translate-x: -0.5rem;
}
.-translate-x-3 {
  --tw-translate-x: -0.75rem;
}
.-translate-x-4 {
  --tw-translate-x: -1rem;
}
.-translate-x-5 {
  --tw-translate-x: -1.25rem;
}
.-translate-x-6 {
  --tw-translate-x: -1.5rem;
}
.-translate-x-7 {
  --tw-translate-x: -1.75rem;
}
.-translate-x-8 {
  --tw-translate-x: -2rem;
}
.-translate-x-9 {
  --tw-translate-x: -2.25rem;
}
.-translate-x-10 {
  --tw-translate-x: -2.5rem;
}
.-translate-x-11 {
  --tw-translate-x: -2.75rem;
}
.-translate-x-12 {
  --tw-translate-x: -3rem;
}
.-translate-x-14 {
  --tw-translate-x: -3.5rem;
}
.-translate-x-16 {
  --tw-translate-x: -4rem;
}
.-translate-x-20 {
  --tw-translate-x: -5rem;
}
.-translate-x-24 {
  --tw-translate-x: -6rem;
}
.-translate-x-28 {
  --tw-translate-x: -7rem;
}
.-translate-x-32 {
  --tw-translate-x: -8rem;
}
.-translate-x-36 {
  --tw-translate-x: -9rem;
}
.-translate-x-40 {
  --tw-translate-x: -10rem;
}
.-translate-x-44 {
  --tw-translate-x: -11rem;
}
.-translate-x-48 {
  --tw-translate-x: -12rem;
}
.-translate-x-52 {
  --tw-translate-x: -13rem;
}
.-translate-x-56 {
  --tw-translate-x: -14rem;
}
.-translate-x-60 {
  --tw-translate-x: -15rem;
}
.-translate-x-64 {
  --tw-translate-x: -16rem;
}
.-translate-x-72 {
  --tw-translate-x: -18rem;
}
.-translate-x-80 {
  --tw-translate-x: -20rem;
}
.-translate-x-96 {
  --tw-translate-x: -24rem;
}
.-translate-x-px {
  --tw-translate-x: -1px;
}
.-translate-x-0\.5 {
  --tw-translate-x: -0.125rem;
}
.-translate-x-1\.5 {
  --tw-translate-x: -0.375rem;
}
.-translate-x-2\.5 {
  --tw-translate-x: -0.625rem;
}
.-translate-x-3\.5 {
  --tw-translate-x: -0.875rem;
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
}
.translate-x-1\/3 {
  --tw-translate-x: 33.333333%;
}
.translate-x-2\/3 {
  --tw-translate-x: 66.666667%;
}
.translate-x-1\/4 {
  --tw-translate-x: 25%;
}
.translate-x-2\/4 {
  --tw-translate-x: 50%;
}
.translate-x-3\/4 {
  --tw-translate-x: 75%;
}
.translate-x-full {
  --tw-translate-x: 100%;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.-translate-x-1\/3 {
  --tw-translate-x: -33.333333%;
}
.-translate-x-2\/3 {
  --tw-translate-x: -66.666667%;
}
.-translate-x-1\/4 {
  --tw-translate-x: -25%;
}
.-translate-x-2\/4 {
  --tw-translate-x: -50%;
}
.-translate-x-3\/4 {
  --tw-translate-x: -75%;
}
.-translate-x-full {
  --tw-translate-x: -100%;
}
.translate-y-0 {
  --tw-translate-y: 0px;
}
.translate-y-1 {
  --tw-translate-y: 0.25rem;
}
.translate-y-2 {
  --tw-translate-y: 0.5rem;
}
.translate-y-3 {
  --tw-translate-y: 0.75rem;
}
.translate-y-4 {
  --tw-translate-y: 1rem;
}
.translate-y-5 {
  --tw-translate-y: 1.25rem;
}
.translate-y-6 {
  --tw-translate-y: 1.5rem;
}
.translate-y-7 {
  --tw-translate-y: 1.75rem;
}
.translate-y-8 {
  --tw-translate-y: 2rem;
}
.translate-y-9 {
  --tw-translate-y: 2.25rem;
}
.translate-y-10 {
  --tw-translate-y: 2.5rem;
}
.translate-y-11 {
  --tw-translate-y: 2.75rem;
}
.translate-y-12 {
  --tw-translate-y: 3rem;
}
.translate-y-14 {
  --tw-translate-y: 3.5rem;
}
.translate-y-16 {
  --tw-translate-y: 4rem;
}
.translate-y-20 {
  --tw-translate-y: 5rem;
}
.translate-y-24 {
  --tw-translate-y: 6rem;
}
.translate-y-28 {
  --tw-translate-y: 7rem;
}
.translate-y-32 {
  --tw-translate-y: 8rem;
}
.translate-y-36 {
  --tw-translate-y: 9rem;
}
.translate-y-40 {
  --tw-translate-y: 10rem;
}
.translate-y-44 {
  --tw-translate-y: 11rem;
}
.translate-y-48 {
  --tw-translate-y: 12rem;
}
.translate-y-52 {
  --tw-translate-y: 13rem;
}
.translate-y-56 {
  --tw-translate-y: 14rem;
}
.translate-y-60 {
  --tw-translate-y: 15rem;
}
.translate-y-64 {
  --tw-translate-y: 16rem;
}
.translate-y-72 {
  --tw-translate-y: 18rem;
}
.translate-y-80 {
  --tw-translate-y: 20rem;
}
.translate-y-96 {
  --tw-translate-y: 24rem;
}
.translate-y-px {
  --tw-translate-y: 1px;
}
.translate-y-0\.5 {
  --tw-translate-y: 0.125rem;
}
.translate-y-1\.5 {
  --tw-translate-y: 0.375rem;
}
.translate-y-2\.5 {
  --tw-translate-y: 0.625rem;
}
.translate-y-3\.5 {
  --tw-translate-y: 0.875rem;
}
.-translate-y-0 {
  --tw-translate-y: 0px;
}
.-translate-y-1 {
  --tw-translate-y: -0.25rem;
}
.-translate-y-2 {
  --tw-translate-y: -0.5rem;
}
.-translate-y-3 {
  --tw-translate-y: -0.75rem;
}
.-translate-y-4 {
  --tw-translate-y: -1rem;
}
.-translate-y-5 {
  --tw-translate-y: -1.25rem;
}
.-translate-y-6 {
  --tw-translate-y: -1.5rem;
}
.-translate-y-7 {
  --tw-translate-y: -1.75rem;
}
.-translate-y-8 {
  --tw-translate-y: -2rem;
}
.-translate-y-9 {
  --tw-translate-y: -2.25rem;
}
.-translate-y-10 {
  --tw-translate-y: -2.5rem;
}
.-translate-y-11 {
  --tw-translate-y: -2.75rem;
}
.-translate-y-12 {
  --tw-translate-y: -3rem;
}
.-translate-y-14 {
  --tw-translate-y: -3.5rem;
}
.-translate-y-16 {
  --tw-translate-y: -4rem;
}
.-translate-y-20 {
  --tw-translate-y: -5rem;
}
.-translate-y-24 {
  --tw-translate-y: -6rem;
}
.-translate-y-28 {
  --tw-translate-y: -7rem;
}
.-translate-y-32 {
  --tw-translate-y: -8rem;
}
.-translate-y-36 {
  --tw-translate-y: -9rem;
}
.-translate-y-40 {
  --tw-translate-y: -10rem;
}
.-translate-y-44 {
  --tw-translate-y: -11rem;
}
.-translate-y-48 {
  --tw-translate-y: -12rem;
}
.-translate-y-52 {
  --tw-translate-y: -13rem;
}
.-translate-y-56 {
  --tw-translate-y: -14rem;
}
.-translate-y-60 {
  --tw-translate-y: -15rem;
}
.-translate-y-64 {
  --tw-translate-y: -16rem;
}
.-translate-y-72 {
  --tw-translate-y: -18rem;
}
.-translate-y-80 {
  --tw-translate-y: -20rem;
}
.-translate-y-96 {
  --tw-translate-y: -24rem;
}
.-translate-y-px {
  --tw-translate-y: -1px;
}
.-translate-y-0\.5 {
  --tw-translate-y: -0.125rem;
}
.-translate-y-1\.5 {
  --tw-translate-y: -0.375rem;
}
.-translate-y-2\.5 {
  --tw-translate-y: -0.625rem;
}
.-translate-y-3\.5 {
  --tw-translate-y: -0.875rem;
}
.translate-y-1\/2 {
  --tw-translate-y: 50%;
}
.translate-y-1\/3 {
  --tw-translate-y: 33.333333%;
}
.translate-y-2\/3 {
  --tw-translate-y: 66.666667%;
}
.translate-y-1\/4 {
  --tw-translate-y: 25%;
}
.translate-y-2\/4 {
  --tw-translate-y: 50%;
}
.translate-y-3\/4 {
  --tw-translate-y: 75%;
}
.translate-y-full {
  --tw-translate-y: 100%;
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.-translate-y-1\/3 {
  --tw-translate-y: -33.333333%;
}
.-translate-y-2\/3 {
  --tw-translate-y: -66.666667%;
}
.-translate-y-1\/4 {
  --tw-translate-y: -25%;
}
.-translate-y-2\/4 {
  --tw-translate-y: -50%;
}
.-translate-y-3\/4 {
  --tw-translate-y: -75%;
}
.-translate-y-full {
  --tw-translate-y: -100%;
}
.hover\:translate-x-0:hover {
  --tw-translate-x: 0px;
}
.hover\:translate-x-1:hover {
  --tw-translate-x: 0.25rem;
}
.hover\:translate-x-2:hover {
  --tw-translate-x: 0.5rem;
}
.hover\:translate-x-3:hover {
  --tw-translate-x: 0.75rem;
}
.hover\:translate-x-4:hover {
  --tw-translate-x: 1rem;
}
.hover\:translate-x-5:hover {
  --tw-translate-x: 1.25rem;
}
.hover\:translate-x-6:hover {
  --tw-translate-x: 1.5rem;
}
.hover\:translate-x-7:hover {
  --tw-translate-x: 1.75rem;
}
.hover\:translate-x-8:hover {
  --tw-translate-x: 2rem;
}
.hover\:translate-x-9:hover {
  --tw-translate-x: 2.25rem;
}
.hover\:translate-x-10:hover {
  --tw-translate-x: 2.5rem;
}
.hover\:translate-x-11:hover {
  --tw-translate-x: 2.75rem;
}
.hover\:translate-x-12:hover {
  --tw-translate-x: 3rem;
}
.hover\:translate-x-14:hover {
  --tw-translate-x: 3.5rem;
}
.hover\:translate-x-16:hover {
  --tw-translate-x: 4rem;
}
.hover\:translate-x-20:hover {
  --tw-translate-x: 5rem;
}
.hover\:translate-x-24:hover {
  --tw-translate-x: 6rem;
}
.hover\:translate-x-28:hover {
  --tw-translate-x: 7rem;
}
.hover\:translate-x-32:hover {
  --tw-translate-x: 8rem;
}
.hover\:translate-x-36:hover {
  --tw-translate-x: 9rem;
}
.hover\:translate-x-40:hover {
  --tw-translate-x: 10rem;
}
.hover\:translate-x-44:hover {
  --tw-translate-x: 11rem;
}
.hover\:translate-x-48:hover {
  --tw-translate-x: 12rem;
}
.hover\:translate-x-52:hover {
  --tw-translate-x: 13rem;
}
.hover\:translate-x-56:hover {
  --tw-translate-x: 14rem;
}
.hover\:translate-x-60:hover {
  --tw-translate-x: 15rem;
}
.hover\:translate-x-64:hover {
  --tw-translate-x: 16rem;
}
.hover\:translate-x-72:hover {
  --tw-translate-x: 18rem;
}
.hover\:translate-x-80:hover {
  --tw-translate-x: 20rem;
}
.hover\:translate-x-96:hover {
  --tw-translate-x: 24rem;
}
.hover\:translate-x-px:hover {
  --tw-translate-x: 1px;
}
.hover\:translate-x-0\.5:hover {
  --tw-translate-x: 0.125rem;
}
.hover\:translate-x-1\.5:hover {
  --tw-translate-x: 0.375rem;
}
.hover\:translate-x-2\.5:hover {
  --tw-translate-x: 0.625rem;
}
.hover\:translate-x-3\.5:hover {
  --tw-translate-x: 0.875rem;
}
.hover\:-translate-x-0:hover {
  --tw-translate-x: 0px;
}
.hover\:-translate-x-1:hover {
  --tw-translate-x: -0.25rem;
}
.hover\:-translate-x-2:hover {
  --tw-translate-x: -0.5rem;
}
.hover\:-translate-x-3:hover {
  --tw-translate-x: -0.75rem;
}
.hover\:-translate-x-4:hover {
  --tw-translate-x: -1rem;
}
.hover\:-translate-x-5:hover {
  --tw-translate-x: -1.25rem;
}
.hover\:-translate-x-6:hover {
  --tw-translate-x: -1.5rem;
}
.hover\:-translate-x-7:hover {
  --tw-translate-x: -1.75rem;
}
.hover\:-translate-x-8:hover {
  --tw-translate-x: -2rem;
}
.hover\:-translate-x-9:hover {
  --tw-translate-x: -2.25rem;
}
.hover\:-translate-x-10:hover {
  --tw-translate-x: -2.5rem;
}
.hover\:-translate-x-11:hover {
  --tw-translate-x: -2.75rem;
}
.hover\:-translate-x-12:hover {
  --tw-translate-x: -3rem;
}
.hover\:-translate-x-14:hover {
  --tw-translate-x: -3.5rem;
}
.hover\:-translate-x-16:hover {
  --tw-translate-x: -4rem;
}
.hover\:-translate-x-20:hover {
  --tw-translate-x: -5rem;
}
.hover\:-translate-x-24:hover {
  --tw-translate-x: -6rem;
}
.hover\:-translate-x-28:hover {
  --tw-translate-x: -7rem;
}
.hover\:-translate-x-32:hover {
  --tw-translate-x: -8rem;
}
.hover\:-translate-x-36:hover {
  --tw-translate-x: -9rem;
}
.hover\:-translate-x-40:hover {
  --tw-translate-x: -10rem;
}
.hover\:-translate-x-44:hover {
  --tw-translate-x: -11rem;
}
.hover\:-translate-x-48:hover {
  --tw-translate-x: -12rem;
}
.hover\:-translate-x-52:hover {
  --tw-translate-x: -13rem;
}
.hover\:-translate-x-56:hover {
  --tw-translate-x: -14rem;
}
.hover\:-translate-x-60:hover {
  --tw-translate-x: -15rem;
}
.hover\:-translate-x-64:hover {
  --tw-translate-x: -16rem;
}
.hover\:-translate-x-72:hover {
  --tw-translate-x: -18rem;
}
.hover\:-translate-x-80:hover {
  --tw-translate-x: -20rem;
}
.hover\:-translate-x-96:hover {
  --tw-translate-x: -24rem;
}
.hover\:-translate-x-px:hover {
  --tw-translate-x: -1px;
}
.hover\:-translate-x-0\.5:hover {
  --tw-translate-x: -0.125rem;
}
.hover\:-translate-x-1\.5:hover {
  --tw-translate-x: -0.375rem;
}
.hover\:-translate-x-2\.5:hover {
  --tw-translate-x: -0.625rem;
}
.hover\:-translate-x-3\.5:hover {
  --tw-translate-x: -0.875rem;
}
.hover\:translate-x-1\/2:hover {
  --tw-translate-x: 50%;
}
.hover\:translate-x-1\/3:hover {
  --tw-translate-x: 33.333333%;
}
.hover\:translate-x-2\/3:hover {
  --tw-translate-x: 66.666667%;
}
.hover\:translate-x-1\/4:hover {
  --tw-translate-x: 25%;
}
.hover\:translate-x-2\/4:hover {
  --tw-translate-x: 50%;
}
.hover\:translate-x-3\/4:hover {
  --tw-translate-x: 75%;
}
.hover\:translate-x-full:hover {
  --tw-translate-x: 100%;
}
.hover\:-translate-x-1\/2:hover {
  --tw-translate-x: -50%;
}
.hover\:-translate-x-1\/3:hover {
  --tw-translate-x: -33.333333%;
}
.hover\:-translate-x-2\/3:hover {
  --tw-translate-x: -66.666667%;
}
.hover\:-translate-x-1\/4:hover {
  --tw-translate-x: -25%;
}
.hover\:-translate-x-2\/4:hover {
  --tw-translate-x: -50%;
}
.hover\:-translate-x-3\/4:hover {
  --tw-translate-x: -75%;
}
.hover\:-translate-x-full:hover {
  --tw-translate-x: -100%;
}
.hover\:translate-y-0:hover {
  --tw-translate-y: 0px;
}
.hover\:translate-y-1:hover {
  --tw-translate-y: 0.25rem;
}
.hover\:translate-y-2:hover {
  --tw-translate-y: 0.5rem;
}
.hover\:translate-y-3:hover {
  --tw-translate-y: 0.75rem;
}
.hover\:translate-y-4:hover {
  --tw-translate-y: 1rem;
}
.hover\:translate-y-5:hover {
  --tw-translate-y: 1.25rem;
}
.hover\:translate-y-6:hover {
  --tw-translate-y: 1.5rem;
}
.hover\:translate-y-7:hover {
  --tw-translate-y: 1.75rem;
}
.hover\:translate-y-8:hover {
  --tw-translate-y: 2rem;
}
.hover\:translate-y-9:hover {
  --tw-translate-y: 2.25rem;
}
.hover\:translate-y-10:hover {
  --tw-translate-y: 2.5rem;
}
.hover\:translate-y-11:hover {
  --tw-translate-y: 2.75rem;
}
.hover\:translate-y-12:hover {
  --tw-translate-y: 3rem;
}
.hover\:translate-y-14:hover {
  --tw-translate-y: 3.5rem;
}
.hover\:translate-y-16:hover {
  --tw-translate-y: 4rem;
}
.hover\:translate-y-20:hover {
  --tw-translate-y: 5rem;
}
.hover\:translate-y-24:hover {
  --tw-translate-y: 6rem;
}
.hover\:translate-y-28:hover {
  --tw-translate-y: 7rem;
}
.hover\:translate-y-32:hover {
  --tw-translate-y: 8rem;
}
.hover\:translate-y-36:hover {
  --tw-translate-y: 9rem;
}
.hover\:translate-y-40:hover {
  --tw-translate-y: 10rem;
}
.hover\:translate-y-44:hover {
  --tw-translate-y: 11rem;
}
.hover\:translate-y-48:hover {
  --tw-translate-y: 12rem;
}
.hover\:translate-y-52:hover {
  --tw-translate-y: 13rem;
}
.hover\:translate-y-56:hover {
  --tw-translate-y: 14rem;
}
.hover\:translate-y-60:hover {
  --tw-translate-y: 15rem;
}
.hover\:translate-y-64:hover {
  --tw-translate-y: 16rem;
}
.hover\:translate-y-72:hover {
  --tw-translate-y: 18rem;
}
.hover\:translate-y-80:hover {
  --tw-translate-y: 20rem;
}
.hover\:translate-y-96:hover {
  --tw-translate-y: 24rem;
}
.hover\:translate-y-px:hover {
  --tw-translate-y: 1px;
}
.hover\:translate-y-0\.5:hover {
  --tw-translate-y: 0.125rem;
}
.hover\:translate-y-1\.5:hover {
  --tw-translate-y: 0.375rem;
}
.hover\:translate-y-2\.5:hover {
  --tw-translate-y: 0.625rem;
}
.hover\:translate-y-3\.5:hover {
  --tw-translate-y: 0.875rem;
}
.hover\:-translate-y-0:hover {
  --tw-translate-y: 0px;
}
.hover\:-translate-y-1:hover {
  --tw-translate-y: -0.25rem;
}
.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.5rem;
}
.hover\:-translate-y-3:hover {
  --tw-translate-y: -0.75rem;
}
.hover\:-translate-y-4:hover {
  --tw-translate-y: -1rem;
}
.hover\:-translate-y-5:hover {
  --tw-translate-y: -1.25rem;
}
.hover\:-translate-y-6:hover {
  --tw-translate-y: -1.5rem;
}
.hover\:-translate-y-7:hover {
  --tw-translate-y: -1.75rem;
}
.hover\:-translate-y-8:hover {
  --tw-translate-y: -2rem;
}
.hover\:-translate-y-9:hover {
  --tw-translate-y: -2.25rem;
}
.hover\:-translate-y-10:hover {
  --tw-translate-y: -2.5rem;
}
.hover\:-translate-y-11:hover {
  --tw-translate-y: -2.75rem;
}
.hover\:-translate-y-12:hover {
  --tw-translate-y: -3rem;
}
.hover\:-translate-y-14:hover {
  --tw-translate-y: -3.5rem;
}
.hover\:-translate-y-16:hover {
  --tw-translate-y: -4rem;
}
.hover\:-translate-y-20:hover {
  --tw-translate-y: -5rem;
}
.hover\:-translate-y-24:hover {
  --tw-translate-y: -6rem;
}
.hover\:-translate-y-28:hover {
  --tw-translate-y: -7rem;
}
.hover\:-translate-y-32:hover {
  --tw-translate-y: -8rem;
}
.hover\:-translate-y-36:hover {
  --tw-translate-y: -9rem;
}
.hover\:-translate-y-40:hover {
  --tw-translate-y: -10rem;
}
.hover\:-translate-y-44:hover {
  --tw-translate-y: -11rem;
}
.hover\:-translate-y-48:hover {
  --tw-translate-y: -12rem;
}
.hover\:-translate-y-52:hover {
  --tw-translate-y: -13rem;
}
.hover\:-translate-y-56:hover {
  --tw-translate-y: -14rem;
}
.hover\:-translate-y-60:hover {
  --tw-translate-y: -15rem;
}
.hover\:-translate-y-64:hover {
  --tw-translate-y: -16rem;
}
.hover\:-translate-y-72:hover {
  --tw-translate-y: -18rem;
}
.hover\:-translate-y-80:hover {
  --tw-translate-y: -20rem;
}
.hover\:-translate-y-96:hover {
  --tw-translate-y: -24rem;
}
.hover\:-translate-y-px:hover {
  --tw-translate-y: -1px;
}
.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -0.125rem;
}
.hover\:-translate-y-1\.5:hover {
  --tw-translate-y: -0.375rem;
}
.hover\:-translate-y-2\.5:hover {
  --tw-translate-y: -0.625rem;
}
.hover\:-translate-y-3\.5:hover {
  --tw-translate-y: -0.875rem;
}
.hover\:translate-y-1\/2:hover {
  --tw-translate-y: 50%;
}
.hover\:translate-y-1\/3:hover {
  --tw-translate-y: 33.333333%;
}
.hover\:translate-y-2\/3:hover {
  --tw-translate-y: 66.666667%;
}
.hover\:translate-y-1\/4:hover {
  --tw-translate-y: 25%;
}
.hover\:translate-y-2\/4:hover {
  --tw-translate-y: 50%;
}
.hover\:translate-y-3\/4:hover {
  --tw-translate-y: 75%;
}
.hover\:translate-y-full:hover {
  --tw-translate-y: 100%;
}
.hover\:-translate-y-1\/2:hover {
  --tw-translate-y: -50%;
}
.hover\:-translate-y-1\/3:hover {
  --tw-translate-y: -33.333333%;
}
.hover\:-translate-y-2\/3:hover {
  --tw-translate-y: -66.666667%;
}
.hover\:-translate-y-1\/4:hover {
  --tw-translate-y: -25%;
}
.hover\:-translate-y-2\/4:hover {
  --tw-translate-y: -50%;
}
.hover\:-translate-y-3\/4:hover {
  --tw-translate-y: -75%;
}
.hover\:-translate-y-full:hover {
  --tw-translate-y: -100%;
}
.focus\:translate-x-0:focus {
  --tw-translate-x: 0px;
}
.focus\:translate-x-1:focus {
  --tw-translate-x: 0.25rem;
}
.focus\:translate-x-2:focus {
  --tw-translate-x: 0.5rem;
}
.focus\:translate-x-3:focus {
  --tw-translate-x: 0.75rem;
}
.focus\:translate-x-4:focus {
  --tw-translate-x: 1rem;
}
.focus\:translate-x-5:focus {
  --tw-translate-x: 1.25rem;
}
.focus\:translate-x-6:focus {
  --tw-translate-x: 1.5rem;
}
.focus\:translate-x-7:focus {
  --tw-translate-x: 1.75rem;
}
.focus\:translate-x-8:focus {
  --tw-translate-x: 2rem;
}
.focus\:translate-x-9:focus {
  --tw-translate-x: 2.25rem;
}
.focus\:translate-x-10:focus {
  --tw-translate-x: 2.5rem;
}
.focus\:translate-x-11:focus {
  --tw-translate-x: 2.75rem;
}
.focus\:translate-x-12:focus {
  --tw-translate-x: 3rem;
}
.focus\:translate-x-14:focus {
  --tw-translate-x: 3.5rem;
}
.focus\:translate-x-16:focus {
  --tw-translate-x: 4rem;
}
.focus\:translate-x-20:focus {
  --tw-translate-x: 5rem;
}
.focus\:translate-x-24:focus {
  --tw-translate-x: 6rem;
}
.focus\:translate-x-28:focus {
  --tw-translate-x: 7rem;
}
.focus\:translate-x-32:focus {
  --tw-translate-x: 8rem;
}
.focus\:translate-x-36:focus {
  --tw-translate-x: 9rem;
}
.focus\:translate-x-40:focus {
  --tw-translate-x: 10rem;
}
.focus\:translate-x-44:focus {
  --tw-translate-x: 11rem;
}
.focus\:translate-x-48:focus {
  --tw-translate-x: 12rem;
}
.focus\:translate-x-52:focus {
  --tw-translate-x: 13rem;
}
.focus\:translate-x-56:focus {
  --tw-translate-x: 14rem;
}
.focus\:translate-x-60:focus {
  --tw-translate-x: 15rem;
}
.focus\:translate-x-64:focus {
  --tw-translate-x: 16rem;
}
.focus\:translate-x-72:focus {
  --tw-translate-x: 18rem;
}
.focus\:translate-x-80:focus {
  --tw-translate-x: 20rem;
}
.focus\:translate-x-96:focus {
  --tw-translate-x: 24rem;
}
.focus\:translate-x-px:focus {
  --tw-translate-x: 1px;
}
.focus\:translate-x-0\.5:focus {
  --tw-translate-x: 0.125rem;
}
.focus\:translate-x-1\.5:focus {
  --tw-translate-x: 0.375rem;
}
.focus\:translate-x-2\.5:focus {
  --tw-translate-x: 0.625rem;
}
.focus\:translate-x-3\.5:focus {
  --tw-translate-x: 0.875rem;
}
.focus\:-translate-x-0:focus {
  --tw-translate-x: 0px;
}
.focus\:-translate-x-1:focus {
  --tw-translate-x: -0.25rem;
}
.focus\:-translate-x-2:focus {
  --tw-translate-x: -0.5rem;
}
.focus\:-translate-x-3:focus {
  --tw-translate-x: -0.75rem;
}
.focus\:-translate-x-4:focus {
  --tw-translate-x: -1rem;
}
.focus\:-translate-x-5:focus {
  --tw-translate-x: -1.25rem;
}
.focus\:-translate-x-6:focus {
  --tw-translate-x: -1.5rem;
}
.focus\:-translate-x-7:focus {
  --tw-translate-x: -1.75rem;
}
.focus\:-translate-x-8:focus {
  --tw-translate-x: -2rem;
}
.focus\:-translate-x-9:focus {
  --tw-translate-x: -2.25rem;
}
.focus\:-translate-x-10:focus {
  --tw-translate-x: -2.5rem;
}
.focus\:-translate-x-11:focus {
  --tw-translate-x: -2.75rem;
}
.focus\:-translate-x-12:focus {
  --tw-translate-x: -3rem;
}
.focus\:-translate-x-14:focus {
  --tw-translate-x: -3.5rem;
}
.focus\:-translate-x-16:focus {
  --tw-translate-x: -4rem;
}
.focus\:-translate-x-20:focus {
  --tw-translate-x: -5rem;
}
.focus\:-translate-x-24:focus {
  --tw-translate-x: -6rem;
}
.focus\:-translate-x-28:focus {
  --tw-translate-x: -7rem;
}
.focus\:-translate-x-32:focus {
  --tw-translate-x: -8rem;
}
.focus\:-translate-x-36:focus {
  --tw-translate-x: -9rem;
}
.focus\:-translate-x-40:focus {
  --tw-translate-x: -10rem;
}
.focus\:-translate-x-44:focus {
  --tw-translate-x: -11rem;
}
.focus\:-translate-x-48:focus {
  --tw-translate-x: -12rem;
}
.focus\:-translate-x-52:focus {
  --tw-translate-x: -13rem;
}
.focus\:-translate-x-56:focus {
  --tw-translate-x: -14rem;
}
.focus\:-translate-x-60:focus {
  --tw-translate-x: -15rem;
}
.focus\:-translate-x-64:focus {
  --tw-translate-x: -16rem;
}
.focus\:-translate-x-72:focus {
  --tw-translate-x: -18rem;
}
.focus\:-translate-x-80:focus {
  --tw-translate-x: -20rem;
}
.focus\:-translate-x-96:focus {
  --tw-translate-x: -24rem;
}
.focus\:-translate-x-px:focus {
  --tw-translate-x: -1px;
}
.focus\:-translate-x-0\.5:focus {
  --tw-translate-x: -0.125rem;
}
.focus\:-translate-x-1\.5:focus {
  --tw-translate-x: -0.375rem;
}
.focus\:-translate-x-2\.5:focus {
  --tw-translate-x: -0.625rem;
}
.focus\:-translate-x-3\.5:focus {
  --tw-translate-x: -0.875rem;
}
.focus\:translate-x-1\/2:focus {
  --tw-translate-x: 50%;
}
.focus\:translate-x-1\/3:focus {
  --tw-translate-x: 33.333333%;
}
.focus\:translate-x-2\/3:focus {
  --tw-translate-x: 66.666667%;
}
.focus\:translate-x-1\/4:focus {
  --tw-translate-x: 25%;
}
.focus\:translate-x-2\/4:focus {
  --tw-translate-x: 50%;
}
.focus\:translate-x-3\/4:focus {
  --tw-translate-x: 75%;
}
.focus\:translate-x-full:focus {
  --tw-translate-x: 100%;
}
.focus\:-translate-x-1\/2:focus {
  --tw-translate-x: -50%;
}
.focus\:-translate-x-1\/3:focus {
  --tw-translate-x: -33.333333%;
}
.focus\:-translate-x-2\/3:focus {
  --tw-translate-x: -66.666667%;
}
.focus\:-translate-x-1\/4:focus {
  --tw-translate-x: -25%;
}
.focus\:-translate-x-2\/4:focus {
  --tw-translate-x: -50%;
}
.focus\:-translate-x-3\/4:focus {
  --tw-translate-x: -75%;
}
.focus\:-translate-x-full:focus {
  --tw-translate-x: -100%;
}
.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
}
.focus\:translate-y-1:focus {
  --tw-translate-y: 0.25rem;
}
.focus\:translate-y-2:focus {
  --tw-translate-y: 0.5rem;
}
.focus\:translate-y-3:focus {
  --tw-translate-y: 0.75rem;
}
.focus\:translate-y-4:focus {
  --tw-translate-y: 1rem;
}
.focus\:translate-y-5:focus {
  --tw-translate-y: 1.25rem;
}
.focus\:translate-y-6:focus {
  --tw-translate-y: 1.5rem;
}
.focus\:translate-y-7:focus {
  --tw-translate-y: 1.75rem;
}
.focus\:translate-y-8:focus {
  --tw-translate-y: 2rem;
}
.focus\:translate-y-9:focus {
  --tw-translate-y: 2.25rem;
}
.focus\:translate-y-10:focus {
  --tw-translate-y: 2.5rem;
}
.focus\:translate-y-11:focus {
  --tw-translate-y: 2.75rem;
}
.focus\:translate-y-12:focus {
  --tw-translate-y: 3rem;
}
.focus\:translate-y-14:focus {
  --tw-translate-y: 3.5rem;
}
.focus\:translate-y-16:focus {
  --tw-translate-y: 4rem;
}
.focus\:translate-y-20:focus {
  --tw-translate-y: 5rem;
}
.focus\:translate-y-24:focus {
  --tw-translate-y: 6rem;
}
.focus\:translate-y-28:focus {
  --tw-translate-y: 7rem;
}
.focus\:translate-y-32:focus {
  --tw-translate-y: 8rem;
}
.focus\:translate-y-36:focus {
  --tw-translate-y: 9rem;
}
.focus\:translate-y-40:focus {
  --tw-translate-y: 10rem;
}
.focus\:translate-y-44:focus {
  --tw-translate-y: 11rem;
}
.focus\:translate-y-48:focus {
  --tw-translate-y: 12rem;
}
.focus\:translate-y-52:focus {
  --tw-translate-y: 13rem;
}
.focus\:translate-y-56:focus {
  --tw-translate-y: 14rem;
}
.focus\:translate-y-60:focus {
  --tw-translate-y: 15rem;
}
.focus\:translate-y-64:focus {
  --tw-translate-y: 16rem;
}
.focus\:translate-y-72:focus {
  --tw-translate-y: 18rem;
}
.focus\:translate-y-80:focus {
  --tw-translate-y: 20rem;
}
.focus\:translate-y-96:focus {
  --tw-translate-y: 24rem;
}
.focus\:translate-y-px:focus {
  --tw-translate-y: 1px;
}
.focus\:translate-y-0\.5:focus {
  --tw-translate-y: 0.125rem;
}
.focus\:translate-y-1\.5:focus {
  --tw-translate-y: 0.375rem;
}
.focus\:translate-y-2\.5:focus {
  --tw-translate-y: 0.625rem;
}
.focus\:translate-y-3\.5:focus {
  --tw-translate-y: 0.875rem;
}
.focus\:-translate-y-0:focus {
  --tw-translate-y: 0px;
}
.focus\:-translate-y-1:focus {
  --tw-translate-y: -0.25rem;
}
.focus\:-translate-y-2:focus {
  --tw-translate-y: -0.5rem;
}
.focus\:-translate-y-3:focus {
  --tw-translate-y: -0.75rem;
}
.focus\:-translate-y-4:focus {
  --tw-translate-y: -1rem;
}
.focus\:-translate-y-5:focus {
  --tw-translate-y: -1.25rem;
}
.focus\:-translate-y-6:focus {
  --tw-translate-y: -1.5rem;
}
.focus\:-translate-y-7:focus {
  --tw-translate-y: -1.75rem;
}
.focus\:-translate-y-8:focus {
  --tw-translate-y: -2rem;
}
.focus\:-translate-y-9:focus {
  --tw-translate-y: -2.25rem;
}
.focus\:-translate-y-10:focus {
  --tw-translate-y: -2.5rem;
}
.focus\:-translate-y-11:focus {
  --tw-translate-y: -2.75rem;
}
.focus\:-translate-y-12:focus {
  --tw-translate-y: -3rem;
}
.focus\:-translate-y-14:focus {
  --tw-translate-y: -3.5rem;
}
.focus\:-translate-y-16:focus {
  --tw-translate-y: -4rem;
}
.focus\:-translate-y-20:focus {
  --tw-translate-y: -5rem;
}
.focus\:-translate-y-24:focus {
  --tw-translate-y: -6rem;
}
.focus\:-translate-y-28:focus {
  --tw-translate-y: -7rem;
}
.focus\:-translate-y-32:focus {
  --tw-translate-y: -8rem;
}
.focus\:-translate-y-36:focus {
  --tw-translate-y: -9rem;
}
.focus\:-translate-y-40:focus {
  --tw-translate-y: -10rem;
}
.focus\:-translate-y-44:focus {
  --tw-translate-y: -11rem;
}
.focus\:-translate-y-48:focus {
  --tw-translate-y: -12rem;
}
.focus\:-translate-y-52:focus {
  --tw-translate-y: -13rem;
}
.focus\:-translate-y-56:focus {
  --tw-translate-y: -14rem;
}
.focus\:-translate-y-60:focus {
  --tw-translate-y: -15rem;
}
.focus\:-translate-y-64:focus {
  --tw-translate-y: -16rem;
}
.focus\:-translate-y-72:focus {
  --tw-translate-y: -18rem;
}
.focus\:-translate-y-80:focus {
  --tw-translate-y: -20rem;
}
.focus\:-translate-y-96:focus {
  --tw-translate-y: -24rem;
}
.focus\:-translate-y-px:focus {
  --tw-translate-y: -1px;
}
.focus\:-translate-y-0\.5:focus {
  --tw-translate-y: -0.125rem;
}
.focus\:-translate-y-1\.5:focus {
  --tw-translate-y: -0.375rem;
}
.focus\:-translate-y-2\.5:focus {
  --tw-translate-y: -0.625rem;
}
.focus\:-translate-y-3\.5:focus {
  --tw-translate-y: -0.875rem;
}
.focus\:translate-y-1\/2:focus {
  --tw-translate-y: 50%;
}
.focus\:translate-y-1\/3:focus {
  --tw-translate-y: 33.333333%;
}
.focus\:translate-y-2\/3:focus {
  --tw-translate-y: 66.666667%;
}
.focus\:translate-y-1\/4:focus {
  --tw-translate-y: 25%;
}
.focus\:translate-y-2\/4:focus {
  --tw-translate-y: 50%;
}
.focus\:translate-y-3\/4:focus {
  --tw-translate-y: 75%;
}
.focus\:translate-y-full:focus {
  --tw-translate-y: 100%;
}
.focus\:-translate-y-1\/2:focus {
  --tw-translate-y: -50%;
}
.focus\:-translate-y-1\/3:focus {
  --tw-translate-y: -33.333333%;
}
.focus\:-translate-y-2\/3:focus {
  --tw-translate-y: -66.666667%;
}
.focus\:-translate-y-1\/4:focus {
  --tw-translate-y: -25%;
}
.focus\:-translate-y-2\/4:focus {
  --tw-translate-y: -50%;
}
.focus\:-translate-y-3\/4:focus {
  --tw-translate-y: -75%;
}
.focus\:-translate-y-full:focus {
  --tw-translate-y: -100%;
}
.skew-x-0 {
  --tw-skew-x: 0deg;
}
.skew-x-1 {
  --tw-skew-x: 1deg;
}
.skew-x-2 {
  --tw-skew-x: 2deg;
}
.skew-x-3 {
  --tw-skew-x: 3deg;
}
.skew-x-6 {
  --tw-skew-x: 6deg;
}
.skew-x-12 {
  --tw-skew-x: 12deg;
}
.-skew-x-12 {
  --tw-skew-x: -12deg;
}
.-skew-x-6 {
  --tw-skew-x: -6deg;
}
.-skew-x-3 {
  --tw-skew-x: -3deg;
}
.-skew-x-2 {
  --tw-skew-x: -2deg;
}
.-skew-x-1 {
  --tw-skew-x: -1deg;
}
.skew-y-0 {
  --tw-skew-y: 0deg;
}
.skew-y-1 {
  --tw-skew-y: 1deg;
}
.skew-y-2 {
  --tw-skew-y: 2deg;
}
.skew-y-3 {
  --tw-skew-y: 3deg;
}
.skew-y-6 {
  --tw-skew-y: 6deg;
}
.skew-y-12 {
  --tw-skew-y: 12deg;
}
.-skew-y-12 {
  --tw-skew-y: -12deg;
}
.-skew-y-6 {
  --tw-skew-y: -6deg;
}
.-skew-y-3 {
  --tw-skew-y: -3deg;
}
.-skew-y-2 {
  --tw-skew-y: -2deg;
}
.-skew-y-1 {
  --tw-skew-y: -1deg;
}
.hover\:skew-x-0:hover {
  --tw-skew-x: 0deg;
}
.hover\:skew-x-1:hover {
  --tw-skew-x: 1deg;
}
.hover\:skew-x-2:hover {
  --tw-skew-x: 2deg;
}
.hover\:skew-x-3:hover {
  --tw-skew-x: 3deg;
}
.hover\:skew-x-6:hover {
  --tw-skew-x: 6deg;
}
.hover\:skew-x-12:hover {
  --tw-skew-x: 12deg;
}
.hover\:-skew-x-12:hover {
  --tw-skew-x: -12deg;
}
.hover\:-skew-x-6:hover {
  --tw-skew-x: -6deg;
}
.hover\:-skew-x-3:hover {
  --tw-skew-x: -3deg;
}
.hover\:-skew-x-2:hover {
  --tw-skew-x: -2deg;
}
.hover\:-skew-x-1:hover {
  --tw-skew-x: -1deg;
}
.hover\:skew-y-0:hover {
  --tw-skew-y: 0deg;
}
.hover\:skew-y-1:hover {
  --tw-skew-y: 1deg;
}
.hover\:skew-y-2:hover {
  --tw-skew-y: 2deg;
}
.hover\:skew-y-3:hover {
  --tw-skew-y: 3deg;
}
.hover\:skew-y-6:hover {
  --tw-skew-y: 6deg;
}
.hover\:skew-y-12:hover {
  --tw-skew-y: 12deg;
}
.hover\:-skew-y-12:hover {
  --tw-skew-y: -12deg;
}
.hover\:-skew-y-6:hover {
  --tw-skew-y: -6deg;
}
.hover\:-skew-y-3:hover {
  --tw-skew-y: -3deg;
}
.hover\:-skew-y-2:hover {
  --tw-skew-y: -2deg;
}
.hover\:-skew-y-1:hover {
  --tw-skew-y: -1deg;
}
.focus\:skew-x-0:focus {
  --tw-skew-x: 0deg;
}
.focus\:skew-x-1:focus {
  --tw-skew-x: 1deg;
}
.focus\:skew-x-2:focus {
  --tw-skew-x: 2deg;
}
.focus\:skew-x-3:focus {
  --tw-skew-x: 3deg;
}
.focus\:skew-x-6:focus {
  --tw-skew-x: 6deg;
}
.focus\:skew-x-12:focus {
  --tw-skew-x: 12deg;
}
.focus\:-skew-x-12:focus {
  --tw-skew-x: -12deg;
}
.focus\:-skew-x-6:focus {
  --tw-skew-x: -6deg;
}
.focus\:-skew-x-3:focus {
  --tw-skew-x: -3deg;
}
.focus\:-skew-x-2:focus {
  --tw-skew-x: -2deg;
}
.focus\:-skew-x-1:focus {
  --tw-skew-x: -1deg;
}
.focus\:skew-y-0:focus {
  --tw-skew-y: 0deg;
}
.focus\:skew-y-1:focus {
  --tw-skew-y: 1deg;
}
.focus\:skew-y-2:focus {
  --tw-skew-y: 2deg;
}
.focus\:skew-y-3:focus {
  --tw-skew-y: 3deg;
}
.focus\:skew-y-6:focus {
  --tw-skew-y: 6deg;
}
.focus\:skew-y-12:focus {
  --tw-skew-y: 12deg;
}
.focus\:-skew-y-12:focus {
  --tw-skew-y: -12deg;
}
.focus\:-skew-y-6:focus {
  --tw-skew-y: -6deg;
}
.focus\:-skew-y-3:focus {
  --tw-skew-y: -3deg;
}
.focus\:-skew-y-2:focus {
  --tw-skew-y: -2deg;
}
.focus\:-skew-y-1:focus {
  --tw-skew-y: -1deg;
}
.transition-none {
  transition-property: none;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.ease-linear {
  transition-timing-function: linear;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-75 {
  transition-duration: 75ms;
}
.duration-100 {
  transition-duration: 0.1s;
}
.duration-150 {
  transition-duration: 0.15s;
}
.duration-200 {
  transition-duration: 0.2s;
}
.duration-300 {
  transition-duration: 0.3s;
}
.duration-500 {
  transition-duration: 0.5s;
}
.duration-700 {
  transition-duration: 0.7s;
}
.duration-1000 {
  transition-duration: 1s;
}
.delay-75 {
  transition-delay: 75ms;
}
.delay-100 {
  transition-delay: 0.1s;
}
.delay-150 {
  transition-delay: 0.15s;
}
.delay-200 {
  transition-delay: 0.2s;
}
.delay-300 {
  transition-delay: 0.3s;
}
.delay-500 {
  transition-delay: 0.5s;
}
.delay-700 {
  transition-delay: 0.7s;
}
.delay-1000 {
  transition-delay: 1s;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes ping {
  75%,
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@-webkit-keyframes bounce {
  0%,
  to {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes bounce {
  0%,
  to {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-none {
  -webkit-animation: none;
  animation: none;
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.animate-ping {
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-bounce {
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}
ol {
  list-style: decimal;
}
ol,
ul {
  margin: initial;
  padding: initial;
}
ul {
  list-style: disc;
}
img,
video {
  max-width: none;
}
body,
html {
  background-color: var(--body-background);
}
input,
select {
  font-size: 100%;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
[multiple]:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='email']:focus,
[type='month']:focus,
[type='number']:focus,
[type='password']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='text']:focus,
[type='time']:focus,
[type='url']:focus,
[type='week']:focus,
select:focus,
textarea:focus {
  box-shadow: none;
}
* {
  min-height: 0;
  min-width: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
@keyframes pulse {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: -135% 0;
  }
}
.skeleton {
  background-color: var(--input-background);
  border-radius: 0.25rem;
  background: linear-gradient(
    -90deg,
    var(--skeleton-loader-from),
    var(--skeleton-loader-to) 50%,
    var(--skeleton-loader-from)
  );
  background-size: 400% 400%;
  -webkit-animation: pulse 1.2s ease-in-out infinite;
  animation: pulse 1.2s ease-in-out infinite;
}
@keyframes ping {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  75%,
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
.ping {
  -webkit-animation: ping 1.2s ease-in-out infinite;
  animation: ping 1.2s ease-in-out infinite;
}
.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.md-input-box {
  position: relative;
}
.md-input {
  width: 100%;
  outline: none;
}
.md-label {
  display: block;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: translateY(-35px);
  transform: translateY(-35px);
  transition: color 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.md-input-underline:after,
.md-label {
  position: absolute;
  pointer-events: none;
}
.md-input-underline:after {
  left: 0;
  right: 0;
  bottom: -0.05rem;
  content: '';
  transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.md-input:focus ~ .md-input-underline:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.md-input:not(:-ms-input-placeholder) + .md-label {
  transform: translateY(-60px);
  transform-origin: top left;
  z-index: 1;
}
.md-input:focus + .md-label,
.md-input:not(:placeholder-shown) + .md-label {
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  z-index: 1;
}
button.md-password-button {
  right: 0;
  position: absolute;
  width: 30px;
  background: url(https://revix-dev-cdn-public.azureedge.net/assets/register/password-eye.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
button.md-password-button.visible {
  background-image: url(https://revix-dev-cdn-public.azureedge.net/assets/register/password-eye-visible.svg);
}
button.continue:after {
  content: '\2192';
  height: 23px;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 21px;
  margin-left: 2px;
}
button[disabled] {
  opacity: 0.5;
}
button.primary-button-text {
  color: var(--primary);
}
button.file-upload:before {
  content: ' ';
  height: 21px;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 36px;
  margin-right: 0;
  margin-left: -31px;
}
button.file-upload:focus:before {
  fill: var(--primary);
}
.button-text {
  color: var(--button-text);
}
.outlined-button-text {
  color: var(--outlined-button-text);
}
button.copy:focus {
  outline: none;
}
.otpInput {
  width: 3rem;
  height: 3rem;
}
.otpInput:focus {
  border-color: var(--secondary);
}
ul.why-verify-list {
  list-style: none;
}
ul.why-verify-list li:before {
  content: '\2022';
  /* color: var(--secondary); */
  color: rgb(255, 204, 0);
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.coins-image-rewards-background-top-level {
  background: url(https://revix-dev-cdn-public.azureedge.net/assets/rewards/rewards-background.svg);
}
.background-blue {
  background: var(--background-blue);
}
.background-silver {
  background: var(--background-silver);
}
.background-gold {
  background: var(--background-gold);
}
.background-platinum {
  background: var(--background-platinum);
}
.background-vip {
  background: var(--background-vip);
}
.background-btc {
  background: var(--background-btc);
}
.gradient-promo:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(
    90deg,
    var(--rewards-dark-blue),
    var(--rewards-light-blue)
  );
}
.__react_component_tooltip.show {
  opacity: 1 !important;
}
@media (max-width: 1024px) {
  #crisp-chatbox a {
    bottom: 70px !important;
  }
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonBlack.5c3dbea3.eot) format('eot'),
    url(./assets/BozonBlack.837a2abc.woff) format('woff'),
    url(./assets/BozonBlack.2c7f309b.ttf) format('truetype'),
    url(./assets/BozonBlack.82295994.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonBold.d6969954.eot) format('eot'),
    url(./assets/BozonBold.eb8ee5f3.woff) format('woff'),
    url(./assets/BozonBold.21d80d3d.ttf) format('truetype'),
    url(./assets/BozonBold.c8b8b75f.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonDemiBold.39505056.eot) format('eot'),
    url(./assets/BozonDemiBold.d087a362.woff) format('woff'),
    url(./assets/BozonDemiBold.500e74cf.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonRegular.587e2a0d.eot) format('eot'),
    url(./assets/BozonRegular.e01f3c52.woff) format('woff'),
    url(./assets/BozonRegular.08eaa32d.ttf) format('truetype'),
    url(./assets/BozonRegular.d680fcb0.svg) format('svg'),
    url(./assets/BozonRegular.d3cee7c7.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonBook.ea8e40a0.eot) format('eot'),
    url(./assets/BozonBook.5bd1e7be.woff) format('woff'),
    url(./assets/BozonBook.70b3b0e7.ttf) format('truetype'),
    url(./assets/BozonBook.050f233c.svg) format('svg'),
    url(./assets/BozonBook.26fce06a.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Bozon';
  src: url(./assets/BozonExtraBold.ba3698dc.eot) format('eot'),
    url(./assets/BozonExtraBold.bfb185d6.woff) format('woff'),
    url(./assets/BozonExtraBold.cb609e83.ttf) format('truetype'),
    url(./assets/BozonExtraBold.f2e8530d.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'BozonBold';
  src: url(./assets/BozonBold.c8b8b75f.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'BozonRegular';
  src: url(./assets/BozonRegular.d3cee7c7.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'RobotoMono';
  src: url(./assets/RobotoMono-Thin.7cb58857.ttf) format('truetype');
}
img.center {
  margin: 0 auto;
}
ul.list-items-secondary li:before {
  content: '\2022';
  color: var(--secondary);
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
ol.custom-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none !important;
  counter-reset: step-counter;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
ol.custom-list li {
  counter-increment: step-counter !important;
  margin-bottom: 30px !important;
  text-align: left;
}
ol.custom-list li,
ol.custom-list li:before {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
ol.custom-list li:before {
  content: counter(step-counter) !important;
  margin-right: 18px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  border-radius: 6px !important;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--input-background);
  color: var(--header-text);
}
.bg-horizontal-line {
  background: none;
  background: linear-gradient(
    0deg,
    transparent calc(50% - 1px),
    var(--secondary-dark) 2px,
    transparent calc(50% + 1px)
  );
}
.background-blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.text-indent-3 {
  text-indent: 0.75rem;
}
.text-indent-4 {
  text-indent: 1rem;
}
.text-indent-5 {
  text-indent: 1.25rem;
}
.text-indent-6 {
  text-indent: 1.5rem;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 320px) {
  [data-id='tooltip']:after,
  [data-id='tooltip']:before {
    content: none !important;
  }
}
@media (min-width: 481px) {
  [data-id='tooltip']:after,
  [data-id='tooltip']:before {
    content: none !important;
  }
}
@media (min-width: 641px) {
  [data-id='tooltip']:after,
  [data-id='tooltip']:before {
    content: none !important;
  }
}
@media (min-width: 961px) {
  [data-id='tooltip']:after,
  [data-id='tooltip']:before {
    content: '' !important;
  }
}
@media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
  body,
  html {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  body,
  html {
    font-size: 14px;
  }
}
@media (max-width: 1159px) and (-webkit-device-pixel-ratio: 3) {
  body,
  html {
    overflow: hidden;
  }
  main {
    overflow-y: auto !important;
  }
}
@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .sm\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .sm\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .sm\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }
  .sm\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .sm\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .sm\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .sm\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .sm\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .sm\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .sm\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .sm\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .sm\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .sm\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .sm\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .sm\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .sm\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .sm\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .sm\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .sm\:prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .sm\:prose h3,
  .sm\:prose h4 {
    color: #6d728b;
    font-weight: 600;
  }
  .sm\:prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .sm\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .sm\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .sm\:prose code:after,
  .sm\:prose code:before {
    content: '`';
  }
  .sm\:prose a code {
    color: #6d728b;
  }
  .sm\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .sm\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .sm\:prose pre code:after,
  .sm\:prose pre code:before {
    content: '';
  }
  .sm\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .sm\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .sm\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .sm\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .sm\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .sm\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .sm\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .sm\:prose figure,
  .sm\:prose img,
  .sm\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .sm\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:prose h2 code {
    font-size: 0.875em;
  }
  .sm\:prose h3 code {
    font-size: 0.9em;
  }
  .sm\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .sm\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .sm\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .sm\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .sm\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .sm\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .sm\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .sm\:prose ol ol,
  .sm\:prose ol ul,
  .sm\:prose ul ol,
  .sm\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .sm\:prose h2 + *,
  .sm\:prose h3 + *,
  .sm\:prose h4 + *,
  .sm\:prose hr + * {
    margin-top: 0;
  }
  .sm\:prose thead th:first-child {
    padding-left: 0;
  }
  .sm\:prose thead th:last-child {
    padding-right: 0;
  }
  .sm\:prose tbody td:first-child {
    padding-left: 0;
  }
  .sm\:prose tbody td:last-child {
    padding-right: 0;
  }
  .sm\:prose > :first-child {
    margin-top: 0;
  }
  .sm\:prose > :last-child {
    margin-bottom: 0;
  }
  .sm\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .sm\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .sm\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .sm\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .sm\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .sm\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .sm\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .sm\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .sm\:prose-sm figure,
  .sm\:prose-sm img,
  .sm\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .sm\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .sm\:prose-sm code {
    font-size: 0.8571429em;
  }
  .sm\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .sm\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .sm\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .sm\:prose-sm ol,
  .sm\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .sm\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .sm\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .sm\:prose-sm ol > li:before {
    left: 0;
  }
  .sm\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .sm\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .sm\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .sm\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .sm\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .sm\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .sm\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .sm\:prose-sm ol ol,
  .sm\:prose-sm ol ul,
  .sm\:prose-sm ul ol,
  .sm\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .sm\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .sm\:prose-sm h2 + *,
  .sm\:prose-sm h3 + *,
  .sm\:prose-sm h4 + *,
  .sm\:prose-sm hr + * {
    margin-top: 0;
  }
  .sm\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .sm\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .sm\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .sm\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .sm\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .sm\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .sm\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .sm\:prose-sm > :first-child {
    margin-top: 0;
  }
  .sm\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .sm\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .sm\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .sm\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .sm\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .sm\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .sm\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .sm\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .sm\:prose-lg figure,
  .sm\:prose-lg img,
  .sm\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .sm\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .sm\:prose-lg code {
    font-size: 0.8888889em;
  }
  .sm\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .sm\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .sm\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .sm\:prose-lg ol,
  .sm\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .sm\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .sm\:prose-lg ol > li:before {
    left: 0;
  }
  .sm\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .sm\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .sm\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .sm\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .sm\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .sm\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-lg ol ol,
  .sm\:prose-lg ol ul,
  .sm\:prose-lg ul ol,
  .sm\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .sm\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .sm\:prose-lg h2 + *,
  .sm\:prose-lg h3 + *,
  .sm\:prose-lg h4 + *,
  .sm\:prose-lg hr + * {
    margin-top: 0;
  }
  .sm\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .sm\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .sm\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .sm\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .sm\:prose-lg tbody td {
    padding: 0.75em;
  }
  .sm\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .sm\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .sm\:prose-lg > :first-child {
    margin-top: 0;
  }
  .sm\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .sm\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .sm\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .sm\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .sm\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .sm\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .sm\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .sm\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .sm\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .sm\:prose-xl figure,
  .sm\:prose-xl img,
  .sm\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .sm\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .sm\:prose-xl code {
    font-size: 0.9em;
  }
  .sm\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .sm\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .sm\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .sm\:prose-xl ol,
  .sm\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .sm\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .sm\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .sm\:prose-xl ol > li:before {
    left: 0;
  }
  .sm\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .sm\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .sm\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .sm\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .sm\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .sm\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .sm\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .sm\:prose-xl ol ol,
  .sm\:prose-xl ol ul,
  .sm\:prose-xl ul ol,
  .sm\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .sm\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .sm\:prose-xl h2 + *,
  .sm\:prose-xl h3 + *,
  .sm\:prose-xl h4 + *,
  .sm\:prose-xl hr + * {
    margin-top: 0;
  }
  .sm\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .sm\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .sm\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .sm\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .sm\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .sm\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .sm\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .sm\:prose-xl > :first-child {
    margin-top: 0;
  }
  .sm\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .sm\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .sm\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .sm\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .sm\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .sm\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .sm\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .sm\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .sm\:prose-2xl figure,
  .sm\:prose-2xl img,
  .sm\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .sm\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .sm\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .sm\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .sm\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .sm\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .sm\:prose-2xl ol,
  .sm\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .sm\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .sm\:prose-2xl ol > li:before {
    left: 0;
  }
  .sm\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .sm\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .sm\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .sm\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .sm\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .sm\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .sm\:prose-2xl ol ol,
  .sm\:prose-2xl ol ul,
  .sm\:prose-2xl ul ol,
  .sm\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .sm\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .sm\:prose-2xl h2 + *,
  .sm\:prose-2xl h3 + *,
  .sm\:prose-2xl h4 + *,
  .sm\:prose-2xl hr + * {
    margin-top: 0;
  }
  .sm\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .sm\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .sm\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .sm\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .sm\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .sm\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .sm\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .sm\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .sm\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .sm\:aspect-w-1,
  .sm\:aspect-w-2,
  .sm\:aspect-w-3,
  .sm\:aspect-w-4,
  .sm\:aspect-w-5,
  .sm\:aspect-w-6,
  .sm\:aspect-w-7,
  .sm\:aspect-w-8,
  .sm\:aspect-w-9,
  .sm\:aspect-w-10,
  .sm\:aspect-w-11,
  .sm\:aspect-w-12,
  .sm\:aspect-w-13,
  .sm\:aspect-w-14,
  .sm\:aspect-w-15,
  .sm\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .sm\:aspect-w-1 > *,
  .sm\:aspect-w-2 > *,
  .sm\:aspect-w-3 > *,
  .sm\:aspect-w-4 > *,
  .sm\:aspect-w-5 > *,
  .sm\:aspect-w-6 > *,
  .sm\:aspect-w-7 > *,
  .sm\:aspect-w-8 > *,
  .sm\:aspect-w-9 > *,
  .sm\:aspect-w-10 > *,
  .sm\:aspect-w-11 > *,
  .sm\:aspect-w-12 > *,
  .sm\:aspect-w-13 > *,
  .sm\:aspect-w-14 > *,
  .sm\:aspect-w-15 > *,
  .sm\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sm\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .sm\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .sm\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .sm\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .sm\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .sm\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .sm\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .sm\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .sm\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .sm\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .sm\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .sm\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .sm\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .sm\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .sm\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .sm\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .sm\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .sm\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .sm\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .sm\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .sm\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .sm\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .sm\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .sm\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .sm\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .sm\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .sm\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .sm\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .sm\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .sm\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .sm\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .sm\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .sm\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .sm\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .sm\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .sm\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .sm\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .sm\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .sm\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .sm\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .sm\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .sm\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .sm\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .sm\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .sm\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .sm\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .sm\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .sm\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .sm\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .sm\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .sm\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .sm\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .sm\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .sm\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .sm\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .sm\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .sm\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .sm\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .sm\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .sm\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .sm\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .sm\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .sm\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .sm\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .sm\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .sm\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .sm\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .sm\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .sm\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .sm\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .sm\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .sm\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .sm\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .sm\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .sm\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .sm\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .sm\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .sm\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .sm\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .sm\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .sm\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .sm\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .sm\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .sm\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .sm\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .sm\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .sm\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .sm\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .sm\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .sm\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .sm\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .sm\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .sm\:bg-fixed {
    background-attachment: fixed;
  }
  .sm\:bg-local {
    background-attachment: local;
  }
  .sm\:bg-scroll {
    background-attachment: scroll;
  }
  .sm\:bg-clip-border {
    background-clip: border-box;
  }
  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }
  .sm\:bg-clip-content {
    background-clip: content-box;
  }
  .sm\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .sm\:bg-transparent {
    background-color: transparent;
  }
  .sm\:bg-current {
    background-color: currentColor;
  }
  .sm\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .sm\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .sm\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .sm\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .sm\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .sm\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .sm\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .sm\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .sm\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .sm\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .sm\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .sm\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .sm\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .sm\:bg-body-background {
    background-color: var(--body-background);
  }
  .sm\:bg-background {
    background-color: var(--background);
  }
  .sm\:bg-input-background {
    background-color: var(--input-background);
  }
  .sm\:bg-body-text {
    background-color: var(--body-text);
  }
  .sm\:bg-header-text {
    background-color: var(--header-text);
  }
  .sm\:bg-primary {
    background-color: var(--primary);
  }
  .sm\:bg-secondary {
    background-color: var(--secondary);
  }
  .sm\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .sm\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .sm\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .sm\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .sm\:bg-input-text {
    background-color: var(--input-text);
  }
  .sm\:bg-success {
    background-color: var(--success);
  }
  .sm\:bg-error {
    background-color: var(--error);
  }
  .sm\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .sm\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .sm\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .sm\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .sm\:bg-background50 {
    background-color: var(--background50);
  }
  .sm\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .sm\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .sm\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .sm\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .sm\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .sm\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .sm\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .sm\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .sm\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .sm\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .sm\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .sm\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .sm\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .sm\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .sm\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .sm\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .sm\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .sm\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .sm\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .sm\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .sm\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .sm\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .sm\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .sm\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .sm\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .sm\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .sm\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .sm\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .sm\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .sm\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .sm\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .sm\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .sm\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .sm\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .sm\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .sm\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .sm\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .sm\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .sm\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .sm\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .sm\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .sm\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .sm\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .sm\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .sm\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .sm\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .sm\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .sm\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .sm\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .sm\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .sm\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .sm\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .sm\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .sm\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .sm\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .sm\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .sm\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .sm\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .sm\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .sm\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .sm\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .sm\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .sm\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .sm\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .sm\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .sm\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .sm\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .sm\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .sm\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .sm\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .sm\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .sm\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .sm\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .sm\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .sm\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .sm\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .sm\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .sm\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .sm\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .sm\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .sm\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .sm\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .sm\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .sm\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .sm\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .sm\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .sm\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .sm\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .sm\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .sm\:bg-none {
    background-image: none;
  }
  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .sm\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .sm\:from-background,
  .sm\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-background {
    --tw-gradient-from: var(--background);
  }
  .sm\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .sm\:from-body-text,
  .sm\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .sm\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .sm\:from-header-text,
  .sm\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .sm\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .sm\:from-secondary,
  .sm\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .sm\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .sm\:from-primary-hover,
  .sm\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .sm\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .sm\:from-input-text,
  .sm\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .sm\:from-success {
    --tw-gradient-from: var(--success);
  }
  .sm\:from-error,
  .sm\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-error {
    --tw-gradient-from: var(--error);
  }
  .sm\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .sm\:from-skeleton-loader-from,
  .sm\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .sm\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .sm\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .sm\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .sm\:to-current {
    --tw-gradient-to: currentColor;
  }
  .sm\:to-black {
    --tw-gradient-to: #000;
  }
  .sm\:to-white {
    --tw-gradient-to: #fff;
  }
  .sm\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .sm\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .sm\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .sm\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .sm\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .sm\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .sm\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .sm\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .sm\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .sm\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .sm\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .sm\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .sm\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .sm\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .sm\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .sm\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .sm\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .sm\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .sm\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .sm\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .sm\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .sm\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .sm\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .sm\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .sm\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .sm\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .sm\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .sm\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .sm\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .sm\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .sm\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .sm\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .sm\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .sm\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .sm\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .sm\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .sm\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .sm\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .sm\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .sm\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .sm\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .sm\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .sm\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .sm\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .sm\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .sm\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .sm\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .sm\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .sm\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .sm\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .sm\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .sm\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .sm\:to-background {
    --tw-gradient-to: var(--background);
  }
  .sm\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .sm\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .sm\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .sm\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .sm\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .sm\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .sm\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .sm\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .sm\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .sm\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .sm\:to-success {
    --tw-gradient-to: var(--success);
  }
  .sm\:to-error {
    --tw-gradient-to: var(--error);
  }
  .sm\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .sm\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .sm\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .sm\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .sm\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .sm\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .sm\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .sm\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .sm\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .sm\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .sm\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .sm\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .sm\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .sm\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .sm\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .sm\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .sm\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .sm\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .sm\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .sm\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .sm\:hover\:from-background:hover,
  .sm\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .sm\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .sm\:hover\:from-body-text:hover,
  .sm\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .sm\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .sm\:hover\:from-header-text:hover,
  .sm\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .sm\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .sm\:hover\:from-secondary:hover,
  .sm\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .sm\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .sm\:hover\:from-primary-hover:hover,
  .sm\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .sm\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .sm\:hover\:from-input-text:hover,
  .sm\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .sm\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .sm\:hover\:from-error:hover,
  .sm\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .sm\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .sm\:hover\:from-background50:hover,
  .sm\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .sm\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .sm\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .sm\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .sm\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .sm\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .sm\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .sm\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .sm\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .sm\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .sm\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .sm\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .sm\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .sm\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .sm\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .sm\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .sm\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .sm\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .sm\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .sm\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .sm\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .sm\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .sm\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .sm\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .sm\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .sm\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .sm\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .sm\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .sm\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .sm\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .sm\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .sm\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .sm\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .sm\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .sm\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .sm\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .sm\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .sm\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .sm\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .sm\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .sm\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .sm\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .sm\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .sm\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .sm\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .sm\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .sm\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .sm\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .sm\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .sm\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .sm\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .sm\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .sm\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .sm\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .sm\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .sm\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .sm\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .sm\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .sm\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .sm\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .sm\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .sm\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .sm\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .sm\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .sm\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .sm\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .sm\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .sm\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .sm\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .sm\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .sm\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .sm\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .sm\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .sm\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .sm\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .sm\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .sm\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .sm\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .sm\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .sm\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .sm\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .sm\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .sm\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .sm\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .sm\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .sm\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .sm\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .sm\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .sm\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .sm\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .sm\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .sm\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .sm\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .sm\:focus\:from-background:focus,
  .sm\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .sm\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .sm\:focus\:from-body-text:focus,
  .sm\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .sm\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .sm\:focus\:from-header-text:focus,
  .sm\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .sm\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .sm\:focus\:from-secondary:focus,
  .sm\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .sm\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .sm\:focus\:from-primary-hover:focus,
  .sm\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .sm\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .sm\:focus\:from-input-text:focus,
  .sm\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .sm\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .sm\:focus\:from-error:focus,
  .sm\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .sm\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .sm\:focus\:from-background50:focus,
  .sm\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .sm\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .sm\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .sm\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .sm\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .sm\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .sm\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .sm\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .sm\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .sm\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .sm\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .sm\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .sm\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .sm\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .sm\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .sm\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .sm\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .sm\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .sm\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .sm\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .sm\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .sm\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .sm\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .sm\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .sm\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .sm\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .sm\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .sm\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .sm\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .sm\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .sm\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .sm\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .sm\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .sm\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .sm\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .sm\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .sm\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .sm\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .sm\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .sm\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .sm\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .sm\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .sm\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .sm\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .sm\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .sm\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .sm\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .sm\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .sm\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .sm\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .sm\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .sm\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .sm\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .sm\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .sm\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .sm\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .sm\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .sm\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .sm\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .sm\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .sm\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .sm\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .sm\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .sm\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .sm\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .sm\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .sm\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .sm\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .sm\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .sm\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .sm\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .sm\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .sm\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .sm\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .sm\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .sm\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .sm\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .sm\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .sm\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .sm\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .sm\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .sm\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .sm\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .sm\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .sm\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .sm\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .sm\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .sm\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .sm\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .sm\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .sm\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .sm\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .sm\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .sm\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .sm\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .sm\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .sm\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .sm\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .sm\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .sm\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .sm\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .sm\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .sm\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .sm\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .sm\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .sm\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .sm\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .sm\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .sm\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .sm\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .sm\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .sm\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .sm\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .sm\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .sm\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .sm\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .sm\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .sm\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .sm\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .sm\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .sm\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .sm\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .sm\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .sm\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .sm\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .sm\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .sm\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .sm\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .sm\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .sm\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .sm\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .sm\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .sm\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .sm\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .sm\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .sm\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .sm\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .sm\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .sm\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .sm\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .sm\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .sm\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .sm\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .sm\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .sm\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .sm\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .sm\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .sm\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .sm\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .sm\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .sm\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .sm\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .sm\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .sm\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .sm\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .sm\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .sm\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .sm\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .sm\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .sm\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .sm\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .sm\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .sm\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .sm\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .sm\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .sm\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .sm\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .sm\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .sm\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .sm\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .sm\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .sm\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .sm\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .sm\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .sm\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .sm\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .sm\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .sm\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .sm\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .sm\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .sm\:bg-bottom {
    background-position: bottom;
  }
  .sm\:bg-center {
    background-position: 50%;
  }
  .sm\:bg-left {
    background-position: 0;
  }
  .sm\:bg-left-bottom {
    background-position: 0 100%;
  }
  .sm\:bg-left-top {
    background-position: 0 0;
  }
  .sm\:bg-right {
    background-position: 100%;
  }
  .sm\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .sm\:bg-right-top {
    background-position: 100% 0;
  }
  .sm\:bg-top {
    background-position: top;
  }
  .sm\:bg-repeat {
    background-repeat: repeat;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .sm\:bg-repeat-round {
    background-repeat: round;
  }
  .sm\:bg-repeat-space {
    background-repeat: space;
  }
  .sm\:bg-auto {
    background-size: auto;
  }
  .sm\:bg-cover {
    background-size: cover;
  }
  .sm\:bg-contain {
    background-size: contain;
  }
  .sm\:border-collapse {
    border-collapse: collapse;
  }
  .sm\:border-separate {
    border-collapse: separate;
  }
  .sm\:border-transparent {
    border-color: transparent;
  }
  .sm\:border-current {
    border-color: currentColor;
  }
  .sm\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .sm\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:border-background-primary {
    border-color: var(--background-primary);
  }
  .sm\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .sm\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .sm\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .sm\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .sm\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .sm\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .sm\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .sm\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .sm\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .sm\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .sm\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .sm\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .sm\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .sm\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .sm\:border-body-background {
    border-color: var(--body-background);
  }
  .sm\:border-background {
    border-color: var(--background);
  }
  .sm\:border-input-background {
    border-color: var(--input-background);
  }
  .sm\:border-body-text {
    border-color: var(--body-text);
  }
  .sm\:border-header-text {
    border-color: var(--header-text);
  }
  .sm\:border-primary {
    border-color: var(--primary);
  }
  .sm\:border-secondary {
    border-color: var(--secondary);
  }
  .sm\:border-tertiary {
    border-color: var(--tertiary);
  }
  .sm\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .sm\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .sm\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .sm\:border-input-text {
    border-color: var(--input-text);
  }
  .sm\:border-success {
    border-color: var(--success);
  }
  .sm\:border-error {
    border-color: var(--error);
  }
  .sm\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .sm\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .sm\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .sm\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .sm\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .sm\:border-background50 {
    border-color: var(--background50);
  }
  .sm\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .sm\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .sm\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .sm\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .sm\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .sm\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .sm\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .sm\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .sm\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .sm\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .sm\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .sm\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .sm\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .sm\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .sm\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .sm\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .sm\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .sm\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .sm\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .sm\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .sm\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .sm\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .sm\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .sm\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .sm\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .sm\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .sm\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .sm\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .sm\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .sm\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .sm\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .sm\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .sm\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .sm\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .sm\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .sm\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .sm\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .sm\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .sm\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .sm\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .sm\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .sm\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .sm\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .sm\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .sm\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .sm\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .sm\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .sm\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .sm\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .sm\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .sm\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .sm\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .sm\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .sm\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .sm\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .sm\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .sm\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .sm\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .sm\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .sm\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .sm\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .sm\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .sm\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .sm\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .sm\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .sm\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .sm\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .sm\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .sm\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .sm\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .sm\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .sm\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .sm\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .sm\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .sm\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .sm\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .sm\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .sm\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .sm\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .sm\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .sm\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .sm\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .sm\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .sm\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .sm\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .sm\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .sm\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .sm\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .sm\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .sm\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .sm\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .sm\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .sm\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .sm\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .sm\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .sm\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .sm\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .sm\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .sm\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .sm\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .sm\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .sm\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .sm\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .sm\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .sm\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .sm\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .sm\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .sm\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .sm\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .sm\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .sm\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .sm\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .sm\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .sm\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .sm\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .sm\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .sm\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .sm\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .sm\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .sm\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .sm\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .sm\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .sm\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .sm\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .sm\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .sm\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .sm\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .sm\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .sm\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .sm\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .sm\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .sm\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .sm\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .sm\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .sm\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .sm\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .sm\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .sm\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .sm\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .sm\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .sm\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .sm\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .sm\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .sm\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .sm\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .sm\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .sm\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .sm\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .sm\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .sm\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .sm\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .sm\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .sm\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .sm\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .sm\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .sm\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .sm\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .sm\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .sm\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .sm\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .sm\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .sm\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .sm\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .sm\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .sm\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .sm\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .sm\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .sm\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .sm\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .sm\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .sm\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .sm\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .sm\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .sm\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .sm\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .sm\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .sm\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .sm\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .sm\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .sm\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .sm\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .sm\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .sm\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .sm\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .sm\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .sm\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .sm\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .sm\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .sm\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .sm\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .sm\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .sm\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .sm\:rounded-none {
    border-radius: 0;
  }
  .sm\:rounded-sm {
    border-radius: 0.125rem;
  }
  .sm\:rounded {
    border-radius: 0.25rem;
  }
  .sm\:rounded-md {
    border-radius: 0.375rem;
  }
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
  .sm\:rounded-xl {
    border-radius: 0.75rem;
  }
  .sm\:rounded-2xl {
    border-radius: 1rem;
  }
  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .sm\:rounded-full {
    border-radius: 9999px;
  }
  .sm\:rounded-card {
    border-radius: 20px;
  }
  .sm\:rounded-time-item {
    border-radius: 100px;
  }
  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-t {
    border-top-left-radius: 0.25rem;
  }
  .sm\:rounded-r,
  .sm\:rounded-t {
    border-top-right-radius: 0.25rem;
  }
  .sm\:rounded-b,
  .sm\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .sm\:rounded-b,
  .sm\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .sm\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .sm\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .sm\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .sm\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .sm\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .sm\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .sm\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .sm\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .sm\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .sm\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .sm\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .sm\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .sm\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .sm\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .sm\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .sm\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .sm\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .sm\:border-solid {
    border-style: solid;
  }
  .sm\:border-dashed {
    border-style: dashed;
  }
  .sm\:border-dotted {
    border-style: dotted;
  }
  .sm\:border-double {
    border-style: double;
  }
  .sm\:border-none {
    border-style: none;
  }
  .sm\:border-0 {
    border-width: 0;
  }
  .sm\:border-2 {
    border-width: 2px;
  }
  .sm\:border-4 {
    border-width: 4px;
  }
  .sm\:border-8 {
    border-width: 8px;
  }
  .sm\:border {
    border-width: 1px;
  }
  .sm\:border-t-0 {
    border-top-width: 0;
  }
  .sm\:border-r-0 {
    border-right-width: 0;
  }
  .sm\:border-b-0 {
    border-bottom-width: 0;
  }
  .sm\:border-l-0 {
    border-left-width: 0;
  }
  .sm\:border-t-2 {
    border-top-width: 2px;
  }
  .sm\:border-r-2 {
    border-right-width: 2px;
  }
  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }
  .sm\:border-l-2 {
    border-left-width: 2px;
  }
  .sm\:border-t-4 {
    border-top-width: 4px;
  }
  .sm\:border-r-4 {
    border-right-width: 4px;
  }
  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }
  .sm\:border-l-4 {
    border-left-width: 4px;
  }
  .sm\:border-t-8 {
    border-top-width: 8px;
  }
  .sm\:border-r-8 {
    border-right-width: 8px;
  }
  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }
  .sm\:border-l-8 {
    border-left-width: 8px;
  }
  .sm\:border-t {
    border-top-width: 1px;
  }
  .sm\:border-r {
    border-right-width: 1px;
  }
  .sm\:border-b {
    border-bottom-width: 1px;
  }
  .sm\:border-l {
    border-left-width: 1px;
  }
  .sm\:box-border {
    box-sizing: border-box;
  }
  .sm\:box-content {
    box-sizing: content-box;
  }
  .sm\:cursor-auto {
    cursor: auto;
  }
  .sm\:cursor-default {
    cursor: default;
  }
  .sm\:cursor-pointer {
    cursor: pointer;
  }
  .sm\:cursor-wait {
    cursor: wait;
  }
  .sm\:cursor-text {
    cursor: text;
  }
  .sm\:cursor-move {
    cursor: move;
  }
  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .sm\:block {
    display: block;
  }
  .sm\:inline-block {
    display: inline-block;
  }
  .sm\:inline {
    display: inline;
  }
  .sm\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .sm\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .sm\:table {
    display: table;
  }
  .sm\:table-caption {
    display: table-caption;
  }
  .sm\:table-cell {
    display: table-cell;
  }
  .sm\:table-column {
    display: table-column;
  }
  .sm\:table-column-group {
    display: table-column-group;
  }
  .sm\:table-footer-group {
    display: table-footer-group;
  }
  .sm\:table-header-group {
    display: table-header-group;
  }
  .sm\:table-row-group {
    display: table-row-group;
  }
  .sm\:table-row {
    display: table-row;
  }
  .sm\:flow-root {
    display: flow-root;
  }
  .sm\:grid {
    display: grid;
  }
  .sm\:inline-grid {
    display: inline-grid;
  }
  .sm\:contents {
    display: contents;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .sm\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .sm\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sm\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .sm\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sm\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .sm\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .sm\:place-items-auto {
    place-items: auto;
  }
  .sm\:place-items-start {
    place-items: start;
  }
  .sm\:place-items-end {
    place-items: end;
  }
  .sm\:place-items-center {
    place-items: center;
  }
  .sm\:place-items-stretch {
    place-items: stretch;
  }
  .sm\:place-content-center {
    place-content: center;
  }
  .sm\:place-content-start {
    place-content: start;
  }
  .sm\:place-content-end {
    place-content: end;
  }
  .sm\:place-content-between {
    place-content: space-between;
  }
  .sm\:place-content-around {
    place-content: space-around;
  }
  .sm\:place-content-evenly {
    place-content: space-evenly;
  }
  .sm\:place-content-stretch {
    place-content: stretch;
  }
  .sm\:place-self-auto {
    place-self: auto;
  }
  .sm\:place-self-start {
    place-self: start;
  }
  .sm\:place-self-end {
    place-self: end;
  }
  .sm\:place-self-center {
    place-self: center;
  }
  .sm\:place-self-stretch {
    place-self: stretch;
  }
  .sm\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .sm\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .sm\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sm\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .sm\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .sm\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .sm\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .sm\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .sm\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .sm\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .sm\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .sm\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .sm\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .sm\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .sm\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .sm\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .sm\:justify-items-auto {
    justify-items: auto;
  }
  .sm\:justify-items-start {
    justify-items: start;
  }
  .sm\:justify-items-end {
    justify-items: end;
  }
  .sm\:justify-items-center {
    justify-items: center;
  }
  .sm\:justify-items-stretch {
    justify-items: stretch;
  }
  .sm\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .sm\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .sm\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sm\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .sm\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .sm\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .sm\:justify-self-auto {
    justify-self: auto;
  }
  .sm\:justify-self-start {
    justify-self: start;
  }
  .sm\:justify-self-end {
    justify-self: end;
  }
  .sm\:justify-self-center {
    justify-self: center;
  }
  .sm\:justify-self-stretch {
    justify-self: stretch;
  }
  .sm\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .sm\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .sm\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .sm\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .sm\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .sm\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .sm\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .sm\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .sm\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .sm\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .sm\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .sm\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .sm\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .sm\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .sm\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .sm\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .sm\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .sm\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .sm\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .sm\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .sm\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .sm\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .sm\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .sm\:float-right {
    float: right;
  }
  .sm\:float-left {
    float: left;
  }
  .sm\:float-none {
    float: none;
  }
  .sm\:clear-left {
    clear: left;
  }
  .sm\:clear-right {
    clear: right;
  }
  .sm\:clear-both {
    clear: both;
  }
  .sm\:clear-none {
    clear: none;
  }
  .sm\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .sm\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .sm\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .sm\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .sm\:font-text {
    font-family: Bozon, sans-serif;
  }
  .sm\:font-thin {
    font-weight: 100;
  }
  .sm\:font-extralight {
    font-weight: 200;
  }
  .sm\:font-light {
    font-weight: 300;
  }
  .sm\:font-normal {
    font-weight: 400;
  }
  .sm\:font-medium {
    font-weight: 500;
  }
  .sm\:font-semibold {
    font-weight: 600;
  }
  .sm\:font-bold {
    font-weight: 700;
  }
  .sm\:font-extrabold {
    font-weight: 800;
  }
  .sm\:font-black {
    font-weight: 900;
  }
  .sm\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .sm\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .sm\:hover\:font-light:hover {
    font-weight: 300;
  }
  .sm\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .sm\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .sm\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .sm\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .sm\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .sm\:hover\:font-black:hover {
    font-weight: 900;
  }
  .sm\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .sm\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .sm\:focus\:font-light:focus {
    font-weight: 300;
  }
  .sm\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .sm\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .sm\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .sm\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .sm\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .sm\:focus\:font-black:focus {
    font-weight: 900;
  }
  .sm\:h-0 {
    height: 0;
  }
  .sm\:h-1 {
    height: 0.25rem;
  }
  .sm\:h-2 {
    height: 0.5rem;
  }
  .sm\:h-3 {
    height: 0.75rem;
  }
  .sm\:h-4 {
    height: 1rem;
  }
  .sm\:h-5 {
    height: 1.25rem;
  }
  .sm\:h-6 {
    height: 1.5rem;
  }
  .sm\:h-7 {
    height: 1.75rem;
  }
  .sm\:h-8 {
    height: 2rem;
  }
  .sm\:h-9 {
    height: 2.25rem;
  }
  .sm\:h-10 {
    height: 2.5rem;
  }
  .sm\:h-11 {
    height: 2.75rem;
  }
  .sm\:h-12 {
    height: 3rem;
  }
  .sm\:h-14 {
    height: 3.5rem;
  }
  .sm\:h-16 {
    height: 4rem;
  }
  .sm\:h-20 {
    height: 5rem;
  }
  .sm\:h-24 {
    height: 6rem;
  }
  .sm\:h-28 {
    height: 7rem;
  }
  .sm\:h-32 {
    height: 8rem;
  }
  .sm\:h-36 {
    height: 9rem;
  }
  .sm\:h-40 {
    height: 10rem;
  }
  .sm\:h-44 {
    height: 11rem;
  }
  .sm\:h-48 {
    height: 12rem;
  }
  .sm\:h-52 {
    height: 13rem;
  }
  .sm\:h-56 {
    height: 14rem;
  }
  .sm\:h-60 {
    height: 15rem;
  }
  .sm\:h-64 {
    height: 16rem;
  }
  .sm\:h-72 {
    height: 18rem;
  }
  .sm\:h-80 {
    height: 20rem;
  }
  .sm\:h-96 {
    height: 24rem;
  }
  .sm\:h-105 {
    height: 105px;
  }
  .sm\:h-188 {
    height: 188px;
  }
  .sm\:h-344 {
    height: 344px;
  }
  .sm\:h-425 {
    height: 425px;
  }
  .sm\:h-627 {
    height: 627px;
  }
  .sm\:h-auto {
    height: auto;
  }
  .sm\:h-px {
    height: 1px;
  }
  .sm\:h-0\.5 {
    height: 0.125rem;
  }
  .sm\:h-1\.5 {
    height: 0.375rem;
  }
  .sm\:h-2\.5 {
    height: 0.625rem;
  }
  .sm\:h-3\.5 {
    height: 0.875rem;
  }
  .sm\:h-1\/2 {
    height: 50%;
  }
  .sm\:h-1\/3 {
    height: 33.333333%;
  }
  .sm\:h-2\/3 {
    height: 66.666667%;
  }
  .sm\:h-1\/4 {
    height: 25%;
  }
  .sm\:h-2\/4 {
    height: 50%;
  }
  .sm\:h-3\/4 {
    height: 75%;
  }
  .sm\:h-1\/5 {
    height: 20%;
  }
  .sm\:h-2\/5 {
    height: 40%;
  }
  .sm\:h-3\/5 {
    height: 60%;
  }
  .sm\:h-4\/5 {
    height: 80%;
  }
  .sm\:h-1\/6 {
    height: 16.666667%;
  }
  .sm\:h-2\/6 {
    height: 33.333333%;
  }
  .sm\:h-3\/6 {
    height: 50%;
  }
  .sm\:h-4\/6 {
    height: 66.666667%;
  }
  .sm\:h-5\/6 {
    height: 83.333333%;
  }
  .sm\:h-full {
    height: 100%;
  }
  .sm\:h-screen {
    height: 100vh;
  }
  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-base {
    font-size: 1em;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .sm\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .sm\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .sm\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .sm\:text-m-sm {
    font-size: 0.8em;
  }
  .sm\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .sm\:text-m-xl {
    font-size: 1.2em;
  }
  .sm\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .sm\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .sm\:text-m-4xl {
    font-size: 1.6em;
  }
  .sm\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .sm\:text-d-sm {
    font-size: 0.875em;
  }
  .sm\:text-d-lg {
    font-size: 1.125em;
  }
  .sm\:text-d-xl {
    font-size: 1.375em;
  }
  .sm\:text-d-2xl {
    font-size: 1.625em;
  }
  .sm\:text-d-3xl {
    font-size: 1.75em;
  }
  .sm\:text-d-4xl {
    font-size: 2.25em;
  }
  .sm\:text-d-5xl {
    font-size: 2.625em;
  }
  .sm\:text-d-11xl {
    font-size: 16em;
  }
  .sm\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .sm\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .sm\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .sm\:leading-3 {
    line-height: 0.75rem;
  }
  .sm\:leading-4 {
    line-height: 1rem;
  }
  .sm\:leading-5 {
    line-height: 1.25rem;
  }
  .sm\:leading-6 {
    line-height: 1.5rem;
  }
  .sm\:leading-7 {
    line-height: 1.75rem;
  }
  .sm\:leading-8 {
    line-height: 2rem;
  }
  .sm\:leading-9 {
    line-height: 2.25rem;
  }
  .sm\:leading-10 {
    line-height: 2.5rem;
  }
  .sm\:leading-none {
    line-height: 1;
  }
  .sm\:leading-tight {
    line-height: 1.25;
  }
  .sm\:leading-snug {
    line-height: 1.375;
  }
  .sm\:leading-normal {
    line-height: 1.5;
  }
  .sm\:leading-relaxed {
    line-height: 1.625;
  }
  .sm\:leading-loose {
    line-height: 2;
  }
  .sm\:list-inside {
    list-style-position: inside;
  }
  .sm\:list-outside {
    list-style-position: outside;
  }
  .sm\:list-none {
    list-style-type: none;
  }
  .sm\:list-disc {
    list-style-type: disc;
  }
  .sm\:list-decimal {
    list-style-type: decimal;
  }
  .sm\:m-0 {
    margin: 0;
  }
  .sm\:m-1 {
    margin: 0.25rem;
  }
  .sm\:m-2 {
    margin: 0.5rem;
  }
  .sm\:m-3 {
    margin: 0.75rem;
  }
  .sm\:m-4 {
    margin: 1rem;
  }
  .sm\:m-5 {
    margin: 1.25rem;
  }
  .sm\:m-6 {
    margin: 1.5rem;
  }
  .sm\:m-7 {
    margin: 1.75rem;
  }
  .sm\:m-8 {
    margin: 2rem;
  }
  .sm\:m-9 {
    margin: 2.25rem;
  }
  .sm\:m-10 {
    margin: 2.5rem;
  }
  .sm\:m-11 {
    margin: 2.75rem;
  }
  .sm\:m-12 {
    margin: 3rem;
  }
  .sm\:m-14 {
    margin: 3.5rem;
  }
  .sm\:m-16 {
    margin: 4rem;
  }
  .sm\:m-20 {
    margin: 5rem;
  }
  .sm\:m-24 {
    margin: 6rem;
  }
  .sm\:m-28 {
    margin: 7rem;
  }
  .sm\:m-32 {
    margin: 8rem;
  }
  .sm\:m-36 {
    margin: 9rem;
  }
  .sm\:m-40 {
    margin: 10rem;
  }
  .sm\:m-44 {
    margin: 11rem;
  }
  .sm\:m-48 {
    margin: 12rem;
  }
  .sm\:m-52 {
    margin: 13rem;
  }
  .sm\:m-56 {
    margin: 14rem;
  }
  .sm\:m-60 {
    margin: 15rem;
  }
  .sm\:m-64 {
    margin: 16rem;
  }
  .sm\:m-72 {
    margin: 18rem;
  }
  .sm\:m-80 {
    margin: 20rem;
  }
  .sm\:m-96 {
    margin: 24rem;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:m-px {
    margin: 1px;
  }
  .sm\:m-0\.5 {
    margin: 0.125rem;
  }
  .sm\:m-1\.5 {
    margin: 0.375rem;
  }
  .sm\:m-2\.5 {
    margin: 0.625rem;
  }
  .sm\:m-3\.5 {
    margin: 0.875rem;
  }
  .sm\:-m-0 {
    margin: 0;
  }
  .sm\:-m-1 {
    margin: -0.25rem;
  }
  .sm\:-m-2 {
    margin: -0.5rem;
  }
  .sm\:-m-3 {
    margin: -0.75rem;
  }
  .sm\:-m-4 {
    margin: -1rem;
  }
  .sm\:-m-5 {
    margin: -1.25rem;
  }
  .sm\:-m-6 {
    margin: -1.5rem;
  }
  .sm\:-m-7 {
    margin: -1.75rem;
  }
  .sm\:-m-8 {
    margin: -2rem;
  }
  .sm\:-m-9 {
    margin: -2.25rem;
  }
  .sm\:-m-10 {
    margin: -2.5rem;
  }
  .sm\:-m-11 {
    margin: -2.75rem;
  }
  .sm\:-m-12 {
    margin: -3rem;
  }
  .sm\:-m-14 {
    margin: -3.5rem;
  }
  .sm\:-m-16 {
    margin: -4rem;
  }
  .sm\:-m-20 {
    margin: -5rem;
  }
  .sm\:-m-24 {
    margin: -6rem;
  }
  .sm\:-m-28 {
    margin: -7rem;
  }
  .sm\:-m-32 {
    margin: -8rem;
  }
  .sm\:-m-36 {
    margin: -9rem;
  }
  .sm\:-m-40 {
    margin: -10rem;
  }
  .sm\:-m-44 {
    margin: -11rem;
  }
  .sm\:-m-48 {
    margin: -12rem;
  }
  .sm\:-m-52 {
    margin: -13rem;
  }
  .sm\:-m-56 {
    margin: -14rem;
  }
  .sm\:-m-60 {
    margin: -15rem;
  }
  .sm\:-m-64 {
    margin: -16rem;
  }
  .sm\:-m-72 {
    margin: -18rem;
  }
  .sm\:-m-80 {
    margin: -20rem;
  }
  .sm\:-m-96 {
    margin: -24rem;
  }
  .sm\:-m-px {
    margin: -1px;
  }
  .sm\:-m-0\.5 {
    margin: -0.125rem;
  }
  .sm\:-m-1\.5 {
    margin: -0.375rem;
  }
  .sm\:-m-2\.5 {
    margin: -0.625rem;
  }
  .sm\:-m-3\.5 {
    margin: -0.875rem;
  }
  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .sm\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .sm\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .sm\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .sm\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .sm\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .sm\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .sm\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .sm\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .sm\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .sm\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .sm\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .sm\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .sm\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .sm\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .sm\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .sm\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .sm\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .sm\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .sm\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .sm\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .sm\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .sm\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .sm\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .sm\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .sm\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .sm\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .sm\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .sm\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .sm\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .sm\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .sm\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .sm\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .sm\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .sm\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .sm\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .sm\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .sm\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .sm\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .sm\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .sm\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .sm\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .sm\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .sm\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .sm\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .sm\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .sm\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .sm\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .sm\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .sm\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .sm\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .sm\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .sm\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .sm\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .sm\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .sm\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .sm\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .sm\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .sm\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .sm\:mt-0 {
    margin-top: 0;
  }
  .sm\:mr-0 {
    margin-right: 0;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }
  .sm\:ml-0 {
    margin-left: 0;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem;
  }
  .sm\:mt-3 {
    margin-top: 0.75rem;
  }
  .sm\:mr-3 {
    margin-right: 0.75rem;
  }
  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:mt-4 {
    margin-top: 1rem;
  }
  .sm\:mr-4 {
    margin-right: 1rem;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:mt-5 {
    margin-top: 1.25rem;
  }
  .sm\:mr-5 {
    margin-right: 1.25rem;
  }
  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .sm\:ml-5 {
    margin-left: 1.25rem;
  }
  .sm\:mt-6 {
    margin-top: 1.5rem;
  }
  .sm\:mr-6 {
    margin-right: 1.5rem;
  }
  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  .sm\:mt-7 {
    margin-top: 1.75rem;
  }
  .sm\:mr-7 {
    margin-right: 1.75rem;
  }
  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .sm\:ml-7 {
    margin-left: 1.75rem;
  }
  .sm\:mt-8 {
    margin-top: 2rem;
  }
  .sm\:mr-8 {
    margin-right: 2rem;
  }
  .sm\:mb-8 {
    margin-bottom: 2rem;
  }
  .sm\:ml-8 {
    margin-left: 2rem;
  }
  .sm\:mt-9 {
    margin-top: 2.25rem;
  }
  .sm\:mr-9 {
    margin-right: 2.25rem;
  }
  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .sm\:ml-9 {
    margin-left: 2.25rem;
  }
  .sm\:mt-10 {
    margin-top: 2.5rem;
  }
  .sm\:mr-10 {
    margin-right: 2.5rem;
  }
  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .sm\:ml-10 {
    margin-left: 2.5rem;
  }
  .sm\:mt-11 {
    margin-top: 2.75rem;
  }
  .sm\:mr-11 {
    margin-right: 2.75rem;
  }
  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .sm\:ml-11 {
    margin-left: 2.75rem;
  }
  .sm\:mt-12 {
    margin-top: 3rem;
  }
  .sm\:mr-12 {
    margin-right: 3rem;
  }
  .sm\:mb-12 {
    margin-bottom: 3rem;
  }
  .sm\:ml-12 {
    margin-left: 3rem;
  }
  .sm\:mt-14 {
    margin-top: 3.5rem;
  }
  .sm\:mr-14 {
    margin-right: 3.5rem;
  }
  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .sm\:ml-14 {
    margin-left: 3.5rem;
  }
  .sm\:mt-16 {
    margin-top: 4rem;
  }
  .sm\:mr-16 {
    margin-right: 4rem;
  }
  .sm\:mb-16 {
    margin-bottom: 4rem;
  }
  .sm\:ml-16 {
    margin-left: 4rem;
  }
  .sm\:mt-20 {
    margin-top: 5rem;
  }
  .sm\:mr-20 {
    margin-right: 5rem;
  }
  .sm\:mb-20 {
    margin-bottom: 5rem;
  }
  .sm\:ml-20 {
    margin-left: 5rem;
  }
  .sm\:mt-24 {
    margin-top: 6rem;
  }
  .sm\:mr-24 {
    margin-right: 6rem;
  }
  .sm\:mb-24 {
    margin-bottom: 6rem;
  }
  .sm\:ml-24 {
    margin-left: 6rem;
  }
  .sm\:mt-28 {
    margin-top: 7rem;
  }
  .sm\:mr-28 {
    margin-right: 7rem;
  }
  .sm\:mb-28 {
    margin-bottom: 7rem;
  }
  .sm\:ml-28 {
    margin-left: 7rem;
  }
  .sm\:mt-32 {
    margin-top: 8rem;
  }
  .sm\:mr-32 {
    margin-right: 8rem;
  }
  .sm\:mb-32 {
    margin-bottom: 8rem;
  }
  .sm\:ml-32 {
    margin-left: 8rem;
  }
  .sm\:mt-36 {
    margin-top: 9rem;
  }
  .sm\:mr-36 {
    margin-right: 9rem;
  }
  .sm\:mb-36 {
    margin-bottom: 9rem;
  }
  .sm\:ml-36 {
    margin-left: 9rem;
  }
  .sm\:mt-40 {
    margin-top: 10rem;
  }
  .sm\:mr-40 {
    margin-right: 10rem;
  }
  .sm\:mb-40 {
    margin-bottom: 10rem;
  }
  .sm\:ml-40 {
    margin-left: 10rem;
  }
  .sm\:mt-44 {
    margin-top: 11rem;
  }
  .sm\:mr-44 {
    margin-right: 11rem;
  }
  .sm\:mb-44 {
    margin-bottom: 11rem;
  }
  .sm\:ml-44 {
    margin-left: 11rem;
  }
  .sm\:mt-48 {
    margin-top: 12rem;
  }
  .sm\:mr-48 {
    margin-right: 12rem;
  }
  .sm\:mb-48 {
    margin-bottom: 12rem;
  }
  .sm\:ml-48 {
    margin-left: 12rem;
  }
  .sm\:mt-52 {
    margin-top: 13rem;
  }
  .sm\:mr-52 {
    margin-right: 13rem;
  }
  .sm\:mb-52 {
    margin-bottom: 13rem;
  }
  .sm\:ml-52 {
    margin-left: 13rem;
  }
  .sm\:mt-56 {
    margin-top: 14rem;
  }
  .sm\:mr-56 {
    margin-right: 14rem;
  }
  .sm\:mb-56 {
    margin-bottom: 14rem;
  }
  .sm\:ml-56 {
    margin-left: 14rem;
  }
  .sm\:mt-60 {
    margin-top: 15rem;
  }
  .sm\:mr-60 {
    margin-right: 15rem;
  }
  .sm\:mb-60 {
    margin-bottom: 15rem;
  }
  .sm\:ml-60 {
    margin-left: 15rem;
  }
  .sm\:mt-64 {
    margin-top: 16rem;
  }
  .sm\:mr-64 {
    margin-right: 16rem;
  }
  .sm\:mb-64 {
    margin-bottom: 16rem;
  }
  .sm\:ml-64 {
    margin-left: 16rem;
  }
  .sm\:mt-72 {
    margin-top: 18rem;
  }
  .sm\:mr-72 {
    margin-right: 18rem;
  }
  .sm\:mb-72 {
    margin-bottom: 18rem;
  }
  .sm\:ml-72 {
    margin-left: 18rem;
  }
  .sm\:mt-80 {
    margin-top: 20rem;
  }
  .sm\:mr-80 {
    margin-right: 20rem;
  }
  .sm\:mb-80 {
    margin-bottom: 20rem;
  }
  .sm\:ml-80 {
    margin-left: 20rem;
  }
  .sm\:mt-96 {
    margin-top: 24rem;
  }
  .sm\:mr-96 {
    margin-right: 24rem;
  }
  .sm\:mb-96 {
    margin-bottom: 24rem;
  }
  .sm\:ml-96 {
    margin-left: 24rem;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:mt-px {
    margin-top: 1px;
  }
  .sm\:mr-px {
    margin-right: 1px;
  }
  .sm\:mb-px {
    margin-bottom: 1px;
  }
  .sm\:ml-px {
    margin-left: 1px;
  }
  .sm\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .sm\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .sm\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .sm\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .sm\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .sm\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .sm\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .sm\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .sm\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .sm\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .sm\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .sm\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .sm\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .sm\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .sm\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .sm\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .sm\:-mt-0 {
    margin-top: 0;
  }
  .sm\:-mr-0 {
    margin-right: 0;
  }
  .sm\:-mb-0 {
    margin-bottom: 0;
  }
  .sm\:-ml-0 {
    margin-left: 0;
  }
  .sm\:-mt-1 {
    margin-top: -0.25rem;
  }
  .sm\:-mr-1 {
    margin-right: -0.25rem;
  }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .sm\:-ml-1 {
    margin-left: -0.25rem;
  }
  .sm\:-mt-2 {
    margin-top: -0.5rem;
  }
  .sm\:-mr-2 {
    margin-right: -0.5rem;
  }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .sm\:-ml-2 {
    margin-left: -0.5rem;
  }
  .sm\:-mt-3 {
    margin-top: -0.75rem;
  }
  .sm\:-mr-3 {
    margin-right: -0.75rem;
  }
  .sm\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .sm\:-ml-3 {
    margin-left: -0.75rem;
  }
  .sm\:-mt-4 {
    margin-top: -1rem;
  }
  .sm\:-mr-4 {
    margin-right: -1rem;
  }
  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }
  .sm\:-ml-4 {
    margin-left: -1rem;
  }
  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }
  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }
  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }
  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }
  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }
  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }
  .sm\:-mt-7 {
    margin-top: -1.75rem;
  }
  .sm\:-mr-7 {
    margin-right: -1.75rem;
  }
  .sm\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .sm\:-ml-7 {
    margin-left: -1.75rem;
  }
  .sm\:-mt-8 {
    margin-top: -2rem;
  }
  .sm\:-mr-8 {
    margin-right: -2rem;
  }
  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }
  .sm\:-ml-8 {
    margin-left: -2rem;
  }
  .sm\:-mt-9 {
    margin-top: -2.25rem;
  }
  .sm\:-mr-9 {
    margin-right: -2.25rem;
  }
  .sm\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .sm\:-ml-9 {
    margin-left: -2.25rem;
  }
  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }
  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }
  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }
  .sm\:-mt-11 {
    margin-top: -2.75rem;
  }
  .sm\:-mr-11 {
    margin-right: -2.75rem;
  }
  .sm\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .sm\:-ml-11 {
    margin-left: -2.75rem;
  }
  .sm\:-mt-12 {
    margin-top: -3rem;
  }
  .sm\:-mr-12 {
    margin-right: -3rem;
  }
  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }
  .sm\:-ml-12 {
    margin-left: -3rem;
  }
  .sm\:-mt-14 {
    margin-top: -3.5rem;
  }
  .sm\:-mr-14 {
    margin-right: -3.5rem;
  }
  .sm\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .sm\:-ml-14 {
    margin-left: -3.5rem;
  }
  .sm\:-mt-16 {
    margin-top: -4rem;
  }
  .sm\:-mr-16 {
    margin-right: -4rem;
  }
  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }
  .sm\:-ml-16 {
    margin-left: -4rem;
  }
  .sm\:-mt-20 {
    margin-top: -5rem;
  }
  .sm\:-mr-20 {
    margin-right: -5rem;
  }
  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }
  .sm\:-ml-20 {
    margin-left: -5rem;
  }
  .sm\:-mt-24 {
    margin-top: -6rem;
  }
  .sm\:-mr-24 {
    margin-right: -6rem;
  }
  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }
  .sm\:-ml-24 {
    margin-left: -6rem;
  }
  .sm\:-mt-28 {
    margin-top: -7rem;
  }
  .sm\:-mr-28 {
    margin-right: -7rem;
  }
  .sm\:-mb-28 {
    margin-bottom: -7rem;
  }
  .sm\:-ml-28 {
    margin-left: -7rem;
  }
  .sm\:-mt-32 {
    margin-top: -8rem;
  }
  .sm\:-mr-32 {
    margin-right: -8rem;
  }
  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }
  .sm\:-ml-32 {
    margin-left: -8rem;
  }
  .sm\:-mt-36 {
    margin-top: -9rem;
  }
  .sm\:-mr-36 {
    margin-right: -9rem;
  }
  .sm\:-mb-36 {
    margin-bottom: -9rem;
  }
  .sm\:-ml-36 {
    margin-left: -9rem;
  }
  .sm\:-mt-40 {
    margin-top: -10rem;
  }
  .sm\:-mr-40 {
    margin-right: -10rem;
  }
  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }
  .sm\:-ml-40 {
    margin-left: -10rem;
  }
  .sm\:-mt-44 {
    margin-top: -11rem;
  }
  .sm\:-mr-44 {
    margin-right: -11rem;
  }
  .sm\:-mb-44 {
    margin-bottom: -11rem;
  }
  .sm\:-ml-44 {
    margin-left: -11rem;
  }
  .sm\:-mt-48 {
    margin-top: -12rem;
  }
  .sm\:-mr-48 {
    margin-right: -12rem;
  }
  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }
  .sm\:-ml-48 {
    margin-left: -12rem;
  }
  .sm\:-mt-52 {
    margin-top: -13rem;
  }
  .sm\:-mr-52 {
    margin-right: -13rem;
  }
  .sm\:-mb-52 {
    margin-bottom: -13rem;
  }
  .sm\:-ml-52 {
    margin-left: -13rem;
  }
  .sm\:-mt-56 {
    margin-top: -14rem;
  }
  .sm\:-mr-56 {
    margin-right: -14rem;
  }
  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }
  .sm\:-ml-56 {
    margin-left: -14rem;
  }
  .sm\:-mt-60 {
    margin-top: -15rem;
  }
  .sm\:-mr-60 {
    margin-right: -15rem;
  }
  .sm\:-mb-60 {
    margin-bottom: -15rem;
  }
  .sm\:-ml-60 {
    margin-left: -15rem;
  }
  .sm\:-mt-64 {
    margin-top: -16rem;
  }
  .sm\:-mr-64 {
    margin-right: -16rem;
  }
  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }
  .sm\:-ml-64 {
    margin-left: -16rem;
  }
  .sm\:-mt-72 {
    margin-top: -18rem;
  }
  .sm\:-mr-72 {
    margin-right: -18rem;
  }
  .sm\:-mb-72 {
    margin-bottom: -18rem;
  }
  .sm\:-ml-72 {
    margin-left: -18rem;
  }
  .sm\:-mt-80 {
    margin-top: -20rem;
  }
  .sm\:-mr-80 {
    margin-right: -20rem;
  }
  .sm\:-mb-80 {
    margin-bottom: -20rem;
  }
  .sm\:-ml-80 {
    margin-left: -20rem;
  }
  .sm\:-mt-96 {
    margin-top: -24rem;
  }
  .sm\:-mr-96 {
    margin-right: -24rem;
  }
  .sm\:-mb-96 {
    margin-bottom: -24rem;
  }
  .sm\:-ml-96 {
    margin-left: -24rem;
  }
  .sm\:-mt-px {
    margin-top: -1px;
  }
  .sm\:-mr-px {
    margin-right: -1px;
  }
  .sm\:-mb-px {
    margin-bottom: -1px;
  }
  .sm\:-ml-px {
    margin-left: -1px;
  }
  .sm\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .sm\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .sm\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .sm\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .sm\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .sm\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .sm\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .sm\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .sm\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .sm\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .sm\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .sm\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .sm\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .sm\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .sm\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .sm\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .sm\:max-h-0 {
    max-height: 0;
  }
  .sm\:max-h-1 {
    max-height: 0.25rem;
  }
  .sm\:max-h-2 {
    max-height: 0.5rem;
  }
  .sm\:max-h-3 {
    max-height: 0.75rem;
  }
  .sm\:max-h-4 {
    max-height: 1rem;
  }
  .sm\:max-h-5 {
    max-height: 1.25rem;
  }
  .sm\:max-h-6 {
    max-height: 1.5rem;
  }
  .sm\:max-h-7 {
    max-height: 1.75rem;
  }
  .sm\:max-h-8 {
    max-height: 2rem;
  }
  .sm\:max-h-9 {
    max-height: 2.25rem;
  }
  .sm\:max-h-10 {
    max-height: 2.5rem;
  }
  .sm\:max-h-11 {
    max-height: 2.75rem;
  }
  .sm\:max-h-12 {
    max-height: 3rem;
  }
  .sm\:max-h-14 {
    max-height: 3.5rem;
  }
  .sm\:max-h-16 {
    max-height: 4rem;
  }
  .sm\:max-h-20 {
    max-height: 5rem;
  }
  .sm\:max-h-24 {
    max-height: 6rem;
  }
  .sm\:max-h-28 {
    max-height: 7rem;
  }
  .sm\:max-h-32 {
    max-height: 8rem;
  }
  .sm\:max-h-36 {
    max-height: 9rem;
  }
  .sm\:max-h-40 {
    max-height: 10rem;
  }
  .sm\:max-h-44 {
    max-height: 11rem;
  }
  .sm\:max-h-48 {
    max-height: 12rem;
  }
  .sm\:max-h-52 {
    max-height: 13rem;
  }
  .sm\:max-h-56 {
    max-height: 14rem;
  }
  .sm\:max-h-60 {
    max-height: 15rem;
  }
  .sm\:max-h-64 {
    max-height: 16rem;
  }
  .sm\:max-h-72 {
    max-height: 18rem;
  }
  .sm\:max-h-80 {
    max-height: 20rem;
  }
  .sm\:max-h-96 {
    max-height: 24rem;
  }
  .sm\:max-h-px {
    max-height: 1px;
  }
  .sm\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .sm\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .sm\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .sm\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .sm\:max-h-full {
    max-height: 100%;
  }
  .sm\:max-h-screen {
    max-height: 100vh;
  }
  .sm\:max-h-1\/4 {
    max-height: 25%;
  }
  .sm\:max-h-1\/2 {
    max-height: 50%;
  }
  .sm\:max-h-3\/4 {
    max-height: 75%;
  }
  .sm\:max-h-10\/12 {
    max-height: 80%;
  }
  .sm\:max-h-enormous {
    max-height: 4000px;
  }
  .sm\:max-w-0 {
    max-width: 0;
  }
  .sm\:max-w-110 {
    max-width: 110px;
  }
  .sm\:max-w-473 {
    max-width: 473px;
  }
  .sm\:max-w-none {
    max-width: none;
  }
  .sm\:max-w-xs {
    max-width: 20rem;
  }
  .sm\:max-w-sm {
    max-width: 24rem;
  }
  .sm\:max-w-md {
    max-width: 28rem;
  }
  .sm\:max-w-lg {
    max-width: 32rem;
  }
  .sm\:max-w-xl {
    max-width: 36rem;
  }
  .sm\:max-w-2xl {
    max-width: 42rem;
  }
  .sm\:max-w-3xl {
    max-width: 48rem;
  }
  .sm\:max-w-4xl {
    max-width: 56rem;
  }
  .sm\:max-w-5xl {
    max-width: 64rem;
  }
  .sm\:max-w-6xl {
    max-width: 72rem;
  }
  .sm\:max-w-7xl {
    max-width: 80rem;
  }
  .sm\:max-w-full {
    max-width: 100%;
  }
  .sm\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .sm\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .sm\:max-w-prose {
    max-width: 65ch;
  }
  .sm\:max-w-screen-sm {
    max-width: 640px;
  }
  .sm\:max-w-screen-md {
    max-width: 768px;
  }
  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }
  .sm\:max-w-screen-xl {
    max-width: 1280px;
  }
  .sm\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .sm\:max-w-screen-xxs {
    max-width: 260px;
  }
  .sm\:max-w-screen-xs {
    max-width: 360px;
  }
  .sm\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .sm\:min-h-0 {
    min-height: 0;
  }
  .sm\:min-h-full {
    min-height: 100%;
  }
  .sm\:min-h-screen {
    min-height: 100vh;
  }
  .sm\:min-w-0 {
    min-width: 0;
  }
  .sm\:min-w-10 {
    min-width: 2.5rem;
  }
  .sm\:min-w-12 {
    min-width: 3rem;
  }
  .sm\:min-w-14 {
    min-width: 3.5rem;
  }
  .sm\:min-w-full {
    min-width: 100%;
  }
  .sm\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .sm\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .sm\:object-contain {
    object-fit: contain;
  }
  .sm\:object-cover {
    object-fit: cover;
  }
  .sm\:object-fill {
    object-fit: fill;
  }
  .sm\:object-none {
    object-fit: none;
  }
  .sm\:object-scale-down {
    object-fit: scale-down;
  }
  .sm\:object-bottom {
    object-position: bottom;
  }
  .sm\:object-center {
    object-position: center;
  }
  .sm\:object-left {
    object-position: left;
  }
  .sm\:object-left-bottom {
    object-position: left bottom;
  }
  .sm\:object-left-top {
    object-position: left top;
  }
  .sm\:object-right {
    object-position: right;
  }
  .sm\:object-right-bottom {
    object-position: right bottom;
  }
  .sm\:object-right-top {
    object-position: right top;
  }
  .sm\:object-top {
    object-position: top;
  }
  .sm\:opacity-0 {
    opacity: 0;
  }
  .sm\:opacity-5 {
    opacity: 0.05;
  }
  .sm\:opacity-10 {
    opacity: 0.1;
  }
  .sm\:opacity-20 {
    opacity: 0.2;
  }
  .sm\:opacity-25 {
    opacity: 0.25;
  }
  .sm\:opacity-30 {
    opacity: 0.3;
  }
  .sm\:opacity-40 {
    opacity: 0.4;
  }
  .sm\:opacity-50 {
    opacity: 0.5;
  }
  .sm\:opacity-60 {
    opacity: 0.6;
  }
  .sm\:opacity-70 {
    opacity: 0.7;
  }
  .sm\:opacity-75 {
    opacity: 0.75;
  }
  .sm\:opacity-80 {
    opacity: 0.8;
  }
  .sm\:opacity-90 {
    opacity: 0.9;
  }
  .sm\:opacity-95 {
    opacity: 0.95;
  }
  .sm\:opacity-100 {
    opacity: 1;
  }
  .group:hover .sm\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .sm\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .sm\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .sm\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .sm\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .sm\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .sm\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .sm\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .sm\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .sm\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .sm\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .sm\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .sm\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .sm\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .sm\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .sm\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .sm\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .sm\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .sm\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .sm\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .sm\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .sm\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .sm\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .sm\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .sm\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .sm\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .sm\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .sm\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .sm\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .sm\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .sm\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .sm\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .sm\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .sm\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .sm\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .sm\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .sm\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .sm\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .sm\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .sm\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .sm\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .sm\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .sm\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .sm\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .sm\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .sm\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .sm\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .sm\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .sm\:overflow-auto {
    overflow: auto;
  }
  .sm\:overflow-hidden {
    overflow: hidden;
  }
  .sm\:overflow-visible {
    overflow: visible;
  }
  .sm\:overflow-scroll {
    overflow: scroll;
  }
  .sm\:overflow-x-auto {
    overflow-x: auto;
  }
  .sm\:overflow-y-auto {
    overflow-y: auto;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .sm\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .sm\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .sm\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .sm\:p-0 {
    padding: 0;
  }
  .sm\:p-1 {
    padding: 0.25rem;
  }
  .sm\:p-2 {
    padding: 0.5rem;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:p-4 {
    padding: 1rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:p-7 {
    padding: 1.75rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:p-9 {
    padding: 2.25rem;
  }
  .sm\:p-10 {
    padding: 2.5rem;
  }
  .sm\:p-11 {
    padding: 2.75rem;
  }
  .sm\:p-12 {
    padding: 3rem;
  }
  .sm\:p-14 {
    padding: 3.5rem;
  }
  .sm\:p-16 {
    padding: 4rem;
  }
  .sm\:p-20 {
    padding: 5rem;
  }
  .sm\:p-24 {
    padding: 6rem;
  }
  .sm\:p-28 {
    padding: 7rem;
  }
  .sm\:p-32 {
    padding: 8rem;
  }
  .sm\:p-36 {
    padding: 9rem;
  }
  .sm\:p-40 {
    padding: 10rem;
  }
  .sm\:p-44 {
    padding: 11rem;
  }
  .sm\:p-48 {
    padding: 12rem;
  }
  .sm\:p-52 {
    padding: 13rem;
  }
  .sm\:p-56 {
    padding: 14rem;
  }
  .sm\:p-60 {
    padding: 15rem;
  }
  .sm\:p-64 {
    padding: 16rem;
  }
  .sm\:p-72 {
    padding: 18rem;
  }
  .sm\:p-80 {
    padding: 20rem;
  }
  .sm\:p-96 {
    padding: 24rem;
  }
  .sm\:p-px {
    padding: 1px;
  }
  .sm\:p-0\.5 {
    padding: 0.125rem;
  }
  .sm\:p-1\.5 {
    padding: 0.375rem;
  }
  .sm\:p-2\.5 {
    padding: 0.625rem;
  }
  .sm\:p-3\.5 {
    padding: 0.875rem;
  }
  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .sm\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .sm\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .sm\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .sm\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .sm\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .sm\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .sm\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .sm\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .sm\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .sm\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .sm\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .sm\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .sm\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .sm\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .sm\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .sm\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .sm\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sm\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .sm\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .sm\:pt-0 {
    padding-top: 0;
  }
  .sm\:pr-0 {
    padding-right: 0;
  }
  .sm\:pb-0 {
    padding-bottom: 0;
  }
  .sm\:pl-0 {
    padding-left: 0;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem;
  }
  .sm\:pt-3 {
    padding-top: 0.75rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .sm\:pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:pt-4 {
    padding-top: 1rem;
  }
  .sm\:pr-4 {
    padding-right: 1rem;
  }
  .sm\:pb-4 {
    padding-bottom: 1rem;
  }
  .sm\:pl-4 {
    padding-left: 1rem;
  }
  .sm\:pt-5 {
    padding-top: 1.25rem;
  }
  .sm\:pr-5 {
    padding-right: 1.25rem;
  }
  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .sm\:pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:pt-6 {
    padding-top: 1.5rem;
  }
  .sm\:pr-6 {
    padding-right: 1.5rem;
  }
  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:pt-7 {
    padding-top: 1.75rem;
  }
  .sm\:pr-7 {
    padding-right: 1.75rem;
  }
  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .sm\:pl-7 {
    padding-left: 1.75rem;
  }
  .sm\:pt-8 {
    padding-top: 2rem;
  }
  .sm\:pr-8 {
    padding-right: 2rem;
  }
  .sm\:pb-8 {
    padding-bottom: 2rem;
  }
  .sm\:pl-8 {
    padding-left: 2rem;
  }
  .sm\:pt-9 {
    padding-top: 2.25rem;
  }
  .sm\:pr-9 {
    padding-right: 2.25rem;
  }
  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .sm\:pl-9 {
    padding-left: 2.25rem;
  }
  .sm\:pt-10 {
    padding-top: 2.5rem;
  }
  .sm\:pr-10 {
    padding-right: 2.5rem;
  }
  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .sm\:pl-10 {
    padding-left: 2.5rem;
  }
  .sm\:pt-11 {
    padding-top: 2.75rem;
  }
  .sm\:pr-11 {
    padding-right: 2.75rem;
  }
  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .sm\:pl-11 {
    padding-left: 2.75rem;
  }
  .sm\:pt-12 {
    padding-top: 3rem;
  }
  .sm\:pr-12 {
    padding-right: 3rem;
  }
  .sm\:pb-12 {
    padding-bottom: 3rem;
  }
  .sm\:pl-12 {
    padding-left: 3rem;
  }
  .sm\:pt-14 {
    padding-top: 3.5rem;
  }
  .sm\:pr-14 {
    padding-right: 3.5rem;
  }
  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .sm\:pl-14 {
    padding-left: 3.5rem;
  }
  .sm\:pt-16 {
    padding-top: 4rem;
  }
  .sm\:pr-16 {
    padding-right: 4rem;
  }
  .sm\:pb-16 {
    padding-bottom: 4rem;
  }
  .sm\:pl-16 {
    padding-left: 4rem;
  }
  .sm\:pt-20 {
    padding-top: 5rem;
  }
  .sm\:pr-20 {
    padding-right: 5rem;
  }
  .sm\:pb-20 {
    padding-bottom: 5rem;
  }
  .sm\:pl-20 {
    padding-left: 5rem;
  }
  .sm\:pt-24 {
    padding-top: 6rem;
  }
  .sm\:pr-24 {
    padding-right: 6rem;
  }
  .sm\:pb-24 {
    padding-bottom: 6rem;
  }
  .sm\:pl-24 {
    padding-left: 6rem;
  }
  .sm\:pt-28 {
    padding-top: 7rem;
  }
  .sm\:pr-28 {
    padding-right: 7rem;
  }
  .sm\:pb-28 {
    padding-bottom: 7rem;
  }
  .sm\:pl-28 {
    padding-left: 7rem;
  }
  .sm\:pt-32 {
    padding-top: 8rem;
  }
  .sm\:pr-32 {
    padding-right: 8rem;
  }
  .sm\:pb-32 {
    padding-bottom: 8rem;
  }
  .sm\:pl-32 {
    padding-left: 8rem;
  }
  .sm\:pt-36 {
    padding-top: 9rem;
  }
  .sm\:pr-36 {
    padding-right: 9rem;
  }
  .sm\:pb-36 {
    padding-bottom: 9rem;
  }
  .sm\:pl-36 {
    padding-left: 9rem;
  }
  .sm\:pt-40 {
    padding-top: 10rem;
  }
  .sm\:pr-40 {
    padding-right: 10rem;
  }
  .sm\:pb-40 {
    padding-bottom: 10rem;
  }
  .sm\:pl-40 {
    padding-left: 10rem;
  }
  .sm\:pt-44 {
    padding-top: 11rem;
  }
  .sm\:pr-44 {
    padding-right: 11rem;
  }
  .sm\:pb-44 {
    padding-bottom: 11rem;
  }
  .sm\:pl-44 {
    padding-left: 11rem;
  }
  .sm\:pt-48 {
    padding-top: 12rem;
  }
  .sm\:pr-48 {
    padding-right: 12rem;
  }
  .sm\:pb-48 {
    padding-bottom: 12rem;
  }
  .sm\:pl-48 {
    padding-left: 12rem;
  }
  .sm\:pt-52 {
    padding-top: 13rem;
  }
  .sm\:pr-52 {
    padding-right: 13rem;
  }
  .sm\:pb-52 {
    padding-bottom: 13rem;
  }
  .sm\:pl-52 {
    padding-left: 13rem;
  }
  .sm\:pt-56 {
    padding-top: 14rem;
  }
  .sm\:pr-56 {
    padding-right: 14rem;
  }
  .sm\:pb-56 {
    padding-bottom: 14rem;
  }
  .sm\:pl-56 {
    padding-left: 14rem;
  }
  .sm\:pt-60 {
    padding-top: 15rem;
  }
  .sm\:pr-60 {
    padding-right: 15rem;
  }
  .sm\:pb-60 {
    padding-bottom: 15rem;
  }
  .sm\:pl-60 {
    padding-left: 15rem;
  }
  .sm\:pt-64 {
    padding-top: 16rem;
  }
  .sm\:pr-64 {
    padding-right: 16rem;
  }
  .sm\:pb-64 {
    padding-bottom: 16rem;
  }
  .sm\:pl-64 {
    padding-left: 16rem;
  }
  .sm\:pt-72 {
    padding-top: 18rem;
  }
  .sm\:pr-72 {
    padding-right: 18rem;
  }
  .sm\:pb-72 {
    padding-bottom: 18rem;
  }
  .sm\:pl-72 {
    padding-left: 18rem;
  }
  .sm\:pt-80 {
    padding-top: 20rem;
  }
  .sm\:pr-80 {
    padding-right: 20rem;
  }
  .sm\:pb-80 {
    padding-bottom: 20rem;
  }
  .sm\:pl-80 {
    padding-left: 20rem;
  }
  .sm\:pt-96 {
    padding-top: 24rem;
  }
  .sm\:pr-96 {
    padding-right: 24rem;
  }
  .sm\:pb-96 {
    padding-bottom: 24rem;
  }
  .sm\:pl-96 {
    padding-left: 24rem;
  }
  .sm\:pt-px {
    padding-top: 1px;
  }
  .sm\:pr-px {
    padding-right: 1px;
  }
  .sm\:pb-px {
    padding-bottom: 1px;
  }
  .sm\:pl-px {
    padding-left: 1px;
  }
  .sm\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .sm\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .sm\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .sm\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .sm\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .sm\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .sm\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .sm\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .sm\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .sm\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .sm\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .sm\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .sm\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .sm\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .sm\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .sm\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .sm\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .sm\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .sm\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .sm\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .sm\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .sm\:placeholder-current::placeholder {
    color: currentColor;
  }
  .sm\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .sm\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .sm\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .sm\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .sm\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .sm\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .sm\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .sm\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .sm\:placeholder-background::placeholder {
    color: var(--background);
  }
  .sm\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .sm\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .sm\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .sm\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .sm\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .sm\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .sm\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .sm\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .sm\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .sm\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .sm\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .sm\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .sm\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .sm\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .sm\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .sm\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .sm\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .sm\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .sm\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .sm\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .sm\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .sm\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .sm\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .sm\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .sm\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .sm\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .sm\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .sm\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .sm\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .sm\:placeholder-success::placeholder {
    color: var(--success);
  }
  .sm\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .sm\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .sm\:placeholder-error::placeholder {
    color: var(--error);
  }
  .sm\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .sm\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .sm\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .sm\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .sm\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .sm\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .sm\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .sm\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .sm\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .sm\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .sm\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .sm\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .sm\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .sm\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .sm\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .sm\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .sm\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .sm\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .sm\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .sm\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .sm\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .sm\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .sm\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .sm\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .sm\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .sm\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .sm\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .sm\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .sm\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .sm\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .sm\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .sm\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .sm\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .sm\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .sm\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .sm\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .sm\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .sm\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .sm\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .sm\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .sm\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .sm\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .sm\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .sm\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .sm\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .sm\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .sm\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .sm\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .sm\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .sm\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .sm\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .sm\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .sm\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .sm\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .sm\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .sm\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .sm\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .sm\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .sm\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .sm\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .sm\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .sm\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:pointer-events-none {
    pointer-events: none;
  }
  .sm\:pointer-events-auto {
    pointer-events: auto;
  }
  .sm\:static {
    position: static;
  }
  .sm\:fixed {
    position: fixed;
  }
  .sm\:absolute {
    position: absolute;
  }
  .sm\:relative {
    position: relative;
  }
  .sm\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sm\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .sm\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .sm\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .sm\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .sm\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .sm\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .sm\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .sm\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .sm\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .sm\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .sm\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .sm\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .sm\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .sm\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .sm\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .sm\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .sm\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .sm\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .sm\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .sm\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .sm\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .sm\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .sm\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .sm\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .sm\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .sm\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .sm\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .sm\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .sm\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .sm\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .sm\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .sm\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .sm\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .sm\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .sm\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sm\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .sm\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .sm\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .sm\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .sm\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .sm\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .sm\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .sm\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .sm\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .sm\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .sm\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .sm\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .sm\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .sm\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .sm\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .sm\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .sm\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .sm\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .sm\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .sm\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .sm\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .sm\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .sm\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .sm\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .sm\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .sm\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .sm\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .sm\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .sm\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .sm\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .sm\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .sm\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .sm\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .sm\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .sm\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .sm\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .sm\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .sm\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .sm\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .sm\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .sm\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .sm\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .sm\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .sm\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .sm\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .sm\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .sm\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .sm\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .sm\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .sm\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .sm\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .sm\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .sm\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .sm\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .sm\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .sm\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .sm\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .sm\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .sm\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .sm\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .sm\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .sm\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .sm\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .sm\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .sm\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .sm\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .sm\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .sm\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .sm\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .sm\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .sm\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .sm\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .sm\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .sm\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .sm\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .sm\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .sm\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .sm\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .sm\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .sm\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .sm\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .sm\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .sm\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .sm\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .sm\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .sm\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .sm\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .sm\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .sm\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .sm\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .sm\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .sm\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .sm\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .sm\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .sm\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .sm\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .sm\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .sm\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .sm\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .sm\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .sm\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .sm\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .sm\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .sm\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .sm\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .sm\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .sm\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .sm\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .sm\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .sm\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .sm\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .sm\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .sm\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .sm\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .sm\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .sm\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .sm\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .sm\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .sm\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .sm\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .sm\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .sm\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .sm\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .sm\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .sm\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .sm\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .sm\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .sm\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .sm\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .sm\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .sm\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .sm\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .sm\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .sm\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .sm\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .sm\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .sm\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .sm\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .sm\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .sm\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .sm\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .sm\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .sm\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .sm\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .sm\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .sm\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .sm\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .sm\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .sm\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .sm\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .sm\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .sm\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .sm\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .sm\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .sm\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .sm\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .sm\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .sm\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .sm\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .sm\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .sm\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .sm\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .sm\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .sm\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .sm\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .sm\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .sm\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .sm\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .sm\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .sm\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .sm\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .sm\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .sm\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .sm\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .sm\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .sm\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .sm\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .sm\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .sm\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .sm\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .sm\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .sm\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .sm\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .sm\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .sm\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .sm\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .sm\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .sm\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .sm\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .sm\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .sm\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .sm\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .sm\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .sm\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .sm\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .sm\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .sm\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .sm\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .sm\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .sm\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .sm\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .sm\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .sm\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .sm\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .sm\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .sm\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .sm\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .sm\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .sm\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .sm\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .sm\:top-0 {
    top: 0;
  }
  .sm\:right-0 {
    right: 0;
  }
  .sm\:bottom-0 {
    bottom: 0;
  }
  .sm\:left-0 {
    left: 0;
  }
  .sm\:top-1 {
    top: 0.25rem;
  }
  .sm\:right-1 {
    right: 0.25rem;
  }
  .sm\:bottom-1 {
    bottom: 0.25rem;
  }
  .sm\:left-1 {
    left: 0.25rem;
  }
  .sm\:top-2 {
    top: 0.5rem;
  }
  .sm\:right-2 {
    right: 0.5rem;
  }
  .sm\:bottom-2 {
    bottom: 0.5rem;
  }
  .sm\:left-2 {
    left: 0.5rem;
  }
  .sm\:top-3 {
    top: 0.75rem;
  }
  .sm\:right-3 {
    right: 0.75rem;
  }
  .sm\:bottom-3 {
    bottom: 0.75rem;
  }
  .sm\:left-3 {
    left: 0.75rem;
  }
  .sm\:top-4 {
    top: 1rem;
  }
  .sm\:right-4 {
    right: 1rem;
  }
  .sm\:bottom-4 {
    bottom: 1rem;
  }
  .sm\:left-4 {
    left: 1rem;
  }
  .sm\:top-5 {
    top: 1.25rem;
  }
  .sm\:right-5 {
    right: 1.25rem;
  }
  .sm\:bottom-5 {
    bottom: 1.25rem;
  }
  .sm\:left-5 {
    left: 1.25rem;
  }
  .sm\:top-6 {
    top: 1.5rem;
  }
  .sm\:right-6 {
    right: 1.5rem;
  }
  .sm\:bottom-6 {
    bottom: 1.5rem;
  }
  .sm\:left-6 {
    left: 1.5rem;
  }
  .sm\:top-7 {
    top: 1.75rem;
  }
  .sm\:right-7 {
    right: 1.75rem;
  }
  .sm\:bottom-7 {
    bottom: 1.75rem;
  }
  .sm\:left-7 {
    left: 1.75rem;
  }
  .sm\:top-8 {
    top: 2rem;
  }
  .sm\:right-8 {
    right: 2rem;
  }
  .sm\:bottom-8 {
    bottom: 2rem;
  }
  .sm\:left-8 {
    left: 2rem;
  }
  .sm\:top-9 {
    top: 2.25rem;
  }
  .sm\:right-9 {
    right: 2.25rem;
  }
  .sm\:bottom-9 {
    bottom: 2.25rem;
  }
  .sm\:left-9 {
    left: 2.25rem;
  }
  .sm\:top-10 {
    top: 2.5rem;
  }
  .sm\:right-10 {
    right: 2.5rem;
  }
  .sm\:bottom-10 {
    bottom: 2.5rem;
  }
  .sm\:left-10 {
    left: 2.5rem;
  }
  .sm\:top-11 {
    top: 2.75rem;
  }
  .sm\:right-11 {
    right: 2.75rem;
  }
  .sm\:bottom-11 {
    bottom: 2.75rem;
  }
  .sm\:left-11 {
    left: 2.75rem;
  }
  .sm\:top-12 {
    top: 3rem;
  }
  .sm\:right-12 {
    right: 3rem;
  }
  .sm\:bottom-12 {
    bottom: 3rem;
  }
  .sm\:left-12 {
    left: 3rem;
  }
  .sm\:top-14 {
    top: 3.5rem;
  }
  .sm\:right-14 {
    right: 3.5rem;
  }
  .sm\:bottom-14 {
    bottom: 3.5rem;
  }
  .sm\:left-14 {
    left: 3.5rem;
  }
  .sm\:top-16 {
    top: 4rem;
  }
  .sm\:right-16 {
    right: 4rem;
  }
  .sm\:bottom-16 {
    bottom: 4rem;
  }
  .sm\:left-16 {
    left: 4rem;
  }
  .sm\:top-20 {
    top: 5rem;
  }
  .sm\:right-20 {
    right: 5rem;
  }
  .sm\:bottom-20 {
    bottom: 5rem;
  }
  .sm\:left-20 {
    left: 5rem;
  }
  .sm\:top-24 {
    top: 6rem;
  }
  .sm\:right-24 {
    right: 6rem;
  }
  .sm\:bottom-24 {
    bottom: 6rem;
  }
  .sm\:left-24 {
    left: 6rem;
  }
  .sm\:top-28 {
    top: 7rem;
  }
  .sm\:right-28 {
    right: 7rem;
  }
  .sm\:bottom-28 {
    bottom: 7rem;
  }
  .sm\:left-28 {
    left: 7rem;
  }
  .sm\:top-32 {
    top: 8rem;
  }
  .sm\:right-32 {
    right: 8rem;
  }
  .sm\:bottom-32 {
    bottom: 8rem;
  }
  .sm\:left-32 {
    left: 8rem;
  }
  .sm\:top-36 {
    top: 9rem;
  }
  .sm\:right-36 {
    right: 9rem;
  }
  .sm\:bottom-36 {
    bottom: 9rem;
  }
  .sm\:left-36 {
    left: 9rem;
  }
  .sm\:top-40 {
    top: 10rem;
  }
  .sm\:right-40 {
    right: 10rem;
  }
  .sm\:bottom-40 {
    bottom: 10rem;
  }
  .sm\:left-40 {
    left: 10rem;
  }
  .sm\:top-44 {
    top: 11rem;
  }
  .sm\:right-44 {
    right: 11rem;
  }
  .sm\:bottom-44 {
    bottom: 11rem;
  }
  .sm\:left-44 {
    left: 11rem;
  }
  .sm\:top-48 {
    top: 12rem;
  }
  .sm\:right-48 {
    right: 12rem;
  }
  .sm\:bottom-48 {
    bottom: 12rem;
  }
  .sm\:left-48 {
    left: 12rem;
  }
  .sm\:top-52 {
    top: 13rem;
  }
  .sm\:right-52 {
    right: 13rem;
  }
  .sm\:bottom-52 {
    bottom: 13rem;
  }
  .sm\:left-52 {
    left: 13rem;
  }
  .sm\:top-56 {
    top: 14rem;
  }
  .sm\:right-56 {
    right: 14rem;
  }
  .sm\:bottom-56 {
    bottom: 14rem;
  }
  .sm\:left-56 {
    left: 14rem;
  }
  .sm\:top-60 {
    top: 15rem;
  }
  .sm\:right-60 {
    right: 15rem;
  }
  .sm\:bottom-60 {
    bottom: 15rem;
  }
  .sm\:left-60 {
    left: 15rem;
  }
  .sm\:top-64 {
    top: 16rem;
  }
  .sm\:right-64 {
    right: 16rem;
  }
  .sm\:bottom-64 {
    bottom: 16rem;
  }
  .sm\:left-64 {
    left: 16rem;
  }
  .sm\:top-72 {
    top: 18rem;
  }
  .sm\:right-72 {
    right: 18rem;
  }
  .sm\:bottom-72 {
    bottom: 18rem;
  }
  .sm\:left-72 {
    left: 18rem;
  }
  .sm\:top-80 {
    top: 20rem;
  }
  .sm\:right-80 {
    right: 20rem;
  }
  .sm\:bottom-80 {
    bottom: 20rem;
  }
  .sm\:left-80 {
    left: 20rem;
  }
  .sm\:top-96 {
    top: 24rem;
  }
  .sm\:right-96 {
    right: 24rem;
  }
  .sm\:bottom-96 {
    bottom: 24rem;
  }
  .sm\:left-96 {
    left: 24rem;
  }
  .sm\:top-auto {
    top: auto;
  }
  .sm\:right-auto {
    right: auto;
  }
  .sm\:bottom-auto {
    bottom: auto;
  }
  .sm\:left-auto {
    left: auto;
  }
  .sm\:top-px {
    top: 1px;
  }
  .sm\:right-px {
    right: 1px;
  }
  .sm\:bottom-px {
    bottom: 1px;
  }
  .sm\:left-px {
    left: 1px;
  }
  .sm\:top-0\.5 {
    top: 0.125rem;
  }
  .sm\:right-0\.5 {
    right: 0.125rem;
  }
  .sm\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .sm\:left-0\.5 {
    left: 0.125rem;
  }
  .sm\:top-1\.5 {
    top: 0.375rem;
  }
  .sm\:right-1\.5 {
    right: 0.375rem;
  }
  .sm\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .sm\:left-1\.5 {
    left: 0.375rem;
  }
  .sm\:top-2\.5 {
    top: 0.625rem;
  }
  .sm\:right-2\.5 {
    right: 0.625rem;
  }
  .sm\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .sm\:left-2\.5 {
    left: 0.625rem;
  }
  .sm\:top-3\.5 {
    top: 0.875rem;
  }
  .sm\:right-3\.5 {
    right: 0.875rem;
  }
  .sm\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .sm\:left-3\.5 {
    left: 0.875rem;
  }
  .sm\:-top-0 {
    top: 0;
  }
  .sm\:-right-0 {
    right: 0;
  }
  .sm\:-bottom-0 {
    bottom: 0;
  }
  .sm\:-left-0 {
    left: 0;
  }
  .sm\:-top-1 {
    top: -0.25rem;
  }
  .sm\:-right-1 {
    right: -0.25rem;
  }
  .sm\:-bottom-1 {
    bottom: -0.25rem;
  }
  .sm\:-left-1 {
    left: -0.25rem;
  }
  .sm\:-top-2 {
    top: -0.5rem;
  }
  .sm\:-right-2 {
    right: -0.5rem;
  }
  .sm\:-bottom-2 {
    bottom: -0.5rem;
  }
  .sm\:-left-2 {
    left: -0.5rem;
  }
  .sm\:-top-3 {
    top: -0.75rem;
  }
  .sm\:-right-3 {
    right: -0.75rem;
  }
  .sm\:-bottom-3 {
    bottom: -0.75rem;
  }
  .sm\:-left-3 {
    left: -0.75rem;
  }
  .sm\:-top-4 {
    top: -1rem;
  }
  .sm\:-right-4 {
    right: -1rem;
  }
  .sm\:-bottom-4 {
    bottom: -1rem;
  }
  .sm\:-left-4 {
    left: -1rem;
  }
  .sm\:-top-5 {
    top: -1.25rem;
  }
  .sm\:-right-5 {
    right: -1.25rem;
  }
  .sm\:-bottom-5 {
    bottom: -1.25rem;
  }
  .sm\:-left-5 {
    left: -1.25rem;
  }
  .sm\:-top-6 {
    top: -1.5rem;
  }
  .sm\:-right-6 {
    right: -1.5rem;
  }
  .sm\:-bottom-6 {
    bottom: -1.5rem;
  }
  .sm\:-left-6 {
    left: -1.5rem;
  }
  .sm\:-top-7 {
    top: -1.75rem;
  }
  .sm\:-right-7 {
    right: -1.75rem;
  }
  .sm\:-bottom-7 {
    bottom: -1.75rem;
  }
  .sm\:-left-7 {
    left: -1.75rem;
  }
  .sm\:-top-8 {
    top: -2rem;
  }
  .sm\:-right-8 {
    right: -2rem;
  }
  .sm\:-bottom-8 {
    bottom: -2rem;
  }
  .sm\:-left-8 {
    left: -2rem;
  }
  .sm\:-top-9 {
    top: -2.25rem;
  }
  .sm\:-right-9 {
    right: -2.25rem;
  }
  .sm\:-bottom-9 {
    bottom: -2.25rem;
  }
  .sm\:-left-9 {
    left: -2.25rem;
  }
  .sm\:-top-10 {
    top: -2.5rem;
  }
  .sm\:-right-10 {
    right: -2.5rem;
  }
  .sm\:-bottom-10 {
    bottom: -2.5rem;
  }
  .sm\:-left-10 {
    left: -2.5rem;
  }
  .sm\:-top-11 {
    top: -2.75rem;
  }
  .sm\:-right-11 {
    right: -2.75rem;
  }
  .sm\:-bottom-11 {
    bottom: -2.75rem;
  }
  .sm\:-left-11 {
    left: -2.75rem;
  }
  .sm\:-top-12 {
    top: -3rem;
  }
  .sm\:-right-12 {
    right: -3rem;
  }
  .sm\:-bottom-12 {
    bottom: -3rem;
  }
  .sm\:-left-12 {
    left: -3rem;
  }
  .sm\:-top-14 {
    top: -3.5rem;
  }
  .sm\:-right-14 {
    right: -3.5rem;
  }
  .sm\:-bottom-14 {
    bottom: -3.5rem;
  }
  .sm\:-left-14 {
    left: -3.5rem;
  }
  .sm\:-top-16 {
    top: -4rem;
  }
  .sm\:-right-16 {
    right: -4rem;
  }
  .sm\:-bottom-16 {
    bottom: -4rem;
  }
  .sm\:-left-16 {
    left: -4rem;
  }
  .sm\:-top-20 {
    top: -5rem;
  }
  .sm\:-right-20 {
    right: -5rem;
  }
  .sm\:-bottom-20 {
    bottom: -5rem;
  }
  .sm\:-left-20 {
    left: -5rem;
  }
  .sm\:-top-24 {
    top: -6rem;
  }
  .sm\:-right-24 {
    right: -6rem;
  }
  .sm\:-bottom-24 {
    bottom: -6rem;
  }
  .sm\:-left-24 {
    left: -6rem;
  }
  .sm\:-top-28 {
    top: -7rem;
  }
  .sm\:-right-28 {
    right: -7rem;
  }
  .sm\:-bottom-28 {
    bottom: -7rem;
  }
  .sm\:-left-28 {
    left: -7rem;
  }
  .sm\:-top-32 {
    top: -8rem;
  }
  .sm\:-right-32 {
    right: -8rem;
  }
  .sm\:-bottom-32 {
    bottom: -8rem;
  }
  .sm\:-left-32 {
    left: -8rem;
  }
  .sm\:-top-36 {
    top: -9rem;
  }
  .sm\:-right-36 {
    right: -9rem;
  }
  .sm\:-bottom-36 {
    bottom: -9rem;
  }
  .sm\:-left-36 {
    left: -9rem;
  }
  .sm\:-top-40 {
    top: -10rem;
  }
  .sm\:-right-40 {
    right: -10rem;
  }
  .sm\:-bottom-40 {
    bottom: -10rem;
  }
  .sm\:-left-40 {
    left: -10rem;
  }
  .sm\:-top-44 {
    top: -11rem;
  }
  .sm\:-right-44 {
    right: -11rem;
  }
  .sm\:-bottom-44 {
    bottom: -11rem;
  }
  .sm\:-left-44 {
    left: -11rem;
  }
  .sm\:-top-48 {
    top: -12rem;
  }
  .sm\:-right-48 {
    right: -12rem;
  }
  .sm\:-bottom-48 {
    bottom: -12rem;
  }
  .sm\:-left-48 {
    left: -12rem;
  }
  .sm\:-top-52 {
    top: -13rem;
  }
  .sm\:-right-52 {
    right: -13rem;
  }
  .sm\:-bottom-52 {
    bottom: -13rem;
  }
  .sm\:-left-52 {
    left: -13rem;
  }
  .sm\:-top-56 {
    top: -14rem;
  }
  .sm\:-right-56 {
    right: -14rem;
  }
  .sm\:-bottom-56 {
    bottom: -14rem;
  }
  .sm\:-left-56 {
    left: -14rem;
  }
  .sm\:-top-60 {
    top: -15rem;
  }
  .sm\:-right-60 {
    right: -15rem;
  }
  .sm\:-bottom-60 {
    bottom: -15rem;
  }
  .sm\:-left-60 {
    left: -15rem;
  }
  .sm\:-top-64 {
    top: -16rem;
  }
  .sm\:-right-64 {
    right: -16rem;
  }
  .sm\:-bottom-64 {
    bottom: -16rem;
  }
  .sm\:-left-64 {
    left: -16rem;
  }
  .sm\:-top-72 {
    top: -18rem;
  }
  .sm\:-right-72 {
    right: -18rem;
  }
  .sm\:-bottom-72 {
    bottom: -18rem;
  }
  .sm\:-left-72 {
    left: -18rem;
  }
  .sm\:-top-80 {
    top: -20rem;
  }
  .sm\:-right-80 {
    right: -20rem;
  }
  .sm\:-bottom-80 {
    bottom: -20rem;
  }
  .sm\:-left-80 {
    left: -20rem;
  }
  .sm\:-top-96 {
    top: -24rem;
  }
  .sm\:-right-96 {
    right: -24rem;
  }
  .sm\:-bottom-96 {
    bottom: -24rem;
  }
  .sm\:-left-96 {
    left: -24rem;
  }
  .sm\:-top-px {
    top: -1px;
  }
  .sm\:-right-px {
    right: -1px;
  }
  .sm\:-bottom-px {
    bottom: -1px;
  }
  .sm\:-left-px {
    left: -1px;
  }
  .sm\:-top-0\.5 {
    top: -0.125rem;
  }
  .sm\:-right-0\.5 {
    right: -0.125rem;
  }
  .sm\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .sm\:-left-0\.5 {
    left: -0.125rem;
  }
  .sm\:-top-1\.5 {
    top: -0.375rem;
  }
  .sm\:-right-1\.5 {
    right: -0.375rem;
  }
  .sm\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .sm\:-left-1\.5 {
    left: -0.375rem;
  }
  .sm\:-top-2\.5 {
    top: -0.625rem;
  }
  .sm\:-right-2\.5 {
    right: -0.625rem;
  }
  .sm\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .sm\:-left-2\.5 {
    left: -0.625rem;
  }
  .sm\:-top-3\.5 {
    top: -0.875rem;
  }
  .sm\:-right-3\.5 {
    right: -0.875rem;
  }
  .sm\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .sm\:-left-3\.5 {
    left: -0.875rem;
  }
  .sm\:top-1\/2 {
    top: 50%;
  }
  .sm\:right-1\/2 {
    right: 50%;
  }
  .sm\:bottom-1\/2 {
    bottom: 50%;
  }
  .sm\:left-1\/2 {
    left: 50%;
  }
  .sm\:top-1\/3 {
    top: 33.333333%;
  }
  .sm\:right-1\/3 {
    right: 33.333333%;
  }
  .sm\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .sm\:left-1\/3 {
    left: 33.333333%;
  }
  .sm\:top-2\/3 {
    top: 66.666667%;
  }
  .sm\:right-2\/3 {
    right: 66.666667%;
  }
  .sm\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .sm\:left-2\/3 {
    left: 66.666667%;
  }
  .sm\:top-1\/4 {
    top: 25%;
  }
  .sm\:right-1\/4 {
    right: 25%;
  }
  .sm\:bottom-1\/4 {
    bottom: 25%;
  }
  .sm\:left-1\/4 {
    left: 25%;
  }
  .sm\:top-2\/4 {
    top: 50%;
  }
  .sm\:right-2\/4 {
    right: 50%;
  }
  .sm\:bottom-2\/4 {
    bottom: 50%;
  }
  .sm\:left-2\/4 {
    left: 50%;
  }
  .sm\:top-3\/4 {
    top: 75%;
  }
  .sm\:right-3\/4 {
    right: 75%;
  }
  .sm\:bottom-3\/4 {
    bottom: 75%;
  }
  .sm\:left-3\/4 {
    left: 75%;
  }
  .sm\:top-full {
    top: 100%;
  }
  .sm\:right-full {
    right: 100%;
  }
  .sm\:bottom-full {
    bottom: 100%;
  }
  .sm\:left-full {
    left: 100%;
  }
  .sm\:-top-1\/2 {
    top: -50%;
  }
  .sm\:-right-1\/2 {
    right: -50%;
  }
  .sm\:-bottom-1\/2 {
    bottom: -50%;
  }
  .sm\:-left-1\/2 {
    left: -50%;
  }
  .sm\:-top-1\/3 {
    top: -33.333333%;
  }
  .sm\:-right-1\/3 {
    right: -33.333333%;
  }
  .sm\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .sm\:-left-1\/3 {
    left: -33.333333%;
  }
  .sm\:-top-2\/3 {
    top: -66.666667%;
  }
  .sm\:-right-2\/3 {
    right: -66.666667%;
  }
  .sm\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .sm\:-left-2\/3 {
    left: -66.666667%;
  }
  .sm\:-top-1\/4 {
    top: -25%;
  }
  .sm\:-right-1\/4 {
    right: -25%;
  }
  .sm\:-bottom-1\/4 {
    bottom: -25%;
  }
  .sm\:-left-1\/4 {
    left: -25%;
  }
  .sm\:-top-2\/4 {
    top: -50%;
  }
  .sm\:-right-2\/4 {
    right: -50%;
  }
  .sm\:-bottom-2\/4 {
    bottom: -50%;
  }
  .sm\:-left-2\/4 {
    left: -50%;
  }
  .sm\:-top-3\/4 {
    top: -75%;
  }
  .sm\:-right-3\/4 {
    right: -75%;
  }
  .sm\:-bottom-3\/4 {
    bottom: -75%;
  }
  .sm\:-left-3\/4 {
    left: -75%;
  }
  .sm\:-top-full {
    top: -100%;
  }
  .sm\:-right-full {
    right: -100%;
  }
  .sm\:-bottom-full {
    bottom: -100%;
  }
  .sm\:-left-full {
    left: -100%;
  }
  .sm\:resize-none {
    resize: none;
  }
  .sm\:resize-y {
    resize: vertical;
  }
  .sm\:resize-x {
    resize: horizontal;
  }
  .sm\:resize {
    resize: both;
  }
  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .sm\:shadow,
  .sm\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sm\:shadow-lg,
  .sm\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .sm\:shadow-2xl,
  .sm\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:shadow-inner,
  .sm\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .sm\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .sm\:shadow-bottom-nav,
  .sm\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .sm\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .sm\:group-hover\:shadow-sm,
  .sm\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .sm\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .sm\:group-hover\:shadow,
  .group:hover .sm\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .sm\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .sm\:group-hover\:shadow-lg,
  .group:hover .sm\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .sm\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .sm\:group-hover\:shadow-2xl,
  .group:hover .sm\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .sm\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .sm\:group-hover\:shadow-none,
  .group:hover .sm\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .sm\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .sm\:group-hover\:shadow-bottom-nav,
  .group:hover .sm\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .sm\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .sm\:hover\:shadow-sm:hover,
  .sm\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sm\:hover\:shadow-lg:hover,
  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .sm\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .sm\:hover\:shadow-2xl:hover,
  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .sm\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:hover\:shadow-inner:hover,
  .sm\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .sm\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .sm\:hover\:shadow-bottom-nav:hover,
  .sm\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .sm\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .sm\:focus\:shadow-sm:focus,
  .sm\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .sm\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:focus\:shadow-md:focus,
  .sm\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sm\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .sm\:focus\:shadow-lg:focus,
  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .sm\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .sm\:focus\:shadow-2xl:focus,
  .sm\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .sm\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .sm\:focus\:shadow-none:focus,
  .sm\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .sm\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .sm\:focus\:shadow-bottom-nav:focus,
  .sm\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .sm\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring-0,
  .sm\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring-2,
  .sm\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring,
  .sm\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:ring-inset {
    --tw-ring-inset: inset;
  }
  .sm\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .sm\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-0:focus,
  .sm\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-2:focus,
  .sm\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-8:focus,
  .sm\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .sm\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .sm\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .sm\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .sm\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .sm\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .sm\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .sm\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .sm\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .sm\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .sm\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .sm\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .sm\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .sm\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .sm\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .sm\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .sm\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .sm\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .sm\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .sm\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .sm\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .sm\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .sm\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .sm\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .sm\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .sm\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .sm\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .sm\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .sm\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .sm\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .sm\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .sm\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .sm\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .sm\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .sm\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .sm\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .sm\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .sm\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .sm\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .sm\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .sm\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .sm\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .sm\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .sm\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .sm\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .sm\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .sm\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .sm\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .sm\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .sm\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .sm\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .sm\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .sm\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .sm\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .sm\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .sm\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .sm\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .sm\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .sm\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .sm\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .sm\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .sm\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .sm\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .sm\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .sm\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .sm\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .sm\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .sm\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .sm\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .sm\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .sm\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .sm\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .sm\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .sm\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .sm\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .sm\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .sm\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .sm\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .sm\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .sm\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .sm\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .sm\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .sm\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .sm\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .sm\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .sm\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .sm\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .sm\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .sm\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .sm\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .sm\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .sm\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .sm\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .sm\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .sm\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .sm\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .sm\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .sm\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .sm\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .sm\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .sm\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .sm\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .sm\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .sm\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .sm\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .sm\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .sm\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .sm\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .sm\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .sm\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .sm\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .sm\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .sm\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .sm\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .sm\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .sm\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .sm\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .sm\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .sm\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .sm\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .sm\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .sm\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .sm\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .sm\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .sm\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .sm\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .sm\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .sm\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .sm\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .sm\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .sm\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .sm\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .sm\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .sm\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .sm\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .sm\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .sm\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .sm\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .sm\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .sm\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .sm\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .sm\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .sm\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .sm\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .sm\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .sm\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .sm\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .sm\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .sm\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .sm\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .sm\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .sm\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .sm\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .sm\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .sm\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .sm\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .sm\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .sm\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .sm\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .sm\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .sm\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .sm\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .sm\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .sm\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .sm\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .sm\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .sm\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .sm\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .sm\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .sm\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .sm\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .sm\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .sm\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .sm\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .sm\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .sm\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .sm\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .sm\:ring-current {
    --tw-ring-color: currentColor;
  }
  .sm\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .sm\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .sm\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .sm\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .sm\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .sm\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .sm\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .sm\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .sm\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .sm\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .sm\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .sm\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .sm\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .sm\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .sm\:ring-background {
    --tw-ring-color: var(--background);
  }
  .sm\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .sm\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .sm\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .sm\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .sm\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .sm\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .sm\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .sm\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .sm\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .sm\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .sm\:ring-success {
    --tw-ring-color: var(--success);
  }
  .sm\:ring-error {
    --tw-ring-color: var(--error);
  }
  .sm\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .sm\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .sm\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .sm\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .sm\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .sm\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .sm\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .sm\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .sm\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .sm\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .sm\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .sm\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .sm\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .sm\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .sm\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .sm\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .sm\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .sm\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .sm\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .sm\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .sm\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .sm\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .sm\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .sm\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .sm\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .sm\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .sm\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .sm\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .sm\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .sm\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .sm\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .sm\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .sm\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .sm\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .sm\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .sm\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .sm\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .sm\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .sm\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .sm\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .sm\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .sm\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .sm\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .sm\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .sm\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .sm\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .sm\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .sm\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .sm\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .sm\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .sm\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .sm\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .sm\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .sm\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .sm\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .sm\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .sm\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .sm\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .sm\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .sm\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .sm\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .sm\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .sm\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .sm\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .sm\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .sm\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .sm\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .sm\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .sm\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .sm\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .sm\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .sm\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .sm\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .sm\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .sm\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .sm\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .sm\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .sm\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .sm\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .sm\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .sm\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .sm\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .sm\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .sm\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .sm\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .sm\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .sm\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .sm\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .sm\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .sm\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .sm\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .sm\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .sm\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .sm\:fill-current {
    fill: currentColor;
  }
  .sm\:stroke-current {
    stroke: currentColor;
  }
  .sm\:stroke-0 {
    stroke-width: 0;
  }
  .sm\:stroke-1 {
    stroke-width: 1;
  }
  .sm\:stroke-2 {
    stroke-width: 2;
  }
  .sm\:table-auto {
    table-layout: auto;
  }
  .sm\:table-fixed {
    table-layout: fixed;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:text-center {
    text-align: center;
  }
  .sm\:text-right {
    text-align: right;
  }
  .sm\:text-justify {
    text-align: justify;
  }
  .sm\:text-transparent {
    color: transparent;
  }
  .sm\:text-current {
    color: currentColor;
  }
  .sm\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .sm\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:text-background-primary {
    color: var(--background-primary);
  }
  .sm\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .sm\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .sm\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .sm\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .sm\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .sm\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .sm\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .sm\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .sm\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .sm\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .sm\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .sm\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .sm\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .sm\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .sm\:text-body-background {
    color: var(--body-background);
  }
  .sm\:text-background {
    color: var(--background);
  }
  .sm\:text-input-background {
    color: var(--input-background);
  }
  .sm\:text-body-text {
    color: var(--body-text);
  }
  .sm\:text-header-text {
    color: var(--header-text);
  }
  .sm\:text-primary {
    color: var(--primary);
  }
  .sm\:text-secondary {
    color: var(--secondary);
  }
  .sm\:text-tertiary {
    color: var(--tertiary);
  }
  .sm\:text-primary-hover {
    color: var(--primary-hover);
  }
  .sm\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .sm\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .sm\:text-input-text {
    color: var(--input-text);
  }
  .sm\:text-success {
    color: var(--success);
  }
  .sm\:text-error {
    color: var(--error);
  }
  .sm\:text-custom-loader {
    color: var(--custom-loader);
  }
  .sm\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .sm\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .sm\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .sm\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .sm\:text-background50 {
    color: var(--background50);
  }
  .sm\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .sm\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .sm\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .sm\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .sm\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .sm\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .sm\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .sm\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .sm\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .sm\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .sm\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .sm\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .sm\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .sm\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .sm\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .sm\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .sm\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .sm\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .sm\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .sm\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .sm\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .sm\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .sm\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .sm\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .sm\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .sm\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .sm\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .sm\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .sm\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .sm\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .sm\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .sm\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .sm\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .sm\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .sm\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .sm\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .sm\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .sm\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .sm\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .sm\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .sm\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .sm\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .sm\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .sm\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .sm\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .sm\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .sm\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .sm\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .sm\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .sm\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .sm\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .sm\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .sm\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .sm\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .sm\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .sm\:hover\:text-transparent:hover {
    color: transparent;
  }
  .sm\:hover\:text-current:hover {
    color: currentColor;
  }
  .sm\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .sm\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .sm\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .sm\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .sm\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .sm\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .sm\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .sm\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .sm\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .sm\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .sm\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .sm\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .sm\:hover\:text-background:hover {
    color: var(--background);
  }
  .sm\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .sm\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .sm\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .sm\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .sm\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .sm\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .sm\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .sm\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .sm\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .sm\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .sm\:hover\:text-success:hover {
    color: var(--success);
  }
  .sm\:hover\:text-error:hover {
    color: var(--error);
  }
  .sm\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .sm\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .sm\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .sm\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .sm\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .sm\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .sm\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .sm\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .sm\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .sm\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .sm\:focus\:text-transparent:focus {
    color: transparent;
  }
  .sm\:focus\:text-current:focus {
    color: currentColor;
  }
  .sm\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .sm\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .sm\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .sm\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .sm\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .sm\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .sm\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .sm\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .sm\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .sm\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .sm\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .sm\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .sm\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .sm\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .sm\:focus\:text-background:focus {
    color: var(--background);
  }
  .sm\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .sm\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .sm\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .sm\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .sm\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .sm\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .sm\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .sm\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .sm\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .sm\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .sm\:focus\:text-success:focus {
    color: var(--success);
  }
  .sm\:focus\:text-error:focus {
    color: var(--error);
  }
  .sm\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .sm\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .sm\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .sm\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .sm\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .sm\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .sm\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .sm\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .sm\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .sm\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .sm\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .sm\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .sm\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .sm\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .sm\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .sm\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .sm\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .sm\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .sm\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .sm\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .sm\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .sm\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .sm\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .sm\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .sm\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .sm\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .sm\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .sm\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .sm\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .sm\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .sm\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .sm\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .sm\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .sm\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .sm\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .sm\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .sm\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .sm\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .sm\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .sm\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .sm\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .sm\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .sm\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .sm\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .sm\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .sm\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .sm\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .sm\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .sm\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .sm\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .sm\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .sm\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .sm\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .sm\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .sm\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .sm\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .sm\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .sm\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .sm\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .sm\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .sm\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .sm\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .sm\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .sm\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .sm\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .sm\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .sm\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .sm\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .sm\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .sm\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .sm\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .sm\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .sm\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .sm\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .sm\:overflow-ellipsis,
  .sm\:truncate {
    text-overflow: ellipsis;
  }
  .sm\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .sm\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .sm\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .sm\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .sm\:italic {
    font-style: italic;
  }
  .sm\:not-italic {
    font-style: normal;
  }
  .sm\:uppercase {
    text-transform: uppercase;
  }
  .sm\:lowercase {
    text-transform: lowercase;
  }
  .sm\:capitalize {
    text-transform: capitalize;
  }
  .sm\:normal-case {
    text-transform: none;
  }
  .sm\:underline {
    text-decoration: underline;
  }
  .sm\:line-through {
    text-decoration: line-through;
  }
  .sm\:no-underline {
    text-decoration: none;
  }
  .group:hover .sm\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .sm\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .sm\:group-hover\:no-underline {
    text-decoration: none;
  }
  .sm\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .sm\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .sm\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .sm\:hover\:underline:hover {
    text-decoration: underline;
  }
  .sm\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .sm\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .sm\:focus\:underline:focus {
    text-decoration: underline;
  }
  .sm\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .sm\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .sm\:diagonal-fractions,
  .sm\:lining-nums,
  .sm\:oldstyle-nums,
  .sm\:ordinal,
  .sm\:proportional-nums,
  .sm\:slashed-zero,
  .sm\:stacked-fractions,
  .sm\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .sm\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .sm\:ordinal {
    --tw-ordinal: ordinal;
  }
  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .sm\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .sm\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .sm\:tracking-normal {
    letter-spacing: 0;
  }
  .sm\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .sm\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .sm\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .sm\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .sm\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .sm\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .sm\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .sm\:align-baseline {
    vertical-align: baseline;
  }
  .sm\:align-top {
    vertical-align: top;
  }
  .sm\:align-middle {
    vertical-align: middle;
  }
  .sm\:align-bottom {
    vertical-align: bottom;
  }
  .sm\:align-text-top {
    vertical-align: text-top;
  }
  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .sm\:visible {
    visibility: visible;
  }
  .sm\:invisible {
    visibility: hidden;
  }
  .sm\:whitespace-normal {
    white-space: normal;
  }
  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }
  .sm\:whitespace-pre {
    white-space: pre;
  }
  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }
  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .sm\:break-words {
    overflow-wrap: break-word;
  }
  .sm\:break-all {
    word-break: break-all;
  }
  .group:hover .sm\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .sm\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .sm\:group-hover\:break-all {
    word-break: break-all;
  }
  .sm\:w-0 {
    width: 0;
  }
  .sm\:w-1 {
    width: 0.25rem;
  }
  .sm\:w-2 {
    width: 0.5rem;
  }
  .sm\:w-3 {
    width: 0.75rem;
  }
  .sm\:w-4 {
    width: 1rem;
  }
  .sm\:w-5 {
    width: 1.25rem;
  }
  .sm\:w-6 {
    width: 1.5rem;
  }
  .sm\:w-7 {
    width: 1.75rem;
  }
  .sm\:w-8 {
    width: 2rem;
  }
  .sm\:w-9 {
    width: 2.25rem;
  }
  .sm\:w-10 {
    width: 2.5rem;
  }
  .sm\:w-11 {
    width: 2.75rem;
  }
  .sm\:w-12 {
    width: 3rem;
  }
  .sm\:w-14 {
    width: 3.5rem;
  }
  .sm\:w-16 {
    width: 4rem;
  }
  .sm\:w-20 {
    width: 5rem;
  }
  .sm\:w-24 {
    width: 6rem;
  }
  .sm\:w-28 {
    width: 7rem;
  }
  .sm\:w-32 {
    width: 8rem;
  }
  .sm\:w-36 {
    width: 9rem;
  }
  .sm\:w-40 {
    width: 10rem;
  }
  .sm\:w-44 {
    width: 11rem;
  }
  .sm\:w-48 {
    width: 12rem;
  }
  .sm\:w-52 {
    width: 13rem;
  }
  .sm\:w-56 {
    width: 14rem;
  }
  .sm\:w-60 {
    width: 15rem;
  }
  .sm\:w-64 {
    width: 16rem;
  }
  .sm\:w-72 {
    width: 18rem;
  }
  .sm\:w-80 {
    width: 20rem;
  }
  .sm\:w-96 {
    width: 24rem;
  }
  .sm\:w-150 {
    width: 150px;
  }
  .sm\:w-199 {
    width: 199px;
  }
  .sm\:w-286 {
    width: 286px;
  }
  .sm\:w-512 {
    width: 512px;
  }
  .sm\:w-560 {
    width: 560px;
  }
  .sm\:w-650 {
    width: 650px;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-px {
    width: 1px;
  }
  .sm\:w-0\.5 {
    width: 0.125rem;
  }
  .sm\:w-1\.5 {
    width: 0.375rem;
  }
  .sm\:w-2\.5 {
    width: 0.625rem;
  }
  .sm\:w-3\.5 {
    width: 0.875rem;
  }
  .sm\:w-1\/2 {
    width: 50%;
  }
  .sm\:w-1\/3 {
    width: 33.333333%;
  }
  .sm\:w-2\/3 {
    width: 66.666667%;
  }
  .sm\:w-1\/4 {
    width: 25%;
  }
  .sm\:w-2\/4 {
    width: 50%;
  }
  .sm\:w-3\/4 {
    width: 75%;
  }
  .sm\:w-1\/5 {
    width: 20%;
  }
  .sm\:w-2\/5 {
    width: 40%;
  }
  .sm\:w-3\/5 {
    width: 60%;
  }
  .sm\:w-4\/5 {
    width: 80%;
  }
  .sm\:w-1\/6 {
    width: 16.666667%;
  }
  .sm\:w-2\/6 {
    width: 33.333333%;
  }
  .sm\:w-3\/6 {
    width: 50%;
  }
  .sm\:w-4\/6 {
    width: 66.666667%;
  }
  .sm\:w-5\/6 {
    width: 83.333333%;
  }
  .sm\:w-1\/12 {
    width: 8.333333%;
  }
  .sm\:w-2\/12 {
    width: 16.666667%;
  }
  .sm\:w-3\/12 {
    width: 25%;
  }
  .sm\:w-4\/12 {
    width: 33.333333%;
  }
  .sm\:w-5\/12 {
    width: 41.666667%;
  }
  .sm\:w-6\/12 {
    width: 50%;
  }
  .sm\:w-7\/12 {
    width: 58.333333%;
  }
  .sm\:w-8\/12 {
    width: 66.666667%;
  }
  .sm\:w-9\/12 {
    width: 75%;
  }
  .sm\:w-10\/12 {
    width: 83.333333%;
  }
  .sm\:w-11\/12 {
    width: 91.666667%;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:w-screen {
    width: 100vw;
  }
  .sm\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .sm\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .sm\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .sm\:z-0 {
    z-index: 0;
  }
  .sm\:z-10 {
    z-index: 10;
  }
  .sm\:z-20 {
    z-index: 20;
  }
  .sm\:z-30 {
    z-index: 30;
  }
  .sm\:z-40 {
    z-index: 40;
  }
  .sm\:z-50 {
    z-index: 50;
  }
  .sm\:z-auto {
    z-index: auto;
  }
  .sm\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .sm\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .sm\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .sm\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .sm\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .sm\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .sm\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .sm\:focus\:z-0:focus {
    z-index: 0;
  }
  .sm\:focus\:z-10:focus {
    z-index: 10;
  }
  .sm\:focus\:z-20:focus {
    z-index: 20;
  }
  .sm\:focus\:z-30:focus {
    z-index: 30;
  }
  .sm\:focus\:z-40:focus {
    z-index: 40;
  }
  .sm\:focus\:z-50:focus {
    z-index: 50;
  }
  .sm\:focus\:z-auto:focus {
    z-index: auto;
  }
  .sm\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .sm\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .sm\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .sm\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .sm\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .sm\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .sm\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .sm\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .sm\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .sm\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .sm\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .sm\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .sm\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .sm\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .sm\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .sm\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .sm\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .sm\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .sm\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .sm\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .sm\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .sm\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .sm\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .sm\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .sm\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .sm\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .sm\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .sm\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .sm\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .sm\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .sm\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .sm\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .sm\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .sm\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .sm\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .sm\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .sm\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .sm\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .sm\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .sm\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .sm\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .sm\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .sm\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .sm\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .sm\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .sm\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .sm\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .sm\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .sm\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .sm\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .sm\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .sm\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .sm\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .sm\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .sm\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .sm\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .sm\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .sm\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .sm\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .sm\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .sm\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .sm\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .sm\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .sm\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .sm\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .sm\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .sm\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .sm\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .sm\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .sm\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .sm\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .sm\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .sm\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .sm\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .sm\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .sm\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .sm\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .sm\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .sm\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .sm\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .sm\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .sm\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .sm\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .sm\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .sm\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .sm\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .sm\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .sm\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .sm\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .sm\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .sm\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .sm\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .sm\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .sm\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .sm\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .sm\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .sm\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .sm\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .sm\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .sm\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .sm\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }
  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }
  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm\:grid-cols-none {
    grid-template-columns: none;
  }
  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .sm\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .sm\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .sm\:col-auto {
    grid-column: auto;
  }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:col-start-1 {
    grid-column-start: 1;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:col-start-3 {
    grid-column-start: 3;
  }
  .sm\:col-start-4 {
    grid-column-start: 4;
  }
  .sm\:col-start-5 {
    grid-column-start: 5;
  }
  .sm\:col-start-6 {
    grid-column-start: 6;
  }
  .sm\:col-start-7 {
    grid-column-start: 7;
  }
  .sm\:col-start-8 {
    grid-column-start: 8;
  }
  .sm\:col-start-9 {
    grid-column-start: 9;
  }
  .sm\:col-start-10 {
    grid-column-start: 10;
  }
  .sm\:col-start-11 {
    grid-column-start: 11;
  }
  .sm\:col-start-12 {
    grid-column-start: 12;
  }
  .sm\:col-start-13 {
    grid-column-start: 13;
  }
  .sm\:col-start-auto {
    grid-column-start: auto;
  }
  .sm\:col-end-1 {
    grid-column-end: 1;
  }
  .sm\:col-end-2 {
    grid-column-end: 2;
  }
  .sm\:col-end-3 {
    grid-column-end: 3;
  }
  .sm\:col-end-4 {
    grid-column-end: 4;
  }
  .sm\:col-end-5 {
    grid-column-end: 5;
  }
  .sm\:col-end-6 {
    grid-column-end: 6;
  }
  .sm\:col-end-7 {
    grid-column-end: 7;
  }
  .sm\:col-end-8 {
    grid-column-end: 8;
  }
  .sm\:col-end-9 {
    grid-column-end: 9;
  }
  .sm\:col-end-10 {
    grid-column-end: 10;
  }
  .sm\:col-end-11 {
    grid-column-end: 11;
  }
  .sm\:col-end-12 {
    grid-column-end: 12;
  }
  .sm\:col-end-13 {
    grid-column-end: 13;
  }
  .sm\:col-end-auto {
    grid-column-end: auto;
  }
  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-rows-none {
    grid-template-rows: none;
  }
  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .sm\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .sm\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .sm\:row-auto {
    grid-row: auto;
  }
  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .sm\:row-span-full {
    grid-row: 1/-1;
  }
  .sm\:row-start-1 {
    grid-row-start: 1;
  }
  .sm\:row-start-2 {
    grid-row-start: 2;
  }
  .sm\:row-start-3 {
    grid-row-start: 3;
  }
  .sm\:row-start-4 {
    grid-row-start: 4;
  }
  .sm\:row-start-5 {
    grid-row-start: 5;
  }
  .sm\:row-start-6 {
    grid-row-start: 6;
  }
  .sm\:row-start-7 {
    grid-row-start: 7;
  }
  .sm\:row-start-auto {
    grid-row-start: auto;
  }
  .sm\:row-end-1 {
    grid-row-end: 1;
  }
  .sm\:row-end-2 {
    grid-row-end: 2;
  }
  .sm\:row-end-3 {
    grid-row-end: 3;
  }
  .sm\:row-end-4 {
    grid-row-end: 4;
  }
  .sm\:row-end-5 {
    grid-row-end: 5;
  }
  .sm\:row-end-6 {
    grid-row-end: 6;
  }
  .sm\:row-end-7 {
    grid-row-end: 7;
  }
  .sm\:row-end-auto {
    grid-row-end: auto;
  }
  .sm\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .sm\:transform,
  .sm\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .sm\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .sm\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .sm\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .sm\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .sm\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .sm\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .sm\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .sm\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .sm\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .sm\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .sm\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .sm\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .sm\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .sm\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .sm\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .sm\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .sm\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .sm\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .sm\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .sm\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .sm\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .sm\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .sm\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .sm\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .sm\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .sm\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .sm\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .sm\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .sm\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .sm\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .sm\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .sm\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .sm\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .sm\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .sm\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .sm\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .sm\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .sm\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .sm\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .sm\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .sm\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .sm\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .sm\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .sm\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .sm\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .sm\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .sm\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .sm\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .sm\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .sm\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .sm\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .sm\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .sm\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .sm\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .sm\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .sm\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .sm\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .sm\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .sm\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .sm\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .sm\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .sm\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .sm\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .sm\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .sm\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .sm\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .sm\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .sm\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .sm\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .sm\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .sm\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .sm\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .sm\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .sm\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .sm\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .sm\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .sm\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .sm\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .sm\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .sm\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .sm\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .sm\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .sm\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .sm\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .sm\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .sm\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .sm\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .sm\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .sm\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .sm\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .sm\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .sm\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .sm\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .sm\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .sm\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .sm\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .sm\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .sm\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .sm\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .sm\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .sm\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .sm\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .sm\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .sm\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .sm\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .sm\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .sm\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .sm\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .sm\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .sm\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .sm\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .sm\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .sm\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .sm\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .sm\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .sm\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .sm\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .sm\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .sm\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .sm\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .sm\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .sm\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .sm\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .sm\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .sm\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .sm\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .sm\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .sm\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .sm\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .sm\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .sm\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .sm\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .sm\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .sm\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .sm\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .sm\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .sm\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .sm\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .sm\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .sm\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .sm\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .sm\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .sm\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .sm\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .sm\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .sm\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .sm\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .sm\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .sm\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .sm\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .sm\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .sm\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .sm\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .sm\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .sm\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .sm\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .sm\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .sm\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .sm\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .sm\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .sm\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .sm\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .sm\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .sm\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .sm\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .sm\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .sm\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .sm\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .sm\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .sm\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .sm\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .sm\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .sm\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .sm\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .sm\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .sm\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .sm\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .sm\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .sm\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .sm\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .sm\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .sm\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .sm\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .sm\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .sm\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .sm\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .sm\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .sm\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .sm\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .sm\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .sm\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .sm\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .sm\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .sm\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .sm\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .sm\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .sm\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .sm\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .sm\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .sm\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .sm\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .sm\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .sm\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .sm\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .sm\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .sm\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .sm\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .sm\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .sm\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .sm\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .sm\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .sm\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .sm\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .sm\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .sm\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .sm\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .sm\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .sm\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .sm\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .sm\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .sm\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .sm\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .sm\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .sm\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .sm\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .sm\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .sm\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .sm\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .sm\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .sm\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .sm\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .sm\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .sm\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .sm\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .sm\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .sm\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .sm\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .sm\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .sm\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .sm\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .sm\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .sm\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .sm\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .sm\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .sm\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .sm\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .sm\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .sm\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .sm\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .sm\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .sm\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .sm\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .sm\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .sm\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .sm\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .sm\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .sm\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .sm\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .sm\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .sm\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .sm\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .sm\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .sm\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .sm\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .sm\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .sm\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .sm\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .sm\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .sm\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .sm\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .sm\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .sm\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .sm\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .sm\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .sm\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .sm\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .sm\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .sm\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .sm\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .sm\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .sm\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .sm\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .sm\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .sm\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .sm\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .sm\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .sm\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .sm\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .sm\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .sm\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .sm\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .sm\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .sm\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .sm\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .sm\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .sm\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .sm\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .sm\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .sm\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .sm\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .sm\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .sm\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .sm\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .sm\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .sm\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .sm\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .sm\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .sm\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .sm\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .sm\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .sm\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .sm\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .sm\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .sm\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .sm\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .sm\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .sm\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .sm\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .sm\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .sm\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .sm\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .sm\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .sm\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .sm\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .sm\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .sm\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .sm\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .sm\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .sm\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .sm\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .sm\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .sm\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .sm\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .sm\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .sm\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .sm\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .sm\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .sm\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .sm\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .sm\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .sm\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .sm\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .sm\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .sm\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .sm\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .sm\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .sm\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .sm\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .sm\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .sm\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .sm\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .sm\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .sm\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .sm\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .sm\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .sm\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .sm\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .sm\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .sm\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .sm\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .sm\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .sm\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .sm\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .sm\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .sm\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .sm\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .sm\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .sm\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .sm\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .sm\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .sm\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .sm\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .sm\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .sm\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .sm\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .sm\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .sm\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .sm\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .sm\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .sm\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .sm\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .sm\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .sm\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .sm\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .sm\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .sm\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .sm\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .sm\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .sm\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .sm\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .sm\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .sm\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .sm\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .sm\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .sm\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .sm\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .sm\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .sm\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .sm\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .sm\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .sm\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .sm\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .sm\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .sm\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .sm\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .sm\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .sm\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .sm\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .sm\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .sm\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .sm\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .sm\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .sm\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .sm\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .sm\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .sm\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .sm\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .sm\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .sm\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .sm\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .sm\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .sm\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .sm\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .sm\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .sm\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .sm\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .sm\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .sm\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .sm\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .sm\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .sm\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .sm\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .sm\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .sm\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .sm\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .sm\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .sm\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .sm\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .sm\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .sm\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .sm\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .sm\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .sm\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .sm\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .sm\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .sm\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .sm\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .sm\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .sm\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .sm\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .sm\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .sm\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .sm\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .sm\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .sm\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .sm\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .sm\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .sm\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .sm\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .sm\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .sm\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .sm\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .sm\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .sm\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .sm\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .sm\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .sm\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .sm\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .sm\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .sm\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .sm\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .sm\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .sm\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .sm\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .sm\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .sm\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .sm\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .sm\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .sm\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .sm\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .sm\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .sm\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .sm\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .sm\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .sm\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .sm\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .sm\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .sm\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .sm\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .sm\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .sm\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .sm\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .sm\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .sm\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .sm\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .sm\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .sm\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .sm\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .sm\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .sm\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .sm\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .sm\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .sm\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .sm\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .sm\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .sm\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .sm\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .sm\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .sm\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .sm\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .sm\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .sm\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .sm\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .sm\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .sm\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .sm\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .sm\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .sm\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .sm\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .sm\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .sm\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .sm\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .sm\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .sm\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .sm\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .sm\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .sm\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .sm\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .sm\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .sm\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .sm\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .sm\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .sm\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .sm\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .sm\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .sm\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .sm\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .sm\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .sm\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .sm\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .sm\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .sm\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .sm\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .sm\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .sm\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .sm\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .sm\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .sm\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .sm\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .sm\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .sm\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .sm\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .sm\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .sm\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .sm\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .sm\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .sm\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .sm\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .sm\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .sm\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .sm\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .sm\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .sm\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .sm\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .sm\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .sm\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .sm\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .sm\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .sm\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .sm\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .sm\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .sm\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .sm\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .sm\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .sm\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .sm\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .sm\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .sm\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .sm\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .sm\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .sm\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .sm\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .sm\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .sm\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .sm\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .sm\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .sm\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .sm\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .sm\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .sm\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .sm\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .sm\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .sm\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .sm\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .sm\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .sm\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .sm\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .sm\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .sm\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .sm\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .sm\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .sm\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .sm\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .sm\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .sm\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .sm\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .sm\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .sm\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .sm\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .sm\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .sm\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .sm\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .sm\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .sm\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .sm\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .sm\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .sm\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .sm\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .sm\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .sm\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .sm\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .sm\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .sm\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .sm\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .sm\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .sm\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .sm\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .sm\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .sm\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .sm\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .sm\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .sm\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .sm\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .sm\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .sm\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .sm\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .sm\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .sm\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .sm\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .sm\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .sm\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .sm\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .sm\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .sm\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .sm\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .sm\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .sm\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .sm\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .sm\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .sm\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .sm\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .sm\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .sm\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .sm\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .sm\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .sm\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .sm\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .sm\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .sm\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .sm\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .sm\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .sm\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .sm\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .sm\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .sm\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .sm\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .sm\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .sm\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .sm\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .sm\:transition-none {
    transition-property: none;
  }
  .sm\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .sm\:ease-linear {
    transition-timing-function: linear;
  }
  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .sm\:duration-75 {
    transition-duration: 75ms;
  }
  .sm\:duration-100 {
    transition-duration: 0.1s;
  }
  .sm\:duration-150 {
    transition-duration: 0.15s;
  }
  .sm\:duration-200 {
    transition-duration: 0.2s;
  }
  .sm\:duration-300 {
    transition-duration: 0.3s;
  }
  .sm\:duration-500 {
    transition-duration: 0.5s;
  }
  .sm\:duration-700 {
    transition-duration: 0.7s;
  }
  .sm\:duration-1000 {
    transition-duration: 1s;
  }
  .sm\:delay-75 {
    transition-delay: 75ms;
  }
  .sm\:delay-100 {
    transition-delay: 0.1s;
  }
  .sm\:delay-150 {
    transition-delay: 0.15s;
  }
  .sm\:delay-200 {
    transition-delay: 0.2s;
  }
  .sm\:delay-300 {
    transition-delay: 0.3s;
  }
  .sm\:delay-500 {
    transition-delay: 0.5s;
  }
  .sm\:delay-700 {
    transition-delay: 0.7s;
  }
  .sm\:delay-1000 {
    transition-delay: 1s;
  }
  .sm\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .sm\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .sm\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .sm\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .sm\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .md\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .md\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .md\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }
  .md\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .md\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .md\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .md\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .md\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .md\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .md\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .md\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .md\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .md\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .md\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .md\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .md\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .md\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .md\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .md\:prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .md\:prose h3,
  .md\:prose h4 {
    color: #6d728b;
    font-weight: 600;
  }
  .md\:prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .md\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .md\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .md\:prose code:after,
  .md\:prose code:before {
    content: '`';
  }
  .md\:prose a code {
    color: #6d728b;
  }
  .md\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .md\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .md\:prose pre code:after,
  .md\:prose pre code:before {
    content: '';
  }
  .md\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .md\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .md\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .md\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .md\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .md\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .md\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .md\:prose figure,
  .md\:prose img,
  .md\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .md\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:prose h2 code {
    font-size: 0.875em;
  }
  .md\:prose h3 code {
    font-size: 0.9em;
  }
  .md\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .md\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .md\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .md\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .md\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .md\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .md\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .md\:prose ol ol,
  .md\:prose ol ul,
  .md\:prose ul ol,
  .md\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .md\:prose h2 + *,
  .md\:prose h3 + *,
  .md\:prose h4 + *,
  .md\:prose hr + * {
    margin-top: 0;
  }
  .md\:prose thead th:first-child {
    padding-left: 0;
  }
  .md\:prose thead th:last-child {
    padding-right: 0;
  }
  .md\:prose tbody td:first-child {
    padding-left: 0;
  }
  .md\:prose tbody td:last-child {
    padding-right: 0;
  }
  .md\:prose > :first-child {
    margin-top: 0;
  }
  .md\:prose > :last-child {
    margin-bottom: 0;
  }
  .md\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .md\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .md\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .md\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .md\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .md\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .md\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .md\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .md\:prose-sm figure,
  .md\:prose-sm img,
  .md\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .md\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .md\:prose-sm code {
    font-size: 0.8571429em;
  }
  .md\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .md\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .md\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .md\:prose-sm ol,
  .md\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .md\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .md\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .md\:prose-sm ol > li:before {
    left: 0;
  }
  .md\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .md\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .md\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .md\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .md\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .md\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .md\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .md\:prose-sm ol ol,
  .md\:prose-sm ol ul,
  .md\:prose-sm ul ol,
  .md\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .md\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .md\:prose-sm h2 + *,
  .md\:prose-sm h3 + *,
  .md\:prose-sm h4 + *,
  .md\:prose-sm hr + * {
    margin-top: 0;
  }
  .md\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .md\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .md\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .md\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .md\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .md\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .md\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .md\:prose-sm > :first-child {
    margin-top: 0;
  }
  .md\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .md\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .md\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .md\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .md\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .md\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .md\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .md\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .md\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .md\:prose-lg figure,
  .md\:prose-lg img,
  .md\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .md\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .md\:prose-lg code {
    font-size: 0.8888889em;
  }
  .md\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .md\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .md\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .md\:prose-lg ol,
  .md\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .md\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .md\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .md\:prose-lg ol > li:before {
    left: 0;
  }
  .md\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .md\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .md\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .md\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .md\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .md\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .md\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .md\:prose-lg ol ol,
  .md\:prose-lg ol ul,
  .md\:prose-lg ul ol,
  .md\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .md\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .md\:prose-lg h2 + *,
  .md\:prose-lg h3 + *,
  .md\:prose-lg h4 + *,
  .md\:prose-lg hr + * {
    margin-top: 0;
  }
  .md\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .md\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .md\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .md\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .md\:prose-lg tbody td {
    padding: 0.75em;
  }
  .md\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .md\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .md\:prose-lg > :first-child {
    margin-top: 0;
  }
  .md\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .md\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .md\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .md\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .md\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .md\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .md\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .md\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .md\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .md\:prose-xl figure,
  .md\:prose-xl img,
  .md\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .md\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .md\:prose-xl code {
    font-size: 0.9em;
  }
  .md\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .md\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .md\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .md\:prose-xl ol,
  .md\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .md\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .md\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .md\:prose-xl ol > li:before {
    left: 0;
  }
  .md\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .md\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .md\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .md\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .md\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .md\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .md\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .md\:prose-xl ol ol,
  .md\:prose-xl ol ul,
  .md\:prose-xl ul ol,
  .md\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .md\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .md\:prose-xl h2 + *,
  .md\:prose-xl h3 + *,
  .md\:prose-xl h4 + *,
  .md\:prose-xl hr + * {
    margin-top: 0;
  }
  .md\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .md\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .md\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .md\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .md\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .md\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .md\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .md\:prose-xl > :first-child {
    margin-top: 0;
  }
  .md\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .md\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .md\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .md\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .md\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .md\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .md\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .md\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .md\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .md\:prose-2xl figure,
  .md\:prose-2xl img,
  .md\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .md\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .md\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .md\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .md\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .md\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .md\:prose-2xl ol,
  .md\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .md\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .md\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .md\:prose-2xl ol > li:before {
    left: 0;
  }
  .md\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .md\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .md\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .md\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .md\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .md\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .md\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .md\:prose-2xl ol ol,
  .md\:prose-2xl ol ul,
  .md\:prose-2xl ul ol,
  .md\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .md\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .md\:prose-2xl h2 + *,
  .md\:prose-2xl h3 + *,
  .md\:prose-2xl h4 + *,
  .md\:prose-2xl hr + * {
    margin-top: 0;
  }
  .md\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .md\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .md\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .md\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .md\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .md\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .md\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .md\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .md\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .md\:aspect-w-1,
  .md\:aspect-w-2,
  .md\:aspect-w-3,
  .md\:aspect-w-4,
  .md\:aspect-w-5,
  .md\:aspect-w-6,
  .md\:aspect-w-7,
  .md\:aspect-w-8,
  .md\:aspect-w-9,
  .md\:aspect-w-10,
  .md\:aspect-w-11,
  .md\:aspect-w-12,
  .md\:aspect-w-13,
  .md\:aspect-w-14,
  .md\:aspect-w-15,
  .md\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .md\:aspect-w-1 > *,
  .md\:aspect-w-2 > *,
  .md\:aspect-w-3 > *,
  .md\:aspect-w-4 > *,
  .md\:aspect-w-5 > *,
  .md\:aspect-w-6 > *,
  .md\:aspect-w-7 > *,
  .md\:aspect-w-8 > *,
  .md\:aspect-w-9 > *,
  .md\:aspect-w-10 > *,
  .md\:aspect-w-11 > *,
  .md\:aspect-w-12 > *,
  .md\:aspect-w-13 > *,
  .md\:aspect-w-14 > *,
  .md\:aspect-w-15 > *,
  .md\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .md\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .md\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .md\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .md\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .md\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .md\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .md\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .md\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .md\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .md\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .md\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .md\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .md\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .md\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .md\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .md\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .md\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .md\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .md\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .md\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .md\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .md\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .md\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .md\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .md\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .md\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .md\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .md\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .md\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .md\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .md\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .md\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .md\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .md\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .md\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .md\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .md\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .md\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .md\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .md\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .md\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .md\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .md\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .md\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .md\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .md\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .md\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .md\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .md\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .md\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .md\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .md\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .md\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .md\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .md\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .md\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .md\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .md\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .md\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .md\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .md\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .md\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .md\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .md\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .md\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .md\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .md\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .md\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .md\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .md\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .md\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .md\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .md\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .md\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .md\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .md\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .md\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .md\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .md\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .md\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .md\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .md\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .md\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .md\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .md\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .md\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .md\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .md\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .md\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .md\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .md\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .md\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .md\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .md\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .md\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .md\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .md\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .md\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .md\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .md\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .md\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .md\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .md\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .md\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .md\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .md\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .md\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .md\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .md\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .md\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .md\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .md\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .md\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .md\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .md\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .md\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .md\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .md\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .md\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .md\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .md\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .md\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .md\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .md\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .md\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .md\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .md\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .md\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .md\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .md\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .md\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .md\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .md\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .md\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .md\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .md\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .md\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .md\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .md\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .md\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .md\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .md\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .md\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .md\:bg-fixed {
    background-attachment: fixed;
  }
  .md\:bg-local {
    background-attachment: local;
  }
  .md\:bg-scroll {
    background-attachment: scroll;
  }
  .md\:bg-clip-border {
    background-clip: border-box;
  }
  .md\:bg-clip-padding {
    background-clip: padding-box;
  }
  .md\:bg-clip-content {
    background-clip: content-box;
  }
  .md\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .md\:bg-transparent {
    background-color: transparent;
  }
  .md\:bg-current {
    background-color: currentColor;
  }
  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .md\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .md\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .md\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .md\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .md\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .md\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .md\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .md\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .md\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .md\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .md\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .md\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .md\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .md\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .md\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .md\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .md\:bg-body-background {
    background-color: var(--body-background);
  }
  .md\:bg-background {
    background-color: var(--background);
  }
  .md\:bg-input-background {
    background-color: var(--input-background);
  }
  .md\:bg-body-text {
    background-color: var(--body-text);
  }
  .md\:bg-header-text {
    background-color: var(--header-text);
  }
  .md\:bg-primary {
    background-color: var(--primary);
  }
  .md\:bg-secondary {
    background-color: var(--secondary);
  }
  .md\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .md\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .md\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .md\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .md\:bg-input-text {
    background-color: var(--input-text);
  }
  .md\:bg-success {
    background-color: var(--success);
  }
  .md\:bg-error {
    background-color: var(--error);
  }
  .md\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .md\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .md\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .md\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .md\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .md\:bg-background50 {
    background-color: var(--background50);
  }
  .md\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .md\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .md\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .md\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .md\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .md\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .md\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .md\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .md\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .md\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .md\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .md\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .md\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .md\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .md\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .md\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .md\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .md\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .md\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .md\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .md\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .md\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .md\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .md\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .md\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .md\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .md\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .md\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .md\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .md\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .md\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .md\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .md\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .md\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .md\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .md\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .md\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .md\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .md\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .md\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .md\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .md\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .md\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .md\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .md\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .md\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .md\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .md\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .md\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .md\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .md\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .md\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .md\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .md\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .md\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .md\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .md\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .md\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .md\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .md\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .md\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .md\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .md\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .md\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .md\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .md\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .md\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .md\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .md\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .md\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .md\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .md\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .md\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .md\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .md\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .md\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .md\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .md\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .md\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .md\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .md\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .md\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .md\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .md\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .md\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .md\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .md\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .md\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .md\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .md\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .md\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .md\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .md\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .md\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .md\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .md\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .md\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .md\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .md\:bg-none {
    background-image: none;
  }
  .md\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .md\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .md\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .md\:from-background,
  .md\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-background {
    --tw-gradient-from: var(--background);
  }
  .md\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .md\:from-body-text,
  .md\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .md\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .md\:from-header-text,
  .md\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .md\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .md\:from-secondary,
  .md\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .md\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .md\:from-primary-hover,
  .md\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .md\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .md\:from-input-text,
  .md\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .md\:from-success {
    --tw-gradient-from: var(--success);
  }
  .md\:from-error,
  .md\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-error {
    --tw-gradient-from: var(--error);
  }
  .md\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .md\:from-skeleton-loader-from,
  .md\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .md\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .md\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .md\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .md\:to-current {
    --tw-gradient-to: currentColor;
  }
  .md\:to-black {
    --tw-gradient-to: #000;
  }
  .md\:to-white {
    --tw-gradient-to: #fff;
  }
  .md\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .md\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .md\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .md\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .md\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .md\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .md\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .md\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .md\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .md\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .md\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .md\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .md\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .md\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .md\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .md\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .md\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .md\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .md\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .md\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .md\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .md\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .md\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .md\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .md\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .md\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .md\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .md\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .md\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .md\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .md\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .md\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .md\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .md\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .md\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .md\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .md\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .md\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .md\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .md\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .md\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .md\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .md\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .md\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .md\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .md\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .md\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .md\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .md\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .md\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .md\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .md\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .md\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .md\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .md\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .md\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .md\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .md\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .md\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .md\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .md\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .md\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .md\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .md\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .md\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .md\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .md\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .md\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .md\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .md\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .md\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .md\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .md\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .md\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .md\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .md\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .md\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .md\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .md\:to-background {
    --tw-gradient-to: var(--background);
  }
  .md\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .md\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .md\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .md\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .md\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .md\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .md\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .md\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .md\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .md\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .md\:to-success {
    --tw-gradient-to: var(--success);
  }
  .md\:to-error {
    --tw-gradient-to: var(--error);
  }
  .md\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .md\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .md\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .md\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .md\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .md\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .md\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .md\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .md\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .md\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .md\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .md\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .md\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .md\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .md\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .md\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .md\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .md\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .md\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .md\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .md\:hover\:from-background:hover,
  .md\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .md\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .md\:hover\:from-body-text:hover,
  .md\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .md\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .md\:hover\:from-header-text:hover,
  .md\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .md\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .md\:hover\:from-secondary:hover,
  .md\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .md\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .md\:hover\:from-primary-hover:hover,
  .md\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .md\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .md\:hover\:from-input-text:hover,
  .md\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .md\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .md\:hover\:from-error:hover,
  .md\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .md\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .md\:hover\:from-background50:hover,
  .md\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .md\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .md\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .md\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .md\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .md\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .md\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .md\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .md\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .md\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .md\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .md\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .md\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .md\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .md\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .md\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .md\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .md\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .md\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .md\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .md\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .md\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .md\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .md\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .md\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .md\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .md\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .md\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .md\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .md\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .md\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .md\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .md\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .md\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .md\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .md\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .md\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .md\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .md\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .md\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .md\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .md\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .md\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .md\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .md\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .md\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .md\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .md\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .md\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .md\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .md\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .md\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .md\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .md\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .md\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .md\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .md\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .md\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .md\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .md\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .md\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .md\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .md\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .md\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .md\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .md\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .md\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .md\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .md\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .md\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .md\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .md\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .md\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .md\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .md\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .md\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .md\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .md\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .md\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .md\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .md\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .md\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .md\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .md\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .md\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .md\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .md\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .md\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .md\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .md\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .md\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .md\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .md\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .md\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .md\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .md\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .md\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .md\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .md\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .md\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .md\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .md\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .md\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .md\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .md\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .md\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .md\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .md\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .md\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .md\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .md\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .md\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .md\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .md\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .md\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .md\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .md\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .md\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .md\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .md\:focus\:from-background:focus,
  .md\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .md\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .md\:focus\:from-body-text:focus,
  .md\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .md\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .md\:focus\:from-header-text:focus,
  .md\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .md\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .md\:focus\:from-secondary:focus,
  .md\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .md\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .md\:focus\:from-primary-hover:focus,
  .md\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .md\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .md\:focus\:from-input-text:focus,
  .md\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .md\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .md\:focus\:from-error:focus,
  .md\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .md\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .md\:focus\:from-background50:focus,
  .md\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .md\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .md\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .md\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .md\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .md\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .md\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .md\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .md\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .md\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .md\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .md\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .md\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .md\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .md\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .md\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .md\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .md\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .md\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .md\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .md\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .md\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .md\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .md\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .md\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .md\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .md\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .md\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .md\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .md\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .md\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .md\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .md\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .md\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .md\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .md\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .md\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .md\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .md\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .md\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .md\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .md\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .md\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .md\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .md\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .md\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .md\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .md\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .md\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .md\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .md\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .md\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .md\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .md\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .md\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .md\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .md\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .md\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .md\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .md\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .md\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .md\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .md\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .md\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .md\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .md\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .md\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .md\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .md\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .md\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .md\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .md\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .md\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .md\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .md\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .md\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .md\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .md\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .md\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .md\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .md\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .md\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .md\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .md\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .md\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .md\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .md\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .md\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .md\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .md\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .md\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .md\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .md\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .md\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .md\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .md\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .md\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .md\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .md\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .md\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .md\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .md\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .md\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .md\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .md\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .md\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .md\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .md\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .md\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .md\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .md\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .md\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .md\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .md\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .md\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .md\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .md\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .md\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .md\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .md\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .md\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .md\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .md\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .md\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .md\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .md\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .md\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .md\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .md\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .md\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .md\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .md\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .md\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .md\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .md\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .md\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .md\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .md\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .md\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .md\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .md\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .md\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .md\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .md\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .md\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .md\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .md\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .md\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .md\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .md\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .md\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .md\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .md\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .md\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .md\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .md\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .md\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .md\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .md\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .md\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .md\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .md\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .md\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .md\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .md\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .md\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .md\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .md\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .md\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .md\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .md\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .md\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .md\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .md\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .md\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .md\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .md\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .md\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .md\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .md\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .md\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .md\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .md\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .md\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .md\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .md\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .md\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .md\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .md\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .md\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .md\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .md\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .md\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .md\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .md\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .md\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .md\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .md\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .md\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .md\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .md\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .md\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .md\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .md\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .md\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .md\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .md\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .md\:bg-bottom {
    background-position: bottom;
  }
  .md\:bg-center {
    background-position: 50%;
  }
  .md\:bg-left {
    background-position: 0;
  }
  .md\:bg-left-bottom {
    background-position: 0 100%;
  }
  .md\:bg-left-top {
    background-position: 0 0;
  }
  .md\:bg-right {
    background-position: 100%;
  }
  .md\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .md\:bg-right-top {
    background-position: 100% 0;
  }
  .md\:bg-top {
    background-position: top;
  }
  .md\:bg-repeat {
    background-repeat: repeat;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .md\:bg-repeat-round {
    background-repeat: round;
  }
  .md\:bg-repeat-space {
    background-repeat: space;
  }
  .md\:bg-auto {
    background-size: auto;
  }
  .md\:bg-cover {
    background-size: cover;
  }
  .md\:bg-contain {
    background-size: contain;
  }
  .md\:border-collapse {
    border-collapse: collapse;
  }
  .md\:border-separate {
    border-collapse: separate;
  }
  .md\:border-transparent {
    border-color: transparent;
  }
  .md\:border-current {
    border-color: currentColor;
  }
  .md\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .md\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .md\:border-background-primary {
    border-color: var(--background-primary);
  }
  .md\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .md\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .md\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .md\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .md\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .md\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .md\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .md\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .md\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .md\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .md\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .md\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .md\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .md\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .md\:border-body-background {
    border-color: var(--body-background);
  }
  .md\:border-background {
    border-color: var(--background);
  }
  .md\:border-input-background {
    border-color: var(--input-background);
  }
  .md\:border-body-text {
    border-color: var(--body-text);
  }
  .md\:border-header-text {
    border-color: var(--header-text);
  }
  .md\:border-primary {
    border-color: var(--primary);
  }
  .md\:border-secondary {
    border-color: var(--secondary);
  }
  .md\:border-tertiary {
    border-color: var(--tertiary);
  }
  .md\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .md\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .md\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .md\:border-input-text {
    border-color: var(--input-text);
  }
  .md\:border-success {
    border-color: var(--success);
  }
  .md\:border-error {
    border-color: var(--error);
  }
  .md\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .md\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .md\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .md\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .md\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .md\:border-background50 {
    border-color: var(--background50);
  }
  .md\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .md\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .md\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .md\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .md\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .md\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .md\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .md\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .md\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .md\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .md\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .md\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .md\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .md\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .md\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .md\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .md\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .md\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .md\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .md\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .md\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .md\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .md\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .md\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .md\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .md\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .md\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .md\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .md\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .md\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .md\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .md\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .md\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .md\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .md\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .md\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .md\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .md\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .md\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .md\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .md\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .md\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .md\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .md\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .md\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .md\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .md\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .md\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .md\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .md\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .md\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .md\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .md\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .md\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .md\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .md\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .md\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .md\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .md\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .md\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .md\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .md\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .md\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .md\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .md\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .md\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .md\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .md\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .md\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .md\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .md\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .md\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .md\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .md\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .md\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .md\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .md\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .md\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .md\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .md\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .md\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .md\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .md\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .md\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .md\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .md\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .md\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .md\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .md\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .md\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .md\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .md\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .md\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .md\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .md\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .md\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .md\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .md\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .md\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .md\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .md\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .md\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .md\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .md\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .md\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .md\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .md\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .md\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .md\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .md\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .md\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .md\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .md\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .md\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .md\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .md\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .md\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .md\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .md\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .md\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .md\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .md\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .md\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .md\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .md\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .md\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .md\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .md\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .md\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .md\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .md\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .md\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .md\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .md\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .md\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .md\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .md\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .md\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .md\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .md\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .md\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .md\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .md\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .md\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .md\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .md\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .md\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .md\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .md\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .md\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .md\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .md\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .md\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .md\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .md\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .md\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .md\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .md\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .md\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .md\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .md\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .md\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .md\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .md\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .md\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .md\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .md\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .md\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .md\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .md\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .md\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .md\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .md\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .md\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .md\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .md\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .md\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .md\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .md\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .md\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .md\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .md\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .md\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .md\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .md\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .md\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .md\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .md\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .md\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .md\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .md\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .md\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .md\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .md\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .md\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .md\:rounded-none {
    border-radius: 0;
  }
  .md\:rounded-sm {
    border-radius: 0.125rem;
  }
  .md\:rounded {
    border-radius: 0.25rem;
  }
  .md\:rounded-md {
    border-radius: 0.375rem;
  }
  .md\:rounded-lg {
    border-radius: 0.5rem;
  }
  .md\:rounded-xl {
    border-radius: 0.75rem;
  }
  .md\:rounded-2xl {
    border-radius: 1rem;
  }
  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .md\:rounded-full {
    border-radius: 9999px;
  }
  .md\:rounded-card {
    border-radius: 20px;
  }
  .md\:rounded-time-item {
    border-radius: 100px;
  }
  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-t {
    border-top-left-radius: 0.25rem;
  }
  .md\:rounded-r,
  .md\:rounded-t {
    border-top-right-radius: 0.25rem;
  }
  .md\:rounded-b,
  .md\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .md\:rounded-b,
  .md\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .md\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .md\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .md\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .md\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .md\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .md\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .md\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .md\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .md\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .md\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .md\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .md\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .md\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .md\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .md\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .md\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .md\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .md\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .md\:border-solid {
    border-style: solid;
  }
  .md\:border-dashed {
    border-style: dashed;
  }
  .md\:border-dotted {
    border-style: dotted;
  }
  .md\:border-double {
    border-style: double;
  }
  .md\:border-none {
    border-style: none;
  }
  .md\:border-0 {
    border-width: 0;
  }
  .md\:border-2 {
    border-width: 2px;
  }
  .md\:border-4 {
    border-width: 4px;
  }
  .md\:border-8 {
    border-width: 8px;
  }
  .md\:border {
    border-width: 1px;
  }
  .md\:border-t-0 {
    border-top-width: 0;
  }
  .md\:border-r-0 {
    border-right-width: 0;
  }
  .md\:border-b-0 {
    border-bottom-width: 0;
  }
  .md\:border-l-0 {
    border-left-width: 0;
  }
  .md\:border-t-2 {
    border-top-width: 2px;
  }
  .md\:border-r-2 {
    border-right-width: 2px;
  }
  .md\:border-b-2 {
    border-bottom-width: 2px;
  }
  .md\:border-l-2 {
    border-left-width: 2px;
  }
  .md\:border-t-4 {
    border-top-width: 4px;
  }
  .md\:border-r-4 {
    border-right-width: 4px;
  }
  .md\:border-b-4 {
    border-bottom-width: 4px;
  }
  .md\:border-l-4 {
    border-left-width: 4px;
  }
  .md\:border-t-8 {
    border-top-width: 8px;
  }
  .md\:border-r-8 {
    border-right-width: 8px;
  }
  .md\:border-b-8 {
    border-bottom-width: 8px;
  }
  .md\:border-l-8 {
    border-left-width: 8px;
  }
  .md\:border-t {
    border-top-width: 1px;
  }
  .md\:border-r {
    border-right-width: 1px;
  }
  .md\:border-b {
    border-bottom-width: 1px;
  }
  .md\:border-l {
    border-left-width: 1px;
  }
  .md\:box-border {
    box-sizing: border-box;
  }
  .md\:box-content {
    box-sizing: content-box;
  }
  .md\:cursor-auto {
    cursor: auto;
  }
  .md\:cursor-default {
    cursor: default;
  }
  .md\:cursor-pointer {
    cursor: pointer;
  }
  .md\:cursor-wait {
    cursor: wait;
  }
  .md\:cursor-text {
    cursor: text;
  }
  .md\:cursor-move {
    cursor: move;
  }
  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .md\:block {
    display: block;
  }
  .md\:inline-block {
    display: inline-block;
  }
  .md\:inline {
    display: inline;
  }
  .md\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .md\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .md\:table {
    display: table;
  }
  .md\:table-caption {
    display: table-caption;
  }
  .md\:table-cell {
    display: table-cell;
  }
  .md\:table-column {
    display: table-column;
  }
  .md\:table-column-group {
    display: table-column-group;
  }
  .md\:table-footer-group {
    display: table-footer-group;
  }
  .md\:table-header-group {
    display: table-header-group;
  }
  .md\:table-row-group {
    display: table-row-group;
  }
  .md\:table-row {
    display: table-row;
  }
  .md\:flow-root {
    display: flow-root;
  }
  .md\:grid {
    display: grid;
  }
  .md\:inline-grid {
    display: inline-grid;
  }
  .md\:contents {
    display: contents;
  }
  .md\:hidden {
    display: none;
  }
  .md\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .md\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .md\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .md\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .md\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .md\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .md\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .md\:place-items-auto {
    place-items: auto;
  }
  .md\:place-items-start {
    place-items: start;
  }
  .md\:place-items-end {
    place-items: end;
  }
  .md\:place-items-center {
    place-items: center;
  }
  .md\:place-items-stretch {
    place-items: stretch;
  }
  .md\:place-content-center {
    place-content: center;
  }
  .md\:place-content-start {
    place-content: start;
  }
  .md\:place-content-end {
    place-content: end;
  }
  .md\:place-content-between {
    place-content: space-between;
  }
  .md\:place-content-around {
    place-content: space-around;
  }
  .md\:place-content-evenly {
    place-content: space-evenly;
  }
  .md\:place-content-stretch {
    place-content: stretch;
  }
  .md\:place-self-auto {
    place-self: auto;
  }
  .md\:place-self-start {
    place-self: start;
  }
  .md\:place-self-end {
    place-self: end;
  }
  .md\:place-self-center {
    place-self: center;
  }
  .md\:place-self-stretch {
    place-self: stretch;
  }
  .md\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .md\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .md\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .md\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .md\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .md\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .md\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .md\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .md\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .md\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .md\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .md\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .md\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .md\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .md\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .md\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .md\:justify-items-auto {
    justify-items: auto;
  }
  .md\:justify-items-start {
    justify-items: start;
  }
  .md\:justify-items-end {
    justify-items: end;
  }
  .md\:justify-items-center {
    justify-items: center;
  }
  .md\:justify-items-stretch {
    justify-items: stretch;
  }
  .md\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .md\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .md\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .md\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .md\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .md\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .md\:justify-self-auto {
    justify-self: auto;
  }
  .md\:justify-self-start {
    justify-self: start;
  }
  .md\:justify-self-end {
    justify-self: end;
  }
  .md\:justify-self-center {
    justify-self: center;
  }
  .md\:justify-self-stretch {
    justify-self: stretch;
  }
  .md\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .md\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .md\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .md\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .md\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .md\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .md\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .md\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .md\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .md\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .md\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .md\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .md\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .md\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .md\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .md\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .md\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .md\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .md\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .md\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .md\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .md\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .md\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .md\:float-right {
    float: right;
  }
  .md\:float-left {
    float: left;
  }
  .md\:float-none {
    float: none;
  }
  .md\:clear-left {
    clear: left;
  }
  .md\:clear-right {
    clear: right;
  }
  .md\:clear-both {
    clear: both;
  }
  .md\:clear-none {
    clear: none;
  }
  .md\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .md\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .md\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .md\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .md\:font-text {
    font-family: Bozon, sans-serif;
  }
  .md\:font-thin {
    font-weight: 100;
  }
  .md\:font-extralight {
    font-weight: 200;
  }
  .md\:font-light {
    font-weight: 300;
  }
  .md\:font-normal {
    font-weight: 400;
  }
  .md\:font-medium {
    font-weight: 500;
  }
  .md\:font-semibold {
    font-weight: 600;
  }
  .md\:font-bold {
    font-weight: 700;
  }
  .md\:font-extrabold {
    font-weight: 800;
  }
  .md\:font-black {
    font-weight: 900;
  }
  .md\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .md\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .md\:hover\:font-light:hover {
    font-weight: 300;
  }
  .md\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .md\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .md\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .md\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .md\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .md\:hover\:font-black:hover {
    font-weight: 900;
  }
  .md\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .md\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .md\:focus\:font-light:focus {
    font-weight: 300;
  }
  .md\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .md\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .md\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .md\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .md\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .md\:focus\:font-black:focus {
    font-weight: 900;
  }
  .md\:h-0 {
    height: 0;
  }
  .md\:h-1 {
    height: 0.25rem;
  }
  .md\:h-2 {
    height: 0.5rem;
  }
  .md\:h-3 {
    height: 0.75rem;
  }
  .md\:h-4 {
    height: 1rem;
  }
  .md\:h-5 {
    height: 1.25rem;
  }
  .md\:h-6 {
    height: 1.5rem;
  }
  .md\:h-7 {
    height: 1.75rem;
  }
  .md\:h-8 {
    height: 2rem;
  }
  .md\:h-9 {
    height: 2.25rem;
  }
  .md\:h-10 {
    height: 2.5rem;
  }
  .md\:h-11 {
    height: 2.75rem;
  }
  .md\:h-12 {
    height: 3rem;
  }
  .md\:h-14 {
    height: 3.5rem;
  }
  .md\:h-16 {
    height: 4rem;
  }
  .md\:h-20 {
    height: 5rem;
  }
  .md\:h-24 {
    height: 6rem;
  }
  .md\:h-28 {
    height: 7rem;
  }
  .md\:h-32 {
    height: 8rem;
  }
  .md\:h-36 {
    height: 9rem;
  }
  .md\:h-40 {
    height: 10rem;
  }
  .md\:h-44 {
    height: 11rem;
  }
  .md\:h-48 {
    height: 12rem;
  }
  .md\:h-52 {
    height: 13rem;
  }
  .md\:h-56 {
    height: 14rem;
  }
  .md\:h-60 {
    height: 15rem;
  }
  .md\:h-64 {
    height: 16rem;
  }
  .md\:h-72 {
    height: 18rem;
  }
  .md\:h-80 {
    height: 20rem;
  }
  .md\:h-96 {
    height: 24rem;
  }
  .md\:h-105 {
    height: 105px;
  }
  .md\:h-188 {
    height: 188px;
  }
  .md\:h-344 {
    height: 344px;
  }
  .md\:h-425 {
    height: 425px;
  }
  .md\:h-627 {
    height: 627px;
  }
  .md\:h-auto {
    height: auto;
  }
  .md\:h-px {
    height: 1px;
  }
  .md\:h-0\.5 {
    height: 0.125rem;
  }
  .md\:h-1\.5 {
    height: 0.375rem;
  }
  .md\:h-2\.5 {
    height: 0.625rem;
  }
  .md\:h-3\.5 {
    height: 0.875rem;
  }
  .md\:h-1\/2 {
    height: 50%;
  }
  .md\:h-1\/3 {
    height: 33.333333%;
  }
  .md\:h-2\/3 {
    height: 66.666667%;
  }
  .md\:h-1\/4 {
    height: 25%;
  }
  .md\:h-2\/4 {
    height: 50%;
  }
  .md\:h-3\/4 {
    height: 75%;
  }
  .md\:h-1\/5 {
    height: 20%;
  }
  .md\:h-2\/5 {
    height: 40%;
  }
  .md\:h-3\/5 {
    height: 60%;
  }
  .md\:h-4\/5 {
    height: 80%;
  }
  .md\:h-1\/6 {
    height: 16.666667%;
  }
  .md\:h-2\/6 {
    height: 33.333333%;
  }
  .md\:h-3\/6 {
    height: 50%;
  }
  .md\:h-4\/6 {
    height: 66.666667%;
  }
  .md\:h-5\/6 {
    height: 83.333333%;
  }
  .md\:h-full {
    height: 100%;
  }
  .md\:h-screen {
    height: 100vh;
  }
  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .md\:text-base {
    font-size: 1em;
  }
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .md\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .md\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .md\:text-m-sm {
    font-size: 0.8em;
  }
  .md\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .md\:text-m-xl {
    font-size: 1.2em;
  }
  .md\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .md\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .md\:text-m-4xl {
    font-size: 1.6em;
  }
  .md\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .md\:text-d-sm {
    font-size: 0.875em;
  }
  .md\:text-d-lg {
    font-size: 1.125em;
  }
  .md\:text-d-xl {
    font-size: 1.375em;
  }
  .md\:text-d-2xl {
    font-size: 1.625em;
  }
  .md\:text-d-3xl {
    font-size: 1.75em;
  }
  .md\:text-d-4xl {
    font-size: 2.25em;
  }
  .md\:text-d-5xl {
    font-size: 2.625em;
  }
  .md\:text-d-11xl {
    font-size: 16em;
  }
  .md\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .md\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .md\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .md\:leading-3 {
    line-height: 0.75rem;
  }
  .md\:leading-4 {
    line-height: 1rem;
  }
  .md\:leading-5 {
    line-height: 1.25rem;
  }
  .md\:leading-6 {
    line-height: 1.5rem;
  }
  .md\:leading-7 {
    line-height: 1.75rem;
  }
  .md\:leading-8 {
    line-height: 2rem;
  }
  .md\:leading-9 {
    line-height: 2.25rem;
  }
  .md\:leading-10 {
    line-height: 2.5rem;
  }
  .md\:leading-none {
    line-height: 1;
  }
  .md\:leading-tight {
    line-height: 1.25;
  }
  .md\:leading-snug {
    line-height: 1.375;
  }
  .md\:leading-normal {
    line-height: 1.5;
  }
  .md\:leading-relaxed {
    line-height: 1.625;
  }
  .md\:leading-loose {
    line-height: 2;
  }
  .md\:list-inside {
    list-style-position: inside;
  }
  .md\:list-outside {
    list-style-position: outside;
  }
  .md\:list-none {
    list-style-type: none;
  }
  .md\:list-disc {
    list-style-type: disc;
  }
  .md\:list-decimal {
    list-style-type: decimal;
  }
  .md\:m-0 {
    margin: 0;
  }
  .md\:m-1 {
    margin: 0.25rem;
  }
  .md\:m-2 {
    margin: 0.5rem;
  }
  .md\:m-3 {
    margin: 0.75rem;
  }
  .md\:m-4 {
    margin: 1rem;
  }
  .md\:m-5 {
    margin: 1.25rem;
  }
  .md\:m-6 {
    margin: 1.5rem;
  }
  .md\:m-7 {
    margin: 1.75rem;
  }
  .md\:m-8 {
    margin: 2rem;
  }
  .md\:m-9 {
    margin: 2.25rem;
  }
  .md\:m-10 {
    margin: 2.5rem;
  }
  .md\:m-11 {
    margin: 2.75rem;
  }
  .md\:m-12 {
    margin: 3rem;
  }
  .md\:m-14 {
    margin: 3.5rem;
  }
  .md\:m-16 {
    margin: 4rem;
  }
  .md\:m-20 {
    margin: 5rem;
  }
  .md\:m-24 {
    margin: 6rem;
  }
  .md\:m-28 {
    margin: 7rem;
  }
  .md\:m-32 {
    margin: 8rem;
  }
  .md\:m-36 {
    margin: 9rem;
  }
  .md\:m-40 {
    margin: 10rem;
  }
  .md\:m-44 {
    margin: 11rem;
  }
  .md\:m-48 {
    margin: 12rem;
  }
  .md\:m-52 {
    margin: 13rem;
  }
  .md\:m-56 {
    margin: 14rem;
  }
  .md\:m-60 {
    margin: 15rem;
  }
  .md\:m-64 {
    margin: 16rem;
  }
  .md\:m-72 {
    margin: 18rem;
  }
  .md\:m-80 {
    margin: 20rem;
  }
  .md\:m-96 {
    margin: 24rem;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:m-px {
    margin: 1px;
  }
  .md\:m-0\.5 {
    margin: 0.125rem;
  }
  .md\:m-1\.5 {
    margin: 0.375rem;
  }
  .md\:m-2\.5 {
    margin: 0.625rem;
  }
  .md\:m-3\.5 {
    margin: 0.875rem;
  }
  .md\:-m-0 {
    margin: 0;
  }
  .md\:-m-1 {
    margin: -0.25rem;
  }
  .md\:-m-2 {
    margin: -0.5rem;
  }
  .md\:-m-3 {
    margin: -0.75rem;
  }
  .md\:-m-4 {
    margin: -1rem;
  }
  .md\:-m-5 {
    margin: -1.25rem;
  }
  .md\:-m-6 {
    margin: -1.5rem;
  }
  .md\:-m-7 {
    margin: -1.75rem;
  }
  .md\:-m-8 {
    margin: -2rem;
  }
  .md\:-m-9 {
    margin: -2.25rem;
  }
  .md\:-m-10 {
    margin: -2.5rem;
  }
  .md\:-m-11 {
    margin: -2.75rem;
  }
  .md\:-m-12 {
    margin: -3rem;
  }
  .md\:-m-14 {
    margin: -3.5rem;
  }
  .md\:-m-16 {
    margin: -4rem;
  }
  .md\:-m-20 {
    margin: -5rem;
  }
  .md\:-m-24 {
    margin: -6rem;
  }
  .md\:-m-28 {
    margin: -7rem;
  }
  .md\:-m-32 {
    margin: -8rem;
  }
  .md\:-m-36 {
    margin: -9rem;
  }
  .md\:-m-40 {
    margin: -10rem;
  }
  .md\:-m-44 {
    margin: -11rem;
  }
  .md\:-m-48 {
    margin: -12rem;
  }
  .md\:-m-52 {
    margin: -13rem;
  }
  .md\:-m-56 {
    margin: -14rem;
  }
  .md\:-m-60 {
    margin: -15rem;
  }
  .md\:-m-64 {
    margin: -16rem;
  }
  .md\:-m-72 {
    margin: -18rem;
  }
  .md\:-m-80 {
    margin: -20rem;
  }
  .md\:-m-96 {
    margin: -24rem;
  }
  .md\:-m-px {
    margin: -1px;
  }
  .md\:-m-0\.5 {
    margin: -0.125rem;
  }
  .md\:-m-1\.5 {
    margin: -0.375rem;
  }
  .md\:-m-2\.5 {
    margin: -0.625rem;
  }
  .md\:-m-3\.5 {
    margin: -0.875rem;
  }
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .md\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .md\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .md\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .md\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .md\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .md\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .md\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .md\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .md\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .md\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .md\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .md\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .md\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .md\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .md\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .md\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .md\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .md\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .md\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .md\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .md\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .md\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .md\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .md\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .md\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .md\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .md\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .md\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .md\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .md\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .md\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .md\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .md\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .md\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .md\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .md\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .md\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .md\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .md\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .md\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .md\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .md\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .md\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .md\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .md\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .md\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .md\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .md\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .md\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .md\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .md\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .md\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .md\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .md\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .md\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .md\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .md\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:mr-0 {
    margin-right: 0;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
  .md\:ml-0 {
    margin-left: 0;
  }
  .md\:mt-1 {
    margin-top: 0.25rem;
  }
  .md\:mr-1 {
    margin-right: 0.25rem;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .md\:ml-1 {
    margin-left: 0.25rem;
  }
  .md\:mt-2 {
    margin-top: 0.5rem;
  }
  .md\:mr-2 {
    margin-right: 0.5rem;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .md\:ml-2 {
    margin-left: 0.5rem;
  }
  .md\:mt-3 {
    margin-top: 0.75rem;
  }
  .md\:mr-3 {
    margin-right: 0.75rem;
  }
  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:mt-4 {
    margin-top: 1rem;
  }
  .md\:mr-4 {
    margin-right: 1rem;
  }
  .md\:mb-4 {
    margin-bottom: 1rem;
  }
  .md\:ml-4 {
    margin-left: 1rem;
  }
  .md\:mt-5 {
    margin-top: 1.25rem;
  }
  .md\:mr-5 {
    margin-right: 1.25rem;
  }
  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .md\:ml-5 {
    margin-left: 1.25rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:mr-6 {
    margin-right: 1.5rem;
  }
  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .md\:ml-6 {
    margin-left: 1.5rem;
  }
  .md\:mt-7 {
    margin-top: 1.75rem;
  }
  .md\:mr-7 {
    margin-right: 1.75rem;
  }
  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .md\:ml-7 {
    margin-left: 1.75rem;
  }
  .md\:mt-8 {
    margin-top: 2rem;
  }
  .md\:mr-8 {
    margin-right: 2rem;
  }
  .md\:mb-8 {
    margin-bottom: 2rem;
  }
  .md\:ml-8 {
    margin-left: 2rem;
  }
  .md\:mt-9 {
    margin-top: 2.25rem;
  }
  .md\:mr-9 {
    margin-right: 2.25rem;
  }
  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .md\:ml-9 {
    margin-left: 2.25rem;
  }
  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:mr-10 {
    margin-right: 2.5rem;
  }
  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .md\:ml-10 {
    margin-left: 2.5rem;
  }
  .md\:mt-11 {
    margin-top: 2.75rem;
  }
  .md\:mr-11 {
    margin-right: 2.75rem;
  }
  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .md\:ml-11 {
    margin-left: 2.75rem;
  }
  .md\:mt-12 {
    margin-top: 3rem;
  }
  .md\:mr-12 {
    margin-right: 3rem;
  }
  .md\:mb-12 {
    margin-bottom: 3rem;
  }
  .md\:ml-12 {
    margin-left: 3rem;
  }
  .md\:mt-14 {
    margin-top: 3.5rem;
  }
  .md\:mr-14 {
    margin-right: 3.5rem;
  }
  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .md\:ml-14 {
    margin-left: 3.5rem;
  }
  .md\:mt-16 {
    margin-top: 4rem;
  }
  .md\:mr-16 {
    margin-right: 4rem;
  }
  .md\:mb-16 {
    margin-bottom: 4rem;
  }
  .md\:ml-16 {
    margin-left: 4rem;
  }
  .md\:mt-20 {
    margin-top: 5rem;
  }
  .md\:mr-20 {
    margin-right: 5rem;
  }
  .md\:mb-20 {
    margin-bottom: 5rem;
  }
  .md\:ml-20 {
    margin-left: 5rem;
  }
  .md\:mt-24 {
    margin-top: 6rem;
  }
  .md\:mr-24 {
    margin-right: 6rem;
  }
  .md\:mb-24 {
    margin-bottom: 6rem;
  }
  .md\:ml-24 {
    margin-left: 6rem;
  }
  .md\:mt-28 {
    margin-top: 7rem;
  }
  .md\:mr-28 {
    margin-right: 7rem;
  }
  .md\:mb-28 {
    margin-bottom: 7rem;
  }
  .md\:ml-28 {
    margin-left: 7rem;
  }
  .md\:mt-32 {
    margin-top: 8rem;
  }
  .md\:mr-32 {
    margin-right: 8rem;
  }
  .md\:mb-32 {
    margin-bottom: 8rem;
  }
  .md\:ml-32 {
    margin-left: 8rem;
  }
  .md\:mt-36 {
    margin-top: 9rem;
  }
  .md\:mr-36 {
    margin-right: 9rem;
  }
  .md\:mb-36 {
    margin-bottom: 9rem;
  }
  .md\:ml-36 {
    margin-left: 9rem;
  }
  .md\:mt-40 {
    margin-top: 10rem;
  }
  .md\:mr-40 {
    margin-right: 10rem;
  }
  .md\:mb-40 {
    margin-bottom: 10rem;
  }
  .md\:ml-40 {
    margin-left: 10rem;
  }
  .md\:mt-44 {
    margin-top: 11rem;
  }
  .md\:mr-44 {
    margin-right: 11rem;
  }
  .md\:mb-44 {
    margin-bottom: 11rem;
  }
  .md\:ml-44 {
    margin-left: 11rem;
  }
  .md\:mt-48 {
    margin-top: 12rem;
  }
  .md\:mr-48 {
    margin-right: 12rem;
  }
  .md\:mb-48 {
    margin-bottom: 12rem;
  }
  .md\:ml-48 {
    margin-left: 12rem;
  }
  .md\:mt-52 {
    margin-top: 13rem;
  }
  .md\:mr-52 {
    margin-right: 13rem;
  }
  .md\:mb-52 {
    margin-bottom: 13rem;
  }
  .md\:ml-52 {
    margin-left: 13rem;
  }
  .md\:mt-56 {
    margin-top: 14rem;
  }
  .md\:mr-56 {
    margin-right: 14rem;
  }
  .md\:mb-56 {
    margin-bottom: 14rem;
  }
  .md\:ml-56 {
    margin-left: 14rem;
  }
  .md\:mt-60 {
    margin-top: 15rem;
  }
  .md\:mr-60 {
    margin-right: 15rem;
  }
  .md\:mb-60 {
    margin-bottom: 15rem;
  }
  .md\:ml-60 {
    margin-left: 15rem;
  }
  .md\:mt-64 {
    margin-top: 16rem;
  }
  .md\:mr-64 {
    margin-right: 16rem;
  }
  .md\:mb-64 {
    margin-bottom: 16rem;
  }
  .md\:ml-64 {
    margin-left: 16rem;
  }
  .md\:mt-72 {
    margin-top: 18rem;
  }
  .md\:mr-72 {
    margin-right: 18rem;
  }
  .md\:mb-72 {
    margin-bottom: 18rem;
  }
  .md\:ml-72 {
    margin-left: 18rem;
  }
  .md\:mt-80 {
    margin-top: 20rem;
  }
  .md\:mr-80 {
    margin-right: 20rem;
  }
  .md\:mb-80 {
    margin-bottom: 20rem;
  }
  .md\:ml-80 {
    margin-left: 20rem;
  }
  .md\:mt-96 {
    margin-top: 24rem;
  }
  .md\:mr-96 {
    margin-right: 24rem;
  }
  .md\:mb-96 {
    margin-bottom: 24rem;
  }
  .md\:ml-96 {
    margin-left: 24rem;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:mt-px {
    margin-top: 1px;
  }
  .md\:mr-px {
    margin-right: 1px;
  }
  .md\:mb-px {
    margin-bottom: 1px;
  }
  .md\:ml-px {
    margin-left: 1px;
  }
  .md\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .md\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .md\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .md\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .md\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .md\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .md\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .md\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .md\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .md\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .md\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .md\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .md\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .md\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .md\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .md\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .md\:-mt-0 {
    margin-top: 0;
  }
  .md\:-mr-0 {
    margin-right: 0;
  }
  .md\:-mb-0 {
    margin-bottom: 0;
  }
  .md\:-ml-0 {
    margin-left: 0;
  }
  .md\:-mt-1 {
    margin-top: -0.25rem;
  }
  .md\:-mr-1 {
    margin-right: -0.25rem;
  }
  .md\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .md\:-ml-1 {
    margin-left: -0.25rem;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem;
  }
  .md\:-mr-2 {
    margin-right: -0.5rem;
  }
  .md\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .md\:-ml-2 {
    margin-left: -0.5rem;
  }
  .md\:-mt-3 {
    margin-top: -0.75rem;
  }
  .md\:-mr-3 {
    margin-right: -0.75rem;
  }
  .md\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .md\:-ml-3 {
    margin-left: -0.75rem;
  }
  .md\:-mt-4 {
    margin-top: -1rem;
  }
  .md\:-mr-4 {
    margin-right: -1rem;
  }
  .md\:-mb-4 {
    margin-bottom: -1rem;
  }
  .md\:-ml-4 {
    margin-left: -1rem;
  }
  .md\:-mt-5 {
    margin-top: -1.25rem;
  }
  .md\:-mr-5 {
    margin-right: -1.25rem;
  }
  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .md\:-ml-5 {
    margin-left: -1.25rem;
  }
  .md\:-mt-6 {
    margin-top: -1.5rem;
  }
  .md\:-mr-6 {
    margin-right: -1.5rem;
  }
  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .md\:-ml-6 {
    margin-left: -1.5rem;
  }
  .md\:-mt-7 {
    margin-top: -1.75rem;
  }
  .md\:-mr-7 {
    margin-right: -1.75rem;
  }
  .md\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .md\:-ml-7 {
    margin-left: -1.75rem;
  }
  .md\:-mt-8 {
    margin-top: -2rem;
  }
  .md\:-mr-8 {
    margin-right: -2rem;
  }
  .md\:-mb-8 {
    margin-bottom: -2rem;
  }
  .md\:-ml-8 {
    margin-left: -2rem;
  }
  .md\:-mt-9 {
    margin-top: -2.25rem;
  }
  .md\:-mr-9 {
    margin-right: -2.25rem;
  }
  .md\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .md\:-ml-9 {
    margin-left: -2.25rem;
  }
  .md\:-mt-10 {
    margin-top: -2.5rem;
  }
  .md\:-mr-10 {
    margin-right: -2.5rem;
  }
  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .md\:-ml-10 {
    margin-left: -2.5rem;
  }
  .md\:-mt-11 {
    margin-top: -2.75rem;
  }
  .md\:-mr-11 {
    margin-right: -2.75rem;
  }
  .md\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .md\:-ml-11 {
    margin-left: -2.75rem;
  }
  .md\:-mt-12 {
    margin-top: -3rem;
  }
  .md\:-mr-12 {
    margin-right: -3rem;
  }
  .md\:-mb-12 {
    margin-bottom: -3rem;
  }
  .md\:-ml-12 {
    margin-left: -3rem;
  }
  .md\:-mt-14 {
    margin-top: -3.5rem;
  }
  .md\:-mr-14 {
    margin-right: -3.5rem;
  }
  .md\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .md\:-ml-14 {
    margin-left: -3.5rem;
  }
  .md\:-mt-16 {
    margin-top: -4rem;
  }
  .md\:-mr-16 {
    margin-right: -4rem;
  }
  .md\:-mb-16 {
    margin-bottom: -4rem;
  }
  .md\:-ml-16 {
    margin-left: -4rem;
  }
  .md\:-mt-20 {
    margin-top: -5rem;
  }
  .md\:-mr-20 {
    margin-right: -5rem;
  }
  .md\:-mb-20 {
    margin-bottom: -5rem;
  }
  .md\:-ml-20 {
    margin-left: -5rem;
  }
  .md\:-mt-24 {
    margin-top: -6rem;
  }
  .md\:-mr-24 {
    margin-right: -6rem;
  }
  .md\:-mb-24 {
    margin-bottom: -6rem;
  }
  .md\:-ml-24 {
    margin-left: -6rem;
  }
  .md\:-mt-28 {
    margin-top: -7rem;
  }
  .md\:-mr-28 {
    margin-right: -7rem;
  }
  .md\:-mb-28 {
    margin-bottom: -7rem;
  }
  .md\:-ml-28 {
    margin-left: -7rem;
  }
  .md\:-mt-32 {
    margin-top: -8rem;
  }
  .md\:-mr-32 {
    margin-right: -8rem;
  }
  .md\:-mb-32 {
    margin-bottom: -8rem;
  }
  .md\:-ml-32 {
    margin-left: -8rem;
  }
  .md\:-mt-36 {
    margin-top: -9rem;
  }
  .md\:-mr-36 {
    margin-right: -9rem;
  }
  .md\:-mb-36 {
    margin-bottom: -9rem;
  }
  .md\:-ml-36 {
    margin-left: -9rem;
  }
  .md\:-mt-40 {
    margin-top: -10rem;
  }
  .md\:-mr-40 {
    margin-right: -10rem;
  }
  .md\:-mb-40 {
    margin-bottom: -10rem;
  }
  .md\:-ml-40 {
    margin-left: -10rem;
  }
  .md\:-mt-44 {
    margin-top: -11rem;
  }
  .md\:-mr-44 {
    margin-right: -11rem;
  }
  .md\:-mb-44 {
    margin-bottom: -11rem;
  }
  .md\:-ml-44 {
    margin-left: -11rem;
  }
  .md\:-mt-48 {
    margin-top: -12rem;
  }
  .md\:-mr-48 {
    margin-right: -12rem;
  }
  .md\:-mb-48 {
    margin-bottom: -12rem;
  }
  .md\:-ml-48 {
    margin-left: -12rem;
  }
  .md\:-mt-52 {
    margin-top: -13rem;
  }
  .md\:-mr-52 {
    margin-right: -13rem;
  }
  .md\:-mb-52 {
    margin-bottom: -13rem;
  }
  .md\:-ml-52 {
    margin-left: -13rem;
  }
  .md\:-mt-56 {
    margin-top: -14rem;
  }
  .md\:-mr-56 {
    margin-right: -14rem;
  }
  .md\:-mb-56 {
    margin-bottom: -14rem;
  }
  .md\:-ml-56 {
    margin-left: -14rem;
  }
  .md\:-mt-60 {
    margin-top: -15rem;
  }
  .md\:-mr-60 {
    margin-right: -15rem;
  }
  .md\:-mb-60 {
    margin-bottom: -15rem;
  }
  .md\:-ml-60 {
    margin-left: -15rem;
  }
  .md\:-mt-64 {
    margin-top: -16rem;
  }
  .md\:-mr-64 {
    margin-right: -16rem;
  }
  .md\:-mb-64 {
    margin-bottom: -16rem;
  }
  .md\:-ml-64 {
    margin-left: -16rem;
  }
  .md\:-mt-72 {
    margin-top: -18rem;
  }
  .md\:-mr-72 {
    margin-right: -18rem;
  }
  .md\:-mb-72 {
    margin-bottom: -18rem;
  }
  .md\:-ml-72 {
    margin-left: -18rem;
  }
  .md\:-mt-80 {
    margin-top: -20rem;
  }
  .md\:-mr-80 {
    margin-right: -20rem;
  }
  .md\:-mb-80 {
    margin-bottom: -20rem;
  }
  .md\:-ml-80 {
    margin-left: -20rem;
  }
  .md\:-mt-96 {
    margin-top: -24rem;
  }
  .md\:-mr-96 {
    margin-right: -24rem;
  }
  .md\:-mb-96 {
    margin-bottom: -24rem;
  }
  .md\:-ml-96 {
    margin-left: -24rem;
  }
  .md\:-mt-px {
    margin-top: -1px;
  }
  .md\:-mr-px {
    margin-right: -1px;
  }
  .md\:-mb-px {
    margin-bottom: -1px;
  }
  .md\:-ml-px {
    margin-left: -1px;
  }
  .md\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .md\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .md\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .md\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .md\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .md\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .md\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .md\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .md\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .md\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .md\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .md\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .md\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .md\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .md\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .md\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .md\:max-h-0 {
    max-height: 0;
  }
  .md\:max-h-1 {
    max-height: 0.25rem;
  }
  .md\:max-h-2 {
    max-height: 0.5rem;
  }
  .md\:max-h-3 {
    max-height: 0.75rem;
  }
  .md\:max-h-4 {
    max-height: 1rem;
  }
  .md\:max-h-5 {
    max-height: 1.25rem;
  }
  .md\:max-h-6 {
    max-height: 1.5rem;
  }
  .md\:max-h-7 {
    max-height: 1.75rem;
  }
  .md\:max-h-8 {
    max-height: 2rem;
  }
  .md\:max-h-9 {
    max-height: 2.25rem;
  }
  .md\:max-h-10 {
    max-height: 2.5rem;
  }
  .md\:max-h-11 {
    max-height: 2.75rem;
  }
  .md\:max-h-12 {
    max-height: 3rem;
  }
  .md\:max-h-14 {
    max-height: 3.5rem;
  }
  .md\:max-h-16 {
    max-height: 4rem;
  }
  .md\:max-h-20 {
    max-height: 5rem;
  }
  .md\:max-h-24 {
    max-height: 6rem;
  }
  .md\:max-h-28 {
    max-height: 7rem;
  }
  .md\:max-h-32 {
    max-height: 8rem;
  }
  .md\:max-h-36 {
    max-height: 9rem;
  }
  .md\:max-h-40 {
    max-height: 10rem;
  }
  .md\:max-h-44 {
    max-height: 11rem;
  }
  .md\:max-h-48 {
    max-height: 12rem;
  }
  .md\:max-h-52 {
    max-height: 13rem;
  }
  .md\:max-h-56 {
    max-height: 14rem;
  }
  .md\:max-h-60 {
    max-height: 15rem;
  }
  .md\:max-h-64 {
    max-height: 16rem;
  }
  .md\:max-h-72 {
    max-height: 18rem;
  }
  .md\:max-h-80 {
    max-height: 20rem;
  }
  .md\:max-h-96 {
    max-height: 24rem;
  }
  .md\:max-h-px {
    max-height: 1px;
  }
  .md\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .md\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .md\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .md\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .md\:max-h-full {
    max-height: 100%;
  }
  .md\:max-h-screen {
    max-height: 100vh;
  }
  .md\:max-h-1\/4 {
    max-height: 25%;
  }
  .md\:max-h-1\/2 {
    max-height: 50%;
  }
  .md\:max-h-3\/4 {
    max-height: 75%;
  }
  .md\:max-h-10\/12 {
    max-height: 80%;
  }
  .md\:max-h-enormous {
    max-height: 4000px;
  }
  .md\:max-w-0 {
    max-width: 0;
  }
  .md\:max-w-110 {
    max-width: 110px;
  }
  .md\:max-w-473 {
    max-width: 473px;
  }
  .md\:max-w-none {
    max-width: none;
  }
  .md\:max-w-xs {
    max-width: 20rem;
  }
  .md\:max-w-sm {
    max-width: 24rem;
  }
  .md\:max-w-md {
    max-width: 28rem;
  }
  .md\:max-w-lg {
    max-width: 32rem;
  }
  .md\:max-w-xl {
    max-width: 36rem;
  }
  .md\:max-w-2xl {
    max-width: 42rem;
  }
  .md\:max-w-3xl {
    max-width: 48rem;
  }
  .md\:max-w-4xl {
    max-width: 56rem;
  }
  .md\:max-w-5xl {
    max-width: 64rem;
  }
  .md\:max-w-6xl {
    max-width: 72rem;
  }
  .md\:max-w-7xl {
    max-width: 80rem;
  }
  .md\:max-w-full {
    max-width: 100%;
  }
  .md\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .md\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .md\:max-w-prose {
    max-width: 65ch;
  }
  .md\:max-w-screen-sm {
    max-width: 640px;
  }
  .md\:max-w-screen-md {
    max-width: 768px;
  }
  .md\:max-w-screen-lg {
    max-width: 1024px;
  }
  .md\:max-w-screen-xl {
    max-width: 1280px;
  }
  .md\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .md\:max-w-screen-xxs {
    max-width: 260px;
  }
  .md\:max-w-screen-xs {
    max-width: 360px;
  }
  .md\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .md\:min-h-0 {
    min-height: 0;
  }
  .md\:min-h-full {
    min-height: 100%;
  }
  .md\:min-h-screen {
    min-height: 100vh;
  }
  .md\:min-w-0 {
    min-width: 0;
  }
  .md\:min-w-10 {
    min-width: 2.5rem;
  }
  .md\:min-w-12 {
    min-width: 3rem;
  }
  .md\:min-w-14 {
    min-width: 3.5rem;
  }
  .md\:min-w-full {
    min-width: 100%;
  }
  .md\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .md\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .md\:object-contain {
    object-fit: contain;
  }
  .md\:object-cover {
    object-fit: cover;
  }
  .md\:object-fill {
    object-fit: fill;
  }
  .md\:object-none {
    object-fit: none;
  }
  .md\:object-scale-down {
    object-fit: scale-down;
  }
  .md\:object-bottom {
    object-position: bottom;
  }
  .md\:object-center {
    object-position: center;
  }
  .md\:object-left {
    object-position: left;
  }
  .md\:object-left-bottom {
    object-position: left bottom;
  }
  .md\:object-left-top {
    object-position: left top;
  }
  .md\:object-right {
    object-position: right;
  }
  .md\:object-right-bottom {
    object-position: right bottom;
  }
  .md\:object-right-top {
    object-position: right top;
  }
  .md\:object-top {
    object-position: top;
  }
  .md\:opacity-0 {
    opacity: 0;
  }
  .md\:opacity-5 {
    opacity: 0.05;
  }
  .md\:opacity-10 {
    opacity: 0.1;
  }
  .md\:opacity-20 {
    opacity: 0.2;
  }
  .md\:opacity-25 {
    opacity: 0.25;
  }
  .md\:opacity-30 {
    opacity: 0.3;
  }
  .md\:opacity-40 {
    opacity: 0.4;
  }
  .md\:opacity-50 {
    opacity: 0.5;
  }
  .md\:opacity-60 {
    opacity: 0.6;
  }
  .md\:opacity-70 {
    opacity: 0.7;
  }
  .md\:opacity-75 {
    opacity: 0.75;
  }
  .md\:opacity-80 {
    opacity: 0.8;
  }
  .md\:opacity-90 {
    opacity: 0.9;
  }
  .md\:opacity-95 {
    opacity: 0.95;
  }
  .md\:opacity-100 {
    opacity: 1;
  }
  .group:hover .md\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .md\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .md\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .md\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .md\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .md\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .md\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .md\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .md\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .md\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .md\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .md\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .md\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .md\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .md\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .md\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .md\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .md\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .md\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .md\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .md\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .md\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .md\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .md\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .md\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .md\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .md\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .md\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .md\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .md\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .md\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .md\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .md\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .md\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .md\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .md\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .md\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .md\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .md\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .md\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .md\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .md\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .md\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .md\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .md\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .md\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .md\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .md\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .md\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .md\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .md\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .md\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .md\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .md\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .md\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .md\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .md\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .md\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .md\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .md\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .md\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .md\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .md\:overflow-auto {
    overflow: auto;
  }
  .md\:overflow-hidden {
    overflow: hidden;
  }
  .md\:overflow-visible {
    overflow: visible;
  }
  .md\:overflow-scroll {
    overflow: scroll;
  }
  .md\:overflow-x-auto {
    overflow-x: auto;
  }
  .md\:overflow-y-auto {
    overflow-y: auto;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .md\:overflow-x-visible {
    overflow-x: visible;
  }
  .md\:overflow-y-visible {
    overflow-y: visible;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .md\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .md\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .md\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .md\:p-0 {
    padding: 0;
  }
  .md\:p-1 {
    padding: 0.25rem;
  }
  .md\:p-2 {
    padding: 0.5rem;
  }
  .md\:p-3 {
    padding: 0.75rem;
  }
  .md\:p-4 {
    padding: 1rem;
  }
  .md\:p-5 {
    padding: 1.25rem;
  }
  .md\:p-6 {
    padding: 1.5rem;
  }
  .md\:p-7 {
    padding: 1.75rem;
  }
  .md\:p-8 {
    padding: 2rem;
  }
  .md\:p-9 {
    padding: 2.25rem;
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:p-11 {
    padding: 2.75rem;
  }
  .md\:p-12 {
    padding: 3rem;
  }
  .md\:p-14 {
    padding: 3.5rem;
  }
  .md\:p-16 {
    padding: 4rem;
  }
  .md\:p-20 {
    padding: 5rem;
  }
  .md\:p-24 {
    padding: 6rem;
  }
  .md\:p-28 {
    padding: 7rem;
  }
  .md\:p-32 {
    padding: 8rem;
  }
  .md\:p-36 {
    padding: 9rem;
  }
  .md\:p-40 {
    padding: 10rem;
  }
  .md\:p-44 {
    padding: 11rem;
  }
  .md\:p-48 {
    padding: 12rem;
  }
  .md\:p-52 {
    padding: 13rem;
  }
  .md\:p-56 {
    padding: 14rem;
  }
  .md\:p-60 {
    padding: 15rem;
  }
  .md\:p-64 {
    padding: 16rem;
  }
  .md\:p-72 {
    padding: 18rem;
  }
  .md\:p-80 {
    padding: 20rem;
  }
  .md\:p-96 {
    padding: 24rem;
  }
  .md\:p-px {
    padding: 1px;
  }
  .md\:p-0\.5 {
    padding: 0.125rem;
  }
  .md\:p-1\.5 {
    padding: 0.375rem;
  }
  .md\:p-2\.5 {
    padding: 0.625rem;
  }
  .md\:p-3\.5 {
    padding: 0.875rem;
  }
  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .md\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .md\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .md\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .md\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .md\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .md\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .md\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .md\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .md\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .md\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .md\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .md\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .md\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .md\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .md\:pt-0 {
    padding-top: 0;
  }
  .md\:pr-0 {
    padding-right: 0;
  }
  .md\:pb-0 {
    padding-bottom: 0;
  }
  .md\:pl-0 {
    padding-left: 0;
  }
  .md\:pt-1 {
    padding-top: 0.25rem;
  }
  .md\:pr-1 {
    padding-right: 0.25rem;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .md\:pl-1 {
    padding-left: 0.25rem;
  }
  .md\:pt-2 {
    padding-top: 0.5rem;
  }
  .md\:pr-2 {
    padding-right: 0.5rem;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .md\:pl-2 {
    padding-left: 0.5rem;
  }
  .md\:pt-3 {
    padding-top: 0.75rem;
  }
  .md\:pr-3 {
    padding-right: 0.75rem;
  }
  .md\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .md\:pl-3 {
    padding-left: 0.75rem;
  }
  .md\:pt-4 {
    padding-top: 1rem;
  }
  .md\:pr-4 {
    padding-right: 1rem;
  }
  .md\:pb-4 {
    padding-bottom: 1rem;
  }
  .md\:pl-4 {
    padding-left: 1rem;
  }
  .md\:pt-5 {
    padding-top: 1.25rem;
  }
  .md\:pr-5 {
    padding-right: 1.25rem;
  }
  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .md\:pl-5 {
    padding-left: 1.25rem;
  }
  .md\:pt-6 {
    padding-top: 1.5rem;
  }
  .md\:pr-6 {
    padding-right: 1.5rem;
  }
  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .md\:pl-6 {
    padding-left: 1.5rem;
  }
  .md\:pt-7 {
    padding-top: 1.75rem;
  }
  .md\:pr-7 {
    padding-right: 1.75rem;
  }
  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .md\:pl-7 {
    padding-left: 1.75rem;
  }
  .md\:pt-8 {
    padding-top: 2rem;
  }
  .md\:pr-8 {
    padding-right: 2rem;
  }
  .md\:pb-8 {
    padding-bottom: 2rem;
  }
  .md\:pl-8 {
    padding-left: 2rem;
  }
  .md\:pt-9 {
    padding-top: 2.25rem;
  }
  .md\:pr-9 {
    padding-right: 2.25rem;
  }
  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .md\:pl-9 {
    padding-left: 2.25rem;
  }
  .md\:pt-10 {
    padding-top: 2.5rem;
  }
  .md\:pr-10 {
    padding-right: 2.5rem;
  }
  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .md\:pl-10 {
    padding-left: 2.5rem;
  }
  .md\:pt-11 {
    padding-top: 2.75rem;
  }
  .md\:pr-11 {
    padding-right: 2.75rem;
  }
  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .md\:pl-11 {
    padding-left: 2.75rem;
  }
  .md\:pt-12 {
    padding-top: 3rem;
  }
  .md\:pr-12 {
    padding-right: 3rem;
  }
  .md\:pb-12 {
    padding-bottom: 3rem;
  }
  .md\:pl-12 {
    padding-left: 3rem;
  }
  .md\:pt-14 {
    padding-top: 3.5rem;
  }
  .md\:pr-14 {
    padding-right: 3.5rem;
  }
  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .md\:pl-14 {
    padding-left: 3.5rem;
  }
  .md\:pt-16 {
    padding-top: 4rem;
  }
  .md\:pr-16 {
    padding-right: 4rem;
  }
  .md\:pb-16 {
    padding-bottom: 4rem;
  }
  .md\:pl-16 {
    padding-left: 4rem;
  }
  .md\:pt-20 {
    padding-top: 5rem;
  }
  .md\:pr-20 {
    padding-right: 5rem;
  }
  .md\:pb-20 {
    padding-bottom: 5rem;
  }
  .md\:pl-20 {
    padding-left: 5rem;
  }
  .md\:pt-24 {
    padding-top: 6rem;
  }
  .md\:pr-24 {
    padding-right: 6rem;
  }
  .md\:pb-24 {
    padding-bottom: 6rem;
  }
  .md\:pl-24 {
    padding-left: 6rem;
  }
  .md\:pt-28 {
    padding-top: 7rem;
  }
  .md\:pr-28 {
    padding-right: 7rem;
  }
  .md\:pb-28 {
    padding-bottom: 7rem;
  }
  .md\:pl-28 {
    padding-left: 7rem;
  }
  .md\:pt-32 {
    padding-top: 8rem;
  }
  .md\:pr-32 {
    padding-right: 8rem;
  }
  .md\:pb-32 {
    padding-bottom: 8rem;
  }
  .md\:pl-32 {
    padding-left: 8rem;
  }
  .md\:pt-36 {
    padding-top: 9rem;
  }
  .md\:pr-36 {
    padding-right: 9rem;
  }
  .md\:pb-36 {
    padding-bottom: 9rem;
  }
  .md\:pl-36 {
    padding-left: 9rem;
  }
  .md\:pt-40 {
    padding-top: 10rem;
  }
  .md\:pr-40 {
    padding-right: 10rem;
  }
  .md\:pb-40 {
    padding-bottom: 10rem;
  }
  .md\:pl-40 {
    padding-left: 10rem;
  }
  .md\:pt-44 {
    padding-top: 11rem;
  }
  .md\:pr-44 {
    padding-right: 11rem;
  }
  .md\:pb-44 {
    padding-bottom: 11rem;
  }
  .md\:pl-44 {
    padding-left: 11rem;
  }
  .md\:pt-48 {
    padding-top: 12rem;
  }
  .md\:pr-48 {
    padding-right: 12rem;
  }
  .md\:pb-48 {
    padding-bottom: 12rem;
  }
  .md\:pl-48 {
    padding-left: 12rem;
  }
  .md\:pt-52 {
    padding-top: 13rem;
  }
  .md\:pr-52 {
    padding-right: 13rem;
  }
  .md\:pb-52 {
    padding-bottom: 13rem;
  }
  .md\:pl-52 {
    padding-left: 13rem;
  }
  .md\:pt-56 {
    padding-top: 14rem;
  }
  .md\:pr-56 {
    padding-right: 14rem;
  }
  .md\:pb-56 {
    padding-bottom: 14rem;
  }
  .md\:pl-56 {
    padding-left: 14rem;
  }
  .md\:pt-60 {
    padding-top: 15rem;
  }
  .md\:pr-60 {
    padding-right: 15rem;
  }
  .md\:pb-60 {
    padding-bottom: 15rem;
  }
  .md\:pl-60 {
    padding-left: 15rem;
  }
  .md\:pt-64 {
    padding-top: 16rem;
  }
  .md\:pr-64 {
    padding-right: 16rem;
  }
  .md\:pb-64 {
    padding-bottom: 16rem;
  }
  .md\:pl-64 {
    padding-left: 16rem;
  }
  .md\:pt-72 {
    padding-top: 18rem;
  }
  .md\:pr-72 {
    padding-right: 18rem;
  }
  .md\:pb-72 {
    padding-bottom: 18rem;
  }
  .md\:pl-72 {
    padding-left: 18rem;
  }
  .md\:pt-80 {
    padding-top: 20rem;
  }
  .md\:pr-80 {
    padding-right: 20rem;
  }
  .md\:pb-80 {
    padding-bottom: 20rem;
  }
  .md\:pl-80 {
    padding-left: 20rem;
  }
  .md\:pt-96 {
    padding-top: 24rem;
  }
  .md\:pr-96 {
    padding-right: 24rem;
  }
  .md\:pb-96 {
    padding-bottom: 24rem;
  }
  .md\:pl-96 {
    padding-left: 24rem;
  }
  .md\:pt-px {
    padding-top: 1px;
  }
  .md\:pr-px {
    padding-right: 1px;
  }
  .md\:pb-px {
    padding-bottom: 1px;
  }
  .md\:pl-px {
    padding-left: 1px;
  }
  .md\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .md\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .md\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .md\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .md\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .md\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .md\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .md\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .md\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .md\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .md\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .md\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .md\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .md\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .md\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .md\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .md\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .md\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .md\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .md\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .md\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .md\:placeholder-current::placeholder {
    color: currentColor;
  }
  .md\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .md\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .md\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .md\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .md\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .md\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .md\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .md\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .md\:placeholder-background::placeholder {
    color: var(--background);
  }
  .md\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .md\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .md\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .md\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .md\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .md\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .md\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .md\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .md\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .md\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .md\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .md\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .md\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .md\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .md\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .md\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .md\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .md\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .md\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .md\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .md\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .md\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .md\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .md\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .md\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .md\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .md\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .md\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .md\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .md\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .md\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .md\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .md\:placeholder-success::placeholder {
    color: var(--success);
  }
  .md\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .md\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .md\:placeholder-error::placeholder {
    color: var(--error);
  }
  .md\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .md\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .md\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .md\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .md\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .md\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .md\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .md\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .md\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .md\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .md\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .md\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .md\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .md\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .md\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .md\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .md\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .md\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .md\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .md\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .md\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .md\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .md\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .md\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .md\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .md\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .md\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .md\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .md\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .md\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .md\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .md\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .md\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .md\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .md\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .md\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .md\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .md\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .md\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .md\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .md\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .md\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .md\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .md\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .md\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .md\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .md\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .md\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .md\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .md\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .md\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .md\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .md\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .md\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .md\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .md\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .md\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .md\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .md\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .md\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .md\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .md\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .md\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .md\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:pointer-events-none {
    pointer-events: none;
  }
  .md\:pointer-events-auto {
    pointer-events: auto;
  }
  .md\:static {
    position: static;
  }
  .md\:fixed {
    position: fixed;
  }
  .md\:absolute {
    position: absolute;
  }
  .md\:relative {
    position: relative;
  }
  .md\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .md\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .md\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .md\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .md\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .md\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .md\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .md\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .md\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .md\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .md\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .md\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .md\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .md\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .md\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .md\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .md\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .md\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .md\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .md\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .md\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .md\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .md\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .md\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .md\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .md\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .md\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .md\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .md\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .md\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .md\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .md\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .md\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .md\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .md\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .md\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .md\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .md\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .md\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .md\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .md\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .md\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .md\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .md\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .md\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .md\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .md\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .md\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .md\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .md\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .md\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .md\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .md\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .md\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .md\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .md\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .md\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .md\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .md\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .md\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .md\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .md\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .md\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .md\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .md\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .md\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .md\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .md\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .md\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .md\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .md\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .md\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .md\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .md\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .md\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .md\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .md\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .md\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .md\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .md\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .md\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .md\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .md\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .md\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .md\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .md\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .md\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .md\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .md\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .md\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .md\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .md\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .md\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .md\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .md\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .md\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .md\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .md\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .md\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .md\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .md\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .md\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .md\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .md\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .md\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .md\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .md\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .md\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .md\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .md\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .md\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .md\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .md\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .md\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .md\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .md\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .md\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .md\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .md\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .md\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .md\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .md\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .md\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .md\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .md\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .md\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .md\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .md\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .md\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .md\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .md\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .md\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .md\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .md\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .md\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .md\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .md\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .md\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .md\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .md\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .md\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .md\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .md\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .md\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .md\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .md\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .md\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .md\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .md\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .md\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .md\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .md\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .md\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .md\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .md\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .md\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .md\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .md\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .md\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .md\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .md\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .md\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .md\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .md\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .md\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .md\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .md\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .md\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .md\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .md\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .md\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .md\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .md\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .md\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .md\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .md\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .md\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .md\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .md\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .md\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .md\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .md\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .md\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .md\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .md\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .md\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .md\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .md\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .md\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .md\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .md\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .md\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .md\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .md\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .md\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .md\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .md\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .md\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .md\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .md\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .md\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .md\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .md\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .md\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .md\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .md\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .md\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .md\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .md\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .md\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .md\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .md\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .md\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .md\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .md\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .md\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .md\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .md\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .md\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .md\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .md\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .md\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .md\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .md\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .md\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .md\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .md\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .md\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .md\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .md\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .md\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .md\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .md\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .md\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .md\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .md\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .md\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .md\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .md\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .md\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .md\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .md\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .md\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .md\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .md\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .md\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .md\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .md\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .md\:top-0 {
    top: 0;
  }
  .md\:right-0 {
    right: 0;
  }
  .md\:bottom-0 {
    bottom: 0;
  }
  .md\:left-0 {
    left: 0;
  }
  .md\:top-1 {
    top: 0.25rem;
  }
  .md\:right-1 {
    right: 0.25rem;
  }
  .md\:bottom-1 {
    bottom: 0.25rem;
  }
  .md\:left-1 {
    left: 0.25rem;
  }
  .md\:top-2 {
    top: 0.5rem;
  }
  .md\:right-2 {
    right: 0.5rem;
  }
  .md\:bottom-2 {
    bottom: 0.5rem;
  }
  .md\:left-2 {
    left: 0.5rem;
  }
  .md\:top-3 {
    top: 0.75rem;
  }
  .md\:right-3 {
    right: 0.75rem;
  }
  .md\:bottom-3 {
    bottom: 0.75rem;
  }
  .md\:left-3 {
    left: 0.75rem;
  }
  .md\:top-4 {
    top: 1rem;
  }
  .md\:right-4 {
    right: 1rem;
  }
  .md\:bottom-4 {
    bottom: 1rem;
  }
  .md\:left-4 {
    left: 1rem;
  }
  .md\:top-5 {
    top: 1.25rem;
  }
  .md\:right-5 {
    right: 1.25rem;
  }
  .md\:bottom-5 {
    bottom: 1.25rem;
  }
  .md\:left-5 {
    left: 1.25rem;
  }
  .md\:top-6 {
    top: 1.5rem;
  }
  .md\:right-6 {
    right: 1.5rem;
  }
  .md\:bottom-6 {
    bottom: 1.5rem;
  }
  .md\:left-6 {
    left: 1.5rem;
  }
  .md\:top-7 {
    top: 1.75rem;
  }
  .md\:right-7 {
    right: 1.75rem;
  }
  .md\:bottom-7 {
    bottom: 1.75rem;
  }
  .md\:left-7 {
    left: 1.75rem;
  }
  .md\:top-8 {
    top: 2rem;
  }
  .md\:right-8 {
    right: 2rem;
  }
  .md\:bottom-8 {
    bottom: 2rem;
  }
  .md\:left-8 {
    left: 2rem;
  }
  .md\:top-9 {
    top: 2.25rem;
  }
  .md\:right-9 {
    right: 2.25rem;
  }
  .md\:bottom-9 {
    bottom: 2.25rem;
  }
  .md\:left-9 {
    left: 2.25rem;
  }
  .md\:top-10 {
    top: 2.5rem;
  }
  .md\:right-10 {
    right: 2.5rem;
  }
  .md\:bottom-10 {
    bottom: 2.5rem;
  }
  .md\:left-10 {
    left: 2.5rem;
  }
  .md\:top-11 {
    top: 2.75rem;
  }
  .md\:right-11 {
    right: 2.75rem;
  }
  .md\:bottom-11 {
    bottom: 2.75rem;
  }
  .md\:left-11 {
    left: 2.75rem;
  }
  .md\:top-12 {
    top: 3rem;
  }
  .md\:right-12 {
    right: 3rem;
  }
  .md\:bottom-12 {
    bottom: 3rem;
  }
  .md\:left-12 {
    left: 3rem;
  }
  .md\:top-14 {
    top: 3.5rem;
  }
  .md\:right-14 {
    right: 3.5rem;
  }
  .md\:bottom-14 {
    bottom: 3.5rem;
  }
  .md\:left-14 {
    left: 3.5rem;
  }
  .md\:top-16 {
    top: 4rem;
  }
  .md\:right-16 {
    right: 4rem;
  }
  .md\:bottom-16 {
    bottom: 4rem;
  }
  .md\:left-16 {
    left: 4rem;
  }
  .md\:top-20 {
    top: 5rem;
  }
  .md\:right-20 {
    right: 5rem;
  }
  .md\:bottom-20 {
    bottom: 5rem;
  }
  .md\:left-20 {
    left: 5rem;
  }
  .md\:top-24 {
    top: 6rem;
  }
  .md\:right-24 {
    right: 6rem;
  }
  .md\:bottom-24 {
    bottom: 6rem;
  }
  .md\:left-24 {
    left: 6rem;
  }
  .md\:top-28 {
    top: 7rem;
  }
  .md\:right-28 {
    right: 7rem;
  }
  .md\:bottom-28 {
    bottom: 7rem;
  }
  .md\:left-28 {
    left: 7rem;
  }
  .md\:top-32 {
    top: 8rem;
  }
  .md\:right-32 {
    right: 8rem;
  }
  .md\:bottom-32 {
    bottom: 8rem;
  }
  .md\:left-32 {
    left: 8rem;
  }
  .md\:top-36 {
    top: 9rem;
  }
  .md\:right-36 {
    right: 9rem;
  }
  .md\:bottom-36 {
    bottom: 9rem;
  }
  .md\:left-36 {
    left: 9rem;
  }
  .md\:top-40 {
    top: 10rem;
  }
  .md\:right-40 {
    right: 10rem;
  }
  .md\:bottom-40 {
    bottom: 10rem;
  }
  .md\:left-40 {
    left: 10rem;
  }
  .md\:top-44 {
    top: 11rem;
  }
  .md\:right-44 {
    right: 11rem;
  }
  .md\:bottom-44 {
    bottom: 11rem;
  }
  .md\:left-44 {
    left: 11rem;
  }
  .md\:top-48 {
    top: 12rem;
  }
  .md\:right-48 {
    right: 12rem;
  }
  .md\:bottom-48 {
    bottom: 12rem;
  }
  .md\:left-48 {
    left: 12rem;
  }
  .md\:top-52 {
    top: 13rem;
  }
  .md\:right-52 {
    right: 13rem;
  }
  .md\:bottom-52 {
    bottom: 13rem;
  }
  .md\:left-52 {
    left: 13rem;
  }
  .md\:top-56 {
    top: 14rem;
  }
  .md\:right-56 {
    right: 14rem;
  }
  .md\:bottom-56 {
    bottom: 14rem;
  }
  .md\:left-56 {
    left: 14rem;
  }
  .md\:top-60 {
    top: 15rem;
  }
  .md\:right-60 {
    right: 15rem;
  }
  .md\:bottom-60 {
    bottom: 15rem;
  }
  .md\:left-60 {
    left: 15rem;
  }
  .md\:top-64 {
    top: 16rem;
  }
  .md\:right-64 {
    right: 16rem;
  }
  .md\:bottom-64 {
    bottom: 16rem;
  }
  .md\:left-64 {
    left: 16rem;
  }
  .md\:top-72 {
    top: 18rem;
  }
  .md\:right-72 {
    right: 18rem;
  }
  .md\:bottom-72 {
    bottom: 18rem;
  }
  .md\:left-72 {
    left: 18rem;
  }
  .md\:top-80 {
    top: 20rem;
  }
  .md\:right-80 {
    right: 20rem;
  }
  .md\:bottom-80 {
    bottom: 20rem;
  }
  .md\:left-80 {
    left: 20rem;
  }
  .md\:top-96 {
    top: 24rem;
  }
  .md\:right-96 {
    right: 24rem;
  }
  .md\:bottom-96 {
    bottom: 24rem;
  }
  .md\:left-96 {
    left: 24rem;
  }
  .md\:top-auto {
    top: auto;
  }
  .md\:right-auto {
    right: auto;
  }
  .md\:bottom-auto {
    bottom: auto;
  }
  .md\:left-auto {
    left: auto;
  }
  .md\:top-px {
    top: 1px;
  }
  .md\:right-px {
    right: 1px;
  }
  .md\:bottom-px {
    bottom: 1px;
  }
  .md\:left-px {
    left: 1px;
  }
  .md\:top-0\.5 {
    top: 0.125rem;
  }
  .md\:right-0\.5 {
    right: 0.125rem;
  }
  .md\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .md\:left-0\.5 {
    left: 0.125rem;
  }
  .md\:top-1\.5 {
    top: 0.375rem;
  }
  .md\:right-1\.5 {
    right: 0.375rem;
  }
  .md\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .md\:left-1\.5 {
    left: 0.375rem;
  }
  .md\:top-2\.5 {
    top: 0.625rem;
  }
  .md\:right-2\.5 {
    right: 0.625rem;
  }
  .md\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .md\:left-2\.5 {
    left: 0.625rem;
  }
  .md\:top-3\.5 {
    top: 0.875rem;
  }
  .md\:right-3\.5 {
    right: 0.875rem;
  }
  .md\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .md\:left-3\.5 {
    left: 0.875rem;
  }
  .md\:-top-0 {
    top: 0;
  }
  .md\:-right-0 {
    right: 0;
  }
  .md\:-bottom-0 {
    bottom: 0;
  }
  .md\:-left-0 {
    left: 0;
  }
  .md\:-top-1 {
    top: -0.25rem;
  }
  .md\:-right-1 {
    right: -0.25rem;
  }
  .md\:-bottom-1 {
    bottom: -0.25rem;
  }
  .md\:-left-1 {
    left: -0.25rem;
  }
  .md\:-top-2 {
    top: -0.5rem;
  }
  .md\:-right-2 {
    right: -0.5rem;
  }
  .md\:-bottom-2 {
    bottom: -0.5rem;
  }
  .md\:-left-2 {
    left: -0.5rem;
  }
  .md\:-top-3 {
    top: -0.75rem;
  }
  .md\:-right-3 {
    right: -0.75rem;
  }
  .md\:-bottom-3 {
    bottom: -0.75rem;
  }
  .md\:-left-3 {
    left: -0.75rem;
  }
  .md\:-top-4 {
    top: -1rem;
  }
  .md\:-right-4 {
    right: -1rem;
  }
  .md\:-bottom-4 {
    bottom: -1rem;
  }
  .md\:-left-4 {
    left: -1rem;
  }
  .md\:-top-5 {
    top: -1.25rem;
  }
  .md\:-right-5 {
    right: -1.25rem;
  }
  .md\:-bottom-5 {
    bottom: -1.25rem;
  }
  .md\:-left-5 {
    left: -1.25rem;
  }
  .md\:-top-6 {
    top: -1.5rem;
  }
  .md\:-right-6 {
    right: -1.5rem;
  }
  .md\:-bottom-6 {
    bottom: -1.5rem;
  }
  .md\:-left-6 {
    left: -1.5rem;
  }
  .md\:-top-7 {
    top: -1.75rem;
  }
  .md\:-right-7 {
    right: -1.75rem;
  }
  .md\:-bottom-7 {
    bottom: -1.75rem;
  }
  .md\:-left-7 {
    left: -1.75rem;
  }
  .md\:-top-8 {
    top: -2rem;
  }
  .md\:-right-8 {
    right: -2rem;
  }
  .md\:-bottom-8 {
    bottom: -2rem;
  }
  .md\:-left-8 {
    left: -2rem;
  }
  .md\:-top-9 {
    top: -2.25rem;
  }
  .md\:-right-9 {
    right: -2.25rem;
  }
  .md\:-bottom-9 {
    bottom: -2.25rem;
  }
  .md\:-left-9 {
    left: -2.25rem;
  }
  .md\:-top-10 {
    top: -2.5rem;
  }
  .md\:-right-10 {
    right: -2.5rem;
  }
  .md\:-bottom-10 {
    bottom: -2.5rem;
  }
  .md\:-left-10 {
    left: -2.5rem;
  }
  .md\:-top-11 {
    top: -2.75rem;
  }
  .md\:-right-11 {
    right: -2.75rem;
  }
  .md\:-bottom-11 {
    bottom: -2.75rem;
  }
  .md\:-left-11 {
    left: -2.75rem;
  }
  .md\:-top-12 {
    top: -3rem;
  }
  .md\:-right-12 {
    right: -3rem;
  }
  .md\:-bottom-12 {
    bottom: -3rem;
  }
  .md\:-left-12 {
    left: -3rem;
  }
  .md\:-top-14 {
    top: -3.5rem;
  }
  .md\:-right-14 {
    right: -3.5rem;
  }
  .md\:-bottom-14 {
    bottom: -3.5rem;
  }
  .md\:-left-14 {
    left: -3.5rem;
  }
  .md\:-top-16 {
    top: -4rem;
  }
  .md\:-right-16 {
    right: -4rem;
  }
  .md\:-bottom-16 {
    bottom: -4rem;
  }
  .md\:-left-16 {
    left: -4rem;
  }
  .md\:-top-20 {
    top: -5rem;
  }
  .md\:-right-20 {
    right: -5rem;
  }
  .md\:-bottom-20 {
    bottom: -5rem;
  }
  .md\:-left-20 {
    left: -5rem;
  }
  .md\:-top-24 {
    top: -6rem;
  }
  .md\:-right-24 {
    right: -6rem;
  }
  .md\:-bottom-24 {
    bottom: -6rem;
  }
  .md\:-left-24 {
    left: -6rem;
  }
  .md\:-top-28 {
    top: -7rem;
  }
  .md\:-right-28 {
    right: -7rem;
  }
  .md\:-bottom-28 {
    bottom: -7rem;
  }
  .md\:-left-28 {
    left: -7rem;
  }
  .md\:-top-32 {
    top: -8rem;
  }
  .md\:-right-32 {
    right: -8rem;
  }
  .md\:-bottom-32 {
    bottom: -8rem;
  }
  .md\:-left-32 {
    left: -8rem;
  }
  .md\:-top-36 {
    top: -9rem;
  }
  .md\:-right-36 {
    right: -9rem;
  }
  .md\:-bottom-36 {
    bottom: -9rem;
  }
  .md\:-left-36 {
    left: -9rem;
  }
  .md\:-top-40 {
    top: -10rem;
  }
  .md\:-right-40 {
    right: -10rem;
  }
  .md\:-bottom-40 {
    bottom: -10rem;
  }
  .md\:-left-40 {
    left: -10rem;
  }
  .md\:-top-44 {
    top: -11rem;
  }
  .md\:-right-44 {
    right: -11rem;
  }
  .md\:-bottom-44 {
    bottom: -11rem;
  }
  .md\:-left-44 {
    left: -11rem;
  }
  .md\:-top-48 {
    top: -12rem;
  }
  .md\:-right-48 {
    right: -12rem;
  }
  .md\:-bottom-48 {
    bottom: -12rem;
  }
  .md\:-left-48 {
    left: -12rem;
  }
  .md\:-top-52 {
    top: -13rem;
  }
  .md\:-right-52 {
    right: -13rem;
  }
  .md\:-bottom-52 {
    bottom: -13rem;
  }
  .md\:-left-52 {
    left: -13rem;
  }
  .md\:-top-56 {
    top: -14rem;
  }
  .md\:-right-56 {
    right: -14rem;
  }
  .md\:-bottom-56 {
    bottom: -14rem;
  }
  .md\:-left-56 {
    left: -14rem;
  }
  .md\:-top-60 {
    top: -15rem;
  }
  .md\:-right-60 {
    right: -15rem;
  }
  .md\:-bottom-60 {
    bottom: -15rem;
  }
  .md\:-left-60 {
    left: -15rem;
  }
  .md\:-top-64 {
    top: -16rem;
  }
  .md\:-right-64 {
    right: -16rem;
  }
  .md\:-bottom-64 {
    bottom: -16rem;
  }
  .md\:-left-64 {
    left: -16rem;
  }
  .md\:-top-72 {
    top: -18rem;
  }
  .md\:-right-72 {
    right: -18rem;
  }
  .md\:-bottom-72 {
    bottom: -18rem;
  }
  .md\:-left-72 {
    left: -18rem;
  }
  .md\:-top-80 {
    top: -20rem;
  }
  .md\:-right-80 {
    right: -20rem;
  }
  .md\:-bottom-80 {
    bottom: -20rem;
  }
  .md\:-left-80 {
    left: -20rem;
  }
  .md\:-top-96 {
    top: -24rem;
  }
  .md\:-right-96 {
    right: -24rem;
  }
  .md\:-bottom-96 {
    bottom: -24rem;
  }
  .md\:-left-96 {
    left: -24rem;
  }
  .md\:-top-px {
    top: -1px;
  }
  .md\:-right-px {
    right: -1px;
  }
  .md\:-bottom-px {
    bottom: -1px;
  }
  .md\:-left-px {
    left: -1px;
  }
  .md\:-top-0\.5 {
    top: -0.125rem;
  }
  .md\:-right-0\.5 {
    right: -0.125rem;
  }
  .md\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .md\:-left-0\.5 {
    left: -0.125rem;
  }
  .md\:-top-1\.5 {
    top: -0.375rem;
  }
  .md\:-right-1\.5 {
    right: -0.375rem;
  }
  .md\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .md\:-left-1\.5 {
    left: -0.375rem;
  }
  .md\:-top-2\.5 {
    top: -0.625rem;
  }
  .md\:-right-2\.5 {
    right: -0.625rem;
  }
  .md\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .md\:-left-2\.5 {
    left: -0.625rem;
  }
  .md\:-top-3\.5 {
    top: -0.875rem;
  }
  .md\:-right-3\.5 {
    right: -0.875rem;
  }
  .md\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .md\:-left-3\.5 {
    left: -0.875rem;
  }
  .md\:top-1\/2 {
    top: 50%;
  }
  .md\:right-1\/2 {
    right: 50%;
  }
  .md\:bottom-1\/2 {
    bottom: 50%;
  }
  .md\:left-1\/2 {
    left: 50%;
  }
  .md\:top-1\/3 {
    top: 33.333333%;
  }
  .md\:right-1\/3 {
    right: 33.333333%;
  }
  .md\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .md\:left-1\/3 {
    left: 33.333333%;
  }
  .md\:top-2\/3 {
    top: 66.666667%;
  }
  .md\:right-2\/3 {
    right: 66.666667%;
  }
  .md\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .md\:left-2\/3 {
    left: 66.666667%;
  }
  .md\:top-1\/4 {
    top: 25%;
  }
  .md\:right-1\/4 {
    right: 25%;
  }
  .md\:bottom-1\/4 {
    bottom: 25%;
  }
  .md\:left-1\/4 {
    left: 25%;
  }
  .md\:top-2\/4 {
    top: 50%;
  }
  .md\:right-2\/4 {
    right: 50%;
  }
  .md\:bottom-2\/4 {
    bottom: 50%;
  }
  .md\:left-2\/4 {
    left: 50%;
  }
  .md\:top-3\/4 {
    top: 75%;
  }
  .md\:right-3\/4 {
    right: 75%;
  }
  .md\:bottom-3\/4 {
    bottom: 75%;
  }
  .md\:left-3\/4 {
    left: 75%;
  }
  .md\:top-full {
    top: 100%;
  }
  .md\:right-full {
    right: 100%;
  }
  .md\:bottom-full {
    bottom: 100%;
  }
  .md\:left-full {
    left: 100%;
  }
  .md\:-top-1\/2 {
    top: -50%;
  }
  .md\:-right-1\/2 {
    right: -50%;
  }
  .md\:-bottom-1\/2 {
    bottom: -50%;
  }
  .md\:-left-1\/2 {
    left: -50%;
  }
  .md\:-top-1\/3 {
    top: -33.333333%;
  }
  .md\:-right-1\/3 {
    right: -33.333333%;
  }
  .md\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .md\:-left-1\/3 {
    left: -33.333333%;
  }
  .md\:-top-2\/3 {
    top: -66.666667%;
  }
  .md\:-right-2\/3 {
    right: -66.666667%;
  }
  .md\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .md\:-left-2\/3 {
    left: -66.666667%;
  }
  .md\:-top-1\/4 {
    top: -25%;
  }
  .md\:-right-1\/4 {
    right: -25%;
  }
  .md\:-bottom-1\/4 {
    bottom: -25%;
  }
  .md\:-left-1\/4 {
    left: -25%;
  }
  .md\:-top-2\/4 {
    top: -50%;
  }
  .md\:-right-2\/4 {
    right: -50%;
  }
  .md\:-bottom-2\/4 {
    bottom: -50%;
  }
  .md\:-left-2\/4 {
    left: -50%;
  }
  .md\:-top-3\/4 {
    top: -75%;
  }
  .md\:-right-3\/4 {
    right: -75%;
  }
  .md\:-bottom-3\/4 {
    bottom: -75%;
  }
  .md\:-left-3\/4 {
    left: -75%;
  }
  .md\:-top-full {
    top: -100%;
  }
  .md\:-right-full {
    right: -100%;
  }
  .md\:-bottom-full {
    bottom: -100%;
  }
  .md\:-left-full {
    left: -100%;
  }
  .md\:resize-none {
    resize: none;
  }
  .md\:resize-y {
    resize: vertical;
  }
  .md\:resize-x {
    resize: horizontal;
  }
  .md\:resize {
    resize: both;
  }
  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .md\:shadow,
  .md\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .md\:shadow-lg,
  .md\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .md\:shadow-2xl,
  .md\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:shadow-inner,
  .md\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .md\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .md\:shadow-bottom-nav,
  .md\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .md\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .md\:group-hover\:shadow-sm,
  .md\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .md\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .md\:group-hover\:shadow,
  .group:hover .md\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .md\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .md\:group-hover\:shadow-lg,
  .group:hover .md\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .md\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .md\:group-hover\:shadow-2xl,
  .group:hover .md\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .md\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .md\:group-hover\:shadow-none,
  .group:hover .md\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .md\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .md\:group-hover\:shadow-bottom-nav,
  .group:hover .md\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .md\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .md\:hover\:shadow-sm:hover,
  .md\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .md\:hover\:shadow-lg:hover,
  .md\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .md\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .md\:hover\:shadow-2xl:hover,
  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .md\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:hover\:shadow-inner:hover,
  .md\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .md\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .md\:hover\:shadow-bottom-nav:hover,
  .md\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .md\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .md\:focus\:shadow-sm:focus,
  .md\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .md\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:focus\:shadow-md:focus,
  .md\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .md\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .md\:focus\:shadow-lg:focus,
  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .md\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .md\:focus\:shadow-2xl:focus,
  .md\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .md\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .md\:focus\:shadow-none:focus,
  .md\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .md\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .md\:focus\:shadow-bottom-nav:focus,
  .md\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .md\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring-0,
  .md\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring-2,
  .md\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring,
  .md\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:ring-inset {
    --tw-ring-inset: inset;
  }
  .md\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .md\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-0:focus,
  .md\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-2:focus,
  .md\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-8:focus,
  .md\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .md\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .md\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .md\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .md\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .md\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .md\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .md\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .md\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .md\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .md\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .md\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .md\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .md\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .md\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .md\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .md\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .md\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .md\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .md\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .md\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .md\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .md\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .md\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .md\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .md\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .md\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .md\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .md\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .md\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .md\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .md\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .md\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .md\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .md\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .md\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .md\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .md\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .md\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .md\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .md\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .md\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .md\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .md\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .md\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .md\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .md\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .md\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .md\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .md\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .md\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .md\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .md\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .md\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .md\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .md\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .md\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .md\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .md\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .md\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .md\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .md\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .md\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .md\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .md\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .md\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .md\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .md\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .md\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .md\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .md\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .md\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .md\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .md\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .md\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .md\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .md\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .md\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .md\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .md\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .md\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .md\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .md\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .md\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .md\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .md\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .md\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .md\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .md\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .md\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .md\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .md\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .md\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .md\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .md\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .md\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .md\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .md\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .md\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .md\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .md\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .md\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .md\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .md\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .md\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .md\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .md\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .md\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .md\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .md\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .md\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .md\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .md\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .md\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .md\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .md\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .md\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .md\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .md\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .md\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .md\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .md\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .md\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .md\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .md\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .md\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .md\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .md\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .md\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .md\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .md\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .md\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .md\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .md\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .md\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .md\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .md\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .md\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .md\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .md\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .md\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .md\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .md\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .md\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .md\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .md\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .md\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .md\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .md\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .md\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .md\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .md\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .md\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .md\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .md\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .md\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .md\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .md\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .md\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .md\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .md\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .md\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .md\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .md\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .md\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .md\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .md\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .md\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .md\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .md\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .md\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .md\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .md\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .md\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .md\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .md\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .md\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .md\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .md\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .md\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .md\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .md\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .md\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .md\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .md\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .md\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .md\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .md\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .md\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .md\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .md\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .md\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .md\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .md\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .md\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .md\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .md\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .md\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .md\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .md\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .md\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .md\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .md\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .md\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .md\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .md\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .md\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .md\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .md\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .md\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .md\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .md\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .md\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .md\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .md\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .md\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .md\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .md\:ring-current {
    --tw-ring-color: currentColor;
  }
  .md\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .md\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .md\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .md\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .md\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .md\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .md\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .md\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .md\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .md\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .md\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .md\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .md\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .md\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .md\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .md\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .md\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .md\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .md\:ring-background {
    --tw-ring-color: var(--background);
  }
  .md\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .md\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .md\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .md\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .md\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .md\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .md\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .md\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .md\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .md\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .md\:ring-success {
    --tw-ring-color: var(--success);
  }
  .md\:ring-error {
    --tw-ring-color: var(--error);
  }
  .md\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .md\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .md\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .md\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .md\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .md\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .md\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .md\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .md\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .md\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .md\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .md\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .md\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .md\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .md\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .md\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .md\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .md\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .md\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .md\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .md\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .md\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .md\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .md\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .md\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .md\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .md\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .md\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .md\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .md\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .md\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .md\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .md\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .md\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .md\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .md\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .md\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .md\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .md\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .md\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .md\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .md\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .md\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .md\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .md\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .md\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .md\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .md\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .md\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .md\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .md\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .md\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .md\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .md\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .md\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .md\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .md\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .md\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .md\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .md\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .md\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .md\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .md\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .md\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .md\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .md\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .md\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .md\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .md\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .md\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .md\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .md\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .md\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .md\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .md\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .md\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .md\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .md\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .md\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .md\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .md\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .md\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .md\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .md\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .md\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .md\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .md\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .md\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .md\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .md\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .md\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .md\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .md\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .md\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .md\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .md\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .md\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .md\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .md\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .md\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .md\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .md\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .md\:fill-current {
    fill: currentColor;
  }
  .md\:stroke-current {
    stroke: currentColor;
  }
  .md\:stroke-0 {
    stroke-width: 0;
  }
  .md\:stroke-1 {
    stroke-width: 1;
  }
  .md\:stroke-2 {
    stroke-width: 2;
  }
  .md\:table-auto {
    table-layout: auto;
  }
  .md\:table-fixed {
    table-layout: fixed;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:text-center {
    text-align: center;
  }
  .md\:text-right {
    text-align: right;
  }
  .md\:text-justify {
    text-align: justify;
  }
  .md\:text-transparent {
    color: transparent;
  }
  .md\:text-current {
    color: currentColor;
  }
  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .md\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:text-background-primary {
    color: var(--background-primary);
  }
  .md\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .md\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .md\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .md\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .md\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .md\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .md\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .md\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .md\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .md\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .md\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .md\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .md\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .md\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .md\:text-body-background {
    color: var(--body-background);
  }
  .md\:text-background {
    color: var(--background);
  }
  .md\:text-input-background {
    color: var(--input-background);
  }
  .md\:text-body-text {
    color: var(--body-text);
  }
  .md\:text-header-text {
    color: var(--header-text);
  }
  .md\:text-primary {
    color: var(--primary);
  }
  .md\:text-secondary {
    color: var(--secondary);
  }
  .md\:text-tertiary {
    color: var(--tertiary);
  }
  .md\:text-primary-hover {
    color: var(--primary-hover);
  }
  .md\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .md\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .md\:text-input-text {
    color: var(--input-text);
  }
  .md\:text-success {
    color: var(--success);
  }
  .md\:text-error {
    color: var(--error);
  }
  .md\:text-custom-loader {
    color: var(--custom-loader);
  }
  .md\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .md\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .md\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .md\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .md\:text-background50 {
    color: var(--background50);
  }
  .md\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .md\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .md\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .md\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .md\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .md\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .md\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .md\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .md\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .md\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .md\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .md\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .md\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .md\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .md\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .md\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .md\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .md\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .md\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .md\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .md\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .md\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .md\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .md\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .md\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .md\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .md\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .md\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .md\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .md\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .md\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .md\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .md\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .md\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .md\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .md\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .md\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .md\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .md\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .md\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .md\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .md\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .md\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .md\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .md\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .md\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .md\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .md\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .md\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .md\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .md\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .md\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .md\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .md\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .md\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .md\:hover\:text-transparent:hover {
    color: transparent;
  }
  .md\:hover\:text-current:hover {
    color: currentColor;
  }
  .md\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .md\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .md\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .md\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .md\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .md\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .md\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .md\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .md\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .md\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .md\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .md\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .md\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .md\:hover\:text-background:hover {
    color: var(--background);
  }
  .md\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .md\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .md\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .md\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .md\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .md\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .md\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .md\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .md\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .md\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .md\:hover\:text-success:hover {
    color: var(--success);
  }
  .md\:hover\:text-error:hover {
    color: var(--error);
  }
  .md\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .md\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .md\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .md\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .md\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .md\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .md\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .md\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .md\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .md\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .md\:focus\:text-transparent:focus {
    color: transparent;
  }
  .md\:focus\:text-current:focus {
    color: currentColor;
  }
  .md\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .md\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .md\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .md\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .md\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .md\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .md\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .md\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .md\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .md\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .md\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .md\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .md\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .md\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .md\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .md\:focus\:text-background:focus {
    color: var(--background);
  }
  .md\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .md\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .md\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .md\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .md\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .md\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .md\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .md\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .md\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .md\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .md\:focus\:text-success:focus {
    color: var(--success);
  }
  .md\:focus\:text-error:focus {
    color: var(--error);
  }
  .md\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .md\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .md\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .md\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .md\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .md\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .md\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .md\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .md\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .md\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .md\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .md\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .md\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .md\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .md\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .md\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .md\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .md\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .md\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .md\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .md\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .md\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .md\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .md\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .md\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .md\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .md\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .md\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .md\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .md\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .md\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .md\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .md\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .md\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .md\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .md\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .md\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .md\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .md\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .md\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .md\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .md\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .md\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .md\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .md\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .md\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .md\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .md\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .md\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .md\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .md\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .md\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .md\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .md\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .md\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .md\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .md\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .md\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .md\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .md\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .md\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .md\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .md\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .md\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .md\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .md\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .md\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .md\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .md\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .md\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .md\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .md\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .md\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .md\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .md\:overflow-ellipsis,
  .md\:truncate {
    text-overflow: ellipsis;
  }
  .md\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .md\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .md\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .md\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .md\:italic {
    font-style: italic;
  }
  .md\:not-italic {
    font-style: normal;
  }
  .md\:uppercase {
    text-transform: uppercase;
  }
  .md\:lowercase {
    text-transform: lowercase;
  }
  .md\:capitalize {
    text-transform: capitalize;
  }
  .md\:normal-case {
    text-transform: none;
  }
  .md\:underline {
    text-decoration: underline;
  }
  .md\:line-through {
    text-decoration: line-through;
  }
  .md\:no-underline {
    text-decoration: none;
  }
  .group:hover .md\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .md\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .md\:group-hover\:no-underline {
    text-decoration: none;
  }
  .md\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .md\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .md\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .md\:hover\:underline:hover {
    text-decoration: underline;
  }
  .md\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .md\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .md\:focus\:underline:focus {
    text-decoration: underline;
  }
  .md\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .md\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .md\:diagonal-fractions,
  .md\:lining-nums,
  .md\:oldstyle-nums,
  .md\:ordinal,
  .md\:proportional-nums,
  .md\:slashed-zero,
  .md\:stacked-fractions,
  .md\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .md\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .md\:ordinal {
    --tw-ordinal: ordinal;
  }
  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .md\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .md\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .md\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .md\:tracking-normal {
    letter-spacing: 0;
  }
  .md\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .md\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .md\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .md\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .md\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .md\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .md\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .md\:align-baseline {
    vertical-align: baseline;
  }
  .md\:align-top {
    vertical-align: top;
  }
  .md\:align-middle {
    vertical-align: middle;
  }
  .md\:align-bottom {
    vertical-align: bottom;
  }
  .md\:align-text-top {
    vertical-align: text-top;
  }
  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .md\:visible {
    visibility: visible;
  }
  .md\:invisible {
    visibility: hidden;
  }
  .md\:whitespace-normal {
    white-space: normal;
  }
  .md\:whitespace-nowrap {
    white-space: nowrap;
  }
  .md\:whitespace-pre {
    white-space: pre;
  }
  .md\:whitespace-pre-line {
    white-space: pre-line;
  }
  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .md\:break-words {
    overflow-wrap: break-word;
  }
  .md\:break-all {
    word-break: break-all;
  }
  .group:hover .md\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .md\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .md\:group-hover\:break-all {
    word-break: break-all;
  }
  .md\:w-0 {
    width: 0;
  }
  .md\:w-1 {
    width: 0.25rem;
  }
  .md\:w-2 {
    width: 0.5rem;
  }
  .md\:w-3 {
    width: 0.75rem;
  }
  .md\:w-4 {
    width: 1rem;
  }
  .md\:w-5 {
    width: 1.25rem;
  }
  .md\:w-6 {
    width: 1.5rem;
  }
  .md\:w-7 {
    width: 1.75rem;
  }
  .md\:w-8 {
    width: 2rem;
  }
  .md\:w-9 {
    width: 2.25rem;
  }
  .md\:w-10 {
    width: 2.5rem;
  }
  .md\:w-11 {
    width: 2.75rem;
  }
  .md\:w-12 {
    width: 3rem;
  }
  .md\:w-14 {
    width: 3.5rem;
  }
  .md\:w-16 {
    width: 4rem;
  }
  .md\:w-20 {
    width: 5rem;
  }
  .md\:w-24 {
    width: 6rem;
  }
  .md\:w-28 {
    width: 7rem;
  }
  .md\:w-32 {
    width: 8rem;
  }
  .md\:w-36 {
    width: 9rem;
  }
  .md\:w-40 {
    width: 10rem;
  }
  .md\:w-44 {
    width: 11rem;
  }
  .md\:w-48 {
    width: 12rem;
  }
  .md\:w-52 {
    width: 13rem;
  }
  .md\:w-56 {
    width: 14rem;
  }
  .md\:w-60 {
    width: 15rem;
  }
  .md\:w-64 {
    width: 16rem;
  }
  .md\:w-72 {
    width: 18rem;
  }
  .md\:w-80 {
    width: 20rem;
  }
  .md\:w-96 {
    width: 24rem;
  }
  .md\:w-150 {
    width: 150px;
  }
  .md\:w-199 {
    width: 199px;
  }
  .md\:w-286 {
    width: 286px;
  }
  .md\:w-512 {
    width: 512px;
  }
  .md\:w-560 {
    width: 560px;
  }
  .md\:w-650 {
    width: 650px;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-px {
    width: 1px;
  }
  .md\:w-0\.5 {
    width: 0.125rem;
  }
  .md\:w-1\.5 {
    width: 0.375rem;
  }
  .md\:w-2\.5 {
    width: 0.625rem;
  }
  .md\:w-3\.5 {
    width: 0.875rem;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:w-1\/3 {
    width: 33.333333%;
  }
  .md\:w-2\/3 {
    width: 66.666667%;
  }
  .md\:w-1\/4 {
    width: 25%;
  }
  .md\:w-2\/4 {
    width: 50%;
  }
  .md\:w-3\/4 {
    width: 75%;
  }
  .md\:w-1\/5 {
    width: 20%;
  }
  .md\:w-2\/5 {
    width: 40%;
  }
  .md\:w-3\/5 {
    width: 60%;
  }
  .md\:w-4\/5 {
    width: 80%;
  }
  .md\:w-1\/6 {
    width: 16.666667%;
  }
  .md\:w-2\/6 {
    width: 33.333333%;
  }
  .md\:w-3\/6 {
    width: 50%;
  }
  .md\:w-4\/6 {
    width: 66.666667%;
  }
  .md\:w-5\/6 {
    width: 83.333333%;
  }
  .md\:w-1\/12 {
    width: 8.333333%;
  }
  .md\:w-2\/12 {
    width: 16.666667%;
  }
  .md\:w-3\/12 {
    width: 25%;
  }
  .md\:w-4\/12 {
    width: 33.333333%;
  }
  .md\:w-5\/12 {
    width: 41.666667%;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:w-8\/12 {
    width: 66.666667%;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:w-10\/12 {
    width: 83.333333%;
  }
  .md\:w-11\/12 {
    width: 91.666667%;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:w-screen {
    width: 100vw;
  }
  .md\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .md\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .md\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .md\:z-0 {
    z-index: 0;
  }
  .md\:z-10 {
    z-index: 10;
  }
  .md\:z-20 {
    z-index: 20;
  }
  .md\:z-30 {
    z-index: 30;
  }
  .md\:z-40 {
    z-index: 40;
  }
  .md\:z-50 {
    z-index: 50;
  }
  .md\:z-auto {
    z-index: auto;
  }
  .md\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .md\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .md\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .md\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .md\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .md\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .md\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .md\:focus\:z-0:focus {
    z-index: 0;
  }
  .md\:focus\:z-10:focus {
    z-index: 10;
  }
  .md\:focus\:z-20:focus {
    z-index: 20;
  }
  .md\:focus\:z-30:focus {
    z-index: 30;
  }
  .md\:focus\:z-40:focus {
    z-index: 40;
  }
  .md\:focus\:z-50:focus {
    z-index: 50;
  }
  .md\:focus\:z-auto:focus {
    z-index: auto;
  }
  .md\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .md\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .md\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .md\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .md\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .md\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .md\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .md\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .md\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .md\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .md\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .md\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .md\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .md\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .md\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .md\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .md\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .md\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .md\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .md\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .md\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .md\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .md\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .md\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .md\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .md\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .md\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .md\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .md\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .md\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .md\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .md\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .md\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .md\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .md\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .md\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .md\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .md\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .md\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .md\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .md\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .md\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .md\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .md\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .md\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .md\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .md\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .md\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .md\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .md\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .md\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .md\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .md\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .md\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .md\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .md\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .md\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .md\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .md\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .md\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .md\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .md\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .md\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .md\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .md\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .md\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .md\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .md\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .md\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .md\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .md\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .md\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .md\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .md\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .md\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .md\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .md\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .md\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .md\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .md\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .md\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .md\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .md\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .md\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .md\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .md\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .md\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .md\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .md\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .md\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .md\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .md\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .md\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .md\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .md\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .md\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .md\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .md\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .md\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .md\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .md\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .md\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .md\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .md\:grid-flow-row {
    grid-auto-flow: row;
  }
  .md\:grid-flow-col {
    grid-auto-flow: column;
  }
  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:grid-cols-none {
    grid-template-columns: none;
  }
  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .md\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .md\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .md\:col-auto {
    grid-column: auto;
  }
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:col-start-1 {
    grid-column-start: 1;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-start-4 {
    grid-column-start: 4;
  }
  .md\:col-start-5 {
    grid-column-start: 5;
  }
  .md\:col-start-6 {
    grid-column-start: 6;
  }
  .md\:col-start-7 {
    grid-column-start: 7;
  }
  .md\:col-start-8 {
    grid-column-start: 8;
  }
  .md\:col-start-9 {
    grid-column-start: 9;
  }
  .md\:col-start-10 {
    grid-column-start: 10;
  }
  .md\:col-start-11 {
    grid-column-start: 11;
  }
  .md\:col-start-12 {
    grid-column-start: 12;
  }
  .md\:col-start-13 {
    grid-column-start: 13;
  }
  .md\:col-start-auto {
    grid-column-start: auto;
  }
  .md\:col-end-1 {
    grid-column-end: 1;
  }
  .md\:col-end-2 {
    grid-column-end: 2;
  }
  .md\:col-end-3 {
    grid-column-end: 3;
  }
  .md\:col-end-4 {
    grid-column-end: 4;
  }
  .md\:col-end-5 {
    grid-column-end: 5;
  }
  .md\:col-end-6 {
    grid-column-end: 6;
  }
  .md\:col-end-7 {
    grid-column-end: 7;
  }
  .md\:col-end-8 {
    grid-column-end: 8;
  }
  .md\:col-end-9 {
    grid-column-end: 9;
  }
  .md\:col-end-10 {
    grid-column-end: 10;
  }
  .md\:col-end-11 {
    grid-column-end: 11;
  }
  .md\:col-end-12 {
    grid-column-end: 12;
  }
  .md\:col-end-13 {
    grid-column-end: 13;
  }
  .md\:col-end-auto {
    grid-column-end: auto;
  }
  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-rows-none {
    grid-template-rows: none;
  }
  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .md\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .md\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .md\:row-auto {
    grid-row: auto;
  }
  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .md\:row-span-full {
    grid-row: 1/-1;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:row-start-2 {
    grid-row-start: 2;
  }
  .md\:row-start-3 {
    grid-row-start: 3;
  }
  .md\:row-start-4 {
    grid-row-start: 4;
  }
  .md\:row-start-5 {
    grid-row-start: 5;
  }
  .md\:row-start-6 {
    grid-row-start: 6;
  }
  .md\:row-start-7 {
    grid-row-start: 7;
  }
  .md\:row-start-auto {
    grid-row-start: auto;
  }
  .md\:row-end-1 {
    grid-row-end: 1;
  }
  .md\:row-end-2 {
    grid-row-end: 2;
  }
  .md\:row-end-3 {
    grid-row-end: 3;
  }
  .md\:row-end-4 {
    grid-row-end: 4;
  }
  .md\:row-end-5 {
    grid-row-end: 5;
  }
  .md\:row-end-6 {
    grid-row-end: 6;
  }
  .md\:row-end-7 {
    grid-row-end: 7;
  }
  .md\:row-end-auto {
    grid-row-end: auto;
  }
  .md\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .md\:transform,
  .md\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .md\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .md\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .md\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .md\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .md\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .md\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .md\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .md\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .md\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .md\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .md\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .md\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .md\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .md\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .md\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .md\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .md\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .md\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .md\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .md\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .md\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .md\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .md\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .md\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .md\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .md\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .md\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .md\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .md\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .md\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .md\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .md\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .md\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .md\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .md\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .md\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .md\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .md\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .md\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .md\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .md\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .md\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .md\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .md\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .md\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .md\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .md\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .md\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .md\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .md\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .md\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .md\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .md\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .md\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .md\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .md\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .md\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .md\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .md\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .md\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .md\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .md\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .md\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .md\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .md\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .md\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .md\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .md\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .md\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .md\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .md\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .md\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .md\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .md\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .md\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .md\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .md\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .md\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .md\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .md\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .md\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .md\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .md\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .md\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .md\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .md\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .md\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .md\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .md\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .md\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .md\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .md\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .md\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .md\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .md\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .md\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .md\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .md\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .md\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .md\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .md\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .md\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .md\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .md\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .md\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .md\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .md\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .md\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .md\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .md\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .md\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .md\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .md\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .md\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .md\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .md\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .md\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .md\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .md\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .md\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .md\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .md\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .md\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .md\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .md\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .md\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .md\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .md\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .md\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .md\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .md\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .md\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .md\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .md\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .md\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .md\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .md\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .md\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .md\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .md\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .md\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .md\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .md\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .md\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .md\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .md\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .md\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .md\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .md\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .md\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .md\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .md\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .md\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .md\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .md\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .md\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .md\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .md\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .md\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .md\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .md\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .md\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .md\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .md\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .md\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .md\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .md\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .md\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .md\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .md\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .md\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .md\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .md\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .md\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .md\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .md\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .md\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .md\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .md\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .md\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .md\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .md\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .md\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .md\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .md\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .md\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .md\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .md\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .md\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .md\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .md\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .md\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .md\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .md\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .md\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .md\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .md\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .md\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .md\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .md\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .md\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .md\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .md\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .md\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .md\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .md\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .md\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .md\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .md\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .md\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .md\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .md\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .md\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .md\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .md\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .md\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .md\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .md\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .md\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .md\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .md\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .md\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .md\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .md\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .md\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .md\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .md\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .md\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .md\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .md\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .md\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .md\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .md\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .md\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .md\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .md\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .md\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .md\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .md\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .md\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .md\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .md\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .md\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .md\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .md\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .md\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .md\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .md\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .md\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .md\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .md\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .md\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .md\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .md\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .md\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .md\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .md\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .md\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .md\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .md\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .md\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .md\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .md\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .md\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .md\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .md\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .md\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .md\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .md\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .md\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .md\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .md\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .md\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .md\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .md\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .md\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .md\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .md\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .md\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .md\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .md\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .md\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .md\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .md\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .md\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .md\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .md\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .md\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .md\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .md\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .md\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .md\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .md\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .md\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .md\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .md\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .md\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .md\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .md\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .md\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .md\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .md\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .md\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .md\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .md\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .md\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .md\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .md\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .md\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .md\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .md\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .md\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .md\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .md\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .md\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .md\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .md\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .md\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .md\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .md\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .md\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .md\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .md\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .md\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .md\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .md\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .md\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .md\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .md\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .md\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .md\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .md\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .md\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .md\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .md\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .md\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .md\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .md\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .md\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .md\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .md\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .md\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .md\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .md\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .md\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .md\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .md\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .md\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .md\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .md\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .md\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .md\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .md\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .md\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .md\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .md\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .md\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .md\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .md\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .md\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .md\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .md\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .md\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .md\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .md\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .md\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .md\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .md\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .md\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .md\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .md\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .md\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .md\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .md\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .md\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .md\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .md\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .md\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .md\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .md\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .md\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .md\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .md\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .md\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .md\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .md\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .md\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .md\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .md\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .md\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .md\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .md\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .md\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .md\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .md\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .md\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .md\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .md\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .md\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .md\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .md\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .md\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .md\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .md\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .md\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .md\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .md\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .md\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .md\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .md\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .md\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .md\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .md\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .md\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .md\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .md\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .md\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .md\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .md\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .md\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .md\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .md\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .md\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .md\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .md\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .md\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .md\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .md\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .md\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .md\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .md\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .md\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .md\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .md\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .md\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .md\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .md\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .md\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .md\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .md\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .md\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .md\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .md\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .md\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .md\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .md\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .md\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .md\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .md\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .md\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .md\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .md\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .md\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .md\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .md\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .md\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .md\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .md\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .md\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .md\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .md\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .md\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .md\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .md\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .md\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .md\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .md\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .md\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .md\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .md\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .md\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .md\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .md\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .md\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .md\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .md\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .md\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .md\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .md\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .md\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .md\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .md\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .md\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .md\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .md\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .md\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .md\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .md\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .md\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .md\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .md\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .md\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .md\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .md\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .md\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .md\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .md\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .md\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .md\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .md\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .md\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .md\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .md\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .md\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .md\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .md\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .md\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .md\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .md\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .md\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .md\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .md\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .md\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .md\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .md\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .md\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .md\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .md\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .md\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .md\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .md\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .md\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .md\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .md\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .md\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .md\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .md\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .md\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .md\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .md\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .md\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .md\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .md\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .md\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .md\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .md\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .md\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .md\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .md\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .md\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .md\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .md\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .md\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .md\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .md\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .md\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .md\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .md\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .md\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .md\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .md\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .md\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .md\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .md\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .md\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .md\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .md\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .md\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .md\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .md\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .md\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .md\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .md\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .md\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .md\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .md\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .md\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .md\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .md\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .md\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .md\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .md\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .md\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .md\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .md\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .md\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .md\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .md\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .md\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .md\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .md\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .md\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .md\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .md\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .md\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .md\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .md\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .md\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .md\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .md\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .md\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .md\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .md\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .md\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .md\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .md\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .md\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .md\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .md\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .md\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .md\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .md\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .md\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .md\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .md\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .md\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .md\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .md\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .md\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .md\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .md\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .md\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .md\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .md\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .md\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .md\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .md\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .md\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .md\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .md\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .md\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .md\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .md\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .md\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .md\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .md\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .md\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .md\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .md\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .md\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .md\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .md\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .md\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .md\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .md\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .md\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .md\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .md\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .md\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .md\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .md\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .md\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .md\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .md\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .md\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .md\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .md\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .md\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .md\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .md\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .md\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .md\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .md\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .md\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .md\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .md\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .md\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .md\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .md\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .md\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .md\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .md\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .md\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .md\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .md\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .md\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .md\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .md\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .md\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .md\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .md\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .md\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .md\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .md\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .md\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .md\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .md\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .md\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .md\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .md\:transition-none {
    transition-property: none;
  }
  .md\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .md\:ease-linear {
    transition-timing-function: linear;
  }
  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .md\:duration-75 {
    transition-duration: 75ms;
  }
  .md\:duration-100 {
    transition-duration: 0.1s;
  }
  .md\:duration-150 {
    transition-duration: 0.15s;
  }
  .md\:duration-200 {
    transition-duration: 0.2s;
  }
  .md\:duration-300 {
    transition-duration: 0.3s;
  }
  .md\:duration-500 {
    transition-duration: 0.5s;
  }
  .md\:duration-700 {
    transition-duration: 0.7s;
  }
  .md\:duration-1000 {
    transition-duration: 1s;
  }
  .md\:delay-75 {
    transition-delay: 75ms;
  }
  .md\:delay-100 {
    transition-delay: 0.1s;
  }
  .md\:delay-150 {
    transition-delay: 0.15s;
  }
  .md\:delay-200 {
    transition-delay: 0.2s;
  }
  .md\:delay-300 {
    transition-delay: 0.3s;
  }
  .md\:delay-500 {
    transition-delay: 0.5s;
  }
  .md\:delay-700 {
    transition-delay: 0.7s;
  }
  .md\:delay-1000 {
    transition-delay: 1s;
  }
  .md\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .md\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .md\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .md\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .md\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .lg\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .lg\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .lg\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }
  .lg\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .lg\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .lg\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .lg\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .lg\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .lg\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .lg\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .lg\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .lg\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .lg\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .lg\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .lg\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .lg\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .lg\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .lg\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .lg\:prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .lg\:prose h3,
  .lg\:prose h4 {
    color: #6d728b;
    font-weight: 600;
  }
  .lg\:prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .lg\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .lg\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .lg\:prose code:after,
  .lg\:prose code:before {
    content: '`';
  }
  .lg\:prose a code {
    color: #6d728b;
  }
  .lg\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .lg\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .lg\:prose pre code:after,
  .lg\:prose pre code:before {
    content: '';
  }
  .lg\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .lg\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .lg\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .lg\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .lg\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .lg\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .lg\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .lg\:prose figure,
  .lg\:prose img,
  .lg\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .lg\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:prose h2 code {
    font-size: 0.875em;
  }
  .lg\:prose h3 code {
    font-size: 0.9em;
  }
  .lg\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .lg\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .lg\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .lg\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .lg\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .lg\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .lg\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .lg\:prose ol ol,
  .lg\:prose ol ul,
  .lg\:prose ul ol,
  .lg\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .lg\:prose h2 + *,
  .lg\:prose h3 + *,
  .lg\:prose h4 + *,
  .lg\:prose hr + * {
    margin-top: 0;
  }
  .lg\:prose thead th:first-child {
    padding-left: 0;
  }
  .lg\:prose thead th:last-child {
    padding-right: 0;
  }
  .lg\:prose tbody td:first-child {
    padding-left: 0;
  }
  .lg\:prose tbody td:last-child {
    padding-right: 0;
  }
  .lg\:prose > :first-child {
    margin-top: 0;
  }
  .lg\:prose > :last-child {
    margin-bottom: 0;
  }
  .lg\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .lg\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .lg\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .lg\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .lg\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .lg\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .lg\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .lg\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .lg\:prose-sm figure,
  .lg\:prose-sm img,
  .lg\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .lg\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .lg\:prose-sm code {
    font-size: 0.8571429em;
  }
  .lg\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .lg\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .lg\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .lg\:prose-sm ol,
  .lg\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .lg\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .lg\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .lg\:prose-sm ol > li:before {
    left: 0;
  }
  .lg\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .lg\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .lg\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .lg\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .lg\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .lg\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .lg\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .lg\:prose-sm ol ol,
  .lg\:prose-sm ol ul,
  .lg\:prose-sm ul ol,
  .lg\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .lg\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .lg\:prose-sm h2 + *,
  .lg\:prose-sm h3 + *,
  .lg\:prose-sm h4 + *,
  .lg\:prose-sm hr + * {
    margin-top: 0;
  }
  .lg\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .lg\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .lg\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .lg\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .lg\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .lg\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .lg\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .lg\:prose-sm > :first-child {
    margin-top: 0;
  }
  .lg\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .lg\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .lg\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .lg\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .lg\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .lg\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .lg\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .lg\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .lg\:prose-lg figure,
  .lg\:prose-lg img,
  .lg\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .lg\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .lg\:prose-lg code {
    font-size: 0.8888889em;
  }
  .lg\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .lg\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .lg\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .lg\:prose-lg ol,
  .lg\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .lg\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .lg\:prose-lg ol > li:before {
    left: 0;
  }
  .lg\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .lg\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .lg\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .lg\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .lg\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .lg\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-lg ol ol,
  .lg\:prose-lg ol ul,
  .lg\:prose-lg ul ol,
  .lg\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .lg\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .lg\:prose-lg h2 + *,
  .lg\:prose-lg h3 + *,
  .lg\:prose-lg h4 + *,
  .lg\:prose-lg hr + * {
    margin-top: 0;
  }
  .lg\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .lg\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .lg\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .lg\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .lg\:prose-lg tbody td {
    padding: 0.75em;
  }
  .lg\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .lg\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .lg\:prose-lg > :first-child {
    margin-top: 0;
  }
  .lg\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .lg\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .lg\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .lg\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .lg\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .lg\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .lg\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .lg\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .lg\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .lg\:prose-xl figure,
  .lg\:prose-xl img,
  .lg\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .lg\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .lg\:prose-xl code {
    font-size: 0.9em;
  }
  .lg\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .lg\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .lg\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .lg\:prose-xl ol,
  .lg\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .lg\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .lg\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .lg\:prose-xl ol > li:before {
    left: 0;
  }
  .lg\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .lg\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .lg\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .lg\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .lg\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .lg\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .lg\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .lg\:prose-xl ol ol,
  .lg\:prose-xl ol ul,
  .lg\:prose-xl ul ol,
  .lg\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .lg\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .lg\:prose-xl h2 + *,
  .lg\:prose-xl h3 + *,
  .lg\:prose-xl h4 + *,
  .lg\:prose-xl hr + * {
    margin-top: 0;
  }
  .lg\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .lg\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .lg\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .lg\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .lg\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .lg\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .lg\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .lg\:prose-xl > :first-child {
    margin-top: 0;
  }
  .lg\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .lg\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .lg\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .lg\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .lg\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .lg\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .lg\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .lg\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .lg\:prose-2xl figure,
  .lg\:prose-2xl img,
  .lg\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .lg\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .lg\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .lg\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .lg\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .lg\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .lg\:prose-2xl ol,
  .lg\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .lg\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .lg\:prose-2xl ol > li:before {
    left: 0;
  }
  .lg\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .lg\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .lg\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .lg\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .lg\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .lg\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .lg\:prose-2xl ol ol,
  .lg\:prose-2xl ol ul,
  .lg\:prose-2xl ul ol,
  .lg\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .lg\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .lg\:prose-2xl h2 + *,
  .lg\:prose-2xl h3 + *,
  .lg\:prose-2xl h4 + *,
  .lg\:prose-2xl hr + * {
    margin-top: 0;
  }
  .lg\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .lg\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .lg\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .lg\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .lg\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .lg\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .lg\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .lg\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .lg\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .lg\:aspect-w-1,
  .lg\:aspect-w-2,
  .lg\:aspect-w-3,
  .lg\:aspect-w-4,
  .lg\:aspect-w-5,
  .lg\:aspect-w-6,
  .lg\:aspect-w-7,
  .lg\:aspect-w-8,
  .lg\:aspect-w-9,
  .lg\:aspect-w-10,
  .lg\:aspect-w-11,
  .lg\:aspect-w-12,
  .lg\:aspect-w-13,
  .lg\:aspect-w-14,
  .lg\:aspect-w-15,
  .lg\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .lg\:aspect-w-1 > *,
  .lg\:aspect-w-2 > *,
  .lg\:aspect-w-3 > *,
  .lg\:aspect-w-4 > *,
  .lg\:aspect-w-5 > *,
  .lg\:aspect-w-6 > *,
  .lg\:aspect-w-7 > *,
  .lg\:aspect-w-8 > *,
  .lg\:aspect-w-9 > *,
  .lg\:aspect-w-10 > *,
  .lg\:aspect-w-11 > *,
  .lg\:aspect-w-12 > *,
  .lg\:aspect-w-13 > *,
  .lg\:aspect-w-14 > *,
  .lg\:aspect-w-15 > *,
  .lg\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lg\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .lg\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .lg\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .lg\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .lg\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .lg\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .lg\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .lg\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .lg\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .lg\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .lg\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .lg\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .lg\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .lg\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .lg\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .lg\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .lg\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .lg\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .lg\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .lg\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .lg\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .lg\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .lg\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .lg\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .lg\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .lg\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .lg\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .lg\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .lg\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .lg\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .lg\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .lg\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .lg\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .lg\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .lg\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .lg\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .lg\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .lg\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .lg\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .lg\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .lg\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .lg\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .lg\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .lg\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .lg\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .lg\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .lg\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .lg\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .lg\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .lg\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .lg\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .lg\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .lg\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .lg\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .lg\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .lg\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .lg\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .lg\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .lg\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .lg\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .lg\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .lg\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .lg\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .lg\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .lg\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .lg\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .lg\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .lg\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .lg\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .lg\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .lg\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .lg\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .lg\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .lg\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .lg\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .lg\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .lg\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .lg\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .lg\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .lg\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .lg\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .lg\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .lg\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .lg\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .lg\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .lg\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .lg\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .lg\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .lg\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .lg\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .lg\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .lg\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .lg\:bg-fixed {
    background-attachment: fixed;
  }
  .lg\:bg-local {
    background-attachment: local;
  }
  .lg\:bg-scroll {
    background-attachment: scroll;
  }
  .lg\:bg-clip-border {
    background-clip: border-box;
  }
  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }
  .lg\:bg-clip-content {
    background-clip: content-box;
  }
  .lg\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .lg\:bg-transparent {
    background-color: transparent;
  }
  .lg\:bg-current {
    background-color: currentColor;
  }
  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .lg\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .lg\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .lg\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .lg\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .lg\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .lg\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .lg\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .lg\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .lg\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .lg\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .lg\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .lg\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .lg\:bg-body-background {
    background-color: var(--body-background);
  }
  .lg\:bg-background {
    background-color: var(--background);
  }
  .lg\:bg-input-background {
    background-color: var(--input-background);
  }
  .lg\:bg-body-text {
    background-color: var(--body-text);
  }
  .lg\:bg-header-text {
    background-color: var(--header-text);
  }
  .lg\:bg-primary {
    background-color: var(--primary);
  }
  .lg\:bg-secondary {
    background-color: var(--secondary);
  }
  .lg\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .lg\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .lg\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .lg\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .lg\:bg-input-text {
    background-color: var(--input-text);
  }
  .lg\:bg-success {
    background-color: var(--success);
  }
  .lg\:bg-error {
    background-color: var(--error);
  }
  .lg\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .lg\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .lg\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .lg\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .lg\:bg-background50 {
    background-color: var(--background50);
  }
  .lg\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .lg\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .lg\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .lg\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .lg\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .lg\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .lg\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .lg\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .lg\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .lg\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .lg\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .lg\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .lg\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .lg\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .lg\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .lg\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .lg\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .lg\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .lg\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .lg\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .lg\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .lg\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .lg\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .lg\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .lg\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .lg\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .lg\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .lg\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .lg\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .lg\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .lg\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .lg\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .lg\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .lg\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .lg\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .lg\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .lg\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .lg\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .lg\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .lg\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .lg\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .lg\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .lg\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .lg\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .lg\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .lg\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .lg\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .lg\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .lg\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .lg\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .lg\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .lg\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .lg\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .lg\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .lg\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .lg\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .lg\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .lg\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .lg\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .lg\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .lg\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .lg\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .lg\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .lg\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .lg\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .lg\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .lg\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .lg\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .lg\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .lg\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .lg\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .lg\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .lg\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .lg\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .lg\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .lg\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .lg\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .lg\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .lg\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .lg\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .lg\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .lg\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .lg\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .lg\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .lg\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .lg\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .lg\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .lg\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .lg\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .lg\:bg-none {
    background-image: none;
  }
  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .lg\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .lg\:from-background,
  .lg\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-background {
    --tw-gradient-from: var(--background);
  }
  .lg\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .lg\:from-body-text,
  .lg\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .lg\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .lg\:from-header-text,
  .lg\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .lg\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .lg\:from-secondary,
  .lg\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .lg\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .lg\:from-primary-hover,
  .lg\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .lg\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .lg\:from-input-text,
  .lg\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .lg\:from-success {
    --tw-gradient-from: var(--success);
  }
  .lg\:from-error,
  .lg\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-error {
    --tw-gradient-from: var(--error);
  }
  .lg\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .lg\:from-skeleton-loader-from,
  .lg\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .lg\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .lg\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .lg\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .lg\:to-current {
    --tw-gradient-to: currentColor;
  }
  .lg\:to-black {
    --tw-gradient-to: #000;
  }
  .lg\:to-white {
    --tw-gradient-to: #fff;
  }
  .lg\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .lg\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .lg\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .lg\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .lg\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .lg\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .lg\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .lg\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .lg\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .lg\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .lg\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .lg\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .lg\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .lg\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .lg\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .lg\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .lg\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .lg\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .lg\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .lg\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .lg\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .lg\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .lg\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .lg\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .lg\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .lg\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .lg\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .lg\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .lg\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .lg\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .lg\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .lg\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .lg\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .lg\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .lg\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .lg\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .lg\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .lg\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .lg\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .lg\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .lg\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .lg\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .lg\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .lg\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .lg\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .lg\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .lg\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .lg\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .lg\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .lg\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .lg\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .lg\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .lg\:to-background {
    --tw-gradient-to: var(--background);
  }
  .lg\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .lg\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .lg\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .lg\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .lg\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .lg\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .lg\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .lg\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .lg\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .lg\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .lg\:to-success {
    --tw-gradient-to: var(--success);
  }
  .lg\:to-error {
    --tw-gradient-to: var(--error);
  }
  .lg\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .lg\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .lg\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .lg\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .lg\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .lg\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .lg\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .lg\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .lg\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .lg\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .lg\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .lg\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .lg\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .lg\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .lg\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .lg\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .lg\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .lg\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .lg\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .lg\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .lg\:hover\:from-background:hover,
  .lg\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .lg\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .lg\:hover\:from-body-text:hover,
  .lg\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .lg\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .lg\:hover\:from-header-text:hover,
  .lg\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .lg\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .lg\:hover\:from-secondary:hover,
  .lg\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .lg\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .lg\:hover\:from-primary-hover:hover,
  .lg\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .lg\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .lg\:hover\:from-input-text:hover,
  .lg\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .lg\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .lg\:hover\:from-error:hover,
  .lg\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .lg\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .lg\:hover\:from-background50:hover,
  .lg\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .lg\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .lg\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .lg\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .lg\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .lg\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .lg\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .lg\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .lg\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .lg\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .lg\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .lg\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .lg\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .lg\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .lg\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .lg\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .lg\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .lg\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .lg\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .lg\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .lg\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .lg\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .lg\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .lg\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .lg\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .lg\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .lg\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .lg\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .lg\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .lg\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .lg\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .lg\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .lg\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .lg\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .lg\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .lg\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .lg\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .lg\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .lg\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .lg\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .lg\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .lg\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .lg\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .lg\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .lg\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .lg\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .lg\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .lg\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .lg\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .lg\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .lg\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .lg\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .lg\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .lg\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .lg\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .lg\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .lg\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .lg\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .lg\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .lg\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .lg\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .lg\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .lg\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .lg\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .lg\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .lg\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .lg\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .lg\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .lg\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .lg\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .lg\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .lg\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .lg\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .lg\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .lg\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .lg\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .lg\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .lg\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .lg\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .lg\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .lg\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .lg\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .lg\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .lg\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .lg\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .lg\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .lg\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .lg\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .lg\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .lg\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .lg\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .lg\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .lg\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .lg\:focus\:from-background:focus,
  .lg\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .lg\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .lg\:focus\:from-body-text:focus,
  .lg\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .lg\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .lg\:focus\:from-header-text:focus,
  .lg\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .lg\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .lg\:focus\:from-secondary:focus,
  .lg\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .lg\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .lg\:focus\:from-primary-hover:focus,
  .lg\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .lg\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .lg\:focus\:from-input-text:focus,
  .lg\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .lg\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .lg\:focus\:from-error:focus,
  .lg\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .lg\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .lg\:focus\:from-background50:focus,
  .lg\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .lg\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .lg\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .lg\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .lg\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .lg\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .lg\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .lg\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .lg\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .lg\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .lg\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .lg\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .lg\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .lg\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .lg\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .lg\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .lg\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .lg\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .lg\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .lg\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .lg\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .lg\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .lg\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .lg\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .lg\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .lg\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .lg\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .lg\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .lg\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .lg\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .lg\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .lg\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .lg\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .lg\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .lg\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .lg\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .lg\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .lg\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .lg\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .lg\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .lg\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .lg\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .lg\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .lg\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .lg\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .lg\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .lg\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .lg\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .lg\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .lg\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .lg\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .lg\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .lg\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .lg\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .lg\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .lg\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .lg\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .lg\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .lg\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .lg\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .lg\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .lg\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .lg\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .lg\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .lg\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .lg\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .lg\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .lg\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .lg\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .lg\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .lg\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .lg\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .lg\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .lg\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .lg\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .lg\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .lg\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .lg\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .lg\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .lg\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .lg\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .lg\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .lg\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .lg\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .lg\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .lg\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .lg\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .lg\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .lg\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .lg\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .lg\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .lg\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .lg\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .lg\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .lg\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .lg\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .lg\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .lg\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .lg\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .lg\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .lg\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .lg\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .lg\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .lg\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .lg\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .lg\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .lg\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .lg\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .lg\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .lg\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .lg\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .lg\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .lg\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .lg\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .lg\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .lg\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .lg\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .lg\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .lg\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .lg\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .lg\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .lg\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .lg\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .lg\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .lg\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .lg\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .lg\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .lg\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .lg\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .lg\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .lg\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .lg\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .lg\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .lg\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .lg\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .lg\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .lg\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .lg\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .lg\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .lg\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .lg\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .lg\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .lg\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .lg\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .lg\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .lg\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .lg\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .lg\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .lg\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .lg\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .lg\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .lg\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .lg\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .lg\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .lg\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .lg\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .lg\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .lg\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .lg\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .lg\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .lg\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .lg\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .lg\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .lg\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .lg\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .lg\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .lg\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .lg\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .lg\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .lg\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .lg\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .lg\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .lg\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .lg\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .lg\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .lg\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .lg\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .lg\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .lg\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .lg\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .lg\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .lg\:bg-bottom {
    background-position: bottom;
  }
  .lg\:bg-center {
    background-position: 50%;
  }
  .lg\:bg-left {
    background-position: 0;
  }
  .lg\:bg-left-bottom {
    background-position: 0 100%;
  }
  .lg\:bg-left-top {
    background-position: 0 0;
  }
  .lg\:bg-right {
    background-position: 100%;
  }
  .lg\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .lg\:bg-right-top {
    background-position: 100% 0;
  }
  .lg\:bg-top {
    background-position: top;
  }
  .lg\:bg-repeat {
    background-repeat: repeat;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .lg\:bg-repeat-round {
    background-repeat: round;
  }
  .lg\:bg-repeat-space {
    background-repeat: space;
  }
  .lg\:bg-auto {
    background-size: auto;
  }
  .lg\:bg-cover {
    background-size: cover;
  }
  .lg\:bg-contain {
    background-size: contain;
  }
  .lg\:border-collapse {
    border-collapse: collapse;
  }
  .lg\:border-separate {
    border-collapse: separate;
  }
  .lg\:border-transparent {
    border-color: transparent;
  }
  .lg\:border-current {
    border-color: currentColor;
  }
  .lg\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .lg\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:border-background-primary {
    border-color: var(--background-primary);
  }
  .lg\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .lg\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .lg\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .lg\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .lg\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .lg\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .lg\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .lg\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .lg\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .lg\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .lg\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .lg\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .lg\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .lg\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .lg\:border-body-background {
    border-color: var(--body-background);
  }
  .lg\:border-background {
    border-color: var(--background);
  }
  .lg\:border-input-background {
    border-color: var(--input-background);
  }
  .lg\:border-body-text {
    border-color: var(--body-text);
  }
  .lg\:border-header-text {
    border-color: var(--header-text);
  }
  .lg\:border-primary {
    border-color: var(--primary);
  }
  .lg\:border-secondary {
    border-color: var(--secondary);
  }
  .lg\:border-tertiary {
    border-color: var(--tertiary);
  }
  .lg\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .lg\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .lg\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .lg\:border-input-text {
    border-color: var(--input-text);
  }
  .lg\:border-success {
    border-color: var(--success);
  }
  .lg\:border-error {
    border-color: var(--error);
  }
  .lg\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .lg\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .lg\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .lg\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .lg\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .lg\:border-background50 {
    border-color: var(--background50);
  }
  .lg\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .lg\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .lg\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .lg\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .lg\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .lg\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .lg\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .lg\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .lg\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .lg\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .lg\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .lg\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .lg\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .lg\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .lg\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .lg\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .lg\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .lg\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .lg\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .lg\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .lg\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .lg\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .lg\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .lg\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .lg\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .lg\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .lg\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .lg\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .lg\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .lg\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .lg\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .lg\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .lg\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .lg\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .lg\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .lg\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .lg\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .lg\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .lg\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .lg\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .lg\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .lg\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .lg\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .lg\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .lg\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .lg\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .lg\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .lg\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .lg\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .lg\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .lg\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .lg\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .lg\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .lg\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .lg\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .lg\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .lg\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .lg\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .lg\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .lg\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .lg\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .lg\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .lg\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .lg\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .lg\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .lg\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .lg\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .lg\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .lg\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .lg\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .lg\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .lg\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .lg\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .lg\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .lg\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .lg\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .lg\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .lg\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .lg\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .lg\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .lg\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .lg\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .lg\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .lg\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .lg\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .lg\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .lg\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .lg\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .lg\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .lg\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .lg\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .lg\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .lg\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .lg\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .lg\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .lg\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .lg\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .lg\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .lg\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .lg\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .lg\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .lg\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .lg\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .lg\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .lg\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .lg\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .lg\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .lg\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .lg\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .lg\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .lg\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .lg\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .lg\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .lg\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .lg\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .lg\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .lg\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .lg\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .lg\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .lg\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .lg\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .lg\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .lg\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .lg\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .lg\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .lg\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .lg\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .lg\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .lg\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .lg\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .lg\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .lg\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .lg\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .lg\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .lg\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .lg\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .lg\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .lg\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .lg\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .lg\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .lg\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .lg\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .lg\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .lg\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .lg\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .lg\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .lg\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .lg\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .lg\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .lg\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .lg\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .lg\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .lg\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .lg\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .lg\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .lg\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .lg\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .lg\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .lg\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .lg\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .lg\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .lg\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .lg\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .lg\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .lg\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .lg\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .lg\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .lg\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .lg\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .lg\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .lg\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .lg\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .lg\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .lg\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .lg\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .lg\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .lg\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .lg\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .lg\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .lg\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .lg\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .lg\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .lg\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .lg\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .lg\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .lg\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .lg\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .lg\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .lg\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .lg\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .lg\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .lg\:rounded-none {
    border-radius: 0;
  }
  .lg\:rounded-sm {
    border-radius: 0.125rem;
  }
  .lg\:rounded {
    border-radius: 0.25rem;
  }
  .lg\:rounded-md {
    border-radius: 0.375rem;
  }
  .lg\:rounded-lg {
    border-radius: 0.5rem;
  }
  .lg\:rounded-xl {
    border-radius: 0.75rem;
  }
  .lg\:rounded-2xl {
    border-radius: 1rem;
  }
  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .lg\:rounded-full {
    border-radius: 9999px;
  }
  .lg\:rounded-card {
    border-radius: 20px;
  }
  .lg\:rounded-time-item {
    border-radius: 100px;
  }
  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-t {
    border-top-left-radius: 0.25rem;
  }
  .lg\:rounded-r,
  .lg\:rounded-t {
    border-top-right-radius: 0.25rem;
  }
  .lg\:rounded-b,
  .lg\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .lg\:rounded-b,
  .lg\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .lg\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .lg\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .lg\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .lg\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .lg\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .lg\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .lg\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .lg\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .lg\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .lg\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .lg\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .lg\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .lg\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .lg\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .lg\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .lg\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .lg\:border-solid {
    border-style: solid;
  }
  .lg\:border-dashed {
    border-style: dashed;
  }
  .lg\:border-dotted {
    border-style: dotted;
  }
  .lg\:border-double {
    border-style: double;
  }
  .lg\:border-none {
    border-style: none;
  }
  .lg\:border-0 {
    border-width: 0;
  }
  .lg\:border-2 {
    border-width: 2px;
  }
  .lg\:border-4 {
    border-width: 4px;
  }
  .lg\:border-8 {
    border-width: 8px;
  }
  .lg\:border {
    border-width: 1px;
  }
  .lg\:border-t-0 {
    border-top-width: 0;
  }
  .lg\:border-r-0 {
    border-right-width: 0;
  }
  .lg\:border-b-0 {
    border-bottom-width: 0;
  }
  .lg\:border-l-0 {
    border-left-width: 0;
  }
  .lg\:border-t-2 {
    border-top-width: 2px;
  }
  .lg\:border-r-2 {
    border-right-width: 2px;
  }
  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }
  .lg\:border-l-2 {
    border-left-width: 2px;
  }
  .lg\:border-t-4 {
    border-top-width: 4px;
  }
  .lg\:border-r-4 {
    border-right-width: 4px;
  }
  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }
  .lg\:border-l-4 {
    border-left-width: 4px;
  }
  .lg\:border-t-8 {
    border-top-width: 8px;
  }
  .lg\:border-r-8 {
    border-right-width: 8px;
  }
  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }
  .lg\:border-l-8 {
    border-left-width: 8px;
  }
  .lg\:border-t {
    border-top-width: 1px;
  }
  .lg\:border-r {
    border-right-width: 1px;
  }
  .lg\:border-b {
    border-bottom-width: 1px;
  }
  .lg\:border-l {
    border-left-width: 1px;
  }
  .lg\:box-border {
    box-sizing: border-box;
  }
  .lg\:box-content {
    box-sizing: content-box;
  }
  .lg\:cursor-auto {
    cursor: auto;
  }
  .lg\:cursor-default {
    cursor: default;
  }
  .lg\:cursor-pointer {
    cursor: pointer;
  }
  .lg\:cursor-wait {
    cursor: wait;
  }
  .lg\:cursor-text {
    cursor: text;
  }
  .lg\:cursor-move {
    cursor: move;
  }
  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .lg\:block {
    display: block;
  }
  .lg\:inline-block {
    display: inline-block;
  }
  .lg\:inline {
    display: inline;
  }
  .lg\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .lg\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .lg\:table {
    display: table;
  }
  .lg\:table-caption {
    display: table-caption;
  }
  .lg\:table-cell {
    display: table-cell;
  }
  .lg\:table-column {
    display: table-column;
  }
  .lg\:table-column-group {
    display: table-column-group;
  }
  .lg\:table-footer-group {
    display: table-footer-group;
  }
  .lg\:table-header-group {
    display: table-header-group;
  }
  .lg\:table-row-group {
    display: table-row-group;
  }
  .lg\:table-row {
    display: table-row;
  }
  .lg\:flow-root {
    display: flow-root;
  }
  .lg\:grid {
    display: grid;
  }
  .lg\:inline-grid {
    display: inline-grid;
  }
  .lg\:contents {
    display: contents;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .lg\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .lg\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .lg\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .lg\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .lg\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .lg\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .lg\:place-items-auto {
    place-items: auto;
  }
  .lg\:place-items-start {
    place-items: start;
  }
  .lg\:place-items-end {
    place-items: end;
  }
  .lg\:place-items-center {
    place-items: center;
  }
  .lg\:place-items-stretch {
    place-items: stretch;
  }
  .lg\:place-content-center {
    place-content: center;
  }
  .lg\:place-content-start {
    place-content: start;
  }
  .lg\:place-content-end {
    place-content: end;
  }
  .lg\:place-content-between {
    place-content: space-between;
  }
  .lg\:place-content-around {
    place-content: space-around;
  }
  .lg\:place-content-evenly {
    place-content: space-evenly;
  }
  .lg\:place-content-stretch {
    place-content: stretch;
  }
  .lg\:place-self-auto {
    place-self: auto;
  }
  .lg\:place-self-start {
    place-self: start;
  }
  .lg\:place-self-end {
    place-self: end;
  }
  .lg\:place-self-center {
    place-self: center;
  }
  .lg\:place-self-stretch {
    place-self: stretch;
  }
  .lg\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .lg\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .lg\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .lg\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .lg\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .lg\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .lg\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .lg\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .lg\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .lg\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .lg\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .lg\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .lg\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .lg\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .lg\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .lg\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .lg\:justify-items-auto {
    justify-items: auto;
  }
  .lg\:justify-items-start {
    justify-items: start;
  }
  .lg\:justify-items-end {
    justify-items: end;
  }
  .lg\:justify-items-center {
    justify-items: center;
  }
  .lg\:justify-items-stretch {
    justify-items: stretch;
  }
  .lg\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .lg\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .lg\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .lg\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .lg\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .lg\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .lg\:justify-self-auto {
    justify-self: auto;
  }
  .lg\:justify-self-start {
    justify-self: start;
  }
  .lg\:justify-self-end {
    justify-self: end;
  }
  .lg\:justify-self-center {
    justify-self: center;
  }
  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
  .lg\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .lg\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .lg\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .lg\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .lg\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .lg\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .lg\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .lg\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .lg\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .lg\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .lg\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .lg\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .lg\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .lg\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .lg\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .lg\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .lg\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .lg\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .lg\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .lg\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .lg\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .lg\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .lg\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .lg\:float-right {
    float: right;
  }
  .lg\:float-left {
    float: left;
  }
  .lg\:float-none {
    float: none;
  }
  .lg\:clear-left {
    clear: left;
  }
  .lg\:clear-right {
    clear: right;
  }
  .lg\:clear-both {
    clear: both;
  }
  .lg\:clear-none {
    clear: none;
  }
  .lg\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .lg\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .lg\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .lg\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .lg\:font-text {
    font-family: Bozon, sans-serif;
  }
  .lg\:font-thin {
    font-weight: 100;
  }
  .lg\:font-extralight {
    font-weight: 200;
  }
  .lg\:font-light {
    font-weight: 300;
  }
  .lg\:font-normal {
    font-weight: 400;
  }
  .lg\:font-medium {
    font-weight: 500;
  }
  .lg\:font-semibold {
    font-weight: 600;
  }
  .lg\:font-bold {
    font-weight: 700;
  }
  .lg\:font-extrabold {
    font-weight: 800;
  }
  .lg\:font-black {
    font-weight: 900;
  }
  .lg\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .lg\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .lg\:hover\:font-light:hover {
    font-weight: 300;
  }
  .lg\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .lg\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .lg\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .lg\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .lg\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .lg\:hover\:font-black:hover {
    font-weight: 900;
  }
  .lg\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .lg\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .lg\:focus\:font-light:focus {
    font-weight: 300;
  }
  .lg\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .lg\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .lg\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .lg\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .lg\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .lg\:focus\:font-black:focus {
    font-weight: 900;
  }
  .lg\:h-0 {
    height: 0;
  }
  .lg\:h-1 {
    height: 0.25rem;
  }
  .lg\:h-2 {
    height: 0.5rem;
  }
  .lg\:h-3 {
    height: 0.75rem;
  }
  .lg\:h-4 {
    height: 1rem;
  }
  .lg\:h-5 {
    height: 1.25rem;
  }
  .lg\:h-6 {
    height: 1.5rem;
  }
  .lg\:h-7 {
    height: 1.75rem;
  }
  .lg\:h-8 {
    height: 2rem;
  }
  .lg\:h-9 {
    height: 2.25rem;
  }
  .lg\:h-10 {
    height: 2.5rem;
  }
  .lg\:h-11 {
    height: 2.75rem;
  }
  .lg\:h-12 {
    height: 3rem;
  }
  .lg\:h-14 {
    height: 3.5rem;
  }
  .lg\:h-16 {
    height: 4rem;
  }
  .lg\:h-20 {
    height: 5rem;
  }
  .lg\:h-24 {
    height: 6rem;
  }
  .lg\:h-28 {
    height: 7rem;
  }
  .lg\:h-32 {
    height: 8rem;
  }
  .lg\:h-36 {
    height: 9rem;
  }
  .lg\:h-40 {
    height: 10rem;
  }
  .lg\:h-44 {
    height: 11rem;
  }
  .lg\:h-48 {
    height: 12rem;
  }
  .lg\:h-52 {
    height: 13rem;
  }
  .lg\:h-56 {
    height: 14rem;
  }
  .lg\:h-60 {
    height: 15rem;
  }
  .lg\:h-64 {
    height: 16rem;
  }
  .lg\:h-72 {
    height: 18rem;
  }
  .lg\:h-80 {
    height: 20rem;
  }
  .lg\:h-96 {
    height: 24rem;
  }
  .lg\:h-105 {
    height: 105px;
  }
  .lg\:h-188 {
    height: 188px;
  }
  .lg\:h-344 {
    height: 344px;
  }
  .lg\:h-425 {
    height: 425px;
  }
  .lg\:h-627 {
    height: 627px;
  }
  .lg\:h-auto {
    height: auto;
  }
  .lg\:h-px {
    height: 1px;
  }
  .lg\:h-0\.5 {
    height: 0.125rem;
  }
  .lg\:h-1\.5 {
    height: 0.375rem;
  }
  .lg\:h-2\.5 {
    height: 0.625rem;
  }
  .lg\:h-3\.5 {
    height: 0.875rem;
  }
  .lg\:h-1\/2 {
    height: 50%;
  }
  .lg\:h-1\/3 {
    height: 33.333333%;
  }
  .lg\:h-2\/3 {
    height: 66.666667%;
  }
  .lg\:h-1\/4 {
    height: 25%;
  }
  .lg\:h-2\/4 {
    height: 50%;
  }
  .lg\:h-3\/4 {
    height: 75%;
  }
  .lg\:h-1\/5 {
    height: 20%;
  }
  .lg\:h-2\/5 {
    height: 40%;
  }
  .lg\:h-3\/5 {
    height: 60%;
  }
  .lg\:h-4\/5 {
    height: 80%;
  }
  .lg\:h-1\/6 {
    height: 16.666667%;
  }
  .lg\:h-2\/6 {
    height: 33.333333%;
  }
  .lg\:h-3\/6 {
    height: 50%;
  }
  .lg\:h-4\/6 {
    height: 66.666667%;
  }
  .lg\:h-5\/6 {
    height: 83.333333%;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:h-screen {
    height: 100vh;
  }
  .lg\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .lg\:text-base {
    font-size: 1em;
  }
  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .lg\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .lg\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .lg\:text-m-sm {
    font-size: 0.8em;
  }
  .lg\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .lg\:text-m-xl {
    font-size: 1.2em;
  }
  .lg\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .lg\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .lg\:text-m-4xl {
    font-size: 1.6em;
  }
  .lg\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .lg\:text-d-sm {
    font-size: 0.875em;
  }
  .lg\:text-d-lg {
    font-size: 1.125em;
  }
  .lg\:text-d-xl {
    font-size: 1.375em;
  }
  .lg\:text-d-2xl {
    font-size: 1.625em;
  }
  .lg\:text-d-3xl {
    font-size: 1.75em;
  }
  .lg\:text-d-4xl {
    font-size: 2.25em;
  }
  .lg\:text-d-5xl {
    font-size: 2.625em;
  }
  .lg\:text-d-11xl {
    font-size: 16em;
  }
  .lg\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .lg\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .lg\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .lg\:leading-3 {
    line-height: 0.75rem;
  }
  .lg\:leading-4 {
    line-height: 1rem;
  }
  .lg\:leading-5 {
    line-height: 1.25rem;
  }
  .lg\:leading-6 {
    line-height: 1.5rem;
  }
  .lg\:leading-7 {
    line-height: 1.75rem;
  }
  .lg\:leading-8 {
    line-height: 2rem;
  }
  .lg\:leading-9 {
    line-height: 2.25rem;
  }
  .lg\:leading-10 {
    line-height: 2.5rem;
  }
  .lg\:leading-none {
    line-height: 1;
  }
  .lg\:leading-tight {
    line-height: 1.25;
  }
  .lg\:leading-snug {
    line-height: 1.375;
  }
  .lg\:leading-normal {
    line-height: 1.5;
  }
  .lg\:leading-relaxed {
    line-height: 1.625;
  }
  .lg\:leading-loose {
    line-height: 2;
  }
  .lg\:list-inside {
    list-style-position: inside;
  }
  .lg\:list-outside {
    list-style-position: outside;
  }
  .lg\:list-none {
    list-style-type: none;
  }
  .lg\:list-disc {
    list-style-type: disc;
  }
  .lg\:list-decimal {
    list-style-type: decimal;
  }
  .lg\:m-0 {
    margin: 0;
  }
  .lg\:m-1 {
    margin: 0.25rem;
  }
  .lg\:m-2 {
    margin: 0.5rem;
  }
  .lg\:m-3 {
    margin: 0.75rem;
  }
  .lg\:m-4 {
    margin: 1rem;
  }
  .lg\:m-5 {
    margin: 1.25rem;
  }
  .lg\:m-6 {
    margin: 1.5rem;
  }
  .lg\:m-7 {
    margin: 1.75rem;
  }
  .lg\:m-8 {
    margin: 2rem;
  }
  .lg\:m-9 {
    margin: 2.25rem;
  }
  .lg\:m-10 {
    margin: 2.5rem;
  }
  .lg\:m-11 {
    margin: 2.75rem;
  }
  .lg\:m-12 {
    margin: 3rem;
  }
  .lg\:m-14 {
    margin: 3.5rem;
  }
  .lg\:m-16 {
    margin: 4rem;
  }
  .lg\:m-20 {
    margin: 5rem;
  }
  .lg\:m-24 {
    margin: 6rem;
  }
  .lg\:m-28 {
    margin: 7rem;
  }
  .lg\:m-32 {
    margin: 8rem;
  }
  .lg\:m-36 {
    margin: 9rem;
  }
  .lg\:m-40 {
    margin: 10rem;
  }
  .lg\:m-44 {
    margin: 11rem;
  }
  .lg\:m-48 {
    margin: 12rem;
  }
  .lg\:m-52 {
    margin: 13rem;
  }
  .lg\:m-56 {
    margin: 14rem;
  }
  .lg\:m-60 {
    margin: 15rem;
  }
  .lg\:m-64 {
    margin: 16rem;
  }
  .lg\:m-72 {
    margin: 18rem;
  }
  .lg\:m-80 {
    margin: 20rem;
  }
  .lg\:m-96 {
    margin: 24rem;
  }
  .lg\:m-auto {
    margin: auto;
  }
  .lg\:m-px {
    margin: 1px;
  }
  .lg\:m-0\.5 {
    margin: 0.125rem;
  }
  .lg\:m-1\.5 {
    margin: 0.375rem;
  }
  .lg\:m-2\.5 {
    margin: 0.625rem;
  }
  .lg\:m-3\.5 {
    margin: 0.875rem;
  }
  .lg\:-m-0 {
    margin: 0;
  }
  .lg\:-m-1 {
    margin: -0.25rem;
  }
  .lg\:-m-2 {
    margin: -0.5rem;
  }
  .lg\:-m-3 {
    margin: -0.75rem;
  }
  .lg\:-m-4 {
    margin: -1rem;
  }
  .lg\:-m-5 {
    margin: -1.25rem;
  }
  .lg\:-m-6 {
    margin: -1.5rem;
  }
  .lg\:-m-7 {
    margin: -1.75rem;
  }
  .lg\:-m-8 {
    margin: -2rem;
  }
  .lg\:-m-9 {
    margin: -2.25rem;
  }
  .lg\:-m-10 {
    margin: -2.5rem;
  }
  .lg\:-m-11 {
    margin: -2.75rem;
  }
  .lg\:-m-12 {
    margin: -3rem;
  }
  .lg\:-m-14 {
    margin: -3.5rem;
  }
  .lg\:-m-16 {
    margin: -4rem;
  }
  .lg\:-m-20 {
    margin: -5rem;
  }
  .lg\:-m-24 {
    margin: -6rem;
  }
  .lg\:-m-28 {
    margin: -7rem;
  }
  .lg\:-m-32 {
    margin: -8rem;
  }
  .lg\:-m-36 {
    margin: -9rem;
  }
  .lg\:-m-40 {
    margin: -10rem;
  }
  .lg\:-m-44 {
    margin: -11rem;
  }
  .lg\:-m-48 {
    margin: -12rem;
  }
  .lg\:-m-52 {
    margin: -13rem;
  }
  .lg\:-m-56 {
    margin: -14rem;
  }
  .lg\:-m-60 {
    margin: -15rem;
  }
  .lg\:-m-64 {
    margin: -16rem;
  }
  .lg\:-m-72 {
    margin: -18rem;
  }
  .lg\:-m-80 {
    margin: -20rem;
  }
  .lg\:-m-96 {
    margin: -24rem;
  }
  .lg\:-m-px {
    margin: -1px;
  }
  .lg\:-m-0\.5 {
    margin: -0.125rem;
  }
  .lg\:-m-1\.5 {
    margin: -0.375rem;
  }
  .lg\:-m-2\.5 {
    margin: -0.625rem;
  }
  .lg\:-m-3\.5 {
    margin: -0.875rem;
  }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .lg\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .lg\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .lg\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .lg\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .lg\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .lg\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .lg\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .lg\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .lg\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .lg\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .lg\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .lg\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .lg\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .lg\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .lg\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .lg\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .lg\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .lg\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .lg\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .lg\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .lg\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .lg\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .lg\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .lg\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .lg\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .lg\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .lg\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .lg\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .lg\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .lg\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .lg\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .lg\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .lg\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .lg\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .lg\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .lg\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .lg\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .lg\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .lg\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .lg\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .lg\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .lg\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .lg\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .lg\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .lg\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .lg\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .lg\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .lg\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .lg\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .lg\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .lg\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .lg\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .lg\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:mr-0 {
    margin-right: 0;
  }
  .lg\:mb-0 {
    margin-bottom: 0;
  }
  .lg\:ml-0 {
    margin-left: 0;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem;
  }
  .lg\:mt-3 {
    margin-top: 0.75rem;
  }
  .lg\:mr-3 {
    margin-right: 0.75rem;
  }
  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .lg\:ml-3 {
    margin-left: 0.75rem;
  }
  .lg\:mt-4 {
    margin-top: 1rem;
  }
  .lg\:mr-4 {
    margin-right: 1rem;
  }
  .lg\:mb-4 {
    margin-bottom: 1rem;
  }
  .lg\:ml-4 {
    margin-left: 1rem;
  }
  .lg\:mt-5 {
    margin-top: 1.25rem;
  }
  .lg\:mr-5 {
    margin-right: 1.25rem;
  }
  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .lg\:ml-5 {
    margin-left: 1.25rem;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem;
  }
  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .lg\:ml-6 {
    margin-left: 1.5rem;
  }
  .lg\:mt-7 {
    margin-top: 1.75rem;
  }
  .lg\:mr-7 {
    margin-right: 1.75rem;
  }
  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .lg\:ml-7 {
    margin-left: 1.75rem;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:mr-8 {
    margin-right: 2rem;
  }
  .lg\:mb-8 {
    margin-bottom: 2rem;
  }
  .lg\:ml-8 {
    margin-left: 2rem;
  }
  .lg\:mt-9 {
    margin-top: 2.25rem;
  }
  .lg\:mr-9 {
    margin-right: 2.25rem;
  }
  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .lg\:ml-9 {
    margin-left: 2.25rem;
  }
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
  .lg\:mr-10 {
    margin-right: 2.5rem;
  }
  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .lg\:ml-10 {
    margin-left: 2.5rem;
  }
  .lg\:mt-11 {
    margin-top: 2.75rem;
  }
  .lg\:mr-11 {
    margin-right: 2.75rem;
  }
  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .lg\:ml-11 {
    margin-left: 2.75rem;
  }
  .lg\:mt-12 {
    margin-top: 3rem;
  }
  .lg\:mr-12 {
    margin-right: 3rem;
  }
  .lg\:mb-12 {
    margin-bottom: 3rem;
  }
  .lg\:ml-12 {
    margin-left: 3rem;
  }
  .lg\:mt-14 {
    margin-top: 3.5rem;
  }
  .lg\:mr-14 {
    margin-right: 3.5rem;
  }
  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .lg\:ml-14 {
    margin-left: 3.5rem;
  }
  .lg\:mt-16 {
    margin-top: 4rem;
  }
  .lg\:mr-16 {
    margin-right: 4rem;
  }
  .lg\:mb-16 {
    margin-bottom: 4rem;
  }
  .lg\:ml-16 {
    margin-left: 4rem;
  }
  .lg\:mt-20 {
    margin-top: 5rem;
  }
  .lg\:mr-20 {
    margin-right: 5rem;
  }
  .lg\:mb-20 {
    margin-bottom: 5rem;
  }
  .lg\:ml-20 {
    margin-left: 5rem;
  }
  .lg\:mt-24 {
    margin-top: 6rem;
  }
  .lg\:mr-24 {
    margin-right: 6rem;
  }
  .lg\:mb-24 {
    margin-bottom: 6rem;
  }
  .lg\:ml-24 {
    margin-left: 6rem;
  }
  .lg\:mt-28 {
    margin-top: 7rem;
  }
  .lg\:mr-28 {
    margin-right: 7rem;
  }
  .lg\:mb-28 {
    margin-bottom: 7rem;
  }
  .lg\:ml-28 {
    margin-left: 7rem;
  }
  .lg\:mt-32 {
    margin-top: 8rem;
  }
  .lg\:mr-32 {
    margin-right: 8rem;
  }
  .lg\:mb-32 {
    margin-bottom: 8rem;
  }
  .lg\:ml-32 {
    margin-left: 8rem;
  }
  .lg\:mt-36 {
    margin-top: 9rem;
  }
  .lg\:mr-36 {
    margin-right: 9rem;
  }
  .lg\:mb-36 {
    margin-bottom: 9rem;
  }
  .lg\:ml-36 {
    margin-left: 9rem;
  }
  .lg\:mt-40 {
    margin-top: 10rem;
  }
  .lg\:mr-40 {
    margin-right: 10rem;
  }
  .lg\:mb-40 {
    margin-bottom: 10rem;
  }
  .lg\:ml-40 {
    margin-left: 10rem;
  }
  .lg\:mt-44 {
    margin-top: 11rem;
  }
  .lg\:mr-44 {
    margin-right: 11rem;
  }
  .lg\:mb-44 {
    margin-bottom: 11rem;
  }
  .lg\:ml-44 {
    margin-left: 11rem;
  }
  .lg\:mt-48 {
    margin-top: 12rem;
  }
  .lg\:mr-48 {
    margin-right: 12rem;
  }
  .lg\:mb-48 {
    margin-bottom: 12rem;
  }
  .lg\:ml-48 {
    margin-left: 12rem;
  }
  .lg\:mt-52 {
    margin-top: 13rem;
  }
  .lg\:mr-52 {
    margin-right: 13rem;
  }
  .lg\:mb-52 {
    margin-bottom: 13rem;
  }
  .lg\:ml-52 {
    margin-left: 13rem;
  }
  .lg\:mt-56 {
    margin-top: 14rem;
  }
  .lg\:mr-56 {
    margin-right: 14rem;
  }
  .lg\:mb-56 {
    margin-bottom: 14rem;
  }
  .lg\:ml-56 {
    margin-left: 14rem;
  }
  .lg\:mt-60 {
    margin-top: 15rem;
  }
  .lg\:mr-60 {
    margin-right: 15rem;
  }
  .lg\:mb-60 {
    margin-bottom: 15rem;
  }
  .lg\:ml-60 {
    margin-left: 15rem;
  }
  .lg\:mt-64 {
    margin-top: 16rem;
  }
  .lg\:mr-64 {
    margin-right: 16rem;
  }
  .lg\:mb-64 {
    margin-bottom: 16rem;
  }
  .lg\:ml-64 {
    margin-left: 16rem;
  }
  .lg\:mt-72 {
    margin-top: 18rem;
  }
  .lg\:mr-72 {
    margin-right: 18rem;
  }
  .lg\:mb-72 {
    margin-bottom: 18rem;
  }
  .lg\:ml-72 {
    margin-left: 18rem;
  }
  .lg\:mt-80 {
    margin-top: 20rem;
  }
  .lg\:mr-80 {
    margin-right: 20rem;
  }
  .lg\:mb-80 {
    margin-bottom: 20rem;
  }
  .lg\:ml-80 {
    margin-left: 20rem;
  }
  .lg\:mt-96 {
    margin-top: 24rem;
  }
  .lg\:mr-96 {
    margin-right: 24rem;
  }
  .lg\:mb-96 {
    margin-bottom: 24rem;
  }
  .lg\:ml-96 {
    margin-left: 24rem;
  }
  .lg\:mt-auto {
    margin-top: auto;
  }
  .lg\:mr-auto {
    margin-right: auto;
  }
  .lg\:mb-auto {
    margin-bottom: auto;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:mt-px {
    margin-top: 1px;
  }
  .lg\:mr-px {
    margin-right: 1px;
  }
  .lg\:mb-px {
    margin-bottom: 1px;
  }
  .lg\:ml-px {
    margin-left: 1px;
  }
  .lg\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .lg\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .lg\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .lg\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .lg\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .lg\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .lg\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .lg\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .lg\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .lg\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .lg\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .lg\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .lg\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .lg\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .lg\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .lg\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .lg\:-mt-0 {
    margin-top: 0;
  }
  .lg\:-mr-0 {
    margin-right: 0;
  }
  .lg\:-mb-0 {
    margin-bottom: 0;
  }
  .lg\:-ml-0 {
    margin-left: 0;
  }
  .lg\:-mt-1 {
    margin-top: -0.25rem;
  }
  .lg\:-mr-1 {
    margin-right: -0.25rem;
  }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .lg\:-ml-1 {
    margin-left: -0.25rem;
  }
  .lg\:-mt-2 {
    margin-top: -0.5rem;
  }
  .lg\:-mr-2 {
    margin-right: -0.5rem;
  }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .lg\:-ml-2 {
    margin-left: -0.5rem;
  }
  .lg\:-mt-3 {
    margin-top: -0.75rem;
  }
  .lg\:-mr-3 {
    margin-right: -0.75rem;
  }
  .lg\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .lg\:-ml-3 {
    margin-left: -0.75rem;
  }
  .lg\:-mt-4 {
    margin-top: -1rem;
  }
  .lg\:-mr-4 {
    margin-right: -1rem;
  }
  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }
  .lg\:-ml-4 {
    margin-left: -1rem;
  }
  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }
  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }
  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }
  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }
  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }
  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }
  .lg\:-mt-7 {
    margin-top: -1.75rem;
  }
  .lg\:-mr-7 {
    margin-right: -1.75rem;
  }
  .lg\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .lg\:-ml-7 {
    margin-left: -1.75rem;
  }
  .lg\:-mt-8 {
    margin-top: -2rem;
  }
  .lg\:-mr-8 {
    margin-right: -2rem;
  }
  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }
  .lg\:-ml-8 {
    margin-left: -2rem;
  }
  .lg\:-mt-9 {
    margin-top: -2.25rem;
  }
  .lg\:-mr-9 {
    margin-right: -2.25rem;
  }
  .lg\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .lg\:-ml-9 {
    margin-left: -2.25rem;
  }
  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }
  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }
  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }
  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }
  .lg\:-mr-11 {
    margin-right: -2.75rem;
  }
  .lg\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .lg\:-ml-11 {
    margin-left: -2.75rem;
  }
  .lg\:-mt-12 {
    margin-top: -3rem;
  }
  .lg\:-mr-12 {
    margin-right: -3rem;
  }
  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }
  .lg\:-ml-12 {
    margin-left: -3rem;
  }
  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }
  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }
  .lg\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .lg\:-ml-14 {
    margin-left: -3.5rem;
  }
  .lg\:-mt-16 {
    margin-top: -4rem;
  }
  .lg\:-mr-16 {
    margin-right: -4rem;
  }
  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }
  .lg\:-ml-16 {
    margin-left: -4rem;
  }
  .lg\:-mt-20 {
    margin-top: -5rem;
  }
  .lg\:-mr-20 {
    margin-right: -5rem;
  }
  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }
  .lg\:-ml-20 {
    margin-left: -5rem;
  }
  .lg\:-mt-24 {
    margin-top: -6rem;
  }
  .lg\:-mr-24 {
    margin-right: -6rem;
  }
  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }
  .lg\:-ml-24 {
    margin-left: -6rem;
  }
  .lg\:-mt-28 {
    margin-top: -7rem;
  }
  .lg\:-mr-28 {
    margin-right: -7rem;
  }
  .lg\:-mb-28 {
    margin-bottom: -7rem;
  }
  .lg\:-ml-28 {
    margin-left: -7rem;
  }
  .lg\:-mt-32 {
    margin-top: -8rem;
  }
  .lg\:-mr-32 {
    margin-right: -8rem;
  }
  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }
  .lg\:-ml-32 {
    margin-left: -8rem;
  }
  .lg\:-mt-36 {
    margin-top: -9rem;
  }
  .lg\:-mr-36 {
    margin-right: -9rem;
  }
  .lg\:-mb-36 {
    margin-bottom: -9rem;
  }
  .lg\:-ml-36 {
    margin-left: -9rem;
  }
  .lg\:-mt-40 {
    margin-top: -10rem;
  }
  .lg\:-mr-40 {
    margin-right: -10rem;
  }
  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }
  .lg\:-ml-40 {
    margin-left: -10rem;
  }
  .lg\:-mt-44 {
    margin-top: -11rem;
  }
  .lg\:-mr-44 {
    margin-right: -11rem;
  }
  .lg\:-mb-44 {
    margin-bottom: -11rem;
  }
  .lg\:-ml-44 {
    margin-left: -11rem;
  }
  .lg\:-mt-48 {
    margin-top: -12rem;
  }
  .lg\:-mr-48 {
    margin-right: -12rem;
  }
  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }
  .lg\:-ml-48 {
    margin-left: -12rem;
  }
  .lg\:-mt-52 {
    margin-top: -13rem;
  }
  .lg\:-mr-52 {
    margin-right: -13rem;
  }
  .lg\:-mb-52 {
    margin-bottom: -13rem;
  }
  .lg\:-ml-52 {
    margin-left: -13rem;
  }
  .lg\:-mt-56 {
    margin-top: -14rem;
  }
  .lg\:-mr-56 {
    margin-right: -14rem;
  }
  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }
  .lg\:-ml-56 {
    margin-left: -14rem;
  }
  .lg\:-mt-60 {
    margin-top: -15rem;
  }
  .lg\:-mr-60 {
    margin-right: -15rem;
  }
  .lg\:-mb-60 {
    margin-bottom: -15rem;
  }
  .lg\:-ml-60 {
    margin-left: -15rem;
  }
  .lg\:-mt-64 {
    margin-top: -16rem;
  }
  .lg\:-mr-64 {
    margin-right: -16rem;
  }
  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }
  .lg\:-ml-64 {
    margin-left: -16rem;
  }
  .lg\:-mt-72 {
    margin-top: -18rem;
  }
  .lg\:-mr-72 {
    margin-right: -18rem;
  }
  .lg\:-mb-72 {
    margin-bottom: -18rem;
  }
  .lg\:-ml-72 {
    margin-left: -18rem;
  }
  .lg\:-mt-80 {
    margin-top: -20rem;
  }
  .lg\:-mr-80 {
    margin-right: -20rem;
  }
  .lg\:-mb-80 {
    margin-bottom: -20rem;
  }
  .lg\:-ml-80 {
    margin-left: -20rem;
  }
  .lg\:-mt-96 {
    margin-top: -24rem;
  }
  .lg\:-mr-96 {
    margin-right: -24rem;
  }
  .lg\:-mb-96 {
    margin-bottom: -24rem;
  }
  .lg\:-ml-96 {
    margin-left: -24rem;
  }
  .lg\:-mt-px {
    margin-top: -1px;
  }
  .lg\:-mr-px {
    margin-right: -1px;
  }
  .lg\:-mb-px {
    margin-bottom: -1px;
  }
  .lg\:-ml-px {
    margin-left: -1px;
  }
  .lg\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .lg\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .lg\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .lg\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .lg\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .lg\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .lg\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .lg\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .lg\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .lg\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .lg\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .lg\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .lg\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .lg\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .lg\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .lg\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .lg\:max-h-0 {
    max-height: 0;
  }
  .lg\:max-h-1 {
    max-height: 0.25rem;
  }
  .lg\:max-h-2 {
    max-height: 0.5rem;
  }
  .lg\:max-h-3 {
    max-height: 0.75rem;
  }
  .lg\:max-h-4 {
    max-height: 1rem;
  }
  .lg\:max-h-5 {
    max-height: 1.25rem;
  }
  .lg\:max-h-6 {
    max-height: 1.5rem;
  }
  .lg\:max-h-7 {
    max-height: 1.75rem;
  }
  .lg\:max-h-8 {
    max-height: 2rem;
  }
  .lg\:max-h-9 {
    max-height: 2.25rem;
  }
  .lg\:max-h-10 {
    max-height: 2.5rem;
  }
  .lg\:max-h-11 {
    max-height: 2.75rem;
  }
  .lg\:max-h-12 {
    max-height: 3rem;
  }
  .lg\:max-h-14 {
    max-height: 3.5rem;
  }
  .lg\:max-h-16 {
    max-height: 4rem;
  }
  .lg\:max-h-20 {
    max-height: 5rem;
  }
  .lg\:max-h-24 {
    max-height: 6rem;
  }
  .lg\:max-h-28 {
    max-height: 7rem;
  }
  .lg\:max-h-32 {
    max-height: 8rem;
  }
  .lg\:max-h-36 {
    max-height: 9rem;
  }
  .lg\:max-h-40 {
    max-height: 10rem;
  }
  .lg\:max-h-44 {
    max-height: 11rem;
  }
  .lg\:max-h-48 {
    max-height: 12rem;
  }
  .lg\:max-h-52 {
    max-height: 13rem;
  }
  .lg\:max-h-56 {
    max-height: 14rem;
  }
  .lg\:max-h-60 {
    max-height: 15rem;
  }
  .lg\:max-h-64 {
    max-height: 16rem;
  }
  .lg\:max-h-72 {
    max-height: 18rem;
  }
  .lg\:max-h-80 {
    max-height: 20rem;
  }
  .lg\:max-h-96 {
    max-height: 24rem;
  }
  .lg\:max-h-px {
    max-height: 1px;
  }
  .lg\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .lg\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .lg\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .lg\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .lg\:max-h-full {
    max-height: 100%;
  }
  .lg\:max-h-screen {
    max-height: 100vh;
  }
  .lg\:max-h-1\/4 {
    max-height: 25%;
  }
  .lg\:max-h-1\/2 {
    max-height: 50%;
  }
  .lg\:max-h-3\/4 {
    max-height: 75%;
  }
  .lg\:max-h-10\/12 {
    max-height: 80%;
  }
  .lg\:max-h-enormous {
    max-height: 4000px;
  }
  .lg\:max-w-0 {
    max-width: 0;
  }
  .lg\:max-w-110 {
    max-width: 110px;
  }
  .lg\:max-w-473 {
    max-width: 473px;
  }
  .lg\:max-w-none {
    max-width: none;
  }
  .lg\:max-w-xs {
    max-width: 20rem;
  }
  .lg\:max-w-sm {
    max-width: 24rem;
  }
  .lg\:max-w-md {
    max-width: 28rem;
  }
  .lg\:max-w-lg {
    max-width: 32rem;
  }
  .lg\:max-w-xl {
    max-width: 36rem;
  }
  .lg\:max-w-2xl {
    max-width: 42rem;
  }
  .lg\:max-w-3xl {
    max-width: 48rem;
  }
  .lg\:max-w-4xl {
    max-width: 56rem;
  }
  .lg\:max-w-5xl {
    max-width: 64rem;
  }
  .lg\:max-w-6xl {
    max-width: 72rem;
  }
  .lg\:max-w-7xl {
    max-width: 80rem;
  }
  .lg\:max-w-full {
    max-width: 100%;
  }
  .lg\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .lg\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .lg\:max-w-prose {
    max-width: 65ch;
  }
  .lg\:max-w-screen-sm {
    max-width: 640px;
  }
  .lg\:max-w-screen-md {
    max-width: 768px;
  }
  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }
  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }
  .lg\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .lg\:max-w-screen-xxs {
    max-width: 260px;
  }
  .lg\:max-w-screen-xs {
    max-width: 360px;
  }
  .lg\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .lg\:min-h-0 {
    min-height: 0;
  }
  .lg\:min-h-full {
    min-height: 100%;
  }
  .lg\:min-h-screen {
    min-height: 100vh;
  }
  .lg\:min-w-0 {
    min-width: 0;
  }
  .lg\:min-w-10 {
    min-width: 2.5rem;
  }
  .lg\:min-w-12 {
    min-width: 3rem;
  }
  .lg\:min-w-14 {
    min-width: 3.5rem;
  }
  .lg\:min-w-full {
    min-width: 100%;
  }
  .lg\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .lg\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .lg\:object-contain {
    object-fit: contain;
  }
  .lg\:object-cover {
    object-fit: cover;
  }
  .lg\:object-fill {
    object-fit: fill;
  }
  .lg\:object-none {
    object-fit: none;
  }
  .lg\:object-scale-down {
    object-fit: scale-down;
  }
  .lg\:object-bottom {
    object-position: bottom;
  }
  .lg\:object-center {
    object-position: center;
  }
  .lg\:object-left {
    object-position: left;
  }
  .lg\:object-left-bottom {
    object-position: left bottom;
  }
  .lg\:object-left-top {
    object-position: left top;
  }
  .lg\:object-right {
    object-position: right;
  }
  .lg\:object-right-bottom {
    object-position: right bottom;
  }
  .lg\:object-right-top {
    object-position: right top;
  }
  .lg\:object-top {
    object-position: top;
  }
  .lg\:opacity-0 {
    opacity: 0;
  }
  .lg\:opacity-5 {
    opacity: 0.05;
  }
  .lg\:opacity-10 {
    opacity: 0.1;
  }
  .lg\:opacity-20 {
    opacity: 0.2;
  }
  .lg\:opacity-25 {
    opacity: 0.25;
  }
  .lg\:opacity-30 {
    opacity: 0.3;
  }
  .lg\:opacity-40 {
    opacity: 0.4;
  }
  .lg\:opacity-50 {
    opacity: 0.5;
  }
  .lg\:opacity-60 {
    opacity: 0.6;
  }
  .lg\:opacity-70 {
    opacity: 0.7;
  }
  .lg\:opacity-75 {
    opacity: 0.75;
  }
  .lg\:opacity-80 {
    opacity: 0.8;
  }
  .lg\:opacity-90 {
    opacity: 0.9;
  }
  .lg\:opacity-95 {
    opacity: 0.95;
  }
  .lg\:opacity-100 {
    opacity: 1;
  }
  .group:hover .lg\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .lg\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .lg\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .lg\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .lg\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .lg\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .lg\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .lg\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .lg\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .lg\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .lg\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .lg\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .lg\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .lg\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .lg\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .lg\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .lg\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .lg\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .lg\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .lg\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .lg\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .lg\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .lg\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .lg\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .lg\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .lg\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .lg\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .lg\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .lg\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .lg\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .lg\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .lg\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .lg\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .lg\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .lg\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .lg\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .lg\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .lg\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .lg\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .lg\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .lg\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .lg\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .lg\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .lg\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .lg\:overflow-auto {
    overflow: auto;
  }
  .lg\:overflow-hidden {
    overflow: hidden;
  }
  .lg\:overflow-visible {
    overflow: visible;
  }
  .lg\:overflow-scroll {
    overflow: scroll;
  }
  .lg\:overflow-x-auto {
    overflow-x: auto;
  }
  .lg\:overflow-y-auto {
    overflow-y: auto;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .lg\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .lg\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .lg\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .lg\:p-0 {
    padding: 0;
  }
  .lg\:p-1 {
    padding: 0.25rem;
  }
  .lg\:p-2 {
    padding: 0.5rem;
  }
  .lg\:p-3 {
    padding: 0.75rem;
  }
  .lg\:p-4 {
    padding: 1rem;
  }
  .lg\:p-5 {
    padding: 1.25rem;
  }
  .lg\:p-6 {
    padding: 1.5rem;
  }
  .lg\:p-7 {
    padding: 1.75rem;
  }
  .lg\:p-8 {
    padding: 2rem;
  }
  .lg\:p-9 {
    padding: 2.25rem;
  }
  .lg\:p-10 {
    padding: 2.5rem;
  }
  .lg\:p-11 {
    padding: 2.75rem;
  }
  .lg\:p-12 {
    padding: 3rem;
  }
  .lg\:p-14 {
    padding: 3.5rem;
  }
  .lg\:p-16 {
    padding: 4rem;
  }
  .lg\:p-20 {
    padding: 5rem;
  }
  .lg\:p-24 {
    padding: 6rem;
  }
  .lg\:p-28 {
    padding: 7rem;
  }
  .lg\:p-32 {
    padding: 8rem;
  }
  .lg\:p-36 {
    padding: 9rem;
  }
  .lg\:p-40 {
    padding: 10rem;
  }
  .lg\:p-44 {
    padding: 11rem;
  }
  .lg\:p-48 {
    padding: 12rem;
  }
  .lg\:p-52 {
    padding: 13rem;
  }
  .lg\:p-56 {
    padding: 14rem;
  }
  .lg\:p-60 {
    padding: 15rem;
  }
  .lg\:p-64 {
    padding: 16rem;
  }
  .lg\:p-72 {
    padding: 18rem;
  }
  .lg\:p-80 {
    padding: 20rem;
  }
  .lg\:p-96 {
    padding: 24rem;
  }
  .lg\:p-px {
    padding: 1px;
  }
  .lg\:p-0\.5 {
    padding: 0.125rem;
  }
  .lg\:p-1\.5 {
    padding: 0.375rem;
  }
  .lg\:p-2\.5 {
    padding: 0.625rem;
  }
  .lg\:p-3\.5 {
    padding: 0.875rem;
  }
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .lg\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .lg\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .lg\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .lg\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .lg\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .lg\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .lg\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .lg\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .lg\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .lg\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .lg\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .lg\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .lg\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .lg\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .lg\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .lg\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .lg\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .lg\:pt-0 {
    padding-top: 0;
  }
  .lg\:pr-0 {
    padding-right: 0;
  }
  .lg\:pb-0 {
    padding-bottom: 0;
  }
  .lg\:pl-0 {
    padding-left: 0;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem;
  }
  .lg\:pt-3 {
    padding-top: 0.75rem;
  }
  .lg\:pr-3 {
    padding-right: 0.75rem;
  }
  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .lg\:pl-3 {
    padding-left: 0.75rem;
  }
  .lg\:pt-4 {
    padding-top: 1rem;
  }
  .lg\:pr-4 {
    padding-right: 1rem;
  }
  .lg\:pb-4 {
    padding-bottom: 1rem;
  }
  .lg\:pl-4 {
    padding-left: 1rem;
  }
  .lg\:pt-5 {
    padding-top: 1.25rem;
  }
  .lg\:pr-5 {
    padding-right: 1.25rem;
  }
  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .lg\:pl-5 {
    padding-left: 1.25rem;
  }
  .lg\:pt-6 {
    padding-top: 1.5rem;
  }
  .lg\:pr-6 {
    padding-right: 1.5rem;
  }
  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .lg\:pl-6 {
    padding-left: 1.5rem;
  }
  .lg\:pt-7 {
    padding-top: 1.75rem;
  }
  .lg\:pr-7 {
    padding-right: 1.75rem;
  }
  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .lg\:pl-7 {
    padding-left: 1.75rem;
  }
  .lg\:pt-8 {
    padding-top: 2rem;
  }
  .lg\:pr-8 {
    padding-right: 2rem;
  }
  .lg\:pb-8 {
    padding-bottom: 2rem;
  }
  .lg\:pl-8 {
    padding-left: 2rem;
  }
  .lg\:pt-9 {
    padding-top: 2.25rem;
  }
  .lg\:pr-9 {
    padding-right: 2.25rem;
  }
  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .lg\:pl-9 {
    padding-left: 2.25rem;
  }
  .lg\:pt-10 {
    padding-top: 2.5rem;
  }
  .lg\:pr-10 {
    padding-right: 2.5rem;
  }
  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .lg\:pl-10 {
    padding-left: 2.5rem;
  }
  .lg\:pt-11 {
    padding-top: 2.75rem;
  }
  .lg\:pr-11 {
    padding-right: 2.75rem;
  }
  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .lg\:pl-11 {
    padding-left: 2.75rem;
  }
  .lg\:pt-12 {
    padding-top: 3rem;
  }
  .lg\:pr-12 {
    padding-right: 3rem;
  }
  .lg\:pb-12 {
    padding-bottom: 3rem;
  }
  .lg\:pl-12 {
    padding-left: 3rem;
  }
  .lg\:pt-14 {
    padding-top: 3.5rem;
  }
  .lg\:pr-14 {
    padding-right: 3.5rem;
  }
  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .lg\:pl-14 {
    padding-left: 3.5rem;
  }
  .lg\:pt-16 {
    padding-top: 4rem;
  }
  .lg\:pr-16 {
    padding-right: 4rem;
  }
  .lg\:pb-16 {
    padding-bottom: 4rem;
  }
  .lg\:pl-16 {
    padding-left: 4rem;
  }
  .lg\:pt-20 {
    padding-top: 5rem;
  }
  .lg\:pr-20 {
    padding-right: 5rem;
  }
  .lg\:pb-20 {
    padding-bottom: 5rem;
  }
  .lg\:pl-20 {
    padding-left: 5rem;
  }
  .lg\:pt-24 {
    padding-top: 6rem;
  }
  .lg\:pr-24 {
    padding-right: 6rem;
  }
  .lg\:pb-24 {
    padding-bottom: 6rem;
  }
  .lg\:pl-24 {
    padding-left: 6rem;
  }
  .lg\:pt-28 {
    padding-top: 7rem;
  }
  .lg\:pr-28 {
    padding-right: 7rem;
  }
  .lg\:pb-28 {
    padding-bottom: 7rem;
  }
  .lg\:pl-28 {
    padding-left: 7rem;
  }
  .lg\:pt-32 {
    padding-top: 8rem;
  }
  .lg\:pr-32 {
    padding-right: 8rem;
  }
  .lg\:pb-32 {
    padding-bottom: 8rem;
  }
  .lg\:pl-32 {
    padding-left: 8rem;
  }
  .lg\:pt-36 {
    padding-top: 9rem;
  }
  .lg\:pr-36 {
    padding-right: 9rem;
  }
  .lg\:pb-36 {
    padding-bottom: 9rem;
  }
  .lg\:pl-36 {
    padding-left: 9rem;
  }
  .lg\:pt-40 {
    padding-top: 10rem;
  }
  .lg\:pr-40 {
    padding-right: 10rem;
  }
  .lg\:pb-40 {
    padding-bottom: 10rem;
  }
  .lg\:pl-40 {
    padding-left: 10rem;
  }
  .lg\:pt-44 {
    padding-top: 11rem;
  }
  .lg\:pr-44 {
    padding-right: 11rem;
  }
  .lg\:pb-44 {
    padding-bottom: 11rem;
  }
  .lg\:pl-44 {
    padding-left: 11rem;
  }
  .lg\:pt-48 {
    padding-top: 12rem;
  }
  .lg\:pr-48 {
    padding-right: 12rem;
  }
  .lg\:pb-48 {
    padding-bottom: 12rem;
  }
  .lg\:pl-48 {
    padding-left: 12rem;
  }
  .lg\:pt-52 {
    padding-top: 13rem;
  }
  .lg\:pr-52 {
    padding-right: 13rem;
  }
  .lg\:pb-52 {
    padding-bottom: 13rem;
  }
  .lg\:pl-52 {
    padding-left: 13rem;
  }
  .lg\:pt-56 {
    padding-top: 14rem;
  }
  .lg\:pr-56 {
    padding-right: 14rem;
  }
  .lg\:pb-56 {
    padding-bottom: 14rem;
  }
  .lg\:pl-56 {
    padding-left: 14rem;
  }
  .lg\:pt-60 {
    padding-top: 15rem;
  }
  .lg\:pr-60 {
    padding-right: 15rem;
  }
  .lg\:pb-60 {
    padding-bottom: 15rem;
  }
  .lg\:pl-60 {
    padding-left: 15rem;
  }
  .lg\:pt-64 {
    padding-top: 16rem;
  }
  .lg\:pr-64 {
    padding-right: 16rem;
  }
  .lg\:pb-64 {
    padding-bottom: 16rem;
  }
  .lg\:pl-64 {
    padding-left: 16rem;
  }
  .lg\:pt-72 {
    padding-top: 18rem;
  }
  .lg\:pr-72 {
    padding-right: 18rem;
  }
  .lg\:pb-72 {
    padding-bottom: 18rem;
  }
  .lg\:pl-72 {
    padding-left: 18rem;
  }
  .lg\:pt-80 {
    padding-top: 20rem;
  }
  .lg\:pr-80 {
    padding-right: 20rem;
  }
  .lg\:pb-80 {
    padding-bottom: 20rem;
  }
  .lg\:pl-80 {
    padding-left: 20rem;
  }
  .lg\:pt-96 {
    padding-top: 24rem;
  }
  .lg\:pr-96 {
    padding-right: 24rem;
  }
  .lg\:pb-96 {
    padding-bottom: 24rem;
  }
  .lg\:pl-96 {
    padding-left: 24rem;
  }
  .lg\:pt-px {
    padding-top: 1px;
  }
  .lg\:pr-px {
    padding-right: 1px;
  }
  .lg\:pb-px {
    padding-bottom: 1px;
  }
  .lg\:pl-px {
    padding-left: 1px;
  }
  .lg\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .lg\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .lg\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .lg\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .lg\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .lg\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .lg\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .lg\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .lg\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .lg\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .lg\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .lg\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .lg\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .lg\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .lg\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .lg\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .lg\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .lg\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .lg\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .lg\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .lg\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .lg\:placeholder-current::placeholder {
    color: currentColor;
  }
  .lg\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .lg\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .lg\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .lg\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .lg\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .lg\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .lg\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .lg\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .lg\:placeholder-background::placeholder {
    color: var(--background);
  }
  .lg\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .lg\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .lg\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .lg\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .lg\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .lg\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .lg\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .lg\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .lg\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .lg\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .lg\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .lg\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .lg\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .lg\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .lg\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .lg\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .lg\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .lg\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .lg\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .lg\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .lg\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .lg\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .lg\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .lg\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .lg\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .lg\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .lg\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .lg\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .lg\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .lg\:placeholder-success::placeholder {
    color: var(--success);
  }
  .lg\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .lg\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .lg\:placeholder-error::placeholder {
    color: var(--error);
  }
  .lg\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .lg\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .lg\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .lg\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .lg\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .lg\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .lg\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .lg\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .lg\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .lg\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .lg\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .lg\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .lg\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .lg\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .lg\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .lg\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .lg\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .lg\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .lg\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .lg\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .lg\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .lg\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .lg\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .lg\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .lg\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .lg\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .lg\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .lg\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .lg\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .lg\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .lg\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .lg\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .lg\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .lg\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .lg\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .lg\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .lg\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .lg\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .lg\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .lg\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .lg\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .lg\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .lg\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .lg\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .lg\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .lg\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .lg\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .lg\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .lg\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .lg\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .lg\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .lg\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .lg\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .lg\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .lg\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .lg\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .lg\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .lg\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .lg\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .lg\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .lg\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .lg\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:pointer-events-none {
    pointer-events: none;
  }
  .lg\:pointer-events-auto {
    pointer-events: auto;
  }
  .lg\:static {
    position: static;
  }
  .lg\:fixed {
    position: fixed;
  }
  .lg\:absolute {
    position: absolute;
  }
  .lg\:relative {
    position: relative;
  }
  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lg\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .lg\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .lg\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .lg\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .lg\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .lg\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .lg\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .lg\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .lg\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .lg\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .lg\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .lg\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .lg\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .lg\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .lg\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .lg\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .lg\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .lg\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .lg\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .lg\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .lg\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .lg\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .lg\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .lg\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .lg\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .lg\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .lg\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .lg\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .lg\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .lg\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .lg\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .lg\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .lg\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .lg\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .lg\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lg\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .lg\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .lg\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .lg\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .lg\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .lg\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .lg\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .lg\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .lg\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .lg\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .lg\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .lg\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .lg\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .lg\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .lg\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .lg\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .lg\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .lg\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .lg\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .lg\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .lg\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .lg\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .lg\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .lg\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .lg\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .lg\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .lg\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .lg\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .lg\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .lg\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .lg\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .lg\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .lg\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .lg\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .lg\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .lg\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .lg\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .lg\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .lg\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .lg\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .lg\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .lg\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .lg\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .lg\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .lg\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .lg\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .lg\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .lg\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .lg\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .lg\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .lg\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .lg\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .lg\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .lg\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .lg\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .lg\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .lg\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .lg\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .lg\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .lg\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .lg\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .lg\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .lg\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .lg\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .lg\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .lg\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .lg\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .lg\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .lg\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .lg\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .lg\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .lg\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .lg\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .lg\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .lg\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .lg\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .lg\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .lg\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .lg\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .lg\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .lg\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .lg\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .lg\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .lg\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .lg\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .lg\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .lg\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .lg\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .lg\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .lg\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .lg\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .lg\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .lg\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .lg\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .lg\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .lg\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .lg\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .lg\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .lg\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .lg\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .lg\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .lg\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .lg\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .lg\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .lg\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .lg\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .lg\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .lg\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .lg\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .lg\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .lg\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .lg\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .lg\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .lg\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .lg\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .lg\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .lg\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .lg\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .lg\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .lg\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .lg\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .lg\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .lg\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .lg\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .lg\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .lg\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .lg\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .lg\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .lg\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .lg\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .lg\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .lg\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .lg\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .lg\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .lg\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .lg\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .lg\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .lg\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .lg\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .lg\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .lg\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .lg\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .lg\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .lg\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .lg\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .lg\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .lg\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .lg\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .lg\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .lg\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .lg\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .lg\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .lg\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .lg\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .lg\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .lg\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .lg\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .lg\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .lg\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .lg\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .lg\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .lg\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .lg\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .lg\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .lg\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .lg\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .lg\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .lg\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .lg\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .lg\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .lg\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .lg\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .lg\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .lg\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .lg\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .lg\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .lg\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .lg\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .lg\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .lg\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .lg\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .lg\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .lg\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .lg\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .lg\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .lg\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .lg\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .lg\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .lg\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .lg\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .lg\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .lg\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .lg\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .lg\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .lg\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .lg\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .lg\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .lg\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .lg\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .lg\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .lg\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .lg\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .lg\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .lg\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .lg\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .lg\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .lg\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .lg\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .lg\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .lg\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .lg\:top-0 {
    top: 0;
  }
  .lg\:right-0 {
    right: 0;
  }
  .lg\:bottom-0 {
    bottom: 0;
  }
  .lg\:left-0 {
    left: 0;
  }
  .lg\:top-1 {
    top: 0.25rem;
  }
  .lg\:right-1 {
    right: 0.25rem;
  }
  .lg\:bottom-1 {
    bottom: 0.25rem;
  }
  .lg\:left-1 {
    left: 0.25rem;
  }
  .lg\:top-2 {
    top: 0.5rem;
  }
  .lg\:right-2 {
    right: 0.5rem;
  }
  .lg\:bottom-2 {
    bottom: 0.5rem;
  }
  .lg\:left-2 {
    left: 0.5rem;
  }
  .lg\:top-3 {
    top: 0.75rem;
  }
  .lg\:right-3 {
    right: 0.75rem;
  }
  .lg\:bottom-3 {
    bottom: 0.75rem;
  }
  .lg\:left-3 {
    left: 0.75rem;
  }
  .lg\:top-4 {
    top: 1rem;
  }
  .lg\:right-4 {
    right: 1rem;
  }
  .lg\:bottom-4 {
    bottom: 1rem;
  }
  .lg\:left-4 {
    left: 1rem;
  }
  .lg\:top-5 {
    top: 1.25rem;
  }
  .lg\:right-5 {
    right: 1.25rem;
  }
  .lg\:bottom-5 {
    bottom: 1.25rem;
  }
  .lg\:left-5 {
    left: 1.25rem;
  }
  .lg\:top-6 {
    top: 1.5rem;
  }
  .lg\:right-6 {
    right: 1.5rem;
  }
  .lg\:bottom-6 {
    bottom: 1.5rem;
  }
  .lg\:left-6 {
    left: 1.5rem;
  }
  .lg\:top-7 {
    top: 1.75rem;
  }
  .lg\:right-7 {
    right: 1.75rem;
  }
  .lg\:bottom-7 {
    bottom: 1.75rem;
  }
  .lg\:left-7 {
    left: 1.75rem;
  }
  .lg\:top-8 {
    top: 2rem;
  }
  .lg\:right-8 {
    right: 2rem;
  }
  .lg\:bottom-8 {
    bottom: 2rem;
  }
  .lg\:left-8 {
    left: 2rem;
  }
  .lg\:top-9 {
    top: 2.25rem;
  }
  .lg\:right-9 {
    right: 2.25rem;
  }
  .lg\:bottom-9 {
    bottom: 2.25rem;
  }
  .lg\:left-9 {
    left: 2.25rem;
  }
  .lg\:top-10 {
    top: 2.5rem;
  }
  .lg\:right-10 {
    right: 2.5rem;
  }
  .lg\:bottom-10 {
    bottom: 2.5rem;
  }
  .lg\:left-10 {
    left: 2.5rem;
  }
  .lg\:top-11 {
    top: 2.75rem;
  }
  .lg\:right-11 {
    right: 2.75rem;
  }
  .lg\:bottom-11 {
    bottom: 2.75rem;
  }
  .lg\:left-11 {
    left: 2.75rem;
  }
  .lg\:top-12 {
    top: 3rem;
  }
  .lg\:right-12 {
    right: 3rem;
  }
  .lg\:bottom-12 {
    bottom: 3rem;
  }
  .lg\:left-12 {
    left: 3rem;
  }
  .lg\:top-14 {
    top: 3.5rem;
  }
  .lg\:right-14 {
    right: 3.5rem;
  }
  .lg\:bottom-14 {
    bottom: 3.5rem;
  }
  .lg\:left-14 {
    left: 3.5rem;
  }
  .lg\:top-16 {
    top: 4rem;
  }
  .lg\:right-16 {
    right: 4rem;
  }
  .lg\:bottom-16 {
    bottom: 4rem;
  }
  .lg\:left-16 {
    left: 4rem;
  }
  .lg\:top-20 {
    top: 5rem;
  }
  .lg\:right-20 {
    right: 5rem;
  }
  .lg\:bottom-20 {
    bottom: 5rem;
  }
  .lg\:left-20 {
    left: 5rem;
  }
  .lg\:top-24 {
    top: 6rem;
  }
  .lg\:right-24 {
    right: 6rem;
  }
  .lg\:bottom-24 {
    bottom: 6rem;
  }
  .lg\:left-24 {
    left: 6rem;
  }
  .lg\:top-28 {
    top: 7rem;
  }
  .lg\:right-28 {
    right: 7rem;
  }
  .lg\:bottom-28 {
    bottom: 7rem;
  }
  .lg\:left-28 {
    left: 7rem;
  }
  .lg\:top-32 {
    top: 8rem;
  }
  .lg\:right-32 {
    right: 8rem;
  }
  .lg\:bottom-32 {
    bottom: 8rem;
  }
  .lg\:left-32 {
    left: 8rem;
  }
  .lg\:top-36 {
    top: 9rem;
  }
  .lg\:right-36 {
    right: 9rem;
  }
  .lg\:bottom-36 {
    bottom: 9rem;
  }
  .lg\:left-36 {
    left: 9rem;
  }
  .lg\:top-40 {
    top: 10rem;
  }
  .lg\:right-40 {
    right: 10rem;
  }
  .lg\:bottom-40 {
    bottom: 10rem;
  }
  .lg\:left-40 {
    left: 10rem;
  }
  .lg\:top-44 {
    top: 11rem;
  }
  .lg\:right-44 {
    right: 11rem;
  }
  .lg\:bottom-44 {
    bottom: 11rem;
  }
  .lg\:left-44 {
    left: 11rem;
  }
  .lg\:top-48 {
    top: 12rem;
  }
  .lg\:right-48 {
    right: 12rem;
  }
  .lg\:bottom-48 {
    bottom: 12rem;
  }
  .lg\:left-48 {
    left: 12rem;
  }
  .lg\:top-52 {
    top: 13rem;
  }
  .lg\:right-52 {
    right: 13rem;
  }
  .lg\:bottom-52 {
    bottom: 13rem;
  }
  .lg\:left-52 {
    left: 13rem;
  }
  .lg\:top-56 {
    top: 14rem;
  }
  .lg\:right-56 {
    right: 14rem;
  }
  .lg\:bottom-56 {
    bottom: 14rem;
  }
  .lg\:left-56 {
    left: 14rem;
  }
  .lg\:top-60 {
    top: 15rem;
  }
  .lg\:right-60 {
    right: 15rem;
  }
  .lg\:bottom-60 {
    bottom: 15rem;
  }
  .lg\:left-60 {
    left: 15rem;
  }
  .lg\:top-64 {
    top: 16rem;
  }
  .lg\:right-64 {
    right: 16rem;
  }
  .lg\:bottom-64 {
    bottom: 16rem;
  }
  .lg\:left-64 {
    left: 16rem;
  }
  .lg\:top-72 {
    top: 18rem;
  }
  .lg\:right-72 {
    right: 18rem;
  }
  .lg\:bottom-72 {
    bottom: 18rem;
  }
  .lg\:left-72 {
    left: 18rem;
  }
  .lg\:top-80 {
    top: 20rem;
  }
  .lg\:right-80 {
    right: 20rem;
  }
  .lg\:bottom-80 {
    bottom: 20rem;
  }
  .lg\:left-80 {
    left: 20rem;
  }
  .lg\:top-96 {
    top: 24rem;
  }
  .lg\:right-96 {
    right: 24rem;
  }
  .lg\:bottom-96 {
    bottom: 24rem;
  }
  .lg\:left-96 {
    left: 24rem;
  }
  .lg\:top-auto {
    top: auto;
  }
  .lg\:right-auto {
    right: auto;
  }
  .lg\:bottom-auto {
    bottom: auto;
  }
  .lg\:left-auto {
    left: auto;
  }
  .lg\:top-px {
    top: 1px;
  }
  .lg\:right-px {
    right: 1px;
  }
  .lg\:bottom-px {
    bottom: 1px;
  }
  .lg\:left-px {
    left: 1px;
  }
  .lg\:top-0\.5 {
    top: 0.125rem;
  }
  .lg\:right-0\.5 {
    right: 0.125rem;
  }
  .lg\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .lg\:left-0\.5 {
    left: 0.125rem;
  }
  .lg\:top-1\.5 {
    top: 0.375rem;
  }
  .lg\:right-1\.5 {
    right: 0.375rem;
  }
  .lg\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .lg\:left-1\.5 {
    left: 0.375rem;
  }
  .lg\:top-2\.5 {
    top: 0.625rem;
  }
  .lg\:right-2\.5 {
    right: 0.625rem;
  }
  .lg\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .lg\:left-2\.5 {
    left: 0.625rem;
  }
  .lg\:top-3\.5 {
    top: 0.875rem;
  }
  .lg\:right-3\.5 {
    right: 0.875rem;
  }
  .lg\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .lg\:left-3\.5 {
    left: 0.875rem;
  }
  .lg\:-top-0 {
    top: 0;
  }
  .lg\:-right-0 {
    right: 0;
  }
  .lg\:-bottom-0 {
    bottom: 0;
  }
  .lg\:-left-0 {
    left: 0;
  }
  .lg\:-top-1 {
    top: -0.25rem;
  }
  .lg\:-right-1 {
    right: -0.25rem;
  }
  .lg\:-bottom-1 {
    bottom: -0.25rem;
  }
  .lg\:-left-1 {
    left: -0.25rem;
  }
  .lg\:-top-2 {
    top: -0.5rem;
  }
  .lg\:-right-2 {
    right: -0.5rem;
  }
  .lg\:-bottom-2 {
    bottom: -0.5rem;
  }
  .lg\:-left-2 {
    left: -0.5rem;
  }
  .lg\:-top-3 {
    top: -0.75rem;
  }
  .lg\:-right-3 {
    right: -0.75rem;
  }
  .lg\:-bottom-3 {
    bottom: -0.75rem;
  }
  .lg\:-left-3 {
    left: -0.75rem;
  }
  .lg\:-top-4 {
    top: -1rem;
  }
  .lg\:-right-4 {
    right: -1rem;
  }
  .lg\:-bottom-4 {
    bottom: -1rem;
  }
  .lg\:-left-4 {
    left: -1rem;
  }
  .lg\:-top-5 {
    top: -1.25rem;
  }
  .lg\:-right-5 {
    right: -1.25rem;
  }
  .lg\:-bottom-5 {
    bottom: -1.25rem;
  }
  .lg\:-left-5 {
    left: -1.25rem;
  }
  .lg\:-top-6 {
    top: -1.5rem;
  }
  .lg\:-right-6 {
    right: -1.5rem;
  }
  .lg\:-bottom-6 {
    bottom: -1.5rem;
  }
  .lg\:-left-6 {
    left: -1.5rem;
  }
  .lg\:-top-7 {
    top: -1.75rem;
  }
  .lg\:-right-7 {
    right: -1.75rem;
  }
  .lg\:-bottom-7 {
    bottom: -1.75rem;
  }
  .lg\:-left-7 {
    left: -1.75rem;
  }
  .lg\:-top-8 {
    top: -2rem;
  }
  .lg\:-right-8 {
    right: -2rem;
  }
  .lg\:-bottom-8 {
    bottom: -2rem;
  }
  .lg\:-left-8 {
    left: -2rem;
  }
  .lg\:-top-9 {
    top: -2.25rem;
  }
  .lg\:-right-9 {
    right: -2.25rem;
  }
  .lg\:-bottom-9 {
    bottom: -2.25rem;
  }
  .lg\:-left-9 {
    left: -2.25rem;
  }
  .lg\:-top-10 {
    top: -2.5rem;
  }
  .lg\:-right-10 {
    right: -2.5rem;
  }
  .lg\:-bottom-10 {
    bottom: -2.5rem;
  }
  .lg\:-left-10 {
    left: -2.5rem;
  }
  .lg\:-top-11 {
    top: -2.75rem;
  }
  .lg\:-right-11 {
    right: -2.75rem;
  }
  .lg\:-bottom-11 {
    bottom: -2.75rem;
  }
  .lg\:-left-11 {
    left: -2.75rem;
  }
  .lg\:-top-12 {
    top: -3rem;
  }
  .lg\:-right-12 {
    right: -3rem;
  }
  .lg\:-bottom-12 {
    bottom: -3rem;
  }
  .lg\:-left-12 {
    left: -3rem;
  }
  .lg\:-top-14 {
    top: -3.5rem;
  }
  .lg\:-right-14 {
    right: -3.5rem;
  }
  .lg\:-bottom-14 {
    bottom: -3.5rem;
  }
  .lg\:-left-14 {
    left: -3.5rem;
  }
  .lg\:-top-16 {
    top: -4rem;
  }
  .lg\:-right-16 {
    right: -4rem;
  }
  .lg\:-bottom-16 {
    bottom: -4rem;
  }
  .lg\:-left-16 {
    left: -4rem;
  }
  .lg\:-top-20 {
    top: -5rem;
  }
  .lg\:-right-20 {
    right: -5rem;
  }
  .lg\:-bottom-20 {
    bottom: -5rem;
  }
  .lg\:-left-20 {
    left: -5rem;
  }
  .lg\:-top-24 {
    top: -6rem;
  }
  .lg\:-right-24 {
    right: -6rem;
  }
  .lg\:-bottom-24 {
    bottom: -6rem;
  }
  .lg\:-left-24 {
    left: -6rem;
  }
  .lg\:-top-28 {
    top: -7rem;
  }
  .lg\:-right-28 {
    right: -7rem;
  }
  .lg\:-bottom-28 {
    bottom: -7rem;
  }
  .lg\:-left-28 {
    left: -7rem;
  }
  .lg\:-top-32 {
    top: -8rem;
  }
  .lg\:-right-32 {
    right: -8rem;
  }
  .lg\:-bottom-32 {
    bottom: -8rem;
  }
  .lg\:-left-32 {
    left: -8rem;
  }
  .lg\:-top-36 {
    top: -9rem;
  }
  .lg\:-right-36 {
    right: -9rem;
  }
  .lg\:-bottom-36 {
    bottom: -9rem;
  }
  .lg\:-left-36 {
    left: -9rem;
  }
  .lg\:-top-40 {
    top: -10rem;
  }
  .lg\:-right-40 {
    right: -10rem;
  }
  .lg\:-bottom-40 {
    bottom: -10rem;
  }
  .lg\:-left-40 {
    left: -10rem;
  }
  .lg\:-top-44 {
    top: -11rem;
  }
  .lg\:-right-44 {
    right: -11rem;
  }
  .lg\:-bottom-44 {
    bottom: -11rem;
  }
  .lg\:-left-44 {
    left: -11rem;
  }
  .lg\:-top-48 {
    top: -12rem;
  }
  .lg\:-right-48 {
    right: -12rem;
  }
  .lg\:-bottom-48 {
    bottom: -12rem;
  }
  .lg\:-left-48 {
    left: -12rem;
  }
  .lg\:-top-52 {
    top: -13rem;
  }
  .lg\:-right-52 {
    right: -13rem;
  }
  .lg\:-bottom-52 {
    bottom: -13rem;
  }
  .lg\:-left-52 {
    left: -13rem;
  }
  .lg\:-top-56 {
    top: -14rem;
  }
  .lg\:-right-56 {
    right: -14rem;
  }
  .lg\:-bottom-56 {
    bottom: -14rem;
  }
  .lg\:-left-56 {
    left: -14rem;
  }
  .lg\:-top-60 {
    top: -15rem;
  }
  .lg\:-right-60 {
    right: -15rem;
  }
  .lg\:-bottom-60 {
    bottom: -15rem;
  }
  .lg\:-left-60 {
    left: -15rem;
  }
  .lg\:-top-64 {
    top: -16rem;
  }
  .lg\:-right-64 {
    right: -16rem;
  }
  .lg\:-bottom-64 {
    bottom: -16rem;
  }
  .lg\:-left-64 {
    left: -16rem;
  }
  .lg\:-top-72 {
    top: -18rem;
  }
  .lg\:-right-72 {
    right: -18rem;
  }
  .lg\:-bottom-72 {
    bottom: -18rem;
  }
  .lg\:-left-72 {
    left: -18rem;
  }
  .lg\:-top-80 {
    top: -20rem;
  }
  .lg\:-right-80 {
    right: -20rem;
  }
  .lg\:-bottom-80 {
    bottom: -20rem;
  }
  .lg\:-left-80 {
    left: -20rem;
  }
  .lg\:-top-96 {
    top: -24rem;
  }
  .lg\:-right-96 {
    right: -24rem;
  }
  .lg\:-bottom-96 {
    bottom: -24rem;
  }
  .lg\:-left-96 {
    left: -24rem;
  }
  .lg\:-top-px {
    top: -1px;
  }
  .lg\:-right-px {
    right: -1px;
  }
  .lg\:-bottom-px {
    bottom: -1px;
  }
  .lg\:-left-px {
    left: -1px;
  }
  .lg\:-top-0\.5 {
    top: -0.125rem;
  }
  .lg\:-right-0\.5 {
    right: -0.125rem;
  }
  .lg\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .lg\:-left-0\.5 {
    left: -0.125rem;
  }
  .lg\:-top-1\.5 {
    top: -0.375rem;
  }
  .lg\:-right-1\.5 {
    right: -0.375rem;
  }
  .lg\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .lg\:-left-1\.5 {
    left: -0.375rem;
  }
  .lg\:-top-2\.5 {
    top: -0.625rem;
  }
  .lg\:-right-2\.5 {
    right: -0.625rem;
  }
  .lg\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .lg\:-left-2\.5 {
    left: -0.625rem;
  }
  .lg\:-top-3\.5 {
    top: -0.875rem;
  }
  .lg\:-right-3\.5 {
    right: -0.875rem;
  }
  .lg\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .lg\:-left-3\.5 {
    left: -0.875rem;
  }
  .lg\:top-1\/2 {
    top: 50%;
  }
  .lg\:right-1\/2 {
    right: 50%;
  }
  .lg\:bottom-1\/2 {
    bottom: 50%;
  }
  .lg\:left-1\/2 {
    left: 50%;
  }
  .lg\:top-1\/3 {
    top: 33.333333%;
  }
  .lg\:right-1\/3 {
    right: 33.333333%;
  }
  .lg\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .lg\:left-1\/3 {
    left: 33.333333%;
  }
  .lg\:top-2\/3 {
    top: 66.666667%;
  }
  .lg\:right-2\/3 {
    right: 66.666667%;
  }
  .lg\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .lg\:left-2\/3 {
    left: 66.666667%;
  }
  .lg\:top-1\/4 {
    top: 25%;
  }
  .lg\:right-1\/4 {
    right: 25%;
  }
  .lg\:bottom-1\/4 {
    bottom: 25%;
  }
  .lg\:left-1\/4 {
    left: 25%;
  }
  .lg\:top-2\/4 {
    top: 50%;
  }
  .lg\:right-2\/4 {
    right: 50%;
  }
  .lg\:bottom-2\/4 {
    bottom: 50%;
  }
  .lg\:left-2\/4 {
    left: 50%;
  }
  .lg\:top-3\/4 {
    top: 75%;
  }
  .lg\:right-3\/4 {
    right: 75%;
  }
  .lg\:bottom-3\/4 {
    bottom: 75%;
  }
  .lg\:left-3\/4 {
    left: 75%;
  }
  .lg\:top-full {
    top: 100%;
  }
  .lg\:right-full {
    right: 100%;
  }
  .lg\:bottom-full {
    bottom: 100%;
  }
  .lg\:left-full {
    left: 100%;
  }
  .lg\:-top-1\/2 {
    top: -50%;
  }
  .lg\:-right-1\/2 {
    right: -50%;
  }
  .lg\:-bottom-1\/2 {
    bottom: -50%;
  }
  .lg\:-left-1\/2 {
    left: -50%;
  }
  .lg\:-top-1\/3 {
    top: -33.333333%;
  }
  .lg\:-right-1\/3 {
    right: -33.333333%;
  }
  .lg\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .lg\:-left-1\/3 {
    left: -33.333333%;
  }
  .lg\:-top-2\/3 {
    top: -66.666667%;
  }
  .lg\:-right-2\/3 {
    right: -66.666667%;
  }
  .lg\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .lg\:-left-2\/3 {
    left: -66.666667%;
  }
  .lg\:-top-1\/4 {
    top: -25%;
  }
  .lg\:-right-1\/4 {
    right: -25%;
  }
  .lg\:-bottom-1\/4 {
    bottom: -25%;
  }
  .lg\:-left-1\/4 {
    left: -25%;
  }
  .lg\:-top-2\/4 {
    top: -50%;
  }
  .lg\:-right-2\/4 {
    right: -50%;
  }
  .lg\:-bottom-2\/4 {
    bottom: -50%;
  }
  .lg\:-left-2\/4 {
    left: -50%;
  }
  .lg\:-top-3\/4 {
    top: -75%;
  }
  .lg\:-right-3\/4 {
    right: -75%;
  }
  .lg\:-bottom-3\/4 {
    bottom: -75%;
  }
  .lg\:-left-3\/4 {
    left: -75%;
  }
  .lg\:-top-full {
    top: -100%;
  }
  .lg\:-right-full {
    right: -100%;
  }
  .lg\:-bottom-full {
    bottom: -100%;
  }
  .lg\:-left-full {
    left: -100%;
  }
  .lg\:resize-none {
    resize: none;
  }
  .lg\:resize-y {
    resize: vertical;
  }
  .lg\:resize-x {
    resize: horizontal;
  }
  .lg\:resize {
    resize: both;
  }
  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .lg\:shadow,
  .lg\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .lg\:shadow-lg,
  .lg\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .lg\:shadow-2xl,
  .lg\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:shadow-inner,
  .lg\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .lg\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .lg\:shadow-bottom-nav,
  .lg\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .lg\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .lg\:group-hover\:shadow-sm,
  .lg\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .lg\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .lg\:group-hover\:shadow,
  .group:hover .lg\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .lg\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .lg\:group-hover\:shadow-lg,
  .group:hover .lg\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .lg\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .lg\:group-hover\:shadow-2xl,
  .group:hover .lg\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .lg\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .lg\:group-hover\:shadow-none,
  .group:hover .lg\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .lg\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .lg\:group-hover\:shadow-bottom-nav,
  .group:hover .lg\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .lg\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .lg\:hover\:shadow-sm:hover,
  .lg\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .lg\:hover\:shadow-lg:hover,
  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .lg\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .lg\:hover\:shadow-2xl:hover,
  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .lg\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:hover\:shadow-inner:hover,
  .lg\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .lg\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .lg\:hover\:shadow-bottom-nav:hover,
  .lg\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .lg\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .lg\:focus\:shadow-sm:focus,
  .lg\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .lg\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:focus\:shadow-md:focus,
  .lg\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .lg\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .lg\:focus\:shadow-lg:focus,
  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .lg\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .lg\:focus\:shadow-2xl:focus,
  .lg\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .lg\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .lg\:focus\:shadow-none:focus,
  .lg\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .lg\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .lg\:focus\:shadow-bottom-nav:focus,
  .lg\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .lg\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring-0,
  .lg\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring-2,
  .lg\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring,
  .lg\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:ring-inset {
    --tw-ring-inset: inset;
  }
  .lg\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .lg\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-0:focus,
  .lg\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-2:focus,
  .lg\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-8:focus,
  .lg\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .lg\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .lg\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .lg\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .lg\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .lg\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .lg\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .lg\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .lg\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .lg\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .lg\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .lg\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .lg\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .lg\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .lg\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .lg\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .lg\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .lg\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .lg\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .lg\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .lg\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .lg\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .lg\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .lg\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .lg\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .lg\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .lg\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .lg\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .lg\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .lg\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .lg\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .lg\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .lg\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .lg\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .lg\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .lg\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .lg\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .lg\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .lg\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .lg\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .lg\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .lg\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .lg\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .lg\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .lg\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .lg\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .lg\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .lg\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .lg\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .lg\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .lg\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .lg\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .lg\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .lg\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .lg\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .lg\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .lg\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .lg\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .lg\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .lg\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .lg\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .lg\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .lg\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .lg\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .lg\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .lg\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .lg\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .lg\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .lg\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .lg\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .lg\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .lg\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .lg\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .lg\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .lg\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .lg\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .lg\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .lg\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .lg\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .lg\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .lg\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .lg\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .lg\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .lg\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .lg\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .lg\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .lg\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .lg\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .lg\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .lg\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .lg\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .lg\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .lg\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .lg\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .lg\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .lg\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .lg\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .lg\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .lg\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .lg\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .lg\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .lg\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .lg\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .lg\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .lg\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .lg\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .lg\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .lg\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .lg\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .lg\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .lg\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .lg\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .lg\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .lg\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .lg\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .lg\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .lg\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .lg\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .lg\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .lg\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .lg\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .lg\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .lg\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .lg\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .lg\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .lg\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .lg\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .lg\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .lg\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .lg\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .lg\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .lg\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .lg\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .lg\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .lg\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .lg\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .lg\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .lg\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .lg\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .lg\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .lg\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .lg\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .lg\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .lg\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .lg\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .lg\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .lg\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .lg\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .lg\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .lg\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .lg\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .lg\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .lg\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .lg\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .lg\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .lg\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .lg\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .lg\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .lg\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .lg\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .lg\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .lg\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .lg\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .lg\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .lg\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .lg\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .lg\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .lg\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .lg\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .lg\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .lg\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .lg\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .lg\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .lg\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .lg\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .lg\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .lg\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .lg\:ring-current {
    --tw-ring-color: currentColor;
  }
  .lg\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .lg\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .lg\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .lg\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .lg\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .lg\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .lg\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .lg\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .lg\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .lg\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .lg\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .lg\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .lg\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .lg\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .lg\:ring-background {
    --tw-ring-color: var(--background);
  }
  .lg\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .lg\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .lg\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .lg\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .lg\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .lg\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .lg\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .lg\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .lg\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .lg\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .lg\:ring-success {
    --tw-ring-color: var(--success);
  }
  .lg\:ring-error {
    --tw-ring-color: var(--error);
  }
  .lg\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .lg\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .lg\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .lg\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .lg\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .lg\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .lg\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .lg\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .lg\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .lg\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .lg\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .lg\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .lg\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .lg\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .lg\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .lg\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .lg\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .lg\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .lg\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .lg\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .lg\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .lg\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .lg\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .lg\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .lg\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .lg\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .lg\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .lg\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .lg\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .lg\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .lg\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .lg\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .lg\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .lg\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .lg\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .lg\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .lg\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .lg\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .lg\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .lg\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .lg\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .lg\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .lg\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .lg\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .lg\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .lg\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .lg\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .lg\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .lg\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .lg\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .lg\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .lg\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .lg\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .lg\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .lg\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .lg\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .lg\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .lg\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .lg\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .lg\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .lg\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .lg\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .lg\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .lg\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .lg\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .lg\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .lg\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .lg\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .lg\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .lg\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .lg\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .lg\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .lg\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .lg\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .lg\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .lg\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .lg\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .lg\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .lg\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .lg\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .lg\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .lg\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .lg\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .lg\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .lg\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .lg\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .lg\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .lg\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .lg\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .lg\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .lg\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .lg\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .lg\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .lg\:fill-current {
    fill: currentColor;
  }
  .lg\:stroke-current {
    stroke: currentColor;
  }
  .lg\:stroke-0 {
    stroke-width: 0;
  }
  .lg\:stroke-1 {
    stroke-width: 1;
  }
  .lg\:stroke-2 {
    stroke-width: 2;
  }
  .lg\:table-auto {
    table-layout: auto;
  }
  .lg\:table-fixed {
    table-layout: fixed;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:text-right {
    text-align: right;
  }
  .lg\:text-justify {
    text-align: justify;
  }
  .lg\:text-transparent {
    color: transparent;
  }
  .lg\:text-current {
    color: currentColor;
  }
  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .lg\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:text-background-primary {
    color: var(--background-primary);
  }
  .lg\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .lg\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .lg\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .lg\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .lg\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .lg\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .lg\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .lg\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .lg\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .lg\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .lg\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .lg\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .lg\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .lg\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .lg\:text-body-background {
    color: var(--body-background);
  }
  .lg\:text-background {
    color: var(--background);
  }
  .lg\:text-input-background {
    color: var(--input-background);
  }
  .lg\:text-body-text {
    color: var(--body-text);
  }
  .lg\:text-header-text {
    color: var(--header-text);
  }
  .lg\:text-primary {
    color: var(--primary);
  }
  .lg\:text-secondary {
    color: var(--secondary);
  }
  .lg\:text-tertiary {
    color: var(--tertiary);
  }
  .lg\:text-primary-hover {
    color: var(--primary-hover);
  }
  .lg\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .lg\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .lg\:text-input-text {
    color: var(--input-text);
  }
  .lg\:text-success {
    color: var(--success);
  }
  .lg\:text-error {
    color: var(--error);
  }
  .lg\:text-custom-loader {
    color: var(--custom-loader);
  }
  .lg\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .lg\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .lg\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .lg\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .lg\:text-background50 {
    color: var(--background50);
  }
  .lg\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .lg\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .lg\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .lg\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .lg\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .lg\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .lg\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .lg\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .lg\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .lg\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .lg\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .lg\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .lg\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .lg\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .lg\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .lg\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .lg\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .lg\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .lg\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .lg\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .lg\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .lg\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .lg\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .lg\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .lg\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .lg\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .lg\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .lg\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .lg\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .lg\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .lg\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .lg\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .lg\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .lg\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .lg\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .lg\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .lg\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .lg\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .lg\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .lg\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .lg\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .lg\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .lg\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .lg\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .lg\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .lg\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .lg\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .lg\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .lg\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .lg\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .lg\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .lg\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .lg\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .lg\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .lg\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .lg\:hover\:text-transparent:hover {
    color: transparent;
  }
  .lg\:hover\:text-current:hover {
    color: currentColor;
  }
  .lg\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .lg\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .lg\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .lg\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .lg\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .lg\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .lg\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .lg\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .lg\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .lg\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .lg\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .lg\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .lg\:hover\:text-background:hover {
    color: var(--background);
  }
  .lg\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .lg\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .lg\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .lg\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .lg\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .lg\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .lg\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .lg\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .lg\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .lg\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .lg\:hover\:text-success:hover {
    color: var(--success);
  }
  .lg\:hover\:text-error:hover {
    color: var(--error);
  }
  .lg\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .lg\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .lg\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .lg\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .lg\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .lg\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .lg\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .lg\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .lg\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .lg\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .lg\:focus\:text-transparent:focus {
    color: transparent;
  }
  .lg\:focus\:text-current:focus {
    color: currentColor;
  }
  .lg\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .lg\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .lg\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .lg\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .lg\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .lg\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .lg\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .lg\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .lg\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .lg\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .lg\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .lg\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .lg\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .lg\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .lg\:focus\:text-background:focus {
    color: var(--background);
  }
  .lg\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .lg\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .lg\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .lg\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .lg\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .lg\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .lg\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .lg\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .lg\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .lg\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .lg\:focus\:text-success:focus {
    color: var(--success);
  }
  .lg\:focus\:text-error:focus {
    color: var(--error);
  }
  .lg\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .lg\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .lg\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .lg\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .lg\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .lg\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .lg\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .lg\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .lg\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .lg\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .lg\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .lg\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .lg\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .lg\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .lg\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .lg\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .lg\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .lg\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .lg\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .lg\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .lg\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .lg\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .lg\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .lg\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .lg\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .lg\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .lg\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .lg\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .lg\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .lg\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .lg\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .lg\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .lg\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .lg\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .lg\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .lg\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .lg\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .lg\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .lg\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .lg\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .lg\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .lg\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .lg\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .lg\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .lg\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .lg\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .lg\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .lg\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .lg\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .lg\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .lg\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .lg\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .lg\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .lg\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .lg\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .lg\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .lg\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .lg\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .lg\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .lg\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .lg\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .lg\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .lg\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .lg\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .lg\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .lg\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .lg\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .lg\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .lg\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .lg\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .lg\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .lg\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .lg\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .lg\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .lg\:overflow-ellipsis,
  .lg\:truncate {
    text-overflow: ellipsis;
  }
  .lg\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .lg\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .lg\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .lg\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .lg\:italic {
    font-style: italic;
  }
  .lg\:not-italic {
    font-style: normal;
  }
  .lg\:uppercase {
    text-transform: uppercase;
  }
  .lg\:lowercase {
    text-transform: lowercase;
  }
  .lg\:capitalize {
    text-transform: capitalize;
  }
  .lg\:normal-case {
    text-transform: none;
  }
  .lg\:underline {
    text-decoration: underline;
  }
  .lg\:line-through {
    text-decoration: line-through;
  }
  .lg\:no-underline {
    text-decoration: none;
  }
  .group:hover .lg\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .lg\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .lg\:group-hover\:no-underline {
    text-decoration: none;
  }
  .lg\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .lg\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .lg\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }
  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }
  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .lg\:diagonal-fractions,
  .lg\:lining-nums,
  .lg\:oldstyle-nums,
  .lg\:ordinal,
  .lg\:proportional-nums,
  .lg\:slashed-zero,
  .lg\:stacked-fractions,
  .lg\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .lg\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .lg\:ordinal {
    --tw-ordinal: ordinal;
  }
  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .lg\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .lg\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .lg\:tracking-normal {
    letter-spacing: 0;
  }
  .lg\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .lg\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .lg\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .lg\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .lg\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .lg\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .lg\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .lg\:align-baseline {
    vertical-align: baseline;
  }
  .lg\:align-top {
    vertical-align: top;
  }
  .lg\:align-middle {
    vertical-align: middle;
  }
  .lg\:align-bottom {
    vertical-align: bottom;
  }
  .lg\:align-text-top {
    vertical-align: text-top;
  }
  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .lg\:visible {
    visibility: visible;
  }
  .lg\:invisible {
    visibility: hidden;
  }
  .lg\:whitespace-normal {
    white-space: normal;
  }
  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }
  .lg\:whitespace-pre {
    white-space: pre;
  }
  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }
  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .lg\:break-words {
    overflow-wrap: break-word;
  }
  .lg\:break-all {
    word-break: break-all;
  }
  .group:hover .lg\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .lg\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .lg\:group-hover\:break-all {
    word-break: break-all;
  }
  .lg\:w-0 {
    width: 0;
  }
  .lg\:w-1 {
    width: 0.25rem;
  }
  .lg\:w-2 {
    width: 0.5rem;
  }
  .lg\:w-3 {
    width: 0.75rem;
  }
  .lg\:w-4 {
    width: 1rem;
  }
  .lg\:w-5 {
    width: 1.25rem;
  }
  .lg\:w-6 {
    width: 1.5rem;
  }
  .lg\:w-7 {
    width: 1.75rem;
  }
  .lg\:w-8 {
    width: 2rem;
  }
  .lg\:w-9 {
    width: 2.25rem;
  }
  .lg\:w-10 {
    width: 2.5rem;
  }
  .lg\:w-11 {
    width: 2.75rem;
  }
  .lg\:w-12 {
    width: 3rem;
  }
  .lg\:w-14 {
    width: 3.5rem;
  }
  .lg\:w-16 {
    width: 4rem;
  }
  .lg\:w-20 {
    width: 5rem;
  }
  .lg\:w-24 {
    width: 6rem;
  }
  .lg\:w-28 {
    width: 7rem;
  }
  .lg\:w-32 {
    width: 8rem;
  }
  .lg\:w-36 {
    width: 9rem;
  }
  .lg\:w-40 {
    width: 10rem;
  }
  .lg\:w-44 {
    width: 11rem;
  }
  .lg\:w-48 {
    width: 12rem;
  }
  .lg\:w-52 {
    width: 13rem;
  }
  .lg\:w-56 {
    width: 14rem;
  }
  .lg\:w-60 {
    width: 15rem;
  }
  .lg\:w-64 {
    width: 16rem;
  }
  .lg\:w-72 {
    width: 18rem;
  }
  .lg\:w-80 {
    width: 20rem;
  }
  .lg\:w-96 {
    width: 24rem;
  }
  .lg\:w-150 {
    width: 150px;
  }
  .lg\:w-199 {
    width: 199px;
  }
  .lg\:w-286 {
    width: 286px;
  }
  .lg\:w-512 {
    width: 512px;
  }
  .lg\:w-560 {
    width: 560px;
  }
  .lg\:w-650 {
    width: 650px;
  }
  .lg\:w-auto {
    width: auto;
  }
  .lg\:w-px {
    width: 1px;
  }
  .lg\:w-0\.5 {
    width: 0.125rem;
  }
  .lg\:w-1\.5 {
    width: 0.375rem;
  }
  .lg\:w-2\.5 {
    width: 0.625rem;
  }
  .lg\:w-3\.5 {
    width: 0.875rem;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
  .lg\:w-1\/4 {
    width: 25%;
  }
  .lg\:w-2\/4 {
    width: 50%;
  }
  .lg\:w-3\/4 {
    width: 75%;
  }
  .lg\:w-1\/5 {
    width: 20%;
  }
  .lg\:w-2\/5 {
    width: 40%;
  }
  .lg\:w-3\/5 {
    width: 60%;
  }
  .lg\:w-4\/5 {
    width: 80%;
  }
  .lg\:w-1\/6 {
    width: 16.666667%;
  }
  .lg\:w-2\/6 {
    width: 33.333333%;
  }
  .lg\:w-3\/6 {
    width: 50%;
  }
  .lg\:w-4\/6 {
    width: 66.666667%;
  }
  .lg\:w-5\/6 {
    width: 83.333333%;
  }
  .lg\:w-1\/12 {
    width: 8.333333%;
  }
  .lg\:w-2\/12 {
    width: 16.666667%;
  }
  .lg\:w-3\/12 {
    width: 25%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
  .lg\:w-5\/12 {
    width: 41.666667%;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
  .lg\:w-8\/12 {
    width: 66.666667%;
  }
  .lg\:w-9\/12 {
    width: 75%;
  }
  .lg\:w-10\/12 {
    width: 83.333333%;
  }
  .lg\:w-11\/12 {
    width: 91.666667%;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:w-screen {
    width: 100vw;
  }
  .lg\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .lg\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .lg\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .lg\:z-0 {
    z-index: 0;
  }
  .lg\:z-10 {
    z-index: 10;
  }
  .lg\:z-20 {
    z-index: 20;
  }
  .lg\:z-30 {
    z-index: 30;
  }
  .lg\:z-40 {
    z-index: 40;
  }
  .lg\:z-50 {
    z-index: 50;
  }
  .lg\:z-auto {
    z-index: auto;
  }
  .lg\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .lg\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .lg\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .lg\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .lg\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .lg\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .lg\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .lg\:focus\:z-0:focus {
    z-index: 0;
  }
  .lg\:focus\:z-10:focus {
    z-index: 10;
  }
  .lg\:focus\:z-20:focus {
    z-index: 20;
  }
  .lg\:focus\:z-30:focus {
    z-index: 30;
  }
  .lg\:focus\:z-40:focus {
    z-index: 40;
  }
  .lg\:focus\:z-50:focus {
    z-index: 50;
  }
  .lg\:focus\:z-auto:focus {
    z-index: auto;
  }
  .lg\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .lg\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .lg\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .lg\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .lg\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .lg\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .lg\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .lg\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .lg\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .lg\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .lg\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .lg\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .lg\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .lg\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .lg\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .lg\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .lg\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .lg\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .lg\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .lg\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .lg\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .lg\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .lg\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .lg\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .lg\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .lg\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .lg\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .lg\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .lg\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .lg\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .lg\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .lg\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .lg\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .lg\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .lg\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .lg\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .lg\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .lg\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .lg\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .lg\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .lg\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .lg\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .lg\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .lg\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .lg\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .lg\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .lg\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .lg\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .lg\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .lg\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .lg\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .lg\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .lg\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .lg\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .lg\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .lg\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .lg\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .lg\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .lg\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .lg\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .lg\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .lg\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .lg\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .lg\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .lg\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .lg\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .lg\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .lg\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .lg\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .lg\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .lg\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .lg\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .lg\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .lg\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .lg\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .lg\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .lg\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .lg\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .lg\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .lg\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .lg\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .lg\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .lg\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .lg\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .lg\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .lg\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .lg\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .lg\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .lg\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .lg\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .lg\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .lg\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .lg\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .lg\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .lg\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .lg\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .lg\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .lg\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }
  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }
  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg\:grid-cols-none {
    grid-template-columns: none;
  }
  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .lg\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .lg\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .lg\:col-auto {
    grid-column: auto;
  }
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-start-7 {
    grid-column-start: 7;
  }
  .lg\:col-start-8 {
    grid-column-start: 8;
  }
  .lg\:col-start-9 {
    grid-column-start: 9;
  }
  .lg\:col-start-10 {
    grid-column-start: 10;
  }
  .lg\:col-start-11 {
    grid-column-start: 11;
  }
  .lg\:col-start-12 {
    grid-column-start: 12;
  }
  .lg\:col-start-13 {
    grid-column-start: 13;
  }
  .lg\:col-start-auto {
    grid-column-start: auto;
  }
  .lg\:col-end-1 {
    grid-column-end: 1;
  }
  .lg\:col-end-2 {
    grid-column-end: 2;
  }
  .lg\:col-end-3 {
    grid-column-end: 3;
  }
  .lg\:col-end-4 {
    grid-column-end: 4;
  }
  .lg\:col-end-5 {
    grid-column-end: 5;
  }
  .lg\:col-end-6 {
    grid-column-end: 6;
  }
  .lg\:col-end-7 {
    grid-column-end: 7;
  }
  .lg\:col-end-8 {
    grid-column-end: 8;
  }
  .lg\:col-end-9 {
    grid-column-end: 9;
  }
  .lg\:col-end-10 {
    grid-column-end: 10;
  }
  .lg\:col-end-11 {
    grid-column-end: 11;
  }
  .lg\:col-end-12 {
    grid-column-end: 12;
  }
  .lg\:col-end-13 {
    grid-column-end: 13;
  }
  .lg\:col-end-auto {
    grid-column-end: auto;
  }
  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-rows-none {
    grid-template-rows: none;
  }
  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .lg\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .lg\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .lg\:row-auto {
    grid-row: auto;
  }
  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .lg\:row-span-full {
    grid-row: 1/-1;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:row-start-2 {
    grid-row-start: 2;
  }
  .lg\:row-start-3 {
    grid-row-start: 3;
  }
  .lg\:row-start-4 {
    grid-row-start: 4;
  }
  .lg\:row-start-5 {
    grid-row-start: 5;
  }
  .lg\:row-start-6 {
    grid-row-start: 6;
  }
  .lg\:row-start-7 {
    grid-row-start: 7;
  }
  .lg\:row-start-auto {
    grid-row-start: auto;
  }
  .lg\:row-end-1 {
    grid-row-end: 1;
  }
  .lg\:row-end-2 {
    grid-row-end: 2;
  }
  .lg\:row-end-3 {
    grid-row-end: 3;
  }
  .lg\:row-end-4 {
    grid-row-end: 4;
  }
  .lg\:row-end-5 {
    grid-row-end: 5;
  }
  .lg\:row-end-6 {
    grid-row-end: 6;
  }
  .lg\:row-end-7 {
    grid-row-end: 7;
  }
  .lg\:row-end-auto {
    grid-row-end: auto;
  }
  .lg\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .lg\:transform,
  .lg\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .lg\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .lg\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .lg\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .lg\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .lg\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .lg\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .lg\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .lg\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .lg\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .lg\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .lg\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .lg\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .lg\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .lg\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .lg\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .lg\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .lg\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .lg\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .lg\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .lg\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .lg\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .lg\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .lg\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .lg\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .lg\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .lg\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .lg\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .lg\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .lg\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .lg\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .lg\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .lg\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .lg\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .lg\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .lg\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .lg\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .lg\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .lg\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .lg\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .lg\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .lg\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .lg\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .lg\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .lg\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .lg\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .lg\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .lg\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .lg\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .lg\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .lg\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .lg\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .lg\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .lg\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .lg\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .lg\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .lg\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .lg\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .lg\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .lg\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .lg\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .lg\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .lg\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .lg\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .lg\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .lg\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .lg\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .lg\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .lg\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .lg\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .lg\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .lg\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .lg\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .lg\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .lg\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .lg\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .lg\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .lg\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .lg\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .lg\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .lg\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .lg\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .lg\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .lg\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .lg\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .lg\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .lg\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .lg\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .lg\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .lg\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .lg\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .lg\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .lg\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .lg\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .lg\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .lg\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .lg\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .lg\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .lg\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .lg\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .lg\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .lg\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .lg\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .lg\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .lg\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .lg\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .lg\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .lg\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .lg\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .lg\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .lg\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .lg\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .lg\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .lg\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .lg\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .lg\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .lg\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .lg\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .lg\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .lg\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .lg\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .lg\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .lg\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .lg\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .lg\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .lg\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .lg\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .lg\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .lg\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .lg\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .lg\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .lg\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .lg\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .lg\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .lg\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .lg\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .lg\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .lg\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .lg\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .lg\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .lg\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .lg\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .lg\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .lg\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .lg\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .lg\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .lg\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .lg\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .lg\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .lg\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .lg\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .lg\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .lg\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .lg\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .lg\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .lg\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .lg\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .lg\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .lg\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .lg\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .lg\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .lg\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .lg\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .lg\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .lg\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .lg\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .lg\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .lg\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .lg\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .lg\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .lg\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .lg\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .lg\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .lg\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .lg\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .lg\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .lg\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .lg\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .lg\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .lg\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .lg\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .lg\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .lg\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .lg\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .lg\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .lg\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .lg\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .lg\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .lg\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .lg\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .lg\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .lg\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .lg\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .lg\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .lg\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .lg\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .lg\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .lg\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .lg\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .lg\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .lg\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .lg\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .lg\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .lg\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .lg\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .lg\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .lg\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .lg\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .lg\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .lg\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .lg\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .lg\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .lg\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .lg\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .lg\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .lg\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .lg\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .lg\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .lg\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .lg\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .lg\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .lg\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .lg\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .lg\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .lg\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .lg\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .lg\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .lg\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .lg\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .lg\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .lg\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .lg\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .lg\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .lg\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .lg\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .lg\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .lg\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .lg\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .lg\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .lg\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .lg\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .lg\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .lg\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .lg\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .lg\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .lg\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .lg\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .lg\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .lg\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .lg\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .lg\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .lg\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .lg\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .lg\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .lg\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .lg\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .lg\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .lg\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .lg\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .lg\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .lg\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .lg\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .lg\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .lg\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .lg\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .lg\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .lg\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .lg\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .lg\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .lg\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .lg\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .lg\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .lg\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .lg\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .lg\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .lg\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .lg\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .lg\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .lg\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .lg\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .lg\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .lg\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .lg\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .lg\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .lg\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .lg\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .lg\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .lg\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .lg\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .lg\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .lg\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .lg\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .lg\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .lg\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .lg\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .lg\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .lg\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .lg\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .lg\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .lg\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .lg\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .lg\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .lg\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .lg\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .lg\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .lg\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .lg\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .lg\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .lg\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .lg\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .lg\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .lg\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .lg\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .lg\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .lg\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .lg\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .lg\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .lg\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .lg\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .lg\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .lg\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .lg\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .lg\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .lg\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .lg\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .lg\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .lg\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .lg\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .lg\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .lg\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .lg\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .lg\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .lg\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .lg\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .lg\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .lg\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .lg\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .lg\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .lg\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .lg\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .lg\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .lg\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .lg\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .lg\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .lg\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .lg\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .lg\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .lg\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .lg\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .lg\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .lg\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .lg\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .lg\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .lg\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .lg\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .lg\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .lg\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .lg\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .lg\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .lg\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .lg\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .lg\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .lg\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .lg\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .lg\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .lg\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .lg\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .lg\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .lg\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .lg\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .lg\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .lg\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .lg\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .lg\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .lg\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .lg\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .lg\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .lg\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .lg\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .lg\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .lg\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .lg\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .lg\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .lg\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .lg\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .lg\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .lg\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .lg\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .lg\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .lg\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .lg\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .lg\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .lg\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .lg\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .lg\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .lg\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .lg\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .lg\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .lg\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .lg\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .lg\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .lg\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .lg\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .lg\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .lg\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .lg\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .lg\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .lg\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .lg\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .lg\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .lg\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .lg\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .lg\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .lg\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .lg\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .lg\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .lg\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .lg\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .lg\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .lg\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .lg\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .lg\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .lg\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .lg\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .lg\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .lg\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .lg\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .lg\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .lg\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .lg\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .lg\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .lg\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .lg\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .lg\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .lg\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .lg\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .lg\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .lg\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .lg\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .lg\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .lg\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .lg\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .lg\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .lg\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .lg\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .lg\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .lg\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .lg\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .lg\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .lg\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .lg\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .lg\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .lg\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .lg\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .lg\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .lg\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .lg\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .lg\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .lg\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .lg\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .lg\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .lg\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .lg\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .lg\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .lg\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .lg\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .lg\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .lg\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .lg\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .lg\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .lg\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .lg\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .lg\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .lg\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .lg\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .lg\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .lg\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .lg\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .lg\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .lg\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .lg\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .lg\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .lg\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .lg\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .lg\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .lg\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .lg\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .lg\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .lg\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .lg\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .lg\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .lg\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .lg\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .lg\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .lg\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .lg\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .lg\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .lg\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .lg\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .lg\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .lg\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .lg\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .lg\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .lg\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .lg\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .lg\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .lg\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .lg\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .lg\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .lg\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .lg\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .lg\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .lg\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .lg\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .lg\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .lg\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .lg\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .lg\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .lg\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .lg\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .lg\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .lg\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .lg\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .lg\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .lg\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .lg\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .lg\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .lg\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .lg\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .lg\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .lg\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .lg\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .lg\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .lg\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .lg\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .lg\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .lg\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .lg\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .lg\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .lg\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .lg\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .lg\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .lg\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .lg\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .lg\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .lg\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .lg\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .lg\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .lg\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .lg\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .lg\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .lg\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .lg\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .lg\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .lg\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .lg\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .lg\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .lg\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .lg\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .lg\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .lg\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .lg\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .lg\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .lg\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .lg\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .lg\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .lg\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .lg\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .lg\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .lg\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .lg\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .lg\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .lg\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .lg\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .lg\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .lg\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .lg\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .lg\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .lg\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .lg\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .lg\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .lg\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .lg\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .lg\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .lg\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .lg\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .lg\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .lg\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .lg\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .lg\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .lg\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .lg\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .lg\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .lg\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .lg\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .lg\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .lg\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .lg\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .lg\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .lg\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .lg\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .lg\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .lg\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .lg\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .lg\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .lg\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .lg\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .lg\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .lg\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .lg\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .lg\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .lg\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .lg\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .lg\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .lg\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .lg\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .lg\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .lg\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .lg\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .lg\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .lg\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .lg\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .lg\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .lg\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .lg\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .lg\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .lg\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .lg\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .lg\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .lg\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .lg\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .lg\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .lg\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .lg\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .lg\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .lg\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .lg\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .lg\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .lg\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .lg\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .lg\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .lg\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .lg\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .lg\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .lg\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .lg\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .lg\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .lg\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .lg\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .lg\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .lg\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .lg\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .lg\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .lg\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .lg\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .lg\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .lg\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .lg\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .lg\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .lg\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .lg\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .lg\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .lg\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .lg\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .lg\:transition-none {
    transition-property: none;
  }
  .lg\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .lg\:ease-linear {
    transition-timing-function: linear;
  }
  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .lg\:duration-75 {
    transition-duration: 75ms;
  }
  .lg\:duration-100 {
    transition-duration: 0.1s;
  }
  .lg\:duration-150 {
    transition-duration: 0.15s;
  }
  .lg\:duration-200 {
    transition-duration: 0.2s;
  }
  .lg\:duration-300 {
    transition-duration: 0.3s;
  }
  .lg\:duration-500 {
    transition-duration: 0.5s;
  }
  .lg\:duration-700 {
    transition-duration: 0.7s;
  }
  .lg\:duration-1000 {
    transition-duration: 1s;
  }
  .lg\:delay-75 {
    transition-delay: 75ms;
  }
  .lg\:delay-100 {
    transition-delay: 0.1s;
  }
  .lg\:delay-150 {
    transition-delay: 0.15s;
  }
  .lg\:delay-200 {
    transition-delay: 0.2s;
  }
  .lg\:delay-300 {
    transition-delay: 0.3s;
  }
  .lg\:delay-500 {
    transition-delay: 0.5s;
  }
  .lg\:delay-700 {
    transition-delay: 0.7s;
  }
  .lg\:delay-1000 {
    transition-delay: 1s;
  }
  .lg\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .lg\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .lg\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .lg\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .lg\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .xl\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .xl\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .xl\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
  .xl\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .xl\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xl\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .xl\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .xl\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xl\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .xl\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .xl\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .xl\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .xl\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xl\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .xl\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .xl\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .xl\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xl\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .xl\:prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xl\:prose h3,
  .xl\:prose h4 {
    color: #6d728b;
    font-weight: 600;
  }
  .xl\:prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .xl\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .xl\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .xl\:prose code:after,
  .xl\:prose code:before {
    content: '`';
  }
  .xl\:prose a code {
    color: #6d728b;
  }
  .xl\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .xl\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .xl\:prose pre code:after,
  .xl\:prose pre code:before {
    content: '';
  }
  .xl\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .xl\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .xl\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .xl\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .xl\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .xl\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .xl\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xl\:prose figure,
  .xl\:prose img,
  .xl\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xl\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:prose h2 code {
    font-size: 0.875em;
  }
  .xl\:prose h3 code {
    font-size: 0.9em;
  }
  .xl\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xl\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xl\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xl\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .xl\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xl\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .xl\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xl\:prose ol ol,
  .xl\:prose ol ul,
  .xl\:prose ul ol,
  .xl\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xl\:prose h2 + *,
  .xl\:prose h3 + *,
  .xl\:prose h4 + *,
  .xl\:prose hr + * {
    margin-top: 0;
  }
  .xl\:prose thead th:first-child {
    padding-left: 0;
  }
  .xl\:prose thead th:last-child {
    padding-right: 0;
  }
  .xl\:prose tbody td:first-child {
    padding-left: 0;
  }
  .xl\:prose tbody td:last-child {
    padding-right: 0;
  }
  .xl\:prose > :first-child {
    margin-top: 0;
  }
  .xl\:prose > :last-child {
    margin-bottom: 0;
  }
  .xl\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .xl\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xl\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xl\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .xl\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .xl\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .xl\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xl\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .xl\:prose-sm figure,
  .xl\:prose-sm img,
  .xl\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .xl\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .xl\:prose-sm code {
    font-size: 0.8571429em;
  }
  .xl\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .xl\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .xl\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .xl\:prose-sm ol,
  .xl\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xl\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .xl\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .xl\:prose-sm ol > li:before {
    left: 0;
  }
  .xl\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .xl\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .xl\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xl\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xl\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xl\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xl\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xl\:prose-sm ol ol,
  .xl\:prose-sm ol ul,
  .xl\:prose-sm ul ol,
  .xl\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xl\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .xl\:prose-sm h2 + *,
  .xl\:prose-sm h3 + *,
  .xl\:prose-sm h4 + *,
  .xl\:prose-sm hr + * {
    margin-top: 0;
  }
  .xl\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .xl\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .xl\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .xl\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .xl\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .xl\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .xl\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .xl\:prose-sm > :first-child {
    margin-top: 0;
  }
  .xl\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .xl\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .xl\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .xl\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .xl\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .xl\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .xl\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xl\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xl\:prose-lg figure,
  .xl\:prose-lg img,
  .xl\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .xl\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .xl\:prose-lg code {
    font-size: 0.8888889em;
  }
  .xl\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .xl\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .xl\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .xl\:prose-lg ol,
  .xl\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xl\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .xl\:prose-lg ol > li:before {
    left: 0;
  }
  .xl\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .xl\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .xl\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xl\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xl\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xl\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-lg ol ol,
  .xl\:prose-lg ol ul,
  .xl\:prose-lg ul ol,
  .xl\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xl\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .xl\:prose-lg h2 + *,
  .xl\:prose-lg h3 + *,
  .xl\:prose-lg h4 + *,
  .xl\:prose-lg hr + * {
    margin-top: 0;
  }
  .xl\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .xl\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .xl\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .xl\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .xl\:prose-lg tbody td {
    padding: 0.75em;
  }
  .xl\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .xl\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .xl\:prose-lg > :first-child {
    margin-top: 0;
  }
  .xl\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .xl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .xl\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xl\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .xl\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .xl\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .xl\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xl\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .xl\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xl\:prose-xl figure,
  .xl\:prose-xl img,
  .xl\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xl\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .xl\:prose-xl code {
    font-size: 0.9em;
  }
  .xl\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .xl\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .xl\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .xl\:prose-xl ol,
  .xl\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xl\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .xl\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .xl\:prose-xl ol > li:before {
    left: 0;
  }
  .xl\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .xl\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .xl\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xl\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .xl\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xl\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .xl\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xl\:prose-xl ol ol,
  .xl\:prose-xl ol ul,
  .xl\:prose-xl ul ol,
  .xl\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xl\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .xl\:prose-xl h2 + *,
  .xl\:prose-xl h3 + *,
  .xl\:prose-xl h4 + *,
  .xl\:prose-xl hr + * {
    margin-top: 0;
  }
  .xl\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .xl\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .xl\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .xl\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .xl\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .xl\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .xl\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .xl\:prose-xl > :first-child {
    margin-top: 0;
  }
  .xl\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .xl\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .xl\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .xl\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .xl\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .xl\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .xl\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .xl\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xl\:prose-2xl figure,
  .xl\:prose-2xl img,
  .xl\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xl\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .xl\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .xl\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .xl\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .xl\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .xl\:prose-2xl ol,
  .xl\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xl\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .xl\:prose-2xl ol > li:before {
    left: 0;
  }
  .xl\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .xl\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .xl\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .xl\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xl\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xl\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xl\:prose-2xl ol ol,
  .xl\:prose-2xl ol ul,
  .xl\:prose-2xl ul ol,
  .xl\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xl\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xl\:prose-2xl h2 + *,
  .xl\:prose-2xl h3 + *,
  .xl\:prose-2xl h4 + *,
  .xl\:prose-2xl hr + * {
    margin-top: 0;
  }
  .xl\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .xl\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .xl\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .xl\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .xl\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .xl\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .xl\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .xl\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .xl\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .xl\:aspect-w-1,
  .xl\:aspect-w-2,
  .xl\:aspect-w-3,
  .xl\:aspect-w-4,
  .xl\:aspect-w-5,
  .xl\:aspect-w-6,
  .xl\:aspect-w-7,
  .xl\:aspect-w-8,
  .xl\:aspect-w-9,
  .xl\:aspect-w-10,
  .xl\:aspect-w-11,
  .xl\:aspect-w-12,
  .xl\:aspect-w-13,
  .xl\:aspect-w-14,
  .xl\:aspect-w-15,
  .xl\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .xl\:aspect-w-1 > *,
  .xl\:aspect-w-2 > *,
  .xl\:aspect-w-3 > *,
  .xl\:aspect-w-4 > *,
  .xl\:aspect-w-5 > *,
  .xl\:aspect-w-6 > *,
  .xl\:aspect-w-7 > *,
  .xl\:aspect-w-8 > *,
  .xl\:aspect-w-9 > *,
  .xl\:aspect-w-10 > *,
  .xl\:aspect-w-11 > *,
  .xl\:aspect-w-12 > *,
  .xl\:aspect-w-13 > *,
  .xl\:aspect-w-14 > *,
  .xl\:aspect-w-15 > *,
  .xl\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xl\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .xl\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xl\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .xl\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .xl\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .xl\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .xl\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .xl\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .xl\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .xl\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .xl\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .xl\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .xl\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .xl\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .xl\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .xl\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .xl\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .xl\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .xl\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .xl\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .xl\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .xl\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .xl\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .xl\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .xl\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .xl\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .xl\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .xl\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .xl\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .xl\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .xl\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .xl\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .xl\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .xl\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .xl\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xl\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .xl\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .xl\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .xl\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .xl\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .xl\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .xl\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .xl\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .xl\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .xl\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .xl\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .xl\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .xl\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .xl\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .xl\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .xl\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .xl\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .xl\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .xl\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .xl\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .xl\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .xl\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .xl\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .xl\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .xl\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .xl\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .xl\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .xl\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .xl\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .xl\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .xl\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .xl\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .xl\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xl\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .xl\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .xl\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .xl\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .xl\:bg-fixed {
    background-attachment: fixed;
  }
  .xl\:bg-local {
    background-attachment: local;
  }
  .xl\:bg-scroll {
    background-attachment: scroll;
  }
  .xl\:bg-clip-border {
    background-clip: border-box;
  }
  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }
  .xl\:bg-clip-content {
    background-clip: content-box;
  }
  .xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .xl\:bg-transparent {
    background-color: transparent;
  }
  .xl\:bg-current {
    background-color: currentColor;
  }
  .xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .xl\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xl\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xl\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xl\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xl\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xl\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xl\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xl\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xl\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xl\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xl\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xl\:bg-body-background {
    background-color: var(--body-background);
  }
  .xl\:bg-background {
    background-color: var(--background);
  }
  .xl\:bg-input-background {
    background-color: var(--input-background);
  }
  .xl\:bg-body-text {
    background-color: var(--body-text);
  }
  .xl\:bg-header-text {
    background-color: var(--header-text);
  }
  .xl\:bg-primary {
    background-color: var(--primary);
  }
  .xl\:bg-secondary {
    background-color: var(--secondary);
  }
  .xl\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .xl\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .xl\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .xl\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .xl\:bg-input-text {
    background-color: var(--input-text);
  }
  .xl\:bg-success {
    background-color: var(--success);
  }
  .xl\:bg-error {
    background-color: var(--error);
  }
  .xl\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .xl\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xl\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .xl\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .xl\:bg-background50 {
    background-color: var(--background50);
  }
  .xl\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xl\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xl\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .xl\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xl\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .xl\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .xl\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .xl\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .xl\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .xl\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .xl\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .xl\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .xl\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .xl\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .xl\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .xl\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .xl\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .xl\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .xl\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .xl\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .xl\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .xl\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .xl\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .xl\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .xl\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .xl\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .xl\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .xl\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .xl\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .xl\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .xl\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .xl\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .xl\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .xl\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .xl\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .xl\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .xl\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .xl\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .xl\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .xl\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .xl\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .xl\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .xl\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .xl\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .xl\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .xl\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .xl\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .xl\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .xl\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .xl\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .xl\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .xl\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .xl\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .xl\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .xl\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .xl\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .xl\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .xl\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .xl\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .xl\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .xl\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .xl\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .xl\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .xl\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .xl\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .xl\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .xl\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .xl\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .xl\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .xl\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .xl\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .xl\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .xl\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .xl\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .xl\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .xl\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .xl\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .xl\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .xl\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .xl\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .xl\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xl\:bg-none {
    background-image: none;
  }
  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .xl\:from-background,
  .xl\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-background {
    --tw-gradient-from: var(--background);
  }
  .xl\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .xl\:from-body-text,
  .xl\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .xl\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .xl\:from-header-text,
  .xl\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .xl\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .xl\:from-secondary,
  .xl\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .xl\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xl\:from-primary-hover,
  .xl\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xl\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xl\:from-input-text,
  .xl\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .xl\:from-success {
    --tw-gradient-from: var(--success);
  }
  .xl\:from-error,
  .xl\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-error {
    --tw-gradient-from: var(--error);
  }
  .xl\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .xl\:from-skeleton-loader-from,
  .xl\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xl\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .xl\:to-current {
    --tw-gradient-to: currentColor;
  }
  .xl\:to-black {
    --tw-gradient-to: #000;
  }
  .xl\:to-white {
    --tw-gradient-to: #fff;
  }
  .xl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .xl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .xl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .xl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .xl\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .xl\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .xl\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .xl\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .xl\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .xl\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .xl\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .xl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .xl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .xl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .xl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .xl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .xl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .xl\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .xl\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .xl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .xl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .xl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .xl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .xl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .xl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .xl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .xl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .xl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .xl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .xl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .xl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .xl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .xl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .xl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .xl\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .xl\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xl\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .xl\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .xl\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .xl\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .xl\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .xl\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .xl\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .xl\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .xl\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .xl\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .xl\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .xl\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .xl\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .xl\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .xl\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .xl\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .xl\:to-background {
    --tw-gradient-to: var(--background);
  }
  .xl\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .xl\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .xl\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .xl\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .xl\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .xl\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .xl\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xl\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xl\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xl\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .xl\:to-success {
    --tw-gradient-to: var(--success);
  }
  .xl\:to-error {
    --tw-gradient-to: var(--error);
  }
  .xl\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .xl\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .xl\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .xl\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xl\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xl\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .xl\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .xl\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .xl\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .xl\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .xl\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .xl\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .xl\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .xl\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .xl\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .xl\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .xl\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .xl\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .xl\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .xl\:hover\:from-background:hover,
  .xl\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .xl\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .xl\:hover\:from-body-text:hover,
  .xl\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .xl\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .xl\:hover\:from-header-text:hover,
  .xl\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .xl\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .xl\:hover\:from-secondary:hover,
  .xl\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .xl\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xl\:hover\:from-primary-hover:hover,
  .xl\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xl\:hover\:from-input-text:hover,
  .xl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .xl\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .xl\:hover\:from-error:hover,
  .xl\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .xl\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xl\:hover\:from-background50:hover,
  .xl\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .xl\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .xl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .xl\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .xl\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xl\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .xl\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .xl\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .xl\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .xl\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .xl\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .xl\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .xl\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .xl\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .xl\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .xl\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .xl\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .xl\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .xl\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .xl\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .xl\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .xl\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .xl\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .xl\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .xl\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .xl\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .xl\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .xl\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .xl\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xl\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xl\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xl\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .xl\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .xl\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .xl\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .xl\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .xl\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .xl\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xl\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xl\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .xl\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .xl\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .xl\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .xl\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .xl\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .xl\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .xl\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .xl\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .xl\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .xl\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .xl\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .xl\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .xl\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .xl\:focus\:from-background:focus,
  .xl\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .xl\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .xl\:focus\:from-body-text:focus,
  .xl\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .xl\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .xl\:focus\:from-header-text:focus,
  .xl\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .xl\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .xl\:focus\:from-secondary:focus,
  .xl\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .xl\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .xl\:focus\:from-primary-hover:focus,
  .xl\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xl\:focus\:from-input-text:focus,
  .xl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .xl\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .xl\:focus\:from-error:focus,
  .xl\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .xl\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xl\:focus\:from-background50:focus,
  .xl\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .xl\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xl\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xl\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xl\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xl\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xl\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xl\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xl\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xl\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xl\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xl\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xl\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xl\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xl\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xl\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xl\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xl\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xl\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xl\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xl\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xl\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xl\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xl\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xl\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xl\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xl\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xl\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xl\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xl\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xl\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .xl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .xl\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .xl\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .xl\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .xl\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .xl\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .xl\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .xl\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .xl\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .xl\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .xl\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .xl\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .xl\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .xl\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .xl\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .xl\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .xl\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .xl\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .xl\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .xl\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .xl\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .xl\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .xl\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .xl\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .xl\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .xl\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .xl\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xl\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xl\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .xl\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .xl\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .xl\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .xl\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .xl\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .xl\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xl\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xl\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .xl\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .xl\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .xl\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .xl\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .xl\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .xl\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .xl\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .xl\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .xl\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .xl\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .xl\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .xl\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .xl\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .xl\:bg-bottom {
    background-position: bottom;
  }
  .xl\:bg-center {
    background-position: 50%;
  }
  .xl\:bg-left {
    background-position: 0;
  }
  .xl\:bg-left-bottom {
    background-position: 0 100%;
  }
  .xl\:bg-left-top {
    background-position: 0 0;
  }
  .xl\:bg-right {
    background-position: 100%;
  }
  .xl\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .xl\:bg-right-top {
    background-position: 100% 0;
  }
  .xl\:bg-top {
    background-position: top;
  }
  .xl\:bg-repeat {
    background-repeat: repeat;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .xl\:bg-repeat-round {
    background-repeat: round;
  }
  .xl\:bg-repeat-space {
    background-repeat: space;
  }
  .xl\:bg-auto {
    background-size: auto;
  }
  .xl\:bg-cover {
    background-size: cover;
  }
  .xl\:bg-contain {
    background-size: contain;
  }
  .xl\:border-collapse {
    border-collapse: collapse;
  }
  .xl\:border-separate {
    border-collapse: separate;
  }
  .xl\:border-transparent {
    border-color: transparent;
  }
  .xl\:border-current {
    border-color: currentColor;
  }
  .xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:border-background-primary {
    border-color: var(--background-primary);
  }
  .xl\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xl\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xl\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xl\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xl\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xl\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xl\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xl\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xl\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xl\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xl\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xl\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xl\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xl\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xl\:border-body-background {
    border-color: var(--body-background);
  }
  .xl\:border-background {
    border-color: var(--background);
  }
  .xl\:border-input-background {
    border-color: var(--input-background);
  }
  .xl\:border-body-text {
    border-color: var(--body-text);
  }
  .xl\:border-header-text {
    border-color: var(--header-text);
  }
  .xl\:border-primary {
    border-color: var(--primary);
  }
  .xl\:border-secondary {
    border-color: var(--secondary);
  }
  .xl\:border-tertiary {
    border-color: var(--tertiary);
  }
  .xl\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .xl\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .xl\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .xl\:border-input-text {
    border-color: var(--input-text);
  }
  .xl\:border-success {
    border-color: var(--success);
  }
  .xl\:border-error {
    border-color: var(--error);
  }
  .xl\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .xl\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xl\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xl\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .xl\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .xl\:border-background50 {
    border-color: var(--background50);
  }
  .xl\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xl\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xl\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xl\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xl\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xl\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xl\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xl\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xl\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xl\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xl\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .xl\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xl\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .xl\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .xl\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .xl\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .xl\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .xl\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .xl\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .xl\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .xl\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .xl\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .xl\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .xl\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .xl\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .xl\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .xl\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .xl\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .xl\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .xl\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .xl\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .xl\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .xl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .xl\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .xl\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .xl\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .xl\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .xl\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .xl\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .xl\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .xl\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .xl\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .xl\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .xl\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .xl\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .xl\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .xl\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .xl\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .xl\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .xl\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .xl\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .xl\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xl\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .xl\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .xl\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xl\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xl\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xl\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xl\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xl\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xl\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xl\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xl\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xl\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xl\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .xl\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .xl\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .xl\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .xl\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .xl\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .xl\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .xl\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .xl\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .xl\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .xl\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .xl\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .xl\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .xl\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .xl\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .xl\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xl\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xl\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .xl\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .xl\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .xl\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xl\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xl\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xl\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xl\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .xl\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .xl\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xl\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xl\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xl\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xl\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xl\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xl\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xl\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xl\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xl\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xl\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .xl\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .xl\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .xl\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .xl\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .xl\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .xl\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .xl\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .xl\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .xl\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .xl\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .xl\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .xl\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .xl\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .xl\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .xl\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xl\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xl\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .xl\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .xl\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .xl\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xl\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xl\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xl\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xl\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .xl\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .xl\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .xl\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .xl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .xl\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .xl\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .xl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .xl\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .xl\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .xl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .xl\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .xl\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .xl\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .xl\:rounded-none {
    border-radius: 0;
  }
  .xl\:rounded-sm {
    border-radius: 0.125rem;
  }
  .xl\:rounded {
    border-radius: 0.25rem;
  }
  .xl\:rounded-md {
    border-radius: 0.375rem;
  }
  .xl\:rounded-lg {
    border-radius: 0.5rem;
  }
  .xl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xl\:rounded-2xl {
    border-radius: 1rem;
  }
  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .xl\:rounded-full {
    border-radius: 9999px;
  }
  .xl\:rounded-card {
    border-radius: 20px;
  }
  .xl\:rounded-time-item {
    border-radius: 100px;
  }
  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-t {
    border-top-left-radius: 0.25rem;
  }
  .xl\:rounded-r,
  .xl\:rounded-t {
    border-top-right-radius: 0.25rem;
  }
  .xl\:rounded-b,
  .xl\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .xl\:rounded-b,
  .xl\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .xl\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xl\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .xl\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .xl\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xl\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xl\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .xl\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .xl\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xl\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .xl\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .xl\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .xl\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .xl\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .xl\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .xl\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .xl\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .xl\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .xl\:border-solid {
    border-style: solid;
  }
  .xl\:border-dashed {
    border-style: dashed;
  }
  .xl\:border-dotted {
    border-style: dotted;
  }
  .xl\:border-double {
    border-style: double;
  }
  .xl\:border-none {
    border-style: none;
  }
  .xl\:border-0 {
    border-width: 0;
  }
  .xl\:border-2 {
    border-width: 2px;
  }
  .xl\:border-4 {
    border-width: 4px;
  }
  .xl\:border-8 {
    border-width: 8px;
  }
  .xl\:border {
    border-width: 1px;
  }
  .xl\:border-t-0 {
    border-top-width: 0;
  }
  .xl\:border-r-0 {
    border-right-width: 0;
  }
  .xl\:border-b-0 {
    border-bottom-width: 0;
  }
  .xl\:border-l-0 {
    border-left-width: 0;
  }
  .xl\:border-t-2 {
    border-top-width: 2px;
  }
  .xl\:border-r-2 {
    border-right-width: 2px;
  }
  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }
  .xl\:border-l-2 {
    border-left-width: 2px;
  }
  .xl\:border-t-4 {
    border-top-width: 4px;
  }
  .xl\:border-r-4 {
    border-right-width: 4px;
  }
  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }
  .xl\:border-l-4 {
    border-left-width: 4px;
  }
  .xl\:border-t-8 {
    border-top-width: 8px;
  }
  .xl\:border-r-8 {
    border-right-width: 8px;
  }
  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }
  .xl\:border-l-8 {
    border-left-width: 8px;
  }
  .xl\:border-t {
    border-top-width: 1px;
  }
  .xl\:border-r {
    border-right-width: 1px;
  }
  .xl\:border-b {
    border-bottom-width: 1px;
  }
  .xl\:border-l {
    border-left-width: 1px;
  }
  .xl\:box-border {
    box-sizing: border-box;
  }
  .xl\:box-content {
    box-sizing: content-box;
  }
  .xl\:cursor-auto {
    cursor: auto;
  }
  .xl\:cursor-default {
    cursor: default;
  }
  .xl\:cursor-pointer {
    cursor: pointer;
  }
  .xl\:cursor-wait {
    cursor: wait;
  }
  .xl\:cursor-text {
    cursor: text;
  }
  .xl\:cursor-move {
    cursor: move;
  }
  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .xl\:block {
    display: block;
  }
  .xl\:inline-block {
    display: inline-block;
  }
  .xl\:inline {
    display: inline;
  }
  .xl\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xl\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .xl\:table {
    display: table;
  }
  .xl\:table-caption {
    display: table-caption;
  }
  .xl\:table-cell {
    display: table-cell;
  }
  .xl\:table-column {
    display: table-column;
  }
  .xl\:table-column-group {
    display: table-column-group;
  }
  .xl\:table-footer-group {
    display: table-footer-group;
  }
  .xl\:table-header-group {
    display: table-header-group;
  }
  .xl\:table-row-group {
    display: table-row-group;
  }
  .xl\:table-row {
    display: table-row;
  }
  .xl\:flow-root {
    display: flow-root;
  }
  .xl\:grid {
    display: grid;
  }
  .xl\:inline-grid {
    display: inline-grid;
  }
  .xl\:contents {
    display: contents;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .xl\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .xl\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xl\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .xl\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xl\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .xl\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .xl\:place-items-auto {
    place-items: auto;
  }
  .xl\:place-items-start {
    place-items: start;
  }
  .xl\:place-items-end {
    place-items: end;
  }
  .xl\:place-items-center {
    place-items: center;
  }
  .xl\:place-items-stretch {
    place-items: stretch;
  }
  .xl\:place-content-center {
    place-content: center;
  }
  .xl\:place-content-start {
    place-content: start;
  }
  .xl\:place-content-end {
    place-content: end;
  }
  .xl\:place-content-between {
    place-content: space-between;
  }
  .xl\:place-content-around {
    place-content: space-around;
  }
  .xl\:place-content-evenly {
    place-content: space-evenly;
  }
  .xl\:place-content-stretch {
    place-content: stretch;
  }
  .xl\:place-self-auto {
    place-self: auto;
  }
  .xl\:place-self-start {
    place-self: start;
  }
  .xl\:place-self-end {
    place-self: end;
  }
  .xl\:place-self-center {
    place-self: center;
  }
  .xl\:place-self-stretch {
    place-self: stretch;
  }
  .xl\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .xl\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .xl\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .xl\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .xl\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .xl\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .xl\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .xl\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .xl\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .xl\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .xl\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .xl\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .xl\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xl\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xl\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xl\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .xl\:justify-items-auto {
    justify-items: auto;
  }
  .xl\:justify-items-start {
    justify-items: start;
  }
  .xl\:justify-items-end {
    justify-items: end;
  }
  .xl\:justify-items-center {
    justify-items: center;
  }
  .xl\:justify-items-stretch {
    justify-items: stretch;
  }
  .xl\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .xl\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .xl\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .xl\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .xl\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .xl\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .xl\:justify-self-auto {
    justify-self: auto;
  }
  .xl\:justify-self-start {
    justify-self: start;
  }
  .xl\:justify-self-end {
    justify-self: end;
  }
  .xl\:justify-self-center {
    justify-self: center;
  }
  .xl\:justify-self-stretch {
    justify-self: stretch;
  }
  .xl\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .xl\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .xl\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .xl\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .xl\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .xl\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .xl\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .xl\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .xl\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .xl\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .xl\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .xl\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .xl\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .xl\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .xl\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .xl\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .xl\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .xl\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .xl\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .xl\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .xl\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .xl\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .xl\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .xl\:float-right {
    float: right;
  }
  .xl\:float-left {
    float: left;
  }
  .xl\:float-none {
    float: none;
  }
  .xl\:clear-left {
    clear: left;
  }
  .xl\:clear-right {
    clear: right;
  }
  .xl\:clear-both {
    clear: both;
  }
  .xl\:clear-none {
    clear: none;
  }
  .xl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .xl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .xl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .xl\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .xl\:font-text {
    font-family: Bozon, sans-serif;
  }
  .xl\:font-thin {
    font-weight: 100;
  }
  .xl\:font-extralight {
    font-weight: 200;
  }
  .xl\:font-light {
    font-weight: 300;
  }
  .xl\:font-normal {
    font-weight: 400;
  }
  .xl\:font-medium {
    font-weight: 500;
  }
  .xl\:font-semibold {
    font-weight: 600;
  }
  .xl\:font-bold {
    font-weight: 700;
  }
  .xl\:font-extrabold {
    font-weight: 800;
  }
  .xl\:font-black {
    font-weight: 900;
  }
  .xl\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .xl\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .xl\:hover\:font-light:hover {
    font-weight: 300;
  }
  .xl\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .xl\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .xl\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .xl\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .xl\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .xl\:hover\:font-black:hover {
    font-weight: 900;
  }
  .xl\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .xl\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .xl\:focus\:font-light:focus {
    font-weight: 300;
  }
  .xl\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .xl\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .xl\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .xl\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .xl\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .xl\:focus\:font-black:focus {
    font-weight: 900;
  }
  .xl\:h-0 {
    height: 0;
  }
  .xl\:h-1 {
    height: 0.25rem;
  }
  .xl\:h-2 {
    height: 0.5rem;
  }
  .xl\:h-3 {
    height: 0.75rem;
  }
  .xl\:h-4 {
    height: 1rem;
  }
  .xl\:h-5 {
    height: 1.25rem;
  }
  .xl\:h-6 {
    height: 1.5rem;
  }
  .xl\:h-7 {
    height: 1.75rem;
  }
  .xl\:h-8 {
    height: 2rem;
  }
  .xl\:h-9 {
    height: 2.25rem;
  }
  .xl\:h-10 {
    height: 2.5rem;
  }
  .xl\:h-11 {
    height: 2.75rem;
  }
  .xl\:h-12 {
    height: 3rem;
  }
  .xl\:h-14 {
    height: 3.5rem;
  }
  .xl\:h-16 {
    height: 4rem;
  }
  .xl\:h-20 {
    height: 5rem;
  }
  .xl\:h-24 {
    height: 6rem;
  }
  .xl\:h-28 {
    height: 7rem;
  }
  .xl\:h-32 {
    height: 8rem;
  }
  .xl\:h-36 {
    height: 9rem;
  }
  .xl\:h-40 {
    height: 10rem;
  }
  .xl\:h-44 {
    height: 11rem;
  }
  .xl\:h-48 {
    height: 12rem;
  }
  .xl\:h-52 {
    height: 13rem;
  }
  .xl\:h-56 {
    height: 14rem;
  }
  .xl\:h-60 {
    height: 15rem;
  }
  .xl\:h-64 {
    height: 16rem;
  }
  .xl\:h-72 {
    height: 18rem;
  }
  .xl\:h-80 {
    height: 20rem;
  }
  .xl\:h-96 {
    height: 24rem;
  }
  .xl\:h-105 {
    height: 105px;
  }
  .xl\:h-188 {
    height: 188px;
  }
  .xl\:h-344 {
    height: 344px;
  }
  .xl\:h-425 {
    height: 425px;
  }
  .xl\:h-627 {
    height: 627px;
  }
  .xl\:h-auto {
    height: auto;
  }
  .xl\:h-px {
    height: 1px;
  }
  .xl\:h-0\.5 {
    height: 0.125rem;
  }
  .xl\:h-1\.5 {
    height: 0.375rem;
  }
  .xl\:h-2\.5 {
    height: 0.625rem;
  }
  .xl\:h-3\.5 {
    height: 0.875rem;
  }
  .xl\:h-1\/2 {
    height: 50%;
  }
  .xl\:h-1\/3 {
    height: 33.333333%;
  }
  .xl\:h-2\/3 {
    height: 66.666667%;
  }
  .xl\:h-1\/4 {
    height: 25%;
  }
  .xl\:h-2\/4 {
    height: 50%;
  }
  .xl\:h-3\/4 {
    height: 75%;
  }
  .xl\:h-1\/5 {
    height: 20%;
  }
  .xl\:h-2\/5 {
    height: 40%;
  }
  .xl\:h-3\/5 {
    height: 60%;
  }
  .xl\:h-4\/5 {
    height: 80%;
  }
  .xl\:h-1\/6 {
    height: 16.666667%;
  }
  .xl\:h-2\/6 {
    height: 33.333333%;
  }
  .xl\:h-3\/6 {
    height: 50%;
  }
  .xl\:h-4\/6 {
    height: 66.666667%;
  }
  .xl\:h-5\/6 {
    height: 83.333333%;
  }
  .xl\:h-full {
    height: 100%;
  }
  .xl\:h-screen {
    height: 100vh;
  }
  .xl\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .xl\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .xl\:text-base {
    font-size: 1em;
  }
  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .xl\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .xl\:text-m-sm {
    font-size: 0.8em;
  }
  .xl\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .xl\:text-m-xl {
    font-size: 1.2em;
  }
  .xl\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .xl\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .xl\:text-m-4xl {
    font-size: 1.6em;
  }
  .xl\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .xl\:text-d-sm {
    font-size: 0.875em;
  }
  .xl\:text-d-lg {
    font-size: 1.125em;
  }
  .xl\:text-d-xl {
    font-size: 1.375em;
  }
  .xl\:text-d-2xl {
    font-size: 1.625em;
  }
  .xl\:text-d-3xl {
    font-size: 1.75em;
  }
  .xl\:text-d-4xl {
    font-size: 2.25em;
  }
  .xl\:text-d-5xl {
    font-size: 2.625em;
  }
  .xl\:text-d-11xl {
    font-size: 16em;
  }
  .xl\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .xl\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .xl\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .xl\:leading-3 {
    line-height: 0.75rem;
  }
  .xl\:leading-4 {
    line-height: 1rem;
  }
  .xl\:leading-5 {
    line-height: 1.25rem;
  }
  .xl\:leading-6 {
    line-height: 1.5rem;
  }
  .xl\:leading-7 {
    line-height: 1.75rem;
  }
  .xl\:leading-8 {
    line-height: 2rem;
  }
  .xl\:leading-9 {
    line-height: 2.25rem;
  }
  .xl\:leading-10 {
    line-height: 2.5rem;
  }
  .xl\:leading-none {
    line-height: 1;
  }
  .xl\:leading-tight {
    line-height: 1.25;
  }
  .xl\:leading-snug {
    line-height: 1.375;
  }
  .xl\:leading-normal {
    line-height: 1.5;
  }
  .xl\:leading-relaxed {
    line-height: 1.625;
  }
  .xl\:leading-loose {
    line-height: 2;
  }
  .xl\:list-inside {
    list-style-position: inside;
  }
  .xl\:list-outside {
    list-style-position: outside;
  }
  .xl\:list-none {
    list-style-type: none;
  }
  .xl\:list-disc {
    list-style-type: disc;
  }
  .xl\:list-decimal {
    list-style-type: decimal;
  }
  .xl\:m-0 {
    margin: 0;
  }
  .xl\:m-1 {
    margin: 0.25rem;
  }
  .xl\:m-2 {
    margin: 0.5rem;
  }
  .xl\:m-3 {
    margin: 0.75rem;
  }
  .xl\:m-4 {
    margin: 1rem;
  }
  .xl\:m-5 {
    margin: 1.25rem;
  }
  .xl\:m-6 {
    margin: 1.5rem;
  }
  .xl\:m-7 {
    margin: 1.75rem;
  }
  .xl\:m-8 {
    margin: 2rem;
  }
  .xl\:m-9 {
    margin: 2.25rem;
  }
  .xl\:m-10 {
    margin: 2.5rem;
  }
  .xl\:m-11 {
    margin: 2.75rem;
  }
  .xl\:m-12 {
    margin: 3rem;
  }
  .xl\:m-14 {
    margin: 3.5rem;
  }
  .xl\:m-16 {
    margin: 4rem;
  }
  .xl\:m-20 {
    margin: 5rem;
  }
  .xl\:m-24 {
    margin: 6rem;
  }
  .xl\:m-28 {
    margin: 7rem;
  }
  .xl\:m-32 {
    margin: 8rem;
  }
  .xl\:m-36 {
    margin: 9rem;
  }
  .xl\:m-40 {
    margin: 10rem;
  }
  .xl\:m-44 {
    margin: 11rem;
  }
  .xl\:m-48 {
    margin: 12rem;
  }
  .xl\:m-52 {
    margin: 13rem;
  }
  .xl\:m-56 {
    margin: 14rem;
  }
  .xl\:m-60 {
    margin: 15rem;
  }
  .xl\:m-64 {
    margin: 16rem;
  }
  .xl\:m-72 {
    margin: 18rem;
  }
  .xl\:m-80 {
    margin: 20rem;
  }
  .xl\:m-96 {
    margin: 24rem;
  }
  .xl\:m-auto {
    margin: auto;
  }
  .xl\:m-px {
    margin: 1px;
  }
  .xl\:m-0\.5 {
    margin: 0.125rem;
  }
  .xl\:m-1\.5 {
    margin: 0.375rem;
  }
  .xl\:m-2\.5 {
    margin: 0.625rem;
  }
  .xl\:m-3\.5 {
    margin: 0.875rem;
  }
  .xl\:-m-0 {
    margin: 0;
  }
  .xl\:-m-1 {
    margin: -0.25rem;
  }
  .xl\:-m-2 {
    margin: -0.5rem;
  }
  .xl\:-m-3 {
    margin: -0.75rem;
  }
  .xl\:-m-4 {
    margin: -1rem;
  }
  .xl\:-m-5 {
    margin: -1.25rem;
  }
  .xl\:-m-6 {
    margin: -1.5rem;
  }
  .xl\:-m-7 {
    margin: -1.75rem;
  }
  .xl\:-m-8 {
    margin: -2rem;
  }
  .xl\:-m-9 {
    margin: -2.25rem;
  }
  .xl\:-m-10 {
    margin: -2.5rem;
  }
  .xl\:-m-11 {
    margin: -2.75rem;
  }
  .xl\:-m-12 {
    margin: -3rem;
  }
  .xl\:-m-14 {
    margin: -3.5rem;
  }
  .xl\:-m-16 {
    margin: -4rem;
  }
  .xl\:-m-20 {
    margin: -5rem;
  }
  .xl\:-m-24 {
    margin: -6rem;
  }
  .xl\:-m-28 {
    margin: -7rem;
  }
  .xl\:-m-32 {
    margin: -8rem;
  }
  .xl\:-m-36 {
    margin: -9rem;
  }
  .xl\:-m-40 {
    margin: -10rem;
  }
  .xl\:-m-44 {
    margin: -11rem;
  }
  .xl\:-m-48 {
    margin: -12rem;
  }
  .xl\:-m-52 {
    margin: -13rem;
  }
  .xl\:-m-56 {
    margin: -14rem;
  }
  .xl\:-m-60 {
    margin: -15rem;
  }
  .xl\:-m-64 {
    margin: -16rem;
  }
  .xl\:-m-72 {
    margin: -18rem;
  }
  .xl\:-m-80 {
    margin: -20rem;
  }
  .xl\:-m-96 {
    margin: -24rem;
  }
  .xl\:-m-px {
    margin: -1px;
  }
  .xl\:-m-0\.5 {
    margin: -0.125rem;
  }
  .xl\:-m-1\.5 {
    margin: -0.375rem;
  }
  .xl\:-m-2\.5 {
    margin: -0.625rem;
  }
  .xl\:-m-3\.5 {
    margin: -0.875rem;
  }
  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .xl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .xl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .xl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .xl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .xl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .xl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .xl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .xl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .xl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .xl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .xl\:mt-0 {
    margin-top: 0;
  }
  .xl\:mr-0 {
    margin-right: 0;
  }
  .xl\:mb-0 {
    margin-bottom: 0;
  }
  .xl\:ml-0 {
    margin-left: 0;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .xl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .xl\:mt-4 {
    margin-top: 1rem;
  }
  .xl\:mr-4 {
    margin-right: 1rem;
  }
  .xl\:mb-4 {
    margin-bottom: 1rem;
  }
  .xl\:ml-4 {
    margin-left: 1rem;
  }
  .xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .xl\:mr-5 {
    margin-right: 1.25rem;
  }
  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .xl\:mt-6 {
    margin-top: 1.5rem;
  }
  .xl\:mr-6 {
    margin-right: 1.5rem;
  }
  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .xl\:mr-7 {
    margin-right: 1.75rem;
  }
  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xl\:ml-7 {
    margin-left: 1.75rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:mr-8 {
    margin-right: 2rem;
  }
  .xl\:mb-8 {
    margin-bottom: 2rem;
  }
  .xl\:ml-8 {
    margin-left: 2rem;
  }
  .xl\:mt-9 {
    margin-top: 2.25rem;
  }
  .xl\:mr-9 {
    margin-right: 2.25rem;
  }
  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xl\:ml-9 {
    margin-left: 2.25rem;
  }
  .xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .xl\:mr-10 {
    margin-right: 2.5rem;
  }
  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xl\:ml-10 {
    margin-left: 2.5rem;
  }
  .xl\:mt-11 {
    margin-top: 2.75rem;
  }
  .xl\:mr-11 {
    margin-right: 2.75rem;
  }
  .xl\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .xl\:ml-11 {
    margin-left: 2.75rem;
  }
  .xl\:mt-12 {
    margin-top: 3rem;
  }
  .xl\:mr-12 {
    margin-right: 3rem;
  }
  .xl\:mb-12 {
    margin-bottom: 3rem;
  }
  .xl\:ml-12 {
    margin-left: 3rem;
  }
  .xl\:mt-14 {
    margin-top: 3.5rem;
  }
  .xl\:mr-14 {
    margin-right: 3.5rem;
  }
  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .xl\:ml-14 {
    margin-left: 3.5rem;
  }
  .xl\:mt-16 {
    margin-top: 4rem;
  }
  .xl\:mr-16 {
    margin-right: 4rem;
  }
  .xl\:mb-16 {
    margin-bottom: 4rem;
  }
  .xl\:ml-16 {
    margin-left: 4rem;
  }
  .xl\:mt-20 {
    margin-top: 5rem;
  }
  .xl\:mr-20 {
    margin-right: 5rem;
  }
  .xl\:mb-20 {
    margin-bottom: 5rem;
  }
  .xl\:ml-20 {
    margin-left: 5rem;
  }
  .xl\:mt-24 {
    margin-top: 6rem;
  }
  .xl\:mr-24 {
    margin-right: 6rem;
  }
  .xl\:mb-24 {
    margin-bottom: 6rem;
  }
  .xl\:ml-24 {
    margin-left: 6rem;
  }
  .xl\:mt-28 {
    margin-top: 7rem;
  }
  .xl\:mr-28 {
    margin-right: 7rem;
  }
  .xl\:mb-28 {
    margin-bottom: 7rem;
  }
  .xl\:ml-28 {
    margin-left: 7rem;
  }
  .xl\:mt-32 {
    margin-top: 8rem;
  }
  .xl\:mr-32 {
    margin-right: 8rem;
  }
  .xl\:mb-32 {
    margin-bottom: 8rem;
  }
  .xl\:ml-32 {
    margin-left: 8rem;
  }
  .xl\:mt-36 {
    margin-top: 9rem;
  }
  .xl\:mr-36 {
    margin-right: 9rem;
  }
  .xl\:mb-36 {
    margin-bottom: 9rem;
  }
  .xl\:ml-36 {
    margin-left: 9rem;
  }
  .xl\:mt-40 {
    margin-top: 10rem;
  }
  .xl\:mr-40 {
    margin-right: 10rem;
  }
  .xl\:mb-40 {
    margin-bottom: 10rem;
  }
  .xl\:ml-40 {
    margin-left: 10rem;
  }
  .xl\:mt-44 {
    margin-top: 11rem;
  }
  .xl\:mr-44 {
    margin-right: 11rem;
  }
  .xl\:mb-44 {
    margin-bottom: 11rem;
  }
  .xl\:ml-44 {
    margin-left: 11rem;
  }
  .xl\:mt-48 {
    margin-top: 12rem;
  }
  .xl\:mr-48 {
    margin-right: 12rem;
  }
  .xl\:mb-48 {
    margin-bottom: 12rem;
  }
  .xl\:ml-48 {
    margin-left: 12rem;
  }
  .xl\:mt-52 {
    margin-top: 13rem;
  }
  .xl\:mr-52 {
    margin-right: 13rem;
  }
  .xl\:mb-52 {
    margin-bottom: 13rem;
  }
  .xl\:ml-52 {
    margin-left: 13rem;
  }
  .xl\:mt-56 {
    margin-top: 14rem;
  }
  .xl\:mr-56 {
    margin-right: 14rem;
  }
  .xl\:mb-56 {
    margin-bottom: 14rem;
  }
  .xl\:ml-56 {
    margin-left: 14rem;
  }
  .xl\:mt-60 {
    margin-top: 15rem;
  }
  .xl\:mr-60 {
    margin-right: 15rem;
  }
  .xl\:mb-60 {
    margin-bottom: 15rem;
  }
  .xl\:ml-60 {
    margin-left: 15rem;
  }
  .xl\:mt-64 {
    margin-top: 16rem;
  }
  .xl\:mr-64 {
    margin-right: 16rem;
  }
  .xl\:mb-64 {
    margin-bottom: 16rem;
  }
  .xl\:ml-64 {
    margin-left: 16rem;
  }
  .xl\:mt-72 {
    margin-top: 18rem;
  }
  .xl\:mr-72 {
    margin-right: 18rem;
  }
  .xl\:mb-72 {
    margin-bottom: 18rem;
  }
  .xl\:ml-72 {
    margin-left: 18rem;
  }
  .xl\:mt-80 {
    margin-top: 20rem;
  }
  .xl\:mr-80 {
    margin-right: 20rem;
  }
  .xl\:mb-80 {
    margin-bottom: 20rem;
  }
  .xl\:ml-80 {
    margin-left: 20rem;
  }
  .xl\:mt-96 {
    margin-top: 24rem;
  }
  .xl\:mr-96 {
    margin-right: 24rem;
  }
  .xl\:mb-96 {
    margin-bottom: 24rem;
  }
  .xl\:ml-96 {
    margin-left: 24rem;
  }
  .xl\:mt-auto {
    margin-top: auto;
  }
  .xl\:mr-auto {
    margin-right: auto;
  }
  .xl\:mb-auto {
    margin-bottom: auto;
  }
  .xl\:ml-auto {
    margin-left: auto;
  }
  .xl\:mt-px {
    margin-top: 1px;
  }
  .xl\:mr-px {
    margin-right: 1px;
  }
  .xl\:mb-px {
    margin-bottom: 1px;
  }
  .xl\:ml-px {
    margin-left: 1px;
  }
  .xl\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .xl\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .xl\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .xl\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .xl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xl\:-mt-0 {
    margin-top: 0;
  }
  .xl\:-mr-0 {
    margin-right: 0;
  }
  .xl\:-mb-0 {
    margin-bottom: 0;
  }
  .xl\:-ml-0 {
    margin-left: 0;
  }
  .xl\:-mt-1 {
    margin-top: -0.25rem;
  }
  .xl\:-mr-1 {
    margin-right: -0.25rem;
  }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .xl\:-ml-1 {
    margin-left: -0.25rem;
  }
  .xl\:-mt-2 {
    margin-top: -0.5rem;
  }
  .xl\:-mr-2 {
    margin-right: -0.5rem;
  }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xl\:-mt-3 {
    margin-top: -0.75rem;
  }
  .xl\:-mr-3 {
    margin-right: -0.75rem;
  }
  .xl\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .xl\:-ml-3 {
    margin-left: -0.75rem;
  }
  .xl\:-mt-4 {
    margin-top: -1rem;
  }
  .xl\:-mr-4 {
    margin-right: -1rem;
  }
  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }
  .xl\:-ml-4 {
    margin-left: -1rem;
  }
  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }
  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }
  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }
  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }
  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }
  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }
  .xl\:-mt-7 {
    margin-top: -1.75rem;
  }
  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }
  .xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .xl\:-ml-7 {
    margin-left: -1.75rem;
  }
  .xl\:-mt-8 {
    margin-top: -2rem;
  }
  .xl\:-mr-8 {
    margin-right: -2rem;
  }
  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }
  .xl\:-ml-8 {
    margin-left: -2rem;
  }
  .xl\:-mt-9 {
    margin-top: -2.25rem;
  }
  .xl\:-mr-9 {
    margin-right: -2.25rem;
  }
  .xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .xl\:-ml-9 {
    margin-left: -2.25rem;
  }
  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }
  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }
  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }
  .xl\:-mt-11 {
    margin-top: -2.75rem;
  }
  .xl\:-mr-11 {
    margin-right: -2.75rem;
  }
  .xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .xl\:-ml-11 {
    margin-left: -2.75rem;
  }
  .xl\:-mt-12 {
    margin-top: -3rem;
  }
  .xl\:-mr-12 {
    margin-right: -3rem;
  }
  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }
  .xl\:-ml-12 {
    margin-left: -3rem;
  }
  .xl\:-mt-14 {
    margin-top: -3.5rem;
  }
  .xl\:-mr-14 {
    margin-right: -3.5rem;
  }
  .xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }
  .xl\:-mt-16 {
    margin-top: -4rem;
  }
  .xl\:-mr-16 {
    margin-right: -4rem;
  }
  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }
  .xl\:-ml-16 {
    margin-left: -4rem;
  }
  .xl\:-mt-20 {
    margin-top: -5rem;
  }
  .xl\:-mr-20 {
    margin-right: -5rem;
  }
  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }
  .xl\:-ml-20 {
    margin-left: -5rem;
  }
  .xl\:-mt-24 {
    margin-top: -6rem;
  }
  .xl\:-mr-24 {
    margin-right: -6rem;
  }
  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }
  .xl\:-ml-24 {
    margin-left: -6rem;
  }
  .xl\:-mt-28 {
    margin-top: -7rem;
  }
  .xl\:-mr-28 {
    margin-right: -7rem;
  }
  .xl\:-mb-28 {
    margin-bottom: -7rem;
  }
  .xl\:-ml-28 {
    margin-left: -7rem;
  }
  .xl\:-mt-32 {
    margin-top: -8rem;
  }
  .xl\:-mr-32 {
    margin-right: -8rem;
  }
  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }
  .xl\:-ml-32 {
    margin-left: -8rem;
  }
  .xl\:-mt-36 {
    margin-top: -9rem;
  }
  .xl\:-mr-36 {
    margin-right: -9rem;
  }
  .xl\:-mb-36 {
    margin-bottom: -9rem;
  }
  .xl\:-ml-36 {
    margin-left: -9rem;
  }
  .xl\:-mt-40 {
    margin-top: -10rem;
  }
  .xl\:-mr-40 {
    margin-right: -10rem;
  }
  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }
  .xl\:-ml-40 {
    margin-left: -10rem;
  }
  .xl\:-mt-44 {
    margin-top: -11rem;
  }
  .xl\:-mr-44 {
    margin-right: -11rem;
  }
  .xl\:-mb-44 {
    margin-bottom: -11rem;
  }
  .xl\:-ml-44 {
    margin-left: -11rem;
  }
  .xl\:-mt-48 {
    margin-top: -12rem;
  }
  .xl\:-mr-48 {
    margin-right: -12rem;
  }
  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }
  .xl\:-ml-48 {
    margin-left: -12rem;
  }
  .xl\:-mt-52 {
    margin-top: -13rem;
  }
  .xl\:-mr-52 {
    margin-right: -13rem;
  }
  .xl\:-mb-52 {
    margin-bottom: -13rem;
  }
  .xl\:-ml-52 {
    margin-left: -13rem;
  }
  .xl\:-mt-56 {
    margin-top: -14rem;
  }
  .xl\:-mr-56 {
    margin-right: -14rem;
  }
  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }
  .xl\:-ml-56 {
    margin-left: -14rem;
  }
  .xl\:-mt-60 {
    margin-top: -15rem;
  }
  .xl\:-mr-60 {
    margin-right: -15rem;
  }
  .xl\:-mb-60 {
    margin-bottom: -15rem;
  }
  .xl\:-ml-60 {
    margin-left: -15rem;
  }
  .xl\:-mt-64 {
    margin-top: -16rem;
  }
  .xl\:-mr-64 {
    margin-right: -16rem;
  }
  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }
  .xl\:-ml-64 {
    margin-left: -16rem;
  }
  .xl\:-mt-72 {
    margin-top: -18rem;
  }
  .xl\:-mr-72 {
    margin-right: -18rem;
  }
  .xl\:-mb-72 {
    margin-bottom: -18rem;
  }
  .xl\:-ml-72 {
    margin-left: -18rem;
  }
  .xl\:-mt-80 {
    margin-top: -20rem;
  }
  .xl\:-mr-80 {
    margin-right: -20rem;
  }
  .xl\:-mb-80 {
    margin-bottom: -20rem;
  }
  .xl\:-ml-80 {
    margin-left: -20rem;
  }
  .xl\:-mt-96 {
    margin-top: -24rem;
  }
  .xl\:-mr-96 {
    margin-right: -24rem;
  }
  .xl\:-mb-96 {
    margin-bottom: -24rem;
  }
  .xl\:-ml-96 {
    margin-left: -24rem;
  }
  .xl\:-mt-px {
    margin-top: -1px;
  }
  .xl\:-mr-px {
    margin-right: -1px;
  }
  .xl\:-mb-px {
    margin-bottom: -1px;
  }
  .xl\:-ml-px {
    margin-left: -1px;
  }
  .xl\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .xl\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .xl\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .xl\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .xl\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .xl\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .xl\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .xl\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .xl\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .xl\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .xl\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .xl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xl\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .xl\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .xl\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .xl\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .xl\:max-h-0 {
    max-height: 0;
  }
  .xl\:max-h-1 {
    max-height: 0.25rem;
  }
  .xl\:max-h-2 {
    max-height: 0.5rem;
  }
  .xl\:max-h-3 {
    max-height: 0.75rem;
  }
  .xl\:max-h-4 {
    max-height: 1rem;
  }
  .xl\:max-h-5 {
    max-height: 1.25rem;
  }
  .xl\:max-h-6 {
    max-height: 1.5rem;
  }
  .xl\:max-h-7 {
    max-height: 1.75rem;
  }
  .xl\:max-h-8 {
    max-height: 2rem;
  }
  .xl\:max-h-9 {
    max-height: 2.25rem;
  }
  .xl\:max-h-10 {
    max-height: 2.5rem;
  }
  .xl\:max-h-11 {
    max-height: 2.75rem;
  }
  .xl\:max-h-12 {
    max-height: 3rem;
  }
  .xl\:max-h-14 {
    max-height: 3.5rem;
  }
  .xl\:max-h-16 {
    max-height: 4rem;
  }
  .xl\:max-h-20 {
    max-height: 5rem;
  }
  .xl\:max-h-24 {
    max-height: 6rem;
  }
  .xl\:max-h-28 {
    max-height: 7rem;
  }
  .xl\:max-h-32 {
    max-height: 8rem;
  }
  .xl\:max-h-36 {
    max-height: 9rem;
  }
  .xl\:max-h-40 {
    max-height: 10rem;
  }
  .xl\:max-h-44 {
    max-height: 11rem;
  }
  .xl\:max-h-48 {
    max-height: 12rem;
  }
  .xl\:max-h-52 {
    max-height: 13rem;
  }
  .xl\:max-h-56 {
    max-height: 14rem;
  }
  .xl\:max-h-60 {
    max-height: 15rem;
  }
  .xl\:max-h-64 {
    max-height: 16rem;
  }
  .xl\:max-h-72 {
    max-height: 18rem;
  }
  .xl\:max-h-80 {
    max-height: 20rem;
  }
  .xl\:max-h-96 {
    max-height: 24rem;
  }
  .xl\:max-h-px {
    max-height: 1px;
  }
  .xl\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .xl\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .xl\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .xl\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .xl\:max-h-full {
    max-height: 100%;
  }
  .xl\:max-h-screen {
    max-height: 100vh;
  }
  .xl\:max-h-1\/4 {
    max-height: 25%;
  }
  .xl\:max-h-1\/2 {
    max-height: 50%;
  }
  .xl\:max-h-3\/4 {
    max-height: 75%;
  }
  .xl\:max-h-10\/12 {
    max-height: 80%;
  }
  .xl\:max-h-enormous {
    max-height: 4000px;
  }
  .xl\:max-w-0 {
    max-width: 0;
  }
  .xl\:max-w-110 {
    max-width: 110px;
  }
  .xl\:max-w-473 {
    max-width: 473px;
  }
  .xl\:max-w-none {
    max-width: none;
  }
  .xl\:max-w-xs {
    max-width: 20rem;
  }
  .xl\:max-w-sm {
    max-width: 24rem;
  }
  .xl\:max-w-md {
    max-width: 28rem;
  }
  .xl\:max-w-lg {
    max-width: 32rem;
  }
  .xl\:max-w-xl {
    max-width: 36rem;
  }
  .xl\:max-w-2xl {
    max-width: 42rem;
  }
  .xl\:max-w-3xl {
    max-width: 48rem;
  }
  .xl\:max-w-4xl {
    max-width: 56rem;
  }
  .xl\:max-w-5xl {
    max-width: 64rem;
  }
  .xl\:max-w-6xl {
    max-width: 72rem;
  }
  .xl\:max-w-7xl {
    max-width: 80rem;
  }
  .xl\:max-w-full {
    max-width: 100%;
  }
  .xl\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .xl\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .xl\:max-w-prose {
    max-width: 65ch;
  }
  .xl\:max-w-screen-sm {
    max-width: 640px;
  }
  .xl\:max-w-screen-md {
    max-width: 768px;
  }
  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }
  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }
  .xl\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .xl\:max-w-screen-xxs {
    max-width: 260px;
  }
  .xl\:max-w-screen-xs {
    max-width: 360px;
  }
  .xl\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .xl\:min-h-0 {
    min-height: 0;
  }
  .xl\:min-h-full {
    min-height: 100%;
  }
  .xl\:min-h-screen {
    min-height: 100vh;
  }
  .xl\:min-w-0 {
    min-width: 0;
  }
  .xl\:min-w-10 {
    min-width: 2.5rem;
  }
  .xl\:min-w-12 {
    min-width: 3rem;
  }
  .xl\:min-w-14 {
    min-width: 3.5rem;
  }
  .xl\:min-w-full {
    min-width: 100%;
  }
  .xl\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .xl\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .xl\:object-contain {
    object-fit: contain;
  }
  .xl\:object-cover {
    object-fit: cover;
  }
  .xl\:object-fill {
    object-fit: fill;
  }
  .xl\:object-none {
    object-fit: none;
  }
  .xl\:object-scale-down {
    object-fit: scale-down;
  }
  .xl\:object-bottom {
    object-position: bottom;
  }
  .xl\:object-center {
    object-position: center;
  }
  .xl\:object-left {
    object-position: left;
  }
  .xl\:object-left-bottom {
    object-position: left bottom;
  }
  .xl\:object-left-top {
    object-position: left top;
  }
  .xl\:object-right {
    object-position: right;
  }
  .xl\:object-right-bottom {
    object-position: right bottom;
  }
  .xl\:object-right-top {
    object-position: right top;
  }
  .xl\:object-top {
    object-position: top;
  }
  .xl\:opacity-0 {
    opacity: 0;
  }
  .xl\:opacity-5 {
    opacity: 0.05;
  }
  .xl\:opacity-10 {
    opacity: 0.1;
  }
  .xl\:opacity-20 {
    opacity: 0.2;
  }
  .xl\:opacity-25 {
    opacity: 0.25;
  }
  .xl\:opacity-30 {
    opacity: 0.3;
  }
  .xl\:opacity-40 {
    opacity: 0.4;
  }
  .xl\:opacity-50 {
    opacity: 0.5;
  }
  .xl\:opacity-60 {
    opacity: 0.6;
  }
  .xl\:opacity-70 {
    opacity: 0.7;
  }
  .xl\:opacity-75 {
    opacity: 0.75;
  }
  .xl\:opacity-80 {
    opacity: 0.8;
  }
  .xl\:opacity-90 {
    opacity: 0.9;
  }
  .xl\:opacity-95 {
    opacity: 0.95;
  }
  .xl\:opacity-100 {
    opacity: 1;
  }
  .group:hover .xl\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .xl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .xl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .xl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .xl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .xl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .xl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .xl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .xl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .xl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .xl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .xl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .xl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .xl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .xl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .xl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .xl\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .xl\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .xl\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .xl\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .xl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .xl\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .xl\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .xl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .xl\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .xl\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .xl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .xl\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .xl\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .xl\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .xl\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .xl\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .xl\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .xl\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .xl\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .xl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .xl\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .xl\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .xl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .xl\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .xl\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .xl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .xl\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .xl\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .xl\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .xl\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xl\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xl\:overflow-auto {
    overflow: auto;
  }
  .xl\:overflow-hidden {
    overflow: hidden;
  }
  .xl\:overflow-visible {
    overflow: visible;
  }
  .xl\:overflow-scroll {
    overflow: scroll;
  }
  .xl\:overflow-x-auto {
    overflow-x: auto;
  }
  .xl\:overflow-y-auto {
    overflow-y: auto;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .xl\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .xl\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .xl\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .xl\:p-0 {
    padding: 0;
  }
  .xl\:p-1 {
    padding: 0.25rem;
  }
  .xl\:p-2 {
    padding: 0.5rem;
  }
  .xl\:p-3 {
    padding: 0.75rem;
  }
  .xl\:p-4 {
    padding: 1rem;
  }
  .xl\:p-5 {
    padding: 1.25rem;
  }
  .xl\:p-6 {
    padding: 1.5rem;
  }
  .xl\:p-7 {
    padding: 1.75rem;
  }
  .xl\:p-8 {
    padding: 2rem;
  }
  .xl\:p-9 {
    padding: 2.25rem;
  }
  .xl\:p-10 {
    padding: 2.5rem;
  }
  .xl\:p-11 {
    padding: 2.75rem;
  }
  .xl\:p-12 {
    padding: 3rem;
  }
  .xl\:p-14 {
    padding: 3.5rem;
  }
  .xl\:p-16 {
    padding: 4rem;
  }
  .xl\:p-20 {
    padding: 5rem;
  }
  .xl\:p-24 {
    padding: 6rem;
  }
  .xl\:p-28 {
    padding: 7rem;
  }
  .xl\:p-32 {
    padding: 8rem;
  }
  .xl\:p-36 {
    padding: 9rem;
  }
  .xl\:p-40 {
    padding: 10rem;
  }
  .xl\:p-44 {
    padding: 11rem;
  }
  .xl\:p-48 {
    padding: 12rem;
  }
  .xl\:p-52 {
    padding: 13rem;
  }
  .xl\:p-56 {
    padding: 14rem;
  }
  .xl\:p-60 {
    padding: 15rem;
  }
  .xl\:p-64 {
    padding: 16rem;
  }
  .xl\:p-72 {
    padding: 18rem;
  }
  .xl\:p-80 {
    padding: 20rem;
  }
  .xl\:p-96 {
    padding: 24rem;
  }
  .xl\:p-px {
    padding: 1px;
  }
  .xl\:p-0\.5 {
    padding: 0.125rem;
  }
  .xl\:p-1\.5 {
    padding: 0.375rem;
  }
  .xl\:p-2\.5 {
    padding: 0.625rem;
  }
  .xl\:p-3\.5 {
    padding: 0.875rem;
  }
  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xl\:pt-0 {
    padding-top: 0;
  }
  .xl\:pr-0 {
    padding-right: 0;
  }
  .xl\:pb-0 {
    padding-bottom: 0;
  }
  .xl\:pl-0 {
    padding-left: 0;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .xl\:pt-3 {
    padding-top: 0.75rem;
  }
  .xl\:pr-3 {
    padding-right: 0.75rem;
  }
  .xl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .xl\:pt-4 {
    padding-top: 1rem;
  }
  .xl\:pr-4 {
    padding-right: 1rem;
  }
  .xl\:pb-4 {
    padding-bottom: 1rem;
  }
  .xl\:pl-4 {
    padding-left: 1rem;
  }
  .xl\:pt-5 {
    padding-top: 1.25rem;
  }
  .xl\:pr-5 {
    padding-right: 1.25rem;
  }
  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .xl\:pr-6 {
    padding-right: 1.5rem;
  }
  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xl\:pl-6 {
    padding-left: 1.5rem;
  }
  .xl\:pt-7 {
    padding-top: 1.75rem;
  }
  .xl\:pr-7 {
    padding-right: 1.75rem;
  }
  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xl\:pl-7 {
    padding-left: 1.75rem;
  }
  .xl\:pt-8 {
    padding-top: 2rem;
  }
  .xl\:pr-8 {
    padding-right: 2rem;
  }
  .xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .xl\:pl-8 {
    padding-left: 2rem;
  }
  .xl\:pt-9 {
    padding-top: 2.25rem;
  }
  .xl\:pr-9 {
    padding-right: 2.25rem;
  }
  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xl\:pl-9 {
    padding-left: 2.25rem;
  }
  .xl\:pt-10 {
    padding-top: 2.5rem;
  }
  .xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xl\:pt-11 {
    padding-top: 2.75rem;
  }
  .xl\:pr-11 {
    padding-right: 2.75rem;
  }
  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xl\:pl-11 {
    padding-left: 2.75rem;
  }
  .xl\:pt-12 {
    padding-top: 3rem;
  }
  .xl\:pr-12 {
    padding-right: 3rem;
  }
  .xl\:pb-12 {
    padding-bottom: 3rem;
  }
  .xl\:pl-12 {
    padding-left: 3rem;
  }
  .xl\:pt-14 {
    padding-top: 3.5rem;
  }
  .xl\:pr-14 {
    padding-right: 3.5rem;
  }
  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .xl\:pt-16 {
    padding-top: 4rem;
  }
  .xl\:pr-16 {
    padding-right: 4rem;
  }
  .xl\:pb-16 {
    padding-bottom: 4rem;
  }
  .xl\:pl-16 {
    padding-left: 4rem;
  }
  .xl\:pt-20 {
    padding-top: 5rem;
  }
  .xl\:pr-20 {
    padding-right: 5rem;
  }
  .xl\:pb-20 {
    padding-bottom: 5rem;
  }
  .xl\:pl-20 {
    padding-left: 5rem;
  }
  .xl\:pt-24 {
    padding-top: 6rem;
  }
  .xl\:pr-24 {
    padding-right: 6rem;
  }
  .xl\:pb-24 {
    padding-bottom: 6rem;
  }
  .xl\:pl-24 {
    padding-left: 6rem;
  }
  .xl\:pt-28 {
    padding-top: 7rem;
  }
  .xl\:pr-28 {
    padding-right: 7rem;
  }
  .xl\:pb-28 {
    padding-bottom: 7rem;
  }
  .xl\:pl-28 {
    padding-left: 7rem;
  }
  .xl\:pt-32 {
    padding-top: 8rem;
  }
  .xl\:pr-32 {
    padding-right: 8rem;
  }
  .xl\:pb-32 {
    padding-bottom: 8rem;
  }
  .xl\:pl-32 {
    padding-left: 8rem;
  }
  .xl\:pt-36 {
    padding-top: 9rem;
  }
  .xl\:pr-36 {
    padding-right: 9rem;
  }
  .xl\:pb-36 {
    padding-bottom: 9rem;
  }
  .xl\:pl-36 {
    padding-left: 9rem;
  }
  .xl\:pt-40 {
    padding-top: 10rem;
  }
  .xl\:pr-40 {
    padding-right: 10rem;
  }
  .xl\:pb-40 {
    padding-bottom: 10rem;
  }
  .xl\:pl-40 {
    padding-left: 10rem;
  }
  .xl\:pt-44 {
    padding-top: 11rem;
  }
  .xl\:pr-44 {
    padding-right: 11rem;
  }
  .xl\:pb-44 {
    padding-bottom: 11rem;
  }
  .xl\:pl-44 {
    padding-left: 11rem;
  }
  .xl\:pt-48 {
    padding-top: 12rem;
  }
  .xl\:pr-48 {
    padding-right: 12rem;
  }
  .xl\:pb-48 {
    padding-bottom: 12rem;
  }
  .xl\:pl-48 {
    padding-left: 12rem;
  }
  .xl\:pt-52 {
    padding-top: 13rem;
  }
  .xl\:pr-52 {
    padding-right: 13rem;
  }
  .xl\:pb-52 {
    padding-bottom: 13rem;
  }
  .xl\:pl-52 {
    padding-left: 13rem;
  }
  .xl\:pt-56 {
    padding-top: 14rem;
  }
  .xl\:pr-56 {
    padding-right: 14rem;
  }
  .xl\:pb-56 {
    padding-bottom: 14rem;
  }
  .xl\:pl-56 {
    padding-left: 14rem;
  }
  .xl\:pt-60 {
    padding-top: 15rem;
  }
  .xl\:pr-60 {
    padding-right: 15rem;
  }
  .xl\:pb-60 {
    padding-bottom: 15rem;
  }
  .xl\:pl-60 {
    padding-left: 15rem;
  }
  .xl\:pt-64 {
    padding-top: 16rem;
  }
  .xl\:pr-64 {
    padding-right: 16rem;
  }
  .xl\:pb-64 {
    padding-bottom: 16rem;
  }
  .xl\:pl-64 {
    padding-left: 16rem;
  }
  .xl\:pt-72 {
    padding-top: 18rem;
  }
  .xl\:pr-72 {
    padding-right: 18rem;
  }
  .xl\:pb-72 {
    padding-bottom: 18rem;
  }
  .xl\:pl-72 {
    padding-left: 18rem;
  }
  .xl\:pt-80 {
    padding-top: 20rem;
  }
  .xl\:pr-80 {
    padding-right: 20rem;
  }
  .xl\:pb-80 {
    padding-bottom: 20rem;
  }
  .xl\:pl-80 {
    padding-left: 20rem;
  }
  .xl\:pt-96 {
    padding-top: 24rem;
  }
  .xl\:pr-96 {
    padding-right: 24rem;
  }
  .xl\:pb-96 {
    padding-bottom: 24rem;
  }
  .xl\:pl-96 {
    padding-left: 24rem;
  }
  .xl\:pt-px {
    padding-top: 1px;
  }
  .xl\:pr-px {
    padding-right: 1px;
  }
  .xl\:pb-px {
    padding-bottom: 1px;
  }
  .xl\:pl-px {
    padding-left: 1px;
  }
  .xl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xl\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .xl\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .xl\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .xl\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .xl\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .xl\:placeholder-current::placeholder {
    color: currentColor;
  }
  .xl\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xl\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xl\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .xl\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xl\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xl\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .xl\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .xl\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .xl\:placeholder-background::placeholder {
    color: var(--background);
  }
  .xl\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xl\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xl\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .xl\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xl\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xl\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .xl\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xl\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xl\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .xl\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xl\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .xl\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .xl\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xl\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xl\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .xl\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xl\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xl\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .xl\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xl\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xl\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .xl\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xl\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xl\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .xl\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xl\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xl\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .xl\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .xl\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .xl\:placeholder-success::placeholder {
    color: var(--success);
  }
  .xl\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .xl\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .xl\:placeholder-error::placeholder {
    color: var(--error);
  }
  .xl\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xl\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xl\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .xl\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xl\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .xl\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .xl\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .xl\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .xl\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .xl\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xl\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xl\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .xl\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xl\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xl\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .xl\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .xl\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .xl\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .xl\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xl\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xl\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .xl\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xl\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xl\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .xl\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xl\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xl\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .xl\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xl\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .xl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .xl\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xl\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xl\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .xl\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xl\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xl\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .xl\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xl\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xl\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .xl\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xl\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xl\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .xl\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .xl\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xl\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xl\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .xl\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .xl\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .xl\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .xl\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .xl\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .xl\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .xl\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xl\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xl\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .xl\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xl\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xl\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xl\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .xl\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .xl\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:pointer-events-none {
    pointer-events: none;
  }
  .xl\:pointer-events-auto {
    pointer-events: auto;
  }
  .xl\:static {
    position: static;
  }
  .xl\:fixed {
    position: fixed;
  }
  .xl\:absolute {
    position: absolute;
  }
  .xl\:relative {
    position: relative;
  }
  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .xl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .xl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .xl\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .xl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .xl\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .xl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .xl\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .xl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .xl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .xl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .xl\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .xl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .xl\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .xl\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .xl\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .xl\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .xl\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .xl\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .xl\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .xl\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .xl\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .xl\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .xl\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .xl\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .xl\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .xl\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .xl\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .xl\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .xl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .xl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .xl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .xl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .xl\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .xl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .xl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .xl\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .xl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .xl\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .xl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .xl\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .xl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .xl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .xl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .xl\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .xl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .xl\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .xl\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .xl\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .xl\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .xl\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .xl\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .xl\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .xl\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .xl\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .xl\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .xl\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .xl\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .xl\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .xl\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .xl\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .xl\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .xl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .xl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .xl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .xl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xl\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .xl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .xl\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .xl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .xl\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .xl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .xl\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .xl\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .xl\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .xl\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .xl\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .xl\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .xl\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .xl\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .xl\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .xl\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .xl\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .xl\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .xl\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .xl\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .xl\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .xl\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .xl\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .xl\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .xl\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .xl\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .xl\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .xl\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .xl\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .xl\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .xl\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .xl\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .xl\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .xl\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .xl\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .xl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .xl\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .xl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .xl\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .xl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .xl\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .xl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .xl\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xl\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .xl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .xl\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .xl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .xl\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .xl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .xl\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .xl\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .xl\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .xl\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .xl\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .xl\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .xl\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .xl\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .xl\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .xl\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .xl\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .xl\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .xl\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .xl\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .xl\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .xl\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .xl\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .xl\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .xl\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .xl\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .xl\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .xl\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .xl\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .xl\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .xl\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .xl\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .xl\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .xl\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .xl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .xl\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .xl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .xl\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .xl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .xl\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .xl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .xl\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .xl\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .xl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .xl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .xl\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .xl\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .xl\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .xl\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .xl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .xl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .xl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .xl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .xl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .xl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .xl\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .xl\:top-0 {
    top: 0;
  }
  .xl\:right-0 {
    right: 0;
  }
  .xl\:bottom-0 {
    bottom: 0;
  }
  .xl\:left-0 {
    left: 0;
  }
  .xl\:top-1 {
    top: 0.25rem;
  }
  .xl\:right-1 {
    right: 0.25rem;
  }
  .xl\:bottom-1 {
    bottom: 0.25rem;
  }
  .xl\:left-1 {
    left: 0.25rem;
  }
  .xl\:top-2 {
    top: 0.5rem;
  }
  .xl\:right-2 {
    right: 0.5rem;
  }
  .xl\:bottom-2 {
    bottom: 0.5rem;
  }
  .xl\:left-2 {
    left: 0.5rem;
  }
  .xl\:top-3 {
    top: 0.75rem;
  }
  .xl\:right-3 {
    right: 0.75rem;
  }
  .xl\:bottom-3 {
    bottom: 0.75rem;
  }
  .xl\:left-3 {
    left: 0.75rem;
  }
  .xl\:top-4 {
    top: 1rem;
  }
  .xl\:right-4 {
    right: 1rem;
  }
  .xl\:bottom-4 {
    bottom: 1rem;
  }
  .xl\:left-4 {
    left: 1rem;
  }
  .xl\:top-5 {
    top: 1.25rem;
  }
  .xl\:right-5 {
    right: 1.25rem;
  }
  .xl\:bottom-5 {
    bottom: 1.25rem;
  }
  .xl\:left-5 {
    left: 1.25rem;
  }
  .xl\:top-6 {
    top: 1.5rem;
  }
  .xl\:right-6 {
    right: 1.5rem;
  }
  .xl\:bottom-6 {
    bottom: 1.5rem;
  }
  .xl\:left-6 {
    left: 1.5rem;
  }
  .xl\:top-7 {
    top: 1.75rem;
  }
  .xl\:right-7 {
    right: 1.75rem;
  }
  .xl\:bottom-7 {
    bottom: 1.75rem;
  }
  .xl\:left-7 {
    left: 1.75rem;
  }
  .xl\:top-8 {
    top: 2rem;
  }
  .xl\:right-8 {
    right: 2rem;
  }
  .xl\:bottom-8 {
    bottom: 2rem;
  }
  .xl\:left-8 {
    left: 2rem;
  }
  .xl\:top-9 {
    top: 2.25rem;
  }
  .xl\:right-9 {
    right: 2.25rem;
  }
  .xl\:bottom-9 {
    bottom: 2.25rem;
  }
  .xl\:left-9 {
    left: 2.25rem;
  }
  .xl\:top-10 {
    top: 2.5rem;
  }
  .xl\:right-10 {
    right: 2.5rem;
  }
  .xl\:bottom-10 {
    bottom: 2.5rem;
  }
  .xl\:left-10 {
    left: 2.5rem;
  }
  .xl\:top-11 {
    top: 2.75rem;
  }
  .xl\:right-11 {
    right: 2.75rem;
  }
  .xl\:bottom-11 {
    bottom: 2.75rem;
  }
  .xl\:left-11 {
    left: 2.75rem;
  }
  .xl\:top-12 {
    top: 3rem;
  }
  .xl\:right-12 {
    right: 3rem;
  }
  .xl\:bottom-12 {
    bottom: 3rem;
  }
  .xl\:left-12 {
    left: 3rem;
  }
  .xl\:top-14 {
    top: 3.5rem;
  }
  .xl\:right-14 {
    right: 3.5rem;
  }
  .xl\:bottom-14 {
    bottom: 3.5rem;
  }
  .xl\:left-14 {
    left: 3.5rem;
  }
  .xl\:top-16 {
    top: 4rem;
  }
  .xl\:right-16 {
    right: 4rem;
  }
  .xl\:bottom-16 {
    bottom: 4rem;
  }
  .xl\:left-16 {
    left: 4rem;
  }
  .xl\:top-20 {
    top: 5rem;
  }
  .xl\:right-20 {
    right: 5rem;
  }
  .xl\:bottom-20 {
    bottom: 5rem;
  }
  .xl\:left-20 {
    left: 5rem;
  }
  .xl\:top-24 {
    top: 6rem;
  }
  .xl\:right-24 {
    right: 6rem;
  }
  .xl\:bottom-24 {
    bottom: 6rem;
  }
  .xl\:left-24 {
    left: 6rem;
  }
  .xl\:top-28 {
    top: 7rem;
  }
  .xl\:right-28 {
    right: 7rem;
  }
  .xl\:bottom-28 {
    bottom: 7rem;
  }
  .xl\:left-28 {
    left: 7rem;
  }
  .xl\:top-32 {
    top: 8rem;
  }
  .xl\:right-32 {
    right: 8rem;
  }
  .xl\:bottom-32 {
    bottom: 8rem;
  }
  .xl\:left-32 {
    left: 8rem;
  }
  .xl\:top-36 {
    top: 9rem;
  }
  .xl\:right-36 {
    right: 9rem;
  }
  .xl\:bottom-36 {
    bottom: 9rem;
  }
  .xl\:left-36 {
    left: 9rem;
  }
  .xl\:top-40 {
    top: 10rem;
  }
  .xl\:right-40 {
    right: 10rem;
  }
  .xl\:bottom-40 {
    bottom: 10rem;
  }
  .xl\:left-40 {
    left: 10rem;
  }
  .xl\:top-44 {
    top: 11rem;
  }
  .xl\:right-44 {
    right: 11rem;
  }
  .xl\:bottom-44 {
    bottom: 11rem;
  }
  .xl\:left-44 {
    left: 11rem;
  }
  .xl\:top-48 {
    top: 12rem;
  }
  .xl\:right-48 {
    right: 12rem;
  }
  .xl\:bottom-48 {
    bottom: 12rem;
  }
  .xl\:left-48 {
    left: 12rem;
  }
  .xl\:top-52 {
    top: 13rem;
  }
  .xl\:right-52 {
    right: 13rem;
  }
  .xl\:bottom-52 {
    bottom: 13rem;
  }
  .xl\:left-52 {
    left: 13rem;
  }
  .xl\:top-56 {
    top: 14rem;
  }
  .xl\:right-56 {
    right: 14rem;
  }
  .xl\:bottom-56 {
    bottom: 14rem;
  }
  .xl\:left-56 {
    left: 14rem;
  }
  .xl\:top-60 {
    top: 15rem;
  }
  .xl\:right-60 {
    right: 15rem;
  }
  .xl\:bottom-60 {
    bottom: 15rem;
  }
  .xl\:left-60 {
    left: 15rem;
  }
  .xl\:top-64 {
    top: 16rem;
  }
  .xl\:right-64 {
    right: 16rem;
  }
  .xl\:bottom-64 {
    bottom: 16rem;
  }
  .xl\:left-64 {
    left: 16rem;
  }
  .xl\:top-72 {
    top: 18rem;
  }
  .xl\:right-72 {
    right: 18rem;
  }
  .xl\:bottom-72 {
    bottom: 18rem;
  }
  .xl\:left-72 {
    left: 18rem;
  }
  .xl\:top-80 {
    top: 20rem;
  }
  .xl\:right-80 {
    right: 20rem;
  }
  .xl\:bottom-80 {
    bottom: 20rem;
  }
  .xl\:left-80 {
    left: 20rem;
  }
  .xl\:top-96 {
    top: 24rem;
  }
  .xl\:right-96 {
    right: 24rem;
  }
  .xl\:bottom-96 {
    bottom: 24rem;
  }
  .xl\:left-96 {
    left: 24rem;
  }
  .xl\:top-auto {
    top: auto;
  }
  .xl\:right-auto {
    right: auto;
  }
  .xl\:bottom-auto {
    bottom: auto;
  }
  .xl\:left-auto {
    left: auto;
  }
  .xl\:top-px {
    top: 1px;
  }
  .xl\:right-px {
    right: 1px;
  }
  .xl\:bottom-px {
    bottom: 1px;
  }
  .xl\:left-px {
    left: 1px;
  }
  .xl\:top-0\.5 {
    top: 0.125rem;
  }
  .xl\:right-0\.5 {
    right: 0.125rem;
  }
  .xl\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .xl\:left-0\.5 {
    left: 0.125rem;
  }
  .xl\:top-1\.5 {
    top: 0.375rem;
  }
  .xl\:right-1\.5 {
    right: 0.375rem;
  }
  .xl\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .xl\:left-1\.5 {
    left: 0.375rem;
  }
  .xl\:top-2\.5 {
    top: 0.625rem;
  }
  .xl\:right-2\.5 {
    right: 0.625rem;
  }
  .xl\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .xl\:left-2\.5 {
    left: 0.625rem;
  }
  .xl\:top-3\.5 {
    top: 0.875rem;
  }
  .xl\:right-3\.5 {
    right: 0.875rem;
  }
  .xl\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .xl\:left-3\.5 {
    left: 0.875rem;
  }
  .xl\:-top-0 {
    top: 0;
  }
  .xl\:-right-0 {
    right: 0;
  }
  .xl\:-bottom-0 {
    bottom: 0;
  }
  .xl\:-left-0 {
    left: 0;
  }
  .xl\:-top-1 {
    top: -0.25rem;
  }
  .xl\:-right-1 {
    right: -0.25rem;
  }
  .xl\:-bottom-1 {
    bottom: -0.25rem;
  }
  .xl\:-left-1 {
    left: -0.25rem;
  }
  .xl\:-top-2 {
    top: -0.5rem;
  }
  .xl\:-right-2 {
    right: -0.5rem;
  }
  .xl\:-bottom-2 {
    bottom: -0.5rem;
  }
  .xl\:-left-2 {
    left: -0.5rem;
  }
  .xl\:-top-3 {
    top: -0.75rem;
  }
  .xl\:-right-3 {
    right: -0.75rem;
  }
  .xl\:-bottom-3 {
    bottom: -0.75rem;
  }
  .xl\:-left-3 {
    left: -0.75rem;
  }
  .xl\:-top-4 {
    top: -1rem;
  }
  .xl\:-right-4 {
    right: -1rem;
  }
  .xl\:-bottom-4 {
    bottom: -1rem;
  }
  .xl\:-left-4 {
    left: -1rem;
  }
  .xl\:-top-5 {
    top: -1.25rem;
  }
  .xl\:-right-5 {
    right: -1.25rem;
  }
  .xl\:-bottom-5 {
    bottom: -1.25rem;
  }
  .xl\:-left-5 {
    left: -1.25rem;
  }
  .xl\:-top-6 {
    top: -1.5rem;
  }
  .xl\:-right-6 {
    right: -1.5rem;
  }
  .xl\:-bottom-6 {
    bottom: -1.5rem;
  }
  .xl\:-left-6 {
    left: -1.5rem;
  }
  .xl\:-top-7 {
    top: -1.75rem;
  }
  .xl\:-right-7 {
    right: -1.75rem;
  }
  .xl\:-bottom-7 {
    bottom: -1.75rem;
  }
  .xl\:-left-7 {
    left: -1.75rem;
  }
  .xl\:-top-8 {
    top: -2rem;
  }
  .xl\:-right-8 {
    right: -2rem;
  }
  .xl\:-bottom-8 {
    bottom: -2rem;
  }
  .xl\:-left-8 {
    left: -2rem;
  }
  .xl\:-top-9 {
    top: -2.25rem;
  }
  .xl\:-right-9 {
    right: -2.25rem;
  }
  .xl\:-bottom-9 {
    bottom: -2.25rem;
  }
  .xl\:-left-9 {
    left: -2.25rem;
  }
  .xl\:-top-10 {
    top: -2.5rem;
  }
  .xl\:-right-10 {
    right: -2.5rem;
  }
  .xl\:-bottom-10 {
    bottom: -2.5rem;
  }
  .xl\:-left-10 {
    left: -2.5rem;
  }
  .xl\:-top-11 {
    top: -2.75rem;
  }
  .xl\:-right-11 {
    right: -2.75rem;
  }
  .xl\:-bottom-11 {
    bottom: -2.75rem;
  }
  .xl\:-left-11 {
    left: -2.75rem;
  }
  .xl\:-top-12 {
    top: -3rem;
  }
  .xl\:-right-12 {
    right: -3rem;
  }
  .xl\:-bottom-12 {
    bottom: -3rem;
  }
  .xl\:-left-12 {
    left: -3rem;
  }
  .xl\:-top-14 {
    top: -3.5rem;
  }
  .xl\:-right-14 {
    right: -3.5rem;
  }
  .xl\:-bottom-14 {
    bottom: -3.5rem;
  }
  .xl\:-left-14 {
    left: -3.5rem;
  }
  .xl\:-top-16 {
    top: -4rem;
  }
  .xl\:-right-16 {
    right: -4rem;
  }
  .xl\:-bottom-16 {
    bottom: -4rem;
  }
  .xl\:-left-16 {
    left: -4rem;
  }
  .xl\:-top-20 {
    top: -5rem;
  }
  .xl\:-right-20 {
    right: -5rem;
  }
  .xl\:-bottom-20 {
    bottom: -5rem;
  }
  .xl\:-left-20 {
    left: -5rem;
  }
  .xl\:-top-24 {
    top: -6rem;
  }
  .xl\:-right-24 {
    right: -6rem;
  }
  .xl\:-bottom-24 {
    bottom: -6rem;
  }
  .xl\:-left-24 {
    left: -6rem;
  }
  .xl\:-top-28 {
    top: -7rem;
  }
  .xl\:-right-28 {
    right: -7rem;
  }
  .xl\:-bottom-28 {
    bottom: -7rem;
  }
  .xl\:-left-28 {
    left: -7rem;
  }
  .xl\:-top-32 {
    top: -8rem;
  }
  .xl\:-right-32 {
    right: -8rem;
  }
  .xl\:-bottom-32 {
    bottom: -8rem;
  }
  .xl\:-left-32 {
    left: -8rem;
  }
  .xl\:-top-36 {
    top: -9rem;
  }
  .xl\:-right-36 {
    right: -9rem;
  }
  .xl\:-bottom-36 {
    bottom: -9rem;
  }
  .xl\:-left-36 {
    left: -9rem;
  }
  .xl\:-top-40 {
    top: -10rem;
  }
  .xl\:-right-40 {
    right: -10rem;
  }
  .xl\:-bottom-40 {
    bottom: -10rem;
  }
  .xl\:-left-40 {
    left: -10rem;
  }
  .xl\:-top-44 {
    top: -11rem;
  }
  .xl\:-right-44 {
    right: -11rem;
  }
  .xl\:-bottom-44 {
    bottom: -11rem;
  }
  .xl\:-left-44 {
    left: -11rem;
  }
  .xl\:-top-48 {
    top: -12rem;
  }
  .xl\:-right-48 {
    right: -12rem;
  }
  .xl\:-bottom-48 {
    bottom: -12rem;
  }
  .xl\:-left-48 {
    left: -12rem;
  }
  .xl\:-top-52 {
    top: -13rem;
  }
  .xl\:-right-52 {
    right: -13rem;
  }
  .xl\:-bottom-52 {
    bottom: -13rem;
  }
  .xl\:-left-52 {
    left: -13rem;
  }
  .xl\:-top-56 {
    top: -14rem;
  }
  .xl\:-right-56 {
    right: -14rem;
  }
  .xl\:-bottom-56 {
    bottom: -14rem;
  }
  .xl\:-left-56 {
    left: -14rem;
  }
  .xl\:-top-60 {
    top: -15rem;
  }
  .xl\:-right-60 {
    right: -15rem;
  }
  .xl\:-bottom-60 {
    bottom: -15rem;
  }
  .xl\:-left-60 {
    left: -15rem;
  }
  .xl\:-top-64 {
    top: -16rem;
  }
  .xl\:-right-64 {
    right: -16rem;
  }
  .xl\:-bottom-64 {
    bottom: -16rem;
  }
  .xl\:-left-64 {
    left: -16rem;
  }
  .xl\:-top-72 {
    top: -18rem;
  }
  .xl\:-right-72 {
    right: -18rem;
  }
  .xl\:-bottom-72 {
    bottom: -18rem;
  }
  .xl\:-left-72 {
    left: -18rem;
  }
  .xl\:-top-80 {
    top: -20rem;
  }
  .xl\:-right-80 {
    right: -20rem;
  }
  .xl\:-bottom-80 {
    bottom: -20rem;
  }
  .xl\:-left-80 {
    left: -20rem;
  }
  .xl\:-top-96 {
    top: -24rem;
  }
  .xl\:-right-96 {
    right: -24rem;
  }
  .xl\:-bottom-96 {
    bottom: -24rem;
  }
  .xl\:-left-96 {
    left: -24rem;
  }
  .xl\:-top-px {
    top: -1px;
  }
  .xl\:-right-px {
    right: -1px;
  }
  .xl\:-bottom-px {
    bottom: -1px;
  }
  .xl\:-left-px {
    left: -1px;
  }
  .xl\:-top-0\.5 {
    top: -0.125rem;
  }
  .xl\:-right-0\.5 {
    right: -0.125rem;
  }
  .xl\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .xl\:-left-0\.5 {
    left: -0.125rem;
  }
  .xl\:-top-1\.5 {
    top: -0.375rem;
  }
  .xl\:-right-1\.5 {
    right: -0.375rem;
  }
  .xl\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .xl\:-left-1\.5 {
    left: -0.375rem;
  }
  .xl\:-top-2\.5 {
    top: -0.625rem;
  }
  .xl\:-right-2\.5 {
    right: -0.625rem;
  }
  .xl\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .xl\:-left-2\.5 {
    left: -0.625rem;
  }
  .xl\:-top-3\.5 {
    top: -0.875rem;
  }
  .xl\:-right-3\.5 {
    right: -0.875rem;
  }
  .xl\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .xl\:-left-3\.5 {
    left: -0.875rem;
  }
  .xl\:top-1\/2 {
    top: 50%;
  }
  .xl\:right-1\/2 {
    right: 50%;
  }
  .xl\:bottom-1\/2 {
    bottom: 50%;
  }
  .xl\:left-1\/2 {
    left: 50%;
  }
  .xl\:top-1\/3 {
    top: 33.333333%;
  }
  .xl\:right-1\/3 {
    right: 33.333333%;
  }
  .xl\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .xl\:left-1\/3 {
    left: 33.333333%;
  }
  .xl\:top-2\/3 {
    top: 66.666667%;
  }
  .xl\:right-2\/3 {
    right: 66.666667%;
  }
  .xl\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .xl\:left-2\/3 {
    left: 66.666667%;
  }
  .xl\:top-1\/4 {
    top: 25%;
  }
  .xl\:right-1\/4 {
    right: 25%;
  }
  .xl\:bottom-1\/4 {
    bottom: 25%;
  }
  .xl\:left-1\/4 {
    left: 25%;
  }
  .xl\:top-2\/4 {
    top: 50%;
  }
  .xl\:right-2\/4 {
    right: 50%;
  }
  .xl\:bottom-2\/4 {
    bottom: 50%;
  }
  .xl\:left-2\/4 {
    left: 50%;
  }
  .xl\:top-3\/4 {
    top: 75%;
  }
  .xl\:right-3\/4 {
    right: 75%;
  }
  .xl\:bottom-3\/4 {
    bottom: 75%;
  }
  .xl\:left-3\/4 {
    left: 75%;
  }
  .xl\:top-full {
    top: 100%;
  }
  .xl\:right-full {
    right: 100%;
  }
  .xl\:bottom-full {
    bottom: 100%;
  }
  .xl\:left-full {
    left: 100%;
  }
  .xl\:-top-1\/2 {
    top: -50%;
  }
  .xl\:-right-1\/2 {
    right: -50%;
  }
  .xl\:-bottom-1\/2 {
    bottom: -50%;
  }
  .xl\:-left-1\/2 {
    left: -50%;
  }
  .xl\:-top-1\/3 {
    top: -33.333333%;
  }
  .xl\:-right-1\/3 {
    right: -33.333333%;
  }
  .xl\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .xl\:-left-1\/3 {
    left: -33.333333%;
  }
  .xl\:-top-2\/3 {
    top: -66.666667%;
  }
  .xl\:-right-2\/3 {
    right: -66.666667%;
  }
  .xl\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .xl\:-left-2\/3 {
    left: -66.666667%;
  }
  .xl\:-top-1\/4 {
    top: -25%;
  }
  .xl\:-right-1\/4 {
    right: -25%;
  }
  .xl\:-bottom-1\/4 {
    bottom: -25%;
  }
  .xl\:-left-1\/4 {
    left: -25%;
  }
  .xl\:-top-2\/4 {
    top: -50%;
  }
  .xl\:-right-2\/4 {
    right: -50%;
  }
  .xl\:-bottom-2\/4 {
    bottom: -50%;
  }
  .xl\:-left-2\/4 {
    left: -50%;
  }
  .xl\:-top-3\/4 {
    top: -75%;
  }
  .xl\:-right-3\/4 {
    right: -75%;
  }
  .xl\:-bottom-3\/4 {
    bottom: -75%;
  }
  .xl\:-left-3\/4 {
    left: -75%;
  }
  .xl\:-top-full {
    top: -100%;
  }
  .xl\:-right-full {
    right: -100%;
  }
  .xl\:-bottom-full {
    bottom: -100%;
  }
  .xl\:-left-full {
    left: -100%;
  }
  .xl\:resize-none {
    resize: none;
  }
  .xl\:resize-y {
    resize: vertical;
  }
  .xl\:resize-x {
    resize: horizontal;
  }
  .xl\:resize {
    resize: both;
  }
  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xl\:shadow,
  .xl\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xl\:shadow-lg,
  .xl\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xl\:shadow-2xl,
  .xl\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:shadow-inner,
  .xl\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .xl\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xl\:shadow-bottom-nav,
  .xl\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xl\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .xl\:group-hover\:shadow-sm,
  .xl\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xl\:group-hover\:shadow,
  .group:hover .xl\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .xl\:group-hover\:shadow-lg,
  .group:hover .xl\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .xl\:group-hover\:shadow-2xl,
  .group:hover .xl\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .xl\:group-hover\:shadow-none,
  .group:hover .xl\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .xl\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .xl\:group-hover\:shadow-bottom-nav,
  .group:hover .xl\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xl\:hover\:shadow-sm:hover,
  .xl\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xl\:hover\:shadow-lg:hover,
  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xl\:hover\:shadow-2xl:hover,
  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:hover\:shadow-inner:hover,
  .xl\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .xl\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xl\:hover\:shadow-bottom-nav:hover,
  .xl\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xl\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xl\:focus\:shadow-sm:focus,
  .xl\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:focus\:shadow-md:focus,
  .xl\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xl\:focus\:shadow-lg:focus,
  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xl\:focus\:shadow-2xl:focus,
  .xl\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .xl\:focus\:shadow-none:focus,
  .xl\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xl\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xl\:focus\:shadow-bottom-nav:focus,
  .xl\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xl\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring-0,
  .xl\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring-2,
  .xl\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring,
  .xl\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:ring-inset {
    --tw-ring-inset: inset;
  }
  .xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-0:focus,
  .xl\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-2:focus,
  .xl\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-8:focus,
  .xl\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .xl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .xl\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xl\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .xl\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .xl\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xl\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xl\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .xl\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .xl\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .xl\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .xl\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .xl\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .xl\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .xl\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xl\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .xl\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .xl\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .xl\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .xl\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .xl\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .xl\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .xl\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .xl\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .xl\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xl\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xl\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xl\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xl\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .xl\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .xl\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .xl\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xl\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .xl\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .xl\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xl\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xl\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .xl\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .xl\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .xl\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .xl\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xl\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .xl\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .xl\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xl\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .xl\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .xl\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .xl\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .xl\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xl\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .xl\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .xl\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xl\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xl\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .xl\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .xl\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .xl\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .xl\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .xl\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .xl\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .xl\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xl\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .xl\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .xl\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .xl\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .xl\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .xl\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .xl\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .xl\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .xl\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .xl\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xl\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xl\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xl\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xl\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .xl\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .xl\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .xl\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xl\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .xl\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .xl\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xl\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xl\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .xl\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .xl\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .xl\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .xl\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xl\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .xl\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .xl\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xl\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .xl\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .xl\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .xl\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .xl\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xl\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .xl\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .xl\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xl\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xl\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .xl\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .xl\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .xl\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .xl\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .xl\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .xl\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .xl\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xl\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .xl\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .xl\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .xl\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .xl\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .xl\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .xl\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .xl\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .xl\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .xl\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xl\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xl\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xl\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xl\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .xl\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .xl\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .xl\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xl\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .xl\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .xl\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xl\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xl\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .xl\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .xl\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .xl\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .xl\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xl\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .xl\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .xl\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xl\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xl\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .xl\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .xl\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .xl\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .xl\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .xl\:ring-current {
    --tw-ring-color: currentColor;
  }
  .xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .xl\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xl\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xl\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xl\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xl\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xl\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xl\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xl\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xl\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xl\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xl\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xl\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .xl\:ring-background {
    --tw-ring-color: var(--background);
  }
  .xl\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .xl\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .xl\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .xl\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .xl\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .xl\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .xl\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .xl\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .xl\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xl\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .xl\:ring-success {
    --tw-ring-color: var(--success);
  }
  .xl\:ring-error {
    --tw-ring-color: var(--error);
  }
  .xl\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .xl\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xl\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xl\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xl\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .xl\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xl\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xl\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .xl\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .xl\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .xl\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .xl\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .xl\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .xl\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .xl\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .xl\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .xl\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .xl\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .xl\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xl\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .xl\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .xl\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .xl\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .xl\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xl\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xl\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .xl\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .xl\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .xl\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .xl\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .xl\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .xl\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .xl\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .xl\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .xl\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .xl\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .xl\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .xl\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xl\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .xl\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .xl\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .xl\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .xl\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xl\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xl\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .xl\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .xl\:fill-current {
    fill: currentColor;
  }
  .xl\:stroke-current {
    stroke: currentColor;
  }
  .xl\:stroke-0 {
    stroke-width: 0;
  }
  .xl\:stroke-1 {
    stroke-width: 1;
  }
  .xl\:stroke-2 {
    stroke-width: 2;
  }
  .xl\:table-auto {
    table-layout: auto;
  }
  .xl\:table-fixed {
    table-layout: fixed;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:text-center {
    text-align: center;
  }
  .xl\:text-right {
    text-align: right;
  }
  .xl\:text-justify {
    text-align: justify;
  }
  .xl\:text-transparent {
    color: transparent;
  }
  .xl\:text-current {
    color: currentColor;
  }
  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:text-background-primary {
    color: var(--background-primary);
  }
  .xl\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xl\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xl\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xl\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xl\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xl\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xl\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xl\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xl\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xl\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xl\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xl\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xl\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xl\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xl\:text-body-background {
    color: var(--body-background);
  }
  .xl\:text-background {
    color: var(--background);
  }
  .xl\:text-input-background {
    color: var(--input-background);
  }
  .xl\:text-body-text {
    color: var(--body-text);
  }
  .xl\:text-header-text {
    color: var(--header-text);
  }
  .xl\:text-primary {
    color: var(--primary);
  }
  .xl\:text-secondary {
    color: var(--secondary);
  }
  .xl\:text-tertiary {
    color: var(--tertiary);
  }
  .xl\:text-primary-hover {
    color: var(--primary-hover);
  }
  .xl\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .xl\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .xl\:text-input-text {
    color: var(--input-text);
  }
  .xl\:text-success {
    color: var(--success);
  }
  .xl\:text-error {
    color: var(--error);
  }
  .xl\:text-custom-loader {
    color: var(--custom-loader);
  }
  .xl\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xl\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xl\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .xl\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .xl\:text-background50 {
    color: var(--background50);
  }
  .xl\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xl\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xl\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xl\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xl\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xl\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xl\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xl\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xl\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xl\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xl\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .xl\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xl\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .xl\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .xl\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .xl\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .xl\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .xl\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .xl\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .xl\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .xl\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .xl\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .xl\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .xl\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .xl\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .xl\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .xl\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .xl\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .xl\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .xl\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .xl\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .xl\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .xl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .xl\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .xl\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .xl\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .xl\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .xl\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .xl\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .xl\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .xl\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .xl\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .xl\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .xl\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .xl\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .xl\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .xl\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .xl\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .xl\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .xl\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .xl\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .xl\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xl\:hover\:text-transparent:hover {
    color: transparent;
  }
  .xl\:hover\:text-current:hover {
    color: currentColor;
  }
  .xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .xl\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xl\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xl\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xl\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xl\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xl\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xl\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xl\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xl\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xl\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xl\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .xl\:hover\:text-background:hover {
    color: var(--background);
  }
  .xl\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .xl\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .xl\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .xl\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .xl\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .xl\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .xl\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .xl\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .xl\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .xl\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .xl\:hover\:text-success:hover {
    color: var(--success);
  }
  .xl\:hover\:text-error:hover {
    color: var(--error);
  }
  .xl\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .xl\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xl\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xl\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .xl\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .xl\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .xl\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xl\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xl\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xl\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xl\:focus\:text-transparent:focus {
    color: transparent;
  }
  .xl\:focus\:text-current:focus {
    color: currentColor;
  }
  .xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .xl\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .xl\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xl\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xl\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xl\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xl\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xl\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xl\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xl\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xl\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xl\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xl\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .xl\:focus\:text-background:focus {
    color: var(--background);
  }
  .xl\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .xl\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .xl\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .xl\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .xl\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .xl\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .xl\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .xl\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .xl\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .xl\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .xl\:focus\:text-success:focus {
    color: var(--success);
  }
  .xl\:focus\:text-error:focus {
    color: var(--error);
  }
  .xl\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .xl\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xl\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xl\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .xl\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .xl\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .xl\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xl\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xl\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xl\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xl\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .xl\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .xl\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .xl\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .xl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .xl\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .xl\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .xl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .xl\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .xl\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .xl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .xl\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .xl\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .xl\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .xl\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .xl\:overflow-ellipsis,
  .xl\:truncate {
    text-overflow: ellipsis;
  }
  .xl\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .xl\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .xl\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .xl\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .xl\:italic {
    font-style: italic;
  }
  .xl\:not-italic {
    font-style: normal;
  }
  .xl\:uppercase {
    text-transform: uppercase;
  }
  .xl\:lowercase {
    text-transform: lowercase;
  }
  .xl\:capitalize {
    text-transform: capitalize;
  }
  .xl\:normal-case {
    text-transform: none;
  }
  .xl\:underline {
    text-decoration: underline;
  }
  .xl\:line-through {
    text-decoration: line-through;
  }
  .xl\:no-underline {
    text-decoration: none;
  }
  .group:hover .xl\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .xl\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .xl\:group-hover\:no-underline {
    text-decoration: none;
  }
  .xl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .xl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .xl\:hover\:underline:hover {
    text-decoration: underline;
  }
  .xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .xl\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .xl\:focus\:underline:focus {
    text-decoration: underline;
  }
  .xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .xl\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .xl\:diagonal-fractions,
  .xl\:lining-nums,
  .xl\:oldstyle-nums,
  .xl\:ordinal,
  .xl\:proportional-nums,
  .xl\:slashed-zero,
  .xl\:stacked-fractions,
  .xl\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .xl\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .xl\:ordinal {
    --tw-ordinal: ordinal;
  }
  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .xl\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .xl\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .xl\:tracking-normal {
    letter-spacing: 0;
  }
  .xl\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .xl\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .xl\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .xl\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .xl\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .xl\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .xl\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .xl\:align-baseline {
    vertical-align: baseline;
  }
  .xl\:align-top {
    vertical-align: top;
  }
  .xl\:align-middle {
    vertical-align: middle;
  }
  .xl\:align-bottom {
    vertical-align: bottom;
  }
  .xl\:align-text-top {
    vertical-align: text-top;
  }
  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .xl\:visible {
    visibility: visible;
  }
  .xl\:invisible {
    visibility: hidden;
  }
  .xl\:whitespace-normal {
    white-space: normal;
  }
  .xl\:whitespace-nowrap {
    white-space: nowrap;
  }
  .xl\:whitespace-pre {
    white-space: pre;
  }
  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }
  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .xl\:break-words {
    overflow-wrap: break-word;
  }
  .xl\:break-all {
    word-break: break-all;
  }
  .group:hover .xl\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .xl\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .xl\:group-hover\:break-all {
    word-break: break-all;
  }
  .xl\:w-0 {
    width: 0;
  }
  .xl\:w-1 {
    width: 0.25rem;
  }
  .xl\:w-2 {
    width: 0.5rem;
  }
  .xl\:w-3 {
    width: 0.75rem;
  }
  .xl\:w-4 {
    width: 1rem;
  }
  .xl\:w-5 {
    width: 1.25rem;
  }
  .xl\:w-6 {
    width: 1.5rem;
  }
  .xl\:w-7 {
    width: 1.75rem;
  }
  .xl\:w-8 {
    width: 2rem;
  }
  .xl\:w-9 {
    width: 2.25rem;
  }
  .xl\:w-10 {
    width: 2.5rem;
  }
  .xl\:w-11 {
    width: 2.75rem;
  }
  .xl\:w-12 {
    width: 3rem;
  }
  .xl\:w-14 {
    width: 3.5rem;
  }
  .xl\:w-16 {
    width: 4rem;
  }
  .xl\:w-20 {
    width: 5rem;
  }
  .xl\:w-24 {
    width: 6rem;
  }
  .xl\:w-28 {
    width: 7rem;
  }
  .xl\:w-32 {
    width: 8rem;
  }
  .xl\:w-36 {
    width: 9rem;
  }
  .xl\:w-40 {
    width: 10rem;
  }
  .xl\:w-44 {
    width: 11rem;
  }
  .xl\:w-48 {
    width: 12rem;
  }
  .xl\:w-52 {
    width: 13rem;
  }
  .xl\:w-56 {
    width: 14rem;
  }
  .xl\:w-60 {
    width: 15rem;
  }
  .xl\:w-64 {
    width: 16rem;
  }
  .xl\:w-72 {
    width: 18rem;
  }
  .xl\:w-80 {
    width: 20rem;
  }
  .xl\:w-96 {
    width: 24rem;
  }
  .xl\:w-150 {
    width: 150px;
  }
  .xl\:w-199 {
    width: 199px;
  }
  .xl\:w-286 {
    width: 286px;
  }
  .xl\:w-512 {
    width: 512px;
  }
  .xl\:w-560 {
    width: 560px;
  }
  .xl\:w-650 {
    width: 650px;
  }
  .xl\:w-auto {
    width: auto;
  }
  .xl\:w-px {
    width: 1px;
  }
  .xl\:w-0\.5 {
    width: 0.125rem;
  }
  .xl\:w-1\.5 {
    width: 0.375rem;
  }
  .xl\:w-2\.5 {
    width: 0.625rem;
  }
  .xl\:w-3\.5 {
    width: 0.875rem;
  }
  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-1\/3 {
    width: 33.333333%;
  }
  .xl\:w-2\/3 {
    width: 66.666667%;
  }
  .xl\:w-1\/4 {
    width: 25%;
  }
  .xl\:w-2\/4 {
    width: 50%;
  }
  .xl\:w-3\/4 {
    width: 75%;
  }
  .xl\:w-1\/5 {
    width: 20%;
  }
  .xl\:w-2\/5 {
    width: 40%;
  }
  .xl\:w-3\/5 {
    width: 60%;
  }
  .xl\:w-4\/5 {
    width: 80%;
  }
  .xl\:w-1\/6 {
    width: 16.666667%;
  }
  .xl\:w-2\/6 {
    width: 33.333333%;
  }
  .xl\:w-3\/6 {
    width: 50%;
  }
  .xl\:w-4\/6 {
    width: 66.666667%;
  }
  .xl\:w-5\/6 {
    width: 83.333333%;
  }
  .xl\:w-1\/12 {
    width: 8.333333%;
  }
  .xl\:w-2\/12 {
    width: 16.666667%;
  }
  .xl\:w-3\/12 {
    width: 25%;
  }
  .xl\:w-4\/12 {
    width: 33.333333%;
  }
  .xl\:w-5\/12 {
    width: 41.666667%;
  }
  .xl\:w-6\/12 {
    width: 50%;
  }
  .xl\:w-7\/12 {
    width: 58.333333%;
  }
  .xl\:w-8\/12 {
    width: 66.666667%;
  }
  .xl\:w-9\/12 {
    width: 75%;
  }
  .xl\:w-10\/12 {
    width: 83.333333%;
  }
  .xl\:w-11\/12 {
    width: 91.666667%;
  }
  .xl\:w-full {
    width: 100%;
  }
  .xl\:w-screen {
    width: 100vw;
  }
  .xl\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .xl\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .xl\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .xl\:z-0 {
    z-index: 0;
  }
  .xl\:z-10 {
    z-index: 10;
  }
  .xl\:z-20 {
    z-index: 20;
  }
  .xl\:z-30 {
    z-index: 30;
  }
  .xl\:z-40 {
    z-index: 40;
  }
  .xl\:z-50 {
    z-index: 50;
  }
  .xl\:z-auto {
    z-index: auto;
  }
  .xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .xl\:focus\:z-0:focus {
    z-index: 0;
  }
  .xl\:focus\:z-10:focus {
    z-index: 10;
  }
  .xl\:focus\:z-20:focus {
    z-index: 20;
  }
  .xl\:focus\:z-30:focus {
    z-index: 30;
  }
  .xl\:focus\:z-40:focus {
    z-index: 40;
  }
  .xl\:focus\:z-50:focus {
    z-index: 50;
  }
  .xl\:focus\:z-auto:focus {
    z-index: auto;
  }
  .xl\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .xl\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .xl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .xl\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .xl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .xl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .xl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .xl\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .xl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .xl\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .xl\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .xl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .xl\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .xl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .xl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .xl\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .xl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .xl\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .xl\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .xl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .xl\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .xl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .xl\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .xl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .xl\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .xl\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .xl\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .xl\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .xl\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .xl\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .xl\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .xl\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .xl\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .xl\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .xl\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .xl\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .xl\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .xl\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .xl\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .xl\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .xl\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .xl\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .xl\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .xl\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .xl\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .xl\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .xl\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .xl\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .xl\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .xl\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .xl\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .xl\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .xl\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .xl\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .xl\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .xl\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .xl\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .xl\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .xl\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .xl\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .xl\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .xl\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .xl\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .xl\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .xl\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .xl\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .xl\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .xl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .xl\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .xl\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .xl\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .xl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .xl\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .xl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .xl\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .xl\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .xl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .xl\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .xl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .xl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .xl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .xl\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .xl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .xl\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .xl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .xl\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .xl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .xl\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .xl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .xl\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .xl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .xl\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .xl\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .xl\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .xl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .xl\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .xl\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .xl\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .xl\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }
  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xl\:grid-cols-none {
    grid-template-columns: none;
  }
  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .xl\:col-auto {
    grid-column: auto;
  }
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .xl\:col-span-full {
    grid-column: 1/-1;
  }
  .xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-start-2 {
    grid-column-start: 2;
  }
  .xl\:col-start-3 {
    grid-column-start: 3;
  }
  .xl\:col-start-4 {
    grid-column-start: 4;
  }
  .xl\:col-start-5 {
    grid-column-start: 5;
  }
  .xl\:col-start-6 {
    grid-column-start: 6;
  }
  .xl\:col-start-7 {
    grid-column-start: 7;
  }
  .xl\:col-start-8 {
    grid-column-start: 8;
  }
  .xl\:col-start-9 {
    grid-column-start: 9;
  }
  .xl\:col-start-10 {
    grid-column-start: 10;
  }
  .xl\:col-start-11 {
    grid-column-start: 11;
  }
  .xl\:col-start-12 {
    grid-column-start: 12;
  }
  .xl\:col-start-13 {
    grid-column-start: 13;
  }
  .xl\:col-start-auto {
    grid-column-start: auto;
  }
  .xl\:col-end-1 {
    grid-column-end: 1;
  }
  .xl\:col-end-2 {
    grid-column-end: 2;
  }
  .xl\:col-end-3 {
    grid-column-end: 3;
  }
  .xl\:col-end-4 {
    grid-column-end: 4;
  }
  .xl\:col-end-5 {
    grid-column-end: 5;
  }
  .xl\:col-end-6 {
    grid-column-end: 6;
  }
  .xl\:col-end-7 {
    grid-column-end: 7;
  }
  .xl\:col-end-8 {
    grid-column-end: 8;
  }
  .xl\:col-end-9 {
    grid-column-end: 9;
  }
  .xl\:col-end-10 {
    grid-column-end: 10;
  }
  .xl\:col-end-11 {
    grid-column-end: 11;
  }
  .xl\:col-end-12 {
    grid-column-end: 12;
  }
  .xl\:col-end-13 {
    grid-column-end: 13;
  }
  .xl\:col-end-auto {
    grid-column-end: auto;
  }
  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-rows-none {
    grid-template-rows: none;
  }
  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .xl\:row-auto {
    grid-row: auto;
  }
  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .xl\:row-span-full {
    grid-row: 1/-1;
  }
  .xl\:row-start-1 {
    grid-row-start: 1;
  }
  .xl\:row-start-2 {
    grid-row-start: 2;
  }
  .xl\:row-start-3 {
    grid-row-start: 3;
  }
  .xl\:row-start-4 {
    grid-row-start: 4;
  }
  .xl\:row-start-5 {
    grid-row-start: 5;
  }
  .xl\:row-start-6 {
    grid-row-start: 6;
  }
  .xl\:row-start-7 {
    grid-row-start: 7;
  }
  .xl\:row-start-auto {
    grid-row-start: auto;
  }
  .xl\:row-end-1 {
    grid-row-end: 1;
  }
  .xl\:row-end-2 {
    grid-row-end: 2;
  }
  .xl\:row-end-3 {
    grid-row-end: 3;
  }
  .xl\:row-end-4 {
    grid-row-end: 4;
  }
  .xl\:row-end-5 {
    grid-row-end: 5;
  }
  .xl\:row-end-6 {
    grid-row-end: 6;
  }
  .xl\:row-end-7 {
    grid-row-end: 7;
  }
  .xl\:row-end-auto {
    grid-row-end: auto;
  }
  .xl\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xl\:transform,
  .xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xl\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .xl\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .xl\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .xl\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .xl\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .xl\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .xl\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .xl\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .xl\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .xl\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .xl\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .xl\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .xl\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .xl\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .xl\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .xl\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .xl\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .xl\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .xl\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .xl\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .xl\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .xl\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .xl\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .xl\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .xl\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .xl\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .xl\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .xl\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .xl\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .xl\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .xl\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .xl\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .xl\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .xl\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .xl\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .xl\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .xl\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .xl\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .xl\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .xl\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .xl\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .xl\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .xl\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .xl\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .xl\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .xl\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .xl\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .xl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .xl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .xl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .xl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .xl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .xl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .xl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .xl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .xl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .xl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .xl\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .xl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .xl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .xl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .xl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .xl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .xl\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .xl\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .xl\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .xl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .xl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .xl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .xl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .xl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .xl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .xl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .xl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .xl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .xl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .xl\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .xl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .xl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .xl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .xl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .xl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .xl\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .xl\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .xl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .xl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .xl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .xl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .xl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .xl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .xl\:transition-none {
    transition-property: none;
  }
  .xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xl\:ease-linear {
    transition-timing-function: linear;
  }
  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .xl\:duration-75 {
    transition-duration: 75ms;
  }
  .xl\:duration-100 {
    transition-duration: 0.1s;
  }
  .xl\:duration-150 {
    transition-duration: 0.15s;
  }
  .xl\:duration-200 {
    transition-duration: 0.2s;
  }
  .xl\:duration-300 {
    transition-duration: 0.3s;
  }
  .xl\:duration-500 {
    transition-duration: 0.5s;
  }
  .xl\:duration-700 {
    transition-duration: 0.7s;
  }
  .xl\:duration-1000 {
    transition-duration: 1s;
  }
  .xl\:delay-75 {
    transition-delay: 75ms;
  }
  .xl\:delay-100 {
    transition-delay: 0.1s;
  }
  .xl\:delay-150 {
    transition-delay: 0.15s;
  }
  .xl\:delay-200 {
    transition-delay: 0.2s;
  }
  .xl\:delay-300 {
    transition-delay: 0.3s;
  }
  .xl\:delay-500 {
    transition-delay: 0.5s;
  }
  .xl\:delay-700 {
    transition-delay: 0.7s;
  }
  .xl\:delay-1000 {
    transition-delay: 1s;
  }
  .xl\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .xl\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .xl\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .xl\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .xl\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 1536px) {
  .\32xl\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .\32xl\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .\32xl\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .\32xl\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .\32xl\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .\32xl\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .\32xl\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .\32xl\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .\32xl\:container {
      max-width: 1536px;
    }
  }
  .\32xl\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .\32xl\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .\32xl\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .\32xl\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .\32xl\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .\32xl\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .\32xl\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .\32xl\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .\32xl\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .\32xl\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .\32xl\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .\32xl\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .\32xl\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .\32xl\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .\32xl\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .\32xl\:prose h3 {
    color: #6d728b;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .\32xl\:prose h4 {
    color: #6d728b;
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .\32xl\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .\32xl\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .\32xl\:prose code:after,
  .\32xl\:prose code:before {
    content: '`';
  }
  .\32xl\:prose a code {
    color: #6d728b;
  }
  .\32xl\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .\32xl\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .\32xl\:prose pre code:after,
  .\32xl\:prose pre code:before {
    content: '';
  }
  .\32xl\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .\32xl\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .\32xl\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .\32xl\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .\32xl\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .\32xl\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .\32xl\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .\32xl\:prose figure,
  .\32xl\:prose img,
  .\32xl\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .\32xl\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:prose h2 code {
    font-size: 0.875em;
  }
  .\32xl\:prose h3 code {
    font-size: 0.9em;
  }
  .\32xl\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .\32xl\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .\32xl\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .\32xl\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .\32xl\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .\32xl\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .\32xl\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .\32xl\:prose ol ol,
  .\32xl\:prose ol ul,
  .\32xl\:prose ul ol,
  .\32xl\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .\32xl\:prose h2 + *,
  .\32xl\:prose h3 + *,
  .\32xl\:prose h4 + *,
  .\32xl\:prose hr + * {
    margin-top: 0;
  }
  .\32xl\:prose thead th:first-child {
    padding-left: 0;
  }
  .\32xl\:prose thead th:last-child {
    padding-right: 0;
  }
  .\32xl\:prose tbody td:first-child {
    padding-left: 0;
  }
  .\32xl\:prose tbody td:last-child {
    padding-right: 0;
  }
  .\32xl\:prose > :first-child {
    margin-top: 0;
  }
  .\32xl\:prose > :last-child {
    margin-bottom: 0;
  }
  .\32xl\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .\32xl\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .\32xl\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .\32xl\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .\32xl\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .\32xl\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .\32xl\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .\32xl\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .\32xl\:prose-sm figure,
  .\32xl\:prose-sm img,
  .\32xl\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .\32xl\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .\32xl\:prose-sm code {
    font-size: 0.8571429em;
  }
  .\32xl\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .\32xl\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .\32xl\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .\32xl\:prose-sm ol,
  .\32xl\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .\32xl\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .\32xl\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .\32xl\:prose-sm ol > li:before {
    left: 0;
  }
  .\32xl\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .\32xl\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .\32xl\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .\32xl\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .\32xl\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .\32xl\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .\32xl\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .\32xl\:prose-sm ol ol,
  .\32xl\:prose-sm ol ul,
  .\32xl\:prose-sm ul ol,
  .\32xl\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .\32xl\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .\32xl\:prose-sm h2 + *,
  .\32xl\:prose-sm h3 + *,
  .\32xl\:prose-sm h4 + *,
  .\32xl\:prose-sm hr + * {
    margin-top: 0;
  }
  .\32xl\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .\32xl\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .\32xl\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .\32xl\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-sm > :first-child {
    margin-top: 0;
  }
  .\32xl\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .\32xl\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .\32xl\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .\32xl\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .\32xl\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .\32xl\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .\32xl\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .\32xl\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .\32xl\:prose-lg figure,
  .\32xl\:prose-lg img,
  .\32xl\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .\32xl\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .\32xl\:prose-lg code {
    font-size: 0.8888889em;
  }
  .\32xl\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .\32xl\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .\32xl\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .\32xl\:prose-lg ol,
  .\32xl\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .\32xl\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .\32xl\:prose-lg ol > li:before {
    left: 0;
  }
  .\32xl\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .\32xl\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .\32xl\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .\32xl\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .\32xl\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .\32xl\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-lg ol ol,
  .\32xl\:prose-lg ol ul,
  .\32xl\:prose-lg ul ol,
  .\32xl\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .\32xl\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .\32xl\:prose-lg h2 + *,
  .\32xl\:prose-lg h3 + *,
  .\32xl\:prose-lg h4 + *,
  .\32xl\:prose-lg hr + * {
    margin-top: 0;
  }
  .\32xl\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .\32xl\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .\32xl\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-lg tbody td {
    padding: 0.75em;
  }
  .\32xl\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-lg > :first-child {
    margin-top: 0;
  }
  .\32xl\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .\32xl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .\32xl\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .\32xl\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .\32xl\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .\32xl\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .\32xl\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .\32xl\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .\32xl\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .\32xl\:prose-xl figure,
  .\32xl\:prose-xl img,
  .\32xl\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .\32xl\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .\32xl\:prose-xl code {
    font-size: 0.9em;
  }
  .\32xl\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .\32xl\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .\32xl\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .\32xl\:prose-xl ol,
  .\32xl\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .\32xl\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .\32xl\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .\32xl\:prose-xl ol > li:before {
    left: 0;
  }
  .\32xl\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .\32xl\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .\32xl\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .\32xl\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .\32xl\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .\32xl\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .\32xl\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .\32xl\:prose-xl ol ol,
  .\32xl\:prose-xl ol ul,
  .\32xl\:prose-xl ul ol,
  .\32xl\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .\32xl\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .\32xl\:prose-xl h2 + *,
  .\32xl\:prose-xl h3 + *,
  .\32xl\:prose-xl h4 + *,
  .\32xl\:prose-xl hr + * {
    margin-top: 0;
  }
  .\32xl\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .\32xl\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .\32xl\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .\32xl\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-xl > :first-child {
    margin-top: 0;
  }
  .\32xl\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .\32xl\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .\32xl\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .\32xl\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .\32xl\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .\32xl\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .\32xl\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .\32xl\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .\32xl\:prose-2xl figure,
  .\32xl\:prose-2xl img,
  .\32xl\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .\32xl\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .\32xl\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .\32xl\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .\32xl\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .\32xl\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .\32xl\:prose-2xl ol,
  .\32xl\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .\32xl\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .\32xl\:prose-2xl ol > li:before {
    left: 0;
  }
  .\32xl\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .\32xl\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .\32xl\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .\32xl\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .\32xl\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .\32xl\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .\32xl\:prose-2xl ol ol,
  .\32xl\:prose-2xl ol ul,
  .\32xl\:prose-2xl ul ol,
  .\32xl\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .\32xl\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .\32xl\:prose-2xl h2 + *,
  .\32xl\:prose-2xl h3 + *,
  .\32xl\:prose-2xl h4 + *,
  .\32xl\:prose-2xl hr + * {
    margin-top: 0;
  }
  .\32xl\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .\32xl\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .\32xl\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .\32xl\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .\32xl\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .\32xl\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .\32xl\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .\32xl\:aspect-w-1,
  .\32xl\:aspect-w-2,
  .\32xl\:aspect-w-3,
  .\32xl\:aspect-w-4,
  .\32xl\:aspect-w-5,
  .\32xl\:aspect-w-6,
  .\32xl\:aspect-w-7,
  .\32xl\:aspect-w-8,
  .\32xl\:aspect-w-9,
  .\32xl\:aspect-w-10,
  .\32xl\:aspect-w-11,
  .\32xl\:aspect-w-12,
  .\32xl\:aspect-w-13,
  .\32xl\:aspect-w-14,
  .\32xl\:aspect-w-15,
  .\32xl\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .\32xl\:aspect-w-1 > *,
  .\32xl\:aspect-w-2 > *,
  .\32xl\:aspect-w-3 > *,
  .\32xl\:aspect-w-4 > *,
  .\32xl\:aspect-w-5 > *,
  .\32xl\:aspect-w-6 > *,
  .\32xl\:aspect-w-7 > *,
  .\32xl\:aspect-w-8 > *,
  .\32xl\:aspect-w-9 > *,
  .\32xl\:aspect-w-10 > *,
  .\32xl\:aspect-w-11 > *,
  .\32xl\:aspect-w-12 > *,
  .\32xl\:aspect-w-13 > *,
  .\32xl\:aspect-w-14 > *,
  .\32xl\:aspect-w-15 > *,
  .\32xl\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .\32xl\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .\32xl\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .\32xl\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .\32xl\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .\32xl\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .\32xl\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .\32xl\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .\32xl\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .\32xl\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .\32xl\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .\32xl\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .\32xl\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .\32xl\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .\32xl\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .\32xl\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .\32xl\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .\32xl\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .\32xl\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .\32xl\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .\32xl\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .\32xl\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .\32xl\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .\32xl\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .\32xl\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .\32xl\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .\32xl\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .\32xl\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .\32xl\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .\32xl\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .\32xl\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .\32xl\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .\32xl\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .\32xl\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .\32xl\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .\32xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .\32xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .\32xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .\32xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .\32xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .\32xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .\32xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .\32xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .\32xl\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .\32xl\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .\32xl\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .\32xl\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .\32xl\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .\32xl\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .\32xl\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .\32xl\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .\32xl\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .\32xl\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .\32xl\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .\32xl\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .\32xl\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .\32xl\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .\32xl\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .\32xl\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .\32xl\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .\32xl\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .\32xl\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .\32xl\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .\32xl\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .\32xl\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .\32xl\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .\32xl\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .\32xl\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .\32xl\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .\32xl\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .\32xl\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .\32xl\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .\32xl\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .\32xl\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .\32xl\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .\32xl\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .\32xl\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .\32xl\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .\32xl\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .\32xl\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .\32xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .\32xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .\32xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .\32xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .\32xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .\32xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .\32xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .\32xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .\32xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .\32xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .\32xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .\32xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .\32xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .\32xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .\32xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .\32xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .\32xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .\32xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .\32xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .\32xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .\32xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .\32xl\:bg-fixed {
    background-attachment: fixed;
  }
  .\32xl\:bg-local {
    background-attachment: local;
  }
  .\32xl\:bg-scroll {
    background-attachment: scroll;
  }
  .\32xl\:bg-clip-border {
    background-clip: border-box;
  }
  .\32xl\:bg-clip-padding {
    background-clip: padding-box;
  }
  .\32xl\:bg-clip-content {
    background-clip: content-box;
  }
  .\32xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .\32xl\:bg-transparent {
    background-color: transparent;
  }
  .\32xl\:bg-current {
    background-color: currentColor;
  }
  .\32xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .\32xl\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .\32xl\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .\32xl\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .\32xl\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .\32xl\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .\32xl\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .\32xl\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .\32xl\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .\32xl\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .\32xl\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .\32xl\:bg-body-background {
    background-color: var(--body-background);
  }
  .\32xl\:bg-background {
    background-color: var(--background);
  }
  .\32xl\:bg-input-background {
    background-color: var(--input-background);
  }
  .\32xl\:bg-body-text {
    background-color: var(--body-text);
  }
  .\32xl\:bg-header-text {
    background-color: var(--header-text);
  }
  .\32xl\:bg-primary {
    background-color: var(--primary);
  }
  .\32xl\:bg-secondary {
    background-color: var(--secondary);
  }
  .\32xl\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .\32xl\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .\32xl\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .\32xl\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .\32xl\:bg-input-text {
    background-color: var(--input-text);
  }
  .\32xl\:bg-success {
    background-color: var(--success);
  }
  .\32xl\:bg-error {
    background-color: var(--error);
  }
  .\32xl\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .\32xl\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .\32xl\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .\32xl\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .\32xl\:bg-background50 {
    background-color: var(--background50);
  }
  .\32xl\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .\32xl\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .\32xl\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .\32xl\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .\32xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .\32xl\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .\32xl\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .\32xl\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .\32xl\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .\32xl\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .\32xl\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .\32xl\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .\32xl\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .\32xl\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .\32xl\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .\32xl\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .\32xl\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .\32xl\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .\32xl\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .\32xl\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .\32xl\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .\32xl\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .\32xl\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .\32xl\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .\32xl\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .\32xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .\32xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .\32xl\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .\32xl\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .\32xl\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .\32xl\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .\32xl\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .\32xl\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .\32xl\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .\32xl\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .\32xl\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .\32xl\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .\32xl\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .\32xl\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .\32xl\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .\32xl\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .\32xl\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .\32xl\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .\32xl\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .\32xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .\32xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .\32xl\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .\32xl\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .\32xl\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .\32xl\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .\32xl\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .\32xl\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .\32xl\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .\32xl\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .\32xl\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .\32xl\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .\32xl\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .\32xl\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .\32xl\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .\32xl\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .\32xl\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .\32xl\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .\32xl\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .\32xl\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .\32xl\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .\32xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .\32xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .\32xl\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .\32xl\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .\32xl\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .\32xl\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .\32xl\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .\32xl\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .\32xl\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .\32xl\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .\32xl\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .\32xl\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .\32xl\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .\32xl\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .\32xl\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .\32xl\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .\32xl\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .\32xl\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .\32xl\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .\32xl\:bg-none {
    background-image: none;
  }
  .\32xl\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .\32xl\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .\32xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .\32xl\:from-background,
  .\32xl\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-background {
    --tw-gradient-from: var(--background);
  }
  .\32xl\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .\32xl\:from-body-text,
  .\32xl\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .\32xl\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .\32xl\:from-header-text,
  .\32xl\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .\32xl\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .\32xl\:from-secondary,
  .\32xl\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .\32xl\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .\32xl\:from-primary-hover,
  .\32xl\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .\32xl\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .\32xl\:from-input-text,
  .\32xl\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .\32xl\:from-success {
    --tw-gradient-from: var(--success);
  }
  .\32xl\:from-error,
  .\32xl\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-error {
    --tw-gradient-from: var(--error);
  }
  .\32xl\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .\32xl\:from-skeleton-loader-from,
  .\32xl\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .\32xl\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .\32xl\:to-current {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:to-black {
    --tw-gradient-to: #000;
  }
  .\32xl\:to-white {
    --tw-gradient-to: #fff;
  }
  .\32xl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .\32xl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .\32xl\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .\32xl\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .\32xl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .\32xl\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .\32xl\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .\32xl\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .\32xl\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .\32xl\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .\32xl\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .\32xl\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .\32xl\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .\32xl\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .\32xl\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .\32xl\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .\32xl\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .\32xl\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .\32xl\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .\32xl\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .\32xl\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .\32xl\:to-background {
    --tw-gradient-to: var(--background);
  }
  .\32xl\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .\32xl\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .\32xl\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .\32xl\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .\32xl\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .\32xl\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .\32xl\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .\32xl\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .\32xl\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .\32xl\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .\32xl\:to-success {
    --tw-gradient-to: var(--success);
  }
  .\32xl\:to-error {
    --tw-gradient-to: var(--error);
  }
  .\32xl\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .\32xl\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .\32xl\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .\32xl\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .\32xl\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .\32xl\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .\32xl\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .\32xl\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .\32xl\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .\32xl\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .\32xl\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .\32xl\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .\32xl\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .\32xl\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .\32xl\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .\32xl\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .\32xl\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .\32xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .\32xl\:hover\:from-background:hover,
  .\32xl\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .\32xl\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .\32xl\:hover\:from-body-text:hover,
  .\32xl\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .\32xl\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .\32xl\:hover\:from-header-text:hover,
  .\32xl\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .\32xl\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .\32xl\:hover\:from-secondary:hover,
  .\32xl\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .\32xl\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .\32xl\:hover\:from-primary-hover:hover,
  .\32xl\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .\32xl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .\32xl\:hover\:from-input-text:hover,
  .\32xl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .\32xl\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .\32xl\:hover\:from-error:hover,
  .\32xl\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .\32xl\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .\32xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .\32xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .\32xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .\32xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .\32xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .\32xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .\32xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .\32xl\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .\32xl\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .\32xl\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .\32xl\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .\32xl\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .\32xl\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .\32xl\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .\32xl\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .\32xl\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .\32xl\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .\32xl\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .\32xl\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .\32xl\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .\32xl\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .\32xl\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .\32xl\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .\32xl\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .\32xl\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .\32xl\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .\32xl\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .\32xl\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .\32xl\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .\32xl\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .\32xl\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .\32xl\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .\32xl\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .\32xl\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .\32xl\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .\32xl\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .\32xl\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .\32xl\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .\32xl\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .\32xl\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .\32xl\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .\32xl\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .\32xl\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .\32xl\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .\32xl\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .\32xl\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .\32xl\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .\32xl\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .\32xl\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .\32xl\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .\32xl\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .\32xl\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .\32xl\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .\32xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .\32xl\:focus\:from-background:focus,
  .\32xl\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .\32xl\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .\32xl\:focus\:from-body-text:focus,
  .\32xl\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .\32xl\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .\32xl\:focus\:from-header-text:focus,
  .\32xl\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .\32xl\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .\32xl\:focus\:from-secondary:focus,
  .\32xl\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .\32xl\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .\32xl\:focus\:from-primary-hover:focus,
  .\32xl\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .\32xl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .\32xl\:focus\:from-input-text:focus,
  .\32xl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .\32xl\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .\32xl\:focus\:from-error:focus,
  .\32xl\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .\32xl\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .\32xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .\32xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .\32xl\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .\32xl\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .\32xl\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .\32xl\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .\32xl\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .\32xl\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .\32xl\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .\32xl\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .\32xl\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .\32xl\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .\32xl\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .\32xl\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .\32xl\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .\32xl\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .\32xl\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .\32xl\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .\32xl\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .\32xl\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .\32xl\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .\32xl\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .\32xl\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .\32xl\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .\32xl\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .\32xl\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .\32xl\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .\32xl\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .\32xl\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .\32xl\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .\32xl\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .\32xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .\32xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .\32xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .\32xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .\32xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .\32xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .\32xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .\32xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .\32xl\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .\32xl\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .\32xl\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .\32xl\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .\32xl\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .\32xl\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .\32xl\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .\32xl\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .\32xl\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .\32xl\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .\32xl\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .\32xl\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .\32xl\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .\32xl\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .\32xl\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .\32xl\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .\32xl\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .\32xl\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .\32xl\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .\32xl\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .\32xl\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .\32xl\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .\32xl\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .\32xl\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .\32xl\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .\32xl\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .\32xl\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .\32xl\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .\32xl\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .\32xl\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .\32xl\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .\32xl\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .\32xl\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .\32xl\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .\32xl\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .\32xl\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .\32xl\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .\32xl\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .\32xl\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .\32xl\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .\32xl\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .\32xl\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .\32xl\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .\32xl\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .\32xl\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .\32xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .\32xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .\32xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .\32xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .\32xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .\32xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .\32xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .\32xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .\32xl\:bg-bottom {
    background-position: bottom;
  }
  .\32xl\:bg-center {
    background-position: 50%;
  }
  .\32xl\:bg-left {
    background-position: 0;
  }
  .\32xl\:bg-left-bottom {
    background-position: 0 100%;
  }
  .\32xl\:bg-left-top {
    background-position: 0 0;
  }
  .\32xl\:bg-right {
    background-position: 100%;
  }
  .\32xl\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .\32xl\:bg-right-top {
    background-position: 100% 0;
  }
  .\32xl\:bg-top {
    background-position: top;
  }
  .\32xl\:bg-repeat {
    background-repeat: repeat;
  }
  .\32xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .\32xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .\32xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .\32xl\:bg-repeat-round {
    background-repeat: round;
  }
  .\32xl\:bg-repeat-space {
    background-repeat: space;
  }
  .\32xl\:bg-auto {
    background-size: auto;
  }
  .\32xl\:bg-cover {
    background-size: cover;
  }
  .\32xl\:bg-contain {
    background-size: contain;
  }
  .\32xl\:border-collapse {
    border-collapse: collapse;
  }
  .\32xl\:border-separate {
    border-collapse: separate;
  }
  .\32xl\:border-transparent {
    border-color: transparent;
  }
  .\32xl\:border-current {
    border-color: currentColor;
  }
  .\32xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .\32xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:border-background-primary {
    border-color: var(--background-primary);
  }
  .\32xl\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .\32xl\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .\32xl\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .\32xl\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .\32xl\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .\32xl\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .\32xl\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .\32xl\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .\32xl\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .\32xl\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .\32xl\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .\32xl\:border-body-background {
    border-color: var(--body-background);
  }
  .\32xl\:border-background {
    border-color: var(--background);
  }
  .\32xl\:border-input-background {
    border-color: var(--input-background);
  }
  .\32xl\:border-body-text {
    border-color: var(--body-text);
  }
  .\32xl\:border-header-text {
    border-color: var(--header-text);
  }
  .\32xl\:border-primary {
    border-color: var(--primary);
  }
  .\32xl\:border-secondary {
    border-color: var(--secondary);
  }
  .\32xl\:border-tertiary {
    border-color: var(--tertiary);
  }
  .\32xl\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .\32xl\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .\32xl\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .\32xl\:border-input-text {
    border-color: var(--input-text);
  }
  .\32xl\:border-success {
    border-color: var(--success);
  }
  .\32xl\:border-error {
    border-color: var(--error);
  }
  .\32xl\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .\32xl\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .\32xl\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .\32xl\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .\32xl\:border-background50 {
    border-color: var(--background50);
  }
  .\32xl\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .\32xl\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .\32xl\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .\32xl\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .\32xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .\32xl\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .\32xl\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .\32xl\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .\32xl\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .\32xl\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .\32xl\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .\32xl\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .\32xl\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .\32xl\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .\32xl\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .\32xl\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .\32xl\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .\32xl\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .\32xl\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .\32xl\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .\32xl\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .\32xl\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .\32xl\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .\32xl\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .\32xl\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .\32xl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .\32xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .\32xl\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .\32xl\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .\32xl\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .\32xl\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .\32xl\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .\32xl\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .\32xl\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .\32xl\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .\32xl\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .\32xl\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .\32xl\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .\32xl\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .\32xl\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .\32xl\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .\32xl\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .\32xl\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .\32xl\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .\32xl\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .\32xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .\32xl\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .\32xl\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .\32xl\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .\32xl\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .\32xl\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .\32xl\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .\32xl\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .\32xl\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .\32xl\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .\32xl\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .\32xl\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .\32xl\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .\32xl\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .\32xl\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .\32xl\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .\32xl\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .\32xl\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .\32xl\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .\32xl\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .\32xl\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .\32xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .\32xl\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .\32xl\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .\32xl\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .\32xl\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .\32xl\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .\32xl\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .\32xl\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .\32xl\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .\32xl\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .\32xl\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .\32xl\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .\32xl\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .\32xl\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .\32xl\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .\32xl\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .\32xl\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .\32xl\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .\32xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .\32xl\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .\32xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .\32xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .\32xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .\32xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .\32xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .\32xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .\32xl\:rounded-none {
    border-radius: 0;
  }
  .\32xl\:rounded-sm {
    border-radius: 0.125rem;
  }
  .\32xl\:rounded {
    border-radius: 0.25rem;
  }
  .\32xl\:rounded-md {
    border-radius: 0.375rem;
  }
  .\32xl\:rounded-lg {
    border-radius: 0.5rem;
  }
  .\32xl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .\32xl\:rounded-2xl {
    border-radius: 1rem;
  }
  .\32xl\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .\32xl\:rounded-full {
    border-radius: 9999px;
  }
  .\32xl\:rounded-card {
    border-radius: 20px;
  }
  .\32xl\:rounded-time-item {
    border-radius: 100px;
  }
  .\32xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .\32xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .\32xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .\32xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .\32xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .\32xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .\32xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .\32xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .\32xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .\32xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .\32xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .\32xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .\32xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .\32xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .\32xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .\32xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .\32xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .\32xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .\32xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .\32xl\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .\32xl\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .\32xl\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .\32xl\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .\32xl\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .\32xl\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .\32xl\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .\32xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .\32xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .\32xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .\32xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .\32xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .\32xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .\32xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .\32xl\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .\32xl\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .\32xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .\32xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .\32xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .\32xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .\32xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .\32xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .\32xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .\32xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .\32xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .\32xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .\32xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .\32xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .\32xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .\32xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .\32xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .\32xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .\32xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .\32xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .\32xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .\32xl\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .\32xl\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .\32xl\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .\32xl\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .\32xl\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .\32xl\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .\32xl\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .\32xl\:border-solid {
    border-style: solid;
  }
  .\32xl\:border-dashed {
    border-style: dashed;
  }
  .\32xl\:border-dotted {
    border-style: dotted;
  }
  .\32xl\:border-double {
    border-style: double;
  }
  .\32xl\:border-none {
    border-style: none;
  }
  .\32xl\:border-0 {
    border-width: 0;
  }
  .\32xl\:border-2 {
    border-width: 2px;
  }
  .\32xl\:border-4 {
    border-width: 4px;
  }
  .\32xl\:border-8 {
    border-width: 8px;
  }
  .\32xl\:border {
    border-width: 1px;
  }
  .\32xl\:border-t-0 {
    border-top-width: 0;
  }
  .\32xl\:border-r-0 {
    border-right-width: 0;
  }
  .\32xl\:border-b-0 {
    border-bottom-width: 0;
  }
  .\32xl\:border-l-0 {
    border-left-width: 0;
  }
  .\32xl\:border-t-2 {
    border-top-width: 2px;
  }
  .\32xl\:border-r-2 {
    border-right-width: 2px;
  }
  .\32xl\:border-b-2 {
    border-bottom-width: 2px;
  }
  .\32xl\:border-l-2 {
    border-left-width: 2px;
  }
  .\32xl\:border-t-4 {
    border-top-width: 4px;
  }
  .\32xl\:border-r-4 {
    border-right-width: 4px;
  }
  .\32xl\:border-b-4 {
    border-bottom-width: 4px;
  }
  .\32xl\:border-l-4 {
    border-left-width: 4px;
  }
  .\32xl\:border-t-8 {
    border-top-width: 8px;
  }
  .\32xl\:border-r-8 {
    border-right-width: 8px;
  }
  .\32xl\:border-b-8 {
    border-bottom-width: 8px;
  }
  .\32xl\:border-l-8 {
    border-left-width: 8px;
  }
  .\32xl\:border-t {
    border-top-width: 1px;
  }
  .\32xl\:border-r {
    border-right-width: 1px;
  }
  .\32xl\:border-b {
    border-bottom-width: 1px;
  }
  .\32xl\:border-l {
    border-left-width: 1px;
  }
  .\32xl\:box-border {
    box-sizing: border-box;
  }
  .\32xl\:box-content {
    box-sizing: content-box;
  }
  .\32xl\:cursor-auto {
    cursor: auto;
  }
  .\32xl\:cursor-default {
    cursor: default;
  }
  .\32xl\:cursor-pointer {
    cursor: pointer;
  }
  .\32xl\:cursor-wait {
    cursor: wait;
  }
  .\32xl\:cursor-text {
    cursor: text;
  }
  .\32xl\:cursor-move {
    cursor: move;
  }
  .\32xl\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .\32xl\:block {
    display: block;
  }
  .\32xl\:inline-block {
    display: inline-block;
  }
  .\32xl\:inline {
    display: inline;
  }
  .\32xl\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .\32xl\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .\32xl\:table {
    display: table;
  }
  .\32xl\:table-caption {
    display: table-caption;
  }
  .\32xl\:table-cell {
    display: table-cell;
  }
  .\32xl\:table-column {
    display: table-column;
  }
  .\32xl\:table-column-group {
    display: table-column-group;
  }
  .\32xl\:table-footer-group {
    display: table-footer-group;
  }
  .\32xl\:table-header-group {
    display: table-header-group;
  }
  .\32xl\:table-row-group {
    display: table-row-group;
  }
  .\32xl\:table-row {
    display: table-row;
  }
  .\32xl\:flow-root {
    display: flow-root;
  }
  .\32xl\:grid {
    display: grid;
  }
  .\32xl\:inline-grid {
    display: inline-grid;
  }
  .\32xl\:contents {
    display: contents;
  }
  .\32xl\:hidden {
    display: none;
  }
  .\32xl\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .\32xl\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .\32xl\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .\32xl\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .\32xl\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .\32xl\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .\32xl\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .\32xl\:place-items-auto {
    place-items: auto;
  }
  .\32xl\:place-items-start {
    place-items: start;
  }
  .\32xl\:place-items-end {
    place-items: end;
  }
  .\32xl\:place-items-center {
    place-items: center;
  }
  .\32xl\:place-items-stretch {
    place-items: stretch;
  }
  .\32xl\:place-content-center {
    place-content: center;
  }
  .\32xl\:place-content-start {
    place-content: start;
  }
  .\32xl\:place-content-end {
    place-content: end;
  }
  .\32xl\:place-content-between {
    place-content: space-between;
  }
  .\32xl\:place-content-around {
    place-content: space-around;
  }
  .\32xl\:place-content-evenly {
    place-content: space-evenly;
  }
  .\32xl\:place-content-stretch {
    place-content: stretch;
  }
  .\32xl\:place-self-auto {
    place-self: auto;
  }
  .\32xl\:place-self-start {
    place-self: start;
  }
  .\32xl\:place-self-end {
    place-self: end;
  }
  .\32xl\:place-self-center {
    place-self: center;
  }
  .\32xl\:place-self-stretch {
    place-self: stretch;
  }
  .\32xl\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .\32xl\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .\32xl\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .\32xl\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .\32xl\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .\32xl\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .\32xl\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .\32xl\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .\32xl\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .\32xl\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .\32xl\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .\32xl\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .\32xl\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .\32xl\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .\32xl\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .\32xl\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .\32xl\:justify-items-auto {
    justify-items: auto;
  }
  .\32xl\:justify-items-start {
    justify-items: start;
  }
  .\32xl\:justify-items-end {
    justify-items: end;
  }
  .\32xl\:justify-items-center {
    justify-items: center;
  }
  .\32xl\:justify-items-stretch {
    justify-items: stretch;
  }
  .\32xl\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .\32xl\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .\32xl\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .\32xl\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .\32xl\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .\32xl\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .\32xl\:justify-self-auto {
    justify-self: auto;
  }
  .\32xl\:justify-self-start {
    justify-self: start;
  }
  .\32xl\:justify-self-end {
    justify-self: end;
  }
  .\32xl\:justify-self-center {
    justify-self: center;
  }
  .\32xl\:justify-self-stretch {
    justify-self: stretch;
  }
  .\32xl\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .\32xl\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .\32xl\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .\32xl\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .\32xl\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .\32xl\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .\32xl\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .\32xl\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .\32xl\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .\32xl\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .\32xl\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .\32xl\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .\32xl\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .\32xl\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .\32xl\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .\32xl\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .\32xl\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .\32xl\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .\32xl\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .\32xl\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .\32xl\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .\32xl\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .\32xl\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .\32xl\:float-right {
    float: right;
  }
  .\32xl\:float-left {
    float: left;
  }
  .\32xl\:float-none {
    float: none;
  }
  .\32xl\:clear-left {
    clear: left;
  }
  .\32xl\:clear-right {
    clear: right;
  }
  .\32xl\:clear-both {
    clear: both;
  }
  .\32xl\:clear-none {
    clear: none;
  }
  .\32xl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .\32xl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .\32xl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .\32xl\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .\32xl\:font-text {
    font-family: Bozon, sans-serif;
  }
  .\32xl\:font-thin {
    font-weight: 100;
  }
  .\32xl\:font-extralight {
    font-weight: 200;
  }
  .\32xl\:font-light {
    font-weight: 300;
  }
  .\32xl\:font-normal {
    font-weight: 400;
  }
  .\32xl\:font-medium {
    font-weight: 500;
  }
  .\32xl\:font-semibold {
    font-weight: 600;
  }
  .\32xl\:font-bold {
    font-weight: 700;
  }
  .\32xl\:font-extrabold {
    font-weight: 800;
  }
  .\32xl\:font-black {
    font-weight: 900;
  }
  .\32xl\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .\32xl\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .\32xl\:hover\:font-light:hover {
    font-weight: 300;
  }
  .\32xl\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .\32xl\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .\32xl\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .\32xl\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .\32xl\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .\32xl\:hover\:font-black:hover {
    font-weight: 900;
  }
  .\32xl\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .\32xl\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .\32xl\:focus\:font-light:focus {
    font-weight: 300;
  }
  .\32xl\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .\32xl\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .\32xl\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .\32xl\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .\32xl\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .\32xl\:focus\:font-black:focus {
    font-weight: 900;
  }
  .\32xl\:h-0 {
    height: 0;
  }
  .\32xl\:h-1 {
    height: 0.25rem;
  }
  .\32xl\:h-2 {
    height: 0.5rem;
  }
  .\32xl\:h-3 {
    height: 0.75rem;
  }
  .\32xl\:h-4 {
    height: 1rem;
  }
  .\32xl\:h-5 {
    height: 1.25rem;
  }
  .\32xl\:h-6 {
    height: 1.5rem;
  }
  .\32xl\:h-7 {
    height: 1.75rem;
  }
  .\32xl\:h-8 {
    height: 2rem;
  }
  .\32xl\:h-9 {
    height: 2.25rem;
  }
  .\32xl\:h-10 {
    height: 2.5rem;
  }
  .\32xl\:h-11 {
    height: 2.75rem;
  }
  .\32xl\:h-12 {
    height: 3rem;
  }
  .\32xl\:h-14 {
    height: 3.5rem;
  }
  .\32xl\:h-16 {
    height: 4rem;
  }
  .\32xl\:h-20 {
    height: 5rem;
  }
  .\32xl\:h-24 {
    height: 6rem;
  }
  .\32xl\:h-28 {
    height: 7rem;
  }
  .\32xl\:h-32 {
    height: 8rem;
  }
  .\32xl\:h-36 {
    height: 9rem;
  }
  .\32xl\:h-40 {
    height: 10rem;
  }
  .\32xl\:h-44 {
    height: 11rem;
  }
  .\32xl\:h-48 {
    height: 12rem;
  }
  .\32xl\:h-52 {
    height: 13rem;
  }
  .\32xl\:h-56 {
    height: 14rem;
  }
  .\32xl\:h-60 {
    height: 15rem;
  }
  .\32xl\:h-64 {
    height: 16rem;
  }
  .\32xl\:h-72 {
    height: 18rem;
  }
  .\32xl\:h-80 {
    height: 20rem;
  }
  .\32xl\:h-96 {
    height: 24rem;
  }
  .\32xl\:h-105 {
    height: 105px;
  }
  .\32xl\:h-188 {
    height: 188px;
  }
  .\32xl\:h-344 {
    height: 344px;
  }
  .\32xl\:h-425 {
    height: 425px;
  }
  .\32xl\:h-627 {
    height: 627px;
  }
  .\32xl\:h-auto {
    height: auto;
  }
  .\32xl\:h-px {
    height: 1px;
  }
  .\32xl\:h-0\.5 {
    height: 0.125rem;
  }
  .\32xl\:h-1\.5 {
    height: 0.375rem;
  }
  .\32xl\:h-2\.5 {
    height: 0.625rem;
  }
  .\32xl\:h-3\.5 {
    height: 0.875rem;
  }
  .\32xl\:h-1\/2 {
    height: 50%;
  }
  .\32xl\:h-1\/3 {
    height: 33.333333%;
  }
  .\32xl\:h-2\/3 {
    height: 66.666667%;
  }
  .\32xl\:h-1\/4 {
    height: 25%;
  }
  .\32xl\:h-2\/4 {
    height: 50%;
  }
  .\32xl\:h-3\/4 {
    height: 75%;
  }
  .\32xl\:h-1\/5 {
    height: 20%;
  }
  .\32xl\:h-2\/5 {
    height: 40%;
  }
  .\32xl\:h-3\/5 {
    height: 60%;
  }
  .\32xl\:h-4\/5 {
    height: 80%;
  }
  .\32xl\:h-1\/6 {
    height: 16.666667%;
  }
  .\32xl\:h-2\/6 {
    height: 33.333333%;
  }
  .\32xl\:h-3\/6 {
    height: 50%;
  }
  .\32xl\:h-4\/6 {
    height: 66.666667%;
  }
  .\32xl\:h-5\/6 {
    height: 83.333333%;
  }
  .\32xl\:h-full {
    height: 100%;
  }
  .\32xl\:h-screen {
    height: 100vh;
  }
  .\32xl\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .\32xl\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .\32xl\:text-base {
    font-size: 1em;
  }
  .\32xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .\32xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .\32xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .\32xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .\32xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .\32xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .\32xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .\32xl\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .\32xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .\32xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .\32xl\:text-m-sm {
    font-size: 0.8em;
  }
  .\32xl\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .\32xl\:text-m-xl {
    font-size: 1.2em;
  }
  .\32xl\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .\32xl\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .\32xl\:text-m-4xl {
    font-size: 1.6em;
  }
  .\32xl\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .\32xl\:text-d-sm {
    font-size: 0.875em;
  }
  .\32xl\:text-d-lg {
    font-size: 1.125em;
  }
  .\32xl\:text-d-xl {
    font-size: 1.375em;
  }
  .\32xl\:text-d-2xl {
    font-size: 1.625em;
  }
  .\32xl\:text-d-3xl {
    font-size: 1.75em;
  }
  .\32xl\:text-d-4xl {
    font-size: 2.25em;
  }
  .\32xl\:text-d-5xl {
    font-size: 2.625em;
  }
  .\32xl\:text-d-11xl {
    font-size: 16em;
  }
  .\32xl\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .\32xl\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .\32xl\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .\32xl\:leading-3 {
    line-height: 0.75rem;
  }
  .\32xl\:leading-4 {
    line-height: 1rem;
  }
  .\32xl\:leading-5 {
    line-height: 1.25rem;
  }
  .\32xl\:leading-6 {
    line-height: 1.5rem;
  }
  .\32xl\:leading-7 {
    line-height: 1.75rem;
  }
  .\32xl\:leading-8 {
    line-height: 2rem;
  }
  .\32xl\:leading-9 {
    line-height: 2.25rem;
  }
  .\32xl\:leading-10 {
    line-height: 2.5rem;
  }
  .\32xl\:leading-none {
    line-height: 1;
  }
  .\32xl\:leading-tight {
    line-height: 1.25;
  }
  .\32xl\:leading-snug {
    line-height: 1.375;
  }
  .\32xl\:leading-normal {
    line-height: 1.5;
  }
  .\32xl\:leading-relaxed {
    line-height: 1.625;
  }
  .\32xl\:leading-loose {
    line-height: 2;
  }
  .\32xl\:list-inside {
    list-style-position: inside;
  }
  .\32xl\:list-outside {
    list-style-position: outside;
  }
  .\32xl\:list-none {
    list-style-type: none;
  }
  .\32xl\:list-disc {
    list-style-type: disc;
  }
  .\32xl\:list-decimal {
    list-style-type: decimal;
  }
  .\32xl\:m-0 {
    margin: 0;
  }
  .\32xl\:m-1 {
    margin: 0.25rem;
  }
  .\32xl\:m-2 {
    margin: 0.5rem;
  }
  .\32xl\:m-3 {
    margin: 0.75rem;
  }
  .\32xl\:m-4 {
    margin: 1rem;
  }
  .\32xl\:m-5 {
    margin: 1.25rem;
  }
  .\32xl\:m-6 {
    margin: 1.5rem;
  }
  .\32xl\:m-7 {
    margin: 1.75rem;
  }
  .\32xl\:m-8 {
    margin: 2rem;
  }
  .\32xl\:m-9 {
    margin: 2.25rem;
  }
  .\32xl\:m-10 {
    margin: 2.5rem;
  }
  .\32xl\:m-11 {
    margin: 2.75rem;
  }
  .\32xl\:m-12 {
    margin: 3rem;
  }
  .\32xl\:m-14 {
    margin: 3.5rem;
  }
  .\32xl\:m-16 {
    margin: 4rem;
  }
  .\32xl\:m-20 {
    margin: 5rem;
  }
  .\32xl\:m-24 {
    margin: 6rem;
  }
  .\32xl\:m-28 {
    margin: 7rem;
  }
  .\32xl\:m-32 {
    margin: 8rem;
  }
  .\32xl\:m-36 {
    margin: 9rem;
  }
  .\32xl\:m-40 {
    margin: 10rem;
  }
  .\32xl\:m-44 {
    margin: 11rem;
  }
  .\32xl\:m-48 {
    margin: 12rem;
  }
  .\32xl\:m-52 {
    margin: 13rem;
  }
  .\32xl\:m-56 {
    margin: 14rem;
  }
  .\32xl\:m-60 {
    margin: 15rem;
  }
  .\32xl\:m-64 {
    margin: 16rem;
  }
  .\32xl\:m-72 {
    margin: 18rem;
  }
  .\32xl\:m-80 {
    margin: 20rem;
  }
  .\32xl\:m-96 {
    margin: 24rem;
  }
  .\32xl\:m-auto {
    margin: auto;
  }
  .\32xl\:m-px {
    margin: 1px;
  }
  .\32xl\:m-0\.5 {
    margin: 0.125rem;
  }
  .\32xl\:m-1\.5 {
    margin: 0.375rem;
  }
  .\32xl\:m-2\.5 {
    margin: 0.625rem;
  }
  .\32xl\:m-3\.5 {
    margin: 0.875rem;
  }
  .\32xl\:-m-0 {
    margin: 0;
  }
  .\32xl\:-m-1 {
    margin: -0.25rem;
  }
  .\32xl\:-m-2 {
    margin: -0.5rem;
  }
  .\32xl\:-m-3 {
    margin: -0.75rem;
  }
  .\32xl\:-m-4 {
    margin: -1rem;
  }
  .\32xl\:-m-5 {
    margin: -1.25rem;
  }
  .\32xl\:-m-6 {
    margin: -1.5rem;
  }
  .\32xl\:-m-7 {
    margin: -1.75rem;
  }
  .\32xl\:-m-8 {
    margin: -2rem;
  }
  .\32xl\:-m-9 {
    margin: -2.25rem;
  }
  .\32xl\:-m-10 {
    margin: -2.5rem;
  }
  .\32xl\:-m-11 {
    margin: -2.75rem;
  }
  .\32xl\:-m-12 {
    margin: -3rem;
  }
  .\32xl\:-m-14 {
    margin: -3.5rem;
  }
  .\32xl\:-m-16 {
    margin: -4rem;
  }
  .\32xl\:-m-20 {
    margin: -5rem;
  }
  .\32xl\:-m-24 {
    margin: -6rem;
  }
  .\32xl\:-m-28 {
    margin: -7rem;
  }
  .\32xl\:-m-32 {
    margin: -8rem;
  }
  .\32xl\:-m-36 {
    margin: -9rem;
  }
  .\32xl\:-m-40 {
    margin: -10rem;
  }
  .\32xl\:-m-44 {
    margin: -11rem;
  }
  .\32xl\:-m-48 {
    margin: -12rem;
  }
  .\32xl\:-m-52 {
    margin: -13rem;
  }
  .\32xl\:-m-56 {
    margin: -14rem;
  }
  .\32xl\:-m-60 {
    margin: -15rem;
  }
  .\32xl\:-m-64 {
    margin: -16rem;
  }
  .\32xl\:-m-72 {
    margin: -18rem;
  }
  .\32xl\:-m-80 {
    margin: -20rem;
  }
  .\32xl\:-m-96 {
    margin: -24rem;
  }
  .\32xl\:-m-px {
    margin: -1px;
  }
  .\32xl\:-m-0\.5 {
    margin: -0.125rem;
  }
  .\32xl\:-m-1\.5 {
    margin: -0.375rem;
  }
  .\32xl\:-m-2\.5 {
    margin: -0.625rem;
  }
  .\32xl\:-m-3\.5 {
    margin: -0.875rem;
  }
  .\32xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .\32xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .\32xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .\32xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .\32xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .\32xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .\32xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .\32xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .\32xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .\32xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .\32xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .\32xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .\32xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .\32xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .\32xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .\32xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .\32xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .\32xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .\32xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .\32xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .\32xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .\32xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .\32xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .\32xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .\32xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .\32xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .\32xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .\32xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .\32xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .\32xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .\32xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .\32xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .\32xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .\32xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .\32xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .\32xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .\32xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .\32xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .\32xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .\32xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .\32xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .\32xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .\32xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .\32xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .\32xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .\32xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .\32xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .\32xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .\32xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .\32xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .\32xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .\32xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .\32xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .\32xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .\32xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .\32xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .\32xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .\32xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .\32xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .\32xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .\32xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .\32xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .\32xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .\32xl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .\32xl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .\32xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .\32xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .\32xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .\32xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .\32xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .\32xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .\32xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .\32xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .\32xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .\32xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .\32xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .\32xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .\32xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .\32xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .\32xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .\32xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .\32xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .\32xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .\32xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .\32xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .\32xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .\32xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .\32xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .\32xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .\32xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .\32xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .\32xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .\32xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .\32xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .\32xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .\32xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .\32xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .\32xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .\32xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .\32xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .\32xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .\32xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .\32xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .\32xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .\32xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .\32xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .\32xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .\32xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .\32xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .\32xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .\32xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .\32xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .\32xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .\32xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .\32xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .\32xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .\32xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .\32xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .\32xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .\32xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .\32xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .\32xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .\32xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .\32xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .\32xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .\32xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .\32xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .\32xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .\32xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .\32xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .\32xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .\32xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .\32xl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .\32xl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .\32xl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .\32xl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .\32xl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .\32xl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .\32xl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .\32xl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .\32xl\:mt-0 {
    margin-top: 0;
  }
  .\32xl\:mr-0 {
    margin-right: 0;
  }
  .\32xl\:mb-0 {
    margin-bottom: 0;
  }
  .\32xl\:ml-0 {
    margin-left: 0;
  }
  .\32xl\:mt-1 {
    margin-top: 0.25rem;
  }
  .\32xl\:mr-1 {
    margin-right: 0.25rem;
  }
  .\32xl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .\32xl\:ml-1 {
    margin-left: 0.25rem;
  }
  .\32xl\:mt-2 {
    margin-top: 0.5rem;
  }
  .\32xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .\32xl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .\32xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .\32xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .\32xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .\32xl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .\32xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .\32xl\:mt-4 {
    margin-top: 1rem;
  }
  .\32xl\:mr-4 {
    margin-right: 1rem;
  }
  .\32xl\:mb-4 {
    margin-bottom: 1rem;
  }
  .\32xl\:ml-4 {
    margin-left: 1rem;
  }
  .\32xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .\32xl\:mr-5 {
    margin-right: 1.25rem;
  }
  .\32xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .\32xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .\32xl\:mt-6 {
    margin-top: 1.5rem;
  }
  .\32xl\:mr-6 {
    margin-right: 1.5rem;
  }
  .\32xl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .\32xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .\32xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .\32xl\:mr-7 {
    margin-right: 1.75rem;
  }
  .\32xl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .\32xl\:ml-7 {
    margin-left: 1.75rem;
  }
  .\32xl\:mt-8 {
    margin-top: 2rem;
  }
  .\32xl\:mr-8 {
    margin-right: 2rem;
  }
  .\32xl\:mb-8 {
    margin-bottom: 2rem;
  }
  .\32xl\:ml-8 {
    margin-left: 2rem;
  }
  .\32xl\:mt-9 {
    margin-top: 2.25rem;
  }
  .\32xl\:mr-9 {
    margin-right: 2.25rem;
  }
  .\32xl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .\32xl\:ml-9 {
    margin-left: 2.25rem;
  }
  .\32xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .\32xl\:mr-10 {
    margin-right: 2.5rem;
  }
  .\32xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .\32xl\:ml-10 {
    margin-left: 2.5rem;
  }
  .\32xl\:mt-11 {
    margin-top: 2.75rem;
  }
  .\32xl\:mr-11 {
    margin-right: 2.75rem;
  }
  .\32xl\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .\32xl\:ml-11 {
    margin-left: 2.75rem;
  }
  .\32xl\:mt-12 {
    margin-top: 3rem;
  }
  .\32xl\:mr-12 {
    margin-right: 3rem;
  }
  .\32xl\:mb-12 {
    margin-bottom: 3rem;
  }
  .\32xl\:ml-12 {
    margin-left: 3rem;
  }
  .\32xl\:mt-14 {
    margin-top: 3.5rem;
  }
  .\32xl\:mr-14 {
    margin-right: 3.5rem;
  }
  .\32xl\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .\32xl\:ml-14 {
    margin-left: 3.5rem;
  }
  .\32xl\:mt-16 {
    margin-top: 4rem;
  }
  .\32xl\:mr-16 {
    margin-right: 4rem;
  }
  .\32xl\:mb-16 {
    margin-bottom: 4rem;
  }
  .\32xl\:ml-16 {
    margin-left: 4rem;
  }
  .\32xl\:mt-20 {
    margin-top: 5rem;
  }
  .\32xl\:mr-20 {
    margin-right: 5rem;
  }
  .\32xl\:mb-20 {
    margin-bottom: 5rem;
  }
  .\32xl\:ml-20 {
    margin-left: 5rem;
  }
  .\32xl\:mt-24 {
    margin-top: 6rem;
  }
  .\32xl\:mr-24 {
    margin-right: 6rem;
  }
  .\32xl\:mb-24 {
    margin-bottom: 6rem;
  }
  .\32xl\:ml-24 {
    margin-left: 6rem;
  }
  .\32xl\:mt-28 {
    margin-top: 7rem;
  }
  .\32xl\:mr-28 {
    margin-right: 7rem;
  }
  .\32xl\:mb-28 {
    margin-bottom: 7rem;
  }
  .\32xl\:ml-28 {
    margin-left: 7rem;
  }
  .\32xl\:mt-32 {
    margin-top: 8rem;
  }
  .\32xl\:mr-32 {
    margin-right: 8rem;
  }
  .\32xl\:mb-32 {
    margin-bottom: 8rem;
  }
  .\32xl\:ml-32 {
    margin-left: 8rem;
  }
  .\32xl\:mt-36 {
    margin-top: 9rem;
  }
  .\32xl\:mr-36 {
    margin-right: 9rem;
  }
  .\32xl\:mb-36 {
    margin-bottom: 9rem;
  }
  .\32xl\:ml-36 {
    margin-left: 9rem;
  }
  .\32xl\:mt-40 {
    margin-top: 10rem;
  }
  .\32xl\:mr-40 {
    margin-right: 10rem;
  }
  .\32xl\:mb-40 {
    margin-bottom: 10rem;
  }
  .\32xl\:ml-40 {
    margin-left: 10rem;
  }
  .\32xl\:mt-44 {
    margin-top: 11rem;
  }
  .\32xl\:mr-44 {
    margin-right: 11rem;
  }
  .\32xl\:mb-44 {
    margin-bottom: 11rem;
  }
  .\32xl\:ml-44 {
    margin-left: 11rem;
  }
  .\32xl\:mt-48 {
    margin-top: 12rem;
  }
  .\32xl\:mr-48 {
    margin-right: 12rem;
  }
  .\32xl\:mb-48 {
    margin-bottom: 12rem;
  }
  .\32xl\:ml-48 {
    margin-left: 12rem;
  }
  .\32xl\:mt-52 {
    margin-top: 13rem;
  }
  .\32xl\:mr-52 {
    margin-right: 13rem;
  }
  .\32xl\:mb-52 {
    margin-bottom: 13rem;
  }
  .\32xl\:ml-52 {
    margin-left: 13rem;
  }
  .\32xl\:mt-56 {
    margin-top: 14rem;
  }
  .\32xl\:mr-56 {
    margin-right: 14rem;
  }
  .\32xl\:mb-56 {
    margin-bottom: 14rem;
  }
  .\32xl\:ml-56 {
    margin-left: 14rem;
  }
  .\32xl\:mt-60 {
    margin-top: 15rem;
  }
  .\32xl\:mr-60 {
    margin-right: 15rem;
  }
  .\32xl\:mb-60 {
    margin-bottom: 15rem;
  }
  .\32xl\:ml-60 {
    margin-left: 15rem;
  }
  .\32xl\:mt-64 {
    margin-top: 16rem;
  }
  .\32xl\:mr-64 {
    margin-right: 16rem;
  }
  .\32xl\:mb-64 {
    margin-bottom: 16rem;
  }
  .\32xl\:ml-64 {
    margin-left: 16rem;
  }
  .\32xl\:mt-72 {
    margin-top: 18rem;
  }
  .\32xl\:mr-72 {
    margin-right: 18rem;
  }
  .\32xl\:mb-72 {
    margin-bottom: 18rem;
  }
  .\32xl\:ml-72 {
    margin-left: 18rem;
  }
  .\32xl\:mt-80 {
    margin-top: 20rem;
  }
  .\32xl\:mr-80 {
    margin-right: 20rem;
  }
  .\32xl\:mb-80 {
    margin-bottom: 20rem;
  }
  .\32xl\:ml-80 {
    margin-left: 20rem;
  }
  .\32xl\:mt-96 {
    margin-top: 24rem;
  }
  .\32xl\:mr-96 {
    margin-right: 24rem;
  }
  .\32xl\:mb-96 {
    margin-bottom: 24rem;
  }
  .\32xl\:ml-96 {
    margin-left: 24rem;
  }
  .\32xl\:mt-auto {
    margin-top: auto;
  }
  .\32xl\:mr-auto {
    margin-right: auto;
  }
  .\32xl\:mb-auto {
    margin-bottom: auto;
  }
  .\32xl\:ml-auto {
    margin-left: auto;
  }
  .\32xl\:mt-px {
    margin-top: 1px;
  }
  .\32xl\:mr-px {
    margin-right: 1px;
  }
  .\32xl\:mb-px {
    margin-bottom: 1px;
  }
  .\32xl\:ml-px {
    margin-left: 1px;
  }
  .\32xl\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .\32xl\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .\32xl\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .\32xl\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .\32xl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .\32xl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .\32xl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .\32xl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .\32xl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .\32xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .\32xl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .\32xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .\32xl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .\32xl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .\32xl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .\32xl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .\32xl\:-mt-0 {
    margin-top: 0;
  }
  .\32xl\:-mr-0 {
    margin-right: 0;
  }
  .\32xl\:-mb-0 {
    margin-bottom: 0;
  }
  .\32xl\:-ml-0 {
    margin-left: 0;
  }
  .\32xl\:-mt-1 {
    margin-top: -0.25rem;
  }
  .\32xl\:-mr-1 {
    margin-right: -0.25rem;
  }
  .\32xl\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .\32xl\:-ml-1 {
    margin-left: -0.25rem;
  }
  .\32xl\:-mt-2 {
    margin-top: -0.5rem;
  }
  .\32xl\:-mr-2 {
    margin-right: -0.5rem;
  }
  .\32xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .\32xl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .\32xl\:-mt-3 {
    margin-top: -0.75rem;
  }
  .\32xl\:-mr-3 {
    margin-right: -0.75rem;
  }
  .\32xl\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .\32xl\:-ml-3 {
    margin-left: -0.75rem;
  }
  .\32xl\:-mt-4 {
    margin-top: -1rem;
  }
  .\32xl\:-mr-4 {
    margin-right: -1rem;
  }
  .\32xl\:-mb-4 {
    margin-bottom: -1rem;
  }
  .\32xl\:-ml-4 {
    margin-left: -1rem;
  }
  .\32xl\:-mt-5 {
    margin-top: -1.25rem;
  }
  .\32xl\:-mr-5 {
    margin-right: -1.25rem;
  }
  .\32xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .\32xl\:-ml-5 {
    margin-left: -1.25rem;
  }
  .\32xl\:-mt-6 {
    margin-top: -1.5rem;
  }
  .\32xl\:-mr-6 {
    margin-right: -1.5rem;
  }
  .\32xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .\32xl\:-ml-6 {
    margin-left: -1.5rem;
  }
  .\32xl\:-mt-7 {
    margin-top: -1.75rem;
  }
  .\32xl\:-mr-7 {
    margin-right: -1.75rem;
  }
  .\32xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .\32xl\:-ml-7 {
    margin-left: -1.75rem;
  }
  .\32xl\:-mt-8 {
    margin-top: -2rem;
  }
  .\32xl\:-mr-8 {
    margin-right: -2rem;
  }
  .\32xl\:-mb-8 {
    margin-bottom: -2rem;
  }
  .\32xl\:-ml-8 {
    margin-left: -2rem;
  }
  .\32xl\:-mt-9 {
    margin-top: -2.25rem;
  }
  .\32xl\:-mr-9 {
    margin-right: -2.25rem;
  }
  .\32xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .\32xl\:-ml-9 {
    margin-left: -2.25rem;
  }
  .\32xl\:-mt-10 {
    margin-top: -2.5rem;
  }
  .\32xl\:-mr-10 {
    margin-right: -2.5rem;
  }
  .\32xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .\32xl\:-ml-10 {
    margin-left: -2.5rem;
  }
  .\32xl\:-mt-11 {
    margin-top: -2.75rem;
  }
  .\32xl\:-mr-11 {
    margin-right: -2.75rem;
  }
  .\32xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .\32xl\:-ml-11 {
    margin-left: -2.75rem;
  }
  .\32xl\:-mt-12 {
    margin-top: -3rem;
  }
  .\32xl\:-mr-12 {
    margin-right: -3rem;
  }
  .\32xl\:-mb-12 {
    margin-bottom: -3rem;
  }
  .\32xl\:-ml-12 {
    margin-left: -3rem;
  }
  .\32xl\:-mt-14 {
    margin-top: -3.5rem;
  }
  .\32xl\:-mr-14 {
    margin-right: -3.5rem;
  }
  .\32xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .\32xl\:-ml-14 {
    margin-left: -3.5rem;
  }
  .\32xl\:-mt-16 {
    margin-top: -4rem;
  }
  .\32xl\:-mr-16 {
    margin-right: -4rem;
  }
  .\32xl\:-mb-16 {
    margin-bottom: -4rem;
  }
  .\32xl\:-ml-16 {
    margin-left: -4rem;
  }
  .\32xl\:-mt-20 {
    margin-top: -5rem;
  }
  .\32xl\:-mr-20 {
    margin-right: -5rem;
  }
  .\32xl\:-mb-20 {
    margin-bottom: -5rem;
  }
  .\32xl\:-ml-20 {
    margin-left: -5rem;
  }
  .\32xl\:-mt-24 {
    margin-top: -6rem;
  }
  .\32xl\:-mr-24 {
    margin-right: -6rem;
  }
  .\32xl\:-mb-24 {
    margin-bottom: -6rem;
  }
  .\32xl\:-ml-24 {
    margin-left: -6rem;
  }
  .\32xl\:-mt-28 {
    margin-top: -7rem;
  }
  .\32xl\:-mr-28 {
    margin-right: -7rem;
  }
  .\32xl\:-mb-28 {
    margin-bottom: -7rem;
  }
  .\32xl\:-ml-28 {
    margin-left: -7rem;
  }
  .\32xl\:-mt-32 {
    margin-top: -8rem;
  }
  .\32xl\:-mr-32 {
    margin-right: -8rem;
  }
  .\32xl\:-mb-32 {
    margin-bottom: -8rem;
  }
  .\32xl\:-ml-32 {
    margin-left: -8rem;
  }
  .\32xl\:-mt-36 {
    margin-top: -9rem;
  }
  .\32xl\:-mr-36 {
    margin-right: -9rem;
  }
  .\32xl\:-mb-36 {
    margin-bottom: -9rem;
  }
  .\32xl\:-ml-36 {
    margin-left: -9rem;
  }
  .\32xl\:-mt-40 {
    margin-top: -10rem;
  }
  .\32xl\:-mr-40 {
    margin-right: -10rem;
  }
  .\32xl\:-mb-40 {
    margin-bottom: -10rem;
  }
  .\32xl\:-ml-40 {
    margin-left: -10rem;
  }
  .\32xl\:-mt-44 {
    margin-top: -11rem;
  }
  .\32xl\:-mr-44 {
    margin-right: -11rem;
  }
  .\32xl\:-mb-44 {
    margin-bottom: -11rem;
  }
  .\32xl\:-ml-44 {
    margin-left: -11rem;
  }
  .\32xl\:-mt-48 {
    margin-top: -12rem;
  }
  .\32xl\:-mr-48 {
    margin-right: -12rem;
  }
  .\32xl\:-mb-48 {
    margin-bottom: -12rem;
  }
  .\32xl\:-ml-48 {
    margin-left: -12rem;
  }
  .\32xl\:-mt-52 {
    margin-top: -13rem;
  }
  .\32xl\:-mr-52 {
    margin-right: -13rem;
  }
  .\32xl\:-mb-52 {
    margin-bottom: -13rem;
  }
  .\32xl\:-ml-52 {
    margin-left: -13rem;
  }
  .\32xl\:-mt-56 {
    margin-top: -14rem;
  }
  .\32xl\:-mr-56 {
    margin-right: -14rem;
  }
  .\32xl\:-mb-56 {
    margin-bottom: -14rem;
  }
  .\32xl\:-ml-56 {
    margin-left: -14rem;
  }
  .\32xl\:-mt-60 {
    margin-top: -15rem;
  }
  .\32xl\:-mr-60 {
    margin-right: -15rem;
  }
  .\32xl\:-mb-60 {
    margin-bottom: -15rem;
  }
  .\32xl\:-ml-60 {
    margin-left: -15rem;
  }
  .\32xl\:-mt-64 {
    margin-top: -16rem;
  }
  .\32xl\:-mr-64 {
    margin-right: -16rem;
  }
  .\32xl\:-mb-64 {
    margin-bottom: -16rem;
  }
  .\32xl\:-ml-64 {
    margin-left: -16rem;
  }
  .\32xl\:-mt-72 {
    margin-top: -18rem;
  }
  .\32xl\:-mr-72 {
    margin-right: -18rem;
  }
  .\32xl\:-mb-72 {
    margin-bottom: -18rem;
  }
  .\32xl\:-ml-72 {
    margin-left: -18rem;
  }
  .\32xl\:-mt-80 {
    margin-top: -20rem;
  }
  .\32xl\:-mr-80 {
    margin-right: -20rem;
  }
  .\32xl\:-mb-80 {
    margin-bottom: -20rem;
  }
  .\32xl\:-ml-80 {
    margin-left: -20rem;
  }
  .\32xl\:-mt-96 {
    margin-top: -24rem;
  }
  .\32xl\:-mr-96 {
    margin-right: -24rem;
  }
  .\32xl\:-mb-96 {
    margin-bottom: -24rem;
  }
  .\32xl\:-ml-96 {
    margin-left: -24rem;
  }
  .\32xl\:-mt-px {
    margin-top: -1px;
  }
  .\32xl\:-mr-px {
    margin-right: -1px;
  }
  .\32xl\:-mb-px {
    margin-bottom: -1px;
  }
  .\32xl\:-ml-px {
    margin-left: -1px;
  }
  .\32xl\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .\32xl\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .\32xl\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .\32xl\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .\32xl\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .\32xl\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .\32xl\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .\32xl\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .\32xl\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .\32xl\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .\32xl\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .\32xl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .\32xl\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .\32xl\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .\32xl\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .\32xl\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .\32xl\:max-h-0 {
    max-height: 0;
  }
  .\32xl\:max-h-1 {
    max-height: 0.25rem;
  }
  .\32xl\:max-h-2 {
    max-height: 0.5rem;
  }
  .\32xl\:max-h-3 {
    max-height: 0.75rem;
  }
  .\32xl\:max-h-4 {
    max-height: 1rem;
  }
  .\32xl\:max-h-5 {
    max-height: 1.25rem;
  }
  .\32xl\:max-h-6 {
    max-height: 1.5rem;
  }
  .\32xl\:max-h-7 {
    max-height: 1.75rem;
  }
  .\32xl\:max-h-8 {
    max-height: 2rem;
  }
  .\32xl\:max-h-9 {
    max-height: 2.25rem;
  }
  .\32xl\:max-h-10 {
    max-height: 2.5rem;
  }
  .\32xl\:max-h-11 {
    max-height: 2.75rem;
  }
  .\32xl\:max-h-12 {
    max-height: 3rem;
  }
  .\32xl\:max-h-14 {
    max-height: 3.5rem;
  }
  .\32xl\:max-h-16 {
    max-height: 4rem;
  }
  .\32xl\:max-h-20 {
    max-height: 5rem;
  }
  .\32xl\:max-h-24 {
    max-height: 6rem;
  }
  .\32xl\:max-h-28 {
    max-height: 7rem;
  }
  .\32xl\:max-h-32 {
    max-height: 8rem;
  }
  .\32xl\:max-h-36 {
    max-height: 9rem;
  }
  .\32xl\:max-h-40 {
    max-height: 10rem;
  }
  .\32xl\:max-h-44 {
    max-height: 11rem;
  }
  .\32xl\:max-h-48 {
    max-height: 12rem;
  }
  .\32xl\:max-h-52 {
    max-height: 13rem;
  }
  .\32xl\:max-h-56 {
    max-height: 14rem;
  }
  .\32xl\:max-h-60 {
    max-height: 15rem;
  }
  .\32xl\:max-h-64 {
    max-height: 16rem;
  }
  .\32xl\:max-h-72 {
    max-height: 18rem;
  }
  .\32xl\:max-h-80 {
    max-height: 20rem;
  }
  .\32xl\:max-h-96 {
    max-height: 24rem;
  }
  .\32xl\:max-h-px {
    max-height: 1px;
  }
  .\32xl\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .\32xl\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .\32xl\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .\32xl\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .\32xl\:max-h-full {
    max-height: 100%;
  }
  .\32xl\:max-h-screen {
    max-height: 100vh;
  }
  .\32xl\:max-h-1\/4 {
    max-height: 25%;
  }
  .\32xl\:max-h-1\/2 {
    max-height: 50%;
  }
  .\32xl\:max-h-3\/4 {
    max-height: 75%;
  }
  .\32xl\:max-h-10\/12 {
    max-height: 80%;
  }
  .\32xl\:max-h-enormous {
    max-height: 4000px;
  }
  .\32xl\:max-w-0 {
    max-width: 0;
  }
  .\32xl\:max-w-110 {
    max-width: 110px;
  }
  .\32xl\:max-w-473 {
    max-width: 473px;
  }
  .\32xl\:max-w-none {
    max-width: none;
  }
  .\32xl\:max-w-xs {
    max-width: 20rem;
  }
  .\32xl\:max-w-sm {
    max-width: 24rem;
  }
  .\32xl\:max-w-md {
    max-width: 28rem;
  }
  .\32xl\:max-w-lg {
    max-width: 32rem;
  }
  .\32xl\:max-w-xl {
    max-width: 36rem;
  }
  .\32xl\:max-w-2xl {
    max-width: 42rem;
  }
  .\32xl\:max-w-3xl {
    max-width: 48rem;
  }
  .\32xl\:max-w-4xl {
    max-width: 56rem;
  }
  .\32xl\:max-w-5xl {
    max-width: 64rem;
  }
  .\32xl\:max-w-6xl {
    max-width: 72rem;
  }
  .\32xl\:max-w-7xl {
    max-width: 80rem;
  }
  .\32xl\:max-w-full {
    max-width: 100%;
  }
  .\32xl\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .\32xl\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .\32xl\:max-w-prose {
    max-width: 65ch;
  }
  .\32xl\:max-w-screen-sm {
    max-width: 640px;
  }
  .\32xl\:max-w-screen-md {
    max-width: 768px;
  }
  .\32xl\:max-w-screen-lg {
    max-width: 1024px;
  }
  .\32xl\:max-w-screen-xl {
    max-width: 1280px;
  }
  .\32xl\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .\32xl\:max-w-screen-xxs {
    max-width: 260px;
  }
  .\32xl\:max-w-screen-xs {
    max-width: 360px;
  }
  .\32xl\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .\32xl\:min-h-0 {
    min-height: 0;
  }
  .\32xl\:min-h-full {
    min-height: 100%;
  }
  .\32xl\:min-h-screen {
    min-height: 100vh;
  }
  .\32xl\:min-w-0 {
    min-width: 0;
  }
  .\32xl\:min-w-10 {
    min-width: 2.5rem;
  }
  .\32xl\:min-w-12 {
    min-width: 3rem;
  }
  .\32xl\:min-w-14 {
    min-width: 3.5rem;
  }
  .\32xl\:min-w-full {
    min-width: 100%;
  }
  .\32xl\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .\32xl\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .\32xl\:object-contain {
    object-fit: contain;
  }
  .\32xl\:object-cover {
    object-fit: cover;
  }
  .\32xl\:object-fill {
    object-fit: fill;
  }
  .\32xl\:object-none {
    object-fit: none;
  }
  .\32xl\:object-scale-down {
    object-fit: scale-down;
  }
  .\32xl\:object-bottom {
    object-position: bottom;
  }
  .\32xl\:object-center {
    object-position: center;
  }
  .\32xl\:object-left {
    object-position: left;
  }
  .\32xl\:object-left-bottom {
    object-position: left bottom;
  }
  .\32xl\:object-left-top {
    object-position: left top;
  }
  .\32xl\:object-right {
    object-position: right;
  }
  .\32xl\:object-right-bottom {
    object-position: right bottom;
  }
  .\32xl\:object-right-top {
    object-position: right top;
  }
  .\32xl\:object-top {
    object-position: top;
  }
  .\32xl\:opacity-0 {
    opacity: 0;
  }
  .\32xl\:opacity-5 {
    opacity: 0.05;
  }
  .\32xl\:opacity-10 {
    opacity: 0.1;
  }
  .\32xl\:opacity-20 {
    opacity: 0.2;
  }
  .\32xl\:opacity-25 {
    opacity: 0.25;
  }
  .\32xl\:opacity-30 {
    opacity: 0.3;
  }
  .\32xl\:opacity-40 {
    opacity: 0.4;
  }
  .\32xl\:opacity-50 {
    opacity: 0.5;
  }
  .\32xl\:opacity-60 {
    opacity: 0.6;
  }
  .\32xl\:opacity-70 {
    opacity: 0.7;
  }
  .\32xl\:opacity-75 {
    opacity: 0.75;
  }
  .\32xl\:opacity-80 {
    opacity: 0.8;
  }
  .\32xl\:opacity-90 {
    opacity: 0.9;
  }
  .\32xl\:opacity-95 {
    opacity: 0.95;
  }
  .\32xl\:opacity-100 {
    opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .\32xl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .\32xl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .\32xl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .\32xl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .\32xl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .\32xl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .\32xl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .\32xl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .\32xl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .\32xl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .\32xl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .\32xl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .\32xl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .\32xl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .\32xl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .\32xl\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .\32xl\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .\32xl\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .\32xl\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .\32xl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .\32xl\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .\32xl\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .\32xl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .\32xl\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .\32xl\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .\32xl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .\32xl\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .\32xl\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .\32xl\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .\32xl\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .\32xl\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .\32xl\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .\32xl\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .\32xl\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .\32xl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .\32xl\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .\32xl\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .\32xl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .\32xl\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .\32xl\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .\32xl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .\32xl\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .\32xl\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .\32xl\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .\32xl\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .\32xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .\32xl\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .\32xl\:overflow-auto {
    overflow: auto;
  }
  .\32xl\:overflow-hidden {
    overflow: hidden;
  }
  .\32xl\:overflow-visible {
    overflow: visible;
  }
  .\32xl\:overflow-scroll {
    overflow: scroll;
  }
  .\32xl\:overflow-x-auto {
    overflow-x: auto;
  }
  .\32xl\:overflow-y-auto {
    overflow-y: auto;
  }
  .\32xl\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .\32xl\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .\32xl\:overflow-x-visible {
    overflow-x: visible;
  }
  .\32xl\:overflow-y-visible {
    overflow-y: visible;
  }
  .\32xl\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .\32xl\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .\32xl\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .\32xl\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .\32xl\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .\32xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .\32xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .\32xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .\32xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .\32xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .\32xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .\32xl\:p-0 {
    padding: 0;
  }
  .\32xl\:p-1 {
    padding: 0.25rem;
  }
  .\32xl\:p-2 {
    padding: 0.5rem;
  }
  .\32xl\:p-3 {
    padding: 0.75rem;
  }
  .\32xl\:p-4 {
    padding: 1rem;
  }
  .\32xl\:p-5 {
    padding: 1.25rem;
  }
  .\32xl\:p-6 {
    padding: 1.5rem;
  }
  .\32xl\:p-7 {
    padding: 1.75rem;
  }
  .\32xl\:p-8 {
    padding: 2rem;
  }
  .\32xl\:p-9 {
    padding: 2.25rem;
  }
  .\32xl\:p-10 {
    padding: 2.5rem;
  }
  .\32xl\:p-11 {
    padding: 2.75rem;
  }
  .\32xl\:p-12 {
    padding: 3rem;
  }
  .\32xl\:p-14 {
    padding: 3.5rem;
  }
  .\32xl\:p-16 {
    padding: 4rem;
  }
  .\32xl\:p-20 {
    padding: 5rem;
  }
  .\32xl\:p-24 {
    padding: 6rem;
  }
  .\32xl\:p-28 {
    padding: 7rem;
  }
  .\32xl\:p-32 {
    padding: 8rem;
  }
  .\32xl\:p-36 {
    padding: 9rem;
  }
  .\32xl\:p-40 {
    padding: 10rem;
  }
  .\32xl\:p-44 {
    padding: 11rem;
  }
  .\32xl\:p-48 {
    padding: 12rem;
  }
  .\32xl\:p-52 {
    padding: 13rem;
  }
  .\32xl\:p-56 {
    padding: 14rem;
  }
  .\32xl\:p-60 {
    padding: 15rem;
  }
  .\32xl\:p-64 {
    padding: 16rem;
  }
  .\32xl\:p-72 {
    padding: 18rem;
  }
  .\32xl\:p-80 {
    padding: 20rem;
  }
  .\32xl\:p-96 {
    padding: 24rem;
  }
  .\32xl\:p-px {
    padding: 1px;
  }
  .\32xl\:p-0\.5 {
    padding: 0.125rem;
  }
  .\32xl\:p-1\.5 {
    padding: 0.375rem;
  }
  .\32xl\:p-2\.5 {
    padding: 0.625rem;
  }
  .\32xl\:p-3\.5 {
    padding: 0.875rem;
  }
  .\32xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .\32xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .\32xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .\32xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .\32xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .\32xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .\32xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .\32xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .\32xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .\32xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .\32xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .\32xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .\32xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .\32xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .\32xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .\32xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .\32xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .\32xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .\32xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .\32xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .\32xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .\32xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .\32xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .\32xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .\32xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .\32xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .\32xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .\32xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .\32xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .\32xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .\32xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .\32xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .\32xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .\32xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .\32xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .\32xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .\32xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .\32xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .\32xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .\32xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .\32xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .\32xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .\32xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .\32xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .\32xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .\32xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .\32xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .\32xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .\32xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .\32xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .\32xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .\32xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .\32xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .\32xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .\32xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .\32xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .\32xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .\32xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .\32xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .\32xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .\32xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .\32xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .\32xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .\32xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .\32xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .\32xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .\32xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .\32xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .\32xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .\32xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .\32xl\:pt-0 {
    padding-top: 0;
  }
  .\32xl\:pr-0 {
    padding-right: 0;
  }
  .\32xl\:pb-0 {
    padding-bottom: 0;
  }
  .\32xl\:pl-0 {
    padding-left: 0;
  }
  .\32xl\:pt-1 {
    padding-top: 0.25rem;
  }
  .\32xl\:pr-1 {
    padding-right: 0.25rem;
  }
  .\32xl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .\32xl\:pl-1 {
    padding-left: 0.25rem;
  }
  .\32xl\:pt-2 {
    padding-top: 0.5rem;
  }
  .\32xl\:pr-2 {
    padding-right: 0.5rem;
  }
  .\32xl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .\32xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .\32xl\:pt-3 {
    padding-top: 0.75rem;
  }
  .\32xl\:pr-3 {
    padding-right: 0.75rem;
  }
  .\32xl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .\32xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .\32xl\:pt-4 {
    padding-top: 1rem;
  }
  .\32xl\:pr-4 {
    padding-right: 1rem;
  }
  .\32xl\:pb-4 {
    padding-bottom: 1rem;
  }
  .\32xl\:pl-4 {
    padding-left: 1rem;
  }
  .\32xl\:pt-5 {
    padding-top: 1.25rem;
  }
  .\32xl\:pr-5 {
    padding-right: 1.25rem;
  }
  .\32xl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .\32xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .\32xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .\32xl\:pr-6 {
    padding-right: 1.5rem;
  }
  .\32xl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .\32xl\:pl-6 {
    padding-left: 1.5rem;
  }
  .\32xl\:pt-7 {
    padding-top: 1.75rem;
  }
  .\32xl\:pr-7 {
    padding-right: 1.75rem;
  }
  .\32xl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .\32xl\:pl-7 {
    padding-left: 1.75rem;
  }
  .\32xl\:pt-8 {
    padding-top: 2rem;
  }
  .\32xl\:pr-8 {
    padding-right: 2rem;
  }
  .\32xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .\32xl\:pl-8 {
    padding-left: 2rem;
  }
  .\32xl\:pt-9 {
    padding-top: 2.25rem;
  }
  .\32xl\:pr-9 {
    padding-right: 2.25rem;
  }
  .\32xl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .\32xl\:pl-9 {
    padding-left: 2.25rem;
  }
  .\32xl\:pt-10 {
    padding-top: 2.5rem;
  }
  .\32xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .\32xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .\32xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .\32xl\:pt-11 {
    padding-top: 2.75rem;
  }
  .\32xl\:pr-11 {
    padding-right: 2.75rem;
  }
  .\32xl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .\32xl\:pl-11 {
    padding-left: 2.75rem;
  }
  .\32xl\:pt-12 {
    padding-top: 3rem;
  }
  .\32xl\:pr-12 {
    padding-right: 3rem;
  }
  .\32xl\:pb-12 {
    padding-bottom: 3rem;
  }
  .\32xl\:pl-12 {
    padding-left: 3rem;
  }
  .\32xl\:pt-14 {
    padding-top: 3.5rem;
  }
  .\32xl\:pr-14 {
    padding-right: 3.5rem;
  }
  .\32xl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .\32xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .\32xl\:pt-16 {
    padding-top: 4rem;
  }
  .\32xl\:pr-16 {
    padding-right: 4rem;
  }
  .\32xl\:pb-16 {
    padding-bottom: 4rem;
  }
  .\32xl\:pl-16 {
    padding-left: 4rem;
  }
  .\32xl\:pt-20 {
    padding-top: 5rem;
  }
  .\32xl\:pr-20 {
    padding-right: 5rem;
  }
  .\32xl\:pb-20 {
    padding-bottom: 5rem;
  }
  .\32xl\:pl-20 {
    padding-left: 5rem;
  }
  .\32xl\:pt-24 {
    padding-top: 6rem;
  }
  .\32xl\:pr-24 {
    padding-right: 6rem;
  }
  .\32xl\:pb-24 {
    padding-bottom: 6rem;
  }
  .\32xl\:pl-24 {
    padding-left: 6rem;
  }
  .\32xl\:pt-28 {
    padding-top: 7rem;
  }
  .\32xl\:pr-28 {
    padding-right: 7rem;
  }
  .\32xl\:pb-28 {
    padding-bottom: 7rem;
  }
  .\32xl\:pl-28 {
    padding-left: 7rem;
  }
  .\32xl\:pt-32 {
    padding-top: 8rem;
  }
  .\32xl\:pr-32 {
    padding-right: 8rem;
  }
  .\32xl\:pb-32 {
    padding-bottom: 8rem;
  }
  .\32xl\:pl-32 {
    padding-left: 8rem;
  }
  .\32xl\:pt-36 {
    padding-top: 9rem;
  }
  .\32xl\:pr-36 {
    padding-right: 9rem;
  }
  .\32xl\:pb-36 {
    padding-bottom: 9rem;
  }
  .\32xl\:pl-36 {
    padding-left: 9rem;
  }
  .\32xl\:pt-40 {
    padding-top: 10rem;
  }
  .\32xl\:pr-40 {
    padding-right: 10rem;
  }
  .\32xl\:pb-40 {
    padding-bottom: 10rem;
  }
  .\32xl\:pl-40 {
    padding-left: 10rem;
  }
  .\32xl\:pt-44 {
    padding-top: 11rem;
  }
  .\32xl\:pr-44 {
    padding-right: 11rem;
  }
  .\32xl\:pb-44 {
    padding-bottom: 11rem;
  }
  .\32xl\:pl-44 {
    padding-left: 11rem;
  }
  .\32xl\:pt-48 {
    padding-top: 12rem;
  }
  .\32xl\:pr-48 {
    padding-right: 12rem;
  }
  .\32xl\:pb-48 {
    padding-bottom: 12rem;
  }
  .\32xl\:pl-48 {
    padding-left: 12rem;
  }
  .\32xl\:pt-52 {
    padding-top: 13rem;
  }
  .\32xl\:pr-52 {
    padding-right: 13rem;
  }
  .\32xl\:pb-52 {
    padding-bottom: 13rem;
  }
  .\32xl\:pl-52 {
    padding-left: 13rem;
  }
  .\32xl\:pt-56 {
    padding-top: 14rem;
  }
  .\32xl\:pr-56 {
    padding-right: 14rem;
  }
  .\32xl\:pb-56 {
    padding-bottom: 14rem;
  }
  .\32xl\:pl-56 {
    padding-left: 14rem;
  }
  .\32xl\:pt-60 {
    padding-top: 15rem;
  }
  .\32xl\:pr-60 {
    padding-right: 15rem;
  }
  .\32xl\:pb-60 {
    padding-bottom: 15rem;
  }
  .\32xl\:pl-60 {
    padding-left: 15rem;
  }
  .\32xl\:pt-64 {
    padding-top: 16rem;
  }
  .\32xl\:pr-64 {
    padding-right: 16rem;
  }
  .\32xl\:pb-64 {
    padding-bottom: 16rem;
  }
  .\32xl\:pl-64 {
    padding-left: 16rem;
  }
  .\32xl\:pt-72 {
    padding-top: 18rem;
  }
  .\32xl\:pr-72 {
    padding-right: 18rem;
  }
  .\32xl\:pb-72 {
    padding-bottom: 18rem;
  }
  .\32xl\:pl-72 {
    padding-left: 18rem;
  }
  .\32xl\:pt-80 {
    padding-top: 20rem;
  }
  .\32xl\:pr-80 {
    padding-right: 20rem;
  }
  .\32xl\:pb-80 {
    padding-bottom: 20rem;
  }
  .\32xl\:pl-80 {
    padding-left: 20rem;
  }
  .\32xl\:pt-96 {
    padding-top: 24rem;
  }
  .\32xl\:pr-96 {
    padding-right: 24rem;
  }
  .\32xl\:pb-96 {
    padding-bottom: 24rem;
  }
  .\32xl\:pl-96 {
    padding-left: 24rem;
  }
  .\32xl\:pt-px {
    padding-top: 1px;
  }
  .\32xl\:pr-px {
    padding-right: 1px;
  }
  .\32xl\:pb-px {
    padding-bottom: 1px;
  }
  .\32xl\:pl-px {
    padding-left: 1px;
  }
  .\32xl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .\32xl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .\32xl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .\32xl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .\32xl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .\32xl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .\32xl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .\32xl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .\32xl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .\32xl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .\32xl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .\32xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .\32xl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .\32xl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .\32xl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .\32xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .\32xl\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .\32xl\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .\32xl\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .\32xl\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .\32xl\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .\32xl\:placeholder-current::placeholder {
    color: currentColor;
  }
  .\32xl\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .\32xl\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .\32xl\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .\32xl\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .\32xl\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .\32xl\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .\32xl\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .\32xl\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .\32xl\:placeholder-background::placeholder {
    color: var(--background);
  }
  .\32xl\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .\32xl\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .\32xl\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .\32xl\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .\32xl\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .\32xl\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .\32xl\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .\32xl\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .\32xl\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .\32xl\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .\32xl\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .\32xl\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .\32xl\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .\32xl\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .\32xl\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .\32xl\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .\32xl\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .\32xl\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .\32xl\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .\32xl\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .\32xl\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .\32xl\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .\32xl\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .\32xl\:placeholder-success::placeholder {
    color: var(--success);
  }
  .\32xl\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .\32xl\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .\32xl\:placeholder-error::placeholder {
    color: var(--error);
  }
  .\32xl\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .\32xl\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .\32xl\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .\32xl\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .\32xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .\32xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .\32xl\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .\32xl\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .\32xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .\32xl\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .\32xl\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .\32xl\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .\32xl\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .\32xl\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .\32xl\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .\32xl\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .\32xl\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .\32xl\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .\32xl\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .\32xl\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .\32xl\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .\32xl\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .\32xl\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .\32xl\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .\32xl\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .\32xl\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .\32xl\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .\32xl\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .\32xl\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .\32xl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .\32xl\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .\32xl\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .\32xl\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .\32xl\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .\32xl\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .\32xl\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .\32xl\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .\32xl\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .\32xl\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .\32xl\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .\32xl\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .\32xl\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .\32xl\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .\32xl\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .\32xl\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .\32xl\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .\32xl\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .\32xl\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .\32xl\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .\32xl\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .\32xl\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .\32xl\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .\32xl\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:pointer-events-none {
    pointer-events: none;
  }
  .\32xl\:pointer-events-auto {
    pointer-events: auto;
  }
  .\32xl\:static {
    position: static;
  }
  .\32xl\:fixed {
    position: fixed;
  }
  .\32xl\:absolute {
    position: absolute;
  }
  .\32xl\:relative {
    position: relative;
  }
  .\32xl\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .\32xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .\32xl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .\32xl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .\32xl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .\32xl\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .\32xl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .\32xl\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .\32xl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .\32xl\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .\32xl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .\32xl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .\32xl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .\32xl\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .\32xl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .\32xl\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .\32xl\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .\32xl\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .\32xl\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .\32xl\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .\32xl\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .\32xl\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .\32xl\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .\32xl\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .\32xl\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .\32xl\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .\32xl\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .\32xl\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .\32xl\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .\32xl\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .\32xl\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .\32xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .\32xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .\32xl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .\32xl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .\32xl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .\32xl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .\32xl\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .\32xl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .\32xl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .\32xl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .\32xl\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .\32xl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .\32xl\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .\32xl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .\32xl\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .\32xl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .\32xl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .\32xl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .\32xl\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .\32xl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .\32xl\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .\32xl\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .\32xl\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .\32xl\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .\32xl\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .\32xl\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .\32xl\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .\32xl\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .\32xl\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .\32xl\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .\32xl\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .\32xl\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .\32xl\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .\32xl\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .\32xl\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .\32xl\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .\32xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .\32xl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .\32xl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .\32xl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .\32xl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .\32xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .\32xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .\32xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .\32xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .\32xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .\32xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .\32xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .\32xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .\32xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .\32xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .\32xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .\32xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .\32xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .\32xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .\32xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .\32xl\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .\32xl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .\32xl\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .\32xl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .\32xl\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .\32xl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .\32xl\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .\32xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .\32xl\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .\32xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .\32xl\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .\32xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .\32xl\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .\32xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .\32xl\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .\32xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .\32xl\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .\32xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .\32xl\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .\32xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .\32xl\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .\32xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .\32xl\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .\32xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .\32xl\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .\32xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .\32xl\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .\32xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .\32xl\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .\32xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .\32xl\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .\32xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .\32xl\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .\32xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .\32xl\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .\32xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .\32xl\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .\32xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .\32xl\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .\32xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .\32xl\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .\32xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .\32xl\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .\32xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .\32xl\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .\32xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .\32xl\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .\32xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .\32xl\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .\32xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .\32xl\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .\32xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .\32xl\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .\32xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .\32xl\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .\32xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .\32xl\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .\32xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .\32xl\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .\32xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .\32xl\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .\32xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .\32xl\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .\32xl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .\32xl\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .\32xl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .\32xl\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .\32xl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .\32xl\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .\32xl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .\32xl\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .\32xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .\32xl\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .\32xl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .\32xl\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .\32xl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .\32xl\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .\32xl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .\32xl\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .\32xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .\32xl\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .\32xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .\32xl\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .\32xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .\32xl\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .\32xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .\32xl\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .\32xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .\32xl\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .\32xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .\32xl\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .\32xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .\32xl\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .\32xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .\32xl\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .\32xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .\32xl\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .\32xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .\32xl\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .\32xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .\32xl\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .\32xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .\32xl\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .\32xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .\32xl\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .\32xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .\32xl\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .\32xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .\32xl\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .\32xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .\32xl\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .\32xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .\32xl\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .\32xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .\32xl\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .\32xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .\32xl\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .\32xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .\32xl\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .\32xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .\32xl\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .\32xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .\32xl\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .\32xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .\32xl\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .\32xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .\32xl\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .\32xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .\32xl\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .\32xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .\32xl\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .\32xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .\32xl\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .\32xl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .\32xl\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .\32xl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .\32xl\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .\32xl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .\32xl\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .\32xl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .\32xl\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .\32xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .\32xl\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .\32xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .\32xl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .\32xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .\32xl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .\32xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .\32xl\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .\32xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .\32xl\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .\32xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .\32xl\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .\32xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .\32xl\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .\32xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .\32xl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .\32xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .\32xl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .\32xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .\32xl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .\32xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .\32xl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .\32xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .\32xl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .\32xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .\32xl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .\32xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .\32xl\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .\32xl\:top-0 {
    top: 0;
  }
  .\32xl\:right-0 {
    right: 0;
  }
  .\32xl\:bottom-0 {
    bottom: 0;
  }
  .\32xl\:left-0 {
    left: 0;
  }
  .\32xl\:top-1 {
    top: 0.25rem;
  }
  .\32xl\:right-1 {
    right: 0.25rem;
  }
  .\32xl\:bottom-1 {
    bottom: 0.25rem;
  }
  .\32xl\:left-1 {
    left: 0.25rem;
  }
  .\32xl\:top-2 {
    top: 0.5rem;
  }
  .\32xl\:right-2 {
    right: 0.5rem;
  }
  .\32xl\:bottom-2 {
    bottom: 0.5rem;
  }
  .\32xl\:left-2 {
    left: 0.5rem;
  }
  .\32xl\:top-3 {
    top: 0.75rem;
  }
  .\32xl\:right-3 {
    right: 0.75rem;
  }
  .\32xl\:bottom-3 {
    bottom: 0.75rem;
  }
  .\32xl\:left-3 {
    left: 0.75rem;
  }
  .\32xl\:top-4 {
    top: 1rem;
  }
  .\32xl\:right-4 {
    right: 1rem;
  }
  .\32xl\:bottom-4 {
    bottom: 1rem;
  }
  .\32xl\:left-4 {
    left: 1rem;
  }
  .\32xl\:top-5 {
    top: 1.25rem;
  }
  .\32xl\:right-5 {
    right: 1.25rem;
  }
  .\32xl\:bottom-5 {
    bottom: 1.25rem;
  }
  .\32xl\:left-5 {
    left: 1.25rem;
  }
  .\32xl\:top-6 {
    top: 1.5rem;
  }
  .\32xl\:right-6 {
    right: 1.5rem;
  }
  .\32xl\:bottom-6 {
    bottom: 1.5rem;
  }
  .\32xl\:left-6 {
    left: 1.5rem;
  }
  .\32xl\:top-7 {
    top: 1.75rem;
  }
  .\32xl\:right-7 {
    right: 1.75rem;
  }
  .\32xl\:bottom-7 {
    bottom: 1.75rem;
  }
  .\32xl\:left-7 {
    left: 1.75rem;
  }
  .\32xl\:top-8 {
    top: 2rem;
  }
  .\32xl\:right-8 {
    right: 2rem;
  }
  .\32xl\:bottom-8 {
    bottom: 2rem;
  }
  .\32xl\:left-8 {
    left: 2rem;
  }
  .\32xl\:top-9 {
    top: 2.25rem;
  }
  .\32xl\:right-9 {
    right: 2.25rem;
  }
  .\32xl\:bottom-9 {
    bottom: 2.25rem;
  }
  .\32xl\:left-9 {
    left: 2.25rem;
  }
  .\32xl\:top-10 {
    top: 2.5rem;
  }
  .\32xl\:right-10 {
    right: 2.5rem;
  }
  .\32xl\:bottom-10 {
    bottom: 2.5rem;
  }
  .\32xl\:left-10 {
    left: 2.5rem;
  }
  .\32xl\:top-11 {
    top: 2.75rem;
  }
  .\32xl\:right-11 {
    right: 2.75rem;
  }
  .\32xl\:bottom-11 {
    bottom: 2.75rem;
  }
  .\32xl\:left-11 {
    left: 2.75rem;
  }
  .\32xl\:top-12 {
    top: 3rem;
  }
  .\32xl\:right-12 {
    right: 3rem;
  }
  .\32xl\:bottom-12 {
    bottom: 3rem;
  }
  .\32xl\:left-12 {
    left: 3rem;
  }
  .\32xl\:top-14 {
    top: 3.5rem;
  }
  .\32xl\:right-14 {
    right: 3.5rem;
  }
  .\32xl\:bottom-14 {
    bottom: 3.5rem;
  }
  .\32xl\:left-14 {
    left: 3.5rem;
  }
  .\32xl\:top-16 {
    top: 4rem;
  }
  .\32xl\:right-16 {
    right: 4rem;
  }
  .\32xl\:bottom-16 {
    bottom: 4rem;
  }
  .\32xl\:left-16 {
    left: 4rem;
  }
  .\32xl\:top-20 {
    top: 5rem;
  }
  .\32xl\:right-20 {
    right: 5rem;
  }
  .\32xl\:bottom-20 {
    bottom: 5rem;
  }
  .\32xl\:left-20 {
    left: 5rem;
  }
  .\32xl\:top-24 {
    top: 6rem;
  }
  .\32xl\:right-24 {
    right: 6rem;
  }
  .\32xl\:bottom-24 {
    bottom: 6rem;
  }
  .\32xl\:left-24 {
    left: 6rem;
  }
  .\32xl\:top-28 {
    top: 7rem;
  }
  .\32xl\:right-28 {
    right: 7rem;
  }
  .\32xl\:bottom-28 {
    bottom: 7rem;
  }
  .\32xl\:left-28 {
    left: 7rem;
  }
  .\32xl\:top-32 {
    top: 8rem;
  }
  .\32xl\:right-32 {
    right: 8rem;
  }
  .\32xl\:bottom-32 {
    bottom: 8rem;
  }
  .\32xl\:left-32 {
    left: 8rem;
  }
  .\32xl\:top-36 {
    top: 9rem;
  }
  .\32xl\:right-36 {
    right: 9rem;
  }
  .\32xl\:bottom-36 {
    bottom: 9rem;
  }
  .\32xl\:left-36 {
    left: 9rem;
  }
  .\32xl\:top-40 {
    top: 10rem;
  }
  .\32xl\:right-40 {
    right: 10rem;
  }
  .\32xl\:bottom-40 {
    bottom: 10rem;
  }
  .\32xl\:left-40 {
    left: 10rem;
  }
  .\32xl\:top-44 {
    top: 11rem;
  }
  .\32xl\:right-44 {
    right: 11rem;
  }
  .\32xl\:bottom-44 {
    bottom: 11rem;
  }
  .\32xl\:left-44 {
    left: 11rem;
  }
  .\32xl\:top-48 {
    top: 12rem;
  }
  .\32xl\:right-48 {
    right: 12rem;
  }
  .\32xl\:bottom-48 {
    bottom: 12rem;
  }
  .\32xl\:left-48 {
    left: 12rem;
  }
  .\32xl\:top-52 {
    top: 13rem;
  }
  .\32xl\:right-52 {
    right: 13rem;
  }
  .\32xl\:bottom-52 {
    bottom: 13rem;
  }
  .\32xl\:left-52 {
    left: 13rem;
  }
  .\32xl\:top-56 {
    top: 14rem;
  }
  .\32xl\:right-56 {
    right: 14rem;
  }
  .\32xl\:bottom-56 {
    bottom: 14rem;
  }
  .\32xl\:left-56 {
    left: 14rem;
  }
  .\32xl\:top-60 {
    top: 15rem;
  }
  .\32xl\:right-60 {
    right: 15rem;
  }
  .\32xl\:bottom-60 {
    bottom: 15rem;
  }
  .\32xl\:left-60 {
    left: 15rem;
  }
  .\32xl\:top-64 {
    top: 16rem;
  }
  .\32xl\:right-64 {
    right: 16rem;
  }
  .\32xl\:bottom-64 {
    bottom: 16rem;
  }
  .\32xl\:left-64 {
    left: 16rem;
  }
  .\32xl\:top-72 {
    top: 18rem;
  }
  .\32xl\:right-72 {
    right: 18rem;
  }
  .\32xl\:bottom-72 {
    bottom: 18rem;
  }
  .\32xl\:left-72 {
    left: 18rem;
  }
  .\32xl\:top-80 {
    top: 20rem;
  }
  .\32xl\:right-80 {
    right: 20rem;
  }
  .\32xl\:bottom-80 {
    bottom: 20rem;
  }
  .\32xl\:left-80 {
    left: 20rem;
  }
  .\32xl\:top-96 {
    top: 24rem;
  }
  .\32xl\:right-96 {
    right: 24rem;
  }
  .\32xl\:bottom-96 {
    bottom: 24rem;
  }
  .\32xl\:left-96 {
    left: 24rem;
  }
  .\32xl\:top-auto {
    top: auto;
  }
  .\32xl\:right-auto {
    right: auto;
  }
  .\32xl\:bottom-auto {
    bottom: auto;
  }
  .\32xl\:left-auto {
    left: auto;
  }
  .\32xl\:top-px {
    top: 1px;
  }
  .\32xl\:right-px {
    right: 1px;
  }
  .\32xl\:bottom-px {
    bottom: 1px;
  }
  .\32xl\:left-px {
    left: 1px;
  }
  .\32xl\:top-0\.5 {
    top: 0.125rem;
  }
  .\32xl\:right-0\.5 {
    right: 0.125rem;
  }
  .\32xl\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .\32xl\:left-0\.5 {
    left: 0.125rem;
  }
  .\32xl\:top-1\.5 {
    top: 0.375rem;
  }
  .\32xl\:right-1\.5 {
    right: 0.375rem;
  }
  .\32xl\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .\32xl\:left-1\.5 {
    left: 0.375rem;
  }
  .\32xl\:top-2\.5 {
    top: 0.625rem;
  }
  .\32xl\:right-2\.5 {
    right: 0.625rem;
  }
  .\32xl\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .\32xl\:left-2\.5 {
    left: 0.625rem;
  }
  .\32xl\:top-3\.5 {
    top: 0.875rem;
  }
  .\32xl\:right-3\.5 {
    right: 0.875rem;
  }
  .\32xl\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .\32xl\:left-3\.5 {
    left: 0.875rem;
  }
  .\32xl\:-top-0 {
    top: 0;
  }
  .\32xl\:-right-0 {
    right: 0;
  }
  .\32xl\:-bottom-0 {
    bottom: 0;
  }
  .\32xl\:-left-0 {
    left: 0;
  }
  .\32xl\:-top-1 {
    top: -0.25rem;
  }
  .\32xl\:-right-1 {
    right: -0.25rem;
  }
  .\32xl\:-bottom-1 {
    bottom: -0.25rem;
  }
  .\32xl\:-left-1 {
    left: -0.25rem;
  }
  .\32xl\:-top-2 {
    top: -0.5rem;
  }
  .\32xl\:-right-2 {
    right: -0.5rem;
  }
  .\32xl\:-bottom-2 {
    bottom: -0.5rem;
  }
  .\32xl\:-left-2 {
    left: -0.5rem;
  }
  .\32xl\:-top-3 {
    top: -0.75rem;
  }
  .\32xl\:-right-3 {
    right: -0.75rem;
  }
  .\32xl\:-bottom-3 {
    bottom: -0.75rem;
  }
  .\32xl\:-left-3 {
    left: -0.75rem;
  }
  .\32xl\:-top-4 {
    top: -1rem;
  }
  .\32xl\:-right-4 {
    right: -1rem;
  }
  .\32xl\:-bottom-4 {
    bottom: -1rem;
  }
  .\32xl\:-left-4 {
    left: -1rem;
  }
  .\32xl\:-top-5 {
    top: -1.25rem;
  }
  .\32xl\:-right-5 {
    right: -1.25rem;
  }
  .\32xl\:-bottom-5 {
    bottom: -1.25rem;
  }
  .\32xl\:-left-5 {
    left: -1.25rem;
  }
  .\32xl\:-top-6 {
    top: -1.5rem;
  }
  .\32xl\:-right-6 {
    right: -1.5rem;
  }
  .\32xl\:-bottom-6 {
    bottom: -1.5rem;
  }
  .\32xl\:-left-6 {
    left: -1.5rem;
  }
  .\32xl\:-top-7 {
    top: -1.75rem;
  }
  .\32xl\:-right-7 {
    right: -1.75rem;
  }
  .\32xl\:-bottom-7 {
    bottom: -1.75rem;
  }
  .\32xl\:-left-7 {
    left: -1.75rem;
  }
  .\32xl\:-top-8 {
    top: -2rem;
  }
  .\32xl\:-right-8 {
    right: -2rem;
  }
  .\32xl\:-bottom-8 {
    bottom: -2rem;
  }
  .\32xl\:-left-8 {
    left: -2rem;
  }
  .\32xl\:-top-9 {
    top: -2.25rem;
  }
  .\32xl\:-right-9 {
    right: -2.25rem;
  }
  .\32xl\:-bottom-9 {
    bottom: -2.25rem;
  }
  .\32xl\:-left-9 {
    left: -2.25rem;
  }
  .\32xl\:-top-10 {
    top: -2.5rem;
  }
  .\32xl\:-right-10 {
    right: -2.5rem;
  }
  .\32xl\:-bottom-10 {
    bottom: -2.5rem;
  }
  .\32xl\:-left-10 {
    left: -2.5rem;
  }
  .\32xl\:-top-11 {
    top: -2.75rem;
  }
  .\32xl\:-right-11 {
    right: -2.75rem;
  }
  .\32xl\:-bottom-11 {
    bottom: -2.75rem;
  }
  .\32xl\:-left-11 {
    left: -2.75rem;
  }
  .\32xl\:-top-12 {
    top: -3rem;
  }
  .\32xl\:-right-12 {
    right: -3rem;
  }
  .\32xl\:-bottom-12 {
    bottom: -3rem;
  }
  .\32xl\:-left-12 {
    left: -3rem;
  }
  .\32xl\:-top-14 {
    top: -3.5rem;
  }
  .\32xl\:-right-14 {
    right: -3.5rem;
  }
  .\32xl\:-bottom-14 {
    bottom: -3.5rem;
  }
  .\32xl\:-left-14 {
    left: -3.5rem;
  }
  .\32xl\:-top-16 {
    top: -4rem;
  }
  .\32xl\:-right-16 {
    right: -4rem;
  }
  .\32xl\:-bottom-16 {
    bottom: -4rem;
  }
  .\32xl\:-left-16 {
    left: -4rem;
  }
  .\32xl\:-top-20 {
    top: -5rem;
  }
  .\32xl\:-right-20 {
    right: -5rem;
  }
  .\32xl\:-bottom-20 {
    bottom: -5rem;
  }
  .\32xl\:-left-20 {
    left: -5rem;
  }
  .\32xl\:-top-24 {
    top: -6rem;
  }
  .\32xl\:-right-24 {
    right: -6rem;
  }
  .\32xl\:-bottom-24 {
    bottom: -6rem;
  }
  .\32xl\:-left-24 {
    left: -6rem;
  }
  .\32xl\:-top-28 {
    top: -7rem;
  }
  .\32xl\:-right-28 {
    right: -7rem;
  }
  .\32xl\:-bottom-28 {
    bottom: -7rem;
  }
  .\32xl\:-left-28 {
    left: -7rem;
  }
  .\32xl\:-top-32 {
    top: -8rem;
  }
  .\32xl\:-right-32 {
    right: -8rem;
  }
  .\32xl\:-bottom-32 {
    bottom: -8rem;
  }
  .\32xl\:-left-32 {
    left: -8rem;
  }
  .\32xl\:-top-36 {
    top: -9rem;
  }
  .\32xl\:-right-36 {
    right: -9rem;
  }
  .\32xl\:-bottom-36 {
    bottom: -9rem;
  }
  .\32xl\:-left-36 {
    left: -9rem;
  }
  .\32xl\:-top-40 {
    top: -10rem;
  }
  .\32xl\:-right-40 {
    right: -10rem;
  }
  .\32xl\:-bottom-40 {
    bottom: -10rem;
  }
  .\32xl\:-left-40 {
    left: -10rem;
  }
  .\32xl\:-top-44 {
    top: -11rem;
  }
  .\32xl\:-right-44 {
    right: -11rem;
  }
  .\32xl\:-bottom-44 {
    bottom: -11rem;
  }
  .\32xl\:-left-44 {
    left: -11rem;
  }
  .\32xl\:-top-48 {
    top: -12rem;
  }
  .\32xl\:-right-48 {
    right: -12rem;
  }
  .\32xl\:-bottom-48 {
    bottom: -12rem;
  }
  .\32xl\:-left-48 {
    left: -12rem;
  }
  .\32xl\:-top-52 {
    top: -13rem;
  }
  .\32xl\:-right-52 {
    right: -13rem;
  }
  .\32xl\:-bottom-52 {
    bottom: -13rem;
  }
  .\32xl\:-left-52 {
    left: -13rem;
  }
  .\32xl\:-top-56 {
    top: -14rem;
  }
  .\32xl\:-right-56 {
    right: -14rem;
  }
  .\32xl\:-bottom-56 {
    bottom: -14rem;
  }
  .\32xl\:-left-56 {
    left: -14rem;
  }
  .\32xl\:-top-60 {
    top: -15rem;
  }
  .\32xl\:-right-60 {
    right: -15rem;
  }
  .\32xl\:-bottom-60 {
    bottom: -15rem;
  }
  .\32xl\:-left-60 {
    left: -15rem;
  }
  .\32xl\:-top-64 {
    top: -16rem;
  }
  .\32xl\:-right-64 {
    right: -16rem;
  }
  .\32xl\:-bottom-64 {
    bottom: -16rem;
  }
  .\32xl\:-left-64 {
    left: -16rem;
  }
  .\32xl\:-top-72 {
    top: -18rem;
  }
  .\32xl\:-right-72 {
    right: -18rem;
  }
  .\32xl\:-bottom-72 {
    bottom: -18rem;
  }
  .\32xl\:-left-72 {
    left: -18rem;
  }
  .\32xl\:-top-80 {
    top: -20rem;
  }
  .\32xl\:-right-80 {
    right: -20rem;
  }
  .\32xl\:-bottom-80 {
    bottom: -20rem;
  }
  .\32xl\:-left-80 {
    left: -20rem;
  }
  .\32xl\:-top-96 {
    top: -24rem;
  }
  .\32xl\:-right-96 {
    right: -24rem;
  }
  .\32xl\:-bottom-96 {
    bottom: -24rem;
  }
  .\32xl\:-left-96 {
    left: -24rem;
  }
  .\32xl\:-top-px {
    top: -1px;
  }
  .\32xl\:-right-px {
    right: -1px;
  }
  .\32xl\:-bottom-px {
    bottom: -1px;
  }
  .\32xl\:-left-px {
    left: -1px;
  }
  .\32xl\:-top-0\.5 {
    top: -0.125rem;
  }
  .\32xl\:-right-0\.5 {
    right: -0.125rem;
  }
  .\32xl\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .\32xl\:-left-0\.5 {
    left: -0.125rem;
  }
  .\32xl\:-top-1\.5 {
    top: -0.375rem;
  }
  .\32xl\:-right-1\.5 {
    right: -0.375rem;
  }
  .\32xl\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .\32xl\:-left-1\.5 {
    left: -0.375rem;
  }
  .\32xl\:-top-2\.5 {
    top: -0.625rem;
  }
  .\32xl\:-right-2\.5 {
    right: -0.625rem;
  }
  .\32xl\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .\32xl\:-left-2\.5 {
    left: -0.625rem;
  }
  .\32xl\:-top-3\.5 {
    top: -0.875rem;
  }
  .\32xl\:-right-3\.5 {
    right: -0.875rem;
  }
  .\32xl\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .\32xl\:-left-3\.5 {
    left: -0.875rem;
  }
  .\32xl\:top-1\/2 {
    top: 50%;
  }
  .\32xl\:right-1\/2 {
    right: 50%;
  }
  .\32xl\:bottom-1\/2 {
    bottom: 50%;
  }
  .\32xl\:left-1\/2 {
    left: 50%;
  }
  .\32xl\:top-1\/3 {
    top: 33.333333%;
  }
  .\32xl\:right-1\/3 {
    right: 33.333333%;
  }
  .\32xl\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .\32xl\:left-1\/3 {
    left: 33.333333%;
  }
  .\32xl\:top-2\/3 {
    top: 66.666667%;
  }
  .\32xl\:right-2\/3 {
    right: 66.666667%;
  }
  .\32xl\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .\32xl\:left-2\/3 {
    left: 66.666667%;
  }
  .\32xl\:top-1\/4 {
    top: 25%;
  }
  .\32xl\:right-1\/4 {
    right: 25%;
  }
  .\32xl\:bottom-1\/4 {
    bottom: 25%;
  }
  .\32xl\:left-1\/4 {
    left: 25%;
  }
  .\32xl\:top-2\/4 {
    top: 50%;
  }
  .\32xl\:right-2\/4 {
    right: 50%;
  }
  .\32xl\:bottom-2\/4 {
    bottom: 50%;
  }
  .\32xl\:left-2\/4 {
    left: 50%;
  }
  .\32xl\:top-3\/4 {
    top: 75%;
  }
  .\32xl\:right-3\/4 {
    right: 75%;
  }
  .\32xl\:bottom-3\/4 {
    bottom: 75%;
  }
  .\32xl\:left-3\/4 {
    left: 75%;
  }
  .\32xl\:top-full {
    top: 100%;
  }
  .\32xl\:right-full {
    right: 100%;
  }
  .\32xl\:bottom-full {
    bottom: 100%;
  }
  .\32xl\:left-full {
    left: 100%;
  }
  .\32xl\:-top-1\/2 {
    top: -50%;
  }
  .\32xl\:-right-1\/2 {
    right: -50%;
  }
  .\32xl\:-bottom-1\/2 {
    bottom: -50%;
  }
  .\32xl\:-left-1\/2 {
    left: -50%;
  }
  .\32xl\:-top-1\/3 {
    top: -33.333333%;
  }
  .\32xl\:-right-1\/3 {
    right: -33.333333%;
  }
  .\32xl\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .\32xl\:-left-1\/3 {
    left: -33.333333%;
  }
  .\32xl\:-top-2\/3 {
    top: -66.666667%;
  }
  .\32xl\:-right-2\/3 {
    right: -66.666667%;
  }
  .\32xl\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .\32xl\:-left-2\/3 {
    left: -66.666667%;
  }
  .\32xl\:-top-1\/4 {
    top: -25%;
  }
  .\32xl\:-right-1\/4 {
    right: -25%;
  }
  .\32xl\:-bottom-1\/4 {
    bottom: -25%;
  }
  .\32xl\:-left-1\/4 {
    left: -25%;
  }
  .\32xl\:-top-2\/4 {
    top: -50%;
  }
  .\32xl\:-right-2\/4 {
    right: -50%;
  }
  .\32xl\:-bottom-2\/4 {
    bottom: -50%;
  }
  .\32xl\:-left-2\/4 {
    left: -50%;
  }
  .\32xl\:-top-3\/4 {
    top: -75%;
  }
  .\32xl\:-right-3\/4 {
    right: -75%;
  }
  .\32xl\:-bottom-3\/4 {
    bottom: -75%;
  }
  .\32xl\:-left-3\/4 {
    left: -75%;
  }
  .\32xl\:-top-full {
    top: -100%;
  }
  .\32xl\:-right-full {
    right: -100%;
  }
  .\32xl\:-bottom-full {
    bottom: -100%;
  }
  .\32xl\:-left-full {
    left: -100%;
  }
  .\32xl\:resize-none {
    resize: none;
  }
  .\32xl\:resize-y {
    resize: vertical;
  }
  .\32xl\:resize-x {
    resize: horizontal;
  }
  .\32xl\:resize {
    resize: both;
  }
  .\32xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .\32xl\:shadow,
  .\32xl\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .\32xl\:shadow-lg,
  .\32xl\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .\32xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .\32xl\:shadow-2xl,
  .\32xl\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .\32xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:shadow-inner,
  .\32xl\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .\32xl\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .\32xl\:shadow-bottom-nav,
  .\32xl\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .\32xl\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .\32xl\:shadow-mobile-buttons,
  .group:hover .\32xl\:group-hover\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .\32xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .\32xl\:group-hover\:shadow,
  .group:hover .\32xl\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .\32xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .\32xl\:group-hover\:shadow-lg,
  .group:hover .\32xl\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .\32xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .\32xl\:group-hover\:shadow-2xl,
  .group:hover .\32xl\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .\32xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .\32xl\:group-hover\:shadow-none,
  .group:hover .\32xl\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .\32xl\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .\32xl\:group-hover\:shadow-bottom-nav,
  .group:hover .\32xl\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .\32xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .\32xl\:hover\:shadow-sm:hover,
  .\32xl\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .\32xl\:hover\:shadow-lg:hover,
  .\32xl\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .\32xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .\32xl\:hover\:shadow-2xl:hover,
  .\32xl\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .\32xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:hover\:shadow-inner:hover,
  .\32xl\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .\32xl\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .\32xl\:hover\:shadow-bottom-nav:hover,
  .\32xl\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .\32xl\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .\32xl\:focus\:shadow-sm:focus,
  .\32xl\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .\32xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:focus\:shadow-md:focus,
  .\32xl\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .\32xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .\32xl\:focus\:shadow-lg:focus,
  .\32xl\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .\32xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .\32xl\:focus\:shadow-2xl:focus,
  .\32xl\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .\32xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .\32xl\:focus\:shadow-none:focus,
  .\32xl\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .\32xl\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .\32xl\:focus\:shadow-bottom-nav:focus,
  .\32xl\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .\32xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring-0,
  .\32xl\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring-2,
  .\32xl\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring,
  .\32xl\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:ring-inset {
    --tw-ring-inset: inset;
  }
  .\32xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .\32xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-0:focus,
  .\32xl\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-2:focus,
  .\32xl\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-8:focus,
  .\32xl\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .\32xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .\32xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .\32xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .\32xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .\32xl\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .\32xl\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .\32xl\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .\32xl\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .\32xl\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .\32xl\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .\32xl\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .\32xl\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .\32xl\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .\32xl\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .\32xl\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .\32xl\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .\32xl\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .\32xl\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .\32xl\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .\32xl\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .\32xl\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .\32xl\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .\32xl\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .\32xl\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .\32xl\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .\32xl\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .\32xl\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .\32xl\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .\32xl\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .\32xl\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .\32xl\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .\32xl\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .\32xl\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .\32xl\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .\32xl\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .\32xl\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .\32xl\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .\32xl\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .\32xl\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .\32xl\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .\32xl\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .\32xl\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .\32xl\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .\32xl\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .\32xl\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .\32xl\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .\32xl\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .\32xl\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .\32xl\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .\32xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .\32xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .\32xl\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .\32xl\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .\32xl\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .\32xl\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .\32xl\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .\32xl\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .\32xl\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .\32xl\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .\32xl\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .\32xl\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .\32xl\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .\32xl\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .\32xl\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .\32xl\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .\32xl\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .\32xl\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .\32xl\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .\32xl\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .\32xl\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .\32xl\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .\32xl\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .\32xl\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .\32xl\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .\32xl\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .\32xl\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .\32xl\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .\32xl\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .\32xl\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .\32xl\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .\32xl\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .\32xl\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .\32xl\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .\32xl\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .\32xl\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .\32xl\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .\32xl\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .\32xl\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .\32xl\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .\32xl\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .\32xl\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .\32xl\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .\32xl\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .\32xl\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .\32xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .\32xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .\32xl\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .\32xl\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .\32xl\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .\32xl\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .\32xl\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .\32xl\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .\32xl\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .\32xl\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .\32xl\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .\32xl\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .\32xl\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .\32xl\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .\32xl\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .\32xl\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .\32xl\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .\32xl\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .\32xl\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .\32xl\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .\32xl\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .\32xl\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .\32xl\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .\32xl\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .\32xl\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .\32xl\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .\32xl\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .\32xl\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .\32xl\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .\32xl\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .\32xl\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .\32xl\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .\32xl\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .\32xl\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .\32xl\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .\32xl\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .\32xl\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .\32xl\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .\32xl\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .\32xl\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .\32xl\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .\32xl\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .\32xl\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .\32xl\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .\32xl\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .\32xl\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .\32xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .\32xl\:ring-current {
    --tw-ring-color: currentColor;
  }
  .\32xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .\32xl\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .\32xl\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .\32xl\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .\32xl\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .\32xl\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .\32xl\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .\32xl\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .\32xl\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .\32xl\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .\32xl\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .\32xl\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .\32xl\:ring-background {
    --tw-ring-color: var(--background);
  }
  .\32xl\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .\32xl\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .\32xl\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .\32xl\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .\32xl\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .\32xl\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .\32xl\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .\32xl\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .\32xl\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .\32xl\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .\32xl\:ring-success {
    --tw-ring-color: var(--success);
  }
  .\32xl\:ring-error {
    --tw-ring-color: var(--error);
  }
  .\32xl\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .\32xl\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .\32xl\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .\32xl\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .\32xl\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .\32xl\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .\32xl\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .\32xl\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .\32xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .\32xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .\32xl\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .\32xl\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .\32xl\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .\32xl\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .\32xl\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .\32xl\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .\32xl\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .\32xl\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .\32xl\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .\32xl\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .\32xl\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .\32xl\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .\32xl\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .\32xl\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .\32xl\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .\32xl\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .\32xl\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .\32xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .\32xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .\32xl\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .\32xl\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .\32xl\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .\32xl\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .\32xl\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .\32xl\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .\32xl\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .\32xl\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .\32xl\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .\32xl\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .\32xl\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .\32xl\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .\32xl\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .\32xl\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .\32xl\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .\32xl\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .\32xl\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .\32xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .\32xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .\32xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .\32xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .\32xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .\32xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .\32xl\:fill-current {
    fill: currentColor;
  }
  .\32xl\:stroke-current {
    stroke: currentColor;
  }
  .\32xl\:stroke-0 {
    stroke-width: 0;
  }
  .\32xl\:stroke-1 {
    stroke-width: 1;
  }
  .\32xl\:stroke-2 {
    stroke-width: 2;
  }
  .\32xl\:table-auto {
    table-layout: auto;
  }
  .\32xl\:table-fixed {
    table-layout: fixed;
  }
  .\32xl\:text-left {
    text-align: left;
  }
  .\32xl\:text-center {
    text-align: center;
  }
  .\32xl\:text-right {
    text-align: right;
  }
  .\32xl\:text-justify {
    text-align: justify;
  }
  .\32xl\:text-transparent {
    color: transparent;
  }
  .\32xl\:text-current {
    color: currentColor;
  }
  .\32xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .\32xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:text-background-primary {
    color: var(--background-primary);
  }
  .\32xl\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .\32xl\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .\32xl\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .\32xl\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .\32xl\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .\32xl\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .\32xl\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .\32xl\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .\32xl\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .\32xl\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .\32xl\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .\32xl\:text-body-background {
    color: var(--body-background);
  }
  .\32xl\:text-background {
    color: var(--background);
  }
  .\32xl\:text-input-background {
    color: var(--input-background);
  }
  .\32xl\:text-body-text {
    color: var(--body-text);
  }
  .\32xl\:text-header-text {
    color: var(--header-text);
  }
  .\32xl\:text-primary {
    color: var(--primary);
  }
  .\32xl\:text-secondary {
    color: var(--secondary);
  }
  .\32xl\:text-tertiary {
    color: var(--tertiary);
  }
  .\32xl\:text-primary-hover {
    color: var(--primary-hover);
  }
  .\32xl\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .\32xl\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .\32xl\:text-input-text {
    color: var(--input-text);
  }
  .\32xl\:text-success {
    color: var(--success);
  }
  .\32xl\:text-error {
    color: var(--error);
  }
  .\32xl\:text-custom-loader {
    color: var(--custom-loader);
  }
  .\32xl\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .\32xl\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:text-background50 {
    color: var(--background50);
  }
  .\32xl\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .\32xl\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .\32xl\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .\32xl\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .\32xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .\32xl\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .\32xl\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .\32xl\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .\32xl\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .\32xl\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .\32xl\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .\32xl\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .\32xl\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .\32xl\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .\32xl\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .\32xl\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .\32xl\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .\32xl\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .\32xl\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .\32xl\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .\32xl\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .\32xl\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .\32xl\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .\32xl\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .\32xl\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .\32xl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .\32xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .\32xl\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .\32xl\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .\32xl\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .\32xl\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .\32xl\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .\32xl\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .\32xl\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .\32xl\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .\32xl\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .\32xl\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .\32xl\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .\32xl\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .\32xl\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .\32xl\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .\32xl\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .\32xl\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .\32xl\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-transparent:hover {
    color: transparent;
  }
  .\32xl\:hover\:text-current:hover {
    color: currentColor;
  }
  .\32xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .\32xl\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .\32xl\:hover\:text-background:hover {
    color: var(--background);
  }
  .\32xl\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .\32xl\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .\32xl\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .\32xl\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .\32xl\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .\32xl\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .\32xl\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .\32xl\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .\32xl\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .\32xl\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .\32xl\:hover\:text-success:hover {
    color: var(--success);
  }
  .\32xl\:hover\:text-error:hover {
    color: var(--error);
  }
  .\32xl\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .\32xl\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .\32xl\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-transparent:focus {
    color: transparent;
  }
  .\32xl\:focus\:text-current:focus {
    color: currentColor;
  }
  .\32xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .\32xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .\32xl\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .\32xl\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .\32xl\:focus\:text-background:focus {
    color: var(--background);
  }
  .\32xl\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .\32xl\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .\32xl\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .\32xl\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .\32xl\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .\32xl\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .\32xl\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .\32xl\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .\32xl\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .\32xl\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .\32xl\:focus\:text-success:focus {
    color: var(--success);
  }
  .\32xl\:focus\:text-error:focus {
    color: var(--error);
  }
  .\32xl\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .\32xl\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .\32xl\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .\32xl\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .\32xl\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .\32xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .\32xl\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .\32xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .\32xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .\32xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .\32xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .\32xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .\32xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .\32xl\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .\32xl\:overflow-ellipsis,
  .\32xl\:truncate {
    text-overflow: ellipsis;
  }
  .\32xl\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .\32xl\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .\32xl\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .\32xl\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .\32xl\:italic {
    font-style: italic;
  }
  .\32xl\:not-italic {
    font-style: normal;
  }
  .\32xl\:uppercase {
    text-transform: uppercase;
  }
  .\32xl\:lowercase {
    text-transform: lowercase;
  }
  .\32xl\:capitalize {
    text-transform: capitalize;
  }
  .\32xl\:normal-case {
    text-transform: none;
  }
  .\32xl\:underline {
    text-decoration: underline;
  }
  .\32xl\:line-through {
    text-decoration: line-through;
  }
  .\32xl\:no-underline {
    text-decoration: none;
  }
  .group:hover .\32xl\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .\32xl\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .\32xl\:group-hover\:no-underline {
    text-decoration: none;
  }
  .\32xl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .\32xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .\32xl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .\32xl\:hover\:underline:hover {
    text-decoration: underline;
  }
  .\32xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .\32xl\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .\32xl\:focus\:underline:focus {
    text-decoration: underline;
  }
  .\32xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .\32xl\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .\32xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .\32xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .\32xl\:diagonal-fractions,
  .\32xl\:lining-nums,
  .\32xl\:oldstyle-nums,
  .\32xl\:ordinal,
  .\32xl\:proportional-nums,
  .\32xl\:slashed-zero,
  .\32xl\:stacked-fractions,
  .\32xl\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .\32xl\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .\32xl\:ordinal {
    --tw-ordinal: ordinal;
  }
  .\32xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .\32xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .\32xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .\32xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .\32xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .\32xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .\32xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .\32xl\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .\32xl\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .\32xl\:tracking-normal {
    letter-spacing: 0;
  }
  .\32xl\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .\32xl\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .\32xl\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .\32xl\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .\32xl\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .\32xl\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .\32xl\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .\32xl\:align-baseline {
    vertical-align: baseline;
  }
  .\32xl\:align-top {
    vertical-align: top;
  }
  .\32xl\:align-middle {
    vertical-align: middle;
  }
  .\32xl\:align-bottom {
    vertical-align: bottom;
  }
  .\32xl\:align-text-top {
    vertical-align: text-top;
  }
  .\32xl\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .\32xl\:visible {
    visibility: visible;
  }
  .\32xl\:invisible {
    visibility: hidden;
  }
  .\32xl\:whitespace-normal {
    white-space: normal;
  }
  .\32xl\:whitespace-nowrap {
    white-space: nowrap;
  }
  .\32xl\:whitespace-pre {
    white-space: pre;
  }
  .\32xl\:whitespace-pre-line {
    white-space: pre-line;
  }
  .\32xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .\32xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .\32xl\:break-words {
    overflow-wrap: break-word;
  }
  .\32xl\:break-all {
    word-break: break-all;
  }
  .group:hover .\32xl\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .\32xl\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .\32xl\:group-hover\:break-all {
    word-break: break-all;
  }
  .\32xl\:w-0 {
    width: 0;
  }
  .\32xl\:w-1 {
    width: 0.25rem;
  }
  .\32xl\:w-2 {
    width: 0.5rem;
  }
  .\32xl\:w-3 {
    width: 0.75rem;
  }
  .\32xl\:w-4 {
    width: 1rem;
  }
  .\32xl\:w-5 {
    width: 1.25rem;
  }
  .\32xl\:w-6 {
    width: 1.5rem;
  }
  .\32xl\:w-7 {
    width: 1.75rem;
  }
  .\32xl\:w-8 {
    width: 2rem;
  }
  .\32xl\:w-9 {
    width: 2.25rem;
  }
  .\32xl\:w-10 {
    width: 2.5rem;
  }
  .\32xl\:w-11 {
    width: 2.75rem;
  }
  .\32xl\:w-12 {
    width: 3rem;
  }
  .\32xl\:w-14 {
    width: 3.5rem;
  }
  .\32xl\:w-16 {
    width: 4rem;
  }
  .\32xl\:w-20 {
    width: 5rem;
  }
  .\32xl\:w-24 {
    width: 6rem;
  }
  .\32xl\:w-28 {
    width: 7rem;
  }
  .\32xl\:w-32 {
    width: 8rem;
  }
  .\32xl\:w-36 {
    width: 9rem;
  }
  .\32xl\:w-40 {
    width: 10rem;
  }
  .\32xl\:w-44 {
    width: 11rem;
  }
  .\32xl\:w-48 {
    width: 12rem;
  }
  .\32xl\:w-52 {
    width: 13rem;
  }
  .\32xl\:w-56 {
    width: 14rem;
  }
  .\32xl\:w-60 {
    width: 15rem;
  }
  .\32xl\:w-64 {
    width: 16rem;
  }
  .\32xl\:w-72 {
    width: 18rem;
  }
  .\32xl\:w-80 {
    width: 20rem;
  }
  .\32xl\:w-96 {
    width: 24rem;
  }
  .\32xl\:w-150 {
    width: 150px;
  }
  .\32xl\:w-199 {
    width: 199px;
  }
  .\32xl\:w-286 {
    width: 286px;
  }
  .\32xl\:w-512 {
    width: 512px;
  }
  .\32xl\:w-560 {
    width: 560px;
  }
  .\32xl\:w-650 {
    width: 650px;
  }
  .\32xl\:w-auto {
    width: auto;
  }
  .\32xl\:w-px {
    width: 1px;
  }
  .\32xl\:w-0\.5 {
    width: 0.125rem;
  }
  .\32xl\:w-1\.5 {
    width: 0.375rem;
  }
  .\32xl\:w-2\.5 {
    width: 0.625rem;
  }
  .\32xl\:w-3\.5 {
    width: 0.875rem;
  }
  .\32xl\:w-1\/2 {
    width: 50%;
  }
  .\32xl\:w-1\/3 {
    width: 33.333333%;
  }
  .\32xl\:w-2\/3 {
    width: 66.666667%;
  }
  .\32xl\:w-1\/4 {
    width: 25%;
  }
  .\32xl\:w-2\/4 {
    width: 50%;
  }
  .\32xl\:w-3\/4 {
    width: 75%;
  }
  .\32xl\:w-1\/5 {
    width: 20%;
  }
  .\32xl\:w-2\/5 {
    width: 40%;
  }
  .\32xl\:w-3\/5 {
    width: 60%;
  }
  .\32xl\:w-4\/5 {
    width: 80%;
  }
  .\32xl\:w-1\/6 {
    width: 16.666667%;
  }
  .\32xl\:w-2\/6 {
    width: 33.333333%;
  }
  .\32xl\:w-3\/6 {
    width: 50%;
  }
  .\32xl\:w-4\/6 {
    width: 66.666667%;
  }
  .\32xl\:w-5\/6 {
    width: 83.333333%;
  }
  .\32xl\:w-1\/12 {
    width: 8.333333%;
  }
  .\32xl\:w-2\/12 {
    width: 16.666667%;
  }
  .\32xl\:w-3\/12 {
    width: 25%;
  }
  .\32xl\:w-4\/12 {
    width: 33.333333%;
  }
  .\32xl\:w-5\/12 {
    width: 41.666667%;
  }
  .\32xl\:w-6\/12 {
    width: 50%;
  }
  .\32xl\:w-7\/12 {
    width: 58.333333%;
  }
  .\32xl\:w-8\/12 {
    width: 66.666667%;
  }
  .\32xl\:w-9\/12 {
    width: 75%;
  }
  .\32xl\:w-10\/12 {
    width: 83.333333%;
  }
  .\32xl\:w-11\/12 {
    width: 91.666667%;
  }
  .\32xl\:w-full {
    width: 100%;
  }
  .\32xl\:w-screen {
    width: 100vw;
  }
  .\32xl\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .\32xl\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .\32xl\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .\32xl\:z-0 {
    z-index: 0;
  }
  .\32xl\:z-10 {
    z-index: 10;
  }
  .\32xl\:z-20 {
    z-index: 20;
  }
  .\32xl\:z-30 {
    z-index: 30;
  }
  .\32xl\:z-40 {
    z-index: 40;
  }
  .\32xl\:z-50 {
    z-index: 50;
  }
  .\32xl\:z-auto {
    z-index: auto;
  }
  .\32xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .\32xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .\32xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .\32xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .\32xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .\32xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .\32xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .\32xl\:focus\:z-0:focus {
    z-index: 0;
  }
  .\32xl\:focus\:z-10:focus {
    z-index: 10;
  }
  .\32xl\:focus\:z-20:focus {
    z-index: 20;
  }
  .\32xl\:focus\:z-30:focus {
    z-index: 30;
  }
  .\32xl\:focus\:z-40:focus {
    z-index: 40;
  }
  .\32xl\:focus\:z-50:focus {
    z-index: 50;
  }
  .\32xl\:focus\:z-auto:focus {
    z-index: auto;
  }
  .\32xl\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .\32xl\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .\32xl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .\32xl\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .\32xl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .\32xl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .\32xl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .\32xl\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .\32xl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .\32xl\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .\32xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .\32xl\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .\32xl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .\32xl\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .\32xl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .\32xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .\32xl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .\32xl\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .\32xl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .\32xl\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .\32xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .\32xl\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .\32xl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .\32xl\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .\32xl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .\32xl\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .\32xl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .\32xl\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .\32xl\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .\32xl\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .\32xl\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .\32xl\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .\32xl\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .\32xl\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .\32xl\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .\32xl\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .\32xl\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .\32xl\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .\32xl\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .\32xl\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .\32xl\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .\32xl\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .\32xl\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .\32xl\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .\32xl\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .\32xl\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .\32xl\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .\32xl\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .\32xl\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .\32xl\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .\32xl\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .\32xl\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .\32xl\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .\32xl\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .\32xl\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .\32xl\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .\32xl\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .\32xl\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .\32xl\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .\32xl\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .\32xl\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .\32xl\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .\32xl\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .\32xl\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .\32xl\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .\32xl\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .\32xl\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .\32xl\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .\32xl\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .\32xl\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .\32xl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .\32xl\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .\32xl\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .\32xl\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .\32xl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .\32xl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .\32xl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .\32xl\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .\32xl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .\32xl\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .\32xl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .\32xl\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .\32xl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .\32xl\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .\32xl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .\32xl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .\32xl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .\32xl\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .\32xl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .\32xl\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .\32xl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .\32xl\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .\32xl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .\32xl\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .\32xl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .\32xl\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .\32xl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .\32xl\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .\32xl\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .\32xl\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .\32xl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .\32xl\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .\32xl\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .\32xl\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .\32xl\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .\32xl\:grid-flow-row {
    grid-auto-flow: row;
  }
  .\32xl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .\32xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .\32xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .\32xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-none {
    grid-template-columns: none;
  }
  .\32xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .\32xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .\32xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .\32xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .\32xl\:col-auto {
    grid-column: auto;
  }
  .\32xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .\32xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .\32xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .\32xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .\32xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .\32xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .\32xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .\32xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .\32xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .\32xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .\32xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .\32xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .\32xl\:col-span-full {
    grid-column: 1/-1;
  }
  .\32xl\:col-start-1 {
    grid-column-start: 1;
  }
  .\32xl\:col-start-2 {
    grid-column-start: 2;
  }
  .\32xl\:col-start-3 {
    grid-column-start: 3;
  }
  .\32xl\:col-start-4 {
    grid-column-start: 4;
  }
  .\32xl\:col-start-5 {
    grid-column-start: 5;
  }
  .\32xl\:col-start-6 {
    grid-column-start: 6;
  }
  .\32xl\:col-start-7 {
    grid-column-start: 7;
  }
  .\32xl\:col-start-8 {
    grid-column-start: 8;
  }
  .\32xl\:col-start-9 {
    grid-column-start: 9;
  }
  .\32xl\:col-start-10 {
    grid-column-start: 10;
  }
  .\32xl\:col-start-11 {
    grid-column-start: 11;
  }
  .\32xl\:col-start-12 {
    grid-column-start: 12;
  }
  .\32xl\:col-start-13 {
    grid-column-start: 13;
  }
  .\32xl\:col-start-auto {
    grid-column-start: auto;
  }
  .\32xl\:col-end-1 {
    grid-column-end: 1;
  }
  .\32xl\:col-end-2 {
    grid-column-end: 2;
  }
  .\32xl\:col-end-3 {
    grid-column-end: 3;
  }
  .\32xl\:col-end-4 {
    grid-column-end: 4;
  }
  .\32xl\:col-end-5 {
    grid-column-end: 5;
  }
  .\32xl\:col-end-6 {
    grid-column-end: 6;
  }
  .\32xl\:col-end-7 {
    grid-column-end: 7;
  }
  .\32xl\:col-end-8 {
    grid-column-end: 8;
  }
  .\32xl\:col-end-9 {
    grid-column-end: 9;
  }
  .\32xl\:col-end-10 {
    grid-column-end: 10;
  }
  .\32xl\:col-end-11 {
    grid-column-end: 11;
  }
  .\32xl\:col-end-12 {
    grid-column-end: 12;
  }
  .\32xl\:col-end-13 {
    grid-column-end: 13;
  }
  .\32xl\:col-end-auto {
    grid-column-end: auto;
  }
  .\32xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-none {
    grid-template-rows: none;
  }
  .\32xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .\32xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .\32xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .\32xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .\32xl\:row-auto {
    grid-row: auto;
  }
  .\32xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .\32xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .\32xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .\32xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .\32xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .\32xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .\32xl\:row-span-full {
    grid-row: 1/-1;
  }
  .\32xl\:row-start-1 {
    grid-row-start: 1;
  }
  .\32xl\:row-start-2 {
    grid-row-start: 2;
  }
  .\32xl\:row-start-3 {
    grid-row-start: 3;
  }
  .\32xl\:row-start-4 {
    grid-row-start: 4;
  }
  .\32xl\:row-start-5 {
    grid-row-start: 5;
  }
  .\32xl\:row-start-6 {
    grid-row-start: 6;
  }
  .\32xl\:row-start-7 {
    grid-row-start: 7;
  }
  .\32xl\:row-start-auto {
    grid-row-start: auto;
  }
  .\32xl\:row-end-1 {
    grid-row-end: 1;
  }
  .\32xl\:row-end-2 {
    grid-row-end: 2;
  }
  .\32xl\:row-end-3 {
    grid-row-end: 3;
  }
  .\32xl\:row-end-4 {
    grid-row-end: 4;
  }
  .\32xl\:row-end-5 {
    grid-row-end: 5;
  }
  .\32xl\:row-end-6 {
    grid-row-end: 6;
  }
  .\32xl\:row-end-7 {
    grid-row-end: 7;
  }
  .\32xl\:row-end-auto {
    grid-row-end: auto;
  }
  .\32xl\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .\32xl\:transform,
  .\32xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .\32xl\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .\32xl\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .\32xl\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .\32xl\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .\32xl\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .\32xl\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .\32xl\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .\32xl\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .\32xl\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .\32xl\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .\32xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .\32xl\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .\32xl\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .\32xl\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .\32xl\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .\32xl\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .\32xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .\32xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .\32xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .\32xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .\32xl\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .\32xl\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .\32xl\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .\32xl\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .\32xl\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .\32xl\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .\32xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .\32xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .\32xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .\32xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .\32xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .\32xl\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .\32xl\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .\32xl\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .\32xl\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .\32xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .\32xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .\32xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .\32xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .\32xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .\32xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .\32xl\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .\32xl\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .\32xl\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .\32xl\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .\32xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .\32xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .\32xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .\32xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .\32xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .\32xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .\32xl\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .\32xl\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .\32xl\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .\32xl\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .\32xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .\32xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .\32xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .\32xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .\32xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .\32xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .\32xl\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .\32xl\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .\32xl\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .\32xl\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .\32xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .\32xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .\32xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .\32xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .\32xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .\32xl\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .\32xl\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .\32xl\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .\32xl\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .\32xl\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .\32xl\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .\32xl\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .\32xl\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .\32xl\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .\32xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .\32xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .\32xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .\32xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .\32xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .\32xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .\32xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .\32xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .\32xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .\32xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .\32xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .\32xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .\32xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .\32xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .\32xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .\32xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .\32xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .\32xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .\32xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .\32xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .\32xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .\32xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .\32xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .\32xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .\32xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .\32xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .\32xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .\32xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .\32xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .\32xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .\32xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .\32xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .\32xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .\32xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .\32xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .\32xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .\32xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .\32xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .\32xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .\32xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .\32xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .\32xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .\32xl\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .\32xl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .\32xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .\32xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .\32xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .\32xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .\32xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .\32xl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .\32xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .\32xl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .\32xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .\32xl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .\32xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .\32xl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .\32xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .\32xl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .\32xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .\32xl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .\32xl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .\32xl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .\32xl\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .\32xl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .\32xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .\32xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .\32xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .\32xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .\32xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .\32xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .\32xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .\32xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .\32xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .\32xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .\32xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .\32xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .\32xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .\32xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .\32xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .\32xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .\32xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .\32xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .\32xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .\32xl\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .\32xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .\32xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .\32xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .\32xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .\32xl\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .\32xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .\32xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .\32xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .\32xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .\32xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .\32xl\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .\32xl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .\32xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .\32xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .\32xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .\32xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .\32xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .\32xl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .\32xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .\32xl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .\32xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .\32xl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .\32xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .\32xl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .\32xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .\32xl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .\32xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .\32xl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .\32xl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .\32xl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .\32xl\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .\32xl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .\32xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .\32xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .\32xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .\32xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .\32xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .\32xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .\32xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .\32xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .\32xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .\32xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .\32xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .\32xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .\32xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .\32xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .\32xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .\32xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .\32xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .\32xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .\32xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .\32xl\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .\32xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .\32xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .\32xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .\32xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .\32xl\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .\32xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .\32xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .\32xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .\32xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .\32xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .\32xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .\32xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .\32xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .\32xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .\32xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .\32xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .\32xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .\32xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .\32xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .\32xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .\32xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .\32xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .\32xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .\32xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .\32xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .\32xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .\32xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .\32xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .\32xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .\32xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .\32xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .\32xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .\32xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .\32xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .\32xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .\32xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .\32xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .\32xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .\32xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .\32xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .\32xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .\32xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .\32xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .\32xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .\32xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .\32xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .\32xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .\32xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .\32xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .\32xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .\32xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .\32xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .\32xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .\32xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .\32xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .\32xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .\32xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .\32xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .\32xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .\32xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .\32xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .\32xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .\32xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .\32xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .\32xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .\32xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .\32xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .\32xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .\32xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .\32xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .\32xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .\32xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .\32xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .\32xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .\32xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .\32xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .\32xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .\32xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .\32xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .\32xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .\32xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .\32xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .\32xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .\32xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .\32xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .\32xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .\32xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .\32xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .\32xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .\32xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .\32xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .\32xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .\32xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .\32xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .\32xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .\32xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .\32xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .\32xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .\32xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .\32xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .\32xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .\32xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .\32xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .\32xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .\32xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .\32xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .\32xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .\32xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .\32xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .\32xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .\32xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .\32xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .\32xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .\32xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .\32xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .\32xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .\32xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .\32xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .\32xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .\32xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .\32xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .\32xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .\32xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .\32xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .\32xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .\32xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .\32xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .\32xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .\32xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .\32xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .\32xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .\32xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .\32xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .\32xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .\32xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .\32xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .\32xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .\32xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .\32xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .\32xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .\32xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .\32xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .\32xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .\32xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .\32xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .\32xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .\32xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .\32xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .\32xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .\32xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .\32xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .\32xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .\32xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .\32xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .\32xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .\32xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .\32xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .\32xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .\32xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .\32xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .\32xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .\32xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .\32xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .\32xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .\32xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .\32xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .\32xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .\32xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .\32xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .\32xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .\32xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .\32xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .\32xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .\32xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .\32xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .\32xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .\32xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .\32xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .\32xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .\32xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .\32xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .\32xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .\32xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .\32xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .\32xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .\32xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .\32xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .\32xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .\32xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .\32xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .\32xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .\32xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .\32xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .\32xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .\32xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .\32xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .\32xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .\32xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .\32xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .\32xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .\32xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .\32xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .\32xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .\32xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .\32xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .\32xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .\32xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .\32xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .\32xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .\32xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .\32xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .\32xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .\32xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .\32xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .\32xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .\32xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .\32xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .\32xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .\32xl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .\32xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .\32xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .\32xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .\32xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .\32xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .\32xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .\32xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .\32xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .\32xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .\32xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .\32xl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .\32xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .\32xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .\32xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .\32xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .\32xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .\32xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .\32xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .\32xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .\32xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .\32xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .\32xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .\32xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .\32xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .\32xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .\32xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .\32xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .\32xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .\32xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .\32xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .\32xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .\32xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .\32xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .\32xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .\32xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .\32xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .\32xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .\32xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .\32xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .\32xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .\32xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .\32xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .\32xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .\32xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .\32xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .\32xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .\32xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .\32xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .\32xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .\32xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .\32xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .\32xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .\32xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .\32xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .\32xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .\32xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .\32xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .\32xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .\32xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .\32xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .\32xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .\32xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .\32xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .\32xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .\32xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .\32xl\:transition-none {
    transition-property: none;
  }
  .\32xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .\32xl\:ease-linear {
    transition-timing-function: linear;
  }
  .\32xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .\32xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .\32xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .\32xl\:duration-75 {
    transition-duration: 75ms;
  }
  .\32xl\:duration-100 {
    transition-duration: 0.1s;
  }
  .\32xl\:duration-150 {
    transition-duration: 0.15s;
  }
  .\32xl\:duration-200 {
    transition-duration: 0.2s;
  }
  .\32xl\:duration-300 {
    transition-duration: 0.3s;
  }
  .\32xl\:duration-500 {
    transition-duration: 0.5s;
  }
  .\32xl\:duration-700 {
    transition-duration: 0.7s;
  }
  .\32xl\:duration-1000 {
    transition-duration: 1s;
  }
  .\32xl\:delay-75 {
    transition-delay: 75ms;
  }
  .\32xl\:delay-100 {
    transition-delay: 0.1s;
  }
  .\32xl\:delay-150 {
    transition-delay: 0.15s;
  }
  .\32xl\:delay-200 {
    transition-delay: 0.2s;
  }
  .\32xl\:delay-300 {
    transition-delay: 0.3s;
  }
  .\32xl\:delay-500 {
    transition-delay: 0.5s;
  }
  .\32xl\:delay-700 {
    transition-delay: 0.7s;
  }
  .\32xl\:delay-1000 {
    transition-delay: 1s;
  }
  .\32xl\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .\32xl\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .\32xl\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .\32xl\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .\32xl\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 260px) {
  .xxs\:container {
    width: 100%;
    max-width: 260px;
  }
  @media (min-width: 360px) {
    .xxs\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .xxs\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .xxs\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .xxs\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .xxs\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .xxs\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .xxs\:container {
      max-width: 1536px;
    }
  }
  .xxs\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .xxs\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxs\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .xxs\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .xxs\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxs\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .xxs\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .xxs\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .xxs\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .xxs\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xxs\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .xxs\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .xxs\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .xxs\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xxs\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .xxs\:prose h3 {
    color: #6d728b;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xxs\:prose h4 {
    color: #6d728b;
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .xxs\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .xxs\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .xxs\:prose code:after,
  .xxs\:prose code:before {
    content: '`';
  }
  .xxs\:prose a code {
    color: #6d728b;
  }
  .xxs\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .xxs\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .xxs\:prose pre code:after,
  .xxs\:prose pre code:before {
    content: '';
  }
  .xxs\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .xxs\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .xxs\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .xxs\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .xxs\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .xxs\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .xxs\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxs\:prose figure,
  .xxs\:prose img,
  .xxs\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxs\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:prose h2 code {
    font-size: 0.875em;
  }
  .xxs\:prose h3 code {
    font-size: 0.9em;
  }
  .xxs\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxs\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xxs\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xxs\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .xxs\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xxs\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .xxs\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xxs\:prose ol ol,
  .xxs\:prose ol ul,
  .xxs\:prose ul ol,
  .xxs\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xxs\:prose h2 + *,
  .xxs\:prose h3 + *,
  .xxs\:prose h4 + *,
  .xxs\:prose hr + * {
    margin-top: 0;
  }
  .xxs\:prose thead th:first-child {
    padding-left: 0;
  }
  .xxs\:prose thead th:last-child {
    padding-right: 0;
  }
  .xxs\:prose tbody td:first-child {
    padding-left: 0;
  }
  .xxs\:prose tbody td:last-child {
    padding-right: 0;
  }
  .xxs\:prose > :first-child {
    margin-top: 0;
  }
  .xxs\:prose > :last-child {
    margin-bottom: 0;
  }
  .xxs\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .xxs\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xxs\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxs\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .xxs\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .xxs\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .xxs\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xxs\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .xxs\:prose-sm figure,
  .xxs\:prose-sm img,
  .xxs\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .xxs\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .xxs\:prose-sm code {
    font-size: 0.8571429em;
  }
  .xxs\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .xxs\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .xxs\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .xxs\:prose-sm ol,
  .xxs\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xxs\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .xxs\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .xxs\:prose-sm ol > li:before {
    left: 0;
  }
  .xxs\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .xxs\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .xxs\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xxs\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xxs\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xxs\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xxs\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xxs\:prose-sm ol ol,
  .xxs\:prose-sm ol ul,
  .xxs\:prose-sm ul ol,
  .xxs\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xxs\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .xxs\:prose-sm h2 + *,
  .xxs\:prose-sm h3 + *,
  .xxs\:prose-sm h4 + *,
  .xxs\:prose-sm hr + * {
    margin-top: 0;
  }
  .xxs\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .xxs\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .xxs\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .xxs\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .xxs\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .xxs\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .xxs\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .xxs\:prose-sm > :first-child {
    margin-top: 0;
  }
  .xxs\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .xxs\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .xxs\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .xxs\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .xxs\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .xxs\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .xxs\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xxs\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xxs\:prose-lg figure,
  .xxs\:prose-lg img,
  .xxs\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .xxs\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .xxs\:prose-lg code {
    font-size: 0.8888889em;
  }
  .xxs\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .xxs\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .xxs\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .xxs\:prose-lg ol,
  .xxs\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xxs\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .xxs\:prose-lg ol > li:before {
    left: 0;
  }
  .xxs\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .xxs\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .xxs\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxs\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxs\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxs\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-lg ol ol,
  .xxs\:prose-lg ol ul,
  .xxs\:prose-lg ul ol,
  .xxs\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxs\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .xxs\:prose-lg h2 + *,
  .xxs\:prose-lg h3 + *,
  .xxs\:prose-lg h4 + *,
  .xxs\:prose-lg hr + * {
    margin-top: 0;
  }
  .xxs\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .xxs\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .xxs\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .xxs\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .xxs\:prose-lg tbody td {
    padding: 0.75em;
  }
  .xxs\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .xxs\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .xxs\:prose-lg > :first-child {
    margin-top: 0;
  }
  .xxs\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .xxs\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .xxs\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxs\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .xxs\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .xxs\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .xxs\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xxs\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .xxs\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xxs\:prose-xl figure,
  .xxs\:prose-xl img,
  .xxs\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxs\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .xxs\:prose-xl code {
    font-size: 0.9em;
  }
  .xxs\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .xxs\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .xxs\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .xxs\:prose-xl ol,
  .xxs\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxs\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .xxs\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .xxs\:prose-xl ol > li:before {
    left: 0;
  }
  .xxs\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .xxs\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .xxs\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xxs\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .xxs\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xxs\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .xxs\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xxs\:prose-xl ol ol,
  .xxs\:prose-xl ol ul,
  .xxs\:prose-xl ul ol,
  .xxs\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xxs\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .xxs\:prose-xl h2 + *,
  .xxs\:prose-xl h3 + *,
  .xxs\:prose-xl h4 + *,
  .xxs\:prose-xl hr + * {
    margin-top: 0;
  }
  .xxs\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .xxs\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .xxs\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .xxs\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .xxs\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .xxs\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .xxs\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .xxs\:prose-xl > :first-child {
    margin-top: 0;
  }
  .xxs\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .xxs\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .xxs\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .xxs\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .xxs\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .xxs\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .xxs\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .xxs\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xxs\:prose-2xl figure,
  .xxs\:prose-2xl img,
  .xxs\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxs\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .xxs\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .xxs\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .xxs\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .xxs\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .xxs\:prose-2xl ol,
  .xxs\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xxs\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .xxs\:prose-2xl ol > li:before {
    left: 0;
  }
  .xxs\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .xxs\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .xxs\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .xxs\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxs\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxs\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxs\:prose-2xl ol ol,
  .xxs\:prose-2xl ol ul,
  .xxs\:prose-2xl ul ol,
  .xxs\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xxs\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xxs\:prose-2xl h2 + *,
  .xxs\:prose-2xl h3 + *,
  .xxs\:prose-2xl h4 + *,
  .xxs\:prose-2xl hr + * {
    margin-top: 0;
  }
  .xxs\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .xxs\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .xxs\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .xxs\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .xxs\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .xxs\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .xxs\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .xxs\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .xxs\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .xxs\:aspect-w-1,
  .xxs\:aspect-w-2,
  .xxs\:aspect-w-3,
  .xxs\:aspect-w-4,
  .xxs\:aspect-w-5,
  .xxs\:aspect-w-6,
  .xxs\:aspect-w-7,
  .xxs\:aspect-w-8,
  .xxs\:aspect-w-9,
  .xxs\:aspect-w-10,
  .xxs\:aspect-w-11,
  .xxs\:aspect-w-12,
  .xxs\:aspect-w-13,
  .xxs\:aspect-w-14,
  .xxs\:aspect-w-15,
  .xxs\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .xxs\:aspect-w-1 > *,
  .xxs\:aspect-w-2 > *,
  .xxs\:aspect-w-3 > *,
  .xxs\:aspect-w-4 > *,
  .xxs\:aspect-w-5 > *,
  .xxs\:aspect-w-6 > *,
  .xxs\:aspect-w-7 > *,
  .xxs\:aspect-w-8 > *,
  .xxs\:aspect-w-9 > *,
  .xxs\:aspect-w-10 > *,
  .xxs\:aspect-w-11 > *,
  .xxs\:aspect-w-12 > *,
  .xxs\:aspect-w-13 > *,
  .xxs\:aspect-w-14 > *,
  .xxs\:aspect-w-15 > *,
  .xxs\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxs\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .xxs\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xxs\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .xxs\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .xxs\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .xxs\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .xxs\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .xxs\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .xxs\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .xxs\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .xxs\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .xxs\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .xxs\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .xxs\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .xxs\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .xxs\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .xxs\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .xxs\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .xxs\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .xxs\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .xxs\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .xxs\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .xxs\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .xxs\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .xxs\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .xxs\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .xxs\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .xxs\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .xxs\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .xxs\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .xxs\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .xxs\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .xxs\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .xxs\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .xxs\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .xxs\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .xxs\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxs\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .xxs\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .xxs\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .xxs\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxs\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .xxs\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxs\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .xxs\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxs\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .xxs\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxs\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .xxs\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxs\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .xxs\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .xxs\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .xxs\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .xxs\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .xxs\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .xxs\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .xxs\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .xxs\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .xxs\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .xxs\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .xxs\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .xxs\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .xxs\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxs\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .xxs\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .xxs\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .xxs\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .xxs\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .xxs\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .xxs\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .xxs\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .xxs\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .xxs\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .xxs\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .xxs\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .xxs\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .xxs\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .xxs\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .xxs\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .xxs\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .xxs\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .xxs\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .xxs\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .xxs\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .xxs\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .xxs\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .xxs\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .xxs\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .xxs\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .xxs\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .xxs\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .xxs\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .xxs\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .xxs\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .xxs\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .xxs\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .xxs\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxs\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .xxs\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .xxs\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xxs\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .xxs\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .xxs\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .xxs\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .xxs\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .xxs\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .xxs\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .xxs\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .xxs\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .xxs\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .xxs\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .xxs\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .xxs\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .xxs\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .xxs\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .xxs\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .xxs\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .xxs\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .xxs\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .xxs\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .xxs\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .xxs\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .xxs\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxs\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxs\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxs\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxs\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxs\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxs\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .xxs\:bg-fixed {
    background-attachment: fixed;
  }
  .xxs\:bg-local {
    background-attachment: local;
  }
  .xxs\:bg-scroll {
    background-attachment: scroll;
  }
  .xxs\:bg-clip-border {
    background-clip: border-box;
  }
  .xxs\:bg-clip-padding {
    background-clip: padding-box;
  }
  .xxs\:bg-clip-content {
    background-clip: content-box;
  }
  .xxs\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .xxs\:bg-transparent {
    background-color: transparent;
  }
  .xxs\:bg-current {
    background-color: currentColor;
  }
  .xxs\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxs\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxs\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxs\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxs\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxs\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxs\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxs\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxs\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxs\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .xxs\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxs\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxs\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxs\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxs\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxs\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxs\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxs\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxs\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxs\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxs\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxs\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxs\:bg-body-background {
    background-color: var(--body-background);
  }
  .xxs\:bg-background {
    background-color: var(--background);
  }
  .xxs\:bg-input-background {
    background-color: var(--input-background);
  }
  .xxs\:bg-body-text {
    background-color: var(--body-text);
  }
  .xxs\:bg-header-text {
    background-color: var(--header-text);
  }
  .xxs\:bg-primary {
    background-color: var(--primary);
  }
  .xxs\:bg-secondary {
    background-color: var(--secondary);
  }
  .xxs\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .xxs\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .xxs\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .xxs\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .xxs\:bg-input-text {
    background-color: var(--input-text);
  }
  .xxs\:bg-success {
    background-color: var(--success);
  }
  .xxs\:bg-error {
    background-color: var(--error);
  }
  .xxs\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .xxs\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxs\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .xxs\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .xxs\:bg-background50 {
    background-color: var(--background50);
  }
  .xxs\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxs\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxs\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .xxs\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .xxs\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxs\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxs\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .xxs\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .xxs\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .xxs\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .xxs\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .xxs\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .xxs\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .xxs\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .xxs\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .xxs\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .xxs\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .xxs\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .xxs\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .xxs\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .xxs\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .xxs\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .xxs\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .xxs\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .xxs\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .xxs\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .xxs\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .xxs\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .xxs\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .xxs\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .xxs\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .xxs\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .xxs\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .xxs\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .xxs\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .xxs\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .xxs\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .xxs\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .xxs\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .xxs\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .xxs\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .xxs\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .xxs\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .xxs\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .xxs\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .xxs\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .xxs\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .xxs\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxs\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .xxs\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .xxs\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .xxs\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .xxs\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .xxs\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .xxs\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .xxs\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .xxs\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .xxs\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .xxs\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .xxs\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .xxs\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .xxs\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .xxs\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .xxs\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .xxs\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .xxs\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .xxs\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .xxs\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .xxs\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .xxs\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxs\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .xxs\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .xxs\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .xxs\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .xxs\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .xxs\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .xxs\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .xxs\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .xxs\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .xxs\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .xxs\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .xxs\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .xxs\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .xxs\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .xxs\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .xxs\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .xxs\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .xxs\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .xxs\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .xxs\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxs\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxs\:bg-none {
    background-image: none;
  }
  .xxs\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .xxs\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .xxs\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .xxs\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .xxs\:from-background,
  .xxs\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-background {
    --tw-gradient-from: var(--background);
  }
  .xxs\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .xxs\:from-body-text,
  .xxs\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .xxs\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .xxs\:from-header-text,
  .xxs\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .xxs\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .xxs\:from-secondary,
  .xxs\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .xxs\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxs\:from-primary-hover,
  .xxs\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxs\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxs\:from-input-text,
  .xxs\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .xxs\:from-success {
    --tw-gradient-from: var(--success);
  }
  .xxs\:from-error,
  .xxs\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-error {
    --tw-gradient-from: var(--error);
  }
  .xxs\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .xxs\:from-skeleton-loader-from,
  .xxs\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxs\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .xxs\:to-current {
    --tw-gradient-to: currentColor;
  }
  .xxs\:to-black {
    --tw-gradient-to: #000;
  }
  .xxs\:to-white {
    --tw-gradient-to: #fff;
  }
  .xxs\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .xxs\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .xxs\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .xxs\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .xxs\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .xxs\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .xxs\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .xxs\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .xxs\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .xxs\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .xxs\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .xxs\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .xxs\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .xxs\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .xxs\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .xxs\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .xxs\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .xxs\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .xxs\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .xxs\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .xxs\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .xxs\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .xxs\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .xxs\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .xxs\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .xxs\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .xxs\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .xxs\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .xxs\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .xxs\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .xxs\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .xxs\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .xxs\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .xxs\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .xxs\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .xxs\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .xxs\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .xxs\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .xxs\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .xxs\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .xxs\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .xxs\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .xxs\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .xxs\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .xxs\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .xxs\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .xxs\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .xxs\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .xxs\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .xxs\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .xxs\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .xxs\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .xxs\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .xxs\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .xxs\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .xxs\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .xxs\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .xxs\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .xxs\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .xxs\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .xxs\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .xxs\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .xxs\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .xxs\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .xxs\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .xxs\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .xxs\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .xxs\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .xxs\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .xxs\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .xxs\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .xxs\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .xxs\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .xxs\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .xxs\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .xxs\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .xxs\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .xxs\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .xxs\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .xxs\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxs\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .xxs\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .xxs\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .xxs\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .xxs\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .xxs\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .xxs\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .xxs\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .xxs\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .xxs\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .xxs\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .xxs\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .xxs\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .xxs\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .xxs\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .xxs\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .xxs\:to-background {
    --tw-gradient-to: var(--background);
  }
  .xxs\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .xxs\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .xxs\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .xxs\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .xxs\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .xxs\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .xxs\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxs\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxs\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxs\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .xxs\:to-success {
    --tw-gradient-to: var(--success);
  }
  .xxs\:to-error {
    --tw-gradient-to: var(--error);
  }
  .xxs\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxs\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .xxs\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .xxs\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxs\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxs\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .xxs\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .xxs\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .xxs\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .xxs\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .xxs\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .xxs\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .xxs\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .xxs\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .xxs\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .xxs\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .xxs\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .xxs\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .xxs\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .xxs\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .xxs\:hover\:from-background:hover,
  .xxs\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .xxs\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .xxs\:hover\:from-body-text:hover,
  .xxs\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .xxs\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .xxs\:hover\:from-header-text:hover,
  .xxs\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .xxs\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .xxs\:hover\:from-secondary:hover,
  .xxs\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .xxs\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxs\:hover\:from-primary-hover:hover,
  .xxs\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxs\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxs\:hover\:from-input-text:hover,
  .xxs\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .xxs\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .xxs\:hover\:from-error:hover,
  .xxs\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .xxs\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxs\:hover\:from-background50:hover,
  .xxs\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .xxs\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .xxs\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .xxs\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .xxs\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .xxs\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .xxs\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .xxs\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .xxs\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .xxs\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .xxs\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .xxs\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .xxs\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .xxs\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .xxs\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .xxs\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .xxs\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .xxs\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .xxs\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .xxs\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .xxs\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .xxs\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .xxs\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .xxs\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .xxs\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .xxs\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .xxs\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .xxs\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .xxs\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .xxs\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .xxs\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .xxs\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .xxs\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .xxs\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .xxs\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .xxs\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .xxs\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .xxs\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .xxs\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .xxs\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .xxs\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .xxs\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .xxs\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .xxs\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .xxs\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .xxs\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .xxs\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .xxs\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .xxs\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .xxs\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .xxs\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .xxs\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .xxs\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .xxs\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .xxs\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .xxs\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .xxs\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .xxs\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .xxs\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .xxs\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .xxs\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .xxs\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .xxs\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .xxs\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .xxs\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .xxs\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .xxs\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .xxs\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .xxs\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .xxs\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .xxs\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .xxs\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .xxs\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .xxs\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .xxs\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .xxs\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .xxs\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .xxs\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .xxs\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .xxs\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .xxs\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .xxs\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .xxs\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .xxs\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .xxs\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxs\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .xxs\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .xxs\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .xxs\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .xxs\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .xxs\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .xxs\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .xxs\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .xxs\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .xxs\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .xxs\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .xxs\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .xxs\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .xxs\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .xxs\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .xxs\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .xxs\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .xxs\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .xxs\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .xxs\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .xxs\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .xxs\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .xxs\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .xxs\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxs\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxs\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxs\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .xxs\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .xxs\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .xxs\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxs\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .xxs\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .xxs\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxs\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxs\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .xxs\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .xxs\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .xxs\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .xxs\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .xxs\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .xxs\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .xxs\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .xxs\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .xxs\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .xxs\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .xxs\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .xxs\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .xxs\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .xxs\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxs\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .xxs\:focus\:from-background:focus,
  .xxs\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .xxs\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .xxs\:focus\:from-body-text:focus,
  .xxs\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .xxs\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .xxs\:focus\:from-header-text:focus,
  .xxs\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .xxs\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .xxs\:focus\:from-secondary:focus,
  .xxs\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .xxs\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxs\:focus\:from-primary-hover:focus,
  .xxs\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxs\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxs\:focus\:from-input-text:focus,
  .xxs\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .xxs\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .xxs\:focus\:from-error:focus,
  .xxs\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .xxs\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxs\:focus\:from-background50:focus,
  .xxs\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .xxs\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxs\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxs\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxs\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxs\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxs\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxs\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxs\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxs\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxs\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxs\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxs\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxs\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxs\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxs\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxs\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxs\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxs\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxs\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxs\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxs\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxs\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxs\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxs\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxs\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxs\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxs\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxs\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxs\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxs\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxs\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxs\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxs\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxs\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxs\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxs\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxs\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxs\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxs\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxs\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxs\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxs\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxs\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxs\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxs\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxs\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxs\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxs\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxs\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxs\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxs\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxs\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxs\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxs\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxs\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxs\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxs\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxs\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxs\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxs\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxs\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxs\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxs\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxs\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxs\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxs\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxs\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxs\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxs\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxs\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxs\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxs\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxs\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxs\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxs\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxs\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxs\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxs\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxs\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxs\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxs\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxs\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxs\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxs\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxs\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxs\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxs\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxs\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxs\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxs\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxs\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxs\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxs\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxs\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxs\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxs\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxs\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxs\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxs\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxs\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxs\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxs\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxs\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxs\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxs\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxs\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxs\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxs\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxs\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxs\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxs\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .xxs\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .xxs\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .xxs\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .xxs\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .xxs\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .xxs\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .xxs\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .xxs\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .xxs\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .xxs\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .xxs\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .xxs\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .xxs\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .xxs\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .xxs\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .xxs\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .xxs\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .xxs\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .xxs\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .xxs\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .xxs\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .xxs\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .xxs\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .xxs\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .xxs\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .xxs\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .xxs\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .xxs\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .xxs\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .xxs\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .xxs\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .xxs\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .xxs\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .xxs\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .xxs\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .xxs\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .xxs\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .xxs\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .xxs\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .xxs\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .xxs\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .xxs\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .xxs\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .xxs\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .xxs\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .xxs\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .xxs\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .xxs\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .xxs\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .xxs\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .xxs\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .xxs\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .xxs\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .xxs\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .xxs\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .xxs\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .xxs\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .xxs\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .xxs\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .xxs\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .xxs\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .xxs\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .xxs\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .xxs\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .xxs\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .xxs\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .xxs\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .xxs\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .xxs\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .xxs\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .xxs\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .xxs\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .xxs\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .xxs\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .xxs\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .xxs\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .xxs\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .xxs\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .xxs\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .xxs\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .xxs\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .xxs\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .xxs\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .xxs\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .xxs\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .xxs\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .xxs\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .xxs\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .xxs\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .xxs\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .xxs\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .xxs\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .xxs\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .xxs\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .xxs\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .xxs\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .xxs\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .xxs\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .xxs\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .xxs\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .xxs\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .xxs\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .xxs\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .xxs\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .xxs\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .xxs\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxs\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxs\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxs\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .xxs\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .xxs\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .xxs\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxs\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .xxs\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .xxs\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxs\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxs\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .xxs\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .xxs\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .xxs\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .xxs\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .xxs\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .xxs\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .xxs\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .xxs\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .xxs\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .xxs\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .xxs\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .xxs\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xxs\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .xxs\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .xxs\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .xxs\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .xxs\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .xxs\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .xxs\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .xxs\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .xxs\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .xxs\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .xxs\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .xxs\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .xxs\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .xxs\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .xxs\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .xxs\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .xxs\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .xxs\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .xxs\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .xxs\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .xxs\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .xxs\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .xxs\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .xxs\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .xxs\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .xxs\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .xxs\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .xxs\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .xxs\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .xxs\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .xxs\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .xxs\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .xxs\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .xxs\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .xxs\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .xxs\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .xxs\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .xxs\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .xxs\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .xxs\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .xxs\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .xxs\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .xxs\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .xxs\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .xxs\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .xxs\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .xxs\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .xxs\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .xxs\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .xxs\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .xxs\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .xxs\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .xxs\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .xxs\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .xxs\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .xxs\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .xxs\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .xxs\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .xxs\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .xxs\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .xxs\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .xxs\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .xxs\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .xxs\:bg-bottom {
    background-position: bottom;
  }
  .xxs\:bg-center {
    background-position: 50%;
  }
  .xxs\:bg-left {
    background-position: 0;
  }
  .xxs\:bg-left-bottom {
    background-position: 0 100%;
  }
  .xxs\:bg-left-top {
    background-position: 0 0;
  }
  .xxs\:bg-right {
    background-position: 100%;
  }
  .xxs\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .xxs\:bg-right-top {
    background-position: 100% 0;
  }
  .xxs\:bg-top {
    background-position: top;
  }
  .xxs\:bg-repeat {
    background-repeat: repeat;
  }
  .xxs\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .xxs\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .xxs\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .xxs\:bg-repeat-round {
    background-repeat: round;
  }
  .xxs\:bg-repeat-space {
    background-repeat: space;
  }
  .xxs\:bg-auto {
    background-size: auto;
  }
  .xxs\:bg-cover {
    background-size: cover;
  }
  .xxs\:bg-contain {
    background-size: contain;
  }
  .xxs\:border-collapse {
    border-collapse: collapse;
  }
  .xxs\:border-separate {
    border-collapse: separate;
  }
  .xxs\:border-transparent {
    border-color: transparent;
  }
  .xxs\:border-current {
    border-color: currentColor;
  }
  .xxs\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxs\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxs\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxs\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxs\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxs\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxs\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxs\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxs\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxs\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxs\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxs\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxs\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxs\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxs\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxs\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxs\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxs\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxs\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxs\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxs\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxs\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxs\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxs\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxs\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxs\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxs\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxs\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxs\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxs\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxs\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxs\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxs\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxs\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxs\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxs\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxs\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxs\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxs\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxs\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxs\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxs\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxs\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxs\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxs\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxs\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxs\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxs\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxs\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxs\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxs\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxs\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxs\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxs\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxs\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxs\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxs\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxs\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxs\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxs\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxs\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxs\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxs\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:border-background-primary {
    border-color: var(--background-primary);
  }
  .xxs\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxs\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxs\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxs\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxs\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxs\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxs\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxs\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxs\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxs\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxs\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxs\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxs\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxs\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxs\:border-body-background {
    border-color: var(--body-background);
  }
  .xxs\:border-background {
    border-color: var(--background);
  }
  .xxs\:border-input-background {
    border-color: var(--input-background);
  }
  .xxs\:border-body-text {
    border-color: var(--body-text);
  }
  .xxs\:border-header-text {
    border-color: var(--header-text);
  }
  .xxs\:border-primary {
    border-color: var(--primary);
  }
  .xxs\:border-secondary {
    border-color: var(--secondary);
  }
  .xxs\:border-tertiary {
    border-color: var(--tertiary);
  }
  .xxs\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .xxs\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .xxs\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .xxs\:border-input-text {
    border-color: var(--input-text);
  }
  .xxs\:border-success {
    border-color: var(--success);
  }
  .xxs\:border-error {
    border-color: var(--error);
  }
  .xxs\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .xxs\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxs\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .xxs\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .xxs\:border-background50 {
    border-color: var(--background50);
  }
  .xxs\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxs\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxs\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxs\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxs\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxs\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxs\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxs\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxs\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxs\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .xxs\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .xxs\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxs\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxs\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .xxs\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .xxs\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .xxs\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .xxs\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .xxs\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .xxs\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .xxs\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .xxs\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .xxs\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .xxs\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .xxs\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .xxs\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .xxs\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .xxs\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .xxs\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .xxs\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .xxs\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .xxs\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .xxs\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .xxs\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .xxs\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .xxs\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .xxs\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .xxs\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .xxs\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .xxs\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .xxs\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .xxs\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .xxs\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .xxs\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .xxs\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .xxs\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .xxs\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .xxs\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .xxs\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .xxs\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .xxs\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .xxs\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .xxs\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .xxs\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .xxs\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxs\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .xxs\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .xxs\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .xxs\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .xxs\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .xxs\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .xxs\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .xxs\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .xxs\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .xxs\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .xxs\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .xxs\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .xxs\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .xxs\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .xxs\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .xxs\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .xxs\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .xxs\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .xxs\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .xxs\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .xxs\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .xxs\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxs\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .xxs\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .xxs\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .xxs\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .xxs\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .xxs\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .xxs\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .xxs\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .xxs\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .xxs\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .xxs\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .xxs\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .xxs\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .xxs\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .xxs\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .xxs\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .xxs\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .xxs\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .xxs\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .xxs\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxs\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxs\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .xxs\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .xxs\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .xxs\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .xxs\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .xxs\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .xxs\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .xxs\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .xxs\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .xxs\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .xxs\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .xxs\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .xxs\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .xxs\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .xxs\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .xxs\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .xxs\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .xxs\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .xxs\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .xxs\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .xxs\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .xxs\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .xxs\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .xxs\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .xxs\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .xxs\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .xxs\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .xxs\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .xxs\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .xxs\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .xxs\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .xxs\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .xxs\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .xxs\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .xxs\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .xxs\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .xxs\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .xxs\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .xxs\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .xxs\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .xxs\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .xxs\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .xxs\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .xxs\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .xxs\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .xxs\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .xxs\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .xxs\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .xxs\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .xxs\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .xxs\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .xxs\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .xxs\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .xxs\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .xxs\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .xxs\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .xxs\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .xxs\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .xxs\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .xxs\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .xxs\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .xxs\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .xxs\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .xxs\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .xxs\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .xxs\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .xxs\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .xxs\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .xxs\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .xxs\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .xxs\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .xxs\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .xxs\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .xxs\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .xxs\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .xxs\:rounded-none {
    border-radius: 0;
  }
  .xxs\:rounded-sm {
    border-radius: 0.125rem;
  }
  .xxs\:rounded {
    border-radius: 0.25rem;
  }
  .xxs\:rounded-md {
    border-radius: 0.375rem;
  }
  .xxs\:rounded-lg {
    border-radius: 0.5rem;
  }
  .xxs\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xxs\:rounded-2xl {
    border-radius: 1rem;
  }
  .xxs\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .xxs\:rounded-full {
    border-radius: 9999px;
  }
  .xxs\:rounded-card {
    border-radius: 20px;
  }
  .xxs\:rounded-time-item {
    border-radius: 100px;
  }
  .xxs\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .xxs\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .xxs\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xxs\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xxs\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .xxs\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .xxs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xxs\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xxs\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .xxs\:rounded-r {
    border-top-right-radius: 0.25rem;
  }
  .xxs\:rounded-b,
  .xxs\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .xxs\:rounded-b,
  .xxs\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .xxs\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .xxs\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .xxs\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .xxs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xxs\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xxs\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .xxs\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .xxs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xxs\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xxs\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .xxs\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .xxs\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xxs\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xxs\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .xxs\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .xxs\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xxs\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xxs\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .xxs\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .xxs\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xxs\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xxs\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .xxs\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .xxs\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xxs\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xxs\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .xxs\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .xxs\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xxs\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xxs\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .xxs\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .xxs\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xxs\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xxs\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .xxs\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .xxs\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .xxs\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .xxs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .xxs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .xxs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .xxs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .xxs\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .xxs\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .xxs\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .xxs\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .xxs\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .xxs\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .xxs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .xxs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .xxs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .xxs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .xxs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .xxs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .xxs\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .xxs\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .xxs\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .xxs\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .xxs\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .xxs\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .xxs\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .xxs\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .xxs\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .xxs\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .xxs\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .xxs\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .xxs\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .xxs\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .xxs\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .xxs\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .xxs\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .xxs\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .xxs\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .xxs\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .xxs\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .xxs\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .xxs\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .xxs\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .xxs\:border-solid {
    border-style: solid;
  }
  .xxs\:border-dashed {
    border-style: dashed;
  }
  .xxs\:border-dotted {
    border-style: dotted;
  }
  .xxs\:border-double {
    border-style: double;
  }
  .xxs\:border-none {
    border-style: none;
  }
  .xxs\:border-0 {
    border-width: 0;
  }
  .xxs\:border-2 {
    border-width: 2px;
  }
  .xxs\:border-4 {
    border-width: 4px;
  }
  .xxs\:border-8 {
    border-width: 8px;
  }
  .xxs\:border {
    border-width: 1px;
  }
  .xxs\:border-t-0 {
    border-top-width: 0;
  }
  .xxs\:border-r-0 {
    border-right-width: 0;
  }
  .xxs\:border-b-0 {
    border-bottom-width: 0;
  }
  .xxs\:border-l-0 {
    border-left-width: 0;
  }
  .xxs\:border-t-2 {
    border-top-width: 2px;
  }
  .xxs\:border-r-2 {
    border-right-width: 2px;
  }
  .xxs\:border-b-2 {
    border-bottom-width: 2px;
  }
  .xxs\:border-l-2 {
    border-left-width: 2px;
  }
  .xxs\:border-t-4 {
    border-top-width: 4px;
  }
  .xxs\:border-r-4 {
    border-right-width: 4px;
  }
  .xxs\:border-b-4 {
    border-bottom-width: 4px;
  }
  .xxs\:border-l-4 {
    border-left-width: 4px;
  }
  .xxs\:border-t-8 {
    border-top-width: 8px;
  }
  .xxs\:border-r-8 {
    border-right-width: 8px;
  }
  .xxs\:border-b-8 {
    border-bottom-width: 8px;
  }
  .xxs\:border-l-8 {
    border-left-width: 8px;
  }
  .xxs\:border-t {
    border-top-width: 1px;
  }
  .xxs\:border-r {
    border-right-width: 1px;
  }
  .xxs\:border-b {
    border-bottom-width: 1px;
  }
  .xxs\:border-l {
    border-left-width: 1px;
  }
  .xxs\:box-border {
    box-sizing: border-box;
  }
  .xxs\:box-content {
    box-sizing: content-box;
  }
  .xxs\:cursor-auto {
    cursor: auto;
  }
  .xxs\:cursor-default {
    cursor: default;
  }
  .xxs\:cursor-pointer {
    cursor: pointer;
  }
  .xxs\:cursor-wait {
    cursor: wait;
  }
  .xxs\:cursor-text {
    cursor: text;
  }
  .xxs\:cursor-move {
    cursor: move;
  }
  .xxs\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .xxs\:block {
    display: block;
  }
  .xxs\:inline-block {
    display: inline-block;
  }
  .xxs\:inline {
    display: inline;
  }
  .xxs\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xxs\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .xxs\:table {
    display: table;
  }
  .xxs\:table-caption {
    display: table-caption;
  }
  .xxs\:table-cell {
    display: table-cell;
  }
  .xxs\:table-column {
    display: table-column;
  }
  .xxs\:table-column-group {
    display: table-column-group;
  }
  .xxs\:table-footer-group {
    display: table-footer-group;
  }
  .xxs\:table-header-group {
    display: table-header-group;
  }
  .xxs\:table-row-group {
    display: table-row-group;
  }
  .xxs\:table-row {
    display: table-row;
  }
  .xxs\:flow-root {
    display: flow-root;
  }
  .xxs\:grid {
    display: grid;
  }
  .xxs\:inline-grid {
    display: inline-grid;
  }
  .xxs\:contents {
    display: contents;
  }
  .xxs\:hidden {
    display: none;
  }
  .xxs\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .xxs\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .xxs\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xxs\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .xxs\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xxs\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .xxs\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .xxs\:place-items-auto {
    place-items: auto;
  }
  .xxs\:place-items-start {
    place-items: start;
  }
  .xxs\:place-items-end {
    place-items: end;
  }
  .xxs\:place-items-center {
    place-items: center;
  }
  .xxs\:place-items-stretch {
    place-items: stretch;
  }
  .xxs\:place-content-center {
    place-content: center;
  }
  .xxs\:place-content-start {
    place-content: start;
  }
  .xxs\:place-content-end {
    place-content: end;
  }
  .xxs\:place-content-between {
    place-content: space-between;
  }
  .xxs\:place-content-around {
    place-content: space-around;
  }
  .xxs\:place-content-evenly {
    place-content: space-evenly;
  }
  .xxs\:place-content-stretch {
    place-content: stretch;
  }
  .xxs\:place-self-auto {
    place-self: auto;
  }
  .xxs\:place-self-start {
    place-self: start;
  }
  .xxs\:place-self-end {
    place-self: end;
  }
  .xxs\:place-self-center {
    place-self: center;
  }
  .xxs\:place-self-stretch {
    place-self: stretch;
  }
  .xxs\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .xxs\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .xxs\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .xxs\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .xxs\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .xxs\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .xxs\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .xxs\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .xxs\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .xxs\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .xxs\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .xxs\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .xxs\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xxs\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xxs\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xxs\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .xxs\:justify-items-auto {
    justify-items: auto;
  }
  .xxs\:justify-items-start {
    justify-items: start;
  }
  .xxs\:justify-items-end {
    justify-items: end;
  }
  .xxs\:justify-items-center {
    justify-items: center;
  }
  .xxs\:justify-items-stretch {
    justify-items: stretch;
  }
  .xxs\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .xxs\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .xxs\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .xxs\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .xxs\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .xxs\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .xxs\:justify-self-auto {
    justify-self: auto;
  }
  .xxs\:justify-self-start {
    justify-self: start;
  }
  .xxs\:justify-self-end {
    justify-self: end;
  }
  .xxs\:justify-self-center {
    justify-self: center;
  }
  .xxs\:justify-self-stretch {
    justify-self: stretch;
  }
  .xxs\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .xxs\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .xxs\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .xxs\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .xxs\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .xxs\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .xxs\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .xxs\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .xxs\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .xxs\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .xxs\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .xxs\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .xxs\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .xxs\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .xxs\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .xxs\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .xxs\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .xxs\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .xxs\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .xxs\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .xxs\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .xxs\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .xxs\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .xxs\:float-right {
    float: right;
  }
  .xxs\:float-left {
    float: left;
  }
  .xxs\:float-none {
    float: none;
  }
  .xxs\:clear-left {
    clear: left;
  }
  .xxs\:clear-right {
    clear: right;
  }
  .xxs\:clear-both {
    clear: both;
  }
  .xxs\:clear-none {
    clear: none;
  }
  .xxs\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .xxs\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .xxs\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .xxs\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .xxs\:font-text {
    font-family: Bozon, sans-serif;
  }
  .xxs\:font-thin {
    font-weight: 100;
  }
  .xxs\:font-extralight {
    font-weight: 200;
  }
  .xxs\:font-light {
    font-weight: 300;
  }
  .xxs\:font-normal {
    font-weight: 400;
  }
  .xxs\:font-medium {
    font-weight: 500;
  }
  .xxs\:font-semibold {
    font-weight: 600;
  }
  .xxs\:font-bold {
    font-weight: 700;
  }
  .xxs\:font-extrabold {
    font-weight: 800;
  }
  .xxs\:font-black {
    font-weight: 900;
  }
  .xxs\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .xxs\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .xxs\:hover\:font-light:hover {
    font-weight: 300;
  }
  .xxs\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .xxs\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .xxs\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .xxs\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .xxs\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .xxs\:hover\:font-black:hover {
    font-weight: 900;
  }
  .xxs\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .xxs\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .xxs\:focus\:font-light:focus {
    font-weight: 300;
  }
  .xxs\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .xxs\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .xxs\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .xxs\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .xxs\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .xxs\:focus\:font-black:focus {
    font-weight: 900;
  }
  .xxs\:h-0 {
    height: 0;
  }
  .xxs\:h-1 {
    height: 0.25rem;
  }
  .xxs\:h-2 {
    height: 0.5rem;
  }
  .xxs\:h-3 {
    height: 0.75rem;
  }
  .xxs\:h-4 {
    height: 1rem;
  }
  .xxs\:h-5 {
    height: 1.25rem;
  }
  .xxs\:h-6 {
    height: 1.5rem;
  }
  .xxs\:h-7 {
    height: 1.75rem;
  }
  .xxs\:h-8 {
    height: 2rem;
  }
  .xxs\:h-9 {
    height: 2.25rem;
  }
  .xxs\:h-10 {
    height: 2.5rem;
  }
  .xxs\:h-11 {
    height: 2.75rem;
  }
  .xxs\:h-12 {
    height: 3rem;
  }
  .xxs\:h-14 {
    height: 3.5rem;
  }
  .xxs\:h-16 {
    height: 4rem;
  }
  .xxs\:h-20 {
    height: 5rem;
  }
  .xxs\:h-24 {
    height: 6rem;
  }
  .xxs\:h-28 {
    height: 7rem;
  }
  .xxs\:h-32 {
    height: 8rem;
  }
  .xxs\:h-36 {
    height: 9rem;
  }
  .xxs\:h-40 {
    height: 10rem;
  }
  .xxs\:h-44 {
    height: 11rem;
  }
  .xxs\:h-48 {
    height: 12rem;
  }
  .xxs\:h-52 {
    height: 13rem;
  }
  .xxs\:h-56 {
    height: 14rem;
  }
  .xxs\:h-60 {
    height: 15rem;
  }
  .xxs\:h-64 {
    height: 16rem;
  }
  .xxs\:h-72 {
    height: 18rem;
  }
  .xxs\:h-80 {
    height: 20rem;
  }
  .xxs\:h-96 {
    height: 24rem;
  }
  .xxs\:h-105 {
    height: 105px;
  }
  .xxs\:h-188 {
    height: 188px;
  }
  .xxs\:h-344 {
    height: 344px;
  }
  .xxs\:h-425 {
    height: 425px;
  }
  .xxs\:h-627 {
    height: 627px;
  }
  .xxs\:h-auto {
    height: auto;
  }
  .xxs\:h-px {
    height: 1px;
  }
  .xxs\:h-0\.5 {
    height: 0.125rem;
  }
  .xxs\:h-1\.5 {
    height: 0.375rem;
  }
  .xxs\:h-2\.5 {
    height: 0.625rem;
  }
  .xxs\:h-3\.5 {
    height: 0.875rem;
  }
  .xxs\:h-1\/2 {
    height: 50%;
  }
  .xxs\:h-1\/3 {
    height: 33.333333%;
  }
  .xxs\:h-2\/3 {
    height: 66.666667%;
  }
  .xxs\:h-1\/4 {
    height: 25%;
  }
  .xxs\:h-2\/4 {
    height: 50%;
  }
  .xxs\:h-3\/4 {
    height: 75%;
  }
  .xxs\:h-1\/5 {
    height: 20%;
  }
  .xxs\:h-2\/5 {
    height: 40%;
  }
  .xxs\:h-3\/5 {
    height: 60%;
  }
  .xxs\:h-4\/5 {
    height: 80%;
  }
  .xxs\:h-1\/6 {
    height: 16.666667%;
  }
  .xxs\:h-2\/6 {
    height: 33.333333%;
  }
  .xxs\:h-3\/6 {
    height: 50%;
  }
  .xxs\:h-4\/6 {
    height: 66.666667%;
  }
  .xxs\:h-5\/6 {
    height: 83.333333%;
  }
  .xxs\:h-full {
    height: 100%;
  }
  .xxs\:h-screen {
    height: 100vh;
  }
  .xxs\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .xxs\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .xxs\:text-base {
    font-size: 1em;
  }
  .xxs\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xxs\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .xxs\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .xxs\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xxs\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xxs\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xxs\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .xxs\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .xxs\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .xxs\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .xxs\:text-m-sm {
    font-size: 0.8em;
  }
  .xxs\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .xxs\:text-m-xl {
    font-size: 1.2em;
  }
  .xxs\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .xxs\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .xxs\:text-m-4xl {
    font-size: 1.6em;
  }
  .xxs\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .xxs\:text-d-sm {
    font-size: 0.875em;
  }
  .xxs\:text-d-lg {
    font-size: 1.125em;
  }
  .xxs\:text-d-xl {
    font-size: 1.375em;
  }
  .xxs\:text-d-2xl {
    font-size: 1.625em;
  }
  .xxs\:text-d-3xl {
    font-size: 1.75em;
  }
  .xxs\:text-d-4xl {
    font-size: 2.25em;
  }
  .xxs\:text-d-5xl {
    font-size: 2.625em;
  }
  .xxs\:text-d-11xl {
    font-size: 16em;
  }
  .xxs\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .xxs\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .xxs\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .xxs\:leading-3 {
    line-height: 0.75rem;
  }
  .xxs\:leading-4 {
    line-height: 1rem;
  }
  .xxs\:leading-5 {
    line-height: 1.25rem;
  }
  .xxs\:leading-6 {
    line-height: 1.5rem;
  }
  .xxs\:leading-7 {
    line-height: 1.75rem;
  }
  .xxs\:leading-8 {
    line-height: 2rem;
  }
  .xxs\:leading-9 {
    line-height: 2.25rem;
  }
  .xxs\:leading-10 {
    line-height: 2.5rem;
  }
  .xxs\:leading-none {
    line-height: 1;
  }
  .xxs\:leading-tight {
    line-height: 1.25;
  }
  .xxs\:leading-snug {
    line-height: 1.375;
  }
  .xxs\:leading-normal {
    line-height: 1.5;
  }
  .xxs\:leading-relaxed {
    line-height: 1.625;
  }
  .xxs\:leading-loose {
    line-height: 2;
  }
  .xxs\:list-inside {
    list-style-position: inside;
  }
  .xxs\:list-outside {
    list-style-position: outside;
  }
  .xxs\:list-none {
    list-style-type: none;
  }
  .xxs\:list-disc {
    list-style-type: disc;
  }
  .xxs\:list-decimal {
    list-style-type: decimal;
  }
  .xxs\:m-0 {
    margin: 0;
  }
  .xxs\:m-1 {
    margin: 0.25rem;
  }
  .xxs\:m-2 {
    margin: 0.5rem;
  }
  .xxs\:m-3 {
    margin: 0.75rem;
  }
  .xxs\:m-4 {
    margin: 1rem;
  }
  .xxs\:m-5 {
    margin: 1.25rem;
  }
  .xxs\:m-6 {
    margin: 1.5rem;
  }
  .xxs\:m-7 {
    margin: 1.75rem;
  }
  .xxs\:m-8 {
    margin: 2rem;
  }
  .xxs\:m-9 {
    margin: 2.25rem;
  }
  .xxs\:m-10 {
    margin: 2.5rem;
  }
  .xxs\:m-11 {
    margin: 2.75rem;
  }
  .xxs\:m-12 {
    margin: 3rem;
  }
  .xxs\:m-14 {
    margin: 3.5rem;
  }
  .xxs\:m-16 {
    margin: 4rem;
  }
  .xxs\:m-20 {
    margin: 5rem;
  }
  .xxs\:m-24 {
    margin: 6rem;
  }
  .xxs\:m-28 {
    margin: 7rem;
  }
  .xxs\:m-32 {
    margin: 8rem;
  }
  .xxs\:m-36 {
    margin: 9rem;
  }
  .xxs\:m-40 {
    margin: 10rem;
  }
  .xxs\:m-44 {
    margin: 11rem;
  }
  .xxs\:m-48 {
    margin: 12rem;
  }
  .xxs\:m-52 {
    margin: 13rem;
  }
  .xxs\:m-56 {
    margin: 14rem;
  }
  .xxs\:m-60 {
    margin: 15rem;
  }
  .xxs\:m-64 {
    margin: 16rem;
  }
  .xxs\:m-72 {
    margin: 18rem;
  }
  .xxs\:m-80 {
    margin: 20rem;
  }
  .xxs\:m-96 {
    margin: 24rem;
  }
  .xxs\:m-auto {
    margin: auto;
  }
  .xxs\:m-px {
    margin: 1px;
  }
  .xxs\:m-0\.5 {
    margin: 0.125rem;
  }
  .xxs\:m-1\.5 {
    margin: 0.375rem;
  }
  .xxs\:m-2\.5 {
    margin: 0.625rem;
  }
  .xxs\:m-3\.5 {
    margin: 0.875rem;
  }
  .xxs\:-m-0 {
    margin: 0;
  }
  .xxs\:-m-1 {
    margin: -0.25rem;
  }
  .xxs\:-m-2 {
    margin: -0.5rem;
  }
  .xxs\:-m-3 {
    margin: -0.75rem;
  }
  .xxs\:-m-4 {
    margin: -1rem;
  }
  .xxs\:-m-5 {
    margin: -1.25rem;
  }
  .xxs\:-m-6 {
    margin: -1.5rem;
  }
  .xxs\:-m-7 {
    margin: -1.75rem;
  }
  .xxs\:-m-8 {
    margin: -2rem;
  }
  .xxs\:-m-9 {
    margin: -2.25rem;
  }
  .xxs\:-m-10 {
    margin: -2.5rem;
  }
  .xxs\:-m-11 {
    margin: -2.75rem;
  }
  .xxs\:-m-12 {
    margin: -3rem;
  }
  .xxs\:-m-14 {
    margin: -3.5rem;
  }
  .xxs\:-m-16 {
    margin: -4rem;
  }
  .xxs\:-m-20 {
    margin: -5rem;
  }
  .xxs\:-m-24 {
    margin: -6rem;
  }
  .xxs\:-m-28 {
    margin: -7rem;
  }
  .xxs\:-m-32 {
    margin: -8rem;
  }
  .xxs\:-m-36 {
    margin: -9rem;
  }
  .xxs\:-m-40 {
    margin: -10rem;
  }
  .xxs\:-m-44 {
    margin: -11rem;
  }
  .xxs\:-m-48 {
    margin: -12rem;
  }
  .xxs\:-m-52 {
    margin: -13rem;
  }
  .xxs\:-m-56 {
    margin: -14rem;
  }
  .xxs\:-m-60 {
    margin: -15rem;
  }
  .xxs\:-m-64 {
    margin: -16rem;
  }
  .xxs\:-m-72 {
    margin: -18rem;
  }
  .xxs\:-m-80 {
    margin: -20rem;
  }
  .xxs\:-m-96 {
    margin: -24rem;
  }
  .xxs\:-m-px {
    margin: -1px;
  }
  .xxs\:-m-0\.5 {
    margin: -0.125rem;
  }
  .xxs\:-m-1\.5 {
    margin: -0.375rem;
  }
  .xxs\:-m-2\.5 {
    margin: -0.625rem;
  }
  .xxs\:-m-3\.5 {
    margin: -0.875rem;
  }
  .xxs\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xxs\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xxs\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xxs\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xxs\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xxs\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xxs\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xxs\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xxs\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xxs\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xxs\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xxs\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xxs\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xxs\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xxs\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xxs\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xxs\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xxs\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xxs\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xxs\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .xxs\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .xxs\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .xxs\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .xxs\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xxs\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xxs\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .xxs\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .xxs\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xxs\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xxs\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .xxs\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .xxs\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .xxs\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .xxs\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .xxs\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .xxs\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xxs\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xxs\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .xxs\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .xxs\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .xxs\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .xxs\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .xxs\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .xxs\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .xxs\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .xxs\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .xxs\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .xxs\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .xxs\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .xxs\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .xxs\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .xxs\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .xxs\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .xxs\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .xxs\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .xxs\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .xxs\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .xxs\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .xxs\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .xxs\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xxs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xxs\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .xxs\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .xxs\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .xxs\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .xxs\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xxs\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xxs\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xxs\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xxs\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xxs\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xxs\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxs\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xxs\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .xxs\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .xxs\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .xxs\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xxs\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .xxs\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .xxs\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xxs\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xxs\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .xxs\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .xxs\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .xxs\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .xxs\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .xxs\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .xxs\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .xxs\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xxs\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .xxs\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .xxs\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .xxs\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .xxs\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .xxs\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .xxs\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .xxs\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .xxs\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .xxs\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .xxs\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .xxs\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xxs\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .xxs\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .xxs\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .xxs\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .xxs\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .xxs\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .xxs\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .xxs\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xxs\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .xxs\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .xxs\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .xxs\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .xxs\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .xxs\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .xxs\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .xxs\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .xxs\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .xxs\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .xxs\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .xxs\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .xxs\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .xxs\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .xxs\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .xxs\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .xxs\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .xxs\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .xxs\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .xxs\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .xxs\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .xxs\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .xxs\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xxs\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .xxs\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .xxs\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .xxs\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .xxs\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .xxs\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .xxs\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .xxs\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .xxs\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .xxs\:mt-0 {
    margin-top: 0;
  }
  .xxs\:mr-0 {
    margin-right: 0;
  }
  .xxs\:mb-0 {
    margin-bottom: 0;
  }
  .xxs\:ml-0 {
    margin-left: 0;
  }
  .xxs\:mt-1 {
    margin-top: 0.25rem;
  }
  .xxs\:mr-1 {
    margin-right: 0.25rem;
  }
  .xxs\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xxs\:ml-1 {
    margin-left: 0.25rem;
  }
  .xxs\:mt-2 {
    margin-top: 0.5rem;
  }
  .xxs\:mr-2 {
    margin-right: 0.5rem;
  }
  .xxs\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xxs\:ml-2 {
    margin-left: 0.5rem;
  }
  .xxs\:mt-3 {
    margin-top: 0.75rem;
  }
  .xxs\:mr-3 {
    margin-right: 0.75rem;
  }
  .xxs\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xxs\:ml-3 {
    margin-left: 0.75rem;
  }
  .xxs\:mt-4 {
    margin-top: 1rem;
  }
  .xxs\:mr-4 {
    margin-right: 1rem;
  }
  .xxs\:mb-4 {
    margin-bottom: 1rem;
  }
  .xxs\:ml-4 {
    margin-left: 1rem;
  }
  .xxs\:mt-5 {
    margin-top: 1.25rem;
  }
  .xxs\:mr-5 {
    margin-right: 1.25rem;
  }
  .xxs\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xxs\:ml-5 {
    margin-left: 1.25rem;
  }
  .xxs\:mt-6 {
    margin-top: 1.5rem;
  }
  .xxs\:mr-6 {
    margin-right: 1.5rem;
  }
  .xxs\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xxs\:ml-6 {
    margin-left: 1.5rem;
  }
  .xxs\:mt-7 {
    margin-top: 1.75rem;
  }
  .xxs\:mr-7 {
    margin-right: 1.75rem;
  }
  .xxs\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xxs\:ml-7 {
    margin-left: 1.75rem;
  }
  .xxs\:mt-8 {
    margin-top: 2rem;
  }
  .xxs\:mr-8 {
    margin-right: 2rem;
  }
  .xxs\:mb-8 {
    margin-bottom: 2rem;
  }
  .xxs\:ml-8 {
    margin-left: 2rem;
  }
  .xxs\:mt-9 {
    margin-top: 2.25rem;
  }
  .xxs\:mr-9 {
    margin-right: 2.25rem;
  }
  .xxs\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xxs\:ml-9 {
    margin-left: 2.25rem;
  }
  .xxs\:mt-10 {
    margin-top: 2.5rem;
  }
  .xxs\:mr-10 {
    margin-right: 2.5rem;
  }
  .xxs\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xxs\:ml-10 {
    margin-left: 2.5rem;
  }
  .xxs\:mt-11 {
    margin-top: 2.75rem;
  }
  .xxs\:mr-11 {
    margin-right: 2.75rem;
  }
  .xxs\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .xxs\:ml-11 {
    margin-left: 2.75rem;
  }
  .xxs\:mt-12 {
    margin-top: 3rem;
  }
  .xxs\:mr-12 {
    margin-right: 3rem;
  }
  .xxs\:mb-12 {
    margin-bottom: 3rem;
  }
  .xxs\:ml-12 {
    margin-left: 3rem;
  }
  .xxs\:mt-14 {
    margin-top: 3.5rem;
  }
  .xxs\:mr-14 {
    margin-right: 3.5rem;
  }
  .xxs\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .xxs\:ml-14 {
    margin-left: 3.5rem;
  }
  .xxs\:mt-16 {
    margin-top: 4rem;
  }
  .xxs\:mr-16 {
    margin-right: 4rem;
  }
  .xxs\:mb-16 {
    margin-bottom: 4rem;
  }
  .xxs\:ml-16 {
    margin-left: 4rem;
  }
  .xxs\:mt-20 {
    margin-top: 5rem;
  }
  .xxs\:mr-20 {
    margin-right: 5rem;
  }
  .xxs\:mb-20 {
    margin-bottom: 5rem;
  }
  .xxs\:ml-20 {
    margin-left: 5rem;
  }
  .xxs\:mt-24 {
    margin-top: 6rem;
  }
  .xxs\:mr-24 {
    margin-right: 6rem;
  }
  .xxs\:mb-24 {
    margin-bottom: 6rem;
  }
  .xxs\:ml-24 {
    margin-left: 6rem;
  }
  .xxs\:mt-28 {
    margin-top: 7rem;
  }
  .xxs\:mr-28 {
    margin-right: 7rem;
  }
  .xxs\:mb-28 {
    margin-bottom: 7rem;
  }
  .xxs\:ml-28 {
    margin-left: 7rem;
  }
  .xxs\:mt-32 {
    margin-top: 8rem;
  }
  .xxs\:mr-32 {
    margin-right: 8rem;
  }
  .xxs\:mb-32 {
    margin-bottom: 8rem;
  }
  .xxs\:ml-32 {
    margin-left: 8rem;
  }
  .xxs\:mt-36 {
    margin-top: 9rem;
  }
  .xxs\:mr-36 {
    margin-right: 9rem;
  }
  .xxs\:mb-36 {
    margin-bottom: 9rem;
  }
  .xxs\:ml-36 {
    margin-left: 9rem;
  }
  .xxs\:mt-40 {
    margin-top: 10rem;
  }
  .xxs\:mr-40 {
    margin-right: 10rem;
  }
  .xxs\:mb-40 {
    margin-bottom: 10rem;
  }
  .xxs\:ml-40 {
    margin-left: 10rem;
  }
  .xxs\:mt-44 {
    margin-top: 11rem;
  }
  .xxs\:mr-44 {
    margin-right: 11rem;
  }
  .xxs\:mb-44 {
    margin-bottom: 11rem;
  }
  .xxs\:ml-44 {
    margin-left: 11rem;
  }
  .xxs\:mt-48 {
    margin-top: 12rem;
  }
  .xxs\:mr-48 {
    margin-right: 12rem;
  }
  .xxs\:mb-48 {
    margin-bottom: 12rem;
  }
  .xxs\:ml-48 {
    margin-left: 12rem;
  }
  .xxs\:mt-52 {
    margin-top: 13rem;
  }
  .xxs\:mr-52 {
    margin-right: 13rem;
  }
  .xxs\:mb-52 {
    margin-bottom: 13rem;
  }
  .xxs\:ml-52 {
    margin-left: 13rem;
  }
  .xxs\:mt-56 {
    margin-top: 14rem;
  }
  .xxs\:mr-56 {
    margin-right: 14rem;
  }
  .xxs\:mb-56 {
    margin-bottom: 14rem;
  }
  .xxs\:ml-56 {
    margin-left: 14rem;
  }
  .xxs\:mt-60 {
    margin-top: 15rem;
  }
  .xxs\:mr-60 {
    margin-right: 15rem;
  }
  .xxs\:mb-60 {
    margin-bottom: 15rem;
  }
  .xxs\:ml-60 {
    margin-left: 15rem;
  }
  .xxs\:mt-64 {
    margin-top: 16rem;
  }
  .xxs\:mr-64 {
    margin-right: 16rem;
  }
  .xxs\:mb-64 {
    margin-bottom: 16rem;
  }
  .xxs\:ml-64 {
    margin-left: 16rem;
  }
  .xxs\:mt-72 {
    margin-top: 18rem;
  }
  .xxs\:mr-72 {
    margin-right: 18rem;
  }
  .xxs\:mb-72 {
    margin-bottom: 18rem;
  }
  .xxs\:ml-72 {
    margin-left: 18rem;
  }
  .xxs\:mt-80 {
    margin-top: 20rem;
  }
  .xxs\:mr-80 {
    margin-right: 20rem;
  }
  .xxs\:mb-80 {
    margin-bottom: 20rem;
  }
  .xxs\:ml-80 {
    margin-left: 20rem;
  }
  .xxs\:mt-96 {
    margin-top: 24rem;
  }
  .xxs\:mr-96 {
    margin-right: 24rem;
  }
  .xxs\:mb-96 {
    margin-bottom: 24rem;
  }
  .xxs\:ml-96 {
    margin-left: 24rem;
  }
  .xxs\:mt-auto {
    margin-top: auto;
  }
  .xxs\:mr-auto {
    margin-right: auto;
  }
  .xxs\:mb-auto {
    margin-bottom: auto;
  }
  .xxs\:ml-auto {
    margin-left: auto;
  }
  .xxs\:mt-px {
    margin-top: 1px;
  }
  .xxs\:mr-px {
    margin-right: 1px;
  }
  .xxs\:mb-px {
    margin-bottom: 1px;
  }
  .xxs\:ml-px {
    margin-left: 1px;
  }
  .xxs\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .xxs\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .xxs\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .xxs\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .xxs\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xxs\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xxs\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xxs\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xxs\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xxs\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xxs\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xxs\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xxs\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xxs\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xxs\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xxs\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xxs\:-mt-0 {
    margin-top: 0;
  }
  .xxs\:-mr-0 {
    margin-right: 0;
  }
  .xxs\:-mb-0 {
    margin-bottom: 0;
  }
  .xxs\:-ml-0 {
    margin-left: 0;
  }
  .xxs\:-mt-1 {
    margin-top: -0.25rem;
  }
  .xxs\:-mr-1 {
    margin-right: -0.25rem;
  }
  .xxs\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .xxs\:-ml-1 {
    margin-left: -0.25rem;
  }
  .xxs\:-mt-2 {
    margin-top: -0.5rem;
  }
  .xxs\:-mr-2 {
    margin-right: -0.5rem;
  }
  .xxs\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .xxs\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xxs\:-mt-3 {
    margin-top: -0.75rem;
  }
  .xxs\:-mr-3 {
    margin-right: -0.75rem;
  }
  .xxs\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .xxs\:-ml-3 {
    margin-left: -0.75rem;
  }
  .xxs\:-mt-4 {
    margin-top: -1rem;
  }
  .xxs\:-mr-4 {
    margin-right: -1rem;
  }
  .xxs\:-mb-4 {
    margin-bottom: -1rem;
  }
  .xxs\:-ml-4 {
    margin-left: -1rem;
  }
  .xxs\:-mt-5 {
    margin-top: -1.25rem;
  }
  .xxs\:-mr-5 {
    margin-right: -1.25rem;
  }
  .xxs\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .xxs\:-ml-5 {
    margin-left: -1.25rem;
  }
  .xxs\:-mt-6 {
    margin-top: -1.5rem;
  }
  .xxs\:-mr-6 {
    margin-right: -1.5rem;
  }
  .xxs\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .xxs\:-ml-6 {
    margin-left: -1.5rem;
  }
  .xxs\:-mt-7 {
    margin-top: -1.75rem;
  }
  .xxs\:-mr-7 {
    margin-right: -1.75rem;
  }
  .xxs\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .xxs\:-ml-7 {
    margin-left: -1.75rem;
  }
  .xxs\:-mt-8 {
    margin-top: -2rem;
  }
  .xxs\:-mr-8 {
    margin-right: -2rem;
  }
  .xxs\:-mb-8 {
    margin-bottom: -2rem;
  }
  .xxs\:-ml-8 {
    margin-left: -2rem;
  }
  .xxs\:-mt-9 {
    margin-top: -2.25rem;
  }
  .xxs\:-mr-9 {
    margin-right: -2.25rem;
  }
  .xxs\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .xxs\:-ml-9 {
    margin-left: -2.25rem;
  }
  .xxs\:-mt-10 {
    margin-top: -2.5rem;
  }
  .xxs\:-mr-10 {
    margin-right: -2.5rem;
  }
  .xxs\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .xxs\:-ml-10 {
    margin-left: -2.5rem;
  }
  .xxs\:-mt-11 {
    margin-top: -2.75rem;
  }
  .xxs\:-mr-11 {
    margin-right: -2.75rem;
  }
  .xxs\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .xxs\:-ml-11 {
    margin-left: -2.75rem;
  }
  .xxs\:-mt-12 {
    margin-top: -3rem;
  }
  .xxs\:-mr-12 {
    margin-right: -3rem;
  }
  .xxs\:-mb-12 {
    margin-bottom: -3rem;
  }
  .xxs\:-ml-12 {
    margin-left: -3rem;
  }
  .xxs\:-mt-14 {
    margin-top: -3.5rem;
  }
  .xxs\:-mr-14 {
    margin-right: -3.5rem;
  }
  .xxs\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .xxs\:-ml-14 {
    margin-left: -3.5rem;
  }
  .xxs\:-mt-16 {
    margin-top: -4rem;
  }
  .xxs\:-mr-16 {
    margin-right: -4rem;
  }
  .xxs\:-mb-16 {
    margin-bottom: -4rem;
  }
  .xxs\:-ml-16 {
    margin-left: -4rem;
  }
  .xxs\:-mt-20 {
    margin-top: -5rem;
  }
  .xxs\:-mr-20 {
    margin-right: -5rem;
  }
  .xxs\:-mb-20 {
    margin-bottom: -5rem;
  }
  .xxs\:-ml-20 {
    margin-left: -5rem;
  }
  .xxs\:-mt-24 {
    margin-top: -6rem;
  }
  .xxs\:-mr-24 {
    margin-right: -6rem;
  }
  .xxs\:-mb-24 {
    margin-bottom: -6rem;
  }
  .xxs\:-ml-24 {
    margin-left: -6rem;
  }
  .xxs\:-mt-28 {
    margin-top: -7rem;
  }
  .xxs\:-mr-28 {
    margin-right: -7rem;
  }
  .xxs\:-mb-28 {
    margin-bottom: -7rem;
  }
  .xxs\:-ml-28 {
    margin-left: -7rem;
  }
  .xxs\:-mt-32 {
    margin-top: -8rem;
  }
  .xxs\:-mr-32 {
    margin-right: -8rem;
  }
  .xxs\:-mb-32 {
    margin-bottom: -8rem;
  }
  .xxs\:-ml-32 {
    margin-left: -8rem;
  }
  .xxs\:-mt-36 {
    margin-top: -9rem;
  }
  .xxs\:-mr-36 {
    margin-right: -9rem;
  }
  .xxs\:-mb-36 {
    margin-bottom: -9rem;
  }
  .xxs\:-ml-36 {
    margin-left: -9rem;
  }
  .xxs\:-mt-40 {
    margin-top: -10rem;
  }
  .xxs\:-mr-40 {
    margin-right: -10rem;
  }
  .xxs\:-mb-40 {
    margin-bottom: -10rem;
  }
  .xxs\:-ml-40 {
    margin-left: -10rem;
  }
  .xxs\:-mt-44 {
    margin-top: -11rem;
  }
  .xxs\:-mr-44 {
    margin-right: -11rem;
  }
  .xxs\:-mb-44 {
    margin-bottom: -11rem;
  }
  .xxs\:-ml-44 {
    margin-left: -11rem;
  }
  .xxs\:-mt-48 {
    margin-top: -12rem;
  }
  .xxs\:-mr-48 {
    margin-right: -12rem;
  }
  .xxs\:-mb-48 {
    margin-bottom: -12rem;
  }
  .xxs\:-ml-48 {
    margin-left: -12rem;
  }
  .xxs\:-mt-52 {
    margin-top: -13rem;
  }
  .xxs\:-mr-52 {
    margin-right: -13rem;
  }
  .xxs\:-mb-52 {
    margin-bottom: -13rem;
  }
  .xxs\:-ml-52 {
    margin-left: -13rem;
  }
  .xxs\:-mt-56 {
    margin-top: -14rem;
  }
  .xxs\:-mr-56 {
    margin-right: -14rem;
  }
  .xxs\:-mb-56 {
    margin-bottom: -14rem;
  }
  .xxs\:-ml-56 {
    margin-left: -14rem;
  }
  .xxs\:-mt-60 {
    margin-top: -15rem;
  }
  .xxs\:-mr-60 {
    margin-right: -15rem;
  }
  .xxs\:-mb-60 {
    margin-bottom: -15rem;
  }
  .xxs\:-ml-60 {
    margin-left: -15rem;
  }
  .xxs\:-mt-64 {
    margin-top: -16rem;
  }
  .xxs\:-mr-64 {
    margin-right: -16rem;
  }
  .xxs\:-mb-64 {
    margin-bottom: -16rem;
  }
  .xxs\:-ml-64 {
    margin-left: -16rem;
  }
  .xxs\:-mt-72 {
    margin-top: -18rem;
  }
  .xxs\:-mr-72 {
    margin-right: -18rem;
  }
  .xxs\:-mb-72 {
    margin-bottom: -18rem;
  }
  .xxs\:-ml-72 {
    margin-left: -18rem;
  }
  .xxs\:-mt-80 {
    margin-top: -20rem;
  }
  .xxs\:-mr-80 {
    margin-right: -20rem;
  }
  .xxs\:-mb-80 {
    margin-bottom: -20rem;
  }
  .xxs\:-ml-80 {
    margin-left: -20rem;
  }
  .xxs\:-mt-96 {
    margin-top: -24rem;
  }
  .xxs\:-mr-96 {
    margin-right: -24rem;
  }
  .xxs\:-mb-96 {
    margin-bottom: -24rem;
  }
  .xxs\:-ml-96 {
    margin-left: -24rem;
  }
  .xxs\:-mt-px {
    margin-top: -1px;
  }
  .xxs\:-mr-px {
    margin-right: -1px;
  }
  .xxs\:-mb-px {
    margin-bottom: -1px;
  }
  .xxs\:-ml-px {
    margin-left: -1px;
  }
  .xxs\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .xxs\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .xxs\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .xxs\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .xxs\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .xxs\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .xxs\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .xxs\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .xxs\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .xxs\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .xxs\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .xxs\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xxs\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .xxs\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .xxs\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .xxs\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .xxs\:max-h-0 {
    max-height: 0;
  }
  .xxs\:max-h-1 {
    max-height: 0.25rem;
  }
  .xxs\:max-h-2 {
    max-height: 0.5rem;
  }
  .xxs\:max-h-3 {
    max-height: 0.75rem;
  }
  .xxs\:max-h-4 {
    max-height: 1rem;
  }
  .xxs\:max-h-5 {
    max-height: 1.25rem;
  }
  .xxs\:max-h-6 {
    max-height: 1.5rem;
  }
  .xxs\:max-h-7 {
    max-height: 1.75rem;
  }
  .xxs\:max-h-8 {
    max-height: 2rem;
  }
  .xxs\:max-h-9 {
    max-height: 2.25rem;
  }
  .xxs\:max-h-10 {
    max-height: 2.5rem;
  }
  .xxs\:max-h-11 {
    max-height: 2.75rem;
  }
  .xxs\:max-h-12 {
    max-height: 3rem;
  }
  .xxs\:max-h-14 {
    max-height: 3.5rem;
  }
  .xxs\:max-h-16 {
    max-height: 4rem;
  }
  .xxs\:max-h-20 {
    max-height: 5rem;
  }
  .xxs\:max-h-24 {
    max-height: 6rem;
  }
  .xxs\:max-h-28 {
    max-height: 7rem;
  }
  .xxs\:max-h-32 {
    max-height: 8rem;
  }
  .xxs\:max-h-36 {
    max-height: 9rem;
  }
  .xxs\:max-h-40 {
    max-height: 10rem;
  }
  .xxs\:max-h-44 {
    max-height: 11rem;
  }
  .xxs\:max-h-48 {
    max-height: 12rem;
  }
  .xxs\:max-h-52 {
    max-height: 13rem;
  }
  .xxs\:max-h-56 {
    max-height: 14rem;
  }
  .xxs\:max-h-60 {
    max-height: 15rem;
  }
  .xxs\:max-h-64 {
    max-height: 16rem;
  }
  .xxs\:max-h-72 {
    max-height: 18rem;
  }
  .xxs\:max-h-80 {
    max-height: 20rem;
  }
  .xxs\:max-h-96 {
    max-height: 24rem;
  }
  .xxs\:max-h-px {
    max-height: 1px;
  }
  .xxs\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .xxs\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .xxs\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .xxs\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .xxs\:max-h-full {
    max-height: 100%;
  }
  .xxs\:max-h-screen {
    max-height: 100vh;
  }
  .xxs\:max-h-1\/4 {
    max-height: 25%;
  }
  .xxs\:max-h-1\/2 {
    max-height: 50%;
  }
  .xxs\:max-h-3\/4 {
    max-height: 75%;
  }
  .xxs\:max-h-10\/12 {
    max-height: 80%;
  }
  .xxs\:max-h-enormous {
    max-height: 4000px;
  }
  .xxs\:max-w-0 {
    max-width: 0;
  }
  .xxs\:max-w-110 {
    max-width: 110px;
  }
  .xxs\:max-w-473 {
    max-width: 473px;
  }
  .xxs\:max-w-none {
    max-width: none;
  }
  .xxs\:max-w-xs {
    max-width: 20rem;
  }
  .xxs\:max-w-sm {
    max-width: 24rem;
  }
  .xxs\:max-w-md {
    max-width: 28rem;
  }
  .xxs\:max-w-lg {
    max-width: 32rem;
  }
  .xxs\:max-w-xl {
    max-width: 36rem;
  }
  .xxs\:max-w-2xl {
    max-width: 42rem;
  }
  .xxs\:max-w-3xl {
    max-width: 48rem;
  }
  .xxs\:max-w-4xl {
    max-width: 56rem;
  }
  .xxs\:max-w-5xl {
    max-width: 64rem;
  }
  .xxs\:max-w-6xl {
    max-width: 72rem;
  }
  .xxs\:max-w-7xl {
    max-width: 80rem;
  }
  .xxs\:max-w-full {
    max-width: 100%;
  }
  .xxs\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .xxs\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .xxs\:max-w-prose {
    max-width: 65ch;
  }
  .xxs\:max-w-screen-sm {
    max-width: 640px;
  }
  .xxs\:max-w-screen-md {
    max-width: 768px;
  }
  .xxs\:max-w-screen-lg {
    max-width: 1024px;
  }
  .xxs\:max-w-screen-xl {
    max-width: 1280px;
  }
  .xxs\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .xxs\:max-w-screen-xxs {
    max-width: 260px;
  }
  .xxs\:max-w-screen-xs {
    max-width: 360px;
  }
  .xxs\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .xxs\:min-h-0 {
    min-height: 0;
  }
  .xxs\:min-h-full {
    min-height: 100%;
  }
  .xxs\:min-h-screen {
    min-height: 100vh;
  }
  .xxs\:min-w-0 {
    min-width: 0;
  }
  .xxs\:min-w-10 {
    min-width: 2.5rem;
  }
  .xxs\:min-w-12 {
    min-width: 3rem;
  }
  .xxs\:min-w-14 {
    min-width: 3.5rem;
  }
  .xxs\:min-w-full {
    min-width: 100%;
  }
  .xxs\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .xxs\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .xxs\:object-contain {
    object-fit: contain;
  }
  .xxs\:object-cover {
    object-fit: cover;
  }
  .xxs\:object-fill {
    object-fit: fill;
  }
  .xxs\:object-none {
    object-fit: none;
  }
  .xxs\:object-scale-down {
    object-fit: scale-down;
  }
  .xxs\:object-bottom {
    object-position: bottom;
  }
  .xxs\:object-center {
    object-position: center;
  }
  .xxs\:object-left {
    object-position: left;
  }
  .xxs\:object-left-bottom {
    object-position: left bottom;
  }
  .xxs\:object-left-top {
    object-position: left top;
  }
  .xxs\:object-right {
    object-position: right;
  }
  .xxs\:object-right-bottom {
    object-position: right bottom;
  }
  .xxs\:object-right-top {
    object-position: right top;
  }
  .xxs\:object-top {
    object-position: top;
  }
  .xxs\:opacity-0 {
    opacity: 0;
  }
  .xxs\:opacity-5 {
    opacity: 0.05;
  }
  .xxs\:opacity-10 {
    opacity: 0.1;
  }
  .xxs\:opacity-20 {
    opacity: 0.2;
  }
  .xxs\:opacity-25 {
    opacity: 0.25;
  }
  .xxs\:opacity-30 {
    opacity: 0.3;
  }
  .xxs\:opacity-40 {
    opacity: 0.4;
  }
  .xxs\:opacity-50 {
    opacity: 0.5;
  }
  .xxs\:opacity-60 {
    opacity: 0.6;
  }
  .xxs\:opacity-70 {
    opacity: 0.7;
  }
  .xxs\:opacity-75 {
    opacity: 0.75;
  }
  .xxs\:opacity-80 {
    opacity: 0.8;
  }
  .xxs\:opacity-90 {
    opacity: 0.9;
  }
  .xxs\:opacity-95 {
    opacity: 0.95;
  }
  .xxs\:opacity-100 {
    opacity: 1;
  }
  .group:hover .xxs\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .xxs\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .xxs\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .xxs\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .xxs\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .xxs\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .xxs\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .xxs\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .xxs\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .xxs\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .xxs\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .xxs\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .xxs\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .xxs\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .xxs\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .xxs\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .xxs\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .xxs\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .xxs\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .xxs\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .xxs\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .xxs\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .xxs\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .xxs\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .xxs\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .xxs\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .xxs\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .xxs\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .xxs\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .xxs\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .xxs\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .xxs\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .xxs\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .xxs\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .xxs\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .xxs\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .xxs\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .xxs\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .xxs\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .xxs\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .xxs\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .xxs\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .xxs\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .xxs\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .xxs\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .xxs\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .xxs\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .xxs\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .xxs\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .xxs\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .xxs\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .xxs\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .xxs\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .xxs\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .xxs\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .xxs\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .xxs\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .xxs\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .xxs\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .xxs\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .xxs\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxs\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxs\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxs\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxs\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxs\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxs\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxs\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxs\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxs\:overflow-auto {
    overflow: auto;
  }
  .xxs\:overflow-hidden {
    overflow: hidden;
  }
  .xxs\:overflow-visible {
    overflow: visible;
  }
  .xxs\:overflow-scroll {
    overflow: scroll;
  }
  .xxs\:overflow-x-auto {
    overflow-x: auto;
  }
  .xxs\:overflow-y-auto {
    overflow-y: auto;
  }
  .xxs\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .xxs\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .xxs\:overflow-x-visible {
    overflow-x: visible;
  }
  .xxs\:overflow-y-visible {
    overflow-y: visible;
  }
  .xxs\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .xxs\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .xxs\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .xxs\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .xxs\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .xxs\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .xxs\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .xxs\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .xxs\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .xxs\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .xxs\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .xxs\:p-0 {
    padding: 0;
  }
  .xxs\:p-1 {
    padding: 0.25rem;
  }
  .xxs\:p-2 {
    padding: 0.5rem;
  }
  .xxs\:p-3 {
    padding: 0.75rem;
  }
  .xxs\:p-4 {
    padding: 1rem;
  }
  .xxs\:p-5 {
    padding: 1.25rem;
  }
  .xxs\:p-6 {
    padding: 1.5rem;
  }
  .xxs\:p-7 {
    padding: 1.75rem;
  }
  .xxs\:p-8 {
    padding: 2rem;
  }
  .xxs\:p-9 {
    padding: 2.25rem;
  }
  .xxs\:p-10 {
    padding: 2.5rem;
  }
  .xxs\:p-11 {
    padding: 2.75rem;
  }
  .xxs\:p-12 {
    padding: 3rem;
  }
  .xxs\:p-14 {
    padding: 3.5rem;
  }
  .xxs\:p-16 {
    padding: 4rem;
  }
  .xxs\:p-20 {
    padding: 5rem;
  }
  .xxs\:p-24 {
    padding: 6rem;
  }
  .xxs\:p-28 {
    padding: 7rem;
  }
  .xxs\:p-32 {
    padding: 8rem;
  }
  .xxs\:p-36 {
    padding: 9rem;
  }
  .xxs\:p-40 {
    padding: 10rem;
  }
  .xxs\:p-44 {
    padding: 11rem;
  }
  .xxs\:p-48 {
    padding: 12rem;
  }
  .xxs\:p-52 {
    padding: 13rem;
  }
  .xxs\:p-56 {
    padding: 14rem;
  }
  .xxs\:p-60 {
    padding: 15rem;
  }
  .xxs\:p-64 {
    padding: 16rem;
  }
  .xxs\:p-72 {
    padding: 18rem;
  }
  .xxs\:p-80 {
    padding: 20rem;
  }
  .xxs\:p-96 {
    padding: 24rem;
  }
  .xxs\:p-px {
    padding: 1px;
  }
  .xxs\:p-0\.5 {
    padding: 0.125rem;
  }
  .xxs\:p-1\.5 {
    padding: 0.375rem;
  }
  .xxs\:p-2\.5 {
    padding: 0.625rem;
  }
  .xxs\:p-3\.5 {
    padding: 0.875rem;
  }
  .xxs\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xxs\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xxs\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xxs\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xxs\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xxs\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xxs\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xxs\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xxs\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xxs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xxs\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xxs\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xxs\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xxs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xxs\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xxs\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xxs\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xxs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xxs\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xxs\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xxs\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xxs\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xxs\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xxs\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xxs\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xxs\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xxs\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xxs\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xxs\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xxs\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xxs\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xxs\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xxs\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xxs\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xxs\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .xxs\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .xxs\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xxs\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xxs\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .xxs\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .xxs\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .xxs\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .xxs\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .xxs\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .xxs\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .xxs\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .xxs\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .xxs\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .xxs\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .xxs\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .xxs\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .xxs\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .xxs\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .xxs\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .xxs\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .xxs\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .xxs\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .xxs\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .xxs\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .xxs\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .xxs\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xxs\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xxs\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xxs\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xxs\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xxs\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xxs\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xxs\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xxs\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xxs\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xxs\:pt-0 {
    padding-top: 0;
  }
  .xxs\:pr-0 {
    padding-right: 0;
  }
  .xxs\:pb-0 {
    padding-bottom: 0;
  }
  .xxs\:pl-0 {
    padding-left: 0;
  }
  .xxs\:pt-1 {
    padding-top: 0.25rem;
  }
  .xxs\:pr-1 {
    padding-right: 0.25rem;
  }
  .xxs\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xxs\:pl-1 {
    padding-left: 0.25rem;
  }
  .xxs\:pt-2 {
    padding-top: 0.5rem;
  }
  .xxs\:pr-2 {
    padding-right: 0.5rem;
  }
  .xxs\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xxs\:pl-2 {
    padding-left: 0.5rem;
  }
  .xxs\:pt-3 {
    padding-top: 0.75rem;
  }
  .xxs\:pr-3 {
    padding-right: 0.75rem;
  }
  .xxs\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xxs\:pl-3 {
    padding-left: 0.75rem;
  }
  .xxs\:pt-4 {
    padding-top: 1rem;
  }
  .xxs\:pr-4 {
    padding-right: 1rem;
  }
  .xxs\:pb-4 {
    padding-bottom: 1rem;
  }
  .xxs\:pl-4 {
    padding-left: 1rem;
  }
  .xxs\:pt-5 {
    padding-top: 1.25rem;
  }
  .xxs\:pr-5 {
    padding-right: 1.25rem;
  }
  .xxs\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xxs\:pl-5 {
    padding-left: 1.25rem;
  }
  .xxs\:pt-6 {
    padding-top: 1.5rem;
  }
  .xxs\:pr-6 {
    padding-right: 1.5rem;
  }
  .xxs\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xxs\:pl-6 {
    padding-left: 1.5rem;
  }
  .xxs\:pt-7 {
    padding-top: 1.75rem;
  }
  .xxs\:pr-7 {
    padding-right: 1.75rem;
  }
  .xxs\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xxs\:pl-7 {
    padding-left: 1.75rem;
  }
  .xxs\:pt-8 {
    padding-top: 2rem;
  }
  .xxs\:pr-8 {
    padding-right: 2rem;
  }
  .xxs\:pb-8 {
    padding-bottom: 2rem;
  }
  .xxs\:pl-8 {
    padding-left: 2rem;
  }
  .xxs\:pt-9 {
    padding-top: 2.25rem;
  }
  .xxs\:pr-9 {
    padding-right: 2.25rem;
  }
  .xxs\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xxs\:pl-9 {
    padding-left: 2.25rem;
  }
  .xxs\:pt-10 {
    padding-top: 2.5rem;
  }
  .xxs\:pr-10 {
    padding-right: 2.5rem;
  }
  .xxs\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xxs\:pl-10 {
    padding-left: 2.5rem;
  }
  .xxs\:pt-11 {
    padding-top: 2.75rem;
  }
  .xxs\:pr-11 {
    padding-right: 2.75rem;
  }
  .xxs\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xxs\:pl-11 {
    padding-left: 2.75rem;
  }
  .xxs\:pt-12 {
    padding-top: 3rem;
  }
  .xxs\:pr-12 {
    padding-right: 3rem;
  }
  .xxs\:pb-12 {
    padding-bottom: 3rem;
  }
  .xxs\:pl-12 {
    padding-left: 3rem;
  }
  .xxs\:pt-14 {
    padding-top: 3.5rem;
  }
  .xxs\:pr-14 {
    padding-right: 3.5rem;
  }
  .xxs\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xxs\:pl-14 {
    padding-left: 3.5rem;
  }
  .xxs\:pt-16 {
    padding-top: 4rem;
  }
  .xxs\:pr-16 {
    padding-right: 4rem;
  }
  .xxs\:pb-16 {
    padding-bottom: 4rem;
  }
  .xxs\:pl-16 {
    padding-left: 4rem;
  }
  .xxs\:pt-20 {
    padding-top: 5rem;
  }
  .xxs\:pr-20 {
    padding-right: 5rem;
  }
  .xxs\:pb-20 {
    padding-bottom: 5rem;
  }
  .xxs\:pl-20 {
    padding-left: 5rem;
  }
  .xxs\:pt-24 {
    padding-top: 6rem;
  }
  .xxs\:pr-24 {
    padding-right: 6rem;
  }
  .xxs\:pb-24 {
    padding-bottom: 6rem;
  }
  .xxs\:pl-24 {
    padding-left: 6rem;
  }
  .xxs\:pt-28 {
    padding-top: 7rem;
  }
  .xxs\:pr-28 {
    padding-right: 7rem;
  }
  .xxs\:pb-28 {
    padding-bottom: 7rem;
  }
  .xxs\:pl-28 {
    padding-left: 7rem;
  }
  .xxs\:pt-32 {
    padding-top: 8rem;
  }
  .xxs\:pr-32 {
    padding-right: 8rem;
  }
  .xxs\:pb-32 {
    padding-bottom: 8rem;
  }
  .xxs\:pl-32 {
    padding-left: 8rem;
  }
  .xxs\:pt-36 {
    padding-top: 9rem;
  }
  .xxs\:pr-36 {
    padding-right: 9rem;
  }
  .xxs\:pb-36 {
    padding-bottom: 9rem;
  }
  .xxs\:pl-36 {
    padding-left: 9rem;
  }
  .xxs\:pt-40 {
    padding-top: 10rem;
  }
  .xxs\:pr-40 {
    padding-right: 10rem;
  }
  .xxs\:pb-40 {
    padding-bottom: 10rem;
  }
  .xxs\:pl-40 {
    padding-left: 10rem;
  }
  .xxs\:pt-44 {
    padding-top: 11rem;
  }
  .xxs\:pr-44 {
    padding-right: 11rem;
  }
  .xxs\:pb-44 {
    padding-bottom: 11rem;
  }
  .xxs\:pl-44 {
    padding-left: 11rem;
  }
  .xxs\:pt-48 {
    padding-top: 12rem;
  }
  .xxs\:pr-48 {
    padding-right: 12rem;
  }
  .xxs\:pb-48 {
    padding-bottom: 12rem;
  }
  .xxs\:pl-48 {
    padding-left: 12rem;
  }
  .xxs\:pt-52 {
    padding-top: 13rem;
  }
  .xxs\:pr-52 {
    padding-right: 13rem;
  }
  .xxs\:pb-52 {
    padding-bottom: 13rem;
  }
  .xxs\:pl-52 {
    padding-left: 13rem;
  }
  .xxs\:pt-56 {
    padding-top: 14rem;
  }
  .xxs\:pr-56 {
    padding-right: 14rem;
  }
  .xxs\:pb-56 {
    padding-bottom: 14rem;
  }
  .xxs\:pl-56 {
    padding-left: 14rem;
  }
  .xxs\:pt-60 {
    padding-top: 15rem;
  }
  .xxs\:pr-60 {
    padding-right: 15rem;
  }
  .xxs\:pb-60 {
    padding-bottom: 15rem;
  }
  .xxs\:pl-60 {
    padding-left: 15rem;
  }
  .xxs\:pt-64 {
    padding-top: 16rem;
  }
  .xxs\:pr-64 {
    padding-right: 16rem;
  }
  .xxs\:pb-64 {
    padding-bottom: 16rem;
  }
  .xxs\:pl-64 {
    padding-left: 16rem;
  }
  .xxs\:pt-72 {
    padding-top: 18rem;
  }
  .xxs\:pr-72 {
    padding-right: 18rem;
  }
  .xxs\:pb-72 {
    padding-bottom: 18rem;
  }
  .xxs\:pl-72 {
    padding-left: 18rem;
  }
  .xxs\:pt-80 {
    padding-top: 20rem;
  }
  .xxs\:pr-80 {
    padding-right: 20rem;
  }
  .xxs\:pb-80 {
    padding-bottom: 20rem;
  }
  .xxs\:pl-80 {
    padding-left: 20rem;
  }
  .xxs\:pt-96 {
    padding-top: 24rem;
  }
  .xxs\:pr-96 {
    padding-right: 24rem;
  }
  .xxs\:pb-96 {
    padding-bottom: 24rem;
  }
  .xxs\:pl-96 {
    padding-left: 24rem;
  }
  .xxs\:pt-px {
    padding-top: 1px;
  }
  .xxs\:pr-px {
    padding-right: 1px;
  }
  .xxs\:pb-px {
    padding-bottom: 1px;
  }
  .xxs\:pl-px {
    padding-left: 1px;
  }
  .xxs\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xxs\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xxs\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xxs\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xxs\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xxs\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xxs\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xxs\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xxs\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xxs\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xxs\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xxs\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xxs\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xxs\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xxs\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xxs\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xxs\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .xxs\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .xxs\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .xxs\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .xxs\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .xxs\:placeholder-current::placeholder {
    color: currentColor;
  }
  .xxs\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xxs\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xxs\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .xxs\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xxs\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xxs\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .xxs\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .xxs\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .xxs\:placeholder-background::placeholder {
    color: var(--background);
  }
  .xxs\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xxs\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xxs\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .xxs\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xxs\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xxs\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .xxs\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xxs\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xxs\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .xxs\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xxs\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .xxs\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .xxs\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xxs\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xxs\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .xxs\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xxs\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xxs\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .xxs\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xxs\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xxs\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .xxs\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xxs\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xxs\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .xxs\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .xxs\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .xxs\:placeholder-success::placeholder {
    color: var(--success);
  }
  .xxs\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .xxs\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .xxs\:placeholder-error::placeholder {
    color: var(--error);
  }
  .xxs\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xxs\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xxs\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .xxs\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xxs\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .xxs\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .xxs\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .xxs\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .xxs\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .xxs\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .xxs\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .xxs\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .xxs\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xxs\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xxs\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .xxs\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xxs\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xxs\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .xxs\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .xxs\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .xxs\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .xxs\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xxs\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xxs\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .xxs\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xxs\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xxs\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .xxs\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xxs\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xxs\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .xxs\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xxs\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .xxs\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .xxs\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xxs\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xxs\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .xxs\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xxs\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xxs\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .xxs\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xxs\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xxs\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .xxs\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .xxs\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .xxs\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xxs\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xxs\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .xxs\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .xxs\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .xxs\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .xxs\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .xxs\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .xxs\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .xxs\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xxs\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xxs\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .xxs\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xxs\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .xxs\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .xxs\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxs\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxs\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxs\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxs\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxs\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxs\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxs\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxs\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxs\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxs\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxs\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxs\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxs\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxs\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxs\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxs\:pointer-events-none {
    pointer-events: none;
  }
  .xxs\:pointer-events-auto {
    pointer-events: auto;
  }
  .xxs\:static {
    position: static;
  }
  .xxs\:fixed {
    position: fixed;
  }
  .xxs\:absolute {
    position: absolute;
  }
  .xxs\:relative {
    position: relative;
  }
  .xxs\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .xxs\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxs\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .xxs\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .xxs\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .xxs\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .xxs\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .xxs\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .xxs\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .xxs\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .xxs\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .xxs\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .xxs\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .xxs\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .xxs\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .xxs\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .xxs\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .xxs\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .xxs\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .xxs\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .xxs\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .xxs\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .xxs\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .xxs\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .xxs\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .xxs\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .xxs\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .xxs\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .xxs\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .xxs\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .xxs\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .xxs\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xxs\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .xxs\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .xxs\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .xxs\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .xxs\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .xxs\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxs\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .xxs\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .xxs\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .xxs\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .xxs\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .xxs\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .xxs\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .xxs\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .xxs\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .xxs\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .xxs\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .xxs\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .xxs\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .xxs\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .xxs\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .xxs\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .xxs\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .xxs\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .xxs\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .xxs\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .xxs\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .xxs\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .xxs\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .xxs\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .xxs\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .xxs\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .xxs\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .xxs\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .xxs\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .xxs\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .xxs\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .xxs\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .xxs\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .xxs\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .xxs\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xxs\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .xxs\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .xxs\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .xxs\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xxs\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .xxs\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .xxs\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xxs\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .xxs\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .xxs\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .xxs\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xxs\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .xxs\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .xxs\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xxs\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .xxs\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .xxs\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .xxs\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .xxs\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .xxs\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .xxs\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .xxs\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .xxs\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .xxs\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .xxs\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .xxs\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .xxs\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .xxs\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .xxs\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .xxs\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .xxs\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .xxs\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .xxs\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .xxs\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .xxs\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .xxs\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .xxs\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .xxs\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .xxs\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .xxs\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .xxs\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .xxs\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .xxs\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .xxs\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .xxs\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .xxs\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .xxs\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .xxs\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .xxs\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .xxs\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .xxs\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .xxs\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .xxs\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .xxs\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .xxs\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .xxs\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .xxs\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .xxs\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .xxs\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .xxs\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .xxs\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .xxs\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .xxs\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .xxs\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .xxs\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .xxs\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .xxs\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .xxs\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .xxs\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .xxs\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .xxs\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .xxs\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .xxs\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .xxs\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .xxs\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .xxs\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .xxs\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .xxs\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .xxs\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .xxs\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .xxs\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .xxs\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .xxs\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .xxs\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .xxs\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .xxs\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xxs\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .xxs\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .xxs\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .xxs\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .xxs\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .xxs\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .xxs\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .xxs\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .xxs\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .xxs\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .xxs\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .xxs\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .xxs\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .xxs\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .xxs\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .xxs\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .xxs\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .xxs\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .xxs\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .xxs\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .xxs\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .xxs\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .xxs\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .xxs\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .xxs\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .xxs\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .xxs\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .xxs\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .xxs\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .xxs\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .xxs\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .xxs\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .xxs\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .xxs\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .xxs\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .xxs\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .xxs\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .xxs\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .xxs\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .xxs\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .xxs\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .xxs\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .xxs\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .xxs\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .xxs\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .xxs\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .xxs\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .xxs\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .xxs\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .xxs\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .xxs\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .xxs\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .xxs\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .xxs\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .xxs\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .xxs\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .xxs\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .xxs\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .xxs\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .xxs\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .xxs\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .xxs\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .xxs\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .xxs\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .xxs\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .xxs\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .xxs\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .xxs\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .xxs\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .xxs\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .xxs\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .xxs\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .xxs\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .xxs\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .xxs\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .xxs\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .xxs\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .xxs\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .xxs\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .xxs\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .xxs\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .xxs\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .xxs\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .xxs\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .xxs\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .xxs\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .xxs\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .xxs\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .xxs\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .xxs\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .xxs\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .xxs\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .xxs\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .xxs\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .xxs\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .xxs\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .xxs\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .xxs\:top-0 {
    top: 0;
  }
  .xxs\:right-0 {
    right: 0;
  }
  .xxs\:bottom-0 {
    bottom: 0;
  }
  .xxs\:left-0 {
    left: 0;
  }
  .xxs\:top-1 {
    top: 0.25rem;
  }
  .xxs\:right-1 {
    right: 0.25rem;
  }
  .xxs\:bottom-1 {
    bottom: 0.25rem;
  }
  .xxs\:left-1 {
    left: 0.25rem;
  }
  .xxs\:top-2 {
    top: 0.5rem;
  }
  .xxs\:right-2 {
    right: 0.5rem;
  }
  .xxs\:bottom-2 {
    bottom: 0.5rem;
  }
  .xxs\:left-2 {
    left: 0.5rem;
  }
  .xxs\:top-3 {
    top: 0.75rem;
  }
  .xxs\:right-3 {
    right: 0.75rem;
  }
  .xxs\:bottom-3 {
    bottom: 0.75rem;
  }
  .xxs\:left-3 {
    left: 0.75rem;
  }
  .xxs\:top-4 {
    top: 1rem;
  }
  .xxs\:right-4 {
    right: 1rem;
  }
  .xxs\:bottom-4 {
    bottom: 1rem;
  }
  .xxs\:left-4 {
    left: 1rem;
  }
  .xxs\:top-5 {
    top: 1.25rem;
  }
  .xxs\:right-5 {
    right: 1.25rem;
  }
  .xxs\:bottom-5 {
    bottom: 1.25rem;
  }
  .xxs\:left-5 {
    left: 1.25rem;
  }
  .xxs\:top-6 {
    top: 1.5rem;
  }
  .xxs\:right-6 {
    right: 1.5rem;
  }
  .xxs\:bottom-6 {
    bottom: 1.5rem;
  }
  .xxs\:left-6 {
    left: 1.5rem;
  }
  .xxs\:top-7 {
    top: 1.75rem;
  }
  .xxs\:right-7 {
    right: 1.75rem;
  }
  .xxs\:bottom-7 {
    bottom: 1.75rem;
  }
  .xxs\:left-7 {
    left: 1.75rem;
  }
  .xxs\:top-8 {
    top: 2rem;
  }
  .xxs\:right-8 {
    right: 2rem;
  }
  .xxs\:bottom-8 {
    bottom: 2rem;
  }
  .xxs\:left-8 {
    left: 2rem;
  }
  .xxs\:top-9 {
    top: 2.25rem;
  }
  .xxs\:right-9 {
    right: 2.25rem;
  }
  .xxs\:bottom-9 {
    bottom: 2.25rem;
  }
  .xxs\:left-9 {
    left: 2.25rem;
  }
  .xxs\:top-10 {
    top: 2.5rem;
  }
  .xxs\:right-10 {
    right: 2.5rem;
  }
  .xxs\:bottom-10 {
    bottom: 2.5rem;
  }
  .xxs\:left-10 {
    left: 2.5rem;
  }
  .xxs\:top-11 {
    top: 2.75rem;
  }
  .xxs\:right-11 {
    right: 2.75rem;
  }
  .xxs\:bottom-11 {
    bottom: 2.75rem;
  }
  .xxs\:left-11 {
    left: 2.75rem;
  }
  .xxs\:top-12 {
    top: 3rem;
  }
  .xxs\:right-12 {
    right: 3rem;
  }
  .xxs\:bottom-12 {
    bottom: 3rem;
  }
  .xxs\:left-12 {
    left: 3rem;
  }
  .xxs\:top-14 {
    top: 3.5rem;
  }
  .xxs\:right-14 {
    right: 3.5rem;
  }
  .xxs\:bottom-14 {
    bottom: 3.5rem;
  }
  .xxs\:left-14 {
    left: 3.5rem;
  }
  .xxs\:top-16 {
    top: 4rem;
  }
  .xxs\:right-16 {
    right: 4rem;
  }
  .xxs\:bottom-16 {
    bottom: 4rem;
  }
  .xxs\:left-16 {
    left: 4rem;
  }
  .xxs\:top-20 {
    top: 5rem;
  }
  .xxs\:right-20 {
    right: 5rem;
  }
  .xxs\:bottom-20 {
    bottom: 5rem;
  }
  .xxs\:left-20 {
    left: 5rem;
  }
  .xxs\:top-24 {
    top: 6rem;
  }
  .xxs\:right-24 {
    right: 6rem;
  }
  .xxs\:bottom-24 {
    bottom: 6rem;
  }
  .xxs\:left-24 {
    left: 6rem;
  }
  .xxs\:top-28 {
    top: 7rem;
  }
  .xxs\:right-28 {
    right: 7rem;
  }
  .xxs\:bottom-28 {
    bottom: 7rem;
  }
  .xxs\:left-28 {
    left: 7rem;
  }
  .xxs\:top-32 {
    top: 8rem;
  }
  .xxs\:right-32 {
    right: 8rem;
  }
  .xxs\:bottom-32 {
    bottom: 8rem;
  }
  .xxs\:left-32 {
    left: 8rem;
  }
  .xxs\:top-36 {
    top: 9rem;
  }
  .xxs\:right-36 {
    right: 9rem;
  }
  .xxs\:bottom-36 {
    bottom: 9rem;
  }
  .xxs\:left-36 {
    left: 9rem;
  }
  .xxs\:top-40 {
    top: 10rem;
  }
  .xxs\:right-40 {
    right: 10rem;
  }
  .xxs\:bottom-40 {
    bottom: 10rem;
  }
  .xxs\:left-40 {
    left: 10rem;
  }
  .xxs\:top-44 {
    top: 11rem;
  }
  .xxs\:right-44 {
    right: 11rem;
  }
  .xxs\:bottom-44 {
    bottom: 11rem;
  }
  .xxs\:left-44 {
    left: 11rem;
  }
  .xxs\:top-48 {
    top: 12rem;
  }
  .xxs\:right-48 {
    right: 12rem;
  }
  .xxs\:bottom-48 {
    bottom: 12rem;
  }
  .xxs\:left-48 {
    left: 12rem;
  }
  .xxs\:top-52 {
    top: 13rem;
  }
  .xxs\:right-52 {
    right: 13rem;
  }
  .xxs\:bottom-52 {
    bottom: 13rem;
  }
  .xxs\:left-52 {
    left: 13rem;
  }
  .xxs\:top-56 {
    top: 14rem;
  }
  .xxs\:right-56 {
    right: 14rem;
  }
  .xxs\:bottom-56 {
    bottom: 14rem;
  }
  .xxs\:left-56 {
    left: 14rem;
  }
  .xxs\:top-60 {
    top: 15rem;
  }
  .xxs\:right-60 {
    right: 15rem;
  }
  .xxs\:bottom-60 {
    bottom: 15rem;
  }
  .xxs\:left-60 {
    left: 15rem;
  }
  .xxs\:top-64 {
    top: 16rem;
  }
  .xxs\:right-64 {
    right: 16rem;
  }
  .xxs\:bottom-64 {
    bottom: 16rem;
  }
  .xxs\:left-64 {
    left: 16rem;
  }
  .xxs\:top-72 {
    top: 18rem;
  }
  .xxs\:right-72 {
    right: 18rem;
  }
  .xxs\:bottom-72 {
    bottom: 18rem;
  }
  .xxs\:left-72 {
    left: 18rem;
  }
  .xxs\:top-80 {
    top: 20rem;
  }
  .xxs\:right-80 {
    right: 20rem;
  }
  .xxs\:bottom-80 {
    bottom: 20rem;
  }
  .xxs\:left-80 {
    left: 20rem;
  }
  .xxs\:top-96 {
    top: 24rem;
  }
  .xxs\:right-96 {
    right: 24rem;
  }
  .xxs\:bottom-96 {
    bottom: 24rem;
  }
  .xxs\:left-96 {
    left: 24rem;
  }
  .xxs\:top-auto {
    top: auto;
  }
  .xxs\:right-auto {
    right: auto;
  }
  .xxs\:bottom-auto {
    bottom: auto;
  }
  .xxs\:left-auto {
    left: auto;
  }
  .xxs\:top-px {
    top: 1px;
  }
  .xxs\:right-px {
    right: 1px;
  }
  .xxs\:bottom-px {
    bottom: 1px;
  }
  .xxs\:left-px {
    left: 1px;
  }
  .xxs\:top-0\.5 {
    top: 0.125rem;
  }
  .xxs\:right-0\.5 {
    right: 0.125rem;
  }
  .xxs\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .xxs\:left-0\.5 {
    left: 0.125rem;
  }
  .xxs\:top-1\.5 {
    top: 0.375rem;
  }
  .xxs\:right-1\.5 {
    right: 0.375rem;
  }
  .xxs\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .xxs\:left-1\.5 {
    left: 0.375rem;
  }
  .xxs\:top-2\.5 {
    top: 0.625rem;
  }
  .xxs\:right-2\.5 {
    right: 0.625rem;
  }
  .xxs\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .xxs\:left-2\.5 {
    left: 0.625rem;
  }
  .xxs\:top-3\.5 {
    top: 0.875rem;
  }
  .xxs\:right-3\.5 {
    right: 0.875rem;
  }
  .xxs\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .xxs\:left-3\.5 {
    left: 0.875rem;
  }
  .xxs\:-top-0 {
    top: 0;
  }
  .xxs\:-right-0 {
    right: 0;
  }
  .xxs\:-bottom-0 {
    bottom: 0;
  }
  .xxs\:-left-0 {
    left: 0;
  }
  .xxs\:-top-1 {
    top: -0.25rem;
  }
  .xxs\:-right-1 {
    right: -0.25rem;
  }
  .xxs\:-bottom-1 {
    bottom: -0.25rem;
  }
  .xxs\:-left-1 {
    left: -0.25rem;
  }
  .xxs\:-top-2 {
    top: -0.5rem;
  }
  .xxs\:-right-2 {
    right: -0.5rem;
  }
  .xxs\:-bottom-2 {
    bottom: -0.5rem;
  }
  .xxs\:-left-2 {
    left: -0.5rem;
  }
  .xxs\:-top-3 {
    top: -0.75rem;
  }
  .xxs\:-right-3 {
    right: -0.75rem;
  }
  .xxs\:-bottom-3 {
    bottom: -0.75rem;
  }
  .xxs\:-left-3 {
    left: -0.75rem;
  }
  .xxs\:-top-4 {
    top: -1rem;
  }
  .xxs\:-right-4 {
    right: -1rem;
  }
  .xxs\:-bottom-4 {
    bottom: -1rem;
  }
  .xxs\:-left-4 {
    left: -1rem;
  }
  .xxs\:-top-5 {
    top: -1.25rem;
  }
  .xxs\:-right-5 {
    right: -1.25rem;
  }
  .xxs\:-bottom-5 {
    bottom: -1.25rem;
  }
  .xxs\:-left-5 {
    left: -1.25rem;
  }
  .xxs\:-top-6 {
    top: -1.5rem;
  }
  .xxs\:-right-6 {
    right: -1.5rem;
  }
  .xxs\:-bottom-6 {
    bottom: -1.5rem;
  }
  .xxs\:-left-6 {
    left: -1.5rem;
  }
  .xxs\:-top-7 {
    top: -1.75rem;
  }
  .xxs\:-right-7 {
    right: -1.75rem;
  }
  .xxs\:-bottom-7 {
    bottom: -1.75rem;
  }
  .xxs\:-left-7 {
    left: -1.75rem;
  }
  .xxs\:-top-8 {
    top: -2rem;
  }
  .xxs\:-right-8 {
    right: -2rem;
  }
  .xxs\:-bottom-8 {
    bottom: -2rem;
  }
  .xxs\:-left-8 {
    left: -2rem;
  }
  .xxs\:-top-9 {
    top: -2.25rem;
  }
  .xxs\:-right-9 {
    right: -2.25rem;
  }
  .xxs\:-bottom-9 {
    bottom: -2.25rem;
  }
  .xxs\:-left-9 {
    left: -2.25rem;
  }
  .xxs\:-top-10 {
    top: -2.5rem;
  }
  .xxs\:-right-10 {
    right: -2.5rem;
  }
  .xxs\:-bottom-10 {
    bottom: -2.5rem;
  }
  .xxs\:-left-10 {
    left: -2.5rem;
  }
  .xxs\:-top-11 {
    top: -2.75rem;
  }
  .xxs\:-right-11 {
    right: -2.75rem;
  }
  .xxs\:-bottom-11 {
    bottom: -2.75rem;
  }
  .xxs\:-left-11 {
    left: -2.75rem;
  }
  .xxs\:-top-12 {
    top: -3rem;
  }
  .xxs\:-right-12 {
    right: -3rem;
  }
  .xxs\:-bottom-12 {
    bottom: -3rem;
  }
  .xxs\:-left-12 {
    left: -3rem;
  }
  .xxs\:-top-14 {
    top: -3.5rem;
  }
  .xxs\:-right-14 {
    right: -3.5rem;
  }
  .xxs\:-bottom-14 {
    bottom: -3.5rem;
  }
  .xxs\:-left-14 {
    left: -3.5rem;
  }
  .xxs\:-top-16 {
    top: -4rem;
  }
  .xxs\:-right-16 {
    right: -4rem;
  }
  .xxs\:-bottom-16 {
    bottom: -4rem;
  }
  .xxs\:-left-16 {
    left: -4rem;
  }
  .xxs\:-top-20 {
    top: -5rem;
  }
  .xxs\:-right-20 {
    right: -5rem;
  }
  .xxs\:-bottom-20 {
    bottom: -5rem;
  }
  .xxs\:-left-20 {
    left: -5rem;
  }
  .xxs\:-top-24 {
    top: -6rem;
  }
  .xxs\:-right-24 {
    right: -6rem;
  }
  .xxs\:-bottom-24 {
    bottom: -6rem;
  }
  .xxs\:-left-24 {
    left: -6rem;
  }
  .xxs\:-top-28 {
    top: -7rem;
  }
  .xxs\:-right-28 {
    right: -7rem;
  }
  .xxs\:-bottom-28 {
    bottom: -7rem;
  }
  .xxs\:-left-28 {
    left: -7rem;
  }
  .xxs\:-top-32 {
    top: -8rem;
  }
  .xxs\:-right-32 {
    right: -8rem;
  }
  .xxs\:-bottom-32 {
    bottom: -8rem;
  }
  .xxs\:-left-32 {
    left: -8rem;
  }
  .xxs\:-top-36 {
    top: -9rem;
  }
  .xxs\:-right-36 {
    right: -9rem;
  }
  .xxs\:-bottom-36 {
    bottom: -9rem;
  }
  .xxs\:-left-36 {
    left: -9rem;
  }
  .xxs\:-top-40 {
    top: -10rem;
  }
  .xxs\:-right-40 {
    right: -10rem;
  }
  .xxs\:-bottom-40 {
    bottom: -10rem;
  }
  .xxs\:-left-40 {
    left: -10rem;
  }
  .xxs\:-top-44 {
    top: -11rem;
  }
  .xxs\:-right-44 {
    right: -11rem;
  }
  .xxs\:-bottom-44 {
    bottom: -11rem;
  }
  .xxs\:-left-44 {
    left: -11rem;
  }
  .xxs\:-top-48 {
    top: -12rem;
  }
  .xxs\:-right-48 {
    right: -12rem;
  }
  .xxs\:-bottom-48 {
    bottom: -12rem;
  }
  .xxs\:-left-48 {
    left: -12rem;
  }
  .xxs\:-top-52 {
    top: -13rem;
  }
  .xxs\:-right-52 {
    right: -13rem;
  }
  .xxs\:-bottom-52 {
    bottom: -13rem;
  }
  .xxs\:-left-52 {
    left: -13rem;
  }
  .xxs\:-top-56 {
    top: -14rem;
  }
  .xxs\:-right-56 {
    right: -14rem;
  }
  .xxs\:-bottom-56 {
    bottom: -14rem;
  }
  .xxs\:-left-56 {
    left: -14rem;
  }
  .xxs\:-top-60 {
    top: -15rem;
  }
  .xxs\:-right-60 {
    right: -15rem;
  }
  .xxs\:-bottom-60 {
    bottom: -15rem;
  }
  .xxs\:-left-60 {
    left: -15rem;
  }
  .xxs\:-top-64 {
    top: -16rem;
  }
  .xxs\:-right-64 {
    right: -16rem;
  }
  .xxs\:-bottom-64 {
    bottom: -16rem;
  }
  .xxs\:-left-64 {
    left: -16rem;
  }
  .xxs\:-top-72 {
    top: -18rem;
  }
  .xxs\:-right-72 {
    right: -18rem;
  }
  .xxs\:-bottom-72 {
    bottom: -18rem;
  }
  .xxs\:-left-72 {
    left: -18rem;
  }
  .xxs\:-top-80 {
    top: -20rem;
  }
  .xxs\:-right-80 {
    right: -20rem;
  }
  .xxs\:-bottom-80 {
    bottom: -20rem;
  }
  .xxs\:-left-80 {
    left: -20rem;
  }
  .xxs\:-top-96 {
    top: -24rem;
  }
  .xxs\:-right-96 {
    right: -24rem;
  }
  .xxs\:-bottom-96 {
    bottom: -24rem;
  }
  .xxs\:-left-96 {
    left: -24rem;
  }
  .xxs\:-top-px {
    top: -1px;
  }
  .xxs\:-right-px {
    right: -1px;
  }
  .xxs\:-bottom-px {
    bottom: -1px;
  }
  .xxs\:-left-px {
    left: -1px;
  }
  .xxs\:-top-0\.5 {
    top: -0.125rem;
  }
  .xxs\:-right-0\.5 {
    right: -0.125rem;
  }
  .xxs\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .xxs\:-left-0\.5 {
    left: -0.125rem;
  }
  .xxs\:-top-1\.5 {
    top: -0.375rem;
  }
  .xxs\:-right-1\.5 {
    right: -0.375rem;
  }
  .xxs\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .xxs\:-left-1\.5 {
    left: -0.375rem;
  }
  .xxs\:-top-2\.5 {
    top: -0.625rem;
  }
  .xxs\:-right-2\.5 {
    right: -0.625rem;
  }
  .xxs\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .xxs\:-left-2\.5 {
    left: -0.625rem;
  }
  .xxs\:-top-3\.5 {
    top: -0.875rem;
  }
  .xxs\:-right-3\.5 {
    right: -0.875rem;
  }
  .xxs\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .xxs\:-left-3\.5 {
    left: -0.875rem;
  }
  .xxs\:top-1\/2 {
    top: 50%;
  }
  .xxs\:right-1\/2 {
    right: 50%;
  }
  .xxs\:bottom-1\/2 {
    bottom: 50%;
  }
  .xxs\:left-1\/2 {
    left: 50%;
  }
  .xxs\:top-1\/3 {
    top: 33.333333%;
  }
  .xxs\:right-1\/3 {
    right: 33.333333%;
  }
  .xxs\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .xxs\:left-1\/3 {
    left: 33.333333%;
  }
  .xxs\:top-2\/3 {
    top: 66.666667%;
  }
  .xxs\:right-2\/3 {
    right: 66.666667%;
  }
  .xxs\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .xxs\:left-2\/3 {
    left: 66.666667%;
  }
  .xxs\:top-1\/4 {
    top: 25%;
  }
  .xxs\:right-1\/4 {
    right: 25%;
  }
  .xxs\:bottom-1\/4 {
    bottom: 25%;
  }
  .xxs\:left-1\/4 {
    left: 25%;
  }
  .xxs\:top-2\/4 {
    top: 50%;
  }
  .xxs\:right-2\/4 {
    right: 50%;
  }
  .xxs\:bottom-2\/4 {
    bottom: 50%;
  }
  .xxs\:left-2\/4 {
    left: 50%;
  }
  .xxs\:top-3\/4 {
    top: 75%;
  }
  .xxs\:right-3\/4 {
    right: 75%;
  }
  .xxs\:bottom-3\/4 {
    bottom: 75%;
  }
  .xxs\:left-3\/4 {
    left: 75%;
  }
  .xxs\:top-full {
    top: 100%;
  }
  .xxs\:right-full {
    right: 100%;
  }
  .xxs\:bottom-full {
    bottom: 100%;
  }
  .xxs\:left-full {
    left: 100%;
  }
  .xxs\:-top-1\/2 {
    top: -50%;
  }
  .xxs\:-right-1\/2 {
    right: -50%;
  }
  .xxs\:-bottom-1\/2 {
    bottom: -50%;
  }
  .xxs\:-left-1\/2 {
    left: -50%;
  }
  .xxs\:-top-1\/3 {
    top: -33.333333%;
  }
  .xxs\:-right-1\/3 {
    right: -33.333333%;
  }
  .xxs\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .xxs\:-left-1\/3 {
    left: -33.333333%;
  }
  .xxs\:-top-2\/3 {
    top: -66.666667%;
  }
  .xxs\:-right-2\/3 {
    right: -66.666667%;
  }
  .xxs\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .xxs\:-left-2\/3 {
    left: -66.666667%;
  }
  .xxs\:-top-1\/4 {
    top: -25%;
  }
  .xxs\:-right-1\/4 {
    right: -25%;
  }
  .xxs\:-bottom-1\/4 {
    bottom: -25%;
  }
  .xxs\:-left-1\/4 {
    left: -25%;
  }
  .xxs\:-top-2\/4 {
    top: -50%;
  }
  .xxs\:-right-2\/4 {
    right: -50%;
  }
  .xxs\:-bottom-2\/4 {
    bottom: -50%;
  }
  .xxs\:-left-2\/4 {
    left: -50%;
  }
  .xxs\:-top-3\/4 {
    top: -75%;
  }
  .xxs\:-right-3\/4 {
    right: -75%;
  }
  .xxs\:-bottom-3\/4 {
    bottom: -75%;
  }
  .xxs\:-left-3\/4 {
    left: -75%;
  }
  .xxs\:-top-full {
    top: -100%;
  }
  .xxs\:-right-full {
    right: -100%;
  }
  .xxs\:-bottom-full {
    bottom: -100%;
  }
  .xxs\:-left-full {
    left: -100%;
  }
  .xxs\:resize-none {
    resize: none;
  }
  .xxs\:resize-y {
    resize: vertical;
  }
  .xxs\:resize-x {
    resize: horizontal;
  }
  .xxs\:resize {
    resize: both;
  }
  .xxs\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxs\:shadow,
  .xxs\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxs\:shadow-lg,
  .xxs\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxs\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxs\:shadow-2xl,
  .xxs\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxs\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:shadow-inner,
  .xxs\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .xxs\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxs\:shadow-bottom-nav,
  .xxs\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxs\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .xxs\:group-hover\:shadow-sm,
  .xxs\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .xxs\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxs\:group-hover\:shadow,
  .group:hover .xxs\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxs\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .xxs\:group-hover\:shadow-lg,
  .group:hover .xxs\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .xxs\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .xxs\:group-hover\:shadow-2xl,
  .group:hover .xxs\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxs\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .xxs\:group-hover\:shadow-none,
  .group:hover .xxs\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .xxs\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .xxs\:group-hover\:shadow-bottom-nav,
  .group:hover .xxs\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxs\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxs\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxs\:hover\:shadow-sm:hover,
  .xxs\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxs\:hover\:shadow-lg:hover,
  .xxs\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxs\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxs\:hover\:shadow-2xl:hover,
  .xxs\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxs\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:hover\:shadow-inner:hover,
  .xxs\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .xxs\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxs\:hover\:shadow-bottom-nav:hover,
  .xxs\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxs\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxs\:focus\:shadow-sm:focus,
  .xxs\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxs\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:focus\:shadow-md:focus,
  .xxs\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxs\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxs\:focus\:shadow-lg:focus,
  .xxs\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxs\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxs\:focus\:shadow-2xl:focus,
  .xxs\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxs\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .xxs\:focus\:shadow-none:focus,
  .xxs\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxs\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxs\:focus\:shadow-bottom-nav:focus,
  .xxs\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxs\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxs\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring-0,
  .xxs\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring-2,
  .xxs\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring,
  .xxs\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:ring-inset {
    --tw-ring-inset: inset;
  }
  .xxs\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .xxs\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-0:focus,
  .xxs\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-2:focus,
  .xxs\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-8:focus,
  .xxs\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxs\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxs\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .xxs\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .xxs\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .xxs\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .xxs\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .xxs\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxs\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxs\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxs\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .xxs\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .xxs\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .xxs\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .xxs\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .xxs\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxs\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxs\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .xxs\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxs\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .xxs\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .xxs\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .xxs\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxs\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .xxs\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxs\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxs\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxs\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .xxs\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxs\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxs\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxs\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .xxs\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .xxs\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .xxs\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .xxs\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxs\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxs\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxs\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxs\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .xxs\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .xxs\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .xxs\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .xxs\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .xxs\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .xxs\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxs\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxs\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxs\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxs\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxs\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxs\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .xxs\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxs\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .xxs\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxs\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxs\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxs\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxs\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxs\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .xxs\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .xxs\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxs\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .xxs\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .xxs\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .xxs\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxs\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxs\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxs\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxs\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxs\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxs\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxs\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxs\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxs\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxs\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxs\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxs\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxs\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxs\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .xxs\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .xxs\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .xxs\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .xxs\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .xxs\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .xxs\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxs\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .xxs\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .xxs\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxs\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxs\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .xxs\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxs\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .xxs\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .xxs\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxs\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .xxs\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .xxs\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxs\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .xxs\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .xxs\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxs\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .xxs\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxs\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxs\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxs\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .xxs\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxs\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxs\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxs\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxs\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxs\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxs\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .xxs\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .xxs\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxs\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .xxs\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .xxs\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxs\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxs\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .xxs\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .xxs\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .xxs\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .xxs\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxs\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .xxs\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .xxs\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxs\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .xxs\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .xxs\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxs\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxs\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .xxs\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .xxs\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .xxs\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .xxs\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxs\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxs\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxs\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .xxs\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .xxs\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .xxs\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .xxs\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .xxs\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxs\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxs\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .xxs\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxs\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .xxs\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .xxs\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .xxs\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxs\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .xxs\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxs\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxs\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxs\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .xxs\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxs\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxs\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxs\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .xxs\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .xxs\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .xxs\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .xxs\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxs\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxs\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxs\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxs\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .xxs\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .xxs\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .xxs\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .xxs\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .xxs\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .xxs\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxs\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxs\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxs\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxs\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxs\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxs\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .xxs\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxs\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .xxs\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxs\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxs\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxs\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxs\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxs\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .xxs\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .xxs\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxs\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .xxs\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .xxs\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .xxs\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxs\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxs\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxs\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxs\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxs\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxs\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxs\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxs\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxs\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxs\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxs\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxs\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxs\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxs\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .xxs\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .xxs\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .xxs\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .xxs\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .xxs\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .xxs\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxs\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .xxs\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .xxs\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxs\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxs\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .xxs\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxs\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .xxs\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .xxs\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxs\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .xxs\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .xxs\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxs\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .xxs\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .xxs\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxs\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .xxs\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxs\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxs\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxs\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .xxs\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxs\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxs\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxs\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxs\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxs\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxs\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .xxs\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .xxs\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxs\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .xxs\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .xxs\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxs\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxs\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .xxs\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .xxs\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .xxs\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .xxs\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxs\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .xxs\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .xxs\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxs\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .xxs\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .xxs\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxs\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxs\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .xxs\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .xxs\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .xxs\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .xxs\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxs\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxs\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxs\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .xxs\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .xxs\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .xxs\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .xxs\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .xxs\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxs\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxs\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .xxs\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxs\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .xxs\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .xxs\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .xxs\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxs\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .xxs\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxs\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxs\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxs\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .xxs\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxs\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxs\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxs\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .xxs\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .xxs\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .xxs\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .xxs\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxs\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxs\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxs\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxs\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .xxs\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .xxs\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .xxs\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .xxs\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .xxs\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .xxs\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxs\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxs\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxs\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxs\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxs\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxs\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .xxs\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxs\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .xxs\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxs\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxs\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxs\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxs\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxs\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .xxs\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .xxs\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxs\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .xxs\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .xxs\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .xxs\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxs\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxs\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxs\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxs\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxs\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxs\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxs\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxs\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxs\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxs\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxs\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxs\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxs\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxs\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .xxs\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .xxs\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .xxs\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .xxs\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .xxs\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .xxs\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxs\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .xxs\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .xxs\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxs\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxs\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .xxs\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxs\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .xxs\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .xxs\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxs\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .xxs\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .xxs\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxs\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .xxs\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .xxs\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxs\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .xxs\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxs\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxs\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxs\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .xxs\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxs\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxs\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxs\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxs\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxs\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxs\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .xxs\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .xxs\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxs\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .xxs\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .xxs\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .xxs\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .xxs\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .xxs\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .xxs\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxs\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .xxs\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .xxs\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxs\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxs\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .xxs\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .xxs\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxs\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxs\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxs\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .xxs\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .xxs\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .xxs\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .xxs\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .xxs\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .xxs\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .xxs\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .xxs\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .xxs\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .xxs\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .xxs\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .xxs\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .xxs\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .xxs\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .xxs\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .xxs\:ring-current {
    --tw-ring-color: currentColor;
  }
  .xxs\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxs\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxs\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxs\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxs\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxs\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxs\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxs\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxs\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxs\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .xxs\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxs\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxs\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxs\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxs\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxs\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxs\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxs\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxs\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxs\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxs\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxs\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxs\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .xxs\:ring-background {
    --tw-ring-color: var(--background);
  }
  .xxs\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .xxs\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .xxs\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .xxs\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .xxs\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .xxs\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .xxs\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .xxs\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxs\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxs\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .xxs\:ring-success {
    --tw-ring-color: var(--success);
  }
  .xxs\:ring-error {
    --tw-ring-color: var(--error);
  }
  .xxs\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .xxs\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxs\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxs\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxs\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .xxs\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxs\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxs\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .xxs\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .xxs\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .xxs\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .xxs\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .xxs\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .xxs\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .xxs\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .xxs\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .xxs\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .xxs\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .xxs\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .xxs\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxs\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxs\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .xxs\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .xxs\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .xxs\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .xxs\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxs\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxs\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .xxs\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxs\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .xxs\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .xxs\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .xxs\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .xxs\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .xxs\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .xxs\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .xxs\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .xxs\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .xxs\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .xxs\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .xxs\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .xxs\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxs\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxs\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .xxs\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .xxs\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .xxs\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .xxs\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .xxs\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxs\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxs\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .xxs\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .xxs\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .xxs\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .xxs\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .xxs\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .xxs\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .xxs\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .xxs\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .xxs\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .xxs\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .xxs\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .xxs\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .xxs\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .xxs\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .xxs\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .xxs\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .xxs\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .xxs\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .xxs\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .xxs\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .xxs\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .xxs\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .xxs\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .xxs\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .xxs\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .xxs\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .xxs\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .xxs\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .xxs\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .xxs\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .xxs\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .xxs\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .xxs\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .xxs\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .xxs\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .xxs\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .xxs\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .xxs\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .xxs\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .xxs\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .xxs\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .xxs\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .xxs\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .xxs\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .xxs\:fill-current {
    fill: currentColor;
  }
  .xxs\:stroke-current {
    stroke: currentColor;
  }
  .xxs\:stroke-0 {
    stroke-width: 0;
  }
  .xxs\:stroke-1 {
    stroke-width: 1;
  }
  .xxs\:stroke-2 {
    stroke-width: 2;
  }
  .xxs\:table-auto {
    table-layout: auto;
  }
  .xxs\:table-fixed {
    table-layout: fixed;
  }
  .xxs\:text-left {
    text-align: left;
  }
  .xxs\:text-center {
    text-align: center;
  }
  .xxs\:text-right {
    text-align: right;
  }
  .xxs\:text-justify {
    text-align: justify;
  }
  .xxs\:text-transparent {
    color: transparent;
  }
  .xxs\:text-current {
    color: currentColor;
  }
  .xxs\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxs\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxs\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxs\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxs\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxs\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxs\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxs\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxs\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxs\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxs\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxs\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxs\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxs\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxs\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxs\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxs\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxs\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxs\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxs\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxs\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxs\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxs\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxs\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxs\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxs\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxs\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxs\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxs\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxs\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxs\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxs\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxs\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxs\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxs\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxs\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxs\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxs\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxs\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxs\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxs\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxs\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxs\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxs\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxs\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxs\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxs\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxs\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxs\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxs\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxs\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxs\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxs\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxs\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxs\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxs\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxs\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxs\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxs\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxs\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxs\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxs\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxs\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:text-background-primary {
    color: var(--background-primary);
  }
  .xxs\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxs\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxs\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxs\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxs\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxs\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxs\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxs\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxs\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxs\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxs\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxs\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxs\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxs\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxs\:text-body-background {
    color: var(--body-background);
  }
  .xxs\:text-background {
    color: var(--background);
  }
  .xxs\:text-input-background {
    color: var(--input-background);
  }
  .xxs\:text-body-text {
    color: var(--body-text);
  }
  .xxs\:text-header-text {
    color: var(--header-text);
  }
  .xxs\:text-primary {
    color: var(--primary);
  }
  .xxs\:text-secondary {
    color: var(--secondary);
  }
  .xxs\:text-tertiary {
    color: var(--tertiary);
  }
  .xxs\:text-primary-hover {
    color: var(--primary-hover);
  }
  .xxs\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .xxs\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .xxs\:text-input-text {
    color: var(--input-text);
  }
  .xxs\:text-success {
    color: var(--success);
  }
  .xxs\:text-error {
    color: var(--error);
  }
  .xxs\:text-custom-loader {
    color: var(--custom-loader);
  }
  .xxs\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxs\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .xxs\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .xxs\:text-background50 {
    color: var(--background50);
  }
  .xxs\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxs\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxs\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxs\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxs\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxs\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxs\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxs\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxs\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxs\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .xxs\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .xxs\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxs\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxs\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .xxs\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .xxs\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .xxs\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .xxs\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .xxs\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .xxs\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .xxs\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .xxs\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .xxs\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .xxs\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .xxs\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .xxs\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .xxs\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .xxs\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .xxs\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .xxs\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .xxs\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .xxs\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .xxs\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .xxs\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .xxs\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .xxs\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .xxs\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .xxs\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .xxs\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .xxs\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .xxs\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .xxs\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .xxs\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .xxs\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .xxs\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .xxs\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .xxs\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .xxs\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .xxs\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .xxs\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .xxs\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .xxs\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .xxs\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .xxs\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .xxs\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxs\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-transparent:hover {
    color: transparent;
  }
  .xxs\:hover\:text-current:hover {
    color: currentColor;
  }
  .xxs\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .xxs\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .xxs\:hover\:text-background:hover {
    color: var(--background);
  }
  .xxs\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .xxs\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .xxs\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .xxs\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .xxs\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .xxs\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .xxs\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .xxs\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .xxs\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .xxs\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .xxs\:hover\:text-success:hover {
    color: var(--success);
  }
  .xxs\:hover\:text-error:hover {
    color: var(--error);
  }
  .xxs\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .xxs\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .xxs\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .xxs\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .xxs\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxs\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-transparent:focus {
    color: transparent;
  }
  .xxs\:focus\:text-current:focus {
    color: currentColor;
  }
  .xxs\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxs\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxs\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .xxs\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .xxs\:focus\:text-background:focus {
    color: var(--background);
  }
  .xxs\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .xxs\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .xxs\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .xxs\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .xxs\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .xxs\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .xxs\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .xxs\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .xxs\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .xxs\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .xxs\:focus\:text-success:focus {
    color: var(--success);
  }
  .xxs\:focus\:text-error:focus {
    color: var(--error);
  }
  .xxs\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .xxs\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .xxs\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .xxs\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .xxs\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxs\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxs\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .xxs\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .xxs\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .xxs\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .xxs\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .xxs\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .xxs\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .xxs\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .xxs\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .xxs\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .xxs\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .xxs\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .xxs\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .xxs\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .xxs\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .xxs\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .xxs\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .xxs\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .xxs\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .xxs\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .xxs\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .xxs\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .xxs\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .xxs\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .xxs\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .xxs\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .xxs\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .xxs\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .xxs\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .xxs\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .xxs\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .xxs\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .xxs\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .xxs\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .xxs\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .xxs\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .xxs\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .xxs\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .xxs\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .xxs\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .xxs\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .xxs\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .xxs\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .xxs\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .xxs\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .xxs\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .xxs\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .xxs\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .xxs\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .xxs\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .xxs\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .xxs\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .xxs\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .xxs\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .xxs\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .xxs\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .xxs\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .xxs\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .xxs\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .xxs\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .xxs\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .xxs\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .xxs\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .xxs\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .xxs\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .xxs\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .xxs\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .xxs\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .xxs\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .xxs\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .xxs\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .xxs\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .xxs\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .xxs\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .xxs\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .xxs\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .xxs\:overflow-ellipsis,
  .xxs\:truncate {
    text-overflow: ellipsis;
  }
  .xxs\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .xxs\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .xxs\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .xxs\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .xxs\:italic {
    font-style: italic;
  }
  .xxs\:not-italic {
    font-style: normal;
  }
  .xxs\:uppercase {
    text-transform: uppercase;
  }
  .xxs\:lowercase {
    text-transform: lowercase;
  }
  .xxs\:capitalize {
    text-transform: capitalize;
  }
  .xxs\:normal-case {
    text-transform: none;
  }
  .xxs\:underline {
    text-decoration: underline;
  }
  .xxs\:line-through {
    text-decoration: line-through;
  }
  .xxs\:no-underline {
    text-decoration: none;
  }
  .group:hover .xxs\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .xxs\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .xxs\:group-hover\:no-underline {
    text-decoration: none;
  }
  .xxs\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .xxs\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .xxs\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .xxs\:hover\:underline:hover {
    text-decoration: underline;
  }
  .xxs\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .xxs\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .xxs\:focus\:underline:focus {
    text-decoration: underline;
  }
  .xxs\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .xxs\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .xxs\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .xxs\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .xxs\:diagonal-fractions,
  .xxs\:lining-nums,
  .xxs\:oldstyle-nums,
  .xxs\:ordinal,
  .xxs\:proportional-nums,
  .xxs\:slashed-zero,
  .xxs\:stacked-fractions,
  .xxs\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .xxs\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .xxs\:ordinal {
    --tw-ordinal: ordinal;
  }
  .xxs\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .xxs\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .xxs\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .xxs\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .xxs\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .xxs\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .xxs\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .xxs\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .xxs\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .xxs\:tracking-normal {
    letter-spacing: 0;
  }
  .xxs\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .xxs\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .xxs\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .xxs\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .xxs\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .xxs\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .xxs\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .xxs\:align-baseline {
    vertical-align: baseline;
  }
  .xxs\:align-top {
    vertical-align: top;
  }
  .xxs\:align-middle {
    vertical-align: middle;
  }
  .xxs\:align-bottom {
    vertical-align: bottom;
  }
  .xxs\:align-text-top {
    vertical-align: text-top;
  }
  .xxs\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .xxs\:visible {
    visibility: visible;
  }
  .xxs\:invisible {
    visibility: hidden;
  }
  .xxs\:whitespace-normal {
    white-space: normal;
  }
  .xxs\:whitespace-nowrap {
    white-space: nowrap;
  }
  .xxs\:whitespace-pre {
    white-space: pre;
  }
  .xxs\:whitespace-pre-line {
    white-space: pre-line;
  }
  .xxs\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .xxs\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .xxs\:break-words {
    overflow-wrap: break-word;
  }
  .xxs\:break-all {
    word-break: break-all;
  }
  .group:hover .xxs\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .xxs\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .xxs\:group-hover\:break-all {
    word-break: break-all;
  }
  .xxs\:w-0 {
    width: 0;
  }
  .xxs\:w-1 {
    width: 0.25rem;
  }
  .xxs\:w-2 {
    width: 0.5rem;
  }
  .xxs\:w-3 {
    width: 0.75rem;
  }
  .xxs\:w-4 {
    width: 1rem;
  }
  .xxs\:w-5 {
    width: 1.25rem;
  }
  .xxs\:w-6 {
    width: 1.5rem;
  }
  .xxs\:w-7 {
    width: 1.75rem;
  }
  .xxs\:w-8 {
    width: 2rem;
  }
  .xxs\:w-9 {
    width: 2.25rem;
  }
  .xxs\:w-10 {
    width: 2.5rem;
  }
  .xxs\:w-11 {
    width: 2.75rem;
  }
  .xxs\:w-12 {
    width: 3rem;
  }
  .xxs\:w-14 {
    width: 3.5rem;
  }
  .xxs\:w-16 {
    width: 4rem;
  }
  .xxs\:w-20 {
    width: 5rem;
  }
  .xxs\:w-24 {
    width: 6rem;
  }
  .xxs\:w-28 {
    width: 7rem;
  }
  .xxs\:w-32 {
    width: 8rem;
  }
  .xxs\:w-36 {
    width: 9rem;
  }
  .xxs\:w-40 {
    width: 10rem;
  }
  .xxs\:w-44 {
    width: 11rem;
  }
  .xxs\:w-48 {
    width: 12rem;
  }
  .xxs\:w-52 {
    width: 13rem;
  }
  .xxs\:w-56 {
    width: 14rem;
  }
  .xxs\:w-60 {
    width: 15rem;
  }
  .xxs\:w-64 {
    width: 16rem;
  }
  .xxs\:w-72 {
    width: 18rem;
  }
  .xxs\:w-80 {
    width: 20rem;
  }
  .xxs\:w-96 {
    width: 24rem;
  }
  .xxs\:w-150 {
    width: 150px;
  }
  .xxs\:w-199 {
    width: 199px;
  }
  .xxs\:w-286 {
    width: 286px;
  }
  .xxs\:w-512 {
    width: 512px;
  }
  .xxs\:w-560 {
    width: 560px;
  }
  .xxs\:w-650 {
    width: 650px;
  }
  .xxs\:w-auto {
    width: auto;
  }
  .xxs\:w-px {
    width: 1px;
  }
  .xxs\:w-0\.5 {
    width: 0.125rem;
  }
  .xxs\:w-1\.5 {
    width: 0.375rem;
  }
  .xxs\:w-2\.5 {
    width: 0.625rem;
  }
  .xxs\:w-3\.5 {
    width: 0.875rem;
  }
  .xxs\:w-1\/2 {
    width: 50%;
  }
  .xxs\:w-1\/3 {
    width: 33.333333%;
  }
  .xxs\:w-2\/3 {
    width: 66.666667%;
  }
  .xxs\:w-1\/4 {
    width: 25%;
  }
  .xxs\:w-2\/4 {
    width: 50%;
  }
  .xxs\:w-3\/4 {
    width: 75%;
  }
  .xxs\:w-1\/5 {
    width: 20%;
  }
  .xxs\:w-2\/5 {
    width: 40%;
  }
  .xxs\:w-3\/5 {
    width: 60%;
  }
  .xxs\:w-4\/5 {
    width: 80%;
  }
  .xxs\:w-1\/6 {
    width: 16.666667%;
  }
  .xxs\:w-2\/6 {
    width: 33.333333%;
  }
  .xxs\:w-3\/6 {
    width: 50%;
  }
  .xxs\:w-4\/6 {
    width: 66.666667%;
  }
  .xxs\:w-5\/6 {
    width: 83.333333%;
  }
  .xxs\:w-1\/12 {
    width: 8.333333%;
  }
  .xxs\:w-2\/12 {
    width: 16.666667%;
  }
  .xxs\:w-3\/12 {
    width: 25%;
  }
  .xxs\:w-4\/12 {
    width: 33.333333%;
  }
  .xxs\:w-5\/12 {
    width: 41.666667%;
  }
  .xxs\:w-6\/12 {
    width: 50%;
  }
  .xxs\:w-7\/12 {
    width: 58.333333%;
  }
  .xxs\:w-8\/12 {
    width: 66.666667%;
  }
  .xxs\:w-9\/12 {
    width: 75%;
  }
  .xxs\:w-10\/12 {
    width: 83.333333%;
  }
  .xxs\:w-11\/12 {
    width: 91.666667%;
  }
  .xxs\:w-full {
    width: 100%;
  }
  .xxs\:w-screen {
    width: 100vw;
  }
  .xxs\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .xxs\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .xxs\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .xxs\:z-0 {
    z-index: 0;
  }
  .xxs\:z-10 {
    z-index: 10;
  }
  .xxs\:z-20 {
    z-index: 20;
  }
  .xxs\:z-30 {
    z-index: 30;
  }
  .xxs\:z-40 {
    z-index: 40;
  }
  .xxs\:z-50 {
    z-index: 50;
  }
  .xxs\:z-auto {
    z-index: auto;
  }
  .xxs\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .xxs\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .xxs\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .xxs\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .xxs\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .xxs\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .xxs\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .xxs\:focus\:z-0:focus {
    z-index: 0;
  }
  .xxs\:focus\:z-10:focus {
    z-index: 10;
  }
  .xxs\:focus\:z-20:focus {
    z-index: 20;
  }
  .xxs\:focus\:z-30:focus {
    z-index: 30;
  }
  .xxs\:focus\:z-40:focus {
    z-index: 40;
  }
  .xxs\:focus\:z-50:focus {
    z-index: 50;
  }
  .xxs\:focus\:z-auto:focus {
    z-index: auto;
  }
  .xxs\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .xxs\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .xxs\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .xxs\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .xxs\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .xxs\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .xxs\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .xxs\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .xxs\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .xxs\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .xxs\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .xxs\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .xxs\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .xxs\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .xxs\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .xxs\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .xxs\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .xxs\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .xxs\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .xxs\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .xxs\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .xxs\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .xxs\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .xxs\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .xxs\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .xxs\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .xxs\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .xxs\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .xxs\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .xxs\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .xxs\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .xxs\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .xxs\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .xxs\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .xxs\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .xxs\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .xxs\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .xxs\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .xxs\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .xxs\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .xxs\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .xxs\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .xxs\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .xxs\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .xxs\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .xxs\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .xxs\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .xxs\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .xxs\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .xxs\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .xxs\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .xxs\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .xxs\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .xxs\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .xxs\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .xxs\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .xxs\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .xxs\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .xxs\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .xxs\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .xxs\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .xxs\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .xxs\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .xxs\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .xxs\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .xxs\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .xxs\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .xxs\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .xxs\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .xxs\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .xxs\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .xxs\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .xxs\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .xxs\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .xxs\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .xxs\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .xxs\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .xxs\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .xxs\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .xxs\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .xxs\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .xxs\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .xxs\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .xxs\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .xxs\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .xxs\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .xxs\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .xxs\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .xxs\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .xxs\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .xxs\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .xxs\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .xxs\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .xxs\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .xxs\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .xxs\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .xxs\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .xxs\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .xxs\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .xxs\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .xxs\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .xxs\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .xxs\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .xxs\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .xxs\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .xxs\:grid-flow-row {
    grid-auto-flow: row;
  }
  .xxs\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xxs\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .xxs\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .xxs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xxs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xxs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xxs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xxs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xxs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xxs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xxs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xxs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xxs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xxs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xxs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xxs\:grid-cols-none {
    grid-template-columns: none;
  }
  .xxs\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .xxs\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .xxs\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .xxs\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .xxs\:col-auto {
    grid-column: auto;
  }
  .xxs\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xxs\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xxs\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xxs\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xxs\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xxs\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .xxs\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xxs\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .xxs\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .xxs\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .xxs\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .xxs\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .xxs\:col-span-full {
    grid-column: 1/-1;
  }
  .xxs\:col-start-1 {
    grid-column-start: 1;
  }
  .xxs\:col-start-2 {
    grid-column-start: 2;
  }
  .xxs\:col-start-3 {
    grid-column-start: 3;
  }
  .xxs\:col-start-4 {
    grid-column-start: 4;
  }
  .xxs\:col-start-5 {
    grid-column-start: 5;
  }
  .xxs\:col-start-6 {
    grid-column-start: 6;
  }
  .xxs\:col-start-7 {
    grid-column-start: 7;
  }
  .xxs\:col-start-8 {
    grid-column-start: 8;
  }
  .xxs\:col-start-9 {
    grid-column-start: 9;
  }
  .xxs\:col-start-10 {
    grid-column-start: 10;
  }
  .xxs\:col-start-11 {
    grid-column-start: 11;
  }
  .xxs\:col-start-12 {
    grid-column-start: 12;
  }
  .xxs\:col-start-13 {
    grid-column-start: 13;
  }
  .xxs\:col-start-auto {
    grid-column-start: auto;
  }
  .xxs\:col-end-1 {
    grid-column-end: 1;
  }
  .xxs\:col-end-2 {
    grid-column-end: 2;
  }
  .xxs\:col-end-3 {
    grid-column-end: 3;
  }
  .xxs\:col-end-4 {
    grid-column-end: 4;
  }
  .xxs\:col-end-5 {
    grid-column-end: 5;
  }
  .xxs\:col-end-6 {
    grid-column-end: 6;
  }
  .xxs\:col-end-7 {
    grid-column-end: 7;
  }
  .xxs\:col-end-8 {
    grid-column-end: 8;
  }
  .xxs\:col-end-9 {
    grid-column-end: 9;
  }
  .xxs\:col-end-10 {
    grid-column-end: 10;
  }
  .xxs\:col-end-11 {
    grid-column-end: 11;
  }
  .xxs\:col-end-12 {
    grid-column-end: 12;
  }
  .xxs\:col-end-13 {
    grid-column-end: 13;
  }
  .xxs\:col-end-auto {
    grid-column-end: auto;
  }
  .xxs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .xxs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xxs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xxs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xxs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xxs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xxs\:grid-rows-none {
    grid-template-rows: none;
  }
  .xxs\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .xxs\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .xxs\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .xxs\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .xxs\:row-auto {
    grid-row: auto;
  }
  .xxs\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xxs\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xxs\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xxs\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .xxs\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .xxs\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .xxs\:row-span-full {
    grid-row: 1/-1;
  }
  .xxs\:row-start-1 {
    grid-row-start: 1;
  }
  .xxs\:row-start-2 {
    grid-row-start: 2;
  }
  .xxs\:row-start-3 {
    grid-row-start: 3;
  }
  .xxs\:row-start-4 {
    grid-row-start: 4;
  }
  .xxs\:row-start-5 {
    grid-row-start: 5;
  }
  .xxs\:row-start-6 {
    grid-row-start: 6;
  }
  .xxs\:row-start-7 {
    grid-row-start: 7;
  }
  .xxs\:row-start-auto {
    grid-row-start: auto;
  }
  .xxs\:row-end-1 {
    grid-row-end: 1;
  }
  .xxs\:row-end-2 {
    grid-row-end: 2;
  }
  .xxs\:row-end-3 {
    grid-row-end: 3;
  }
  .xxs\:row-end-4 {
    grid-row-end: 4;
  }
  .xxs\:row-end-5 {
    grid-row-end: 5;
  }
  .xxs\:row-end-6 {
    grid-row-end: 6;
  }
  .xxs\:row-end-7 {
    grid-row-end: 7;
  }
  .xxs\:row-end-auto {
    grid-row-end: auto;
  }
  .xxs\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xxs\:transform,
  .xxs\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxs\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xxs\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .xxs\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .xxs\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .xxs\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .xxs\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .xxs\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .xxs\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .xxs\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .xxs\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .xxs\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .xxs\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxs\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxs\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxs\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxs\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxs\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxs\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxs\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxs\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxs\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxs\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .xxs\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .xxs\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .xxs\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .xxs\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .xxs\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .xxs\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .xxs\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .xxs\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .xxs\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .xxs\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .xxs\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .xxs\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .xxs\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .xxs\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .xxs\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .xxs\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .xxs\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .xxs\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .xxs\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .xxs\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxs\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxs\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxs\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxs\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxs\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxs\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxs\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxs\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxs\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxs\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .xxs\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .xxs\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .xxs\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .xxs\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .xxs\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .xxs\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .xxs\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .xxs\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .xxs\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .xxs\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .xxs\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .xxs\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .xxs\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .xxs\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .xxs\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .xxs\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .xxs\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .xxs\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .xxs\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .xxs\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxs\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxs\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxs\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxs\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxs\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxs\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxs\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxs\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxs\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxs\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .xxs\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .xxs\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .xxs\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .xxs\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .xxs\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .xxs\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .xxs\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .xxs\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .xxs\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .xxs\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .xxs\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .xxs\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .xxs\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .xxs\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .xxs\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .xxs\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .xxs\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .xxs\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .xxs\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .xxs\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .xxs\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .xxs\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .xxs\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .xxs\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .xxs\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .xxs\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .xxs\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .xxs\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .xxs\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .xxs\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .xxs\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .xxs\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .xxs\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .xxs\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .xxs\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .xxs\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .xxs\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .xxs\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .xxs\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .xxs\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .xxs\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .xxs\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .xxs\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .xxs\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .xxs\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .xxs\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .xxs\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .xxs\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .xxs\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .xxs\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .xxs\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .xxs\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .xxs\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .xxs\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .xxs\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .xxs\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .xxs\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .xxs\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .xxs\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .xxs\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .xxs\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .xxs\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .xxs\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .xxs\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .xxs\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .xxs\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .xxs\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .xxs\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .xxs\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .xxs\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .xxs\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xxs\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .xxs\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .xxs\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .xxs\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .xxs\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .xxs\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .xxs\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .xxs\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .xxs\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .xxs\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .xxs\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .xxs\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .xxs\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .xxs\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .xxs\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .xxs\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .xxs\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .xxs\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .xxs\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .xxs\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .xxs\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .xxs\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .xxs\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .xxs\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .xxs\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .xxs\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .xxs\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .xxs\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .xxs\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .xxs\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .xxs\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .xxs\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .xxs\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .xxs\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .xxs\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xxs\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .xxs\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .xxs\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .xxs\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .xxs\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .xxs\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .xxs\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .xxs\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .xxs\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .xxs\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .xxs\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .xxs\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .xxs\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .xxs\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .xxs\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .xxs\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .xxs\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .xxs\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .xxs\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .xxs\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .xxs\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .xxs\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .xxs\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .xxs\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .xxs\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .xxs\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .xxs\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .xxs\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .xxs\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .xxs\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .xxs\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .xxs\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .xxs\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .xxs\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .xxs\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .xxs\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .xxs\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .xxs\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .xxs\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .xxs\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .xxs\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .xxs\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .xxs\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .xxs\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .xxs\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .xxs\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .xxs\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .xxs\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .xxs\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xxs\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .xxs\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .xxs\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .xxs\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .xxs\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .xxs\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .xxs\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .xxs\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .xxs\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .xxs\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .xxs\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .xxs\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .xxs\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .xxs\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .xxs\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .xxs\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .xxs\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .xxs\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .xxs\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .xxs\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .xxs\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .xxs\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .xxs\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .xxs\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .xxs\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .xxs\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .xxs\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .xxs\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .xxs\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .xxs\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .xxs\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .xxs\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .xxs\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .xxs\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .xxs\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xxs\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .xxs\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .xxs\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .xxs\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .xxs\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .xxs\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .xxs\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .xxs\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .xxs\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .xxs\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .xxs\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .xxs\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .xxs\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .xxs\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .xxs\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .xxs\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .xxs\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .xxs\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .xxs\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .xxs\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .xxs\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .xxs\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .xxs\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .xxs\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .xxs\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .xxs\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .xxs\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .xxs\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .xxs\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .xxs\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .xxs\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .xxs\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .xxs\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .xxs\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .xxs\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .xxs\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .xxs\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .xxs\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .xxs\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .xxs\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .xxs\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .xxs\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .xxs\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .xxs\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .xxs\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .xxs\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .xxs\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .xxs\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .xxs\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xxs\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .xxs\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .xxs\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .xxs\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .xxs\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .xxs\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .xxs\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .xxs\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .xxs\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .xxs\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .xxs\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .xxs\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .xxs\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .xxs\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .xxs\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .xxs\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .xxs\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .xxs\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .xxs\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .xxs\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .xxs\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .xxs\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .xxs\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .xxs\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .xxs\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .xxs\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .xxs\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .xxs\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .xxs\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .xxs\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .xxs\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .xxs\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .xxs\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .xxs\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .xxs\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xxs\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .xxs\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .xxs\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .xxs\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .xxs\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .xxs\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .xxs\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .xxs\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .xxs\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .xxs\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .xxs\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .xxs\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .xxs\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .xxs\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .xxs\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .xxs\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .xxs\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .xxs\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .xxs\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .xxs\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .xxs\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .xxs\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .xxs\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .xxs\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .xxs\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .xxs\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .xxs\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .xxs\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .xxs\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .xxs\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .xxs\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .xxs\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .xxs\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .xxs\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .xxs\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .xxs\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .xxs\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .xxs\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .xxs\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .xxs\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .xxs\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .xxs\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .xxs\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .xxs\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .xxs\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .xxs\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .xxs\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .xxs\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .xxs\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xxs\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .xxs\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .xxs\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .xxs\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .xxs\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .xxs\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .xxs\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .xxs\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .xxs\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .xxs\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .xxs\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .xxs\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .xxs\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .xxs\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .xxs\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .xxs\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .xxs\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .xxs\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .xxs\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .xxs\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .xxs\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .xxs\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .xxs\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .xxs\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .xxs\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .xxs\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .xxs\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .xxs\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .xxs\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .xxs\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .xxs\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .xxs\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .xxs\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .xxs\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .xxs\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xxs\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .xxs\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .xxs\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .xxs\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .xxs\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .xxs\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .xxs\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .xxs\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .xxs\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .xxs\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .xxs\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .xxs\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .xxs\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .xxs\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .xxs\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .xxs\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .xxs\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .xxs\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .xxs\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .xxs\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .xxs\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .xxs\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .xxs\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .xxs\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .xxs\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .xxs\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .xxs\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .xxs\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .xxs\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .xxs\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .xxs\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .xxs\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .xxs\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .xxs\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .xxs\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .xxs\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .xxs\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .xxs\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .xxs\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .xxs\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .xxs\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .xxs\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .xxs\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .xxs\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .xxs\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .xxs\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .xxs\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .xxs\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .xxs\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xxs\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .xxs\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .xxs\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .xxs\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .xxs\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .xxs\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .xxs\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .xxs\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .xxs\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .xxs\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .xxs\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .xxs\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .xxs\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .xxs\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .xxs\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .xxs\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .xxs\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .xxs\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .xxs\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .xxs\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .xxs\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .xxs\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .xxs\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .xxs\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .xxs\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .xxs\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .xxs\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .xxs\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .xxs\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .xxs\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .xxs\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .xxs\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .xxs\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .xxs\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .xxs\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xxs\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .xxs\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .xxs\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .xxs\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .xxs\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .xxs\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .xxs\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .xxs\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .xxs\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .xxs\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .xxs\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .xxs\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .xxs\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .xxs\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .xxs\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .xxs\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .xxs\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .xxs\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .xxs\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .xxs\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .xxs\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .xxs\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .xxs\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .xxs\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .xxs\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .xxs\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .xxs\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .xxs\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .xxs\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .xxs\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .xxs\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .xxs\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .xxs\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .xxs\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .xxs\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .xxs\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .xxs\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .xxs\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .xxs\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .xxs\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .xxs\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .xxs\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .xxs\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .xxs\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .xxs\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .xxs\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .xxs\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .xxs\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .xxs\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xxs\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .xxs\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .xxs\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .xxs\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .xxs\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .xxs\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .xxs\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .xxs\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .xxs\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .xxs\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .xxs\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .xxs\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .xxs\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .xxs\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .xxs\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .xxs\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .xxs\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .xxs\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .xxs\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .xxs\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .xxs\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .xxs\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .xxs\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .xxs\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .xxs\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .xxs\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .xxs\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .xxs\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .xxs\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .xxs\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .xxs\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .xxs\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .xxs\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .xxs\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .xxs\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xxs\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .xxs\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .xxs\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .xxs\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .xxs\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .xxs\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .xxs\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .xxs\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .xxs\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .xxs\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .xxs\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .xxs\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .xxs\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .xxs\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .xxs\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .xxs\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .xxs\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .xxs\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .xxs\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .xxs\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .xxs\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .xxs\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .xxs\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .xxs\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .xxs\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .xxs\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .xxs\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .xxs\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .xxs\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .xxs\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .xxs\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .xxs\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .xxs\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .xxs\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .xxs\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .xxs\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .xxs\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .xxs\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .xxs\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .xxs\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .xxs\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .xxs\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .xxs\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .xxs\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .xxs\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .xxs\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .xxs\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .xxs\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .xxs\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .xxs\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .xxs\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .xxs\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .xxs\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .xxs\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .xxs\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .xxs\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .xxs\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .xxs\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .xxs\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .xxs\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .xxs\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .xxs\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .xxs\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .xxs\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .xxs\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .xxs\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .xxs\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .xxs\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .xxs\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .xxs\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .xxs\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .xxs\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .xxs\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .xxs\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .xxs\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .xxs\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .xxs\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .xxs\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .xxs\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .xxs\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .xxs\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .xxs\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .xxs\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .xxs\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .xxs\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .xxs\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .xxs\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .xxs\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .xxs\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .xxs\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .xxs\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .xxs\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .xxs\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .xxs\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .xxs\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .xxs\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .xxs\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .xxs\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .xxs\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .xxs\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .xxs\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .xxs\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .xxs\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .xxs\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .xxs\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .xxs\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .xxs\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .xxs\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .xxs\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .xxs\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .xxs\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .xxs\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .xxs\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .xxs\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .xxs\:transition-none {
    transition-property: none;
  }
  .xxs\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxs\:ease-linear {
    transition-timing-function: linear;
  }
  .xxs\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .xxs\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .xxs\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .xxs\:duration-75 {
    transition-duration: 75ms;
  }
  .xxs\:duration-100 {
    transition-duration: 0.1s;
  }
  .xxs\:duration-150 {
    transition-duration: 0.15s;
  }
  .xxs\:duration-200 {
    transition-duration: 0.2s;
  }
  .xxs\:duration-300 {
    transition-duration: 0.3s;
  }
  .xxs\:duration-500 {
    transition-duration: 0.5s;
  }
  .xxs\:duration-700 {
    transition-duration: 0.7s;
  }
  .xxs\:duration-1000 {
    transition-duration: 1s;
  }
  .xxs\:delay-75 {
    transition-delay: 75ms;
  }
  .xxs\:delay-100 {
    transition-delay: 0.1s;
  }
  .xxs\:delay-150 {
    transition-delay: 0.15s;
  }
  .xxs\:delay-200 {
    transition-delay: 0.2s;
  }
  .xxs\:delay-300 {
    transition-delay: 0.3s;
  }
  .xxs\:delay-500 {
    transition-delay: 0.5s;
  }
  .xxs\:delay-700 {
    transition-delay: 0.7s;
  }
  .xxs\:delay-1000 {
    transition-delay: 1s;
  }
  .xxs\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .xxs\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .xxs\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .xxs\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .xxs\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 360px) {
  .xs\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .xs\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .xs\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .xs\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .xs\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .xs\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .xs\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .xs\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .xs\:container {
      max-width: 1536px;
    }
  }
  .xs\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .xs\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xs\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .xs\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .xs\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xs\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .xs\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .xs\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .xs\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .xs\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xs\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .xs\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .xs\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .xs\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xs\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .xs\:prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xs\:prose h3,
  .xs\:prose h4 {
    color: #6d728b;
    font-weight: 600;
  }
  .xs\:prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .xs\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .xs\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .xs\:prose code:after,
  .xs\:prose code:before {
    content: '`';
  }
  .xs\:prose a code {
    color: #6d728b;
  }
  .xs\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .xs\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .xs\:prose pre code:after,
  .xs\:prose pre code:before {
    content: '';
  }
  .xs\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .xs\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .xs\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .xs\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .xs\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .xs\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .xs\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xs\:prose figure,
  .xs\:prose img,
  .xs\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xs\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:prose h2 code {
    font-size: 0.875em;
  }
  .xs\:prose h3 code {
    font-size: 0.9em;
  }
  .xs\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xs\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xs\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xs\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .xs\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xs\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .xs\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xs\:prose ol ol,
  .xs\:prose ol ul,
  .xs\:prose ul ol,
  .xs\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xs\:prose h2 + *,
  .xs\:prose h3 + *,
  .xs\:prose h4 + *,
  .xs\:prose hr + * {
    margin-top: 0;
  }
  .xs\:prose thead th:first-child {
    padding-left: 0;
  }
  .xs\:prose thead th:last-child {
    padding-right: 0;
  }
  .xs\:prose tbody td:first-child {
    padding-left: 0;
  }
  .xs\:prose tbody td:last-child {
    padding-right: 0;
  }
  .xs\:prose > :first-child {
    margin-top: 0;
  }
  .xs\:prose > :last-child {
    margin-bottom: 0;
  }
  .xs\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .xs\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xs\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xs\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .xs\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .xs\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .xs\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xs\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .xs\:prose-sm figure,
  .xs\:prose-sm img,
  .xs\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .xs\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .xs\:prose-sm code {
    font-size: 0.8571429em;
  }
  .xs\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .xs\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .xs\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .xs\:prose-sm ol,
  .xs\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xs\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .xs\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .xs\:prose-sm ol > li:before {
    left: 0;
  }
  .xs\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .xs\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .xs\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xs\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xs\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xs\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xs\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xs\:prose-sm ol ol,
  .xs\:prose-sm ol ul,
  .xs\:prose-sm ul ol,
  .xs\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xs\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .xs\:prose-sm h2 + *,
  .xs\:prose-sm h3 + *,
  .xs\:prose-sm h4 + *,
  .xs\:prose-sm hr + * {
    margin-top: 0;
  }
  .xs\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .xs\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .xs\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .xs\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .xs\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .xs\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .xs\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .xs\:prose-sm > :first-child {
    margin-top: 0;
  }
  .xs\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .xs\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .xs\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .xs\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .xs\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .xs\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .xs\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xs\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xs\:prose-lg figure,
  .xs\:prose-lg img,
  .xs\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .xs\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .xs\:prose-lg code {
    font-size: 0.8888889em;
  }
  .xs\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .xs\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .xs\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .xs\:prose-lg ol,
  .xs\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xs\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .xs\:prose-lg ol > li:before {
    left: 0;
  }
  .xs\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .xs\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .xs\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xs\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xs\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xs\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-lg ol ol,
  .xs\:prose-lg ol ul,
  .xs\:prose-lg ul ol,
  .xs\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xs\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .xs\:prose-lg h2 + *,
  .xs\:prose-lg h3 + *,
  .xs\:prose-lg h4 + *,
  .xs\:prose-lg hr + * {
    margin-top: 0;
  }
  .xs\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .xs\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .xs\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .xs\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .xs\:prose-lg tbody td {
    padding: 0.75em;
  }
  .xs\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .xs\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .xs\:prose-lg > :first-child {
    margin-top: 0;
  }
  .xs\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .xs\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .xs\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xs\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .xs\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .xs\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .xs\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xs\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .xs\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xs\:prose-xl figure,
  .xs\:prose-xl img,
  .xs\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xs\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .xs\:prose-xl code {
    font-size: 0.9em;
  }
  .xs\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .xs\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .xs\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .xs\:prose-xl ol,
  .xs\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xs\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .xs\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .xs\:prose-xl ol > li:before {
    left: 0;
  }
  .xs\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .xs\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .xs\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xs\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .xs\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xs\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .xs\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xs\:prose-xl ol ol,
  .xs\:prose-xl ol ul,
  .xs\:prose-xl ul ol,
  .xs\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xs\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .xs\:prose-xl h2 + *,
  .xs\:prose-xl h3 + *,
  .xs\:prose-xl h4 + *,
  .xs\:prose-xl hr + * {
    margin-top: 0;
  }
  .xs\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .xs\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .xs\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .xs\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .xs\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .xs\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .xs\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .xs\:prose-xl > :first-child {
    margin-top: 0;
  }
  .xs\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .xs\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .xs\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .xs\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .xs\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .xs\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .xs\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .xs\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xs\:prose-2xl figure,
  .xs\:prose-2xl img,
  .xs\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xs\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .xs\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .xs\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .xs\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .xs\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .xs\:prose-2xl ol,
  .xs\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xs\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .xs\:prose-2xl ol > li:before {
    left: 0;
  }
  .xs\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .xs\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .xs\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .xs\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xs\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xs\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xs\:prose-2xl ol ol,
  .xs\:prose-2xl ol ul,
  .xs\:prose-2xl ul ol,
  .xs\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xs\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xs\:prose-2xl h2 + *,
  .xs\:prose-2xl h3 + *,
  .xs\:prose-2xl h4 + *,
  .xs\:prose-2xl hr + * {
    margin-top: 0;
  }
  .xs\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .xs\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .xs\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .xs\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .xs\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .xs\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .xs\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .xs\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .xs\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .xs\:aspect-w-1,
  .xs\:aspect-w-2,
  .xs\:aspect-w-3,
  .xs\:aspect-w-4,
  .xs\:aspect-w-5,
  .xs\:aspect-w-6,
  .xs\:aspect-w-7,
  .xs\:aspect-w-8,
  .xs\:aspect-w-9,
  .xs\:aspect-w-10,
  .xs\:aspect-w-11,
  .xs\:aspect-w-12,
  .xs\:aspect-w-13,
  .xs\:aspect-w-14,
  .xs\:aspect-w-15,
  .xs\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .xs\:aspect-w-1 > *,
  .xs\:aspect-w-2 > *,
  .xs\:aspect-w-3 > *,
  .xs\:aspect-w-4 > *,
  .xs\:aspect-w-5 > *,
  .xs\:aspect-w-6 > *,
  .xs\:aspect-w-7 > *,
  .xs\:aspect-w-8 > *,
  .xs\:aspect-w-9 > *,
  .xs\:aspect-w-10 > *,
  .xs\:aspect-w-11 > *,
  .xs\:aspect-w-12 > *,
  .xs\:aspect-w-13 > *,
  .xs\:aspect-w-14 > *,
  .xs\:aspect-w-15 > *,
  .xs\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xs\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .xs\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xs\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .xs\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .xs\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .xs\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .xs\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .xs\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .xs\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .xs\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .xs\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .xs\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .xs\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .xs\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .xs\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .xs\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .xs\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .xs\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .xs\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .xs\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .xs\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .xs\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .xs\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .xs\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .xs\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .xs\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .xs\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .xs\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .xs\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .xs\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .xs\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .xs\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .xs\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .xs\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .xs\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xs\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .xs\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .xs\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .xs\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xs\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .xs\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .xs\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .xs\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .xs\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .xs\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .xs\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .xs\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .xs\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .xs\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .xs\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .xs\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .xs\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .xs\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .xs\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .xs\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .xs\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .xs\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .xs\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .xs\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .xs\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .xs\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .xs\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .xs\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .xs\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .xs\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .xs\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .xs\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .xs\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .xs\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .xs\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .xs\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .xs\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .xs\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .xs\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .xs\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .xs\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .xs\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .xs\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .xs\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .xs\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .xs\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .xs\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .xs\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xs\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .xs\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .xs\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .xs\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .xs\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .xs\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .xs\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .xs\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .xs\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .xs\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .xs\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .xs\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .xs\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .xs\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .xs\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .xs\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .xs\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .xs\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .xs\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .xs\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .xs\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .xs\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .xs\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .xs\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .xs\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .xs\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .xs\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .xs\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .xs\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .xs\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .xs\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .xs\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .xs\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .xs\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xs\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .xs\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .xs\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xs\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .xs\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .xs\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .xs\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .xs\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .xs\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .xs\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .xs\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .xs\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .xs\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .xs\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .xs\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .xs\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .xs\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .xs\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .xs\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .xs\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .xs\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .xs\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .xs\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .xs\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .xs\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .xs\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xs\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xs\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xs\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xs\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xs\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xs\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .xs\:bg-fixed {
    background-attachment: fixed;
  }
  .xs\:bg-local {
    background-attachment: local;
  }
  .xs\:bg-scroll {
    background-attachment: scroll;
  }
  .xs\:bg-clip-border {
    background-clip: border-box;
  }
  .xs\:bg-clip-padding {
    background-clip: padding-box;
  }
  .xs\:bg-clip-content {
    background-clip: content-box;
  }
  .xs\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .xs\:bg-transparent {
    background-color: transparent;
  }
  .xs\:bg-current {
    background-color: currentColor;
  }
  .xs\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xs\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xs\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xs\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xs\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xs\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xs\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xs\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xs\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xs\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xs\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xs\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xs\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xs\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xs\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xs\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xs\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xs\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xs\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xs\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xs\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xs\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xs\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xs\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xs\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xs\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xs\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xs\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .xs\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xs\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xs\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xs\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xs\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xs\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xs\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xs\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xs\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xs\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xs\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xs\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xs\:bg-body-background {
    background-color: var(--body-background);
  }
  .xs\:bg-background {
    background-color: var(--background);
  }
  .xs\:bg-input-background {
    background-color: var(--input-background);
  }
  .xs\:bg-body-text {
    background-color: var(--body-text);
  }
  .xs\:bg-header-text {
    background-color: var(--header-text);
  }
  .xs\:bg-primary {
    background-color: var(--primary);
  }
  .xs\:bg-secondary {
    background-color: var(--secondary);
  }
  .xs\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .xs\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .xs\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .xs\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .xs\:bg-input-text {
    background-color: var(--input-text);
  }
  .xs\:bg-success {
    background-color: var(--success);
  }
  .xs\:bg-error {
    background-color: var(--error);
  }
  .xs\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .xs\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xs\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .xs\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .xs\:bg-background50 {
    background-color: var(--background50);
  }
  .xs\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xs\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xs\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .xs\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .xs\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xs\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xs\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .xs\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .xs\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .xs\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .xs\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .xs\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .xs\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .xs\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .xs\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .xs\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .xs\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .xs\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .xs\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .xs\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .xs\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .xs\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .xs\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .xs\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .xs\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .xs\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .xs\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .xs\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .xs\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .xs\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .xs\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .xs\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .xs\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .xs\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .xs\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .xs\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .xs\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .xs\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .xs\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .xs\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .xs\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .xs\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .xs\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .xs\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .xs\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .xs\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .xs\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .xs\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xs\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .xs\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .xs\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .xs\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .xs\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .xs\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .xs\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .xs\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .xs\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .xs\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .xs\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .xs\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .xs\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .xs\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .xs\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .xs\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .xs\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .xs\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .xs\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .xs\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .xs\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .xs\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xs\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .xs\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .xs\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .xs\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .xs\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .xs\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .xs\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .xs\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .xs\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .xs\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .xs\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .xs\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .xs\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .xs\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .xs\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .xs\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .xs\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .xs\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .xs\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .xs\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .xs\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .xs\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xs\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xs\:bg-none {
    background-image: none;
  }
  .xs\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .xs\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .xs\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .xs\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .xs\:from-background,
  .xs\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-background {
    --tw-gradient-from: var(--background);
  }
  .xs\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .xs\:from-body-text,
  .xs\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .xs\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .xs\:from-header-text,
  .xs\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .xs\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .xs\:from-secondary,
  .xs\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .xs\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xs\:from-primary-hover,
  .xs\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xs\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xs\:from-input-text,
  .xs\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .xs\:from-success {
    --tw-gradient-from: var(--success);
  }
  .xs\:from-error,
  .xs\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-error {
    --tw-gradient-from: var(--error);
  }
  .xs\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .xs\:from-skeleton-loader-from,
  .xs\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xs\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xs\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xs\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .xs\:to-current {
    --tw-gradient-to: currentColor;
  }
  .xs\:to-black {
    --tw-gradient-to: #000;
  }
  .xs\:to-white {
    --tw-gradient-to: #fff;
  }
  .xs\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .xs\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .xs\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .xs\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .xs\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .xs\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .xs\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .xs\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .xs\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .xs\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .xs\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .xs\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .xs\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .xs\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .xs\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .xs\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .xs\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .xs\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .xs\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .xs\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .xs\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .xs\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .xs\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .xs\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .xs\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .xs\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .xs\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .xs\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .xs\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .xs\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .xs\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .xs\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .xs\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .xs\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .xs\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .xs\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .xs\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .xs\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .xs\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .xs\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .xs\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .xs\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .xs\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .xs\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .xs\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .xs\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .xs\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .xs\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .xs\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .xs\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .xs\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .xs\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .xs\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .xs\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .xs\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .xs\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .xs\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .xs\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .xs\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .xs\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .xs\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .xs\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .xs\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .xs\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .xs\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .xs\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .xs\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .xs\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .xs\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .xs\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .xs\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .xs\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .xs\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .xs\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .xs\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .xs\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .xs\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .xs\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .xs\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .xs\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xs\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .xs\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .xs\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .xs\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .xs\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .xs\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .xs\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .xs\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .xs\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .xs\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .xs\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .xs\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .xs\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .xs\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .xs\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .xs\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .xs\:to-background {
    --tw-gradient-to: var(--background);
  }
  .xs\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .xs\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .xs\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .xs\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .xs\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .xs\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .xs\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xs\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xs\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xs\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .xs\:to-success {
    --tw-gradient-to: var(--success);
  }
  .xs\:to-error {
    --tw-gradient-to: var(--error);
  }
  .xs\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .xs\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .xs\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .xs\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xs\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xs\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .xs\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .xs\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .xs\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .xs\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .xs\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .xs\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .xs\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .xs\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .xs\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .xs\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .xs\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .xs\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .xs\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .xs\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .xs\:hover\:from-background:hover,
  .xs\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .xs\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .xs\:hover\:from-body-text:hover,
  .xs\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .xs\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .xs\:hover\:from-header-text:hover,
  .xs\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .xs\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .xs\:hover\:from-secondary:hover,
  .xs\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .xs\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xs\:hover\:from-primary-hover:hover,
  .xs\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xs\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xs\:hover\:from-input-text:hover,
  .xs\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .xs\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .xs\:hover\:from-error:hover,
  .xs\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .xs\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xs\:hover\:from-background50:hover,
  .xs\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .xs\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xs\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xs\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .xs\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .xs\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .xs\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .xs\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .xs\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .xs\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .xs\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .xs\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .xs\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .xs\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .xs\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .xs\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .xs\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .xs\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .xs\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .xs\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .xs\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .xs\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .xs\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .xs\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .xs\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .xs\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .xs\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .xs\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .xs\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .xs\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .xs\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .xs\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .xs\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .xs\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .xs\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .xs\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .xs\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .xs\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .xs\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .xs\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .xs\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .xs\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .xs\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .xs\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .xs\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .xs\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .xs\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .xs\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .xs\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .xs\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .xs\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .xs\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .xs\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .xs\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .xs\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .xs\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .xs\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .xs\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .xs\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .xs\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .xs\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .xs\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .xs\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .xs\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .xs\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .xs\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .xs\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .xs\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .xs\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .xs\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .xs\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .xs\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .xs\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .xs\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .xs\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .xs\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .xs\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .xs\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .xs\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .xs\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .xs\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .xs\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .xs\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .xs\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .xs\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .xs\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .xs\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xs\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .xs\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .xs\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .xs\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .xs\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .xs\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .xs\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .xs\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .xs\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .xs\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .xs\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .xs\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .xs\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .xs\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .xs\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .xs\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .xs\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .xs\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .xs\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .xs\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .xs\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .xs\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .xs\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .xs\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xs\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xs\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xs\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .xs\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .xs\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .xs\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .xs\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .xs\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .xs\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xs\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xs\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .xs\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .xs\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .xs\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .xs\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .xs\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .xs\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .xs\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .xs\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .xs\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .xs\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .xs\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .xs\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .xs\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .xs\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xs\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .xs\:focus\:from-background:focus,
  .xs\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .xs\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .xs\:focus\:from-body-text:focus,
  .xs\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .xs\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .xs\:focus\:from-header-text:focus,
  .xs\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .xs\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .xs\:focus\:from-secondary:focus,
  .xs\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .xs\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .xs\:focus\:from-primary-hover:focus,
  .xs\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xs\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xs\:focus\:from-input-text:focus,
  .xs\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .xs\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .xs\:focus\:from-error:focus,
  .xs\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .xs\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xs\:focus\:from-background50:focus,
  .xs\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .xs\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xs\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xs\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xs\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xs\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xs\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xs\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xs\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xs\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xs\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xs\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xs\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xs\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xs\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xs\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xs\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xs\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xs\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xs\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xs\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xs\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xs\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xs\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xs\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xs\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xs\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xs\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xs\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xs\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xs\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xs\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xs\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xs\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xs\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xs\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xs\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xs\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xs\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xs\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xs\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xs\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xs\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xs\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xs\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xs\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xs\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xs\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xs\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xs\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xs\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xs\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xs\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xs\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xs\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xs\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xs\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xs\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xs\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xs\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xs\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xs\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xs\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xs\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xs\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xs\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xs\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xs\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xs\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xs\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xs\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xs\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xs\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xs\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xs\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xs\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xs\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xs\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xs\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xs\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xs\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xs\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xs\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xs\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xs\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xs\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xs\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xs\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xs\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xs\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xs\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xs\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xs\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xs\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xs\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xs\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xs\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xs\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xs\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xs\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xs\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xs\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xs\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xs\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xs\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xs\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xs\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xs\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xs\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xs\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xs\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xs\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xs\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .xs\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .xs\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .xs\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .xs\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .xs\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .xs\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .xs\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .xs\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .xs\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .xs\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .xs\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .xs\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .xs\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .xs\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .xs\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .xs\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .xs\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .xs\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .xs\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .xs\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .xs\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .xs\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .xs\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .xs\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .xs\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .xs\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .xs\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .xs\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .xs\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .xs\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .xs\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .xs\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .xs\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .xs\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .xs\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .xs\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .xs\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .xs\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .xs\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .xs\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .xs\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .xs\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .xs\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .xs\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .xs\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .xs\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .xs\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .xs\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .xs\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .xs\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .xs\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .xs\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .xs\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .xs\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .xs\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .xs\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .xs\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .xs\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .xs\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .xs\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .xs\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .xs\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .xs\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .xs\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .xs\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .xs\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .xs\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .xs\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .xs\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .xs\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .xs\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .xs\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .xs\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .xs\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .xs\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .xs\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .xs\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .xs\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .xs\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .xs\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .xs\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .xs\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .xs\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .xs\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .xs\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .xs\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .xs\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .xs\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .xs\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .xs\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .xs\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .xs\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .xs\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .xs\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .xs\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .xs\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .xs\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .xs\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .xs\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .xs\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .xs\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .xs\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .xs\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .xs\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .xs\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .xs\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .xs\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xs\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xs\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .xs\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .xs\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .xs\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .xs\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .xs\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .xs\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xs\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xs\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .xs\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .xs\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .xs\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .xs\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .xs\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .xs\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .xs\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .xs\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .xs\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .xs\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .xs\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .xs\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xs\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .xs\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .xs\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .xs\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .xs\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .xs\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .xs\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .xs\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .xs\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .xs\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .xs\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .xs\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .xs\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .xs\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .xs\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .xs\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .xs\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .xs\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .xs\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .xs\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .xs\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .xs\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .xs\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .xs\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .xs\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .xs\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .xs\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .xs\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .xs\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .xs\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .xs\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .xs\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .xs\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .xs\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .xs\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .xs\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .xs\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .xs\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .xs\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .xs\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .xs\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .xs\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .xs\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .xs\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .xs\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .xs\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .xs\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .xs\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .xs\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .xs\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .xs\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .xs\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .xs\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .xs\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .xs\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .xs\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .xs\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .xs\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .xs\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .xs\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .xs\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .xs\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .xs\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .xs\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .xs\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .xs\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .xs\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .xs\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .xs\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .xs\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .xs\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .xs\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .xs\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .xs\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .xs\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .xs\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .xs\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .xs\:bg-bottom {
    background-position: bottom;
  }
  .xs\:bg-center {
    background-position: 50%;
  }
  .xs\:bg-left {
    background-position: 0;
  }
  .xs\:bg-left-bottom {
    background-position: 0 100%;
  }
  .xs\:bg-left-top {
    background-position: 0 0;
  }
  .xs\:bg-right {
    background-position: 100%;
  }
  .xs\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .xs\:bg-right-top {
    background-position: 100% 0;
  }
  .xs\:bg-top {
    background-position: top;
  }
  .xs\:bg-repeat {
    background-repeat: repeat;
  }
  .xs\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .xs\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .xs\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .xs\:bg-repeat-round {
    background-repeat: round;
  }
  .xs\:bg-repeat-space {
    background-repeat: space;
  }
  .xs\:bg-auto {
    background-size: auto;
  }
  .xs\:bg-cover {
    background-size: cover;
  }
  .xs\:bg-contain {
    background-size: contain;
  }
  .xs\:border-collapse {
    border-collapse: collapse;
  }
  .xs\:border-separate {
    border-collapse: separate;
  }
  .xs\:border-transparent {
    border-color: transparent;
  }
  .xs\:border-current {
    border-color: currentColor;
  }
  .xs\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xs\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xs\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xs\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xs\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xs\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xs\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xs\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xs\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xs\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xs\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xs\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xs\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xs\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xs\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xs\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xs\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xs\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xs\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xs\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xs\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xs\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xs\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xs\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xs\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xs\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xs\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xs\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xs\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xs\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xs\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xs\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xs\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xs\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xs\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xs\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xs\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xs\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xs\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xs\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xs\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xs\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xs\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xs\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xs\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xs\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xs\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xs\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xs\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xs\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xs\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xs\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xs\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xs\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xs\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xs\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xs\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xs\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xs\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xs\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xs\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xs\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xs\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xs\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xs\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xs\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xs\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xs\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xs\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xs\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xs\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xs\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xs\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xs\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xs\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xs\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xs\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xs\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xs\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xs\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xs\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:border-background-primary {
    border-color: var(--background-primary);
  }
  .xs\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xs\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xs\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xs\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xs\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xs\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xs\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xs\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xs\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xs\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xs\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xs\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xs\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xs\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xs\:border-body-background {
    border-color: var(--body-background);
  }
  .xs\:border-background {
    border-color: var(--background);
  }
  .xs\:border-input-background {
    border-color: var(--input-background);
  }
  .xs\:border-body-text {
    border-color: var(--body-text);
  }
  .xs\:border-header-text {
    border-color: var(--header-text);
  }
  .xs\:border-primary {
    border-color: var(--primary);
  }
  .xs\:border-secondary {
    border-color: var(--secondary);
  }
  .xs\:border-tertiary {
    border-color: var(--tertiary);
  }
  .xs\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .xs\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .xs\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .xs\:border-input-text {
    border-color: var(--input-text);
  }
  .xs\:border-success {
    border-color: var(--success);
  }
  .xs\:border-error {
    border-color: var(--error);
  }
  .xs\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .xs\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xs\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xs\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .xs\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .xs\:border-background50 {
    border-color: var(--background50);
  }
  .xs\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xs\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xs\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xs\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xs\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xs\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xs\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xs\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xs\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xs\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xs\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .xs\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .xs\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xs\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xs\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .xs\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .xs\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .xs\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .xs\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .xs\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .xs\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .xs\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .xs\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .xs\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .xs\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .xs\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .xs\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .xs\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .xs\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .xs\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .xs\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .xs\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .xs\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .xs\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .xs\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .xs\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .xs\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .xs\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .xs\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .xs\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .xs\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .xs\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .xs\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .xs\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .xs\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .xs\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .xs\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .xs\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .xs\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .xs\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .xs\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .xs\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .xs\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .xs\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .xs\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .xs\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xs\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xs\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .xs\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .xs\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xs\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xs\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xs\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xs\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xs\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xs\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xs\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xs\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .xs\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xs\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xs\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xs\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xs\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xs\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xs\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xs\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xs\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xs\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xs\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xs\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .xs\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .xs\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .xs\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .xs\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .xs\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .xs\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .xs\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .xs\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .xs\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .xs\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .xs\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .xs\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .xs\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .xs\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .xs\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xs\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xs\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .xs\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .xs\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .xs\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xs\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xs\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xs\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xs\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .xs\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .xs\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xs\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xs\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xs\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xs\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xs\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xs\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xs\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xs\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .xs\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xs\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xs\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xs\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xs\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xs\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xs\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xs\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xs\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xs\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xs\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xs\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .xs\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .xs\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .xs\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .xs\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .xs\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .xs\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .xs\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .xs\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .xs\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .xs\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .xs\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .xs\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .xs\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .xs\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .xs\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xs\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xs\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .xs\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .xs\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .xs\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xs\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xs\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xs\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xs\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xs\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xs\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .xs\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .xs\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .xs\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .xs\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .xs\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .xs\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .xs\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .xs\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .xs\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .xs\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .xs\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .xs\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .xs\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .xs\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .xs\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .xs\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .xs\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .xs\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .xs\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .xs\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .xs\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .xs\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .xs\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .xs\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .xs\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .xs\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .xs\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .xs\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .xs\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .xs\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .xs\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .xs\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .xs\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .xs\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .xs\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .xs\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .xs\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .xs\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .xs\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .xs\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .xs\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .xs\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .xs\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .xs\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .xs\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .xs\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .xs\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .xs\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .xs\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .xs\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .xs\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .xs\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .xs\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .xs\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .xs\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .xs\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .xs\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .xs\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .xs\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .xs\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .xs\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .xs\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .xs\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .xs\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .xs\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .xs\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .xs\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .xs\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .xs\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .xs\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .xs\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .xs\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .xs\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .xs\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .xs\:rounded-none {
    border-radius: 0;
  }
  .xs\:rounded-sm {
    border-radius: 0.125rem;
  }
  .xs\:rounded {
    border-radius: 0.25rem;
  }
  .xs\:rounded-md {
    border-radius: 0.375rem;
  }
  .xs\:rounded-lg {
    border-radius: 0.5rem;
  }
  .xs\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xs\:rounded-2xl {
    border-radius: 1rem;
  }
  .xs\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .xs\:rounded-full {
    border-radius: 9999px;
  }
  .xs\:rounded-card {
    border-radius: 20px;
  }
  .xs\:rounded-time-item {
    border-radius: 100px;
  }
  .xs\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .xs\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .xs\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xs\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xs\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .xs\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .xs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xs\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xs\:rounded-t {
    border-top-left-radius: 0.25rem;
  }
  .xs\:rounded-r,
  .xs\:rounded-t {
    border-top-right-radius: 0.25rem;
  }
  .xs\:rounded-b,
  .xs\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .xs\:rounded-b,
  .xs\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .xs\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .xs\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .xs\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .xs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xs\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xs\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .xs\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .xs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xs\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xs\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .xs\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .xs\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xs\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xs\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .xs\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .xs\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xs\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xs\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .xs\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .xs\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xs\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xs\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .xs\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .xs\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xs\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xs\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .xs\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .xs\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xs\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xs\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .xs\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .xs\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xs\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xs\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .xs\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .xs\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .xs\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .xs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .xs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .xs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .xs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .xs\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .xs\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .xs\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .xs\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .xs\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .xs\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .xs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .xs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .xs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .xs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .xs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .xs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .xs\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .xs\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .xs\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .xs\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .xs\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .xs\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .xs\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .xs\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .xs\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .xs\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .xs\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .xs\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .xs\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .xs\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .xs\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .xs\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .xs\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .xs\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .xs\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .xs\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .xs\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .xs\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .xs\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .xs\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .xs\:border-solid {
    border-style: solid;
  }
  .xs\:border-dashed {
    border-style: dashed;
  }
  .xs\:border-dotted {
    border-style: dotted;
  }
  .xs\:border-double {
    border-style: double;
  }
  .xs\:border-none {
    border-style: none;
  }
  .xs\:border-0 {
    border-width: 0;
  }
  .xs\:border-2 {
    border-width: 2px;
  }
  .xs\:border-4 {
    border-width: 4px;
  }
  .xs\:border-8 {
    border-width: 8px;
  }
  .xs\:border {
    border-width: 1px;
  }
  .xs\:border-t-0 {
    border-top-width: 0;
  }
  .xs\:border-r-0 {
    border-right-width: 0;
  }
  .xs\:border-b-0 {
    border-bottom-width: 0;
  }
  .xs\:border-l-0 {
    border-left-width: 0;
  }
  .xs\:border-t-2 {
    border-top-width: 2px;
  }
  .xs\:border-r-2 {
    border-right-width: 2px;
  }
  .xs\:border-b-2 {
    border-bottom-width: 2px;
  }
  .xs\:border-l-2 {
    border-left-width: 2px;
  }
  .xs\:border-t-4 {
    border-top-width: 4px;
  }
  .xs\:border-r-4 {
    border-right-width: 4px;
  }
  .xs\:border-b-4 {
    border-bottom-width: 4px;
  }
  .xs\:border-l-4 {
    border-left-width: 4px;
  }
  .xs\:border-t-8 {
    border-top-width: 8px;
  }
  .xs\:border-r-8 {
    border-right-width: 8px;
  }
  .xs\:border-b-8 {
    border-bottom-width: 8px;
  }
  .xs\:border-l-8 {
    border-left-width: 8px;
  }
  .xs\:border-t {
    border-top-width: 1px;
  }
  .xs\:border-r {
    border-right-width: 1px;
  }
  .xs\:border-b {
    border-bottom-width: 1px;
  }
  .xs\:border-l {
    border-left-width: 1px;
  }
  .xs\:box-border {
    box-sizing: border-box;
  }
  .xs\:box-content {
    box-sizing: content-box;
  }
  .xs\:cursor-auto {
    cursor: auto;
  }
  .xs\:cursor-default {
    cursor: default;
  }
  .xs\:cursor-pointer {
    cursor: pointer;
  }
  .xs\:cursor-wait {
    cursor: wait;
  }
  .xs\:cursor-text {
    cursor: text;
  }
  .xs\:cursor-move {
    cursor: move;
  }
  .xs\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .xs\:block {
    display: block;
  }
  .xs\:inline-block {
    display: inline-block;
  }
  .xs\:inline {
    display: inline;
  }
  .xs\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xs\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .xs\:table {
    display: table;
  }
  .xs\:table-caption {
    display: table-caption;
  }
  .xs\:table-cell {
    display: table-cell;
  }
  .xs\:table-column {
    display: table-column;
  }
  .xs\:table-column-group {
    display: table-column-group;
  }
  .xs\:table-footer-group {
    display: table-footer-group;
  }
  .xs\:table-header-group {
    display: table-header-group;
  }
  .xs\:table-row-group {
    display: table-row-group;
  }
  .xs\:table-row {
    display: table-row;
  }
  .xs\:flow-root {
    display: flow-root;
  }
  .xs\:grid {
    display: grid;
  }
  .xs\:inline-grid {
    display: inline-grid;
  }
  .xs\:contents {
    display: contents;
  }
  .xs\:hidden {
    display: none;
  }
  .xs\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .xs\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .xs\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xs\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .xs\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xs\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .xs\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .xs\:place-items-auto {
    place-items: auto;
  }
  .xs\:place-items-start {
    place-items: start;
  }
  .xs\:place-items-end {
    place-items: end;
  }
  .xs\:place-items-center {
    place-items: center;
  }
  .xs\:place-items-stretch {
    place-items: stretch;
  }
  .xs\:place-content-center {
    place-content: center;
  }
  .xs\:place-content-start {
    place-content: start;
  }
  .xs\:place-content-end {
    place-content: end;
  }
  .xs\:place-content-between {
    place-content: space-between;
  }
  .xs\:place-content-around {
    place-content: space-around;
  }
  .xs\:place-content-evenly {
    place-content: space-evenly;
  }
  .xs\:place-content-stretch {
    place-content: stretch;
  }
  .xs\:place-self-auto {
    place-self: auto;
  }
  .xs\:place-self-start {
    place-self: start;
  }
  .xs\:place-self-end {
    place-self: end;
  }
  .xs\:place-self-center {
    place-self: center;
  }
  .xs\:place-self-stretch {
    place-self: stretch;
  }
  .xs\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .xs\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .xs\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .xs\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .xs\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .xs\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .xs\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .xs\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .xs\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .xs\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .xs\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .xs\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .xs\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xs\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xs\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xs\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .xs\:justify-items-auto {
    justify-items: auto;
  }
  .xs\:justify-items-start {
    justify-items: start;
  }
  .xs\:justify-items-end {
    justify-items: end;
  }
  .xs\:justify-items-center {
    justify-items: center;
  }
  .xs\:justify-items-stretch {
    justify-items: stretch;
  }
  .xs\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .xs\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .xs\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .xs\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .xs\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .xs\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .xs\:justify-self-auto {
    justify-self: auto;
  }
  .xs\:justify-self-start {
    justify-self: start;
  }
  .xs\:justify-self-end {
    justify-self: end;
  }
  .xs\:justify-self-center {
    justify-self: center;
  }
  .xs\:justify-self-stretch {
    justify-self: stretch;
  }
  .xs\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .xs\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .xs\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .xs\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .xs\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .xs\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .xs\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .xs\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .xs\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .xs\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .xs\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .xs\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .xs\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .xs\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .xs\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .xs\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .xs\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .xs\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .xs\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .xs\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .xs\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .xs\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .xs\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .xs\:float-right {
    float: right;
  }
  .xs\:float-left {
    float: left;
  }
  .xs\:float-none {
    float: none;
  }
  .xs\:clear-left {
    clear: left;
  }
  .xs\:clear-right {
    clear: right;
  }
  .xs\:clear-both {
    clear: both;
  }
  .xs\:clear-none {
    clear: none;
  }
  .xs\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .xs\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .xs\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .xs\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .xs\:font-text {
    font-family: Bozon, sans-serif;
  }
  .xs\:font-thin {
    font-weight: 100;
  }
  .xs\:font-extralight {
    font-weight: 200;
  }
  .xs\:font-light {
    font-weight: 300;
  }
  .xs\:font-normal {
    font-weight: 400;
  }
  .xs\:font-medium {
    font-weight: 500;
  }
  .xs\:font-semibold {
    font-weight: 600;
  }
  .xs\:font-bold {
    font-weight: 700;
  }
  .xs\:font-extrabold {
    font-weight: 800;
  }
  .xs\:font-black {
    font-weight: 900;
  }
  .xs\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .xs\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .xs\:hover\:font-light:hover {
    font-weight: 300;
  }
  .xs\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .xs\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .xs\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .xs\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .xs\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .xs\:hover\:font-black:hover {
    font-weight: 900;
  }
  .xs\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .xs\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .xs\:focus\:font-light:focus {
    font-weight: 300;
  }
  .xs\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .xs\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .xs\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .xs\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .xs\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .xs\:focus\:font-black:focus {
    font-weight: 900;
  }
  .xs\:h-0 {
    height: 0;
  }
  .xs\:h-1 {
    height: 0.25rem;
  }
  .xs\:h-2 {
    height: 0.5rem;
  }
  .xs\:h-3 {
    height: 0.75rem;
  }
  .xs\:h-4 {
    height: 1rem;
  }
  .xs\:h-5 {
    height: 1.25rem;
  }
  .xs\:h-6 {
    height: 1.5rem;
  }
  .xs\:h-7 {
    height: 1.75rem;
  }
  .xs\:h-8 {
    height: 2rem;
  }
  .xs\:h-9 {
    height: 2.25rem;
  }
  .xs\:h-10 {
    height: 2.5rem;
  }
  .xs\:h-11 {
    height: 2.75rem;
  }
  .xs\:h-12 {
    height: 3rem;
  }
  .xs\:h-14 {
    height: 3.5rem;
  }
  .xs\:h-16 {
    height: 4rem;
  }
  .xs\:h-20 {
    height: 5rem;
  }
  .xs\:h-24 {
    height: 6rem;
  }
  .xs\:h-28 {
    height: 7rem;
  }
  .xs\:h-32 {
    height: 8rem;
  }
  .xs\:h-36 {
    height: 9rem;
  }
  .xs\:h-40 {
    height: 10rem;
  }
  .xs\:h-44 {
    height: 11rem;
  }
  .xs\:h-48 {
    height: 12rem;
  }
  .xs\:h-52 {
    height: 13rem;
  }
  .xs\:h-56 {
    height: 14rem;
  }
  .xs\:h-60 {
    height: 15rem;
  }
  .xs\:h-64 {
    height: 16rem;
  }
  .xs\:h-72 {
    height: 18rem;
  }
  .xs\:h-80 {
    height: 20rem;
  }
  .xs\:h-96 {
    height: 24rem;
  }
  .xs\:h-105 {
    height: 105px;
  }
  .xs\:h-188 {
    height: 188px;
  }
  .xs\:h-344 {
    height: 344px;
  }
  .xs\:h-425 {
    height: 425px;
  }
  .xs\:h-627 {
    height: 627px;
  }
  .xs\:h-auto {
    height: auto;
  }
  .xs\:h-px {
    height: 1px;
  }
  .xs\:h-0\.5 {
    height: 0.125rem;
  }
  .xs\:h-1\.5 {
    height: 0.375rem;
  }
  .xs\:h-2\.5 {
    height: 0.625rem;
  }
  .xs\:h-3\.5 {
    height: 0.875rem;
  }
  .xs\:h-1\/2 {
    height: 50%;
  }
  .xs\:h-1\/3 {
    height: 33.333333%;
  }
  .xs\:h-2\/3 {
    height: 66.666667%;
  }
  .xs\:h-1\/4 {
    height: 25%;
  }
  .xs\:h-2\/4 {
    height: 50%;
  }
  .xs\:h-3\/4 {
    height: 75%;
  }
  .xs\:h-1\/5 {
    height: 20%;
  }
  .xs\:h-2\/5 {
    height: 40%;
  }
  .xs\:h-3\/5 {
    height: 60%;
  }
  .xs\:h-4\/5 {
    height: 80%;
  }
  .xs\:h-1\/6 {
    height: 16.666667%;
  }
  .xs\:h-2\/6 {
    height: 33.333333%;
  }
  .xs\:h-3\/6 {
    height: 50%;
  }
  .xs\:h-4\/6 {
    height: 66.666667%;
  }
  .xs\:h-5\/6 {
    height: 83.333333%;
  }
  .xs\:h-full {
    height: 100%;
  }
  .xs\:h-screen {
    height: 100vh;
  }
  .xs\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .xs\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .xs\:text-base {
    font-size: 1em;
  }
  .xs\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xs\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .xs\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .xs\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xs\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xs\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xs\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .xs\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .xs\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .xs\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .xs\:text-m-sm {
    font-size: 0.8em;
  }
  .xs\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .xs\:text-m-xl {
    font-size: 1.2em;
  }
  .xs\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .xs\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .xs\:text-m-4xl {
    font-size: 1.6em;
  }
  .xs\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .xs\:text-d-sm {
    font-size: 0.875em;
  }
  .xs\:text-d-lg {
    font-size: 1.125em;
  }
  .xs\:text-d-xl {
    font-size: 1.375em;
  }
  .xs\:text-d-2xl {
    font-size: 1.625em;
  }
  .xs\:text-d-3xl {
    font-size: 1.75em;
  }
  .xs\:text-d-4xl {
    font-size: 2.25em;
  }
  .xs\:text-d-5xl {
    font-size: 2.625em;
  }
  .xs\:text-d-11xl {
    font-size: 16em;
  }
  .xs\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .xs\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .xs\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .xs\:leading-3 {
    line-height: 0.75rem;
  }
  .xs\:leading-4 {
    line-height: 1rem;
  }
  .xs\:leading-5 {
    line-height: 1.25rem;
  }
  .xs\:leading-6 {
    line-height: 1.5rem;
  }
  .xs\:leading-7 {
    line-height: 1.75rem;
  }
  .xs\:leading-8 {
    line-height: 2rem;
  }
  .xs\:leading-9 {
    line-height: 2.25rem;
  }
  .xs\:leading-10 {
    line-height: 2.5rem;
  }
  .xs\:leading-none {
    line-height: 1;
  }
  .xs\:leading-tight {
    line-height: 1.25;
  }
  .xs\:leading-snug {
    line-height: 1.375;
  }
  .xs\:leading-normal {
    line-height: 1.5;
  }
  .xs\:leading-relaxed {
    line-height: 1.625;
  }
  .xs\:leading-loose {
    line-height: 2;
  }
  .xs\:list-inside {
    list-style-position: inside;
  }
  .xs\:list-outside {
    list-style-position: outside;
  }
  .xs\:list-none {
    list-style-type: none;
  }
  .xs\:list-disc {
    list-style-type: disc;
  }
  .xs\:list-decimal {
    list-style-type: decimal;
  }
  .xs\:m-0 {
    margin: 0;
  }
  .xs\:m-1 {
    margin: 0.25rem;
  }
  .xs\:m-2 {
    margin: 0.5rem;
  }
  .xs\:m-3 {
    margin: 0.75rem;
  }
  .xs\:m-4 {
    margin: 1rem;
  }
  .xs\:m-5 {
    margin: 1.25rem;
  }
  .xs\:m-6 {
    margin: 1.5rem;
  }
  .xs\:m-7 {
    margin: 1.75rem;
  }
  .xs\:m-8 {
    margin: 2rem;
  }
  .xs\:m-9 {
    margin: 2.25rem;
  }
  .xs\:m-10 {
    margin: 2.5rem;
  }
  .xs\:m-11 {
    margin: 2.75rem;
  }
  .xs\:m-12 {
    margin: 3rem;
  }
  .xs\:m-14 {
    margin: 3.5rem;
  }
  .xs\:m-16 {
    margin: 4rem;
  }
  .xs\:m-20 {
    margin: 5rem;
  }
  .xs\:m-24 {
    margin: 6rem;
  }
  .xs\:m-28 {
    margin: 7rem;
  }
  .xs\:m-32 {
    margin: 8rem;
  }
  .xs\:m-36 {
    margin: 9rem;
  }
  .xs\:m-40 {
    margin: 10rem;
  }
  .xs\:m-44 {
    margin: 11rem;
  }
  .xs\:m-48 {
    margin: 12rem;
  }
  .xs\:m-52 {
    margin: 13rem;
  }
  .xs\:m-56 {
    margin: 14rem;
  }
  .xs\:m-60 {
    margin: 15rem;
  }
  .xs\:m-64 {
    margin: 16rem;
  }
  .xs\:m-72 {
    margin: 18rem;
  }
  .xs\:m-80 {
    margin: 20rem;
  }
  .xs\:m-96 {
    margin: 24rem;
  }
  .xs\:m-auto {
    margin: auto;
  }
  .xs\:m-px {
    margin: 1px;
  }
  .xs\:m-0\.5 {
    margin: 0.125rem;
  }
  .xs\:m-1\.5 {
    margin: 0.375rem;
  }
  .xs\:m-2\.5 {
    margin: 0.625rem;
  }
  .xs\:m-3\.5 {
    margin: 0.875rem;
  }
  .xs\:-m-0 {
    margin: 0;
  }
  .xs\:-m-1 {
    margin: -0.25rem;
  }
  .xs\:-m-2 {
    margin: -0.5rem;
  }
  .xs\:-m-3 {
    margin: -0.75rem;
  }
  .xs\:-m-4 {
    margin: -1rem;
  }
  .xs\:-m-5 {
    margin: -1.25rem;
  }
  .xs\:-m-6 {
    margin: -1.5rem;
  }
  .xs\:-m-7 {
    margin: -1.75rem;
  }
  .xs\:-m-8 {
    margin: -2rem;
  }
  .xs\:-m-9 {
    margin: -2.25rem;
  }
  .xs\:-m-10 {
    margin: -2.5rem;
  }
  .xs\:-m-11 {
    margin: -2.75rem;
  }
  .xs\:-m-12 {
    margin: -3rem;
  }
  .xs\:-m-14 {
    margin: -3.5rem;
  }
  .xs\:-m-16 {
    margin: -4rem;
  }
  .xs\:-m-20 {
    margin: -5rem;
  }
  .xs\:-m-24 {
    margin: -6rem;
  }
  .xs\:-m-28 {
    margin: -7rem;
  }
  .xs\:-m-32 {
    margin: -8rem;
  }
  .xs\:-m-36 {
    margin: -9rem;
  }
  .xs\:-m-40 {
    margin: -10rem;
  }
  .xs\:-m-44 {
    margin: -11rem;
  }
  .xs\:-m-48 {
    margin: -12rem;
  }
  .xs\:-m-52 {
    margin: -13rem;
  }
  .xs\:-m-56 {
    margin: -14rem;
  }
  .xs\:-m-60 {
    margin: -15rem;
  }
  .xs\:-m-64 {
    margin: -16rem;
  }
  .xs\:-m-72 {
    margin: -18rem;
  }
  .xs\:-m-80 {
    margin: -20rem;
  }
  .xs\:-m-96 {
    margin: -24rem;
  }
  .xs\:-m-px {
    margin: -1px;
  }
  .xs\:-m-0\.5 {
    margin: -0.125rem;
  }
  .xs\:-m-1\.5 {
    margin: -0.375rem;
  }
  .xs\:-m-2\.5 {
    margin: -0.625rem;
  }
  .xs\:-m-3\.5 {
    margin: -0.875rem;
  }
  .xs\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xs\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xs\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xs\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xs\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xs\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xs\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xs\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xs\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xs\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xs\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xs\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xs\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xs\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xs\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xs\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xs\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xs\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xs\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xs\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .xs\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .xs\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .xs\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .xs\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xs\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xs\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .xs\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .xs\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xs\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xs\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .xs\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .xs\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .xs\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .xs\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .xs\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .xs\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xs\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xs\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .xs\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .xs\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .xs\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .xs\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .xs\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .xs\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .xs\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .xs\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .xs\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .xs\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .xs\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .xs\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .xs\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .xs\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .xs\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .xs\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .xs\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .xs\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .xs\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .xs\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .xs\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .xs\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xs\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .xs\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .xs\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .xs\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .xs\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xs\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xs\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xs\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xs\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xs\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xs\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xs\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .xs\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .xs\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .xs\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xs\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .xs\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .xs\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xs\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xs\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .xs\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .xs\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .xs\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .xs\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .xs\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .xs\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .xs\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xs\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .xs\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .xs\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .xs\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .xs\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .xs\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .xs\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .xs\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .xs\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .xs\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .xs\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .xs\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xs\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .xs\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .xs\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .xs\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .xs\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .xs\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .xs\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .xs\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xs\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .xs\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .xs\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .xs\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .xs\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .xs\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .xs\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .xs\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .xs\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .xs\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .xs\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .xs\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .xs\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .xs\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .xs\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .xs\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .xs\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .xs\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .xs\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .xs\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .xs\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .xs\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .xs\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xs\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .xs\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .xs\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .xs\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .xs\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .xs\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .xs\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .xs\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .xs\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .xs\:mt-0 {
    margin-top: 0;
  }
  .xs\:mr-0 {
    margin-right: 0;
  }
  .xs\:mb-0 {
    margin-bottom: 0;
  }
  .xs\:ml-0 {
    margin-left: 0;
  }
  .xs\:mt-1 {
    margin-top: 0.25rem;
  }
  .xs\:mr-1 {
    margin-right: 0.25rem;
  }
  .xs\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xs\:ml-1 {
    margin-left: 0.25rem;
  }
  .xs\:mt-2 {
    margin-top: 0.5rem;
  }
  .xs\:mr-2 {
    margin-right: 0.5rem;
  }
  .xs\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xs\:ml-2 {
    margin-left: 0.5rem;
  }
  .xs\:mt-3 {
    margin-top: 0.75rem;
  }
  .xs\:mr-3 {
    margin-right: 0.75rem;
  }
  .xs\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xs\:ml-3 {
    margin-left: 0.75rem;
  }
  .xs\:mt-4 {
    margin-top: 1rem;
  }
  .xs\:mr-4 {
    margin-right: 1rem;
  }
  .xs\:mb-4 {
    margin-bottom: 1rem;
  }
  .xs\:ml-4 {
    margin-left: 1rem;
  }
  .xs\:mt-5 {
    margin-top: 1.25rem;
  }
  .xs\:mr-5 {
    margin-right: 1.25rem;
  }
  .xs\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xs\:ml-5 {
    margin-left: 1.25rem;
  }
  .xs\:mt-6 {
    margin-top: 1.5rem;
  }
  .xs\:mr-6 {
    margin-right: 1.5rem;
  }
  .xs\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xs\:ml-6 {
    margin-left: 1.5rem;
  }
  .xs\:mt-7 {
    margin-top: 1.75rem;
  }
  .xs\:mr-7 {
    margin-right: 1.75rem;
  }
  .xs\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xs\:ml-7 {
    margin-left: 1.75rem;
  }
  .xs\:mt-8 {
    margin-top: 2rem;
  }
  .xs\:mr-8 {
    margin-right: 2rem;
  }
  .xs\:mb-8 {
    margin-bottom: 2rem;
  }
  .xs\:ml-8 {
    margin-left: 2rem;
  }
  .xs\:mt-9 {
    margin-top: 2.25rem;
  }
  .xs\:mr-9 {
    margin-right: 2.25rem;
  }
  .xs\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xs\:ml-9 {
    margin-left: 2.25rem;
  }
  .xs\:mt-10 {
    margin-top: 2.5rem;
  }
  .xs\:mr-10 {
    margin-right: 2.5rem;
  }
  .xs\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xs\:ml-10 {
    margin-left: 2.5rem;
  }
  .xs\:mt-11 {
    margin-top: 2.75rem;
  }
  .xs\:mr-11 {
    margin-right: 2.75rem;
  }
  .xs\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .xs\:ml-11 {
    margin-left: 2.75rem;
  }
  .xs\:mt-12 {
    margin-top: 3rem;
  }
  .xs\:mr-12 {
    margin-right: 3rem;
  }
  .xs\:mb-12 {
    margin-bottom: 3rem;
  }
  .xs\:ml-12 {
    margin-left: 3rem;
  }
  .xs\:mt-14 {
    margin-top: 3.5rem;
  }
  .xs\:mr-14 {
    margin-right: 3.5rem;
  }
  .xs\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .xs\:ml-14 {
    margin-left: 3.5rem;
  }
  .xs\:mt-16 {
    margin-top: 4rem;
  }
  .xs\:mr-16 {
    margin-right: 4rem;
  }
  .xs\:mb-16 {
    margin-bottom: 4rem;
  }
  .xs\:ml-16 {
    margin-left: 4rem;
  }
  .xs\:mt-20 {
    margin-top: 5rem;
  }
  .xs\:mr-20 {
    margin-right: 5rem;
  }
  .xs\:mb-20 {
    margin-bottom: 5rem;
  }
  .xs\:ml-20 {
    margin-left: 5rem;
  }
  .xs\:mt-24 {
    margin-top: 6rem;
  }
  .xs\:mr-24 {
    margin-right: 6rem;
  }
  .xs\:mb-24 {
    margin-bottom: 6rem;
  }
  .xs\:ml-24 {
    margin-left: 6rem;
  }
  .xs\:mt-28 {
    margin-top: 7rem;
  }
  .xs\:mr-28 {
    margin-right: 7rem;
  }
  .xs\:mb-28 {
    margin-bottom: 7rem;
  }
  .xs\:ml-28 {
    margin-left: 7rem;
  }
  .xs\:mt-32 {
    margin-top: 8rem;
  }
  .xs\:mr-32 {
    margin-right: 8rem;
  }
  .xs\:mb-32 {
    margin-bottom: 8rem;
  }
  .xs\:ml-32 {
    margin-left: 8rem;
  }
  .xs\:mt-36 {
    margin-top: 9rem;
  }
  .xs\:mr-36 {
    margin-right: 9rem;
  }
  .xs\:mb-36 {
    margin-bottom: 9rem;
  }
  .xs\:ml-36 {
    margin-left: 9rem;
  }
  .xs\:mt-40 {
    margin-top: 10rem;
  }
  .xs\:mr-40 {
    margin-right: 10rem;
  }
  .xs\:mb-40 {
    margin-bottom: 10rem;
  }
  .xs\:ml-40 {
    margin-left: 10rem;
  }
  .xs\:mt-44 {
    margin-top: 11rem;
  }
  .xs\:mr-44 {
    margin-right: 11rem;
  }
  .xs\:mb-44 {
    margin-bottom: 11rem;
  }
  .xs\:ml-44 {
    margin-left: 11rem;
  }
  .xs\:mt-48 {
    margin-top: 12rem;
  }
  .xs\:mr-48 {
    margin-right: 12rem;
  }
  .xs\:mb-48 {
    margin-bottom: 12rem;
  }
  .xs\:ml-48 {
    margin-left: 12rem;
  }
  .xs\:mt-52 {
    margin-top: 13rem;
  }
  .xs\:mr-52 {
    margin-right: 13rem;
  }
  .xs\:mb-52 {
    margin-bottom: 13rem;
  }
  .xs\:ml-52 {
    margin-left: 13rem;
  }
  .xs\:mt-56 {
    margin-top: 14rem;
  }
  .xs\:mr-56 {
    margin-right: 14rem;
  }
  .xs\:mb-56 {
    margin-bottom: 14rem;
  }
  .xs\:ml-56 {
    margin-left: 14rem;
  }
  .xs\:mt-60 {
    margin-top: 15rem;
  }
  .xs\:mr-60 {
    margin-right: 15rem;
  }
  .xs\:mb-60 {
    margin-bottom: 15rem;
  }
  .xs\:ml-60 {
    margin-left: 15rem;
  }
  .xs\:mt-64 {
    margin-top: 16rem;
  }
  .xs\:mr-64 {
    margin-right: 16rem;
  }
  .xs\:mb-64 {
    margin-bottom: 16rem;
  }
  .xs\:ml-64 {
    margin-left: 16rem;
  }
  .xs\:mt-72 {
    margin-top: 18rem;
  }
  .xs\:mr-72 {
    margin-right: 18rem;
  }
  .xs\:mb-72 {
    margin-bottom: 18rem;
  }
  .xs\:ml-72 {
    margin-left: 18rem;
  }
  .xs\:mt-80 {
    margin-top: 20rem;
  }
  .xs\:mr-80 {
    margin-right: 20rem;
  }
  .xs\:mb-80 {
    margin-bottom: 20rem;
  }
  .xs\:ml-80 {
    margin-left: 20rem;
  }
  .xs\:mt-96 {
    margin-top: 24rem;
  }
  .xs\:mr-96 {
    margin-right: 24rem;
  }
  .xs\:mb-96 {
    margin-bottom: 24rem;
  }
  .xs\:ml-96 {
    margin-left: 24rem;
  }
  .xs\:mt-auto {
    margin-top: auto;
  }
  .xs\:mr-auto {
    margin-right: auto;
  }
  .xs\:mb-auto {
    margin-bottom: auto;
  }
  .xs\:ml-auto {
    margin-left: auto;
  }
  .xs\:mt-px {
    margin-top: 1px;
  }
  .xs\:mr-px {
    margin-right: 1px;
  }
  .xs\:mb-px {
    margin-bottom: 1px;
  }
  .xs\:ml-px {
    margin-left: 1px;
  }
  .xs\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .xs\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .xs\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .xs\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .xs\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xs\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xs\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xs\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xs\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xs\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xs\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xs\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xs\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xs\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xs\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xs\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xs\:-mt-0 {
    margin-top: 0;
  }
  .xs\:-mr-0 {
    margin-right: 0;
  }
  .xs\:-mb-0 {
    margin-bottom: 0;
  }
  .xs\:-ml-0 {
    margin-left: 0;
  }
  .xs\:-mt-1 {
    margin-top: -0.25rem;
  }
  .xs\:-mr-1 {
    margin-right: -0.25rem;
  }
  .xs\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .xs\:-ml-1 {
    margin-left: -0.25rem;
  }
  .xs\:-mt-2 {
    margin-top: -0.5rem;
  }
  .xs\:-mr-2 {
    margin-right: -0.5rem;
  }
  .xs\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .xs\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xs\:-mt-3 {
    margin-top: -0.75rem;
  }
  .xs\:-mr-3 {
    margin-right: -0.75rem;
  }
  .xs\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .xs\:-ml-3 {
    margin-left: -0.75rem;
  }
  .xs\:-mt-4 {
    margin-top: -1rem;
  }
  .xs\:-mr-4 {
    margin-right: -1rem;
  }
  .xs\:-mb-4 {
    margin-bottom: -1rem;
  }
  .xs\:-ml-4 {
    margin-left: -1rem;
  }
  .xs\:-mt-5 {
    margin-top: -1.25rem;
  }
  .xs\:-mr-5 {
    margin-right: -1.25rem;
  }
  .xs\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .xs\:-ml-5 {
    margin-left: -1.25rem;
  }
  .xs\:-mt-6 {
    margin-top: -1.5rem;
  }
  .xs\:-mr-6 {
    margin-right: -1.5rem;
  }
  .xs\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .xs\:-ml-6 {
    margin-left: -1.5rem;
  }
  .xs\:-mt-7 {
    margin-top: -1.75rem;
  }
  .xs\:-mr-7 {
    margin-right: -1.75rem;
  }
  .xs\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .xs\:-ml-7 {
    margin-left: -1.75rem;
  }
  .xs\:-mt-8 {
    margin-top: -2rem;
  }
  .xs\:-mr-8 {
    margin-right: -2rem;
  }
  .xs\:-mb-8 {
    margin-bottom: -2rem;
  }
  .xs\:-ml-8 {
    margin-left: -2rem;
  }
  .xs\:-mt-9 {
    margin-top: -2.25rem;
  }
  .xs\:-mr-9 {
    margin-right: -2.25rem;
  }
  .xs\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .xs\:-ml-9 {
    margin-left: -2.25rem;
  }
  .xs\:-mt-10 {
    margin-top: -2.5rem;
  }
  .xs\:-mr-10 {
    margin-right: -2.5rem;
  }
  .xs\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .xs\:-ml-10 {
    margin-left: -2.5rem;
  }
  .xs\:-mt-11 {
    margin-top: -2.75rem;
  }
  .xs\:-mr-11 {
    margin-right: -2.75rem;
  }
  .xs\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .xs\:-ml-11 {
    margin-left: -2.75rem;
  }
  .xs\:-mt-12 {
    margin-top: -3rem;
  }
  .xs\:-mr-12 {
    margin-right: -3rem;
  }
  .xs\:-mb-12 {
    margin-bottom: -3rem;
  }
  .xs\:-ml-12 {
    margin-left: -3rem;
  }
  .xs\:-mt-14 {
    margin-top: -3.5rem;
  }
  .xs\:-mr-14 {
    margin-right: -3.5rem;
  }
  .xs\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .xs\:-ml-14 {
    margin-left: -3.5rem;
  }
  .xs\:-mt-16 {
    margin-top: -4rem;
  }
  .xs\:-mr-16 {
    margin-right: -4rem;
  }
  .xs\:-mb-16 {
    margin-bottom: -4rem;
  }
  .xs\:-ml-16 {
    margin-left: -4rem;
  }
  .xs\:-mt-20 {
    margin-top: -5rem;
  }
  .xs\:-mr-20 {
    margin-right: -5rem;
  }
  .xs\:-mb-20 {
    margin-bottom: -5rem;
  }
  .xs\:-ml-20 {
    margin-left: -5rem;
  }
  .xs\:-mt-24 {
    margin-top: -6rem;
  }
  .xs\:-mr-24 {
    margin-right: -6rem;
  }
  .xs\:-mb-24 {
    margin-bottom: -6rem;
  }
  .xs\:-ml-24 {
    margin-left: -6rem;
  }
  .xs\:-mt-28 {
    margin-top: -7rem;
  }
  .xs\:-mr-28 {
    margin-right: -7rem;
  }
  .xs\:-mb-28 {
    margin-bottom: -7rem;
  }
  .xs\:-ml-28 {
    margin-left: -7rem;
  }
  .xs\:-mt-32 {
    margin-top: -8rem;
  }
  .xs\:-mr-32 {
    margin-right: -8rem;
  }
  .xs\:-mb-32 {
    margin-bottom: -8rem;
  }
  .xs\:-ml-32 {
    margin-left: -8rem;
  }
  .xs\:-mt-36 {
    margin-top: -9rem;
  }
  .xs\:-mr-36 {
    margin-right: -9rem;
  }
  .xs\:-mb-36 {
    margin-bottom: -9rem;
  }
  .xs\:-ml-36 {
    margin-left: -9rem;
  }
  .xs\:-mt-40 {
    margin-top: -10rem;
  }
  .xs\:-mr-40 {
    margin-right: -10rem;
  }
  .xs\:-mb-40 {
    margin-bottom: -10rem;
  }
  .xs\:-ml-40 {
    margin-left: -10rem;
  }
  .xs\:-mt-44 {
    margin-top: -11rem;
  }
  .xs\:-mr-44 {
    margin-right: -11rem;
  }
  .xs\:-mb-44 {
    margin-bottom: -11rem;
  }
  .xs\:-ml-44 {
    margin-left: -11rem;
  }
  .xs\:-mt-48 {
    margin-top: -12rem;
  }
  .xs\:-mr-48 {
    margin-right: -12rem;
  }
  .xs\:-mb-48 {
    margin-bottom: -12rem;
  }
  .xs\:-ml-48 {
    margin-left: -12rem;
  }
  .xs\:-mt-52 {
    margin-top: -13rem;
  }
  .xs\:-mr-52 {
    margin-right: -13rem;
  }
  .xs\:-mb-52 {
    margin-bottom: -13rem;
  }
  .xs\:-ml-52 {
    margin-left: -13rem;
  }
  .xs\:-mt-56 {
    margin-top: -14rem;
  }
  .xs\:-mr-56 {
    margin-right: -14rem;
  }
  .xs\:-mb-56 {
    margin-bottom: -14rem;
  }
  .xs\:-ml-56 {
    margin-left: -14rem;
  }
  .xs\:-mt-60 {
    margin-top: -15rem;
  }
  .xs\:-mr-60 {
    margin-right: -15rem;
  }
  .xs\:-mb-60 {
    margin-bottom: -15rem;
  }
  .xs\:-ml-60 {
    margin-left: -15rem;
  }
  .xs\:-mt-64 {
    margin-top: -16rem;
  }
  .xs\:-mr-64 {
    margin-right: -16rem;
  }
  .xs\:-mb-64 {
    margin-bottom: -16rem;
  }
  .xs\:-ml-64 {
    margin-left: -16rem;
  }
  .xs\:-mt-72 {
    margin-top: -18rem;
  }
  .xs\:-mr-72 {
    margin-right: -18rem;
  }
  .xs\:-mb-72 {
    margin-bottom: -18rem;
  }
  .xs\:-ml-72 {
    margin-left: -18rem;
  }
  .xs\:-mt-80 {
    margin-top: -20rem;
  }
  .xs\:-mr-80 {
    margin-right: -20rem;
  }
  .xs\:-mb-80 {
    margin-bottom: -20rem;
  }
  .xs\:-ml-80 {
    margin-left: -20rem;
  }
  .xs\:-mt-96 {
    margin-top: -24rem;
  }
  .xs\:-mr-96 {
    margin-right: -24rem;
  }
  .xs\:-mb-96 {
    margin-bottom: -24rem;
  }
  .xs\:-ml-96 {
    margin-left: -24rem;
  }
  .xs\:-mt-px {
    margin-top: -1px;
  }
  .xs\:-mr-px {
    margin-right: -1px;
  }
  .xs\:-mb-px {
    margin-bottom: -1px;
  }
  .xs\:-ml-px {
    margin-left: -1px;
  }
  .xs\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .xs\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .xs\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .xs\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .xs\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .xs\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .xs\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .xs\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .xs\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .xs\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .xs\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .xs\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xs\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .xs\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .xs\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .xs\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .xs\:max-h-0 {
    max-height: 0;
  }
  .xs\:max-h-1 {
    max-height: 0.25rem;
  }
  .xs\:max-h-2 {
    max-height: 0.5rem;
  }
  .xs\:max-h-3 {
    max-height: 0.75rem;
  }
  .xs\:max-h-4 {
    max-height: 1rem;
  }
  .xs\:max-h-5 {
    max-height: 1.25rem;
  }
  .xs\:max-h-6 {
    max-height: 1.5rem;
  }
  .xs\:max-h-7 {
    max-height: 1.75rem;
  }
  .xs\:max-h-8 {
    max-height: 2rem;
  }
  .xs\:max-h-9 {
    max-height: 2.25rem;
  }
  .xs\:max-h-10 {
    max-height: 2.5rem;
  }
  .xs\:max-h-11 {
    max-height: 2.75rem;
  }
  .xs\:max-h-12 {
    max-height: 3rem;
  }
  .xs\:max-h-14 {
    max-height: 3.5rem;
  }
  .xs\:max-h-16 {
    max-height: 4rem;
  }
  .xs\:max-h-20 {
    max-height: 5rem;
  }
  .xs\:max-h-24 {
    max-height: 6rem;
  }
  .xs\:max-h-28 {
    max-height: 7rem;
  }
  .xs\:max-h-32 {
    max-height: 8rem;
  }
  .xs\:max-h-36 {
    max-height: 9rem;
  }
  .xs\:max-h-40 {
    max-height: 10rem;
  }
  .xs\:max-h-44 {
    max-height: 11rem;
  }
  .xs\:max-h-48 {
    max-height: 12rem;
  }
  .xs\:max-h-52 {
    max-height: 13rem;
  }
  .xs\:max-h-56 {
    max-height: 14rem;
  }
  .xs\:max-h-60 {
    max-height: 15rem;
  }
  .xs\:max-h-64 {
    max-height: 16rem;
  }
  .xs\:max-h-72 {
    max-height: 18rem;
  }
  .xs\:max-h-80 {
    max-height: 20rem;
  }
  .xs\:max-h-96 {
    max-height: 24rem;
  }
  .xs\:max-h-px {
    max-height: 1px;
  }
  .xs\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .xs\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .xs\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .xs\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .xs\:max-h-full {
    max-height: 100%;
  }
  .xs\:max-h-screen {
    max-height: 100vh;
  }
  .xs\:max-h-1\/4 {
    max-height: 25%;
  }
  .xs\:max-h-1\/2 {
    max-height: 50%;
  }
  .xs\:max-h-3\/4 {
    max-height: 75%;
  }
  .xs\:max-h-10\/12 {
    max-height: 80%;
  }
  .xs\:max-h-enormous {
    max-height: 4000px;
  }
  .xs\:max-w-0 {
    max-width: 0;
  }
  .xs\:max-w-110 {
    max-width: 110px;
  }
  .xs\:max-w-473 {
    max-width: 473px;
  }
  .xs\:max-w-none {
    max-width: none;
  }
  .xs\:max-w-xs {
    max-width: 20rem;
  }
  .xs\:max-w-sm {
    max-width: 24rem;
  }
  .xs\:max-w-md {
    max-width: 28rem;
  }
  .xs\:max-w-lg {
    max-width: 32rem;
  }
  .xs\:max-w-xl {
    max-width: 36rem;
  }
  .xs\:max-w-2xl {
    max-width: 42rem;
  }
  .xs\:max-w-3xl {
    max-width: 48rem;
  }
  .xs\:max-w-4xl {
    max-width: 56rem;
  }
  .xs\:max-w-5xl {
    max-width: 64rem;
  }
  .xs\:max-w-6xl {
    max-width: 72rem;
  }
  .xs\:max-w-7xl {
    max-width: 80rem;
  }
  .xs\:max-w-full {
    max-width: 100%;
  }
  .xs\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .xs\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .xs\:max-w-prose {
    max-width: 65ch;
  }
  .xs\:max-w-screen-sm {
    max-width: 640px;
  }
  .xs\:max-w-screen-md {
    max-width: 768px;
  }
  .xs\:max-w-screen-lg {
    max-width: 1024px;
  }
  .xs\:max-w-screen-xl {
    max-width: 1280px;
  }
  .xs\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .xs\:max-w-screen-xxs {
    max-width: 260px;
  }
  .xs\:max-w-screen-xs {
    max-width: 360px;
  }
  .xs\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .xs\:min-h-0 {
    min-height: 0;
  }
  .xs\:min-h-full {
    min-height: 100%;
  }
  .xs\:min-h-screen {
    min-height: 100vh;
  }
  .xs\:min-w-0 {
    min-width: 0;
  }
  .xs\:min-w-10 {
    min-width: 2.5rem;
  }
  .xs\:min-w-12 {
    min-width: 3rem;
  }
  .xs\:min-w-14 {
    min-width: 3.5rem;
  }
  .xs\:min-w-full {
    min-width: 100%;
  }
  .xs\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .xs\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .xs\:object-contain {
    object-fit: contain;
  }
  .xs\:object-cover {
    object-fit: cover;
  }
  .xs\:object-fill {
    object-fit: fill;
  }
  .xs\:object-none {
    object-fit: none;
  }
  .xs\:object-scale-down {
    object-fit: scale-down;
  }
  .xs\:object-bottom {
    object-position: bottom;
  }
  .xs\:object-center {
    object-position: center;
  }
  .xs\:object-left {
    object-position: left;
  }
  .xs\:object-left-bottom {
    object-position: left bottom;
  }
  .xs\:object-left-top {
    object-position: left top;
  }
  .xs\:object-right {
    object-position: right;
  }
  .xs\:object-right-bottom {
    object-position: right bottom;
  }
  .xs\:object-right-top {
    object-position: right top;
  }
  .xs\:object-top {
    object-position: top;
  }
  .xs\:opacity-0 {
    opacity: 0;
  }
  .xs\:opacity-5 {
    opacity: 0.05;
  }
  .xs\:opacity-10 {
    opacity: 0.1;
  }
  .xs\:opacity-20 {
    opacity: 0.2;
  }
  .xs\:opacity-25 {
    opacity: 0.25;
  }
  .xs\:opacity-30 {
    opacity: 0.3;
  }
  .xs\:opacity-40 {
    opacity: 0.4;
  }
  .xs\:opacity-50 {
    opacity: 0.5;
  }
  .xs\:opacity-60 {
    opacity: 0.6;
  }
  .xs\:opacity-70 {
    opacity: 0.7;
  }
  .xs\:opacity-75 {
    opacity: 0.75;
  }
  .xs\:opacity-80 {
    opacity: 0.8;
  }
  .xs\:opacity-90 {
    opacity: 0.9;
  }
  .xs\:opacity-95 {
    opacity: 0.95;
  }
  .xs\:opacity-100 {
    opacity: 1;
  }
  .group:hover .xs\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .xs\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .xs\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .xs\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .xs\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .xs\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .xs\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .xs\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .xs\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .xs\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .xs\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .xs\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .xs\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .xs\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .xs\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .xs\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .xs\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .xs\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .xs\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .xs\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .xs\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .xs\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .xs\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .xs\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .xs\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .xs\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .xs\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .xs\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .xs\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .xs\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .xs\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .xs\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .xs\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .xs\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .xs\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .xs\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .xs\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .xs\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .xs\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .xs\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .xs\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .xs\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .xs\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .xs\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .xs\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .xs\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .xs\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .xs\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .xs\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .xs\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .xs\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .xs\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .xs\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .xs\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .xs\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .xs\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .xs\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .xs\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .xs\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .xs\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .xs\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xs\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xs\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xs\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xs\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xs\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xs\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xs\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xs\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xs\:overflow-auto {
    overflow: auto;
  }
  .xs\:overflow-hidden {
    overflow: hidden;
  }
  .xs\:overflow-visible {
    overflow: visible;
  }
  .xs\:overflow-scroll {
    overflow: scroll;
  }
  .xs\:overflow-x-auto {
    overflow-x: auto;
  }
  .xs\:overflow-y-auto {
    overflow-y: auto;
  }
  .xs\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .xs\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .xs\:overflow-x-visible {
    overflow-x: visible;
  }
  .xs\:overflow-y-visible {
    overflow-y: visible;
  }
  .xs\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .xs\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .xs\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .xs\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .xs\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .xs\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .xs\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .xs\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .xs\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .xs\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .xs\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .xs\:p-0 {
    padding: 0;
  }
  .xs\:p-1 {
    padding: 0.25rem;
  }
  .xs\:p-2 {
    padding: 0.5rem;
  }
  .xs\:p-3 {
    padding: 0.75rem;
  }
  .xs\:p-4 {
    padding: 1rem;
  }
  .xs\:p-5 {
    padding: 1.25rem;
  }
  .xs\:p-6 {
    padding: 1.5rem;
  }
  .xs\:p-7 {
    padding: 1.75rem;
  }
  .xs\:p-8 {
    padding: 2rem;
  }
  .xs\:p-9 {
    padding: 2.25rem;
  }
  .xs\:p-10 {
    padding: 2.5rem;
  }
  .xs\:p-11 {
    padding: 2.75rem;
  }
  .xs\:p-12 {
    padding: 3rem;
  }
  .xs\:p-14 {
    padding: 3.5rem;
  }
  .xs\:p-16 {
    padding: 4rem;
  }
  .xs\:p-20 {
    padding: 5rem;
  }
  .xs\:p-24 {
    padding: 6rem;
  }
  .xs\:p-28 {
    padding: 7rem;
  }
  .xs\:p-32 {
    padding: 8rem;
  }
  .xs\:p-36 {
    padding: 9rem;
  }
  .xs\:p-40 {
    padding: 10rem;
  }
  .xs\:p-44 {
    padding: 11rem;
  }
  .xs\:p-48 {
    padding: 12rem;
  }
  .xs\:p-52 {
    padding: 13rem;
  }
  .xs\:p-56 {
    padding: 14rem;
  }
  .xs\:p-60 {
    padding: 15rem;
  }
  .xs\:p-64 {
    padding: 16rem;
  }
  .xs\:p-72 {
    padding: 18rem;
  }
  .xs\:p-80 {
    padding: 20rem;
  }
  .xs\:p-96 {
    padding: 24rem;
  }
  .xs\:p-px {
    padding: 1px;
  }
  .xs\:p-0\.5 {
    padding: 0.125rem;
  }
  .xs\:p-1\.5 {
    padding: 0.375rem;
  }
  .xs\:p-2\.5 {
    padding: 0.625rem;
  }
  .xs\:p-3\.5 {
    padding: 0.875rem;
  }
  .xs\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xs\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xs\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xs\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xs\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xs\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xs\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xs\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xs\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xs\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xs\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xs\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xs\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xs\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xs\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xs\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xs\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xs\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xs\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xs\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xs\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xs\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xs\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xs\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xs\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xs\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xs\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xs\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xs\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xs\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .xs\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .xs\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xs\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xs\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .xs\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .xs\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .xs\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .xs\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .xs\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .xs\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .xs\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .xs\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .xs\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .xs\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .xs\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .xs\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .xs\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .xs\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .xs\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .xs\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .xs\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .xs\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .xs\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .xs\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .xs\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .xs\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xs\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xs\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xs\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xs\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xs\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xs\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xs\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xs\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xs\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xs\:pt-0 {
    padding-top: 0;
  }
  .xs\:pr-0 {
    padding-right: 0;
  }
  .xs\:pb-0 {
    padding-bottom: 0;
  }
  .xs\:pl-0 {
    padding-left: 0;
  }
  .xs\:pt-1 {
    padding-top: 0.25rem;
  }
  .xs\:pr-1 {
    padding-right: 0.25rem;
  }
  .xs\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xs\:pl-1 {
    padding-left: 0.25rem;
  }
  .xs\:pt-2 {
    padding-top: 0.5rem;
  }
  .xs\:pr-2 {
    padding-right: 0.5rem;
  }
  .xs\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xs\:pl-2 {
    padding-left: 0.5rem;
  }
  .xs\:pt-3 {
    padding-top: 0.75rem;
  }
  .xs\:pr-3 {
    padding-right: 0.75rem;
  }
  .xs\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xs\:pl-3 {
    padding-left: 0.75rem;
  }
  .xs\:pt-4 {
    padding-top: 1rem;
  }
  .xs\:pr-4 {
    padding-right: 1rem;
  }
  .xs\:pb-4 {
    padding-bottom: 1rem;
  }
  .xs\:pl-4 {
    padding-left: 1rem;
  }
  .xs\:pt-5 {
    padding-top: 1.25rem;
  }
  .xs\:pr-5 {
    padding-right: 1.25rem;
  }
  .xs\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xs\:pl-5 {
    padding-left: 1.25rem;
  }
  .xs\:pt-6 {
    padding-top: 1.5rem;
  }
  .xs\:pr-6 {
    padding-right: 1.5rem;
  }
  .xs\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xs\:pl-6 {
    padding-left: 1.5rem;
  }
  .xs\:pt-7 {
    padding-top: 1.75rem;
  }
  .xs\:pr-7 {
    padding-right: 1.75rem;
  }
  .xs\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xs\:pl-7 {
    padding-left: 1.75rem;
  }
  .xs\:pt-8 {
    padding-top: 2rem;
  }
  .xs\:pr-8 {
    padding-right: 2rem;
  }
  .xs\:pb-8 {
    padding-bottom: 2rem;
  }
  .xs\:pl-8 {
    padding-left: 2rem;
  }
  .xs\:pt-9 {
    padding-top: 2.25rem;
  }
  .xs\:pr-9 {
    padding-right: 2.25rem;
  }
  .xs\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xs\:pl-9 {
    padding-left: 2.25rem;
  }
  .xs\:pt-10 {
    padding-top: 2.5rem;
  }
  .xs\:pr-10 {
    padding-right: 2.5rem;
  }
  .xs\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xs\:pl-10 {
    padding-left: 2.5rem;
  }
  .xs\:pt-11 {
    padding-top: 2.75rem;
  }
  .xs\:pr-11 {
    padding-right: 2.75rem;
  }
  .xs\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xs\:pl-11 {
    padding-left: 2.75rem;
  }
  .xs\:pt-12 {
    padding-top: 3rem;
  }
  .xs\:pr-12 {
    padding-right: 3rem;
  }
  .xs\:pb-12 {
    padding-bottom: 3rem;
  }
  .xs\:pl-12 {
    padding-left: 3rem;
  }
  .xs\:pt-14 {
    padding-top: 3.5rem;
  }
  .xs\:pr-14 {
    padding-right: 3.5rem;
  }
  .xs\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xs\:pl-14 {
    padding-left: 3.5rem;
  }
  .xs\:pt-16 {
    padding-top: 4rem;
  }
  .xs\:pr-16 {
    padding-right: 4rem;
  }
  .xs\:pb-16 {
    padding-bottom: 4rem;
  }
  .xs\:pl-16 {
    padding-left: 4rem;
  }
  .xs\:pt-20 {
    padding-top: 5rem;
  }
  .xs\:pr-20 {
    padding-right: 5rem;
  }
  .xs\:pb-20 {
    padding-bottom: 5rem;
  }
  .xs\:pl-20 {
    padding-left: 5rem;
  }
  .xs\:pt-24 {
    padding-top: 6rem;
  }
  .xs\:pr-24 {
    padding-right: 6rem;
  }
  .xs\:pb-24 {
    padding-bottom: 6rem;
  }
  .xs\:pl-24 {
    padding-left: 6rem;
  }
  .xs\:pt-28 {
    padding-top: 7rem;
  }
  .xs\:pr-28 {
    padding-right: 7rem;
  }
  .xs\:pb-28 {
    padding-bottom: 7rem;
  }
  .xs\:pl-28 {
    padding-left: 7rem;
  }
  .xs\:pt-32 {
    padding-top: 8rem;
  }
  .xs\:pr-32 {
    padding-right: 8rem;
  }
  .xs\:pb-32 {
    padding-bottom: 8rem;
  }
  .xs\:pl-32 {
    padding-left: 8rem;
  }
  .xs\:pt-36 {
    padding-top: 9rem;
  }
  .xs\:pr-36 {
    padding-right: 9rem;
  }
  .xs\:pb-36 {
    padding-bottom: 9rem;
  }
  .xs\:pl-36 {
    padding-left: 9rem;
  }
  .xs\:pt-40 {
    padding-top: 10rem;
  }
  .xs\:pr-40 {
    padding-right: 10rem;
  }
  .xs\:pb-40 {
    padding-bottom: 10rem;
  }
  .xs\:pl-40 {
    padding-left: 10rem;
  }
  .xs\:pt-44 {
    padding-top: 11rem;
  }
  .xs\:pr-44 {
    padding-right: 11rem;
  }
  .xs\:pb-44 {
    padding-bottom: 11rem;
  }
  .xs\:pl-44 {
    padding-left: 11rem;
  }
  .xs\:pt-48 {
    padding-top: 12rem;
  }
  .xs\:pr-48 {
    padding-right: 12rem;
  }
  .xs\:pb-48 {
    padding-bottom: 12rem;
  }
  .xs\:pl-48 {
    padding-left: 12rem;
  }
  .xs\:pt-52 {
    padding-top: 13rem;
  }
  .xs\:pr-52 {
    padding-right: 13rem;
  }
  .xs\:pb-52 {
    padding-bottom: 13rem;
  }
  .xs\:pl-52 {
    padding-left: 13rem;
  }
  .xs\:pt-56 {
    padding-top: 14rem;
  }
  .xs\:pr-56 {
    padding-right: 14rem;
  }
  .xs\:pb-56 {
    padding-bottom: 14rem;
  }
  .xs\:pl-56 {
    padding-left: 14rem;
  }
  .xs\:pt-60 {
    padding-top: 15rem;
  }
  .xs\:pr-60 {
    padding-right: 15rem;
  }
  .xs\:pb-60 {
    padding-bottom: 15rem;
  }
  .xs\:pl-60 {
    padding-left: 15rem;
  }
  .xs\:pt-64 {
    padding-top: 16rem;
  }
  .xs\:pr-64 {
    padding-right: 16rem;
  }
  .xs\:pb-64 {
    padding-bottom: 16rem;
  }
  .xs\:pl-64 {
    padding-left: 16rem;
  }
  .xs\:pt-72 {
    padding-top: 18rem;
  }
  .xs\:pr-72 {
    padding-right: 18rem;
  }
  .xs\:pb-72 {
    padding-bottom: 18rem;
  }
  .xs\:pl-72 {
    padding-left: 18rem;
  }
  .xs\:pt-80 {
    padding-top: 20rem;
  }
  .xs\:pr-80 {
    padding-right: 20rem;
  }
  .xs\:pb-80 {
    padding-bottom: 20rem;
  }
  .xs\:pl-80 {
    padding-left: 20rem;
  }
  .xs\:pt-96 {
    padding-top: 24rem;
  }
  .xs\:pr-96 {
    padding-right: 24rem;
  }
  .xs\:pb-96 {
    padding-bottom: 24rem;
  }
  .xs\:pl-96 {
    padding-left: 24rem;
  }
  .xs\:pt-px {
    padding-top: 1px;
  }
  .xs\:pr-px {
    padding-right: 1px;
  }
  .xs\:pb-px {
    padding-bottom: 1px;
  }
  .xs\:pl-px {
    padding-left: 1px;
  }
  .xs\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xs\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xs\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xs\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xs\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xs\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xs\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xs\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xs\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xs\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xs\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xs\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xs\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xs\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xs\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xs\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xs\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .xs\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .xs\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .xs\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .xs\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .xs\:placeholder-current::placeholder {
    color: currentColor;
  }
  .xs\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xs\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xs\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .xs\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xs\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xs\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .xs\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .xs\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .xs\:placeholder-background::placeholder {
    color: var(--background);
  }
  .xs\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xs\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xs\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .xs\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xs\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xs\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .xs\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xs\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xs\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .xs\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xs\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .xs\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .xs\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xs\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xs\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .xs\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xs\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xs\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .xs\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xs\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xs\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .xs\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xs\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xs\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .xs\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xs\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xs\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .xs\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .xs\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .xs\:placeholder-success::placeholder {
    color: var(--success);
  }
  .xs\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .xs\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .xs\:placeholder-error::placeholder {
    color: var(--error);
  }
  .xs\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xs\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xs\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .xs\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xs\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .xs\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .xs\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .xs\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .xs\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .xs\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .xs\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .xs\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .xs\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xs\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xs\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .xs\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xs\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xs\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .xs\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .xs\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .xs\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .xs\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xs\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xs\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .xs\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xs\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xs\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .xs\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xs\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xs\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .xs\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xs\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .xs\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .xs\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xs\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xs\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .xs\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xs\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xs\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .xs\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xs\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xs\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .xs\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xs\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xs\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .xs\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .xs\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xs\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xs\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .xs\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .xs\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .xs\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .xs\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .xs\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .xs\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .xs\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xs\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xs\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .xs\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xs\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xs\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xs\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .xs\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .xs\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xs\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xs\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xs\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xs\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xs\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xs\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xs\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xs\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xs\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xs\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xs\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xs\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xs\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xs\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xs\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xs\:pointer-events-none {
    pointer-events: none;
  }
  .xs\:pointer-events-auto {
    pointer-events: auto;
  }
  .xs\:static {
    position: static;
  }
  .xs\:fixed {
    position: fixed;
  }
  .xs\:absolute {
    position: absolute;
  }
  .xs\:relative {
    position: relative;
  }
  .xs\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .xs\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xs\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .xs\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .xs\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .xs\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .xs\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .xs\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .xs\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .xs\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .xs\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .xs\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .xs\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .xs\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .xs\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .xs\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .xs\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .xs\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .xs\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .xs\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .xs\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .xs\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .xs\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .xs\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .xs\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .xs\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .xs\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .xs\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .xs\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .xs\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .xs\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .xs\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xs\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .xs\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .xs\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .xs\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .xs\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .xs\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xs\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .xs\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .xs\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .xs\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .xs\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .xs\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .xs\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .xs\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .xs\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .xs\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .xs\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .xs\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .xs\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .xs\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .xs\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .xs\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .xs\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .xs\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .xs\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .xs\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .xs\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .xs\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .xs\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .xs\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .xs\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .xs\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .xs\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .xs\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .xs\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .xs\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .xs\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .xs\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .xs\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .xs\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .xs\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xs\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .xs\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .xs\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .xs\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xs\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .xs\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .xs\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xs\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .xs\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .xs\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .xs\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xs\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .xs\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .xs\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xs\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .xs\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .xs\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .xs\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .xs\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .xs\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .xs\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .xs\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .xs\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .xs\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .xs\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .xs\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .xs\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .xs\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .xs\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .xs\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .xs\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .xs\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .xs\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .xs\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .xs\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .xs\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .xs\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .xs\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .xs\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .xs\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .xs\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .xs\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .xs\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .xs\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .xs\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .xs\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .xs\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .xs\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .xs\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .xs\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .xs\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .xs\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .xs\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .xs\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .xs\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .xs\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .xs\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .xs\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .xs\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .xs\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .xs\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .xs\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .xs\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .xs\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .xs\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .xs\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .xs\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .xs\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .xs\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .xs\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .xs\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .xs\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .xs\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .xs\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .xs\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .xs\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .xs\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .xs\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .xs\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .xs\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .xs\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .xs\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .xs\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .xs\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .xs\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .xs\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xs\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .xs\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .xs\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .xs\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .xs\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .xs\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .xs\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .xs\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .xs\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .xs\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .xs\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .xs\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .xs\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .xs\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .xs\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .xs\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .xs\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .xs\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .xs\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .xs\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .xs\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .xs\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .xs\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .xs\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .xs\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .xs\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .xs\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .xs\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .xs\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .xs\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .xs\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .xs\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .xs\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .xs\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .xs\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .xs\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .xs\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .xs\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .xs\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .xs\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .xs\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .xs\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .xs\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .xs\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .xs\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .xs\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .xs\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .xs\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .xs\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .xs\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .xs\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .xs\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .xs\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .xs\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .xs\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .xs\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .xs\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .xs\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .xs\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .xs\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .xs\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .xs\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .xs\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .xs\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .xs\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .xs\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .xs\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .xs\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .xs\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .xs\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .xs\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .xs\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .xs\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .xs\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .xs\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .xs\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .xs\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .xs\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .xs\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .xs\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .xs\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .xs\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .xs\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .xs\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .xs\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .xs\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .xs\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .xs\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .xs\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .xs\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .xs\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .xs\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .xs\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .xs\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .xs\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .xs\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .xs\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .xs\:top-0 {
    top: 0;
  }
  .xs\:right-0 {
    right: 0;
  }
  .xs\:bottom-0 {
    bottom: 0;
  }
  .xs\:left-0 {
    left: 0;
  }
  .xs\:top-1 {
    top: 0.25rem;
  }
  .xs\:right-1 {
    right: 0.25rem;
  }
  .xs\:bottom-1 {
    bottom: 0.25rem;
  }
  .xs\:left-1 {
    left: 0.25rem;
  }
  .xs\:top-2 {
    top: 0.5rem;
  }
  .xs\:right-2 {
    right: 0.5rem;
  }
  .xs\:bottom-2 {
    bottom: 0.5rem;
  }
  .xs\:left-2 {
    left: 0.5rem;
  }
  .xs\:top-3 {
    top: 0.75rem;
  }
  .xs\:right-3 {
    right: 0.75rem;
  }
  .xs\:bottom-3 {
    bottom: 0.75rem;
  }
  .xs\:left-3 {
    left: 0.75rem;
  }
  .xs\:top-4 {
    top: 1rem;
  }
  .xs\:right-4 {
    right: 1rem;
  }
  .xs\:bottom-4 {
    bottom: 1rem;
  }
  .xs\:left-4 {
    left: 1rem;
  }
  .xs\:top-5 {
    top: 1.25rem;
  }
  .xs\:right-5 {
    right: 1.25rem;
  }
  .xs\:bottom-5 {
    bottom: 1.25rem;
  }
  .xs\:left-5 {
    left: 1.25rem;
  }
  .xs\:top-6 {
    top: 1.5rem;
  }
  .xs\:right-6 {
    right: 1.5rem;
  }
  .xs\:bottom-6 {
    bottom: 1.5rem;
  }
  .xs\:left-6 {
    left: 1.5rem;
  }
  .xs\:top-7 {
    top: 1.75rem;
  }
  .xs\:right-7 {
    right: 1.75rem;
  }
  .xs\:bottom-7 {
    bottom: 1.75rem;
  }
  .xs\:left-7 {
    left: 1.75rem;
  }
  .xs\:top-8 {
    top: 2rem;
  }
  .xs\:right-8 {
    right: 2rem;
  }
  .xs\:bottom-8 {
    bottom: 2rem;
  }
  .xs\:left-8 {
    left: 2rem;
  }
  .xs\:top-9 {
    top: 2.25rem;
  }
  .xs\:right-9 {
    right: 2.25rem;
  }
  .xs\:bottom-9 {
    bottom: 2.25rem;
  }
  .xs\:left-9 {
    left: 2.25rem;
  }
  .xs\:top-10 {
    top: 2.5rem;
  }
  .xs\:right-10 {
    right: 2.5rem;
  }
  .xs\:bottom-10 {
    bottom: 2.5rem;
  }
  .xs\:left-10 {
    left: 2.5rem;
  }
  .xs\:top-11 {
    top: 2.75rem;
  }
  .xs\:right-11 {
    right: 2.75rem;
  }
  .xs\:bottom-11 {
    bottom: 2.75rem;
  }
  .xs\:left-11 {
    left: 2.75rem;
  }
  .xs\:top-12 {
    top: 3rem;
  }
  .xs\:right-12 {
    right: 3rem;
  }
  .xs\:bottom-12 {
    bottom: 3rem;
  }
  .xs\:left-12 {
    left: 3rem;
  }
  .xs\:top-14 {
    top: 3.5rem;
  }
  .xs\:right-14 {
    right: 3.5rem;
  }
  .xs\:bottom-14 {
    bottom: 3.5rem;
  }
  .xs\:left-14 {
    left: 3.5rem;
  }
  .xs\:top-16 {
    top: 4rem;
  }
  .xs\:right-16 {
    right: 4rem;
  }
  .xs\:bottom-16 {
    bottom: 4rem;
  }
  .xs\:left-16 {
    left: 4rem;
  }
  .xs\:top-20 {
    top: 5rem;
  }
  .xs\:right-20 {
    right: 5rem;
  }
  .xs\:bottom-20 {
    bottom: 5rem;
  }
  .xs\:left-20 {
    left: 5rem;
  }
  .xs\:top-24 {
    top: 6rem;
  }
  .xs\:right-24 {
    right: 6rem;
  }
  .xs\:bottom-24 {
    bottom: 6rem;
  }
  .xs\:left-24 {
    left: 6rem;
  }
  .xs\:top-28 {
    top: 7rem;
  }
  .xs\:right-28 {
    right: 7rem;
  }
  .xs\:bottom-28 {
    bottom: 7rem;
  }
  .xs\:left-28 {
    left: 7rem;
  }
  .xs\:top-32 {
    top: 8rem;
  }
  .xs\:right-32 {
    right: 8rem;
  }
  .xs\:bottom-32 {
    bottom: 8rem;
  }
  .xs\:left-32 {
    left: 8rem;
  }
  .xs\:top-36 {
    top: 9rem;
  }
  .xs\:right-36 {
    right: 9rem;
  }
  .xs\:bottom-36 {
    bottom: 9rem;
  }
  .xs\:left-36 {
    left: 9rem;
  }
  .xs\:top-40 {
    top: 10rem;
  }
  .xs\:right-40 {
    right: 10rem;
  }
  .xs\:bottom-40 {
    bottom: 10rem;
  }
  .xs\:left-40 {
    left: 10rem;
  }
  .xs\:top-44 {
    top: 11rem;
  }
  .xs\:right-44 {
    right: 11rem;
  }
  .xs\:bottom-44 {
    bottom: 11rem;
  }
  .xs\:left-44 {
    left: 11rem;
  }
  .xs\:top-48 {
    top: 12rem;
  }
  .xs\:right-48 {
    right: 12rem;
  }
  .xs\:bottom-48 {
    bottom: 12rem;
  }
  .xs\:left-48 {
    left: 12rem;
  }
  .xs\:top-52 {
    top: 13rem;
  }
  .xs\:right-52 {
    right: 13rem;
  }
  .xs\:bottom-52 {
    bottom: 13rem;
  }
  .xs\:left-52 {
    left: 13rem;
  }
  .xs\:top-56 {
    top: 14rem;
  }
  .xs\:right-56 {
    right: 14rem;
  }
  .xs\:bottom-56 {
    bottom: 14rem;
  }
  .xs\:left-56 {
    left: 14rem;
  }
  .xs\:top-60 {
    top: 15rem;
  }
  .xs\:right-60 {
    right: 15rem;
  }
  .xs\:bottom-60 {
    bottom: 15rem;
  }
  .xs\:left-60 {
    left: 15rem;
  }
  .xs\:top-64 {
    top: 16rem;
  }
  .xs\:right-64 {
    right: 16rem;
  }
  .xs\:bottom-64 {
    bottom: 16rem;
  }
  .xs\:left-64 {
    left: 16rem;
  }
  .xs\:top-72 {
    top: 18rem;
  }
  .xs\:right-72 {
    right: 18rem;
  }
  .xs\:bottom-72 {
    bottom: 18rem;
  }
  .xs\:left-72 {
    left: 18rem;
  }
  .xs\:top-80 {
    top: 20rem;
  }
  .xs\:right-80 {
    right: 20rem;
  }
  .xs\:bottom-80 {
    bottom: 20rem;
  }
  .xs\:left-80 {
    left: 20rem;
  }
  .xs\:top-96 {
    top: 24rem;
  }
  .xs\:right-96 {
    right: 24rem;
  }
  .xs\:bottom-96 {
    bottom: 24rem;
  }
  .xs\:left-96 {
    left: 24rem;
  }
  .xs\:top-auto {
    top: auto;
  }
  .xs\:right-auto {
    right: auto;
  }
  .xs\:bottom-auto {
    bottom: auto;
  }
  .xs\:left-auto {
    left: auto;
  }
  .xs\:top-px {
    top: 1px;
  }
  .xs\:right-px {
    right: 1px;
  }
  .xs\:bottom-px {
    bottom: 1px;
  }
  .xs\:left-px {
    left: 1px;
  }
  .xs\:top-0\.5 {
    top: 0.125rem;
  }
  .xs\:right-0\.5 {
    right: 0.125rem;
  }
  .xs\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .xs\:left-0\.5 {
    left: 0.125rem;
  }
  .xs\:top-1\.5 {
    top: 0.375rem;
  }
  .xs\:right-1\.5 {
    right: 0.375rem;
  }
  .xs\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .xs\:left-1\.5 {
    left: 0.375rem;
  }
  .xs\:top-2\.5 {
    top: 0.625rem;
  }
  .xs\:right-2\.5 {
    right: 0.625rem;
  }
  .xs\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .xs\:left-2\.5 {
    left: 0.625rem;
  }
  .xs\:top-3\.5 {
    top: 0.875rem;
  }
  .xs\:right-3\.5 {
    right: 0.875rem;
  }
  .xs\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .xs\:left-3\.5 {
    left: 0.875rem;
  }
  .xs\:-top-0 {
    top: 0;
  }
  .xs\:-right-0 {
    right: 0;
  }
  .xs\:-bottom-0 {
    bottom: 0;
  }
  .xs\:-left-0 {
    left: 0;
  }
  .xs\:-top-1 {
    top: -0.25rem;
  }
  .xs\:-right-1 {
    right: -0.25rem;
  }
  .xs\:-bottom-1 {
    bottom: -0.25rem;
  }
  .xs\:-left-1 {
    left: -0.25rem;
  }
  .xs\:-top-2 {
    top: -0.5rem;
  }
  .xs\:-right-2 {
    right: -0.5rem;
  }
  .xs\:-bottom-2 {
    bottom: -0.5rem;
  }
  .xs\:-left-2 {
    left: -0.5rem;
  }
  .xs\:-top-3 {
    top: -0.75rem;
  }
  .xs\:-right-3 {
    right: -0.75rem;
  }
  .xs\:-bottom-3 {
    bottom: -0.75rem;
  }
  .xs\:-left-3 {
    left: -0.75rem;
  }
  .xs\:-top-4 {
    top: -1rem;
  }
  .xs\:-right-4 {
    right: -1rem;
  }
  .xs\:-bottom-4 {
    bottom: -1rem;
  }
  .xs\:-left-4 {
    left: -1rem;
  }
  .xs\:-top-5 {
    top: -1.25rem;
  }
  .xs\:-right-5 {
    right: -1.25rem;
  }
  .xs\:-bottom-5 {
    bottom: -1.25rem;
  }
  .xs\:-left-5 {
    left: -1.25rem;
  }
  .xs\:-top-6 {
    top: -1.5rem;
  }
  .xs\:-right-6 {
    right: -1.5rem;
  }
  .xs\:-bottom-6 {
    bottom: -1.5rem;
  }
  .xs\:-left-6 {
    left: -1.5rem;
  }
  .xs\:-top-7 {
    top: -1.75rem;
  }
  .xs\:-right-7 {
    right: -1.75rem;
  }
  .xs\:-bottom-7 {
    bottom: -1.75rem;
  }
  .xs\:-left-7 {
    left: -1.75rem;
  }
  .xs\:-top-8 {
    top: -2rem;
  }
  .xs\:-right-8 {
    right: -2rem;
  }
  .xs\:-bottom-8 {
    bottom: -2rem;
  }
  .xs\:-left-8 {
    left: -2rem;
  }
  .xs\:-top-9 {
    top: -2.25rem;
  }
  .xs\:-right-9 {
    right: -2.25rem;
  }
  .xs\:-bottom-9 {
    bottom: -2.25rem;
  }
  .xs\:-left-9 {
    left: -2.25rem;
  }
  .xs\:-top-10 {
    top: -2.5rem;
  }
  .xs\:-right-10 {
    right: -2.5rem;
  }
  .xs\:-bottom-10 {
    bottom: -2.5rem;
  }
  .xs\:-left-10 {
    left: -2.5rem;
  }
  .xs\:-top-11 {
    top: -2.75rem;
  }
  .xs\:-right-11 {
    right: -2.75rem;
  }
  .xs\:-bottom-11 {
    bottom: -2.75rem;
  }
  .xs\:-left-11 {
    left: -2.75rem;
  }
  .xs\:-top-12 {
    top: -3rem;
  }
  .xs\:-right-12 {
    right: -3rem;
  }
  .xs\:-bottom-12 {
    bottom: -3rem;
  }
  .xs\:-left-12 {
    left: -3rem;
  }
  .xs\:-top-14 {
    top: -3.5rem;
  }
  .xs\:-right-14 {
    right: -3.5rem;
  }
  .xs\:-bottom-14 {
    bottom: -3.5rem;
  }
  .xs\:-left-14 {
    left: -3.5rem;
  }
  .xs\:-top-16 {
    top: -4rem;
  }
  .xs\:-right-16 {
    right: -4rem;
  }
  .xs\:-bottom-16 {
    bottom: -4rem;
  }
  .xs\:-left-16 {
    left: -4rem;
  }
  .xs\:-top-20 {
    top: -5rem;
  }
  .xs\:-right-20 {
    right: -5rem;
  }
  .xs\:-bottom-20 {
    bottom: -5rem;
  }
  .xs\:-left-20 {
    left: -5rem;
  }
  .xs\:-top-24 {
    top: -6rem;
  }
  .xs\:-right-24 {
    right: -6rem;
  }
  .xs\:-bottom-24 {
    bottom: -6rem;
  }
  .xs\:-left-24 {
    left: -6rem;
  }
  .xs\:-top-28 {
    top: -7rem;
  }
  .xs\:-right-28 {
    right: -7rem;
  }
  .xs\:-bottom-28 {
    bottom: -7rem;
  }
  .xs\:-left-28 {
    left: -7rem;
  }
  .xs\:-top-32 {
    top: -8rem;
  }
  .xs\:-right-32 {
    right: -8rem;
  }
  .xs\:-bottom-32 {
    bottom: -8rem;
  }
  .xs\:-left-32 {
    left: -8rem;
  }
  .xs\:-top-36 {
    top: -9rem;
  }
  .xs\:-right-36 {
    right: -9rem;
  }
  .xs\:-bottom-36 {
    bottom: -9rem;
  }
  .xs\:-left-36 {
    left: -9rem;
  }
  .xs\:-top-40 {
    top: -10rem;
  }
  .xs\:-right-40 {
    right: -10rem;
  }
  .xs\:-bottom-40 {
    bottom: -10rem;
  }
  .xs\:-left-40 {
    left: -10rem;
  }
  .xs\:-top-44 {
    top: -11rem;
  }
  .xs\:-right-44 {
    right: -11rem;
  }
  .xs\:-bottom-44 {
    bottom: -11rem;
  }
  .xs\:-left-44 {
    left: -11rem;
  }
  .xs\:-top-48 {
    top: -12rem;
  }
  .xs\:-right-48 {
    right: -12rem;
  }
  .xs\:-bottom-48 {
    bottom: -12rem;
  }
  .xs\:-left-48 {
    left: -12rem;
  }
  .xs\:-top-52 {
    top: -13rem;
  }
  .xs\:-right-52 {
    right: -13rem;
  }
  .xs\:-bottom-52 {
    bottom: -13rem;
  }
  .xs\:-left-52 {
    left: -13rem;
  }
  .xs\:-top-56 {
    top: -14rem;
  }
  .xs\:-right-56 {
    right: -14rem;
  }
  .xs\:-bottom-56 {
    bottom: -14rem;
  }
  .xs\:-left-56 {
    left: -14rem;
  }
  .xs\:-top-60 {
    top: -15rem;
  }
  .xs\:-right-60 {
    right: -15rem;
  }
  .xs\:-bottom-60 {
    bottom: -15rem;
  }
  .xs\:-left-60 {
    left: -15rem;
  }
  .xs\:-top-64 {
    top: -16rem;
  }
  .xs\:-right-64 {
    right: -16rem;
  }
  .xs\:-bottom-64 {
    bottom: -16rem;
  }
  .xs\:-left-64 {
    left: -16rem;
  }
  .xs\:-top-72 {
    top: -18rem;
  }
  .xs\:-right-72 {
    right: -18rem;
  }
  .xs\:-bottom-72 {
    bottom: -18rem;
  }
  .xs\:-left-72 {
    left: -18rem;
  }
  .xs\:-top-80 {
    top: -20rem;
  }
  .xs\:-right-80 {
    right: -20rem;
  }
  .xs\:-bottom-80 {
    bottom: -20rem;
  }
  .xs\:-left-80 {
    left: -20rem;
  }
  .xs\:-top-96 {
    top: -24rem;
  }
  .xs\:-right-96 {
    right: -24rem;
  }
  .xs\:-bottom-96 {
    bottom: -24rem;
  }
  .xs\:-left-96 {
    left: -24rem;
  }
  .xs\:-top-px {
    top: -1px;
  }
  .xs\:-right-px {
    right: -1px;
  }
  .xs\:-bottom-px {
    bottom: -1px;
  }
  .xs\:-left-px {
    left: -1px;
  }
  .xs\:-top-0\.5 {
    top: -0.125rem;
  }
  .xs\:-right-0\.5 {
    right: -0.125rem;
  }
  .xs\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .xs\:-left-0\.5 {
    left: -0.125rem;
  }
  .xs\:-top-1\.5 {
    top: -0.375rem;
  }
  .xs\:-right-1\.5 {
    right: -0.375rem;
  }
  .xs\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .xs\:-left-1\.5 {
    left: -0.375rem;
  }
  .xs\:-top-2\.5 {
    top: -0.625rem;
  }
  .xs\:-right-2\.5 {
    right: -0.625rem;
  }
  .xs\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .xs\:-left-2\.5 {
    left: -0.625rem;
  }
  .xs\:-top-3\.5 {
    top: -0.875rem;
  }
  .xs\:-right-3\.5 {
    right: -0.875rem;
  }
  .xs\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .xs\:-left-3\.5 {
    left: -0.875rem;
  }
  .xs\:top-1\/2 {
    top: 50%;
  }
  .xs\:right-1\/2 {
    right: 50%;
  }
  .xs\:bottom-1\/2 {
    bottom: 50%;
  }
  .xs\:left-1\/2 {
    left: 50%;
  }
  .xs\:top-1\/3 {
    top: 33.333333%;
  }
  .xs\:right-1\/3 {
    right: 33.333333%;
  }
  .xs\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .xs\:left-1\/3 {
    left: 33.333333%;
  }
  .xs\:top-2\/3 {
    top: 66.666667%;
  }
  .xs\:right-2\/3 {
    right: 66.666667%;
  }
  .xs\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .xs\:left-2\/3 {
    left: 66.666667%;
  }
  .xs\:top-1\/4 {
    top: 25%;
  }
  .xs\:right-1\/4 {
    right: 25%;
  }
  .xs\:bottom-1\/4 {
    bottom: 25%;
  }
  .xs\:left-1\/4 {
    left: 25%;
  }
  .xs\:top-2\/4 {
    top: 50%;
  }
  .xs\:right-2\/4 {
    right: 50%;
  }
  .xs\:bottom-2\/4 {
    bottom: 50%;
  }
  .xs\:left-2\/4 {
    left: 50%;
  }
  .xs\:top-3\/4 {
    top: 75%;
  }
  .xs\:right-3\/4 {
    right: 75%;
  }
  .xs\:bottom-3\/4 {
    bottom: 75%;
  }
  .xs\:left-3\/4 {
    left: 75%;
  }
  .xs\:top-full {
    top: 100%;
  }
  .xs\:right-full {
    right: 100%;
  }
  .xs\:bottom-full {
    bottom: 100%;
  }
  .xs\:left-full {
    left: 100%;
  }
  .xs\:-top-1\/2 {
    top: -50%;
  }
  .xs\:-right-1\/2 {
    right: -50%;
  }
  .xs\:-bottom-1\/2 {
    bottom: -50%;
  }
  .xs\:-left-1\/2 {
    left: -50%;
  }
  .xs\:-top-1\/3 {
    top: -33.333333%;
  }
  .xs\:-right-1\/3 {
    right: -33.333333%;
  }
  .xs\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .xs\:-left-1\/3 {
    left: -33.333333%;
  }
  .xs\:-top-2\/3 {
    top: -66.666667%;
  }
  .xs\:-right-2\/3 {
    right: -66.666667%;
  }
  .xs\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .xs\:-left-2\/3 {
    left: -66.666667%;
  }
  .xs\:-top-1\/4 {
    top: -25%;
  }
  .xs\:-right-1\/4 {
    right: -25%;
  }
  .xs\:-bottom-1\/4 {
    bottom: -25%;
  }
  .xs\:-left-1\/4 {
    left: -25%;
  }
  .xs\:-top-2\/4 {
    top: -50%;
  }
  .xs\:-right-2\/4 {
    right: -50%;
  }
  .xs\:-bottom-2\/4 {
    bottom: -50%;
  }
  .xs\:-left-2\/4 {
    left: -50%;
  }
  .xs\:-top-3\/4 {
    top: -75%;
  }
  .xs\:-right-3\/4 {
    right: -75%;
  }
  .xs\:-bottom-3\/4 {
    bottom: -75%;
  }
  .xs\:-left-3\/4 {
    left: -75%;
  }
  .xs\:-top-full {
    top: -100%;
  }
  .xs\:-right-full {
    right: -100%;
  }
  .xs\:-bottom-full {
    bottom: -100%;
  }
  .xs\:-left-full {
    left: -100%;
  }
  .xs\:resize-none {
    resize: none;
  }
  .xs\:resize-y {
    resize: vertical;
  }
  .xs\:resize-x {
    resize: horizontal;
  }
  .xs\:resize {
    resize: both;
  }
  .xs\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xs\:shadow,
  .xs\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xs\:shadow-lg,
  .xs\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xs\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xs\:shadow-2xl,
  .xs\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xs\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:shadow-inner,
  .xs\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .xs\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xs\:shadow-bottom-nav,
  .xs\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xs\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .xs\:group-hover\:shadow-sm,
  .xs\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .xs\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xs\:group-hover\:shadow,
  .group:hover .xs\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .xs\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .xs\:group-hover\:shadow-lg,
  .group:hover .xs\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .xs\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .xs\:group-hover\:shadow-2xl,
  .group:hover .xs\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xs\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .xs\:group-hover\:shadow-none,
  .group:hover .xs\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .xs\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .xs\:group-hover\:shadow-bottom-nav,
  .group:hover .xs\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xs\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xs\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xs\:hover\:shadow-sm:hover,
  .xs\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xs\:hover\:shadow-lg:hover,
  .xs\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xs\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xs\:hover\:shadow-2xl:hover,
  .xs\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xs\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:hover\:shadow-inner:hover,
  .xs\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .xs\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xs\:hover\:shadow-bottom-nav:hover,
  .xs\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xs\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xs\:focus\:shadow-sm:focus,
  .xs\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xs\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:focus\:shadow-md:focus,
  .xs\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xs\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xs\:focus\:shadow-lg:focus,
  .xs\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xs\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xs\:focus\:shadow-2xl:focus,
  .xs\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xs\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .xs\:focus\:shadow-none:focus,
  .xs\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xs\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xs\:focus\:shadow-bottom-nav:focus,
  .xs\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xs\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xs\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring-0,
  .xs\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring-2,
  .xs\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring,
  .xs\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:ring-inset {
    --tw-ring-inset: inset;
  }
  .xs\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .xs\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-0:focus,
  .xs\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-2:focus,
  .xs\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-8:focus,
  .xs\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xs\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xs\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .xs\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .xs\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .xs\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .xs\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .xs\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .xs\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xs\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .xs\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .xs\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .xs\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .xs\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .xs\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .xs\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .xs\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .xs\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .xs\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .xs\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .xs\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .xs\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .xs\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .xs\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .xs\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xs\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .xs\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .xs\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .xs\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .xs\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .xs\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .xs\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .xs\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .xs\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .xs\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .xs\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xs\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .xs\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xs\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xs\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .xs\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .xs\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .xs\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .xs\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .xs\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .xs\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .xs\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .xs\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xs\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .xs\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .xs\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .xs\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .xs\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xs\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .xs\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xs\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .xs\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xs\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xs\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xs\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .xs\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .xs\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .xs\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .xs\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .xs\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .xs\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xs\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .xs\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xs\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xs\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .xs\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xs\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .xs\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .xs\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .xs\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .xs\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xs\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .xs\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xs\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xs\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .xs\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .xs\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .xs\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .xs\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .xs\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .xs\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xs\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .xs\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .xs\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xs\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xs\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .xs\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .xs\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .xs\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .xs\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .xs\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .xs\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .xs\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xs\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .xs\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .xs\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .xs\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .xs\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .xs\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .xs\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .xs\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .xs\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .xs\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xs\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xs\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xs\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xs\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .xs\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .xs\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .xs\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xs\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .xs\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .xs\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xs\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xs\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .xs\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .xs\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .xs\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .xs\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xs\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .xs\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .xs\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xs\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .xs\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .xs\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .xs\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .xs\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .xs\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .xs\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .xs\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .xs\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .xs\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xs\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .xs\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .xs\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .xs\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .xs\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .xs\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .xs\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .xs\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .xs\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .xs\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .xs\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .xs\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .xs\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .xs\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .xs\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .xs\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xs\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .xs\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .xs\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .xs\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .xs\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .xs\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .xs\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .xs\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .xs\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .xs\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .xs\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xs\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .xs\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xs\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xs\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .xs\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .xs\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .xs\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .xs\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .xs\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .xs\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .xs\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .xs\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xs\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .xs\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .xs\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .xs\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .xs\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xs\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .xs\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xs\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .xs\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xs\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xs\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xs\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .xs\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .xs\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .xs\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .xs\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .xs\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .xs\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xs\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .xs\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xs\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xs\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .xs\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xs\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .xs\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .xs\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .xs\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .xs\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xs\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .xs\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xs\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xs\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .xs\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .xs\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .xs\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .xs\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .xs\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .xs\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xs\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .xs\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .xs\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xs\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xs\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .xs\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .xs\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .xs\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .xs\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .xs\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .xs\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .xs\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xs\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .xs\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .xs\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .xs\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .xs\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .xs\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .xs\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .xs\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .xs\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .xs\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xs\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xs\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xs\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xs\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .xs\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .xs\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .xs\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xs\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .xs\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .xs\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xs\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xs\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .xs\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .xs\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .xs\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .xs\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xs\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .xs\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .xs\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xs\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .xs\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .xs\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .xs\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .xs\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .xs\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .xs\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .xs\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .xs\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .xs\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xs\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .xs\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .xs\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .xs\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .xs\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .xs\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .xs\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .xs\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .xs\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .xs\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .xs\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .xs\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .xs\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .xs\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .xs\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .xs\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xs\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .xs\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .xs\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .xs\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .xs\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .xs\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .xs\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .xs\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .xs\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .xs\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .xs\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xs\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .xs\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xs\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xs\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .xs\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .xs\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .xs\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .xs\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .xs\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .xs\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .xs\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .xs\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xs\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .xs\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .xs\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .xs\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .xs\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xs\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .xs\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xs\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .xs\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xs\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xs\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xs\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .xs\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .xs\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .xs\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .xs\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .xs\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .xs\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xs\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .xs\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xs\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xs\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .xs\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xs\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .xs\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .xs\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .xs\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .xs\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xs\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .xs\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xs\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xs\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .xs\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .xs\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .xs\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .xs\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .xs\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .xs\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xs\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .xs\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .xs\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xs\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xs\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .xs\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .xs\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .xs\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .xs\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .xs\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .xs\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .xs\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xs\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .xs\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .xs\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .xs\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .xs\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .xs\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .xs\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .xs\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .xs\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .xs\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xs\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xs\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xs\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xs\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .xs\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .xs\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .xs\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xs\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .xs\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .xs\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xs\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xs\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .xs\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .xs\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .xs\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .xs\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xs\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .xs\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .xs\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xs\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xs\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .xs\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .xs\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xs\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .xs\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .xs\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .xs\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .xs\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .xs\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .xs\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .xs\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .xs\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .xs\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .xs\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .xs\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .xs\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .xs\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .xs\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .xs\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .xs\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .xs\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .xs\:ring-current {
    --tw-ring-color: currentColor;
  }
  .xs\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xs\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xs\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xs\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xs\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xs\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xs\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xs\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xs\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xs\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xs\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xs\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xs\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xs\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xs\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xs\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xs\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xs\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xs\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xs\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xs\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xs\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xs\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xs\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xs\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xs\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xs\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xs\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .xs\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xs\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xs\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xs\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xs\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xs\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xs\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xs\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xs\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xs\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xs\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xs\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xs\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .xs\:ring-background {
    --tw-ring-color: var(--background);
  }
  .xs\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .xs\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .xs\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .xs\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .xs\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .xs\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .xs\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .xs\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .xs\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xs\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .xs\:ring-success {
    --tw-ring-color: var(--success);
  }
  .xs\:ring-error {
    --tw-ring-color: var(--error);
  }
  .xs\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .xs\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xs\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xs\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xs\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .xs\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xs\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xs\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .xs\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .xs\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .xs\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .xs\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .xs\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .xs\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .xs\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .xs\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .xs\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .xs\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .xs\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .xs\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .xs\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xs\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .xs\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .xs\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .xs\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .xs\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xs\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xs\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .xs\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xs\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .xs\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .xs\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .xs\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .xs\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .xs\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .xs\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .xs\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .xs\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .xs\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .xs\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .xs\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .xs\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .xs\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xs\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .xs\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .xs\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .xs\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .xs\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xs\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xs\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .xs\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xs\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xs\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .xs\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .xs\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .xs\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .xs\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .xs\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .xs\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .xs\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .xs\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .xs\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .xs\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .xs\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .xs\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .xs\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .xs\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .xs\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .xs\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .xs\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .xs\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .xs\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .xs\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .xs\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .xs\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .xs\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .xs\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .xs\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .xs\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .xs\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .xs\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .xs\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .xs\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .xs\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .xs\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .xs\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .xs\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .xs\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .xs\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .xs\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .xs\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .xs\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .xs\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .xs\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .xs\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .xs\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .xs\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .xs\:fill-current {
    fill: currentColor;
  }
  .xs\:stroke-current {
    stroke: currentColor;
  }
  .xs\:stroke-0 {
    stroke-width: 0;
  }
  .xs\:stroke-1 {
    stroke-width: 1;
  }
  .xs\:stroke-2 {
    stroke-width: 2;
  }
  .xs\:table-auto {
    table-layout: auto;
  }
  .xs\:table-fixed {
    table-layout: fixed;
  }
  .xs\:text-left {
    text-align: left;
  }
  .xs\:text-center {
    text-align: center;
  }
  .xs\:text-right {
    text-align: right;
  }
  .xs\:text-justify {
    text-align: justify;
  }
  .xs\:text-transparent {
    color: transparent;
  }
  .xs\:text-current {
    color: currentColor;
  }
  .xs\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xs\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xs\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xs\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xs\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xs\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xs\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xs\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xs\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xs\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xs\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xs\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xs\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xs\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xs\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xs\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xs\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xs\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xs\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xs\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xs\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xs\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xs\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xs\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xs\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xs\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xs\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xs\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xs\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xs\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xs\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xs\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xs\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xs\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xs\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xs\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xs\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xs\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xs\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xs\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xs\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xs\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xs\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xs\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xs\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xs\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xs\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xs\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xs\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xs\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xs\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xs\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xs\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xs\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xs\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xs\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xs\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xs\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xs\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xs\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xs\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xs\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xs\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xs\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xs\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xs\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xs\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xs\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xs\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xs\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xs\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xs\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xs\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xs\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xs\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xs\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xs\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xs\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xs\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xs\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xs\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:text-background-primary {
    color: var(--background-primary);
  }
  .xs\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xs\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xs\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xs\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xs\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xs\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xs\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xs\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xs\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xs\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xs\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xs\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xs\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xs\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xs\:text-body-background {
    color: var(--body-background);
  }
  .xs\:text-background {
    color: var(--background);
  }
  .xs\:text-input-background {
    color: var(--input-background);
  }
  .xs\:text-body-text {
    color: var(--body-text);
  }
  .xs\:text-header-text {
    color: var(--header-text);
  }
  .xs\:text-primary {
    color: var(--primary);
  }
  .xs\:text-secondary {
    color: var(--secondary);
  }
  .xs\:text-tertiary {
    color: var(--tertiary);
  }
  .xs\:text-primary-hover {
    color: var(--primary-hover);
  }
  .xs\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .xs\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .xs\:text-input-text {
    color: var(--input-text);
  }
  .xs\:text-success {
    color: var(--success);
  }
  .xs\:text-error {
    color: var(--error);
  }
  .xs\:text-custom-loader {
    color: var(--custom-loader);
  }
  .xs\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xs\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xs\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .xs\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .xs\:text-background50 {
    color: var(--background50);
  }
  .xs\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xs\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xs\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xs\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xs\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xs\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xs\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xs\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xs\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xs\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xs\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .xs\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .xs\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xs\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xs\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .xs\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .xs\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .xs\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .xs\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .xs\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .xs\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .xs\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .xs\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .xs\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .xs\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .xs\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .xs\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .xs\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .xs\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .xs\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .xs\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .xs\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .xs\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .xs\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .xs\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .xs\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .xs\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .xs\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .xs\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .xs\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .xs\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .xs\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .xs\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .xs\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .xs\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .xs\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .xs\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .xs\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .xs\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .xs\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .xs\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .xs\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .xs\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .xs\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .xs\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .xs\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xs\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xs\:hover\:text-transparent:hover {
    color: transparent;
  }
  .xs\:hover\:text-current:hover {
    color: currentColor;
  }
  .xs\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xs\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xs\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xs\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xs\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xs\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xs\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xs\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xs\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .xs\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xs\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xs\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xs\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xs\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xs\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xs\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xs\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xs\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xs\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xs\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xs\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .xs\:hover\:text-background:hover {
    color: var(--background);
  }
  .xs\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .xs\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .xs\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .xs\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .xs\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .xs\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .xs\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .xs\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .xs\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .xs\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .xs\:hover\:text-success:hover {
    color: var(--success);
  }
  .xs\:hover\:text-error:hover {
    color: var(--error);
  }
  .xs\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .xs\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xs\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xs\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .xs\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .xs\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .xs\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xs\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xs\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xs\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xs\:focus\:text-transparent:focus {
    color: transparent;
  }
  .xs\:focus\:text-current:focus {
    color: currentColor;
  }
  .xs\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xs\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xs\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xs\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xs\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xs\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xs\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xs\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xs\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xs\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .xs\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .xs\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xs\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xs\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xs\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xs\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xs\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xs\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xs\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xs\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xs\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xs\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xs\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .xs\:focus\:text-background:focus {
    color: var(--background);
  }
  .xs\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .xs\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .xs\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .xs\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .xs\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .xs\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .xs\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .xs\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .xs\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .xs\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .xs\:focus\:text-success:focus {
    color: var(--success);
  }
  .xs\:focus\:text-error:focus {
    color: var(--error);
  }
  .xs\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .xs\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xs\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xs\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .xs\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .xs\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .xs\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xs\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xs\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xs\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xs\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xs\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xs\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .xs\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .xs\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .xs\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .xs\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .xs\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .xs\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .xs\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .xs\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .xs\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .xs\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .xs\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .xs\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .xs\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .xs\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .xs\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .xs\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .xs\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .xs\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .xs\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .xs\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .xs\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .xs\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .xs\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .xs\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .xs\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .xs\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .xs\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .xs\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .xs\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .xs\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .xs\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .xs\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .xs\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .xs\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .xs\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .xs\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .xs\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .xs\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .xs\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .xs\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .xs\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .xs\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .xs\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .xs\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .xs\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .xs\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .xs\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .xs\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .xs\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .xs\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .xs\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .xs\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .xs\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .xs\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .xs\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .xs\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .xs\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .xs\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .xs\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .xs\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .xs\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .xs\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .xs\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .xs\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .xs\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .xs\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .xs\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .xs\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .xs\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .xs\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .xs\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .xs\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .xs\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .xs\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .xs\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .xs\:overflow-ellipsis,
  .xs\:truncate {
    text-overflow: ellipsis;
  }
  .xs\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .xs\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .xs\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .xs\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .xs\:italic {
    font-style: italic;
  }
  .xs\:not-italic {
    font-style: normal;
  }
  .xs\:uppercase {
    text-transform: uppercase;
  }
  .xs\:lowercase {
    text-transform: lowercase;
  }
  .xs\:capitalize {
    text-transform: capitalize;
  }
  .xs\:normal-case {
    text-transform: none;
  }
  .xs\:underline {
    text-decoration: underline;
  }
  .xs\:line-through {
    text-decoration: line-through;
  }
  .xs\:no-underline {
    text-decoration: none;
  }
  .group:hover .xs\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .xs\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .xs\:group-hover\:no-underline {
    text-decoration: none;
  }
  .xs\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .xs\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .xs\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .xs\:hover\:underline:hover {
    text-decoration: underline;
  }
  .xs\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .xs\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .xs\:focus\:underline:focus {
    text-decoration: underline;
  }
  .xs\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .xs\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .xs\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .xs\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .xs\:diagonal-fractions,
  .xs\:lining-nums,
  .xs\:oldstyle-nums,
  .xs\:ordinal,
  .xs\:proportional-nums,
  .xs\:slashed-zero,
  .xs\:stacked-fractions,
  .xs\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .xs\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .xs\:ordinal {
    --tw-ordinal: ordinal;
  }
  .xs\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .xs\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .xs\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .xs\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .xs\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .xs\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .xs\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .xs\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .xs\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .xs\:tracking-normal {
    letter-spacing: 0;
  }
  .xs\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .xs\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .xs\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .xs\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .xs\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .xs\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .xs\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .xs\:align-baseline {
    vertical-align: baseline;
  }
  .xs\:align-top {
    vertical-align: top;
  }
  .xs\:align-middle {
    vertical-align: middle;
  }
  .xs\:align-bottom {
    vertical-align: bottom;
  }
  .xs\:align-text-top {
    vertical-align: text-top;
  }
  .xs\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .xs\:visible {
    visibility: visible;
  }
  .xs\:invisible {
    visibility: hidden;
  }
  .xs\:whitespace-normal {
    white-space: normal;
  }
  .xs\:whitespace-nowrap {
    white-space: nowrap;
  }
  .xs\:whitespace-pre {
    white-space: pre;
  }
  .xs\:whitespace-pre-line {
    white-space: pre-line;
  }
  .xs\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .xs\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .xs\:break-words {
    overflow-wrap: break-word;
  }
  .xs\:break-all {
    word-break: break-all;
  }
  .group:hover .xs\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .xs\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .xs\:group-hover\:break-all {
    word-break: break-all;
  }
  .xs\:w-0 {
    width: 0;
  }
  .xs\:w-1 {
    width: 0.25rem;
  }
  .xs\:w-2 {
    width: 0.5rem;
  }
  .xs\:w-3 {
    width: 0.75rem;
  }
  .xs\:w-4 {
    width: 1rem;
  }
  .xs\:w-5 {
    width: 1.25rem;
  }
  .xs\:w-6 {
    width: 1.5rem;
  }
  .xs\:w-7 {
    width: 1.75rem;
  }
  .xs\:w-8 {
    width: 2rem;
  }
  .xs\:w-9 {
    width: 2.25rem;
  }
  .xs\:w-10 {
    width: 2.5rem;
  }
  .xs\:w-11 {
    width: 2.75rem;
  }
  .xs\:w-12 {
    width: 3rem;
  }
  .xs\:w-14 {
    width: 3.5rem;
  }
  .xs\:w-16 {
    width: 4rem;
  }
  .xs\:w-20 {
    width: 5rem;
  }
  .xs\:w-24 {
    width: 6rem;
  }
  .xs\:w-28 {
    width: 7rem;
  }
  .xs\:w-32 {
    width: 8rem;
  }
  .xs\:w-36 {
    width: 9rem;
  }
  .xs\:w-40 {
    width: 10rem;
  }
  .xs\:w-44 {
    width: 11rem;
  }
  .xs\:w-48 {
    width: 12rem;
  }
  .xs\:w-52 {
    width: 13rem;
  }
  .xs\:w-56 {
    width: 14rem;
  }
  .xs\:w-60 {
    width: 15rem;
  }
  .xs\:w-64 {
    width: 16rem;
  }
  .xs\:w-72 {
    width: 18rem;
  }
  .xs\:w-80 {
    width: 20rem;
  }
  .xs\:w-96 {
    width: 24rem;
  }
  .xs\:w-150 {
    width: 150px;
  }
  .xs\:w-199 {
    width: 199px;
  }
  .xs\:w-286 {
    width: 286px;
  }
  .xs\:w-512 {
    width: 512px;
  }
  .xs\:w-560 {
    width: 560px;
  }
  .xs\:w-650 {
    width: 650px;
  }
  .xs\:w-auto {
    width: auto;
  }
  .xs\:w-px {
    width: 1px;
  }
  .xs\:w-0\.5 {
    width: 0.125rem;
  }
  .xs\:w-1\.5 {
    width: 0.375rem;
  }
  .xs\:w-2\.5 {
    width: 0.625rem;
  }
  .xs\:w-3\.5 {
    width: 0.875rem;
  }
  .xs\:w-1\/2 {
    width: 50%;
  }
  .xs\:w-1\/3 {
    width: 33.333333%;
  }
  .xs\:w-2\/3 {
    width: 66.666667%;
  }
  .xs\:w-1\/4 {
    width: 25%;
  }
  .xs\:w-2\/4 {
    width: 50%;
  }
  .xs\:w-3\/4 {
    width: 75%;
  }
  .xs\:w-1\/5 {
    width: 20%;
  }
  .xs\:w-2\/5 {
    width: 40%;
  }
  .xs\:w-3\/5 {
    width: 60%;
  }
  .xs\:w-4\/5 {
    width: 80%;
  }
  .xs\:w-1\/6 {
    width: 16.666667%;
  }
  .xs\:w-2\/6 {
    width: 33.333333%;
  }
  .xs\:w-3\/6 {
    width: 50%;
  }
  .xs\:w-4\/6 {
    width: 66.666667%;
  }
  .xs\:w-5\/6 {
    width: 83.333333%;
  }
  .xs\:w-1\/12 {
    width: 8.333333%;
  }
  .xs\:w-2\/12 {
    width: 16.666667%;
  }
  .xs\:w-3\/12 {
    width: 25%;
  }
  .xs\:w-4\/12 {
    width: 33.333333%;
  }
  .xs\:w-5\/12 {
    width: 41.666667%;
  }
  .xs\:w-6\/12 {
    width: 50%;
  }
  .xs\:w-7\/12 {
    width: 58.333333%;
  }
  .xs\:w-8\/12 {
    width: 66.666667%;
  }
  .xs\:w-9\/12 {
    width: 75%;
  }
  .xs\:w-10\/12 {
    width: 83.333333%;
  }
  .xs\:w-11\/12 {
    width: 91.666667%;
  }
  .xs\:w-full {
    width: 100%;
  }
  .xs\:w-screen {
    width: 100vw;
  }
  .xs\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .xs\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .xs\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .xs\:z-0 {
    z-index: 0;
  }
  .xs\:z-10 {
    z-index: 10;
  }
  .xs\:z-20 {
    z-index: 20;
  }
  .xs\:z-30 {
    z-index: 30;
  }
  .xs\:z-40 {
    z-index: 40;
  }
  .xs\:z-50 {
    z-index: 50;
  }
  .xs\:z-auto {
    z-index: auto;
  }
  .xs\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .xs\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .xs\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .xs\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .xs\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .xs\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .xs\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .xs\:focus\:z-0:focus {
    z-index: 0;
  }
  .xs\:focus\:z-10:focus {
    z-index: 10;
  }
  .xs\:focus\:z-20:focus {
    z-index: 20;
  }
  .xs\:focus\:z-30:focus {
    z-index: 30;
  }
  .xs\:focus\:z-40:focus {
    z-index: 40;
  }
  .xs\:focus\:z-50:focus {
    z-index: 50;
  }
  .xs\:focus\:z-auto:focus {
    z-index: auto;
  }
  .xs\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .xs\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .xs\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .xs\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .xs\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .xs\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .xs\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .xs\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .xs\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .xs\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .xs\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .xs\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .xs\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .xs\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .xs\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .xs\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .xs\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .xs\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .xs\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .xs\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .xs\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .xs\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .xs\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .xs\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .xs\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .xs\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .xs\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .xs\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .xs\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .xs\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .xs\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .xs\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .xs\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .xs\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .xs\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .xs\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .xs\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .xs\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .xs\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .xs\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .xs\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .xs\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .xs\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .xs\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .xs\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .xs\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .xs\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .xs\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .xs\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .xs\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .xs\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .xs\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .xs\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .xs\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .xs\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .xs\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .xs\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .xs\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .xs\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .xs\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .xs\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .xs\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .xs\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .xs\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .xs\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .xs\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .xs\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .xs\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .xs\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .xs\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .xs\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .xs\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .xs\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .xs\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .xs\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .xs\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .xs\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .xs\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .xs\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .xs\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .xs\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .xs\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .xs\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .xs\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .xs\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .xs\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .xs\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .xs\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .xs\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .xs\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .xs\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .xs\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .xs\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .xs\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .xs\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .xs\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .xs\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .xs\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .xs\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .xs\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .xs\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .xs\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .xs\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .xs\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .xs\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .xs\:grid-flow-row {
    grid-auto-flow: row;
  }
  .xs\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xs\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .xs\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xs\:grid-cols-none {
    grid-template-columns: none;
  }
  .xs\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .xs\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .xs\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .xs\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .xs\:col-auto {
    grid-column: auto;
  }
  .xs\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xs\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xs\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xs\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xs\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xs\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .xs\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xs\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .xs\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .xs\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .xs\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .xs\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .xs\:col-span-full {
    grid-column: 1/-1;
  }
  .xs\:col-start-1 {
    grid-column-start: 1;
  }
  .xs\:col-start-2 {
    grid-column-start: 2;
  }
  .xs\:col-start-3 {
    grid-column-start: 3;
  }
  .xs\:col-start-4 {
    grid-column-start: 4;
  }
  .xs\:col-start-5 {
    grid-column-start: 5;
  }
  .xs\:col-start-6 {
    grid-column-start: 6;
  }
  .xs\:col-start-7 {
    grid-column-start: 7;
  }
  .xs\:col-start-8 {
    grid-column-start: 8;
  }
  .xs\:col-start-9 {
    grid-column-start: 9;
  }
  .xs\:col-start-10 {
    grid-column-start: 10;
  }
  .xs\:col-start-11 {
    grid-column-start: 11;
  }
  .xs\:col-start-12 {
    grid-column-start: 12;
  }
  .xs\:col-start-13 {
    grid-column-start: 13;
  }
  .xs\:col-start-auto {
    grid-column-start: auto;
  }
  .xs\:col-end-1 {
    grid-column-end: 1;
  }
  .xs\:col-end-2 {
    grid-column-end: 2;
  }
  .xs\:col-end-3 {
    grid-column-end: 3;
  }
  .xs\:col-end-4 {
    grid-column-end: 4;
  }
  .xs\:col-end-5 {
    grid-column-end: 5;
  }
  .xs\:col-end-6 {
    grid-column-end: 6;
  }
  .xs\:col-end-7 {
    grid-column-end: 7;
  }
  .xs\:col-end-8 {
    grid-column-end: 8;
  }
  .xs\:col-end-9 {
    grid-column-end: 9;
  }
  .xs\:col-end-10 {
    grid-column-end: 10;
  }
  .xs\:col-end-11 {
    grid-column-end: 11;
  }
  .xs\:col-end-12 {
    grid-column-end: 12;
  }
  .xs\:col-end-13 {
    grid-column-end: 13;
  }
  .xs\:col-end-auto {
    grid-column-end: auto;
  }
  .xs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .xs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xs\:grid-rows-none {
    grid-template-rows: none;
  }
  .xs\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .xs\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .xs\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .xs\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .xs\:row-auto {
    grid-row: auto;
  }
  .xs\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xs\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xs\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xs\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .xs\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .xs\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .xs\:row-span-full {
    grid-row: 1/-1;
  }
  .xs\:row-start-1 {
    grid-row-start: 1;
  }
  .xs\:row-start-2 {
    grid-row-start: 2;
  }
  .xs\:row-start-3 {
    grid-row-start: 3;
  }
  .xs\:row-start-4 {
    grid-row-start: 4;
  }
  .xs\:row-start-5 {
    grid-row-start: 5;
  }
  .xs\:row-start-6 {
    grid-row-start: 6;
  }
  .xs\:row-start-7 {
    grid-row-start: 7;
  }
  .xs\:row-start-auto {
    grid-row-start: auto;
  }
  .xs\:row-end-1 {
    grid-row-end: 1;
  }
  .xs\:row-end-2 {
    grid-row-end: 2;
  }
  .xs\:row-end-3 {
    grid-row-end: 3;
  }
  .xs\:row-end-4 {
    grid-row-end: 4;
  }
  .xs\:row-end-5 {
    grid-row-end: 5;
  }
  .xs\:row-end-6 {
    grid-row-end: 6;
  }
  .xs\:row-end-7 {
    grid-row-end: 7;
  }
  .xs\:row-end-auto {
    grid-row-end: auto;
  }
  .xs\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xs\:transform,
  .xs\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xs\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xs\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .xs\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .xs\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .xs\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .xs\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .xs\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .xs\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .xs\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .xs\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .xs\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .xs\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xs\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xs\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xs\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xs\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xs\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xs\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xs\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xs\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xs\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xs\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .xs\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .xs\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .xs\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .xs\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .xs\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .xs\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .xs\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .xs\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .xs\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .xs\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .xs\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .xs\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .xs\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .xs\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .xs\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .xs\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .xs\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .xs\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .xs\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .xs\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xs\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xs\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xs\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xs\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xs\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xs\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xs\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xs\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xs\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xs\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .xs\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .xs\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .xs\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .xs\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .xs\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .xs\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .xs\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .xs\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .xs\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .xs\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .xs\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .xs\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .xs\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .xs\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .xs\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .xs\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .xs\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .xs\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .xs\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .xs\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xs\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xs\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xs\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xs\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xs\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xs\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xs\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xs\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xs\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xs\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .xs\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .xs\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .xs\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .xs\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .xs\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .xs\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .xs\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .xs\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .xs\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .xs\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .xs\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .xs\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .xs\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .xs\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .xs\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .xs\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .xs\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .xs\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .xs\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .xs\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .xs\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .xs\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .xs\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .xs\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .xs\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .xs\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .xs\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .xs\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .xs\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .xs\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .xs\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .xs\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .xs\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .xs\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .xs\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .xs\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .xs\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .xs\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .xs\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .xs\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .xs\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .xs\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .xs\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .xs\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .xs\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .xs\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .xs\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .xs\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .xs\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .xs\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .xs\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .xs\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .xs\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .xs\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .xs\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .xs\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .xs\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .xs\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .xs\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .xs\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .xs\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .xs\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .xs\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .xs\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .xs\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .xs\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .xs\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .xs\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .xs\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .xs\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .xs\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xs\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .xs\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .xs\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .xs\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .xs\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .xs\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .xs\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .xs\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .xs\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .xs\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .xs\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .xs\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .xs\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .xs\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .xs\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .xs\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .xs\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .xs\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .xs\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .xs\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .xs\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .xs\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .xs\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .xs\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .xs\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .xs\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .xs\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .xs\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .xs\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .xs\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .xs\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .xs\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .xs\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .xs\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .xs\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xs\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .xs\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .xs\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .xs\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .xs\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .xs\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .xs\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .xs\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .xs\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .xs\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .xs\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .xs\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .xs\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .xs\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .xs\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .xs\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .xs\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .xs\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .xs\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .xs\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .xs\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .xs\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .xs\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .xs\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .xs\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .xs\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .xs\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .xs\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .xs\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .xs\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .xs\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .xs\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .xs\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .xs\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .xs\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .xs\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .xs\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .xs\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .xs\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .xs\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .xs\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .xs\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .xs\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .xs\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .xs\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .xs\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .xs\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .xs\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .xs\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xs\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .xs\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .xs\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .xs\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .xs\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .xs\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .xs\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .xs\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .xs\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .xs\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .xs\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .xs\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .xs\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .xs\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .xs\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .xs\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .xs\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .xs\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .xs\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .xs\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .xs\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .xs\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .xs\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .xs\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .xs\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .xs\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .xs\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .xs\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .xs\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .xs\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .xs\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .xs\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .xs\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .xs\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .xs\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xs\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .xs\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .xs\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .xs\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .xs\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .xs\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .xs\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .xs\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .xs\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .xs\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .xs\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .xs\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .xs\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .xs\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .xs\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .xs\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .xs\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .xs\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .xs\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .xs\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .xs\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .xs\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .xs\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .xs\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .xs\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .xs\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .xs\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .xs\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .xs\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .xs\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .xs\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .xs\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .xs\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .xs\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .xs\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .xs\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .xs\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .xs\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .xs\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .xs\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .xs\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .xs\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .xs\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .xs\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .xs\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .xs\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .xs\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .xs\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .xs\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xs\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .xs\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .xs\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .xs\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .xs\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .xs\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .xs\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .xs\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .xs\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .xs\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .xs\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .xs\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .xs\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .xs\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .xs\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .xs\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .xs\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .xs\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .xs\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .xs\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .xs\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .xs\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .xs\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .xs\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .xs\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .xs\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .xs\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .xs\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .xs\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .xs\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .xs\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .xs\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .xs\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .xs\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .xs\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xs\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .xs\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .xs\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .xs\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .xs\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .xs\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .xs\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .xs\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .xs\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .xs\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .xs\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .xs\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .xs\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .xs\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .xs\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .xs\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .xs\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .xs\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .xs\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .xs\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .xs\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .xs\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .xs\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .xs\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .xs\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .xs\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .xs\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .xs\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .xs\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .xs\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .xs\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .xs\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .xs\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .xs\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .xs\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .xs\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .xs\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .xs\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .xs\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .xs\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .xs\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .xs\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .xs\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .xs\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .xs\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .xs\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .xs\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .xs\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .xs\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xs\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .xs\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .xs\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .xs\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .xs\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .xs\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .xs\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .xs\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .xs\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .xs\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .xs\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .xs\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .xs\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .xs\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .xs\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .xs\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .xs\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .xs\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .xs\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .xs\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .xs\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .xs\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .xs\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .xs\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .xs\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .xs\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .xs\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .xs\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .xs\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .xs\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .xs\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .xs\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .xs\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .xs\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .xs\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xs\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .xs\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .xs\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .xs\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .xs\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .xs\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .xs\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .xs\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .xs\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .xs\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .xs\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .xs\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .xs\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .xs\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .xs\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .xs\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .xs\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .xs\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .xs\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .xs\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .xs\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .xs\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .xs\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .xs\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .xs\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .xs\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .xs\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .xs\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .xs\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .xs\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .xs\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .xs\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .xs\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .xs\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .xs\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .xs\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .xs\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .xs\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .xs\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .xs\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .xs\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .xs\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .xs\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .xs\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .xs\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .xs\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .xs\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .xs\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .xs\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xs\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .xs\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .xs\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .xs\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .xs\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .xs\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .xs\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .xs\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .xs\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .xs\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .xs\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .xs\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .xs\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .xs\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .xs\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .xs\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .xs\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .xs\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .xs\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .xs\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .xs\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .xs\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .xs\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .xs\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .xs\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .xs\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .xs\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .xs\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .xs\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .xs\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .xs\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .xs\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .xs\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .xs\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .xs\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xs\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .xs\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .xs\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .xs\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .xs\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .xs\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .xs\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .xs\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .xs\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .xs\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .xs\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .xs\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .xs\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .xs\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .xs\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .xs\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .xs\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .xs\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .xs\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .xs\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .xs\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .xs\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .xs\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .xs\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .xs\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .xs\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .xs\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .xs\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .xs\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .xs\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .xs\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .xs\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .xs\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .xs\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .xs\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .xs\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .xs\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .xs\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .xs\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .xs\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .xs\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .xs\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .xs\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .xs\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .xs\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .xs\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .xs\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .xs\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .xs\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xs\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .xs\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .xs\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .xs\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .xs\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .xs\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .xs\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .xs\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .xs\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .xs\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .xs\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .xs\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .xs\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .xs\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .xs\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .xs\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .xs\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .xs\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .xs\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .xs\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .xs\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .xs\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .xs\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .xs\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .xs\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .xs\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .xs\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .xs\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .xs\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .xs\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .xs\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .xs\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .xs\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .xs\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .xs\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xs\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .xs\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .xs\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .xs\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .xs\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .xs\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .xs\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .xs\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .xs\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .xs\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .xs\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .xs\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .xs\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .xs\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .xs\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .xs\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .xs\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .xs\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .xs\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .xs\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .xs\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .xs\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .xs\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .xs\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .xs\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .xs\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .xs\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .xs\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .xs\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .xs\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .xs\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .xs\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .xs\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .xs\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .xs\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .xs\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .xs\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .xs\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .xs\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .xs\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .xs\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .xs\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .xs\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .xs\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .xs\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .xs\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .xs\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .xs\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .xs\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .xs\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .xs\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .xs\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .xs\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .xs\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .xs\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .xs\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .xs\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .xs\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .xs\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .xs\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .xs\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .xs\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .xs\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .xs\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .xs\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .xs\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .xs\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .xs\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .xs\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .xs\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .xs\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .xs\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .xs\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .xs\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .xs\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .xs\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .xs\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .xs\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .xs\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .xs\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .xs\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .xs\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .xs\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .xs\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .xs\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .xs\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .xs\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .xs\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .xs\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .xs\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .xs\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .xs\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .xs\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .xs\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .xs\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .xs\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .xs\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .xs\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .xs\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .xs\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .xs\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .xs\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .xs\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .xs\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .xs\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .xs\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .xs\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .xs\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .xs\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .xs\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .xs\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .xs\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .xs\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .xs\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .xs\:transition-none {
    transition-property: none;
  }
  .xs\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xs\:ease-linear {
    transition-timing-function: linear;
  }
  .xs\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .xs\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .xs\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .xs\:duration-75 {
    transition-duration: 75ms;
  }
  .xs\:duration-100 {
    transition-duration: 0.1s;
  }
  .xs\:duration-150 {
    transition-duration: 0.15s;
  }
  .xs\:duration-200 {
    transition-duration: 0.2s;
  }
  .xs\:duration-300 {
    transition-duration: 0.3s;
  }
  .xs\:duration-500 {
    transition-duration: 0.5s;
  }
  .xs\:duration-700 {
    transition-duration: 0.7s;
  }
  .xs\:duration-1000 {
    transition-duration: 1s;
  }
  .xs\:delay-75 {
    transition-delay: 75ms;
  }
  .xs\:delay-100 {
    transition-delay: 0.1s;
  }
  .xs\:delay-150 {
    transition-delay: 0.15s;
  }
  .xs\:delay-200 {
    transition-delay: 0.2s;
  }
  .xs\:delay-300 {
    transition-delay: 0.3s;
  }
  .xs\:delay-500 {
    transition-delay: 0.5s;
  }
  .xs\:delay-700 {
    transition-delay: 0.7s;
  }
  .xs\:delay-1000 {
    transition-delay: 1s;
  }
  .xs\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .xs\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .xs\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .xs\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .xs\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
@media (min-width: 1440px) {
  .xxl\:container {
    width: 100%;
  }
  @media (min-width: 260px) {
    .xxl\:container {
      max-width: 260px;
    }
  }
  @media (min-width: 360px) {
    .xxl\:container {
      max-width: 360px;
    }
  }
  @media (min-width: 640px) {
    .xxl\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .xxl\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .xxl\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .xxl\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1440px) {
    .xxl\:container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1536px) {
    .xxl\:container {
      max-width: 1536px;
    }
  }
  .xxl\:prose {
    color: #374151;
    max-width: 65ch;
  }
  .xxl\:prose [class~='lead'] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxl\:prose a {
    color: #6d728b;
    text-decoration: underline;
    font-weight: 500;
  }
  .xxl\:prose strong {
    color: #6d728b;
    font-weight: 600;
  }
  .xxl\:prose ol {
    counter-reset: list-counter;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxl\:prose ol > li {
    position: relative;
    counter-increment: list-counter;
    padding-left: 1.75em;
  }
  .xxl\:prose ol > li:before {
    content: counter(list-counter) '.';
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
  }
  .xxl\:prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }
  .xxl\:prose ul > li:before {
    content: '';
    position: absolute;
    background-color: rgba(98, 107, 255, 0.25098039215686274);
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.6875em;
    left: 0.25em;
  }
  .xxl\:prose hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xxl\:prose blockquote {
    font-weight: 500;
    font-style: italic;
    color: #6d728b;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: '\201C''\201D''\2018''\2019';
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }
  .xxl\:prose blockquote p:first-of-type:before {
    content: open-quote;
  }
  .xxl\:prose blockquote p:last-of-type:after {
    content: close-quote;
  }
  .xxl\:prose h1 {
    color: #6d728b;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xxl\:prose h2 {
    color: #6d728b;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }
  .xxl\:prose h3 {
    color: #6d728b;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xxl\:prose h4 {
    color: #6d728b;
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }
  .xxl\:prose figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }
  .xxl\:prose code {
    color: #6d728b;
    font-weight: 600;
    font-size: 0.875em;
  }
  .xxl\:prose code:after,
  .xxl\:prose code:before {
    content: '`';
  }
  .xxl\:prose a code {
    color: #6d728b;
  }
  .xxl\:prose pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }
  .xxl\:prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .xxl\:prose pre code:after,
  .xxl\:prose pre code:before {
    content: '';
  }
  .xxl\:prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }
  .xxl\:prose thead {
    color: #6d728b;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }
  .xxl\:prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
  }
  .xxl\:prose tbody tr:last-child {
    border-bottom-width: 0;
  }
  .xxl\:prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }
  .xxl\:prose {
    font-size: 1rem;
    line-height: 1.75;
  }
  .xxl\:prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxl\:prose figure,
  .xxl\:prose img,
  .xxl\:prose video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxl\:prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:prose h2 code {
    font-size: 0.875em;
  }
  .xxl\:prose h3 code {
    font-size: 0.9em;
  }
  .xxl\:prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  .xxl\:prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xxl\:prose > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xxl\:prose > ul > li > :first-child {
    margin-top: 1.25em;
  }
  .xxl\:prose > ul > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xxl\:prose > ol > li > :first-child {
    margin-top: 1.25em;
  }
  .xxl\:prose > ol > li > :last-child {
    margin-bottom: 1.25em;
  }
  .xxl\:prose ol ol,
  .xxl\:prose ol ul,
  .xxl\:prose ul ol,
  .xxl\:prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .xxl\:prose h2 + *,
  .xxl\:prose h3 + *,
  .xxl\:prose h4 + *,
  .xxl\:prose hr + * {
    margin-top: 0;
  }
  .xxl\:prose thead th:first-child {
    padding-left: 0;
  }
  .xxl\:prose thead th:last-child {
    padding-right: 0;
  }
  .xxl\:prose tbody td:first-child {
    padding-left: 0;
  }
  .xxl\:prose tbody td:last-child {
    padding-right: 0;
  }
  .xxl\:prose > :first-child {
    margin-top: 0;
  }
  .xxl\:prose > :last-child {
    margin-bottom: 0;
  }
  .xxl\:prose-sm {
    font-size: 0.875rem;
    line-height: 1.7142857;
  }
  .xxl\:prose-sm p {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xxl\:prose-sm [class~='lead'] {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxl\:prose-sm blockquote {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
  }
  .xxl\:prose-sm h1 {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2;
  }
  .xxl\:prose-sm h2 {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: 0.8em;
    line-height: 1.4;
  }
  .xxl\:prose-sm h3 {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xxl\:prose-sm h4 {
    margin-top: 1.4285714em;
    margin-bottom: 0.5714286em;
    line-height: 1.4285714;
  }
  .xxl\:prose-sm figure,
  .xxl\:prose-sm img,
  .xxl\:prose-sm video {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
  }
  .xxl\:prose-sm figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:prose-sm figure figcaption {
    font-size: 0.8571429em;
    line-height: 1.3333333;
    margin-top: 0.6666667em;
  }
  .xxl\:prose-sm code {
    font-size: 0.8571429em;
  }
  .xxl\:prose-sm h2 code {
    font-size: 0.9em;
  }
  .xxl\:prose-sm h3 code {
    font-size: 0.8888889em;
  }
  .xxl\:prose-sm pre {
    font-size: 0.8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }
  .xxl\:prose-sm ol,
  .xxl\:prose-sm ul {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
  }
  .xxl\:prose-sm li {
    margin-top: 0.2857143em;
    margin-bottom: 0.2857143em;
  }
  .xxl\:prose-sm ol > li {
    padding-left: 1.5714286em;
  }
  .xxl\:prose-sm ol > li:before {
    left: 0;
  }
  .xxl\:prose-sm ul > li {
    padding-left: 1.5714286em;
  }
  .xxl\:prose-sm ul > li:before {
    height: 0.3571429em;
    width: 0.3571429em;
    top: 0.67857em;
    left: 0.2142857em;
  }
  .xxl\:prose-sm > ul > li p {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xxl\:prose-sm > ul > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xxl\:prose-sm > ul > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xxl\:prose-sm > ol > li > :first-child {
    margin-top: 1.1428571em;
  }
  .xxl\:prose-sm > ol > li > :last-child {
    margin-bottom: 1.1428571em;
  }
  .xxl\:prose-sm ol ol,
  .xxl\:prose-sm ol ul,
  .xxl\:prose-sm ul ol,
  .xxl\:prose-sm ul ul {
    margin-top: 0.5714286em;
    margin-bottom: 0.5714286em;
  }
  .xxl\:prose-sm hr {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em;
  }
  .xxl\:prose-sm h2 + *,
  .xxl\:prose-sm h3 + *,
  .xxl\:prose-sm h4 + *,
  .xxl\:prose-sm hr + * {
    margin-top: 0;
  }
  .xxl\:prose-sm table {
    font-size: 0.8571429em;
    line-height: 1.5;
  }
  .xxl\:prose-sm thead th {
    padding-right: 1em;
    padding-bottom: 0.6666667em;
    padding-left: 1em;
  }
  .xxl\:prose-sm thead th:first-child {
    padding-left: 0;
  }
  .xxl\:prose-sm thead th:last-child {
    padding-right: 0;
  }
  .xxl\:prose-sm tbody td {
    padding: 0.6666667em 1em;
  }
  .xxl\:prose-sm tbody td:first-child {
    padding-left: 0;
  }
  .xxl\:prose-sm tbody td:last-child {
    padding-right: 0;
  }
  .xxl\:prose-sm > :first-child {
    margin-top: 0;
  }
  .xxl\:prose-sm > :last-child {
    margin-bottom: 0;
  }
  .xxl\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .xxl\:prose-lg p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-lg [class~='lead'] {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }
  .xxl\:prose-lg blockquote {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-left: 1em;
  }
  .xxl\:prose-lg h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }
  .xxl\:prose-lg h2 {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }
  .xxl\:prose-lg h3 {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xxl\:prose-lg h4 {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }
  .xxl\:prose-lg figure,
  .xxl\:prose-lg img,
  .xxl\:prose-lg video {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }
  .xxl\:prose-lg figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:prose-lg figure figcaption {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }
  .xxl\:prose-lg code {
    font-size: 0.8888889em;
  }
  .xxl\:prose-lg h2 code {
    font-size: 0.8666667em;
  }
  .xxl\:prose-lg h3 code {
    font-size: 0.875em;
  }
  .xxl\:prose-lg pre {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }
  .xxl\:prose-lg ol,
  .xxl\:prose-lg ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-lg li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xxl\:prose-lg ol > li {
    padding-left: 1.6666667em;
  }
  .xxl\:prose-lg ol > li:before {
    left: 0;
  }
  .xxl\:prose-lg ul > li {
    padding-left: 1.6666667em;
  }
  .xxl\:prose-lg ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.72222em;
    left: 0.2222222em;
  }
  .xxl\:prose-lg > ul > li p {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxl\:prose-lg > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxl\:prose-lg > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-lg > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxl\:prose-lg > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-lg ol ol,
  .xxl\:prose-lg ol ul,
  .xxl\:prose-lg ul ol,
  .xxl\:prose-lg ul ul {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }
  .xxl\:prose-lg hr {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }
  .xxl\:prose-lg h2 + *,
  .xxl\:prose-lg h3 + *,
  .xxl\:prose-lg h4 + *,
  .xxl\:prose-lg hr + * {
    margin-top: 0;
  }
  .xxl\:prose-lg table {
    font-size: 0.8888889em;
    line-height: 1.5;
  }
  .xxl\:prose-lg thead th {
    padding-right: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
  }
  .xxl\:prose-lg thead th:first-child {
    padding-left: 0;
  }
  .xxl\:prose-lg thead th:last-child {
    padding-right: 0;
  }
  .xxl\:prose-lg tbody td {
    padding: 0.75em;
  }
  .xxl\:prose-lg tbody td:first-child {
    padding-left: 0;
  }
  .xxl\:prose-lg tbody td:last-child {
    padding-right: 0;
  }
  .xxl\:prose-lg > :first-child {
    margin-top: 0;
  }
  .xxl\:prose-lg > :last-child {
    margin-bottom: 0;
  }
  .xxl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .xxl\:prose-xl p {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxl\:prose-xl [class~='lead'] {
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .xxl\:prose-xl blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.0666667em;
  }
  .xxl\:prose-xl h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0.8571429em;
    line-height: 1;
  }
  .xxl\:prose-xl h2 {
    font-size: 1.8em;
    margin-top: 1.5555556em;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }
  .xxl\:prose-xl h3 {
    font-size: 1.5em;
    margin-top: 1.6em;
    margin-bottom: 0.6666667em;
    line-height: 1.3333333;
  }
  .xxl\:prose-xl h4 {
    margin-top: 1.8em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
  .xxl\:prose-xl figure,
  .xxl\:prose-xl img,
  .xxl\:prose-xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxl\:prose-xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:prose-xl figure figcaption {
    font-size: 0.9em;
    line-height: 1.5555556;
    margin-top: 1em;
  }
  .xxl\:prose-xl code {
    font-size: 0.9em;
  }
  .xxl\:prose-xl h2 code {
    font-size: 0.8611111em;
  }
  .xxl\:prose-xl h3 code {
    font-size: 0.9em;
  }
  .xxl\:prose-xl pre {
    font-size: 0.9em;
    line-height: 1.7777778;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.1111111em 1.3333333em;
  }
  .xxl\:prose-xl ol,
  .xxl\:prose-xl ul {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .xxl\:prose-xl li {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .xxl\:prose-xl ol > li {
    padding-left: 1.8em;
  }
  .xxl\:prose-xl ol > li:before {
    left: 0;
  }
  .xxl\:prose-xl ul > li {
    padding-left: 1.8em;
  }
  .xxl\:prose-xl ul > li:before {
    width: 0.35em;
    height: 0.35em;
    top: 0.725em;
    left: 0.25em;
  }
  .xxl\:prose-xl > ul > li p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xxl\:prose-xl > ul > li > :first-child {
    margin-top: 1.2em;
  }
  .xxl\:prose-xl > ul > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xxl\:prose-xl > ol > li > :first-child {
    margin-top: 1.2em;
  }
  .xxl\:prose-xl > ol > li > :last-child {
    margin-bottom: 1.2em;
  }
  .xxl\:prose-xl ol ol,
  .xxl\:prose-xl ol ul,
  .xxl\:prose-xl ul ol,
  .xxl\:prose-xl ul ul {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .xxl\:prose-xl hr {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }
  .xxl\:prose-xl h2 + *,
  .xxl\:prose-xl h3 + *,
  .xxl\:prose-xl h4 + *,
  .xxl\:prose-xl hr + * {
    margin-top: 0;
  }
  .xxl\:prose-xl table {
    font-size: 0.9em;
    line-height: 1.5555556;
  }
  .xxl\:prose-xl thead th {
    padding-right: 0.6666667em;
    padding-bottom: 0.8888889em;
    padding-left: 0.6666667em;
  }
  .xxl\:prose-xl thead th:first-child {
    padding-left: 0;
  }
  .xxl\:prose-xl thead th:last-child {
    padding-right: 0;
  }
  .xxl\:prose-xl tbody td {
    padding: 0.8888889em 0.6666667em;
  }
  .xxl\:prose-xl tbody td:first-child {
    padding-left: 0;
  }
  .xxl\:prose-xl tbody td:last-child {
    padding-right: 0;
  }
  .xxl\:prose-xl > :first-child {
    margin-top: 0;
  }
  .xxl\:prose-xl > :last-child {
    margin-bottom: 0;
  }
  .xxl\:prose-2xl {
    font-size: 1.5rem;
    line-height: 1.6666667;
  }
  .xxl\:prose-2xl p {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-2xl [class~='lead'] {
    font-size: 1.25em;
    line-height: 1.4666667;
    margin-top: 1.0666667em;
    margin-bottom: 1.0666667em;
  }
  .xxl\:prose-2xl blockquote {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
    padding-left: 1.1111111em;
  }
  .xxl\:prose-2xl h1 {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.875em;
    line-height: 1;
  }
  .xxl\:prose-2xl h2 {
    font-size: 2em;
    margin-top: 1.5em;
    margin-bottom: 0.8333333em;
    line-height: 1.0833333;
  }
  .xxl\:prose-2xl h3 {
    font-size: 1.5em;
    margin-top: 1.5555556em;
    margin-bottom: 0.6666667em;
    line-height: 1.2222222;
  }
  .xxl\:prose-2xl h4 {
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }
  .xxl\:prose-2xl figure,
  .xxl\:prose-2xl img,
  .xxl\:prose-2xl video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .xxl\:prose-2xl figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:prose-2xl figure figcaption {
    font-size: 0.8333333em;
    line-height: 1.6;
    margin-top: 1em;
  }
  .xxl\:prose-2xl code {
    font-size: 0.8333333em;
  }
  .xxl\:prose-2xl h2 code {
    font-size: 0.875em;
  }
  .xxl\:prose-2xl h3 code {
    font-size: 0.8888889em;
  }
  .xxl\:prose-2xl pre {
    font-size: 0.8333333em;
    line-height: 1.8;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.5rem;
    padding: 1.2em 1.6em;
  }
  .xxl\:prose-2xl ol,
  .xxl\:prose-2xl ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-2xl li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .xxl\:prose-2xl ol > li {
    padding-left: 1.6666667em;
  }
  .xxl\:prose-2xl ol > li:before {
    left: 0;
  }
  .xxl\:prose-2xl ul > li {
    padding-left: 1.6666667em;
  }
  .xxl\:prose-2xl ul > li:before {
    width: 0.3333333em;
    height: 0.3333333em;
    top: 0.66667em;
    left: 0.25em;
  }
  .xxl\:prose-2xl > ul > li p {
    margin-top: 0.8333333em;
    margin-bottom: 0.8333333em;
  }
  .xxl\:prose-2xl > ul > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxl\:prose-2xl > ul > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-2xl > ol > li > :first-child {
    margin-top: 1.3333333em;
  }
  .xxl\:prose-2xl > ol > li > :last-child {
    margin-bottom: 1.3333333em;
  }
  .xxl\:prose-2xl ol ol,
  .xxl\:prose-2xl ol ul,
  .xxl\:prose-2xl ul ol,
  .xxl\:prose-2xl ul ul {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }
  .xxl\:prose-2xl hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .xxl\:prose-2xl h2 + *,
  .xxl\:prose-2xl h3 + *,
  .xxl\:prose-2xl h4 + *,
  .xxl\:prose-2xl hr + * {
    margin-top: 0;
  }
  .xxl\:prose-2xl table {
    font-size: 0.8333333em;
    line-height: 1.4;
  }
  .xxl\:prose-2xl thead th {
    padding-right: 0.6em;
    padding-bottom: 0.8em;
    padding-left: 0.6em;
  }
  .xxl\:prose-2xl thead th:first-child {
    padding-left: 0;
  }
  .xxl\:prose-2xl thead th:last-child {
    padding-right: 0;
  }
  .xxl\:prose-2xl tbody td {
    padding: 0.8em 0.6em;
  }
  .xxl\:prose-2xl tbody td:first-child {
    padding-left: 0;
  }
  .xxl\:prose-2xl tbody td:last-child {
    padding-right: 0;
  }
  .xxl\:prose-2xl > :first-child {
    margin-top: 0;
  }
  .xxl\:prose-2xl > :last-child {
    margin-bottom: 0;
  }
  .xxl\:aspect-w-1,
  .xxl\:aspect-w-2,
  .xxl\:aspect-w-3,
  .xxl\:aspect-w-4,
  .xxl\:aspect-w-5,
  .xxl\:aspect-w-6,
  .xxl\:aspect-w-7,
  .xxl\:aspect-w-8,
  .xxl\:aspect-w-9,
  .xxl\:aspect-w-10,
  .xxl\:aspect-w-11,
  .xxl\:aspect-w-12,
  .xxl\:aspect-w-13,
  .xxl\:aspect-w-14,
  .xxl\:aspect-w-15,
  .xxl\:aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .xxl\:aspect-w-1 > *,
  .xxl\:aspect-w-2 > *,
  .xxl\:aspect-w-3 > *,
  .xxl\:aspect-w-4 > *,
  .xxl\:aspect-w-5 > *,
  .xxl\:aspect-w-6 > *,
  .xxl\:aspect-w-7 > *,
  .xxl\:aspect-w-8 > *,
  .xxl\:aspect-w-9 > *,
  .xxl\:aspect-w-10 > *,
  .xxl\:aspect-w-11 > *,
  .xxl\:aspect-w-12 > *,
  .xxl\:aspect-w-13 > *,
  .xxl\:aspect-w-14 > *,
  .xxl\:aspect-w-15 > *,
  .xxl\:aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxl\:aspect-none {
    position: static;
    padding-bottom: 0;
  }
  .xxl\:aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xxl\:aspect-w-1 {
    --tw-aspect-w: 1;
  }
  .xxl\:aspect-w-2 {
    --tw-aspect-w: 2;
  }
  .xxl\:aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .xxl\:aspect-w-4 {
    --tw-aspect-w: 4;
  }
  .xxl\:aspect-w-5 {
    --tw-aspect-w: 5;
  }
  .xxl\:aspect-w-6 {
    --tw-aspect-w: 6;
  }
  .xxl\:aspect-w-7 {
    --tw-aspect-w: 7;
  }
  .xxl\:aspect-w-8 {
    --tw-aspect-w: 8;
  }
  .xxl\:aspect-w-9 {
    --tw-aspect-w: 9;
  }
  .xxl\:aspect-w-10 {
    --tw-aspect-w: 10;
  }
  .xxl\:aspect-w-11 {
    --tw-aspect-w: 11;
  }
  .xxl\:aspect-w-12 {
    --tw-aspect-w: 12;
  }
  .xxl\:aspect-w-13 {
    --tw-aspect-w: 13;
  }
  .xxl\:aspect-w-14 {
    --tw-aspect-w: 14;
  }
  .xxl\:aspect-w-15 {
    --tw-aspect-w: 15;
  }
  .xxl\:aspect-w-16 {
    --tw-aspect-w: 16;
  }
  .xxl\:aspect-h-1 {
    --tw-aspect-h: 1;
  }
  .xxl\:aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .xxl\:aspect-h-3 {
    --tw-aspect-h: 3;
  }
  .xxl\:aspect-h-4 {
    --tw-aspect-h: 4;
  }
  .xxl\:aspect-h-5 {
    --tw-aspect-h: 5;
  }
  .xxl\:aspect-h-6 {
    --tw-aspect-h: 6;
  }
  .xxl\:aspect-h-7 {
    --tw-aspect-h: 7;
  }
  .xxl\:aspect-h-8 {
    --tw-aspect-h: 8;
  }
  .xxl\:aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .xxl\:aspect-h-10 {
    --tw-aspect-h: 10;
  }
  .xxl\:aspect-h-11 {
    --tw-aspect-h: 11;
  }
  .xxl\:aspect-h-12 {
    --tw-aspect-h: 12;
  }
  .xxl\:aspect-h-13 {
    --tw-aspect-h: 13;
  }
  .xxl\:aspect-h-14 {
    --tw-aspect-h: 14;
  }
  .xxl\:aspect-h-15 {
    --tw-aspect-h: 15;
  }
  .xxl\:aspect-h-16 {
    --tw-aspect-h: 16;
  }
  .xxl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .xxl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * (1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .xxl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * (1 - var(--tw-space-x-reverse)));
  }
  .xxl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .xxl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .xxl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .xxl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .xxl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .xxl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .xxl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .xxl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  }
  .xxl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .xxl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .xxl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .xxl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .xxl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .xxl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .xxl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .xxl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .xxl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .xxl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .xxl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .xxl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .xxl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .xxl\:divide-vivid-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxl\:divide-vivid-blue-500 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:divide-background-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background-primary);
  }
  .xxl\:divide-main-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-divide-opacity));
  }
  .xxl\:divide-dark-blue > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-divide-opacity));
  }
  .xxl\:divide-blue-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-divide-opacity));
  }
  .xxl\:divide-text-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-divide-opacity));
  }
  .xxl\:divide-pale-grey-two > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-pale-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-divide-opacity));
  }
  .xxl\:divide-btc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-divide-opacity));
  }
  .xxl\:divide-btc-symbol > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-divide-opacity));
  }
  .xxl\:divide-paxg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-divide-opacity));
  }
  .xxl\:divide-usdc > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-divide-opacity));
  }
  .xxl\:divide-black-navy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-divide-opacity));
  }
  .xxl\:divide-ghost-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-spring-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-divide-opacity));
  }
  .xxl\:divide-light-slate-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-divide-opacity));
  }
  .xxl\:divide-body-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-background);
  }
  .xxl\:divide-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background);
  }
  .xxl\:divide-input-background > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-background);
  }
  .xxl\:divide-body-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--body-text);
  }
  .xxl\:divide-header-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--header-text);
  }
  .xxl\:divide-primary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary);
  }
  .xxl\:divide-secondary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary);
  }
  .xxl\:divide-tertiary > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary);
  }
  .xxl\:divide-primary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--primary-hover);
  }
  .xxl\:divide-secondary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--secondary-hover);
  }
  .xxl\:divide-tertiary-hover > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--tertiary-hover);
  }
  .xxl\:divide-input-text > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--input-text);
  }
  .xxl\:divide-success > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--success);
  }
  .xxl\:divide-error > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--error);
  }
  .xxl\:divide-custom-loader > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--custom-loader);
  }
  .xxl\:divide-violet > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-divide-opacity));
  }
  .xxl\:divide-violet-hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-divide-opacity));
  }
  .xxl\:divide-skeleton-loader-from > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-from);
  }
  .xxl\:divide-skeleton-loader-to > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--skeleton-loader-to);
  }
  .xxl\:divide-background50 > :not([hidden]) ~ :not([hidden]) {
    border-color: var(--background50);
  }
  .xxl\:divide-reward-blue-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-blue-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-silver-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-silver-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-gold-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-gold-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-platinum-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-platinum-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-vip-start > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-divide-opacity));
  }
  .xxl\:divide-reward-vip-end > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-divide-opacity));
  }
  .xxl\:divide-fee-breakdown-bg > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-divide-opacity));
  }
  .xxl\:divide-light-grey > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xxl\:divide-disabled-button-background > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-divide-opacity));
  }
  .xxl\:divide-disabled-button-text > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-divide-opacity));
  }
  .xxl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .xxl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .xxl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .xxl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .xxl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .xxl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .xxl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .xxl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .xxl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .xxl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .xxl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .xxl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .xxl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .xxl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .xxl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .xxl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .xxl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .xxl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .xxl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .xxl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .xxl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xxl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xxl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .xxl\:bg-fixed {
    background-attachment: fixed;
  }
  .xxl\:bg-local {
    background-attachment: local;
  }
  .xxl\:bg-scroll {
    background-attachment: scroll;
  }
  .xxl\:bg-clip-border {
    background-clip: border-box;
  }
  .xxl\:bg-clip-padding {
    background-clip: padding-box;
  }
  .xxl\:bg-clip-content {
    background-clip: content-box;
  }
  .xxl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .xxl\:bg-transparent {
    background-color: transparent;
  }
  .xxl\:bg-current {
    background-color: currentColor;
  }
  .xxl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxl\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .xxl\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxl\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxl\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxl\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxl\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxl\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxl\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxl\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxl\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxl\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxl\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxl\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxl\:bg-body-background {
    background-color: var(--body-background);
  }
  .xxl\:bg-background {
    background-color: var(--background);
  }
  .xxl\:bg-input-background {
    background-color: var(--input-background);
  }
  .xxl\:bg-body-text {
    background-color: var(--body-text);
  }
  .xxl\:bg-header-text {
    background-color: var(--header-text);
  }
  .xxl\:bg-primary {
    background-color: var(--primary);
  }
  .xxl\:bg-secondary {
    background-color: var(--secondary);
  }
  .xxl\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .xxl\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .xxl\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .xxl\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .xxl\:bg-input-text {
    background-color: var(--input-text);
  }
  .xxl\:bg-success {
    background-color: var(--success);
  }
  .xxl\:bg-error {
    background-color: var(--error);
  }
  .xxl\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .xxl\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxl\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .xxl\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .xxl\:bg-background50 {
    background-color: var(--background50);
  }
  .xxl\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxl\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxl\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .xxl\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .xxl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-300 {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-vivid-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-vivid-blue-500 {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxl\:group-hover\:bg-background-primary {
    background-color: var(--background-primary);
  }
  .group:hover .xxl\:group-hover\:bg-main-background {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-dark-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-blue-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-text-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pale-grey-two {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-pale-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-btc {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-btc-symbol {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-paxg {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-usdc {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-black-navy {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-ghost-white {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-spring-green {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-light-slate-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-body-background {
    background-color: var(--body-background);
  }
  .group:hover .xxl\:group-hover\:bg-background {
    background-color: var(--background);
  }
  .group:hover .xxl\:group-hover\:bg-input-background {
    background-color: var(--input-background);
  }
  .group:hover .xxl\:group-hover\:bg-body-text {
    background-color: var(--body-text);
  }
  .group:hover .xxl\:group-hover\:bg-header-text {
    background-color: var(--header-text);
  }
  .group:hover .xxl\:group-hover\:bg-primary {
    background-color: var(--primary);
  }
  .group:hover .xxl\:group-hover\:bg-secondary {
    background-color: var(--secondary);
  }
  .group:hover .xxl\:group-hover\:bg-tertiary {
    background-color: var(--tertiary);
  }
  .group:hover .xxl\:group-hover\:bg-primary-hover {
    background-color: var(--primary-hover);
  }
  .group:hover .xxl\:group-hover\:bg-secondary-hover {
    background-color: var(--secondary-hover);
  }
  .group:hover .xxl\:group-hover\:bg-tertiary-hover {
    background-color: var(--tertiary-hover);
  }
  .group:hover .xxl\:group-hover\:bg-input-text {
    background-color: var(--input-text);
  }
  .group:hover .xxl\:group-hover\:bg-success {
    background-color: var(--success);
  }
  .group:hover .xxl\:group-hover\:bg-error {
    background-color: var(--error);
  }
  .group:hover .xxl\:group-hover\:bg-custom-loader {
    background-color: var(--custom-loader);
  }
  .group:hover .xxl\:group-hover\:bg-violet {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-violet-hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-skeleton-loader-from {
    background-color: var(--skeleton-loader-from);
  }
  .group:hover .xxl\:group-hover\:bg-skeleton-loader-to {
    background-color: var(--skeleton-loader-to);
  }
  .group:hover .xxl\:group-hover\:bg-background50 {
    background-color: var(--background50);
  }
  .group:hover .xxl\:group-hover\:bg-reward-blue-start {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-blue-end {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-silver-start {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-silver-end {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-gold-start {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-gold-end {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-platinum-start {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-platinum-end {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-vip-start {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-reward-vip-end {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-fee-breakdown-bg {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-light-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-disabled-button-background {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .group:hover .xxl\:group-hover\:bg-disabled-button-text {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .xxl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .xxl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-300:focus-within {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-vivid-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-vivid-blue-500:focus-within {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus-within\:bg-background-primary:focus-within {
    background-color: var(--background-primary);
  }
  .xxl\:focus-within\:bg-main-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-dark-blue:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-blue-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-text-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pale-grey-two:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-pale-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-btc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-btc-symbol:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-paxg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-usdc:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-black-navy:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-ghost-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-spring-green:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-light-slate-gray:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-body-background:focus-within {
    background-color: var(--body-background);
  }
  .xxl\:focus-within\:bg-background:focus-within {
    background-color: var(--background);
  }
  .xxl\:focus-within\:bg-input-background:focus-within {
    background-color: var(--input-background);
  }
  .xxl\:focus-within\:bg-body-text:focus-within {
    background-color: var(--body-text);
  }
  .xxl\:focus-within\:bg-header-text:focus-within {
    background-color: var(--header-text);
  }
  .xxl\:focus-within\:bg-primary:focus-within {
    background-color: var(--primary);
  }
  .xxl\:focus-within\:bg-secondary:focus-within {
    background-color: var(--secondary);
  }
  .xxl\:focus-within\:bg-tertiary:focus-within {
    background-color: var(--tertiary);
  }
  .xxl\:focus-within\:bg-primary-hover:focus-within {
    background-color: var(--primary-hover);
  }
  .xxl\:focus-within\:bg-secondary-hover:focus-within {
    background-color: var(--secondary-hover);
  }
  .xxl\:focus-within\:bg-tertiary-hover:focus-within {
    background-color: var(--tertiary-hover);
  }
  .xxl\:focus-within\:bg-input-text:focus-within {
    background-color: var(--input-text);
  }
  .xxl\:focus-within\:bg-success:focus-within {
    background-color: var(--success);
  }
  .xxl\:focus-within\:bg-error:focus-within {
    background-color: var(--error);
  }
  .xxl\:focus-within\:bg-custom-loader:focus-within {
    background-color: var(--custom-loader);
  }
  .xxl\:focus-within\:bg-violet:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-violet-hover:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-skeleton-loader-from:focus-within {
    background-color: var(--skeleton-loader-from);
  }
  .xxl\:focus-within\:bg-skeleton-loader-to:focus-within {
    background-color: var(--skeleton-loader-to);
  }
  .xxl\:focus-within\:bg-background50:focus-within {
    background-color: var(--background50);
  }
  .xxl\:focus-within\:bg-reward-blue-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-blue-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-silver-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-silver-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-gold-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-gold-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-platinum-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-platinum-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-vip-start:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-reward-vip-end:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-fee-breakdown-bg:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-light-grey:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-disabled-button-background:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxl\:focus-within\:bg-disabled-button-text:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .xxl\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .xxl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-300:hover {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-vivid-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-vivid-blue-500:hover {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:hover\:bg-background-primary:hover {
    background-color: var(--background-primary);
  }
  .xxl\:hover\:bg-main-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-dark-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-blue-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-text-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pale-grey-two:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-pale-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-btc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-btc-symbol:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-paxg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-usdc:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-black-navy:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-ghost-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-spring-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-light-slate-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-body-background:hover {
    background-color: var(--body-background);
  }
  .xxl\:hover\:bg-background:hover {
    background-color: var(--background);
  }
  .xxl\:hover\:bg-input-background:hover {
    background-color: var(--input-background);
  }
  .xxl\:hover\:bg-body-text:hover {
    background-color: var(--body-text);
  }
  .xxl\:hover\:bg-header-text:hover {
    background-color: var(--header-text);
  }
  .xxl\:hover\:bg-primary:hover {
    background-color: var(--primary);
  }
  .xxl\:hover\:bg-secondary:hover {
    background-color: var(--secondary);
  }
  .xxl\:hover\:bg-tertiary:hover {
    background-color: var(--tertiary);
  }
  .xxl\:hover\:bg-primary-hover:hover {
    background-color: var(--primary-hover);
  }
  .xxl\:hover\:bg-secondary-hover:hover {
    background-color: var(--secondary-hover);
  }
  .xxl\:hover\:bg-tertiary-hover:hover {
    background-color: var(--tertiary-hover);
  }
  .xxl\:hover\:bg-input-text:hover {
    background-color: var(--input-text);
  }
  .xxl\:hover\:bg-success:hover {
    background-color: var(--success);
  }
  .xxl\:hover\:bg-error:hover {
    background-color: var(--error);
  }
  .xxl\:hover\:bg-custom-loader:hover {
    background-color: var(--custom-loader);
  }
  .xxl\:hover\:bg-violet:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-violet-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-skeleton-loader-from:hover {
    background-color: var(--skeleton-loader-from);
  }
  .xxl\:hover\:bg-skeleton-loader-to:hover {
    background-color: var(--skeleton-loader-to);
  }
  .xxl\:hover\:bg-background50:hover {
    background-color: var(--background50);
  }
  .xxl\:hover\:bg-reward-blue-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-blue-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-silver-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-silver-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-gold-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-gold-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-platinum-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-platinum-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-vip-start:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-reward-vip-end:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-fee-breakdown-bg:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-light-grey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-disabled-button-background:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxl\:hover\:bg-disabled-button-text:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .xxl\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .xxl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-300:focus {
    background-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 114, 139, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-vivid-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-vivid-blue-500:focus {
    background-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:bg-background-primary:focus {
    background-color: var(--background-primary);
  }
  .xxl\:focus\:bg-main-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(18, 23, 41, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-dark-blue:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 65, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-blue-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(47, 58, 92, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-text-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 167, 193, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pale-grey-two:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 235, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-pale-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(168, 175, 204, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-btc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 147, 26, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-btc-symbol:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 179, 0, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-paxg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 197, 78, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-usdc:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 117, 202, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-black-navy:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 18, 57, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-ghost-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-spring-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 229, 109, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-light-slate-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 139, 148, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-body-background:focus {
    background-color: var(--body-background);
  }
  .xxl\:focus\:bg-background:focus {
    background-color: var(--background);
  }
  .xxl\:focus\:bg-input-background:focus {
    background-color: var(--input-background);
  }
  .xxl\:focus\:bg-body-text:focus {
    background-color: var(--body-text);
  }
  .xxl\:focus\:bg-header-text:focus {
    background-color: var(--header-text);
  }
  .xxl\:focus\:bg-primary:focus {
    background-color: var(--primary);
  }
  .xxl\:focus\:bg-secondary:focus {
    background-color: var(--secondary);
  }
  .xxl\:focus\:bg-tertiary:focus {
    background-color: var(--tertiary);
  }
  .xxl\:focus\:bg-primary-hover:focus {
    background-color: var(--primary-hover);
  }
  .xxl\:focus\:bg-secondary-hover:focus {
    background-color: var(--secondary-hover);
  }
  .xxl\:focus\:bg-tertiary-hover:focus {
    background-color: var(--tertiary-hover);
  }
  .xxl\:focus\:bg-input-text:focus {
    background-color: var(--input-text);
  }
  .xxl\:focus\:bg-success:focus {
    background-color: var(--success);
  }
  .xxl\:focus\:bg-error:focus {
    background-color: var(--error);
  }
  .xxl\:focus\:bg-custom-loader:focus {
    background-color: var(--custom-loader);
  }
  .xxl\:focus\:bg-violet:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-violet-hover:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 11, 142, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-skeleton-loader-from:focus {
    background-color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:bg-skeleton-loader-to:focus {
    background-color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:bg-background50:focus {
    background-color: var(--background50);
  }
  .xxl\:focus\:bg-reward-blue-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 107, 255, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-blue-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-silver-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(72, 72, 72, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-silver-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 172, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-gold-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 223, 138, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-gold-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 37, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-platinum-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 149, 149, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-platinum-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-vip-start:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 46, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-reward-vip-end:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 33, 238, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-fee-breakdown-bg:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 33, 57, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-light-grey:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-disabled-button-background:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 42, 152, var(--tw-bg-opacity));
  }
  .xxl\:focus\:bg-disabled-button-text:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 147, 160, var(--tw-bg-opacity));
  }
  .xxl\:bg-none {
    background-image: none;
  }
  .xxl\:bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-r {
    background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .xxl\:bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-l {
    background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  }
  .xxl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .xxl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:from-gray-300 {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:from-gray-900 {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:from-vivid-blue {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:from-vivid-blue-500 {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:from-background-primary {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-main-background {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:from-dark-blue {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:from-blue-grey {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:from-text-grey {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:from-pale-grey-two {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:from-pale-grey {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:from-btc {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:from-btc-symbol {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:from-paxg {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:from-usdc {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:from-black-navy {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:from-ghost-white {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:from-spring-green {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:from-light-slate-gray {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:from-body-background {
    --tw-gradient-from: var(--body-background);
  }
  .xxl\:from-background,
  .xxl\:from-body-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-background {
    --tw-gradient-from: var(--background);
  }
  .xxl\:from-input-background {
    --tw-gradient-from: var(--input-background);
  }
  .xxl\:from-body-text,
  .xxl\:from-input-background {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-body-text {
    --tw-gradient-from: var(--body-text);
  }
  .xxl\:from-header-text {
    --tw-gradient-from: var(--header-text);
  }
  .xxl\:from-header-text,
  .xxl\:from-primary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-primary {
    --tw-gradient-from: var(--primary);
  }
  .xxl\:from-secondary {
    --tw-gradient-from: var(--secondary);
  }
  .xxl\:from-secondary,
  .xxl\:from-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-tertiary {
    --tw-gradient-from: var(--tertiary);
  }
  .xxl\:from-primary-hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxl\:from-primary-hover,
  .xxl\:from-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-secondary-hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxl\:from-tertiary-hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxl\:from-input-text,
  .xxl\:from-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-input-text {
    --tw-gradient-from: var(--input-text);
  }
  .xxl\:from-success {
    --tw-gradient-from: var(--success);
  }
  .xxl\:from-error,
  .xxl\:from-success {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-error {
    --tw-gradient-from: var(--error);
  }
  .xxl\:from-custom-loader {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-violet {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:from-violet-hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:from-skeleton-loader-from {
    --tw-gradient-from: var(--skeleton-loader-from);
  }
  .xxl\:from-skeleton-loader-from,
  .xxl\:from-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-skeleton-loader-to {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxl\:from-background50 {
    --tw-gradient-from: var(--background50);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:from-reward-blue-start {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:from-reward-blue-end {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:from-reward-silver-start {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:from-reward-silver-end {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:from-reward-gold-start {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:from-reward-gold-end {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:from-reward-platinum-start {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:from-reward-platinum-end {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:from-reward-vip-start {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:from-reward-vip-end {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:from-fee-breakdown-bg {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:from-light-grey {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:from-disabled-button-background {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:from-disabled-button-text {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:via-vivid-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:via-vivid-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:via-background-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-main-background {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:via-dark-blue {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:via-blue-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:via-text-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:via-pale-grey-two {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:via-pale-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:via-btc {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:via-btc-symbol {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:via-paxg {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:via-usdc {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:via-black-navy {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:via-ghost-white {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:via-spring-green {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:via-light-slate-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:via-body-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-input-background {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-body-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-header-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-primary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-secondary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-tertiary {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-primary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-secondary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-tertiary-hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-input-text {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-success {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-custom-loader {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-violet {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:via-violet-hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:via-skeleton-loader-from {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-skeleton-loader-to {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-background50 {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:via-reward-blue-start {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:via-reward-blue-end {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:via-reward-silver-start {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:via-reward-silver-end {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:via-reward-gold-start {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:via-reward-gold-end {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:via-reward-platinum-start {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:via-reward-platinum-end {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:via-reward-vip-start {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:via-reward-vip-end {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:via-fee-breakdown-bg {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:via-light-grey {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:via-disabled-button-background {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:via-disabled-button-text {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .xxl\:to-current {
    --tw-gradient-to: currentColor;
  }
  .xxl\:to-black {
    --tw-gradient-to: #000;
  }
  .xxl\:to-white {
    --tw-gradient-to: #fff;
  }
  .xxl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .xxl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .xxl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:to-gray-300 {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .xxl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .xxl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .xxl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .xxl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .xxl\:to-gray-900 {
    --tw-gradient-to: #6d728b;
  }
  .xxl\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .xxl\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .xxl\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .xxl\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .xxl\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .xxl\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .xxl\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .xxl\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .xxl\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .xxl\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .xxl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .xxl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .xxl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .xxl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .xxl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .xxl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .xxl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .xxl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .xxl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .xxl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .xxl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .xxl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .xxl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .xxl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .xxl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .xxl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .xxl\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .xxl\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .xxl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .xxl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .xxl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .xxl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .xxl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .xxl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .xxl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .xxl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .xxl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .xxl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .xxl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .xxl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .xxl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .xxl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .xxl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .xxl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .xxl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .xxl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .xxl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .xxl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .xxl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .xxl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .xxl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .xxl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .xxl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .xxl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .xxl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .xxl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .xxl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .xxl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .xxl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .xxl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .xxl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .xxl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .xxl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .xxl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .xxl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .xxl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .xxl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .xxl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .xxl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .xxl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .xxl\:to-vivid-blue {
    --tw-gradient-to: #212eee;
  }
  .xxl\:to-vivid-blue-500 {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxl\:to-background-primary {
    --tw-gradient-to: var(--background-primary);
  }
  .xxl\:to-main-background {
    --tw-gradient-to: #121729;
  }
  .xxl\:to-dark-blue {
    --tw-gradient-to: #1e2741;
  }
  .xxl\:to-blue-grey {
    --tw-gradient-to: #2f3a5c;
  }
  .xxl\:to-text-grey {
    --tw-gradient-to: #a4a7c1;
  }
  .xxl\:to-pale-grey-two {
    --tw-gradient-to: #eeebff;
  }
  .xxl\:to-pale-grey {
    --tw-gradient-to: #a8afcc;
  }
  .xxl\:to-btc {
    --tw-gradient-to: #f7931a;
  }
  .xxl\:to-btc-symbol {
    --tw-gradient-to: #f5b300;
  }
  .xxl\:to-paxg {
    --tw-gradient-to: #ffc54e;
  }
  .xxl\:to-usdc {
    --tw-gradient-to: #2775ca;
  }
  .xxl\:to-black-navy {
    --tw-gradient-to: #101239;
  }
  .xxl\:to-ghost-white {
    --tw-gradient-to: #f6f5ff;
  }
  .xxl\:to-spring-green {
    --tw-gradient-to: #1be56d;
  }
  .xxl\:to-light-slate-gray {
    --tw-gradient-to: #888b94;
  }
  .xxl\:to-body-background {
    --tw-gradient-to: var(--body-background);
  }
  .xxl\:to-background {
    --tw-gradient-to: var(--background);
  }
  .xxl\:to-input-background {
    --tw-gradient-to: var(--input-background);
  }
  .xxl\:to-body-text {
    --tw-gradient-to: var(--body-text);
  }
  .xxl\:to-header-text {
    --tw-gradient-to: var(--header-text);
  }
  .xxl\:to-primary {
    --tw-gradient-to: var(--primary);
  }
  .xxl\:to-secondary {
    --tw-gradient-to: var(--secondary);
  }
  .xxl\:to-tertiary {
    --tw-gradient-to: var(--tertiary);
  }
  .xxl\:to-primary-hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxl\:to-secondary-hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxl\:to-tertiary-hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxl\:to-input-text {
    --tw-gradient-to: var(--input-text);
  }
  .xxl\:to-success {
    --tw-gradient-to: var(--success);
  }
  .xxl\:to-error {
    --tw-gradient-to: var(--error);
  }
  .xxl\:to-custom-loader {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxl\:to-violet {
    --tw-gradient-to: #9421ee;
  }
  .xxl\:to-violet-hover {
    --tw-gradient-to: #550b8e;
  }
  .xxl\:to-skeleton-loader-from {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxl\:to-skeleton-loader-to {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxl\:to-background50 {
    --tw-gradient-to: var(--background50);
  }
  .xxl\:to-reward-blue-start {
    --tw-gradient-to: #626bff;
  }
  .xxl\:to-reward-blue-end {
    --tw-gradient-to: #212eee;
  }
  .xxl\:to-reward-silver-start {
    --tw-gradient-to: #484848;
  }
  .xxl\:to-reward-silver-end {
    --tw-gradient-to: #acacac;
  }
  .xxl\:to-reward-gold-start {
    --tw-gradient-to: #ffdf8a;
  }
  .xxl\:to-reward-gold-end {
    --tw-gradient-to: #ffc425;
  }
  .xxl\:to-reward-platinum-start {
    --tw-gradient-to: #959595;
  }
  .xxl\:to-reward-platinum-end {
    --tw-gradient-to: #f8f8f8;
  }
  .xxl\:to-reward-vip-start {
    --tw-gradient-to: #212eee;
  }
  .xxl\:to-reward-vip-end {
    --tw-gradient-to: #c521ee;
  }
  .xxl\:to-fee-breakdown-bg {
    --tw-gradient-to: #182139;
  }
  .xxl\:to-light-grey {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:to-disabled-button-background {
    --tw-gradient-to: #1f2a98;
  }
  .xxl\:to-disabled-button-text {
    --tw-gradient-to: #8e93a0;
  }
  .xxl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:hover\:from-gray-300:hover {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:hover\:from-vivid-blue:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:from-vivid-blue-500:hover {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:hover\:from-background-primary:hover {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-main-background:hover {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:hover\:from-dark-blue:hover {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:hover\:from-blue-grey:hover {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:hover\:from-text-grey:hover {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:hover\:from-pale-grey-two:hover {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:hover\:from-pale-grey:hover {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:hover\:from-btc:hover {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:hover\:from-btc-symbol:hover {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:hover\:from-paxg:hover {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:hover\:from-usdc:hover {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:hover\:from-black-navy:hover {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:hover\:from-ghost-white:hover {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:hover\:from-spring-green:hover {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:hover\:from-light-slate-gray:hover {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:hover\:from-body-background:hover {
    --tw-gradient-from: var(--body-background);
  }
  .xxl\:hover\:from-background:hover,
  .xxl\:hover\:from-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-background:hover {
    --tw-gradient-from: var(--background);
  }
  .xxl\:hover\:from-input-background:hover {
    --tw-gradient-from: var(--input-background);
  }
  .xxl\:hover\:from-body-text:hover,
  .xxl\:hover\:from-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-body-text:hover {
    --tw-gradient-from: var(--body-text);
  }
  .xxl\:hover\:from-header-text:hover {
    --tw-gradient-from: var(--header-text);
  }
  .xxl\:hover\:from-header-text:hover,
  .xxl\:hover\:from-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-primary:hover {
    --tw-gradient-from: var(--primary);
  }
  .xxl\:hover\:from-secondary:hover {
    --tw-gradient-from: var(--secondary);
  }
  .xxl\:hover\:from-secondary:hover,
  .xxl\:hover\:from-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-tertiary:hover {
    --tw-gradient-from: var(--tertiary);
  }
  .xxl\:hover\:from-primary-hover:hover {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxl\:hover\:from-primary-hover:hover,
  .xxl\:hover\:from-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-secondary-hover:hover {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxl\:hover\:from-input-text:hover,
  .xxl\:hover\:from-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-input-text:hover {
    --tw-gradient-from: var(--input-text);
  }
  .xxl\:hover\:from-success:hover {
    --tw-gradient-from: var(--success);
  }
  .xxl\:hover\:from-error:hover,
  .xxl\:hover\:from-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-error:hover {
    --tw-gradient-from: var(--error);
  }
  .xxl\:hover\:from-custom-loader:hover {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-violet:hover {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:hover\:from-violet-hover:hover {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:hover\:from-skeleton-loader-from:hover {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxl\:hover\:from-background50:hover,
  .xxl\:hover\:from-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:from-background50:hover {
    --tw-gradient-from: var(--background50);
  }
  .xxl\:hover\:from-reward-blue-start:hover {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:hover\:from-reward-blue-end:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:from-reward-silver-start:hover {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:hover\:from-reward-silver-end:hover {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:hover\:from-reward-gold-start:hover {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:hover\:from-reward-gold-end:hover {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:hover\:from-reward-platinum-start:hover {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:hover\:from-reward-platinum-end:hover {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:hover\:from-reward-vip-start:hover {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:from-reward-vip-end:hover {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:hover\:from-fee-breakdown-bg:hover {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:hover\:from-light-grey:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:hover\:from-disabled-button-background:hover {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:hover\:from-disabled-button-text:hover {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:hover\:via-vivid-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:via-vivid-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:hover\:via-background-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-main-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:hover\:via-dark-blue:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:hover\:via-blue-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:hover\:via-text-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:hover\:via-pale-grey-two:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:hover\:via-pale-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:hover\:via-btc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:hover\:via-btc-symbol:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:hover\:via-paxg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:hover\:via-usdc:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:hover\:via-black-navy:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:hover\:via-ghost-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:hover\:via-spring-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:hover\:via-light-slate-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:hover\:via-body-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-input-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-body-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-header-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-primary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-secondary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-tertiary:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-primary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-secondary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-tertiary-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-input-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-success:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-custom-loader:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-violet:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:hover\:via-violet-hover:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:hover\:via-skeleton-loader-from:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-skeleton-loader-to:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-background50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:hover\:via-reward-blue-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:hover\:via-reward-blue-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:via-reward-silver-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:hover\:via-reward-silver-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:hover\:via-reward-gold-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:hover\:via-reward-gold-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:hover\:via-reward-platinum-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:hover\:via-reward-platinum-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:hover\:via-reward-vip-start:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:hover\:via-reward-vip-end:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:hover\:via-fee-breakdown-bg:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:hover\:via-light-grey:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:hover\:via-disabled-button-background:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:hover\:via-disabled-button-text:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .xxl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .xxl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .xxl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .xxl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .xxl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .xxl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:hover\:to-gray-300:hover {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .xxl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .xxl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .xxl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .xxl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .xxl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #6d728b;
  }
  .xxl\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .xxl\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .xxl\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .xxl\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .xxl\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .xxl\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .xxl\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .xxl\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .xxl\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .xxl\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .xxl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .xxl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .xxl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .xxl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .xxl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .xxl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .xxl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .xxl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .xxl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .xxl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .xxl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .xxl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .xxl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .xxl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .xxl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .xxl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .xxl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .xxl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .xxl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .xxl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .xxl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .xxl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .xxl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .xxl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .xxl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .xxl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .xxl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .xxl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .xxl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .xxl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .xxl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .xxl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .xxl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .xxl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .xxl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .xxl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .xxl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .xxl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .xxl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .xxl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .xxl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .xxl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .xxl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .xxl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .xxl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .xxl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .xxl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .xxl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .xxl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .xxl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .xxl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .xxl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .xxl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .xxl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .xxl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .xxl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .xxl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .xxl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .xxl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .xxl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .xxl\:hover\:to-vivid-blue:hover {
    --tw-gradient-to: #212eee;
  }
  .xxl\:hover\:to-vivid-blue-500:hover {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxl\:hover\:to-background-primary:hover {
    --tw-gradient-to: var(--background-primary);
  }
  .xxl\:hover\:to-main-background:hover {
    --tw-gradient-to: #121729;
  }
  .xxl\:hover\:to-dark-blue:hover {
    --tw-gradient-to: #1e2741;
  }
  .xxl\:hover\:to-blue-grey:hover {
    --tw-gradient-to: #2f3a5c;
  }
  .xxl\:hover\:to-text-grey:hover {
    --tw-gradient-to: #a4a7c1;
  }
  .xxl\:hover\:to-pale-grey-two:hover {
    --tw-gradient-to: #eeebff;
  }
  .xxl\:hover\:to-pale-grey:hover {
    --tw-gradient-to: #a8afcc;
  }
  .xxl\:hover\:to-btc:hover {
    --tw-gradient-to: #f7931a;
  }
  .xxl\:hover\:to-btc-symbol:hover {
    --tw-gradient-to: #f5b300;
  }
  .xxl\:hover\:to-paxg:hover {
    --tw-gradient-to: #ffc54e;
  }
  .xxl\:hover\:to-usdc:hover {
    --tw-gradient-to: #2775ca;
  }
  .xxl\:hover\:to-black-navy:hover {
    --tw-gradient-to: #101239;
  }
  .xxl\:hover\:to-ghost-white:hover {
    --tw-gradient-to: #f6f5ff;
  }
  .xxl\:hover\:to-spring-green:hover {
    --tw-gradient-to: #1be56d;
  }
  .xxl\:hover\:to-light-slate-gray:hover {
    --tw-gradient-to: #888b94;
  }
  .xxl\:hover\:to-body-background:hover {
    --tw-gradient-to: var(--body-background);
  }
  .xxl\:hover\:to-background:hover {
    --tw-gradient-to: var(--background);
  }
  .xxl\:hover\:to-input-background:hover {
    --tw-gradient-to: var(--input-background);
  }
  .xxl\:hover\:to-body-text:hover {
    --tw-gradient-to: var(--body-text);
  }
  .xxl\:hover\:to-header-text:hover {
    --tw-gradient-to: var(--header-text);
  }
  .xxl\:hover\:to-primary:hover {
    --tw-gradient-to: var(--primary);
  }
  .xxl\:hover\:to-secondary:hover {
    --tw-gradient-to: var(--secondary);
  }
  .xxl\:hover\:to-tertiary:hover {
    --tw-gradient-to: var(--tertiary);
  }
  .xxl\:hover\:to-primary-hover:hover {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxl\:hover\:to-secondary-hover:hover {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxl\:hover\:to-tertiary-hover:hover {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxl\:hover\:to-input-text:hover {
    --tw-gradient-to: var(--input-text);
  }
  .xxl\:hover\:to-success:hover {
    --tw-gradient-to: var(--success);
  }
  .xxl\:hover\:to-error:hover {
    --tw-gradient-to: var(--error);
  }
  .xxl\:hover\:to-custom-loader:hover {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxl\:hover\:to-violet:hover {
    --tw-gradient-to: #9421ee;
  }
  .xxl\:hover\:to-violet-hover:hover {
    --tw-gradient-to: #550b8e;
  }
  .xxl\:hover\:to-skeleton-loader-from:hover {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxl\:hover\:to-skeleton-loader-to:hover {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxl\:hover\:to-background50:hover {
    --tw-gradient-to: var(--background50);
  }
  .xxl\:hover\:to-reward-blue-start:hover {
    --tw-gradient-to: #626bff;
  }
  .xxl\:hover\:to-reward-blue-end:hover {
    --tw-gradient-to: #212eee;
  }
  .xxl\:hover\:to-reward-silver-start:hover {
    --tw-gradient-to: #484848;
  }
  .xxl\:hover\:to-reward-silver-end:hover {
    --tw-gradient-to: #acacac;
  }
  .xxl\:hover\:to-reward-gold-start:hover {
    --tw-gradient-to: #ffdf8a;
  }
  .xxl\:hover\:to-reward-gold-end:hover {
    --tw-gradient-to: #ffc425;
  }
  .xxl\:hover\:to-reward-platinum-start:hover {
    --tw-gradient-to: #959595;
  }
  .xxl\:hover\:to-reward-platinum-end:hover {
    --tw-gradient-to: #f8f8f8;
  }
  .xxl\:hover\:to-reward-vip-start:hover {
    --tw-gradient-to: #212eee;
  }
  .xxl\:hover\:to-reward-vip-end:hover {
    --tw-gradient-to: #c521ee;
  }
  .xxl\:hover\:to-fee-breakdown-bg:hover {
    --tw-gradient-to: #182139;
  }
  .xxl\:hover\:to-light-grey:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:hover\:to-disabled-button-background:hover {
    --tw-gradient-to: #1f2a98;
  }
  .xxl\:hover\:to-disabled-button-text:hover {
    --tw-gradient-to: #8e93a0;
  }
  .xxl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
  }
  .xxl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:focus\:from-gray-300:focus {
    --tw-gradient-from: rgba(98, 107, 255, 0.25098039215686274);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #6d728b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:focus\:from-vivid-blue:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:from-vivid-blue-500:focus {
    --tw-gradient-from: rgba(144, 150, 246, 0.5);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:focus\:from-background-primary:focus {
    --tw-gradient-from: var(--background-primary);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-main-background:focus {
    --tw-gradient-from: #121729;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:focus\:from-dark-blue:focus {
    --tw-gradient-from: #1e2741;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:focus\:from-blue-grey:focus {
    --tw-gradient-from: #2f3a5c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:focus\:from-text-grey:focus {
    --tw-gradient-from: #a4a7c1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:focus\:from-pale-grey-two:focus {
    --tw-gradient-from: #eeebff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:focus\:from-pale-grey:focus {
    --tw-gradient-from: #a8afcc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:focus\:from-btc:focus {
    --tw-gradient-from: #f7931a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:focus\:from-btc-symbol:focus {
    --tw-gradient-from: #f5b300;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:focus\:from-paxg:focus {
    --tw-gradient-from: #ffc54e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:focus\:from-usdc:focus {
    --tw-gradient-from: #2775ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:focus\:from-black-navy:focus {
    --tw-gradient-from: #101239;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:focus\:from-ghost-white:focus {
    --tw-gradient-from: #f6f5ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:focus\:from-spring-green:focus {
    --tw-gradient-from: #1be56d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:focus\:from-light-slate-gray:focus {
    --tw-gradient-from: #888b94;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:focus\:from-body-background:focus {
    --tw-gradient-from: var(--body-background);
  }
  .xxl\:focus\:from-background:focus,
  .xxl\:focus\:from-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-background:focus {
    --tw-gradient-from: var(--background);
  }
  .xxl\:focus\:from-input-background:focus {
    --tw-gradient-from: var(--input-background);
  }
  .xxl\:focus\:from-body-text:focus,
  .xxl\:focus\:from-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-body-text:focus {
    --tw-gradient-from: var(--body-text);
  }
  .xxl\:focus\:from-header-text:focus {
    --tw-gradient-from: var(--header-text);
  }
  .xxl\:focus\:from-header-text:focus,
  .xxl\:focus\:from-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-primary:focus {
    --tw-gradient-from: var(--primary);
  }
  .xxl\:focus\:from-secondary:focus {
    --tw-gradient-from: var(--secondary);
  }
  .xxl\:focus\:from-secondary:focus,
  .xxl\:focus\:from-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-tertiary:focus {
    --tw-gradient-from: var(--tertiary);
  }
  .xxl\:focus\:from-primary-hover:focus {
    --tw-gradient-from: var(--primary-hover);
  }
  .xxl\:focus\:from-primary-hover:focus,
  .xxl\:focus\:from-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-secondary-hover:focus {
    --tw-gradient-from: var(--secondary-hover);
  }
  .xxl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-from: var(--tertiary-hover);
  }
  .xxl\:focus\:from-input-text:focus,
  .xxl\:focus\:from-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-input-text:focus {
    --tw-gradient-from: var(--input-text);
  }
  .xxl\:focus\:from-success:focus {
    --tw-gradient-from: var(--success);
  }
  .xxl\:focus\:from-error:focus,
  .xxl\:focus\:from-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-error:focus {
    --tw-gradient-from: var(--error);
  }
  .xxl\:focus\:from-custom-loader:focus {
    --tw-gradient-from: var(--custom-loader);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-violet:focus {
    --tw-gradient-from: #9421ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:focus\:from-violet-hover:focus {
    --tw-gradient-from: #550b8e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:focus\:from-skeleton-loader-from:focus {
    --tw-gradient-from: var(--skeleton-loader-from);
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-from: var(--skeleton-loader-to);
  }
  .xxl\:focus\:from-background50:focus,
  .xxl\:focus\:from-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:from-background50:focus {
    --tw-gradient-from: var(--background50);
  }
  .xxl\:focus\:from-reward-blue-start:focus {
    --tw-gradient-from: #626bff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:focus\:from-reward-blue-end:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:from-reward-silver-start:focus {
    --tw-gradient-from: #484848;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:focus\:from-reward-silver-end:focus {
    --tw-gradient-from: #acacac;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:focus\:from-reward-gold-start:focus {
    --tw-gradient-from: #ffdf8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:focus\:from-reward-gold-end:focus {
    --tw-gradient-from: #ffc425;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:focus\:from-reward-platinum-start:focus {
    --tw-gradient-from: #959595;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:focus\:from-reward-platinum-end:focus {
    --tw-gradient-from: #f8f8f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:focus\:from-reward-vip-start:focus {
    --tw-gradient-from: #212eee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:from-reward-vip-end:focus {
    --tw-gradient-from: #c521ee;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:focus\:from-fee-breakdown-bg:focus {
    --tw-gradient-from: #182139;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:focus\:from-light-grey:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:focus\:from-disabled-button-background:focus {
    --tw-gradient-from: #1f2a98;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:focus\:from-disabled-button-text:focus {
    --tw-gradient-from: #8e93a0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, transparent);
  }
  .xxl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xxl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xxl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from),
      rgba(98, 107, 255, 0.25098039215686274),
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xxl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xxl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xxl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xxl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xxl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d728b,
      var(--tw-gradient-to, rgba(109, 114, 139, 0));
  }
  .xxl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xxl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xxl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xxl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xxl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xxl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xxl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xxl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xxl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xxl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xxl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xxl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xxl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xxl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xxl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xxl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xxl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xxl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xxl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xxl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xxl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xxl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xxl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xxl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xxl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xxl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xxl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xxl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xxl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xxl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xxl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xxl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xxl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xxl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xxl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xxl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xxl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xxl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xxl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xxl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xxl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xxl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xxl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xxl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xxl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xxl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xxl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xxl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xxl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xxl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xxl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xxl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xxl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xxl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xxl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xxl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xxl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xxl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xxl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xxl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xxl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xxl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xxl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xxl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xxl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xxl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xxl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xxl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xxl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xxl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xxl\:focus\:via-vivid-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:via-vivid-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), rgba(144, 150, 246, 0.5),
      var(--tw-gradient-to, rgba(144, 150, 246, 0));
  }
  .xxl\:focus\:via-background-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background-primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-main-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #121729,
      var(--tw-gradient-to, rgba(18, 23, 41, 0));
  }
  .xxl\:focus\:via-dark-blue:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e2741,
      var(--tw-gradient-to, rgba(30, 39, 65, 0));
  }
  .xxl\:focus\:via-blue-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2f3a5c,
      var(--tw-gradient-to, rgba(47, 58, 92, 0));
  }
  .xxl\:focus\:via-text-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a7c1,
      var(--tw-gradient-to, rgba(164, 167, 193, 0));
  }
  .xxl\:focus\:via-pale-grey-two:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eeebff,
      var(--tw-gradient-to, rgba(238, 235, 255, 0));
  }
  .xxl\:focus\:via-pale-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a8afcc,
      var(--tw-gradient-to, rgba(168, 175, 204, 0));
  }
  .xxl\:focus\:via-btc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f7931a,
      var(--tw-gradient-to, rgba(247, 147, 26, 0));
  }
  .xxl\:focus\:via-btc-symbol:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5b300,
      var(--tw-gradient-to, rgba(245, 179, 0, 0));
  }
  .xxl\:focus\:via-paxg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc54e,
      var(--tw-gradient-to, rgba(255, 197, 78, 0));
  }
  .xxl\:focus\:via-usdc:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2775ca,
      var(--tw-gradient-to, rgba(39, 117, 202, 0));
  }
  .xxl\:focus\:via-black-navy:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #101239,
      var(--tw-gradient-to, rgba(16, 18, 57, 0));
  }
  .xxl\:focus\:via-ghost-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f6f5ff,
      var(--tw-gradient-to, rgba(246, 245, 255, 0));
  }
  .xxl\:focus\:via-spring-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1be56d,
      var(--tw-gradient-to, rgba(27, 229, 109, 0));
  }
  .xxl\:focus\:via-light-slate-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #888b94,
      var(--tw-gradient-to, rgba(136, 139, 148, 0));
  }
  .xxl\:focus\:via-body-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-input-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-background),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-body-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--body-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-header-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--header-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-primary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-secondary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-tertiary:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-primary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--primary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-secondary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--secondary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-tertiary-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--tertiary-hover),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-input-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--input-text),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-success:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--success),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--error),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-custom-loader:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--custom-loader),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-violet:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9421ee,
      var(--tw-gradient-to, rgba(148, 33, 238, 0));
  }
  .xxl\:focus\:via-violet-hover:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #550b8e,
      var(--tw-gradient-to, rgba(85, 11, 142, 0));
  }
  .xxl\:focus\:via-skeleton-loader-from:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-from),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-skeleton-loader-to:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--skeleton-loader-to),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-background50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), var(--background50),
      var(--tw-gradient-to, hsla(0, 0%, 100%, 0));
  }
  .xxl\:focus\:via-reward-blue-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #626bff,
      var(--tw-gradient-to, rgba(98, 107, 255, 0));
  }
  .xxl\:focus\:via-reward-blue-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:via-reward-silver-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #484848,
      var(--tw-gradient-to, rgba(72, 72, 72, 0));
  }
  .xxl\:focus\:via-reward-silver-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #acacac,
      var(--tw-gradient-to, hsla(0, 0%, 67.5%, 0));
  }
  .xxl\:focus\:via-reward-gold-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffdf8a,
      var(--tw-gradient-to, rgba(255, 223, 138, 0));
  }
  .xxl\:focus\:via-reward-gold-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ffc425,
      var(--tw-gradient-to, rgba(255, 196, 37, 0));
  }
  .xxl\:focus\:via-reward-platinum-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #959595,
      var(--tw-gradient-to, hsla(0, 0%, 58.4%, 0));
  }
  .xxl\:focus\:via-reward-platinum-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f8f8f8,
      var(--tw-gradient-to, hsla(0, 0%, 97.3%, 0));
  }
  .xxl\:focus\:via-reward-vip-start:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212eee,
      var(--tw-gradient-to, rgba(33, 46, 238, 0));
  }
  .xxl\:focus\:via-reward-vip-end:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c521ee,
      var(--tw-gradient-to, rgba(197, 33, 238, 0));
  }
  .xxl\:focus\:via-fee-breakdown-bg:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #182139,
      var(--tw-gradient-to, rgba(24, 33, 57, 0));
  }
  .xxl\:focus\:via-light-grey:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xxl\:focus\:via-disabled-button-background:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2a98,
      var(--tw-gradient-to, rgba(31, 42, 152, 0));
  }
  .xxl\:focus\:via-disabled-button-text:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8e93a0,
      var(--tw-gradient-to, rgba(142, 147, 160, 0));
  }
  .xxl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .xxl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .xxl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .xxl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .xxl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .xxl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .xxl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:focus\:to-gray-300:focus {
    --tw-gradient-to: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .xxl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .xxl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .xxl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .xxl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .xxl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #6d728b;
  }
  .xxl\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .xxl\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .xxl\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .xxl\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .xxl\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .xxl\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .xxl\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .xxl\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .xxl\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .xxl\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .xxl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .xxl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .xxl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .xxl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .xxl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .xxl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .xxl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .xxl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .xxl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .xxl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .xxl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .xxl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .xxl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .xxl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .xxl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .xxl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .xxl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .xxl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .xxl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .xxl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .xxl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .xxl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .xxl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .xxl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .xxl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .xxl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .xxl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .xxl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .xxl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .xxl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .xxl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .xxl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .xxl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .xxl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .xxl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .xxl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .xxl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .xxl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .xxl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .xxl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .xxl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .xxl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .xxl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .xxl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .xxl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .xxl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .xxl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .xxl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .xxl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .xxl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .xxl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .xxl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .xxl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .xxl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .xxl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .xxl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .xxl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .xxl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .xxl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .xxl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .xxl\:focus\:to-vivid-blue:focus {
    --tw-gradient-to: #212eee;
  }
  .xxl\:focus\:to-vivid-blue-500:focus {
    --tw-gradient-to: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:to-background-primary:focus {
    --tw-gradient-to: var(--background-primary);
  }
  .xxl\:focus\:to-main-background:focus {
    --tw-gradient-to: #121729;
  }
  .xxl\:focus\:to-dark-blue:focus {
    --tw-gradient-to: #1e2741;
  }
  .xxl\:focus\:to-blue-grey:focus {
    --tw-gradient-to: #2f3a5c;
  }
  .xxl\:focus\:to-text-grey:focus {
    --tw-gradient-to: #a4a7c1;
  }
  .xxl\:focus\:to-pale-grey-two:focus {
    --tw-gradient-to: #eeebff;
  }
  .xxl\:focus\:to-pale-grey:focus {
    --tw-gradient-to: #a8afcc;
  }
  .xxl\:focus\:to-btc:focus {
    --tw-gradient-to: #f7931a;
  }
  .xxl\:focus\:to-btc-symbol:focus {
    --tw-gradient-to: #f5b300;
  }
  .xxl\:focus\:to-paxg:focus {
    --tw-gradient-to: #ffc54e;
  }
  .xxl\:focus\:to-usdc:focus {
    --tw-gradient-to: #2775ca;
  }
  .xxl\:focus\:to-black-navy:focus {
    --tw-gradient-to: #101239;
  }
  .xxl\:focus\:to-ghost-white:focus {
    --tw-gradient-to: #f6f5ff;
  }
  .xxl\:focus\:to-spring-green:focus {
    --tw-gradient-to: #1be56d;
  }
  .xxl\:focus\:to-light-slate-gray:focus {
    --tw-gradient-to: #888b94;
  }
  .xxl\:focus\:to-body-background:focus {
    --tw-gradient-to: var(--body-background);
  }
  .xxl\:focus\:to-background:focus {
    --tw-gradient-to: var(--background);
  }
  .xxl\:focus\:to-input-background:focus {
    --tw-gradient-to: var(--input-background);
  }
  .xxl\:focus\:to-body-text:focus {
    --tw-gradient-to: var(--body-text);
  }
  .xxl\:focus\:to-header-text:focus {
    --tw-gradient-to: var(--header-text);
  }
  .xxl\:focus\:to-primary:focus {
    --tw-gradient-to: var(--primary);
  }
  .xxl\:focus\:to-secondary:focus {
    --tw-gradient-to: var(--secondary);
  }
  .xxl\:focus\:to-tertiary:focus {
    --tw-gradient-to: var(--tertiary);
  }
  .xxl\:focus\:to-primary-hover:focus {
    --tw-gradient-to: var(--primary-hover);
  }
  .xxl\:focus\:to-secondary-hover:focus {
    --tw-gradient-to: var(--secondary-hover);
  }
  .xxl\:focus\:to-tertiary-hover:focus {
    --tw-gradient-to: var(--tertiary-hover);
  }
  .xxl\:focus\:to-input-text:focus {
    --tw-gradient-to: var(--input-text);
  }
  .xxl\:focus\:to-success:focus {
    --tw-gradient-to: var(--success);
  }
  .xxl\:focus\:to-error:focus {
    --tw-gradient-to: var(--error);
  }
  .xxl\:focus\:to-custom-loader:focus {
    --tw-gradient-to: var(--custom-loader);
  }
  .xxl\:focus\:to-violet:focus {
    --tw-gradient-to: #9421ee;
  }
  .xxl\:focus\:to-violet-hover:focus {
    --tw-gradient-to: #550b8e;
  }
  .xxl\:focus\:to-skeleton-loader-from:focus {
    --tw-gradient-to: var(--skeleton-loader-from);
  }
  .xxl\:focus\:to-skeleton-loader-to:focus {
    --tw-gradient-to: var(--skeleton-loader-to);
  }
  .xxl\:focus\:to-background50:focus {
    --tw-gradient-to: var(--background50);
  }
  .xxl\:focus\:to-reward-blue-start:focus {
    --tw-gradient-to: #626bff;
  }
  .xxl\:focus\:to-reward-blue-end:focus {
    --tw-gradient-to: #212eee;
  }
  .xxl\:focus\:to-reward-silver-start:focus {
    --tw-gradient-to: #484848;
  }
  .xxl\:focus\:to-reward-silver-end:focus {
    --tw-gradient-to: #acacac;
  }
  .xxl\:focus\:to-reward-gold-start:focus {
    --tw-gradient-to: #ffdf8a;
  }
  .xxl\:focus\:to-reward-gold-end:focus {
    --tw-gradient-to: #ffc425;
  }
  .xxl\:focus\:to-reward-platinum-start:focus {
    --tw-gradient-to: #959595;
  }
  .xxl\:focus\:to-reward-platinum-end:focus {
    --tw-gradient-to: #f8f8f8;
  }
  .xxl\:focus\:to-reward-vip-start:focus {
    --tw-gradient-to: #212eee;
  }
  .xxl\:focus\:to-reward-vip-end:focus {
    --tw-gradient-to: #c521ee;
  }
  .xxl\:focus\:to-fee-breakdown-bg:focus {
    --tw-gradient-to: #182139;
  }
  .xxl\:focus\:to-light-grey:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xxl\:focus\:to-disabled-button-background:focus {
    --tw-gradient-to: #1f2a98;
  }
  .xxl\:focus\:to-disabled-button-text:focus {
    --tw-gradient-to: #8e93a0;
  }
  .xxl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .xxl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .xxl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .xxl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .xxl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .xxl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .xxl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .xxl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .xxl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .xxl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .xxl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .xxl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .xxl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .xxl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .xxl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .xxl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .xxl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .xxl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .xxl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .xxl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .xxl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .xxl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .xxl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .xxl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .xxl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .xxl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .xxl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .xxl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .xxl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .xxl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .xxl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .xxl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .xxl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .xxl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .xxl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .xxl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .xxl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .xxl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .xxl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .xxl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .xxl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .xxl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .xxl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .xxl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .xxl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .xxl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .xxl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .xxl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .xxl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .xxl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .xxl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .xxl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .xxl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .xxl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .xxl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .xxl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .xxl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .xxl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .xxl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .xxl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .xxl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .xxl\:bg-bottom {
    background-position: bottom;
  }
  .xxl\:bg-center {
    background-position: 50%;
  }
  .xxl\:bg-left {
    background-position: 0;
  }
  .xxl\:bg-left-bottom {
    background-position: 0 100%;
  }
  .xxl\:bg-left-top {
    background-position: 0 0;
  }
  .xxl\:bg-right {
    background-position: 100%;
  }
  .xxl\:bg-right-bottom {
    background-position: 100% 100%;
  }
  .xxl\:bg-right-top {
    background-position: 100% 0;
  }
  .xxl\:bg-top {
    background-position: top;
  }
  .xxl\:bg-repeat {
    background-repeat: repeat;
  }
  .xxl\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .xxl\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .xxl\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .xxl\:bg-repeat-round {
    background-repeat: round;
  }
  .xxl\:bg-repeat-space {
    background-repeat: space;
  }
  .xxl\:bg-auto {
    background-size: auto;
  }
  .xxl\:bg-cover {
    background-size: cover;
  }
  .xxl\:bg-contain {
    background-size: contain;
  }
  .xxl\:border-collapse {
    border-collapse: collapse;
  }
  .xxl\:border-separate {
    border-collapse: separate;
  }
  .xxl\:border-transparent {
    border-color: transparent;
  }
  .xxl\:border-current {
    border-color: currentColor;
  }
  .xxl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxl\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:border-background-primary {
    border-color: var(--background-primary);
  }
  .xxl\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxl\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxl\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxl\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxl\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxl\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxl\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxl\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxl\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxl\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxl\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxl\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxl\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxl\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxl\:border-body-background {
    border-color: var(--body-background);
  }
  .xxl\:border-background {
    border-color: var(--background);
  }
  .xxl\:border-input-background {
    border-color: var(--input-background);
  }
  .xxl\:border-body-text {
    border-color: var(--body-text);
  }
  .xxl\:border-header-text {
    border-color: var(--header-text);
  }
  .xxl\:border-primary {
    border-color: var(--primary);
  }
  .xxl\:border-secondary {
    border-color: var(--secondary);
  }
  .xxl\:border-tertiary {
    border-color: var(--tertiary);
  }
  .xxl\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .xxl\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .xxl\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .xxl\:border-input-text {
    border-color: var(--input-text);
  }
  .xxl\:border-success {
    border-color: var(--success);
  }
  .xxl\:border-error {
    border-color: var(--error);
  }
  .xxl\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .xxl\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxl\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .xxl\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .xxl\:border-background50 {
    border-color: var(--background50);
  }
  .xxl\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxl\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxl\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxl\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxl\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxl\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxl\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxl\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxl\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxl\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .xxl\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .xxl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-300 {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-vivid-blue {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-vivid-blue-500 {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxl\:group-hover\:border-background-primary {
    border-color: var(--background-primary);
  }
  .group:hover .xxl\:group-hover\:border-main-background {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-blue-grey {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-text-grey {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pale-grey-two {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-pale-grey {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-btc {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-btc-symbol {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-paxg {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-usdc {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-black-navy {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-ghost-white {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-spring-green {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-light-slate-gray {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-body-background {
    border-color: var(--body-background);
  }
  .group:hover .xxl\:group-hover\:border-background {
    border-color: var(--background);
  }
  .group:hover .xxl\:group-hover\:border-input-background {
    border-color: var(--input-background);
  }
  .group:hover .xxl\:group-hover\:border-body-text {
    border-color: var(--body-text);
  }
  .group:hover .xxl\:group-hover\:border-header-text {
    border-color: var(--header-text);
  }
  .group:hover .xxl\:group-hover\:border-primary {
    border-color: var(--primary);
  }
  .group:hover .xxl\:group-hover\:border-secondary {
    border-color: var(--secondary);
  }
  .group:hover .xxl\:group-hover\:border-tertiary {
    border-color: var(--tertiary);
  }
  .group:hover .xxl\:group-hover\:border-primary-hover {
    border-color: var(--primary-hover);
  }
  .group:hover .xxl\:group-hover\:border-secondary-hover {
    border-color: var(--secondary-hover);
  }
  .group:hover .xxl\:group-hover\:border-tertiary-hover {
    border-color: var(--tertiary-hover);
  }
  .group:hover .xxl\:group-hover\:border-input-text {
    border-color: var(--input-text);
  }
  .group:hover .xxl\:group-hover\:border-success {
    border-color: var(--success);
  }
  .group:hover .xxl\:group-hover\:border-error {
    border-color: var(--error);
  }
  .group:hover .xxl\:group-hover\:border-custom-loader {
    border-color: var(--custom-loader);
  }
  .group:hover .xxl\:group-hover\:border-violet {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-violet-hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-skeleton-loader-from {
    border-color: var(--skeleton-loader-from);
  }
  .group:hover .xxl\:group-hover\:border-skeleton-loader-to {
    border-color: var(--skeleton-loader-to);
  }
  .group:hover .xxl\:group-hover\:border-background50 {
    border-color: var(--background50);
  }
  .group:hover .xxl\:group-hover\:border-reward-blue-start {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-blue-end {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-silver-start {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-silver-end {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-gold-start {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-gold-end {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-platinum-start {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-platinum-end {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-vip-start {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-reward-vip-end {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-fee-breakdown-bg {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-light-grey {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-disabled-button-background {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .group:hover .xxl\:group-hover\:border-disabled-button-text {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .xxl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .xxl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-300:focus-within {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-vivid-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-vivid-blue-500:focus-within {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus-within\:border-background-primary:focus-within {
    border-color: var(--background-primary);
  }
  .xxl\:focus-within\:border-main-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-dark-blue:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-blue-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-text-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pale-grey-two:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-pale-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-btc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-btc-symbol:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-paxg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-usdc:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-black-navy:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-ghost-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-spring-green:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-light-slate-gray:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-body-background:focus-within {
    border-color: var(--body-background);
  }
  .xxl\:focus-within\:border-background:focus-within {
    border-color: var(--background);
  }
  .xxl\:focus-within\:border-input-background:focus-within {
    border-color: var(--input-background);
  }
  .xxl\:focus-within\:border-body-text:focus-within {
    border-color: var(--body-text);
  }
  .xxl\:focus-within\:border-header-text:focus-within {
    border-color: var(--header-text);
  }
  .xxl\:focus-within\:border-primary:focus-within {
    border-color: var(--primary);
  }
  .xxl\:focus-within\:border-secondary:focus-within {
    border-color: var(--secondary);
  }
  .xxl\:focus-within\:border-tertiary:focus-within {
    border-color: var(--tertiary);
  }
  .xxl\:focus-within\:border-primary-hover:focus-within {
    border-color: var(--primary-hover);
  }
  .xxl\:focus-within\:border-secondary-hover:focus-within {
    border-color: var(--secondary-hover);
  }
  .xxl\:focus-within\:border-tertiary-hover:focus-within {
    border-color: var(--tertiary-hover);
  }
  .xxl\:focus-within\:border-input-text:focus-within {
    border-color: var(--input-text);
  }
  .xxl\:focus-within\:border-success:focus-within {
    border-color: var(--success);
  }
  .xxl\:focus-within\:border-error:focus-within {
    border-color: var(--error);
  }
  .xxl\:focus-within\:border-custom-loader:focus-within {
    border-color: var(--custom-loader);
  }
  .xxl\:focus-within\:border-violet:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-violet-hover:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-skeleton-loader-from:focus-within {
    border-color: var(--skeleton-loader-from);
  }
  .xxl\:focus-within\:border-skeleton-loader-to:focus-within {
    border-color: var(--skeleton-loader-to);
  }
  .xxl\:focus-within\:border-background50:focus-within {
    border-color: var(--background50);
  }
  .xxl\:focus-within\:border-reward-blue-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-blue-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-silver-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-silver-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-gold-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-gold-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-platinum-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-platinum-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-vip-start:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-reward-vip-end:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-fee-breakdown-bg:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-light-grey:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-disabled-button-background:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxl\:focus-within\:border-disabled-button-text:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .xxl\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .xxl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-300:hover {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-vivid-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-vivid-blue-500:hover {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:hover\:border-background-primary:hover {
    border-color: var(--background-primary);
  }
  .xxl\:hover\:border-main-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-dark-blue:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-blue-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-text-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pale-grey-two:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-pale-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-btc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-btc-symbol:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-paxg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-usdc:hover {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-black-navy:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-ghost-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-spring-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-light-slate-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-body-background:hover {
    border-color: var(--body-background);
  }
  .xxl\:hover\:border-background:hover {
    border-color: var(--background);
  }
  .xxl\:hover\:border-input-background:hover {
    border-color: var(--input-background);
  }
  .xxl\:hover\:border-body-text:hover {
    border-color: var(--body-text);
  }
  .xxl\:hover\:border-header-text:hover {
    border-color: var(--header-text);
  }
  .xxl\:hover\:border-primary:hover {
    border-color: var(--primary);
  }
  .xxl\:hover\:border-secondary:hover {
    border-color: var(--secondary);
  }
  .xxl\:hover\:border-tertiary:hover {
    border-color: var(--tertiary);
  }
  .xxl\:hover\:border-primary-hover:hover {
    border-color: var(--primary-hover);
  }
  .xxl\:hover\:border-secondary-hover:hover {
    border-color: var(--secondary-hover);
  }
  .xxl\:hover\:border-tertiary-hover:hover {
    border-color: var(--tertiary-hover);
  }
  .xxl\:hover\:border-input-text:hover {
    border-color: var(--input-text);
  }
  .xxl\:hover\:border-success:hover {
    border-color: var(--success);
  }
  .xxl\:hover\:border-error:hover {
    border-color: var(--error);
  }
  .xxl\:hover\:border-custom-loader:hover {
    border-color: var(--custom-loader);
  }
  .xxl\:hover\:border-violet:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-violet-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-skeleton-loader-from:hover {
    border-color: var(--skeleton-loader-from);
  }
  .xxl\:hover\:border-skeleton-loader-to:hover {
    border-color: var(--skeleton-loader-to);
  }
  .xxl\:hover\:border-background50:hover {
    border-color: var(--background50);
  }
  .xxl\:hover\:border-reward-blue-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-blue-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-silver-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-silver-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-gold-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-gold-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-platinum-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-platinum-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-vip-start:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-reward-vip-end:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-fee-breakdown-bg:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-light-grey:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-disabled-button-background:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxl\:hover\:border-disabled-button-text:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .xxl\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .xxl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-300:focus {
    border-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 114, 139, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-vivid-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-vivid-blue-500:focus {
    border-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:border-background-primary:focus {
    border-color: var(--background-primary);
  }
  .xxl\:focus\:border-main-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(18, 23, 41, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-dark-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 39, 65, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-blue-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(47, 58, 92, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-text-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 167, 193, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pale-grey-two:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 235, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-pale-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(168, 175, 204, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-btc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(247, 147, 26, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-btc-symbol:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 179, 0, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-paxg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 197, 78, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-usdc:focus {
    --tw-border-opacity: 1;
    border-color: rgba(39, 117, 202, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-black-navy:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 18, 57, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-ghost-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(246, 245, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-spring-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 229, 109, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-light-slate-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 139, 148, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-body-background:focus {
    border-color: var(--body-background);
  }
  .xxl\:focus\:border-background:focus {
    border-color: var(--background);
  }
  .xxl\:focus\:border-input-background:focus {
    border-color: var(--input-background);
  }
  .xxl\:focus\:border-body-text:focus {
    border-color: var(--body-text);
  }
  .xxl\:focus\:border-header-text:focus {
    border-color: var(--header-text);
  }
  .xxl\:focus\:border-primary:focus {
    border-color: var(--primary);
  }
  .xxl\:focus\:border-secondary:focus {
    border-color: var(--secondary);
  }
  .xxl\:focus\:border-tertiary:focus {
    border-color: var(--tertiary);
  }
  .xxl\:focus\:border-primary-hover:focus {
    border-color: var(--primary-hover);
  }
  .xxl\:focus\:border-secondary-hover:focus {
    border-color: var(--secondary-hover);
  }
  .xxl\:focus\:border-tertiary-hover:focus {
    border-color: var(--tertiary-hover);
  }
  .xxl\:focus\:border-input-text:focus {
    border-color: var(--input-text);
  }
  .xxl\:focus\:border-success:focus {
    border-color: var(--success);
  }
  .xxl\:focus\:border-error:focus {
    border-color: var(--error);
  }
  .xxl\:focus\:border-custom-loader:focus {
    border-color: var(--custom-loader);
  }
  .xxl\:focus\:border-violet:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-violet-hover:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 11, 142, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-skeleton-loader-from:focus {
    border-color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:border-skeleton-loader-to:focus {
    border-color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:border-background50:focus {
    border-color: var(--background50);
  }
  .xxl\:focus\:border-reward-blue-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 107, 255, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-blue-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-silver-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(72, 72, 72, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-silver-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(172, 172, 172, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-gold-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 223, 138, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-gold-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 37, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-platinum-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 149, 149, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-platinum-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 248, 248, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-vip-start:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 46, 238, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-reward-vip-end:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 33, 238, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-fee-breakdown-bg:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 33, 57, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-light-grey:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-disabled-button-background:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 42, 152, var(--tw-border-opacity));
  }
  .xxl\:focus\:border-disabled-button-text:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 147, 160, var(--tw-border-opacity));
  }
  .xxl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .xxl\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .xxl\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .xxl\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .xxl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .xxl\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .xxl\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .xxl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .xxl\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .xxl\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .xxl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .xxl\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .xxl\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .xxl\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .xxl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .xxl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .xxl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .xxl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .xxl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .xxl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .xxl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .xxl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .xxl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .xxl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .xxl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .xxl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .xxl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .xxl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .xxl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .xxl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .xxl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .xxl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .xxl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .xxl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .xxl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .xxl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .xxl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .xxl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .xxl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .xxl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .xxl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .xxl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .xxl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .xxl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .xxl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .xxl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .xxl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .xxl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .xxl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .xxl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .xxl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .xxl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .xxl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .xxl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .xxl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .xxl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .xxl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .xxl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .xxl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .xxl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .xxl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .xxl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .xxl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .xxl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .xxl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .xxl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .xxl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .xxl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .xxl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .xxl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .xxl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .xxl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .xxl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .xxl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .xxl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .xxl\:rounded-none {
    border-radius: 0;
  }
  .xxl\:rounded-sm {
    border-radius: 0.125rem;
  }
  .xxl\:rounded {
    border-radius: 0.25rem;
  }
  .xxl\:rounded-md {
    border-radius: 0.375rem;
  }
  .xxl\:rounded-lg {
    border-radius: 0.5rem;
  }
  .xxl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xxl\:rounded-2xl {
    border-radius: 1rem;
  }
  .xxl\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .xxl\:rounded-full {
    border-radius: 9999px;
  }
  .xxl\:rounded-card {
    border-radius: 20px;
  }
  .xxl\:rounded-time-item {
    border-radius: 100px;
  }
  .xxl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .xxl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .xxl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xxl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xxl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .xxl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .xxl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xxl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xxl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .xxl\:rounded-r {
    border-top-right-radius: 0.25rem;
  }
  .xxl\:rounded-b,
  .xxl\:rounded-r {
    border-bottom-right-radius: 0.25rem;
  }
  .xxl\:rounded-b,
  .xxl\:rounded-l {
    border-bottom-left-radius: 0.25rem;
  }
  .xxl\:rounded-l {
    border-top-left-radius: 0.25rem;
  }
  .xxl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .xxl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .xxl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xxl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xxl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .xxl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .xxl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xxl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xxl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .xxl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .xxl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xxl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xxl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .xxl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .xxl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xxl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xxl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .xxl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .xxl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xxl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xxl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .xxl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .xxl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xxl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xxl\:rounded-t-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .xxl\:rounded-r-card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .xxl\:rounded-b-card {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xxl\:rounded-l-card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .xxl\:rounded-t-time-item {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  .xxl\:rounded-r-time-item {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .xxl\:rounded-b-time-item {
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xxl\:rounded-l-time-item {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .xxl\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .xxl\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .xxl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .xxl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .xxl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .xxl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .xxl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .xxl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .xxl\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .xxl\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .xxl\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .xxl\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .xxl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .xxl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .xxl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .xxl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .xxl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .xxl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .xxl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .xxl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .xxl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .xxl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .xxl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .xxl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .xxl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .xxl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .xxl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .xxl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .xxl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .xxl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .xxl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .xxl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .xxl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .xxl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .xxl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .xxl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .xxl\:rounded-tl-card {
    border-top-left-radius: 20px;
  }
  .xxl\:rounded-tr-card {
    border-top-right-radius: 20px;
  }
  .xxl\:rounded-br-card {
    border-bottom-right-radius: 20px;
  }
  .xxl\:rounded-bl-card {
    border-bottom-left-radius: 20px;
  }
  .xxl\:rounded-tl-time-item {
    border-top-left-radius: 100px;
  }
  .xxl\:rounded-tr-time-item {
    border-top-right-radius: 100px;
  }
  .xxl\:rounded-br-time-item {
    border-bottom-right-radius: 100px;
  }
  .xxl\:rounded-bl-time-item {
    border-bottom-left-radius: 100px;
  }
  .xxl\:border-solid {
    border-style: solid;
  }
  .xxl\:border-dashed {
    border-style: dashed;
  }
  .xxl\:border-dotted {
    border-style: dotted;
  }
  .xxl\:border-double {
    border-style: double;
  }
  .xxl\:border-none {
    border-style: none;
  }
  .xxl\:border-0 {
    border-width: 0;
  }
  .xxl\:border-2 {
    border-width: 2px;
  }
  .xxl\:border-4 {
    border-width: 4px;
  }
  .xxl\:border-8 {
    border-width: 8px;
  }
  .xxl\:border {
    border-width: 1px;
  }
  .xxl\:border-t-0 {
    border-top-width: 0;
  }
  .xxl\:border-r-0 {
    border-right-width: 0;
  }
  .xxl\:border-b-0 {
    border-bottom-width: 0;
  }
  .xxl\:border-l-0 {
    border-left-width: 0;
  }
  .xxl\:border-t-2 {
    border-top-width: 2px;
  }
  .xxl\:border-r-2 {
    border-right-width: 2px;
  }
  .xxl\:border-b-2 {
    border-bottom-width: 2px;
  }
  .xxl\:border-l-2 {
    border-left-width: 2px;
  }
  .xxl\:border-t-4 {
    border-top-width: 4px;
  }
  .xxl\:border-r-4 {
    border-right-width: 4px;
  }
  .xxl\:border-b-4 {
    border-bottom-width: 4px;
  }
  .xxl\:border-l-4 {
    border-left-width: 4px;
  }
  .xxl\:border-t-8 {
    border-top-width: 8px;
  }
  .xxl\:border-r-8 {
    border-right-width: 8px;
  }
  .xxl\:border-b-8 {
    border-bottom-width: 8px;
  }
  .xxl\:border-l-8 {
    border-left-width: 8px;
  }
  .xxl\:border-t {
    border-top-width: 1px;
  }
  .xxl\:border-r {
    border-right-width: 1px;
  }
  .xxl\:border-b {
    border-bottom-width: 1px;
  }
  .xxl\:border-l {
    border-left-width: 1px;
  }
  .xxl\:box-border {
    box-sizing: border-box;
  }
  .xxl\:box-content {
    box-sizing: content-box;
  }
  .xxl\:cursor-auto {
    cursor: auto;
  }
  .xxl\:cursor-default {
    cursor: default;
  }
  .xxl\:cursor-pointer {
    cursor: pointer;
  }
  .xxl\:cursor-wait {
    cursor: wait;
  }
  .xxl\:cursor-text {
    cursor: text;
  }
  .xxl\:cursor-move {
    cursor: move;
  }
  .xxl\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .xxl\:block {
    display: block;
  }
  .xxl\:inline-block {
    display: inline-block;
  }
  .xxl\:inline {
    display: inline;
  }
  .xxl\:flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xxl\:inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .xxl\:table {
    display: table;
  }
  .xxl\:table-caption {
    display: table-caption;
  }
  .xxl\:table-cell {
    display: table-cell;
  }
  .xxl\:table-column {
    display: table-column;
  }
  .xxl\:table-column-group {
    display: table-column-group;
  }
  .xxl\:table-footer-group {
    display: table-footer-group;
  }
  .xxl\:table-header-group {
    display: table-header-group;
  }
  .xxl\:table-row-group {
    display: table-row-group;
  }
  .xxl\:table-row {
    display: table-row;
  }
  .xxl\:flow-root {
    display: flow-root;
  }
  .xxl\:grid {
    display: grid;
  }
  .xxl\:inline-grid {
    display: inline-grid;
  }
  .xxl\:contents {
    display: contents;
  }
  .xxl\:hidden {
    display: none;
  }
  .xxl\:flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .xxl\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .xxl\:flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xxl\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .xxl\:flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xxl\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .xxl\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .xxl\:place-items-auto {
    place-items: auto;
  }
  .xxl\:place-items-start {
    place-items: start;
  }
  .xxl\:place-items-end {
    place-items: end;
  }
  .xxl\:place-items-center {
    place-items: center;
  }
  .xxl\:place-items-stretch {
    place-items: stretch;
  }
  .xxl\:place-content-center {
    place-content: center;
  }
  .xxl\:place-content-start {
    place-content: start;
  }
  .xxl\:place-content-end {
    place-content: end;
  }
  .xxl\:place-content-between {
    place-content: space-between;
  }
  .xxl\:place-content-around {
    place-content: space-around;
  }
  .xxl\:place-content-evenly {
    place-content: space-evenly;
  }
  .xxl\:place-content-stretch {
    place-content: stretch;
  }
  .xxl\:place-self-auto {
    place-self: auto;
  }
  .xxl\:place-self-start {
    place-self: start;
  }
  .xxl\:place-self-end {
    place-self: end;
  }
  .xxl\:place-self-center {
    place-self: center;
  }
  .xxl\:place-self-stretch {
    place-self: stretch;
  }
  .xxl\:items-start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .xxl\:items-end {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .xxl\:items-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .xxl\:items-baseline {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .xxl\:items-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .xxl\:content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .xxl\:content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .xxl\:content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }
  .xxl\:content-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }
  .xxl\:content-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }
  .xxl\:content-evenly {
    -ms-flex-line-pack: space-evenly;
    -webkit-align-content: space-evenly;
    align-content: space-evenly;
  }
  .xxl\:self-auto {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .xxl\:self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xxl\:self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xxl\:self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xxl\:self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .xxl\:justify-items-auto {
    justify-items: auto;
  }
  .xxl\:justify-items-start {
    justify-items: start;
  }
  .xxl\:justify-items-end {
    justify-items: end;
  }
  .xxl\:justify-items-center {
    justify-items: center;
  }
  .xxl\:justify-items-stretch {
    justify-items: stretch;
  }
  .xxl\:justify-start {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .xxl\:justify-end {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .xxl\:justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .xxl\:justify-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .xxl\:justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .xxl\:justify-evenly {
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .xxl\:justify-self-auto {
    justify-self: auto;
  }
  .xxl\:justify-self-start {
    justify-self: start;
  }
  .xxl\:justify-self-end {
    justify-self: end;
  }
  .xxl\:justify-self-center {
    justify-self: center;
  }
  .xxl\:justify-self-stretch {
    justify-self: stretch;
  }
  .xxl\:flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1;
  }
  .xxl\:flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .xxl\:flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .xxl\:flex-none {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .xxl\:flex-grow-0 {
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .xxl\:flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .xxl\:flex-shrink-0 {
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .xxl\:flex-shrink {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }
  .xxl\:order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .xxl\:order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .xxl\:order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .xxl\:order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .xxl\:order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .xxl\:order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .xxl\:order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .xxl\:order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .xxl\:order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .xxl\:order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .xxl\:order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .xxl\:order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .xxl\:order-first {
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .xxl\:order-last {
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .xxl\:order-none {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .xxl\:float-right {
    float: right;
  }
  .xxl\:float-left {
    float: left;
  }
  .xxl\:float-none {
    float: none;
  }
  .xxl\:clear-left {
    clear: left;
  }
  .xxl\:clear-right {
    clear: right;
  }
  .xxl\:clear-both {
    clear: both;
  }
  .xxl\:clear-none {
    clear: none;
  }
  .xxl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .xxl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .xxl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
  .xxl\:font-numbers {
    font-family: Roboto, sans-serif;
  }
  .xxl\:font-text {
    font-family: Bozon, sans-serif;
  }
  .xxl\:font-thin {
    font-weight: 100;
  }
  .xxl\:font-extralight {
    font-weight: 200;
  }
  .xxl\:font-light {
    font-weight: 300;
  }
  .xxl\:font-normal {
    font-weight: 400;
  }
  .xxl\:font-medium {
    font-weight: 500;
  }
  .xxl\:font-semibold {
    font-weight: 600;
  }
  .xxl\:font-bold {
    font-weight: 700;
  }
  .xxl\:font-extrabold {
    font-weight: 800;
  }
  .xxl\:font-black {
    font-weight: 900;
  }
  .xxl\:hover\:font-thin:hover {
    font-weight: 100;
  }
  .xxl\:hover\:font-extralight:hover {
    font-weight: 200;
  }
  .xxl\:hover\:font-light:hover {
    font-weight: 300;
  }
  .xxl\:hover\:font-normal:hover {
    font-weight: 400;
  }
  .xxl\:hover\:font-medium:hover {
    font-weight: 500;
  }
  .xxl\:hover\:font-semibold:hover {
    font-weight: 600;
  }
  .xxl\:hover\:font-bold:hover {
    font-weight: 700;
  }
  .xxl\:hover\:font-extrabold:hover {
    font-weight: 800;
  }
  .xxl\:hover\:font-black:hover {
    font-weight: 900;
  }
  .xxl\:focus\:font-thin:focus {
    font-weight: 100;
  }
  .xxl\:focus\:font-extralight:focus {
    font-weight: 200;
  }
  .xxl\:focus\:font-light:focus {
    font-weight: 300;
  }
  .xxl\:focus\:font-normal:focus {
    font-weight: 400;
  }
  .xxl\:focus\:font-medium:focus {
    font-weight: 500;
  }
  .xxl\:focus\:font-semibold:focus {
    font-weight: 600;
  }
  .xxl\:focus\:font-bold:focus {
    font-weight: 700;
  }
  .xxl\:focus\:font-extrabold:focus {
    font-weight: 800;
  }
  .xxl\:focus\:font-black:focus {
    font-weight: 900;
  }
  .xxl\:h-0 {
    height: 0;
  }
  .xxl\:h-1 {
    height: 0.25rem;
  }
  .xxl\:h-2 {
    height: 0.5rem;
  }
  .xxl\:h-3 {
    height: 0.75rem;
  }
  .xxl\:h-4 {
    height: 1rem;
  }
  .xxl\:h-5 {
    height: 1.25rem;
  }
  .xxl\:h-6 {
    height: 1.5rem;
  }
  .xxl\:h-7 {
    height: 1.75rem;
  }
  .xxl\:h-8 {
    height: 2rem;
  }
  .xxl\:h-9 {
    height: 2.25rem;
  }
  .xxl\:h-10 {
    height: 2.5rem;
  }
  .xxl\:h-11 {
    height: 2.75rem;
  }
  .xxl\:h-12 {
    height: 3rem;
  }
  .xxl\:h-14 {
    height: 3.5rem;
  }
  .xxl\:h-16 {
    height: 4rem;
  }
  .xxl\:h-20 {
    height: 5rem;
  }
  .xxl\:h-24 {
    height: 6rem;
  }
  .xxl\:h-28 {
    height: 7rem;
  }
  .xxl\:h-32 {
    height: 8rem;
  }
  .xxl\:h-36 {
    height: 9rem;
  }
  .xxl\:h-40 {
    height: 10rem;
  }
  .xxl\:h-44 {
    height: 11rem;
  }
  .xxl\:h-48 {
    height: 12rem;
  }
  .xxl\:h-52 {
    height: 13rem;
  }
  .xxl\:h-56 {
    height: 14rem;
  }
  .xxl\:h-60 {
    height: 15rem;
  }
  .xxl\:h-64 {
    height: 16rem;
  }
  .xxl\:h-72 {
    height: 18rem;
  }
  .xxl\:h-80 {
    height: 20rem;
  }
  .xxl\:h-96 {
    height: 24rem;
  }
  .xxl\:h-105 {
    height: 105px;
  }
  .xxl\:h-188 {
    height: 188px;
  }
  .xxl\:h-344 {
    height: 344px;
  }
  .xxl\:h-425 {
    height: 425px;
  }
  .xxl\:h-627 {
    height: 627px;
  }
  .xxl\:h-auto {
    height: auto;
  }
  .xxl\:h-px {
    height: 1px;
  }
  .xxl\:h-0\.5 {
    height: 0.125rem;
  }
  .xxl\:h-1\.5 {
    height: 0.375rem;
  }
  .xxl\:h-2\.5 {
    height: 0.625rem;
  }
  .xxl\:h-3\.5 {
    height: 0.875rem;
  }
  .xxl\:h-1\/2 {
    height: 50%;
  }
  .xxl\:h-1\/3 {
    height: 33.333333%;
  }
  .xxl\:h-2\/3 {
    height: 66.666667%;
  }
  .xxl\:h-1\/4 {
    height: 25%;
  }
  .xxl\:h-2\/4 {
    height: 50%;
  }
  .xxl\:h-3\/4 {
    height: 75%;
  }
  .xxl\:h-1\/5 {
    height: 20%;
  }
  .xxl\:h-2\/5 {
    height: 40%;
  }
  .xxl\:h-3\/5 {
    height: 60%;
  }
  .xxl\:h-4\/5 {
    height: 80%;
  }
  .xxl\:h-1\/6 {
    height: 16.666667%;
  }
  .xxl\:h-2\/6 {
    height: 33.333333%;
  }
  .xxl\:h-3\/6 {
    height: 50%;
  }
  .xxl\:h-4\/6 {
    height: 66.666667%;
  }
  .xxl\:h-5\/6 {
    height: 83.333333%;
  }
  .xxl\:h-full {
    height: 100%;
  }
  .xxl\:h-screen {
    height: 100vh;
  }
  .xxl\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .xxl\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .xxl\:text-base {
    font-size: 1em;
  }
  .xxl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xxl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .xxl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .xxl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xxl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xxl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xxl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .xxl\:text-7xl {
    font-size: 5rem;
    line-height: 5rem;
  }
  .xxl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .xxl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .xxl\:text-m-sm {
    font-size: 0.8em;
  }
  .xxl\:text-m-lg {
    font-size: 1.0666666666666667em;
  }
  .xxl\:text-m-xl {
    font-size: 1.2em;
  }
  .xxl\:text-m-2xl {
    font-size: 1.3333333333333333em;
  }
  .xxl\:text-m-3xl {
    font-size: 1.4666666666666666em;
  }
  .xxl\:text-m-4xl {
    font-size: 1.6em;
  }
  .xxl\:text-m-5xl {
    font-size: 2.1333333333333333em;
  }
  .xxl\:text-d-sm {
    font-size: 0.875em;
  }
  .xxl\:text-d-lg {
    font-size: 1.125em;
  }
  .xxl\:text-d-xl {
    font-size: 1.375em;
  }
  .xxl\:text-d-2xl {
    font-size: 1.625em;
  }
  .xxl\:text-d-3xl {
    font-size: 1.75em;
  }
  .xxl\:text-d-4xl {
    font-size: 2.25em;
  }
  .xxl\:text-d-5xl {
    font-size: 2.625em;
  }
  .xxl\:text-d-11xl {
    font-size: 16em;
  }
  .xxl\:text-1\.06x1 {
    font-size: 1.0666666666666667em;
  }
  .xxl\:text-1\.46x1 {
    font-size: 1.4666666666666666em;
  }
  .xxl\:text-2\.5x1 {
    font-size: 1.75rem;
  }
  .xxl\:leading-3 {
    line-height: 0.75rem;
  }
  .xxl\:leading-4 {
    line-height: 1rem;
  }
  .xxl\:leading-5 {
    line-height: 1.25rem;
  }
  .xxl\:leading-6 {
    line-height: 1.5rem;
  }
  .xxl\:leading-7 {
    line-height: 1.75rem;
  }
  .xxl\:leading-8 {
    line-height: 2rem;
  }
  .xxl\:leading-9 {
    line-height: 2.25rem;
  }
  .xxl\:leading-10 {
    line-height: 2.5rem;
  }
  .xxl\:leading-none {
    line-height: 1;
  }
  .xxl\:leading-tight {
    line-height: 1.25;
  }
  .xxl\:leading-snug {
    line-height: 1.375;
  }
  .xxl\:leading-normal {
    line-height: 1.5;
  }
  .xxl\:leading-relaxed {
    line-height: 1.625;
  }
  .xxl\:leading-loose {
    line-height: 2;
  }
  .xxl\:list-inside {
    list-style-position: inside;
  }
  .xxl\:list-outside {
    list-style-position: outside;
  }
  .xxl\:list-none {
    list-style-type: none;
  }
  .xxl\:list-disc {
    list-style-type: disc;
  }
  .xxl\:list-decimal {
    list-style-type: decimal;
  }
  .xxl\:m-0 {
    margin: 0;
  }
  .xxl\:m-1 {
    margin: 0.25rem;
  }
  .xxl\:m-2 {
    margin: 0.5rem;
  }
  .xxl\:m-3 {
    margin: 0.75rem;
  }
  .xxl\:m-4 {
    margin: 1rem;
  }
  .xxl\:m-5 {
    margin: 1.25rem;
  }
  .xxl\:m-6 {
    margin: 1.5rem;
  }
  .xxl\:m-7 {
    margin: 1.75rem;
  }
  .xxl\:m-8 {
    margin: 2rem;
  }
  .xxl\:m-9 {
    margin: 2.25rem;
  }
  .xxl\:m-10 {
    margin: 2.5rem;
  }
  .xxl\:m-11 {
    margin: 2.75rem;
  }
  .xxl\:m-12 {
    margin: 3rem;
  }
  .xxl\:m-14 {
    margin: 3.5rem;
  }
  .xxl\:m-16 {
    margin: 4rem;
  }
  .xxl\:m-20 {
    margin: 5rem;
  }
  .xxl\:m-24 {
    margin: 6rem;
  }
  .xxl\:m-28 {
    margin: 7rem;
  }
  .xxl\:m-32 {
    margin: 8rem;
  }
  .xxl\:m-36 {
    margin: 9rem;
  }
  .xxl\:m-40 {
    margin: 10rem;
  }
  .xxl\:m-44 {
    margin: 11rem;
  }
  .xxl\:m-48 {
    margin: 12rem;
  }
  .xxl\:m-52 {
    margin: 13rem;
  }
  .xxl\:m-56 {
    margin: 14rem;
  }
  .xxl\:m-60 {
    margin: 15rem;
  }
  .xxl\:m-64 {
    margin: 16rem;
  }
  .xxl\:m-72 {
    margin: 18rem;
  }
  .xxl\:m-80 {
    margin: 20rem;
  }
  .xxl\:m-96 {
    margin: 24rem;
  }
  .xxl\:m-auto {
    margin: auto;
  }
  .xxl\:m-px {
    margin: 1px;
  }
  .xxl\:m-0\.5 {
    margin: 0.125rem;
  }
  .xxl\:m-1\.5 {
    margin: 0.375rem;
  }
  .xxl\:m-2\.5 {
    margin: 0.625rem;
  }
  .xxl\:m-3\.5 {
    margin: 0.875rem;
  }
  .xxl\:-m-0 {
    margin: 0;
  }
  .xxl\:-m-1 {
    margin: -0.25rem;
  }
  .xxl\:-m-2 {
    margin: -0.5rem;
  }
  .xxl\:-m-3 {
    margin: -0.75rem;
  }
  .xxl\:-m-4 {
    margin: -1rem;
  }
  .xxl\:-m-5 {
    margin: -1.25rem;
  }
  .xxl\:-m-6 {
    margin: -1.5rem;
  }
  .xxl\:-m-7 {
    margin: -1.75rem;
  }
  .xxl\:-m-8 {
    margin: -2rem;
  }
  .xxl\:-m-9 {
    margin: -2.25rem;
  }
  .xxl\:-m-10 {
    margin: -2.5rem;
  }
  .xxl\:-m-11 {
    margin: -2.75rem;
  }
  .xxl\:-m-12 {
    margin: -3rem;
  }
  .xxl\:-m-14 {
    margin: -3.5rem;
  }
  .xxl\:-m-16 {
    margin: -4rem;
  }
  .xxl\:-m-20 {
    margin: -5rem;
  }
  .xxl\:-m-24 {
    margin: -6rem;
  }
  .xxl\:-m-28 {
    margin: -7rem;
  }
  .xxl\:-m-32 {
    margin: -8rem;
  }
  .xxl\:-m-36 {
    margin: -9rem;
  }
  .xxl\:-m-40 {
    margin: -10rem;
  }
  .xxl\:-m-44 {
    margin: -11rem;
  }
  .xxl\:-m-48 {
    margin: -12rem;
  }
  .xxl\:-m-52 {
    margin: -13rem;
  }
  .xxl\:-m-56 {
    margin: -14rem;
  }
  .xxl\:-m-60 {
    margin: -15rem;
  }
  .xxl\:-m-64 {
    margin: -16rem;
  }
  .xxl\:-m-72 {
    margin: -18rem;
  }
  .xxl\:-m-80 {
    margin: -20rem;
  }
  .xxl\:-m-96 {
    margin: -24rem;
  }
  .xxl\:-m-px {
    margin: -1px;
  }
  .xxl\:-m-0\.5 {
    margin: -0.125rem;
  }
  .xxl\:-m-1\.5 {
    margin: -0.375rem;
  }
  .xxl\:-m-2\.5 {
    margin: -0.625rem;
  }
  .xxl\:-m-3\.5 {
    margin: -0.875rem;
  }
  .xxl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xxl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xxl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xxl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xxl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xxl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xxl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xxl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xxl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xxl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xxl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xxl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xxl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xxl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xxl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xxl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xxl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xxl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xxl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xxl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .xxl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .xxl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .xxl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .xxl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xxl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xxl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .xxl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .xxl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xxl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xxl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .xxl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .xxl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .xxl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .xxl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .xxl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .xxl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xxl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xxl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .xxl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .xxl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .xxl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .xxl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .xxl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .xxl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .xxl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .xxl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .xxl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .xxl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .xxl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .xxl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .xxl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .xxl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .xxl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .xxl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .xxl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .xxl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .xxl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .xxl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .xxl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .xxl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xxl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xxl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .xxl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .xxl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .xxl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .xxl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xxl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xxl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xxl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xxl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xxl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xxl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xxl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xxl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .xxl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .xxl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .xxl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xxl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .xxl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .xxl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xxl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xxl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .xxl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .xxl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .xxl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .xxl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .xxl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .xxl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .xxl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xxl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .xxl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .xxl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .xxl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .xxl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .xxl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .xxl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .xxl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .xxl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .xxl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .xxl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .xxl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xxl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .xxl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .xxl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .xxl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .xxl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .xxl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .xxl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .xxl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xxl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .xxl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .xxl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .xxl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .xxl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .xxl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .xxl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .xxl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .xxl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .xxl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .xxl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .xxl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .xxl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .xxl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .xxl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .xxl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .xxl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .xxl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .xxl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .xxl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .xxl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .xxl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .xxl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xxl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .xxl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .xxl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .xxl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .xxl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .xxl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .xxl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .xxl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .xxl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .xxl\:mt-0 {
    margin-top: 0;
  }
  .xxl\:mr-0 {
    margin-right: 0;
  }
  .xxl\:mb-0 {
    margin-bottom: 0;
  }
  .xxl\:ml-0 {
    margin-left: 0;
  }
  .xxl\:mt-1 {
    margin-top: 0.25rem;
  }
  .xxl\:mr-1 {
    margin-right: 0.25rem;
  }
  .xxl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xxl\:ml-1 {
    margin-left: 0.25rem;
  }
  .xxl\:mt-2 {
    margin-top: 0.5rem;
  }
  .xxl\:mr-2 {
    margin-right: 0.5rem;
  }
  .xxl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xxl\:ml-2 {
    margin-left: 0.5rem;
  }
  .xxl\:mt-3 {
    margin-top: 0.75rem;
  }
  .xxl\:mr-3 {
    margin-right: 0.75rem;
  }
  .xxl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xxl\:ml-3 {
    margin-left: 0.75rem;
  }
  .xxl\:mt-4 {
    margin-top: 1rem;
  }
  .xxl\:mr-4 {
    margin-right: 1rem;
  }
  .xxl\:mb-4 {
    margin-bottom: 1rem;
  }
  .xxl\:ml-4 {
    margin-left: 1rem;
  }
  .xxl\:mt-5 {
    margin-top: 1.25rem;
  }
  .xxl\:mr-5 {
    margin-right: 1.25rem;
  }
  .xxl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xxl\:ml-5 {
    margin-left: 1.25rem;
  }
  .xxl\:mt-6 {
    margin-top: 1.5rem;
  }
  .xxl\:mr-6 {
    margin-right: 1.5rem;
  }
  .xxl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xxl\:ml-6 {
    margin-left: 1.5rem;
  }
  .xxl\:mt-7 {
    margin-top: 1.75rem;
  }
  .xxl\:mr-7 {
    margin-right: 1.75rem;
  }
  .xxl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xxl\:ml-7 {
    margin-left: 1.75rem;
  }
  .xxl\:mt-8 {
    margin-top: 2rem;
  }
  .xxl\:mr-8 {
    margin-right: 2rem;
  }
  .xxl\:mb-8 {
    margin-bottom: 2rem;
  }
  .xxl\:ml-8 {
    margin-left: 2rem;
  }
  .xxl\:mt-9 {
    margin-top: 2.25rem;
  }
  .xxl\:mr-9 {
    margin-right: 2.25rem;
  }
  .xxl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xxl\:ml-9 {
    margin-left: 2.25rem;
  }
  .xxl\:mt-10 {
    margin-top: 2.5rem;
  }
  .xxl\:mr-10 {
    margin-right: 2.5rem;
  }
  .xxl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xxl\:ml-10 {
    margin-left: 2.5rem;
  }
  .xxl\:mt-11 {
    margin-top: 2.75rem;
  }
  .xxl\:mr-11 {
    margin-right: 2.75rem;
  }
  .xxl\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .xxl\:ml-11 {
    margin-left: 2.75rem;
  }
  .xxl\:mt-12 {
    margin-top: 3rem;
  }
  .xxl\:mr-12 {
    margin-right: 3rem;
  }
  .xxl\:mb-12 {
    margin-bottom: 3rem;
  }
  .xxl\:ml-12 {
    margin-left: 3rem;
  }
  .xxl\:mt-14 {
    margin-top: 3.5rem;
  }
  .xxl\:mr-14 {
    margin-right: 3.5rem;
  }
  .xxl\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .xxl\:ml-14 {
    margin-left: 3.5rem;
  }
  .xxl\:mt-16 {
    margin-top: 4rem;
  }
  .xxl\:mr-16 {
    margin-right: 4rem;
  }
  .xxl\:mb-16 {
    margin-bottom: 4rem;
  }
  .xxl\:ml-16 {
    margin-left: 4rem;
  }
  .xxl\:mt-20 {
    margin-top: 5rem;
  }
  .xxl\:mr-20 {
    margin-right: 5rem;
  }
  .xxl\:mb-20 {
    margin-bottom: 5rem;
  }
  .xxl\:ml-20 {
    margin-left: 5rem;
  }
  .xxl\:mt-24 {
    margin-top: 6rem;
  }
  .xxl\:mr-24 {
    margin-right: 6rem;
  }
  .xxl\:mb-24 {
    margin-bottom: 6rem;
  }
  .xxl\:ml-24 {
    margin-left: 6rem;
  }
  .xxl\:mt-28 {
    margin-top: 7rem;
  }
  .xxl\:mr-28 {
    margin-right: 7rem;
  }
  .xxl\:mb-28 {
    margin-bottom: 7rem;
  }
  .xxl\:ml-28 {
    margin-left: 7rem;
  }
  .xxl\:mt-32 {
    margin-top: 8rem;
  }
  .xxl\:mr-32 {
    margin-right: 8rem;
  }
  .xxl\:mb-32 {
    margin-bottom: 8rem;
  }
  .xxl\:ml-32 {
    margin-left: 8rem;
  }
  .xxl\:mt-36 {
    margin-top: 9rem;
  }
  .xxl\:mr-36 {
    margin-right: 9rem;
  }
  .xxl\:mb-36 {
    margin-bottom: 9rem;
  }
  .xxl\:ml-36 {
    margin-left: 9rem;
  }
  .xxl\:mt-40 {
    margin-top: 10rem;
  }
  .xxl\:mr-40 {
    margin-right: 10rem;
  }
  .xxl\:mb-40 {
    margin-bottom: 10rem;
  }
  .xxl\:ml-40 {
    margin-left: 10rem;
  }
  .xxl\:mt-44 {
    margin-top: 11rem;
  }
  .xxl\:mr-44 {
    margin-right: 11rem;
  }
  .xxl\:mb-44 {
    margin-bottom: 11rem;
  }
  .xxl\:ml-44 {
    margin-left: 11rem;
  }
  .xxl\:mt-48 {
    margin-top: 12rem;
  }
  .xxl\:mr-48 {
    margin-right: 12rem;
  }
  .xxl\:mb-48 {
    margin-bottom: 12rem;
  }
  .xxl\:ml-48 {
    margin-left: 12rem;
  }
  .xxl\:mt-52 {
    margin-top: 13rem;
  }
  .xxl\:mr-52 {
    margin-right: 13rem;
  }
  .xxl\:mb-52 {
    margin-bottom: 13rem;
  }
  .xxl\:ml-52 {
    margin-left: 13rem;
  }
  .xxl\:mt-56 {
    margin-top: 14rem;
  }
  .xxl\:mr-56 {
    margin-right: 14rem;
  }
  .xxl\:mb-56 {
    margin-bottom: 14rem;
  }
  .xxl\:ml-56 {
    margin-left: 14rem;
  }
  .xxl\:mt-60 {
    margin-top: 15rem;
  }
  .xxl\:mr-60 {
    margin-right: 15rem;
  }
  .xxl\:mb-60 {
    margin-bottom: 15rem;
  }
  .xxl\:ml-60 {
    margin-left: 15rem;
  }
  .xxl\:mt-64 {
    margin-top: 16rem;
  }
  .xxl\:mr-64 {
    margin-right: 16rem;
  }
  .xxl\:mb-64 {
    margin-bottom: 16rem;
  }
  .xxl\:ml-64 {
    margin-left: 16rem;
  }
  .xxl\:mt-72 {
    margin-top: 18rem;
  }
  .xxl\:mr-72 {
    margin-right: 18rem;
  }
  .xxl\:mb-72 {
    margin-bottom: 18rem;
  }
  .xxl\:ml-72 {
    margin-left: 18rem;
  }
  .xxl\:mt-80 {
    margin-top: 20rem;
  }
  .xxl\:mr-80 {
    margin-right: 20rem;
  }
  .xxl\:mb-80 {
    margin-bottom: 20rem;
  }
  .xxl\:ml-80 {
    margin-left: 20rem;
  }
  .xxl\:mt-96 {
    margin-top: 24rem;
  }
  .xxl\:mr-96 {
    margin-right: 24rem;
  }
  .xxl\:mb-96 {
    margin-bottom: 24rem;
  }
  .xxl\:ml-96 {
    margin-left: 24rem;
  }
  .xxl\:mt-auto {
    margin-top: auto;
  }
  .xxl\:mr-auto {
    margin-right: auto;
  }
  .xxl\:mb-auto {
    margin-bottom: auto;
  }
  .xxl\:ml-auto {
    margin-left: auto;
  }
  .xxl\:mt-px {
    margin-top: 1px;
  }
  .xxl\:mr-px {
    margin-right: 1px;
  }
  .xxl\:mb-px {
    margin-bottom: 1px;
  }
  .xxl\:ml-px {
    margin-left: 1px;
  }
  .xxl\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .xxl\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .xxl\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .xxl\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .xxl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xxl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xxl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xxl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xxl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xxl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xxl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xxl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xxl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xxl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xxl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xxl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xxl\:-mt-0 {
    margin-top: 0;
  }
  .xxl\:-mr-0 {
    margin-right: 0;
  }
  .xxl\:-mb-0 {
    margin-bottom: 0;
  }
  .xxl\:-ml-0 {
    margin-left: 0;
  }
  .xxl\:-mt-1 {
    margin-top: -0.25rem;
  }
  .xxl\:-mr-1 {
    margin-right: -0.25rem;
  }
  .xxl\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .xxl\:-ml-1 {
    margin-left: -0.25rem;
  }
  .xxl\:-mt-2 {
    margin-top: -0.5rem;
  }
  .xxl\:-mr-2 {
    margin-right: -0.5rem;
  }
  .xxl\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .xxl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xxl\:-mt-3 {
    margin-top: -0.75rem;
  }
  .xxl\:-mr-3 {
    margin-right: -0.75rem;
  }
  .xxl\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .xxl\:-ml-3 {
    margin-left: -0.75rem;
  }
  .xxl\:-mt-4 {
    margin-top: -1rem;
  }
  .xxl\:-mr-4 {
    margin-right: -1rem;
  }
  .xxl\:-mb-4 {
    margin-bottom: -1rem;
  }
  .xxl\:-ml-4 {
    margin-left: -1rem;
  }
  .xxl\:-mt-5 {
    margin-top: -1.25rem;
  }
  .xxl\:-mr-5 {
    margin-right: -1.25rem;
  }
  .xxl\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .xxl\:-ml-5 {
    margin-left: -1.25rem;
  }
  .xxl\:-mt-6 {
    margin-top: -1.5rem;
  }
  .xxl\:-mr-6 {
    margin-right: -1.5rem;
  }
  .xxl\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .xxl\:-ml-6 {
    margin-left: -1.5rem;
  }
  .xxl\:-mt-7 {
    margin-top: -1.75rem;
  }
  .xxl\:-mr-7 {
    margin-right: -1.75rem;
  }
  .xxl\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .xxl\:-ml-7 {
    margin-left: -1.75rem;
  }
  .xxl\:-mt-8 {
    margin-top: -2rem;
  }
  .xxl\:-mr-8 {
    margin-right: -2rem;
  }
  .xxl\:-mb-8 {
    margin-bottom: -2rem;
  }
  .xxl\:-ml-8 {
    margin-left: -2rem;
  }
  .xxl\:-mt-9 {
    margin-top: -2.25rem;
  }
  .xxl\:-mr-9 {
    margin-right: -2.25rem;
  }
  .xxl\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .xxl\:-ml-9 {
    margin-left: -2.25rem;
  }
  .xxl\:-mt-10 {
    margin-top: -2.5rem;
  }
  .xxl\:-mr-10 {
    margin-right: -2.5rem;
  }
  .xxl\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .xxl\:-ml-10 {
    margin-left: -2.5rem;
  }
  .xxl\:-mt-11 {
    margin-top: -2.75rem;
  }
  .xxl\:-mr-11 {
    margin-right: -2.75rem;
  }
  .xxl\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .xxl\:-ml-11 {
    margin-left: -2.75rem;
  }
  .xxl\:-mt-12 {
    margin-top: -3rem;
  }
  .xxl\:-mr-12 {
    margin-right: -3rem;
  }
  .xxl\:-mb-12 {
    margin-bottom: -3rem;
  }
  .xxl\:-ml-12 {
    margin-left: -3rem;
  }
  .xxl\:-mt-14 {
    margin-top: -3.5rem;
  }
  .xxl\:-mr-14 {
    margin-right: -3.5rem;
  }
  .xxl\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .xxl\:-ml-14 {
    margin-left: -3.5rem;
  }
  .xxl\:-mt-16 {
    margin-top: -4rem;
  }
  .xxl\:-mr-16 {
    margin-right: -4rem;
  }
  .xxl\:-mb-16 {
    margin-bottom: -4rem;
  }
  .xxl\:-ml-16 {
    margin-left: -4rem;
  }
  .xxl\:-mt-20 {
    margin-top: -5rem;
  }
  .xxl\:-mr-20 {
    margin-right: -5rem;
  }
  .xxl\:-mb-20 {
    margin-bottom: -5rem;
  }
  .xxl\:-ml-20 {
    margin-left: -5rem;
  }
  .xxl\:-mt-24 {
    margin-top: -6rem;
  }
  .xxl\:-mr-24 {
    margin-right: -6rem;
  }
  .xxl\:-mb-24 {
    margin-bottom: -6rem;
  }
  .xxl\:-ml-24 {
    margin-left: -6rem;
  }
  .xxl\:-mt-28 {
    margin-top: -7rem;
  }
  .xxl\:-mr-28 {
    margin-right: -7rem;
  }
  .xxl\:-mb-28 {
    margin-bottom: -7rem;
  }
  .xxl\:-ml-28 {
    margin-left: -7rem;
  }
  .xxl\:-mt-32 {
    margin-top: -8rem;
  }
  .xxl\:-mr-32 {
    margin-right: -8rem;
  }
  .xxl\:-mb-32 {
    margin-bottom: -8rem;
  }
  .xxl\:-ml-32 {
    margin-left: -8rem;
  }
  .xxl\:-mt-36 {
    margin-top: -9rem;
  }
  .xxl\:-mr-36 {
    margin-right: -9rem;
  }
  .xxl\:-mb-36 {
    margin-bottom: -9rem;
  }
  .xxl\:-ml-36 {
    margin-left: -9rem;
  }
  .xxl\:-mt-40 {
    margin-top: -10rem;
  }
  .xxl\:-mr-40 {
    margin-right: -10rem;
  }
  .xxl\:-mb-40 {
    margin-bottom: -10rem;
  }
  .xxl\:-ml-40 {
    margin-left: -10rem;
  }
  .xxl\:-mt-44 {
    margin-top: -11rem;
  }
  .xxl\:-mr-44 {
    margin-right: -11rem;
  }
  .xxl\:-mb-44 {
    margin-bottom: -11rem;
  }
  .xxl\:-ml-44 {
    margin-left: -11rem;
  }
  .xxl\:-mt-48 {
    margin-top: -12rem;
  }
  .xxl\:-mr-48 {
    margin-right: -12rem;
  }
  .xxl\:-mb-48 {
    margin-bottom: -12rem;
  }
  .xxl\:-ml-48 {
    margin-left: -12rem;
  }
  .xxl\:-mt-52 {
    margin-top: -13rem;
  }
  .xxl\:-mr-52 {
    margin-right: -13rem;
  }
  .xxl\:-mb-52 {
    margin-bottom: -13rem;
  }
  .xxl\:-ml-52 {
    margin-left: -13rem;
  }
  .xxl\:-mt-56 {
    margin-top: -14rem;
  }
  .xxl\:-mr-56 {
    margin-right: -14rem;
  }
  .xxl\:-mb-56 {
    margin-bottom: -14rem;
  }
  .xxl\:-ml-56 {
    margin-left: -14rem;
  }
  .xxl\:-mt-60 {
    margin-top: -15rem;
  }
  .xxl\:-mr-60 {
    margin-right: -15rem;
  }
  .xxl\:-mb-60 {
    margin-bottom: -15rem;
  }
  .xxl\:-ml-60 {
    margin-left: -15rem;
  }
  .xxl\:-mt-64 {
    margin-top: -16rem;
  }
  .xxl\:-mr-64 {
    margin-right: -16rem;
  }
  .xxl\:-mb-64 {
    margin-bottom: -16rem;
  }
  .xxl\:-ml-64 {
    margin-left: -16rem;
  }
  .xxl\:-mt-72 {
    margin-top: -18rem;
  }
  .xxl\:-mr-72 {
    margin-right: -18rem;
  }
  .xxl\:-mb-72 {
    margin-bottom: -18rem;
  }
  .xxl\:-ml-72 {
    margin-left: -18rem;
  }
  .xxl\:-mt-80 {
    margin-top: -20rem;
  }
  .xxl\:-mr-80 {
    margin-right: -20rem;
  }
  .xxl\:-mb-80 {
    margin-bottom: -20rem;
  }
  .xxl\:-ml-80 {
    margin-left: -20rem;
  }
  .xxl\:-mt-96 {
    margin-top: -24rem;
  }
  .xxl\:-mr-96 {
    margin-right: -24rem;
  }
  .xxl\:-mb-96 {
    margin-bottom: -24rem;
  }
  .xxl\:-ml-96 {
    margin-left: -24rem;
  }
  .xxl\:-mt-px {
    margin-top: -1px;
  }
  .xxl\:-mr-px {
    margin-right: -1px;
  }
  .xxl\:-mb-px {
    margin-bottom: -1px;
  }
  .xxl\:-ml-px {
    margin-left: -1px;
  }
  .xxl\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .xxl\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .xxl\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .xxl\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .xxl\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .xxl\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .xxl\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .xxl\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .xxl\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .xxl\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .xxl\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .xxl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xxl\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .xxl\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .xxl\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .xxl\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .xxl\:max-h-0 {
    max-height: 0;
  }
  .xxl\:max-h-1 {
    max-height: 0.25rem;
  }
  .xxl\:max-h-2 {
    max-height: 0.5rem;
  }
  .xxl\:max-h-3 {
    max-height: 0.75rem;
  }
  .xxl\:max-h-4 {
    max-height: 1rem;
  }
  .xxl\:max-h-5 {
    max-height: 1.25rem;
  }
  .xxl\:max-h-6 {
    max-height: 1.5rem;
  }
  .xxl\:max-h-7 {
    max-height: 1.75rem;
  }
  .xxl\:max-h-8 {
    max-height: 2rem;
  }
  .xxl\:max-h-9 {
    max-height: 2.25rem;
  }
  .xxl\:max-h-10 {
    max-height: 2.5rem;
  }
  .xxl\:max-h-11 {
    max-height: 2.75rem;
  }
  .xxl\:max-h-12 {
    max-height: 3rem;
  }
  .xxl\:max-h-14 {
    max-height: 3.5rem;
  }
  .xxl\:max-h-16 {
    max-height: 4rem;
  }
  .xxl\:max-h-20 {
    max-height: 5rem;
  }
  .xxl\:max-h-24 {
    max-height: 6rem;
  }
  .xxl\:max-h-28 {
    max-height: 7rem;
  }
  .xxl\:max-h-32 {
    max-height: 8rem;
  }
  .xxl\:max-h-36 {
    max-height: 9rem;
  }
  .xxl\:max-h-40 {
    max-height: 10rem;
  }
  .xxl\:max-h-44 {
    max-height: 11rem;
  }
  .xxl\:max-h-48 {
    max-height: 12rem;
  }
  .xxl\:max-h-52 {
    max-height: 13rem;
  }
  .xxl\:max-h-56 {
    max-height: 14rem;
  }
  .xxl\:max-h-60 {
    max-height: 15rem;
  }
  .xxl\:max-h-64 {
    max-height: 16rem;
  }
  .xxl\:max-h-72 {
    max-height: 18rem;
  }
  .xxl\:max-h-80 {
    max-height: 20rem;
  }
  .xxl\:max-h-96 {
    max-height: 24rem;
  }
  .xxl\:max-h-px {
    max-height: 1px;
  }
  .xxl\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .xxl\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .xxl\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .xxl\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .xxl\:max-h-full {
    max-height: 100%;
  }
  .xxl\:max-h-screen {
    max-height: 100vh;
  }
  .xxl\:max-h-1\/4 {
    max-height: 25%;
  }
  .xxl\:max-h-1\/2 {
    max-height: 50%;
  }
  .xxl\:max-h-3\/4 {
    max-height: 75%;
  }
  .xxl\:max-h-10\/12 {
    max-height: 80%;
  }
  .xxl\:max-h-enormous {
    max-height: 4000px;
  }
  .xxl\:max-w-0 {
    max-width: 0;
  }
  .xxl\:max-w-110 {
    max-width: 110px;
  }
  .xxl\:max-w-473 {
    max-width: 473px;
  }
  .xxl\:max-w-none {
    max-width: none;
  }
  .xxl\:max-w-xs {
    max-width: 20rem;
  }
  .xxl\:max-w-sm {
    max-width: 24rem;
  }
  .xxl\:max-w-md {
    max-width: 28rem;
  }
  .xxl\:max-w-lg {
    max-width: 32rem;
  }
  .xxl\:max-w-xl {
    max-width: 36rem;
  }
  .xxl\:max-w-2xl {
    max-width: 42rem;
  }
  .xxl\:max-w-3xl {
    max-width: 48rem;
  }
  .xxl\:max-w-4xl {
    max-width: 56rem;
  }
  .xxl\:max-w-5xl {
    max-width: 64rem;
  }
  .xxl\:max-w-6xl {
    max-width: 72rem;
  }
  .xxl\:max-w-7xl {
    max-width: 80rem;
  }
  .xxl\:max-w-full {
    max-width: 100%;
  }
  .xxl\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }
  .xxl\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .xxl\:max-w-prose {
    max-width: 65ch;
  }
  .xxl\:max-w-screen-sm {
    max-width: 640px;
  }
  .xxl\:max-w-screen-md {
    max-width: 768px;
  }
  .xxl\:max-w-screen-lg {
    max-width: 1024px;
  }
  .xxl\:max-w-screen-xl {
    max-width: 1280px;
  }
  .xxl\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .xxl\:max-w-screen-xxs {
    max-width: 260px;
  }
  .xxl\:max-w-screen-xs {
    max-width: 360px;
  }
  .xxl\:max-w-screen-xxl {
    max-width: 1440px;
  }
  .xxl\:min-h-0 {
    min-height: 0;
  }
  .xxl\:min-h-full {
    min-height: 100%;
  }
  .xxl\:min-h-screen {
    min-height: 100vh;
  }
  .xxl\:min-w-0 {
    min-width: 0;
  }
  .xxl\:min-w-10 {
    min-width: 2.5rem;
  }
  .xxl\:min-w-12 {
    min-width: 3rem;
  }
  .xxl\:min-w-14 {
    min-width: 3.5rem;
  }
  .xxl\:min-w-full {
    min-width: 100%;
  }
  .xxl\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content;
  }
  .xxl\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  .xxl\:object-contain {
    object-fit: contain;
  }
  .xxl\:object-cover {
    object-fit: cover;
  }
  .xxl\:object-fill {
    object-fit: fill;
  }
  .xxl\:object-none {
    object-fit: none;
  }
  .xxl\:object-scale-down {
    object-fit: scale-down;
  }
  .xxl\:object-bottom {
    object-position: bottom;
  }
  .xxl\:object-center {
    object-position: center;
  }
  .xxl\:object-left {
    object-position: left;
  }
  .xxl\:object-left-bottom {
    object-position: left bottom;
  }
  .xxl\:object-left-top {
    object-position: left top;
  }
  .xxl\:object-right {
    object-position: right;
  }
  .xxl\:object-right-bottom {
    object-position: right bottom;
  }
  .xxl\:object-right-top {
    object-position: right top;
  }
  .xxl\:object-top {
    object-position: top;
  }
  .xxl\:opacity-0 {
    opacity: 0;
  }
  .xxl\:opacity-5 {
    opacity: 0.05;
  }
  .xxl\:opacity-10 {
    opacity: 0.1;
  }
  .xxl\:opacity-20 {
    opacity: 0.2;
  }
  .xxl\:opacity-25 {
    opacity: 0.25;
  }
  .xxl\:opacity-30 {
    opacity: 0.3;
  }
  .xxl\:opacity-40 {
    opacity: 0.4;
  }
  .xxl\:opacity-50 {
    opacity: 0.5;
  }
  .xxl\:opacity-60 {
    opacity: 0.6;
  }
  .xxl\:opacity-70 {
    opacity: 0.7;
  }
  .xxl\:opacity-75 {
    opacity: 0.75;
  }
  .xxl\:opacity-80 {
    opacity: 0.8;
  }
  .xxl\:opacity-90 {
    opacity: 0.9;
  }
  .xxl\:opacity-95 {
    opacity: 0.95;
  }
  .xxl\:opacity-100 {
    opacity: 1;
  }
  .group:hover .xxl\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .xxl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .xxl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .xxl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .xxl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .xxl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .xxl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .xxl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .xxl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .xxl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .xxl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .xxl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .xxl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .xxl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .xxl\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .xxl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .xxl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .xxl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .xxl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .xxl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .xxl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .xxl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .xxl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .xxl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .xxl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .xxl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .xxl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .xxl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .xxl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .xxl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .xxl\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .xxl\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .xxl\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .xxl\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .xxl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .xxl\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .xxl\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .xxl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .xxl\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .xxl\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .xxl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .xxl\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .xxl\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .xxl\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .xxl\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .xxl\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .xxl\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .xxl\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .xxl\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .xxl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .xxl\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .xxl\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .xxl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .xxl\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .xxl\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .xxl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .xxl\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .xxl\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .xxl\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .xxl\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .xxl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxl\:outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxl\:outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xxl\:focus\:outline-white:focus {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
  .xxl\:focus\:outline-black:focus {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }
  .xxl\:overflow-auto {
    overflow: auto;
  }
  .xxl\:overflow-hidden {
    overflow: hidden;
  }
  .xxl\:overflow-visible {
    overflow: visible;
  }
  .xxl\:overflow-scroll {
    overflow: scroll;
  }
  .xxl\:overflow-x-auto {
    overflow-x: auto;
  }
  .xxl\:overflow-y-auto {
    overflow-y: auto;
  }
  .xxl\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .xxl\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .xxl\:overflow-x-visible {
    overflow-x: visible;
  }
  .xxl\:overflow-y-visible {
    overflow-y: visible;
  }
  .xxl\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .xxl\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .xxl\:overscroll-auto {
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
  }
  .xxl\:overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  .xxl\:overscroll-none {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .xxl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .xxl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .xxl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .xxl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .xxl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .xxl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .xxl\:p-0 {
    padding: 0;
  }
  .xxl\:p-1 {
    padding: 0.25rem;
  }
  .xxl\:p-2 {
    padding: 0.5rem;
  }
  .xxl\:p-3 {
    padding: 0.75rem;
  }
  .xxl\:p-4 {
    padding: 1rem;
  }
  .xxl\:p-5 {
    padding: 1.25rem;
  }
  .xxl\:p-6 {
    padding: 1.5rem;
  }
  .xxl\:p-7 {
    padding: 1.75rem;
  }
  .xxl\:p-8 {
    padding: 2rem;
  }
  .xxl\:p-9 {
    padding: 2.25rem;
  }
  .xxl\:p-10 {
    padding: 2.5rem;
  }
  .xxl\:p-11 {
    padding: 2.75rem;
  }
  .xxl\:p-12 {
    padding: 3rem;
  }
  .xxl\:p-14 {
    padding: 3.5rem;
  }
  .xxl\:p-16 {
    padding: 4rem;
  }
  .xxl\:p-20 {
    padding: 5rem;
  }
  .xxl\:p-24 {
    padding: 6rem;
  }
  .xxl\:p-28 {
    padding: 7rem;
  }
  .xxl\:p-32 {
    padding: 8rem;
  }
  .xxl\:p-36 {
    padding: 9rem;
  }
  .xxl\:p-40 {
    padding: 10rem;
  }
  .xxl\:p-44 {
    padding: 11rem;
  }
  .xxl\:p-48 {
    padding: 12rem;
  }
  .xxl\:p-52 {
    padding: 13rem;
  }
  .xxl\:p-56 {
    padding: 14rem;
  }
  .xxl\:p-60 {
    padding: 15rem;
  }
  .xxl\:p-64 {
    padding: 16rem;
  }
  .xxl\:p-72 {
    padding: 18rem;
  }
  .xxl\:p-80 {
    padding: 20rem;
  }
  .xxl\:p-96 {
    padding: 24rem;
  }
  .xxl\:p-px {
    padding: 1px;
  }
  .xxl\:p-0\.5 {
    padding: 0.125rem;
  }
  .xxl\:p-1\.5 {
    padding: 0.375rem;
  }
  .xxl\:p-2\.5 {
    padding: 0.625rem;
  }
  .xxl\:p-3\.5 {
    padding: 0.875rem;
  }
  .xxl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xxl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xxl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xxl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xxl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xxl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xxl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xxl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xxl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xxl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xxl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xxl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xxl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xxl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xxl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xxl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xxl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xxl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xxl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xxl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xxl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xxl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xxl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xxl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xxl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xxl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xxl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xxl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xxl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xxl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xxl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xxl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xxl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xxl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xxl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .xxl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .xxl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xxl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xxl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .xxl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .xxl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .xxl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .xxl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .xxl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .xxl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .xxl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .xxl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .xxl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .xxl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .xxl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .xxl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .xxl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .xxl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .xxl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .xxl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .xxl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .xxl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .xxl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .xxl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .xxl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .xxl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xxl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xxl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xxl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xxl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xxl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xxl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xxl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xxl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xxl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xxl\:pt-0 {
    padding-top: 0;
  }
  .xxl\:pr-0 {
    padding-right: 0;
  }
  .xxl\:pb-0 {
    padding-bottom: 0;
  }
  .xxl\:pl-0 {
    padding-left: 0;
  }
  .xxl\:pt-1 {
    padding-top: 0.25rem;
  }
  .xxl\:pr-1 {
    padding-right: 0.25rem;
  }
  .xxl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xxl\:pl-1 {
    padding-left: 0.25rem;
  }
  .xxl\:pt-2 {
    padding-top: 0.5rem;
  }
  .xxl\:pr-2 {
    padding-right: 0.5rem;
  }
  .xxl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xxl\:pl-2 {
    padding-left: 0.5rem;
  }
  .xxl\:pt-3 {
    padding-top: 0.75rem;
  }
  .xxl\:pr-3 {
    padding-right: 0.75rem;
  }
  .xxl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xxl\:pl-3 {
    padding-left: 0.75rem;
  }
  .xxl\:pt-4 {
    padding-top: 1rem;
  }
  .xxl\:pr-4 {
    padding-right: 1rem;
  }
  .xxl\:pb-4 {
    padding-bottom: 1rem;
  }
  .xxl\:pl-4 {
    padding-left: 1rem;
  }
  .xxl\:pt-5 {
    padding-top: 1.25rem;
  }
  .xxl\:pr-5 {
    padding-right: 1.25rem;
  }
  .xxl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xxl\:pl-5 {
    padding-left: 1.25rem;
  }
  .xxl\:pt-6 {
    padding-top: 1.5rem;
  }
  .xxl\:pr-6 {
    padding-right: 1.5rem;
  }
  .xxl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xxl\:pl-6 {
    padding-left: 1.5rem;
  }
  .xxl\:pt-7 {
    padding-top: 1.75rem;
  }
  .xxl\:pr-7 {
    padding-right: 1.75rem;
  }
  .xxl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xxl\:pl-7 {
    padding-left: 1.75rem;
  }
  .xxl\:pt-8 {
    padding-top: 2rem;
  }
  .xxl\:pr-8 {
    padding-right: 2rem;
  }
  .xxl\:pb-8 {
    padding-bottom: 2rem;
  }
  .xxl\:pl-8 {
    padding-left: 2rem;
  }
  .xxl\:pt-9 {
    padding-top: 2.25rem;
  }
  .xxl\:pr-9 {
    padding-right: 2.25rem;
  }
  .xxl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xxl\:pl-9 {
    padding-left: 2.25rem;
  }
  .xxl\:pt-10 {
    padding-top: 2.5rem;
  }
  .xxl\:pr-10 {
    padding-right: 2.5rem;
  }
  .xxl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xxl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xxl\:pt-11 {
    padding-top: 2.75rem;
  }
  .xxl\:pr-11 {
    padding-right: 2.75rem;
  }
  .xxl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xxl\:pl-11 {
    padding-left: 2.75rem;
  }
  .xxl\:pt-12 {
    padding-top: 3rem;
  }
  .xxl\:pr-12 {
    padding-right: 3rem;
  }
  .xxl\:pb-12 {
    padding-bottom: 3rem;
  }
  .xxl\:pl-12 {
    padding-left: 3rem;
  }
  .xxl\:pt-14 {
    padding-top: 3.5rem;
  }
  .xxl\:pr-14 {
    padding-right: 3.5rem;
  }
  .xxl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xxl\:pl-14 {
    padding-left: 3.5rem;
  }
  .xxl\:pt-16 {
    padding-top: 4rem;
  }
  .xxl\:pr-16 {
    padding-right: 4rem;
  }
  .xxl\:pb-16 {
    padding-bottom: 4rem;
  }
  .xxl\:pl-16 {
    padding-left: 4rem;
  }
  .xxl\:pt-20 {
    padding-top: 5rem;
  }
  .xxl\:pr-20 {
    padding-right: 5rem;
  }
  .xxl\:pb-20 {
    padding-bottom: 5rem;
  }
  .xxl\:pl-20 {
    padding-left: 5rem;
  }
  .xxl\:pt-24 {
    padding-top: 6rem;
  }
  .xxl\:pr-24 {
    padding-right: 6rem;
  }
  .xxl\:pb-24 {
    padding-bottom: 6rem;
  }
  .xxl\:pl-24 {
    padding-left: 6rem;
  }
  .xxl\:pt-28 {
    padding-top: 7rem;
  }
  .xxl\:pr-28 {
    padding-right: 7rem;
  }
  .xxl\:pb-28 {
    padding-bottom: 7rem;
  }
  .xxl\:pl-28 {
    padding-left: 7rem;
  }
  .xxl\:pt-32 {
    padding-top: 8rem;
  }
  .xxl\:pr-32 {
    padding-right: 8rem;
  }
  .xxl\:pb-32 {
    padding-bottom: 8rem;
  }
  .xxl\:pl-32 {
    padding-left: 8rem;
  }
  .xxl\:pt-36 {
    padding-top: 9rem;
  }
  .xxl\:pr-36 {
    padding-right: 9rem;
  }
  .xxl\:pb-36 {
    padding-bottom: 9rem;
  }
  .xxl\:pl-36 {
    padding-left: 9rem;
  }
  .xxl\:pt-40 {
    padding-top: 10rem;
  }
  .xxl\:pr-40 {
    padding-right: 10rem;
  }
  .xxl\:pb-40 {
    padding-bottom: 10rem;
  }
  .xxl\:pl-40 {
    padding-left: 10rem;
  }
  .xxl\:pt-44 {
    padding-top: 11rem;
  }
  .xxl\:pr-44 {
    padding-right: 11rem;
  }
  .xxl\:pb-44 {
    padding-bottom: 11rem;
  }
  .xxl\:pl-44 {
    padding-left: 11rem;
  }
  .xxl\:pt-48 {
    padding-top: 12rem;
  }
  .xxl\:pr-48 {
    padding-right: 12rem;
  }
  .xxl\:pb-48 {
    padding-bottom: 12rem;
  }
  .xxl\:pl-48 {
    padding-left: 12rem;
  }
  .xxl\:pt-52 {
    padding-top: 13rem;
  }
  .xxl\:pr-52 {
    padding-right: 13rem;
  }
  .xxl\:pb-52 {
    padding-bottom: 13rem;
  }
  .xxl\:pl-52 {
    padding-left: 13rem;
  }
  .xxl\:pt-56 {
    padding-top: 14rem;
  }
  .xxl\:pr-56 {
    padding-right: 14rem;
  }
  .xxl\:pb-56 {
    padding-bottom: 14rem;
  }
  .xxl\:pl-56 {
    padding-left: 14rem;
  }
  .xxl\:pt-60 {
    padding-top: 15rem;
  }
  .xxl\:pr-60 {
    padding-right: 15rem;
  }
  .xxl\:pb-60 {
    padding-bottom: 15rem;
  }
  .xxl\:pl-60 {
    padding-left: 15rem;
  }
  .xxl\:pt-64 {
    padding-top: 16rem;
  }
  .xxl\:pr-64 {
    padding-right: 16rem;
  }
  .xxl\:pb-64 {
    padding-bottom: 16rem;
  }
  .xxl\:pl-64 {
    padding-left: 16rem;
  }
  .xxl\:pt-72 {
    padding-top: 18rem;
  }
  .xxl\:pr-72 {
    padding-right: 18rem;
  }
  .xxl\:pb-72 {
    padding-bottom: 18rem;
  }
  .xxl\:pl-72 {
    padding-left: 18rem;
  }
  .xxl\:pt-80 {
    padding-top: 20rem;
  }
  .xxl\:pr-80 {
    padding-right: 20rem;
  }
  .xxl\:pb-80 {
    padding-bottom: 20rem;
  }
  .xxl\:pl-80 {
    padding-left: 20rem;
  }
  .xxl\:pt-96 {
    padding-top: 24rem;
  }
  .xxl\:pr-96 {
    padding-right: 24rem;
  }
  .xxl\:pb-96 {
    padding-bottom: 24rem;
  }
  .xxl\:pl-96 {
    padding-left: 24rem;
  }
  .xxl\:pt-px {
    padding-top: 1px;
  }
  .xxl\:pr-px {
    padding-right: 1px;
  }
  .xxl\:pb-px {
    padding-bottom: 1px;
  }
  .xxl\:pl-px {
    padding-left: 1px;
  }
  .xxl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xxl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xxl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xxl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xxl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xxl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xxl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xxl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xxl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xxl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xxl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xxl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xxl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xxl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xxl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xxl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xxl\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }
  .xxl\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }
  .xxl\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .xxl\:placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }
  .xxl\:placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }
  .xxl\:placeholder-current::placeholder {
    color: currentColor;
  }
  .xxl\:placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:placeholder-gray-300:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:placeholder-gray-300::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-vivid-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-vivid-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-vivid-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-vivid-blue-500::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:placeholder-vivid-blue-500:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:placeholder-vivid-blue-500::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:placeholder-background-primary::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xxl\:placeholder-background-primary:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xxl\:placeholder-background-primary::placeholder {
    color: var(--background-primary);
  }
  .xxl\:placeholder-main-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-main-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-main-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-dark-blue::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-dark-blue:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-dark-blue::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-blue-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-text-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-text-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-text-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey-two::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey-two:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey-two::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-pale-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc-symbol::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc-symbol:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-btc-symbol::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-paxg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-paxg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-paxg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-usdc::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-usdc:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-usdc::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-black-navy::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-black-navy:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-black-navy::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-ghost-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-ghost-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-ghost-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-spring-green::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-spring-green:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-spring-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-slate-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-slate-gray:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-slate-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-body-background::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xxl\:placeholder-body-background:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xxl\:placeholder-body-background::placeholder {
    color: var(--body-background);
  }
  .xxl\:placeholder-background::-webkit-input-placeholder {
    color: var(--background);
  }
  .xxl\:placeholder-background:-ms-input-placeholder {
    color: var(--background);
  }
  .xxl\:placeholder-background::placeholder {
    color: var(--background);
  }
  .xxl\:placeholder-input-background::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xxl\:placeholder-input-background:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xxl\:placeholder-input-background::placeholder {
    color: var(--input-background);
  }
  .xxl\:placeholder-body-text::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xxl\:placeholder-body-text:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xxl\:placeholder-body-text::placeholder {
    color: var(--body-text);
  }
  .xxl\:placeholder-header-text::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xxl\:placeholder-header-text:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xxl\:placeholder-header-text::placeholder {
    color: var(--header-text);
  }
  .xxl\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xxl\:placeholder-primary:-ms-input-placeholder {
    color: var(--primary);
  }
  .xxl\:placeholder-primary::placeholder {
    color: var(--primary);
  }
  .xxl\:placeholder-secondary::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xxl\:placeholder-secondary:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xxl\:placeholder-secondary::placeholder {
    color: var(--secondary);
  }
  .xxl\:placeholder-tertiary::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xxl\:placeholder-tertiary:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xxl\:placeholder-tertiary::placeholder {
    color: var(--tertiary);
  }
  .xxl\:placeholder-primary-hover::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xxl\:placeholder-primary-hover:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xxl\:placeholder-primary-hover::placeholder {
    color: var(--primary-hover);
  }
  .xxl\:placeholder-secondary-hover::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:placeholder-secondary-hover:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:placeholder-secondary-hover::placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:placeholder-tertiary-hover::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:placeholder-tertiary-hover:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:placeholder-tertiary-hover::placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:placeholder-input-text::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xxl\:placeholder-input-text:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xxl\:placeholder-input-text::placeholder {
    color: var(--input-text);
  }
  .xxl\:placeholder-success::-webkit-input-placeholder {
    color: var(--success);
  }
  .xxl\:placeholder-success:-ms-input-placeholder {
    color: var(--success);
  }
  .xxl\:placeholder-success::placeholder {
    color: var(--success);
  }
  .xxl\:placeholder-error::-webkit-input-placeholder {
    color: var(--error);
  }
  .xxl\:placeholder-error:-ms-input-placeholder {
    color: var(--error);
  }
  .xxl\:placeholder-error::placeholder {
    color: var(--error);
  }
  .xxl\:placeholder-custom-loader::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xxl\:placeholder-custom-loader:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xxl\:placeholder-custom-loader::placeholder {
    color: var(--custom-loader);
  }
  .xxl\:placeholder-violet::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-violet:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-violet::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-violet-hover::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-violet-hover:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-violet-hover::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-skeleton-loader-from::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:placeholder-skeleton-loader-from:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:placeholder-skeleton-loader-from::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:placeholder-skeleton-loader-to::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:placeholder-skeleton-loader-to:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:placeholder-skeleton-loader-to::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:placeholder-background50::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xxl\:placeholder-background50:-ms-input-placeholder {
    color: var(--background50);
  }
  .xxl\:placeholder-background50::placeholder {
    color: var(--background50);
  }
  .xxl\:placeholder-reward-blue-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-blue-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-blue-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-blue-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-blue-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-blue-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-silver-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-gold-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-platinum-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-start::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-start:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-start::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-end::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-end:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-reward-vip-end::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-fee-breakdown-bg::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-fee-breakdown-bg:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-fee-breakdown-bg::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-grey::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-grey:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-light-grey::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-background::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-background:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-background::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-text::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-text:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-disabled-button-text::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .xxl\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }
  .xxl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .xxl\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }
  .xxl\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }
  .xxl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .xxl\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:placeholder-gray-300:focus::placeholder {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-vivid-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-vivid-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-vivid-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-vivid-blue-500:focus::-webkit-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:placeholder-vivid-blue-500:focus:-ms-input-placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:placeholder-vivid-blue-500:focus::placeholder {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:placeholder-background-primary:focus::-webkit-input-placeholder {
    color: var(--background-primary);
  }
  .xxl\:focus\:placeholder-background-primary:focus:-ms-input-placeholder {
    color: var(--background-primary);
  }
  .xxl\:focus\:placeholder-background-primary:focus::placeholder {
    color: var(--background-primary);
  }
  .xxl\:focus\:placeholder-main-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-main-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-main-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-dark-blue:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-dark-blue:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-dark-blue:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-blue-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-text-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-text-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-text-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey-two:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey-two:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey-two:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-pale-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc-symbol:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc-symbol:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-btc-symbol:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-paxg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-paxg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-paxg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-usdc:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-usdc:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-usdc:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-black-navy:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-black-navy:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-black-navy:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-ghost-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-ghost-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-ghost-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-spring-green:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-spring-green:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-spring-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-slate-gray:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-slate-gray:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-slate-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-body-background:focus::-webkit-input-placeholder {
    color: var(--body-background);
  }
  .xxl\:focus\:placeholder-body-background:focus:-ms-input-placeholder {
    color: var(--body-background);
  }
  .xxl\:focus\:placeholder-body-background:focus::placeholder {
    color: var(--body-background);
  }
  .xxl\:focus\:placeholder-background:focus::-webkit-input-placeholder {
    color: var(--background);
  }
  .xxl\:focus\:placeholder-background:focus:-ms-input-placeholder {
    color: var(--background);
  }
  .xxl\:focus\:placeholder-background:focus::placeholder {
    color: var(--background);
  }
  .xxl\:focus\:placeholder-input-background:focus::-webkit-input-placeholder {
    color: var(--input-background);
  }
  .xxl\:focus\:placeholder-input-background:focus:-ms-input-placeholder {
    color: var(--input-background);
  }
  .xxl\:focus\:placeholder-input-background:focus::placeholder {
    color: var(--input-background);
  }
  .xxl\:focus\:placeholder-body-text:focus::-webkit-input-placeholder {
    color: var(--body-text);
  }
  .xxl\:focus\:placeholder-body-text:focus:-ms-input-placeholder {
    color: var(--body-text);
  }
  .xxl\:focus\:placeholder-body-text:focus::placeholder {
    color: var(--body-text);
  }
  .xxl\:focus\:placeholder-header-text:focus::-webkit-input-placeholder {
    color: var(--header-text);
  }
  .xxl\:focus\:placeholder-header-text:focus:-ms-input-placeholder {
    color: var(--header-text);
  }
  .xxl\:focus\:placeholder-header-text:focus::placeholder {
    color: var(--header-text);
  }
  .xxl\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary);
  }
  .xxl\:focus\:placeholder-primary:focus:-ms-input-placeholder {
    color: var(--primary);
  }
  .xxl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary);
  }
  .xxl\:focus\:placeholder-secondary:focus::-webkit-input-placeholder {
    color: var(--secondary);
  }
  .xxl\:focus\:placeholder-secondary:focus:-ms-input-placeholder {
    color: var(--secondary);
  }
  .xxl\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--secondary);
  }
  .xxl\:focus\:placeholder-tertiary:focus::-webkit-input-placeholder {
    color: var(--tertiary);
  }
  .xxl\:focus\:placeholder-tertiary:focus:-ms-input-placeholder {
    color: var(--tertiary);
  }
  .xxl\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--tertiary);
  }
  .xxl\:focus\:placeholder-primary-hover:focus::-webkit-input-placeholder {
    color: var(--primary-hover);
  }
  .xxl\:focus\:placeholder-primary-hover:focus:-ms-input-placeholder {
    color: var(--primary-hover);
  }
  .xxl\:focus\:placeholder-primary-hover:focus::placeholder {
    color: var(--primary-hover);
  }
  .xxl\:focus\:placeholder-secondary-hover:focus::-webkit-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:focus\:placeholder-secondary-hover:focus:-ms-input-placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:focus\:placeholder-secondary-hover:focus::placeholder {
    color: var(--secondary-hover);
  }
  .xxl\:focus\:placeholder-tertiary-hover:focus::-webkit-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:focus\:placeholder-tertiary-hover:focus:-ms-input-placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:focus\:placeholder-tertiary-hover:focus::placeholder {
    color: var(--tertiary-hover);
  }
  .xxl\:focus\:placeholder-input-text:focus::-webkit-input-placeholder {
    color: var(--input-text);
  }
  .xxl\:focus\:placeholder-input-text:focus:-ms-input-placeholder {
    color: var(--input-text);
  }
  .xxl\:focus\:placeholder-input-text:focus::placeholder {
    color: var(--input-text);
  }
  .xxl\:focus\:placeholder-success:focus::-webkit-input-placeholder {
    color: var(--success);
  }
  .xxl\:focus\:placeholder-success:focus:-ms-input-placeholder {
    color: var(--success);
  }
  .xxl\:focus\:placeholder-success:focus::placeholder {
    color: var(--success);
  }
  .xxl\:focus\:placeholder-error:focus::-webkit-input-placeholder {
    color: var(--error);
  }
  .xxl\:focus\:placeholder-error:focus:-ms-input-placeholder {
    color: var(--error);
  }
  .xxl\:focus\:placeholder-error:focus::placeholder {
    color: var(--error);
  }
  .xxl\:focus\:placeholder-custom-loader:focus::-webkit-input-placeholder {
    color: var(--custom-loader);
  }
  .xxl\:focus\:placeholder-custom-loader:focus:-ms-input-placeholder {
    color: var(--custom-loader);
  }
  .xxl\:focus\:placeholder-custom-loader:focus::placeholder {
    color: var(--custom-loader);
  }
  .xxl\:focus\:placeholder-violet:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-violet:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-violet:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-violet-hover:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-violet-hover:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-violet-hover:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-skeleton-loader-from:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:placeholder-skeleton-loader-from:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:placeholder-skeleton-loader-from:focus::placeholder {
    color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:placeholder-skeleton-loader-to:focus::-webkit-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:placeholder-skeleton-loader-to:focus:-ms-input-placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:placeholder-skeleton-loader-to:focus::placeholder {
    color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:placeholder-background50:focus::-webkit-input-placeholder {
    color: var(--background50);
  }
  .xxl\:focus\:placeholder-background50:focus:-ms-input-placeholder {
    color: var(--background50);
  }
  .xxl\:focus\:placeholder-background50:focus::placeholder {
    color: var(--background50);
  }
  .xxl\:focus\:placeholder-reward-blue-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-blue-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-blue-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-blue-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-blue-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-blue-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-silver-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-gold-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-platinum-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-start:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-start:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-start:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-end:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-end:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-reward-vip-end:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-fee-breakdown-bg:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-fee-breakdown-bg:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-fee-breakdown-bg:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-grey:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-grey:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-light-grey:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-background:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-background:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-background:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-text:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-text:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:focus\:placeholder-disabled-button-text:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-placeholder-opacity));
  }
  .xxl\:placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xxl\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xxl\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xxl\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xxl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xxl\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xxl\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xxl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xxl\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xxl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xxl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xxl\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xxl\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xxl\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xxl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xxl\:pointer-events-none {
    pointer-events: none;
  }
  .xxl\:pointer-events-auto {
    pointer-events: auto;
  }
  .xxl\:static {
    position: static;
  }
  .xxl\:fixed {
    position: fixed;
  }
  .xxl\:absolute {
    position: absolute;
  }
  .xxl\:relative {
    position: relative;
  }
  .xxl\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .xxl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .xxl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .xxl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .xxl\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .xxl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .xxl\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .xxl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .xxl\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .xxl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .xxl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .xxl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .xxl\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .xxl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .xxl\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .xxl\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .xxl\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .xxl\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .xxl\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .xxl\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .xxl\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .xxl\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .xxl\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .xxl\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .xxl\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .xxl\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .xxl\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .xxl\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .xxl\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .xxl\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .xxl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xxl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .xxl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .xxl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .xxl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .xxl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .xxl\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xxl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .xxl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .xxl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .xxl\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .xxl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .xxl\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .xxl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .xxl\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .xxl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .xxl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .xxl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .xxl\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .xxl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .xxl\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .xxl\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .xxl\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .xxl\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .xxl\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .xxl\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .xxl\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .xxl\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .xxl\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .xxl\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .xxl\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .xxl\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .xxl\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .xxl\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .xxl\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .xxl\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .xxl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .xxl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .xxl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .xxl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .xxl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .xxl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xxl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .xxl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .xxl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .xxl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xxl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .xxl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .xxl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xxl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .xxl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .xxl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .xxl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xxl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .xxl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .xxl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xxl\:inset-x-0 {
    right: 0;
    left: 0;
  }
  .xxl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .xxl\:inset-x-1 {
    right: 0.25rem;
    left: 0.25rem;
  }
  .xxl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .xxl\:inset-x-2 {
    right: 0.5rem;
    left: 0.5rem;
  }
  .xxl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .xxl\:inset-x-3 {
    right: 0.75rem;
    left: 0.75rem;
  }
  .xxl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .xxl\:inset-x-4 {
    right: 1rem;
    left: 1rem;
  }
  .xxl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .xxl\:inset-x-5 {
    right: 1.25rem;
    left: 1.25rem;
  }
  .xxl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .xxl\:inset-x-6 {
    right: 1.5rem;
    left: 1.5rem;
  }
  .xxl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .xxl\:inset-x-7 {
    right: 1.75rem;
    left: 1.75rem;
  }
  .xxl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .xxl\:inset-x-8 {
    right: 2rem;
    left: 2rem;
  }
  .xxl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .xxl\:inset-x-9 {
    right: 2.25rem;
    left: 2.25rem;
  }
  .xxl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .xxl\:inset-x-10 {
    right: 2.5rem;
    left: 2.5rem;
  }
  .xxl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .xxl\:inset-x-11 {
    right: 2.75rem;
    left: 2.75rem;
  }
  .xxl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .xxl\:inset-x-12 {
    right: 3rem;
    left: 3rem;
  }
  .xxl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .xxl\:inset-x-14 {
    right: 3.5rem;
    left: 3.5rem;
  }
  .xxl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .xxl\:inset-x-16 {
    right: 4rem;
    left: 4rem;
  }
  .xxl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .xxl\:inset-x-20 {
    right: 5rem;
    left: 5rem;
  }
  .xxl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .xxl\:inset-x-24 {
    right: 6rem;
    left: 6rem;
  }
  .xxl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .xxl\:inset-x-28 {
    right: 7rem;
    left: 7rem;
  }
  .xxl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .xxl\:inset-x-32 {
    right: 8rem;
    left: 8rem;
  }
  .xxl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .xxl\:inset-x-36 {
    right: 9rem;
    left: 9rem;
  }
  .xxl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .xxl\:inset-x-40 {
    right: 10rem;
    left: 10rem;
  }
  .xxl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .xxl\:inset-x-44 {
    right: 11rem;
    left: 11rem;
  }
  .xxl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .xxl\:inset-x-48 {
    right: 12rem;
    left: 12rem;
  }
  .xxl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .xxl\:inset-x-52 {
    right: 13rem;
    left: 13rem;
  }
  .xxl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .xxl\:inset-x-56 {
    right: 14rem;
    left: 14rem;
  }
  .xxl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .xxl\:inset-x-60 {
    right: 15rem;
    left: 15rem;
  }
  .xxl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .xxl\:inset-x-64 {
    right: 16rem;
    left: 16rem;
  }
  .xxl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .xxl\:inset-x-72 {
    right: 18rem;
    left: 18rem;
  }
  .xxl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .xxl\:inset-x-80 {
    right: 20rem;
    left: 20rem;
  }
  .xxl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .xxl\:inset-x-96 {
    right: 24rem;
    left: 24rem;
  }
  .xxl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .xxl\:inset-x-auto {
    right: auto;
    left: auto;
  }
  .xxl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .xxl\:inset-x-px {
    right: 1px;
    left: 1px;
  }
  .xxl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .xxl\:inset-x-0\.5 {
    right: 0.125rem;
    left: 0.125rem;
  }
  .xxl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .xxl\:inset-x-1\.5 {
    right: 0.375rem;
    left: 0.375rem;
  }
  .xxl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .xxl\:inset-x-2\.5 {
    right: 0.625rem;
    left: 0.625rem;
  }
  .xxl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .xxl\:inset-x-3\.5 {
    right: 0.875rem;
    left: 0.875rem;
  }
  .xxl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xxl\:-inset-x-0 {
    right: 0;
    left: 0;
  }
  .xxl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .xxl\:-inset-x-1 {
    right: -0.25rem;
    left: -0.25rem;
  }
  .xxl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .xxl\:-inset-x-2 {
    right: -0.5rem;
    left: -0.5rem;
  }
  .xxl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .xxl\:-inset-x-3 {
    right: -0.75rem;
    left: -0.75rem;
  }
  .xxl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .xxl\:-inset-x-4 {
    right: -1rem;
    left: -1rem;
  }
  .xxl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .xxl\:-inset-x-5 {
    right: -1.25rem;
    left: -1.25rem;
  }
  .xxl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .xxl\:-inset-x-6 {
    right: -1.5rem;
    left: -1.5rem;
  }
  .xxl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .xxl\:-inset-x-7 {
    right: -1.75rem;
    left: -1.75rem;
  }
  .xxl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .xxl\:-inset-x-8 {
    right: -2rem;
    left: -2rem;
  }
  .xxl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .xxl\:-inset-x-9 {
    right: -2.25rem;
    left: -2.25rem;
  }
  .xxl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .xxl\:-inset-x-10 {
    right: -2.5rem;
    left: -2.5rem;
  }
  .xxl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .xxl\:-inset-x-11 {
    right: -2.75rem;
    left: -2.75rem;
  }
  .xxl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .xxl\:-inset-x-12 {
    right: -3rem;
    left: -3rem;
  }
  .xxl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .xxl\:-inset-x-14 {
    right: -3.5rem;
    left: -3.5rem;
  }
  .xxl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .xxl\:-inset-x-16 {
    right: -4rem;
    left: -4rem;
  }
  .xxl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .xxl\:-inset-x-20 {
    right: -5rem;
    left: -5rem;
  }
  .xxl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .xxl\:-inset-x-24 {
    right: -6rem;
    left: -6rem;
  }
  .xxl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .xxl\:-inset-x-28 {
    right: -7rem;
    left: -7rem;
  }
  .xxl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .xxl\:-inset-x-32 {
    right: -8rem;
    left: -8rem;
  }
  .xxl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .xxl\:-inset-x-36 {
    right: -9rem;
    left: -9rem;
  }
  .xxl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .xxl\:-inset-x-40 {
    right: -10rem;
    left: -10rem;
  }
  .xxl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .xxl\:-inset-x-44 {
    right: -11rem;
    left: -11rem;
  }
  .xxl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .xxl\:-inset-x-48 {
    right: -12rem;
    left: -12rem;
  }
  .xxl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .xxl\:-inset-x-52 {
    right: -13rem;
    left: -13rem;
  }
  .xxl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .xxl\:-inset-x-56 {
    right: -14rem;
    left: -14rem;
  }
  .xxl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .xxl\:-inset-x-60 {
    right: -15rem;
    left: -15rem;
  }
  .xxl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .xxl\:-inset-x-64 {
    right: -16rem;
    left: -16rem;
  }
  .xxl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .xxl\:-inset-x-72 {
    right: -18rem;
    left: -18rem;
  }
  .xxl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .xxl\:-inset-x-80 {
    right: -20rem;
    left: -20rem;
  }
  .xxl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .xxl\:-inset-x-96 {
    right: -24rem;
    left: -24rem;
  }
  .xxl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .xxl\:-inset-x-px {
    right: -1px;
    left: -1px;
  }
  .xxl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .xxl\:-inset-x-0\.5 {
    right: -0.125rem;
    left: -0.125rem;
  }
  .xxl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .xxl\:-inset-x-1\.5 {
    right: -0.375rem;
    left: -0.375rem;
  }
  .xxl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .xxl\:-inset-x-2\.5 {
    right: -0.625rem;
    left: -0.625rem;
  }
  .xxl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .xxl\:-inset-x-3\.5 {
    right: -0.875rem;
    left: -0.875rem;
  }
  .xxl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .xxl\:inset-x-1\/2 {
    right: 50%;
    left: 50%;
  }
  .xxl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .xxl\:inset-x-1\/3 {
    right: 33.333333%;
    left: 33.333333%;
  }
  .xxl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .xxl\:inset-x-2\/3 {
    right: 66.666667%;
    left: 66.666667%;
  }
  .xxl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .xxl\:inset-x-1\/4 {
    right: 25%;
    left: 25%;
  }
  .xxl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .xxl\:inset-x-2\/4 {
    right: 50%;
    left: 50%;
  }
  .xxl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .xxl\:inset-x-3\/4 {
    right: 75%;
    left: 75%;
  }
  .xxl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .xxl\:inset-x-full {
    right: 100%;
    left: 100%;
  }
  .xxl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .xxl\:-inset-x-1\/2 {
    right: -50%;
    left: -50%;
  }
  .xxl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .xxl\:-inset-x-1\/3 {
    right: -33.333333%;
    left: -33.333333%;
  }
  .xxl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .xxl\:-inset-x-2\/3 {
    right: -66.666667%;
    left: -66.666667%;
  }
  .xxl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .xxl\:-inset-x-1\/4 {
    right: -25%;
    left: -25%;
  }
  .xxl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .xxl\:-inset-x-2\/4 {
    right: -50%;
    left: -50%;
  }
  .xxl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .xxl\:-inset-x-3\/4 {
    right: -75%;
    left: -75%;
  }
  .xxl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .xxl\:-inset-x-full {
    right: -100%;
    left: -100%;
  }
  .xxl\:top-0 {
    top: 0;
  }
  .xxl\:right-0 {
    right: 0;
  }
  .xxl\:bottom-0 {
    bottom: 0;
  }
  .xxl\:left-0 {
    left: 0;
  }
  .xxl\:top-1 {
    top: 0.25rem;
  }
  .xxl\:right-1 {
    right: 0.25rem;
  }
  .xxl\:bottom-1 {
    bottom: 0.25rem;
  }
  .xxl\:left-1 {
    left: 0.25rem;
  }
  .xxl\:top-2 {
    top: 0.5rem;
  }
  .xxl\:right-2 {
    right: 0.5rem;
  }
  .xxl\:bottom-2 {
    bottom: 0.5rem;
  }
  .xxl\:left-2 {
    left: 0.5rem;
  }
  .xxl\:top-3 {
    top: 0.75rem;
  }
  .xxl\:right-3 {
    right: 0.75rem;
  }
  .xxl\:bottom-3 {
    bottom: 0.75rem;
  }
  .xxl\:left-3 {
    left: 0.75rem;
  }
  .xxl\:top-4 {
    top: 1rem;
  }
  .xxl\:right-4 {
    right: 1rem;
  }
  .xxl\:bottom-4 {
    bottom: 1rem;
  }
  .xxl\:left-4 {
    left: 1rem;
  }
  .xxl\:top-5 {
    top: 1.25rem;
  }
  .xxl\:right-5 {
    right: 1.25rem;
  }
  .xxl\:bottom-5 {
    bottom: 1.25rem;
  }
  .xxl\:left-5 {
    left: 1.25rem;
  }
  .xxl\:top-6 {
    top: 1.5rem;
  }
  .xxl\:right-6 {
    right: 1.5rem;
  }
  .xxl\:bottom-6 {
    bottom: 1.5rem;
  }
  .xxl\:left-6 {
    left: 1.5rem;
  }
  .xxl\:top-7 {
    top: 1.75rem;
  }
  .xxl\:right-7 {
    right: 1.75rem;
  }
  .xxl\:bottom-7 {
    bottom: 1.75rem;
  }
  .xxl\:left-7 {
    left: 1.75rem;
  }
  .xxl\:top-8 {
    top: 2rem;
  }
  .xxl\:right-8 {
    right: 2rem;
  }
  .xxl\:bottom-8 {
    bottom: 2rem;
  }
  .xxl\:left-8 {
    left: 2rem;
  }
  .xxl\:top-9 {
    top: 2.25rem;
  }
  .xxl\:right-9 {
    right: 2.25rem;
  }
  .xxl\:bottom-9 {
    bottom: 2.25rem;
  }
  .xxl\:left-9 {
    left: 2.25rem;
  }
  .xxl\:top-10 {
    top: 2.5rem;
  }
  .xxl\:right-10 {
    right: 2.5rem;
  }
  .xxl\:bottom-10 {
    bottom: 2.5rem;
  }
  .xxl\:left-10 {
    left: 2.5rem;
  }
  .xxl\:top-11 {
    top: 2.75rem;
  }
  .xxl\:right-11 {
    right: 2.75rem;
  }
  .xxl\:bottom-11 {
    bottom: 2.75rem;
  }
  .xxl\:left-11 {
    left: 2.75rem;
  }
  .xxl\:top-12 {
    top: 3rem;
  }
  .xxl\:right-12 {
    right: 3rem;
  }
  .xxl\:bottom-12 {
    bottom: 3rem;
  }
  .xxl\:left-12 {
    left: 3rem;
  }
  .xxl\:top-14 {
    top: 3.5rem;
  }
  .xxl\:right-14 {
    right: 3.5rem;
  }
  .xxl\:bottom-14 {
    bottom: 3.5rem;
  }
  .xxl\:left-14 {
    left: 3.5rem;
  }
  .xxl\:top-16 {
    top: 4rem;
  }
  .xxl\:right-16 {
    right: 4rem;
  }
  .xxl\:bottom-16 {
    bottom: 4rem;
  }
  .xxl\:left-16 {
    left: 4rem;
  }
  .xxl\:top-20 {
    top: 5rem;
  }
  .xxl\:right-20 {
    right: 5rem;
  }
  .xxl\:bottom-20 {
    bottom: 5rem;
  }
  .xxl\:left-20 {
    left: 5rem;
  }
  .xxl\:top-24 {
    top: 6rem;
  }
  .xxl\:right-24 {
    right: 6rem;
  }
  .xxl\:bottom-24 {
    bottom: 6rem;
  }
  .xxl\:left-24 {
    left: 6rem;
  }
  .xxl\:top-28 {
    top: 7rem;
  }
  .xxl\:right-28 {
    right: 7rem;
  }
  .xxl\:bottom-28 {
    bottom: 7rem;
  }
  .xxl\:left-28 {
    left: 7rem;
  }
  .xxl\:top-32 {
    top: 8rem;
  }
  .xxl\:right-32 {
    right: 8rem;
  }
  .xxl\:bottom-32 {
    bottom: 8rem;
  }
  .xxl\:left-32 {
    left: 8rem;
  }
  .xxl\:top-36 {
    top: 9rem;
  }
  .xxl\:right-36 {
    right: 9rem;
  }
  .xxl\:bottom-36 {
    bottom: 9rem;
  }
  .xxl\:left-36 {
    left: 9rem;
  }
  .xxl\:top-40 {
    top: 10rem;
  }
  .xxl\:right-40 {
    right: 10rem;
  }
  .xxl\:bottom-40 {
    bottom: 10rem;
  }
  .xxl\:left-40 {
    left: 10rem;
  }
  .xxl\:top-44 {
    top: 11rem;
  }
  .xxl\:right-44 {
    right: 11rem;
  }
  .xxl\:bottom-44 {
    bottom: 11rem;
  }
  .xxl\:left-44 {
    left: 11rem;
  }
  .xxl\:top-48 {
    top: 12rem;
  }
  .xxl\:right-48 {
    right: 12rem;
  }
  .xxl\:bottom-48 {
    bottom: 12rem;
  }
  .xxl\:left-48 {
    left: 12rem;
  }
  .xxl\:top-52 {
    top: 13rem;
  }
  .xxl\:right-52 {
    right: 13rem;
  }
  .xxl\:bottom-52 {
    bottom: 13rem;
  }
  .xxl\:left-52 {
    left: 13rem;
  }
  .xxl\:top-56 {
    top: 14rem;
  }
  .xxl\:right-56 {
    right: 14rem;
  }
  .xxl\:bottom-56 {
    bottom: 14rem;
  }
  .xxl\:left-56 {
    left: 14rem;
  }
  .xxl\:top-60 {
    top: 15rem;
  }
  .xxl\:right-60 {
    right: 15rem;
  }
  .xxl\:bottom-60 {
    bottom: 15rem;
  }
  .xxl\:left-60 {
    left: 15rem;
  }
  .xxl\:top-64 {
    top: 16rem;
  }
  .xxl\:right-64 {
    right: 16rem;
  }
  .xxl\:bottom-64 {
    bottom: 16rem;
  }
  .xxl\:left-64 {
    left: 16rem;
  }
  .xxl\:top-72 {
    top: 18rem;
  }
  .xxl\:right-72 {
    right: 18rem;
  }
  .xxl\:bottom-72 {
    bottom: 18rem;
  }
  .xxl\:left-72 {
    left: 18rem;
  }
  .xxl\:top-80 {
    top: 20rem;
  }
  .xxl\:right-80 {
    right: 20rem;
  }
  .xxl\:bottom-80 {
    bottom: 20rem;
  }
  .xxl\:left-80 {
    left: 20rem;
  }
  .xxl\:top-96 {
    top: 24rem;
  }
  .xxl\:right-96 {
    right: 24rem;
  }
  .xxl\:bottom-96 {
    bottom: 24rem;
  }
  .xxl\:left-96 {
    left: 24rem;
  }
  .xxl\:top-auto {
    top: auto;
  }
  .xxl\:right-auto {
    right: auto;
  }
  .xxl\:bottom-auto {
    bottom: auto;
  }
  .xxl\:left-auto {
    left: auto;
  }
  .xxl\:top-px {
    top: 1px;
  }
  .xxl\:right-px {
    right: 1px;
  }
  .xxl\:bottom-px {
    bottom: 1px;
  }
  .xxl\:left-px {
    left: 1px;
  }
  .xxl\:top-0\.5 {
    top: 0.125rem;
  }
  .xxl\:right-0\.5 {
    right: 0.125rem;
  }
  .xxl\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .xxl\:left-0\.5 {
    left: 0.125rem;
  }
  .xxl\:top-1\.5 {
    top: 0.375rem;
  }
  .xxl\:right-1\.5 {
    right: 0.375rem;
  }
  .xxl\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .xxl\:left-1\.5 {
    left: 0.375rem;
  }
  .xxl\:top-2\.5 {
    top: 0.625rem;
  }
  .xxl\:right-2\.5 {
    right: 0.625rem;
  }
  .xxl\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .xxl\:left-2\.5 {
    left: 0.625rem;
  }
  .xxl\:top-3\.5 {
    top: 0.875rem;
  }
  .xxl\:right-3\.5 {
    right: 0.875rem;
  }
  .xxl\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .xxl\:left-3\.5 {
    left: 0.875rem;
  }
  .xxl\:-top-0 {
    top: 0;
  }
  .xxl\:-right-0 {
    right: 0;
  }
  .xxl\:-bottom-0 {
    bottom: 0;
  }
  .xxl\:-left-0 {
    left: 0;
  }
  .xxl\:-top-1 {
    top: -0.25rem;
  }
  .xxl\:-right-1 {
    right: -0.25rem;
  }
  .xxl\:-bottom-1 {
    bottom: -0.25rem;
  }
  .xxl\:-left-1 {
    left: -0.25rem;
  }
  .xxl\:-top-2 {
    top: -0.5rem;
  }
  .xxl\:-right-2 {
    right: -0.5rem;
  }
  .xxl\:-bottom-2 {
    bottom: -0.5rem;
  }
  .xxl\:-left-2 {
    left: -0.5rem;
  }
  .xxl\:-top-3 {
    top: -0.75rem;
  }
  .xxl\:-right-3 {
    right: -0.75rem;
  }
  .xxl\:-bottom-3 {
    bottom: -0.75rem;
  }
  .xxl\:-left-3 {
    left: -0.75rem;
  }
  .xxl\:-top-4 {
    top: -1rem;
  }
  .xxl\:-right-4 {
    right: -1rem;
  }
  .xxl\:-bottom-4 {
    bottom: -1rem;
  }
  .xxl\:-left-4 {
    left: -1rem;
  }
  .xxl\:-top-5 {
    top: -1.25rem;
  }
  .xxl\:-right-5 {
    right: -1.25rem;
  }
  .xxl\:-bottom-5 {
    bottom: -1.25rem;
  }
  .xxl\:-left-5 {
    left: -1.25rem;
  }
  .xxl\:-top-6 {
    top: -1.5rem;
  }
  .xxl\:-right-6 {
    right: -1.5rem;
  }
  .xxl\:-bottom-6 {
    bottom: -1.5rem;
  }
  .xxl\:-left-6 {
    left: -1.5rem;
  }
  .xxl\:-top-7 {
    top: -1.75rem;
  }
  .xxl\:-right-7 {
    right: -1.75rem;
  }
  .xxl\:-bottom-7 {
    bottom: -1.75rem;
  }
  .xxl\:-left-7 {
    left: -1.75rem;
  }
  .xxl\:-top-8 {
    top: -2rem;
  }
  .xxl\:-right-8 {
    right: -2rem;
  }
  .xxl\:-bottom-8 {
    bottom: -2rem;
  }
  .xxl\:-left-8 {
    left: -2rem;
  }
  .xxl\:-top-9 {
    top: -2.25rem;
  }
  .xxl\:-right-9 {
    right: -2.25rem;
  }
  .xxl\:-bottom-9 {
    bottom: -2.25rem;
  }
  .xxl\:-left-9 {
    left: -2.25rem;
  }
  .xxl\:-top-10 {
    top: -2.5rem;
  }
  .xxl\:-right-10 {
    right: -2.5rem;
  }
  .xxl\:-bottom-10 {
    bottom: -2.5rem;
  }
  .xxl\:-left-10 {
    left: -2.5rem;
  }
  .xxl\:-top-11 {
    top: -2.75rem;
  }
  .xxl\:-right-11 {
    right: -2.75rem;
  }
  .xxl\:-bottom-11 {
    bottom: -2.75rem;
  }
  .xxl\:-left-11 {
    left: -2.75rem;
  }
  .xxl\:-top-12 {
    top: -3rem;
  }
  .xxl\:-right-12 {
    right: -3rem;
  }
  .xxl\:-bottom-12 {
    bottom: -3rem;
  }
  .xxl\:-left-12 {
    left: -3rem;
  }
  .xxl\:-top-14 {
    top: -3.5rem;
  }
  .xxl\:-right-14 {
    right: -3.5rem;
  }
  .xxl\:-bottom-14 {
    bottom: -3.5rem;
  }
  .xxl\:-left-14 {
    left: -3.5rem;
  }
  .xxl\:-top-16 {
    top: -4rem;
  }
  .xxl\:-right-16 {
    right: -4rem;
  }
  .xxl\:-bottom-16 {
    bottom: -4rem;
  }
  .xxl\:-left-16 {
    left: -4rem;
  }
  .xxl\:-top-20 {
    top: -5rem;
  }
  .xxl\:-right-20 {
    right: -5rem;
  }
  .xxl\:-bottom-20 {
    bottom: -5rem;
  }
  .xxl\:-left-20 {
    left: -5rem;
  }
  .xxl\:-top-24 {
    top: -6rem;
  }
  .xxl\:-right-24 {
    right: -6rem;
  }
  .xxl\:-bottom-24 {
    bottom: -6rem;
  }
  .xxl\:-left-24 {
    left: -6rem;
  }
  .xxl\:-top-28 {
    top: -7rem;
  }
  .xxl\:-right-28 {
    right: -7rem;
  }
  .xxl\:-bottom-28 {
    bottom: -7rem;
  }
  .xxl\:-left-28 {
    left: -7rem;
  }
  .xxl\:-top-32 {
    top: -8rem;
  }
  .xxl\:-right-32 {
    right: -8rem;
  }
  .xxl\:-bottom-32 {
    bottom: -8rem;
  }
  .xxl\:-left-32 {
    left: -8rem;
  }
  .xxl\:-top-36 {
    top: -9rem;
  }
  .xxl\:-right-36 {
    right: -9rem;
  }
  .xxl\:-bottom-36 {
    bottom: -9rem;
  }
  .xxl\:-left-36 {
    left: -9rem;
  }
  .xxl\:-top-40 {
    top: -10rem;
  }
  .xxl\:-right-40 {
    right: -10rem;
  }
  .xxl\:-bottom-40 {
    bottom: -10rem;
  }
  .xxl\:-left-40 {
    left: -10rem;
  }
  .xxl\:-top-44 {
    top: -11rem;
  }
  .xxl\:-right-44 {
    right: -11rem;
  }
  .xxl\:-bottom-44 {
    bottom: -11rem;
  }
  .xxl\:-left-44 {
    left: -11rem;
  }
  .xxl\:-top-48 {
    top: -12rem;
  }
  .xxl\:-right-48 {
    right: -12rem;
  }
  .xxl\:-bottom-48 {
    bottom: -12rem;
  }
  .xxl\:-left-48 {
    left: -12rem;
  }
  .xxl\:-top-52 {
    top: -13rem;
  }
  .xxl\:-right-52 {
    right: -13rem;
  }
  .xxl\:-bottom-52 {
    bottom: -13rem;
  }
  .xxl\:-left-52 {
    left: -13rem;
  }
  .xxl\:-top-56 {
    top: -14rem;
  }
  .xxl\:-right-56 {
    right: -14rem;
  }
  .xxl\:-bottom-56 {
    bottom: -14rem;
  }
  .xxl\:-left-56 {
    left: -14rem;
  }
  .xxl\:-top-60 {
    top: -15rem;
  }
  .xxl\:-right-60 {
    right: -15rem;
  }
  .xxl\:-bottom-60 {
    bottom: -15rem;
  }
  .xxl\:-left-60 {
    left: -15rem;
  }
  .xxl\:-top-64 {
    top: -16rem;
  }
  .xxl\:-right-64 {
    right: -16rem;
  }
  .xxl\:-bottom-64 {
    bottom: -16rem;
  }
  .xxl\:-left-64 {
    left: -16rem;
  }
  .xxl\:-top-72 {
    top: -18rem;
  }
  .xxl\:-right-72 {
    right: -18rem;
  }
  .xxl\:-bottom-72 {
    bottom: -18rem;
  }
  .xxl\:-left-72 {
    left: -18rem;
  }
  .xxl\:-top-80 {
    top: -20rem;
  }
  .xxl\:-right-80 {
    right: -20rem;
  }
  .xxl\:-bottom-80 {
    bottom: -20rem;
  }
  .xxl\:-left-80 {
    left: -20rem;
  }
  .xxl\:-top-96 {
    top: -24rem;
  }
  .xxl\:-right-96 {
    right: -24rem;
  }
  .xxl\:-bottom-96 {
    bottom: -24rem;
  }
  .xxl\:-left-96 {
    left: -24rem;
  }
  .xxl\:-top-px {
    top: -1px;
  }
  .xxl\:-right-px {
    right: -1px;
  }
  .xxl\:-bottom-px {
    bottom: -1px;
  }
  .xxl\:-left-px {
    left: -1px;
  }
  .xxl\:-top-0\.5 {
    top: -0.125rem;
  }
  .xxl\:-right-0\.5 {
    right: -0.125rem;
  }
  .xxl\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .xxl\:-left-0\.5 {
    left: -0.125rem;
  }
  .xxl\:-top-1\.5 {
    top: -0.375rem;
  }
  .xxl\:-right-1\.5 {
    right: -0.375rem;
  }
  .xxl\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .xxl\:-left-1\.5 {
    left: -0.375rem;
  }
  .xxl\:-top-2\.5 {
    top: -0.625rem;
  }
  .xxl\:-right-2\.5 {
    right: -0.625rem;
  }
  .xxl\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .xxl\:-left-2\.5 {
    left: -0.625rem;
  }
  .xxl\:-top-3\.5 {
    top: -0.875rem;
  }
  .xxl\:-right-3\.5 {
    right: -0.875rem;
  }
  .xxl\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .xxl\:-left-3\.5 {
    left: -0.875rem;
  }
  .xxl\:top-1\/2 {
    top: 50%;
  }
  .xxl\:right-1\/2 {
    right: 50%;
  }
  .xxl\:bottom-1\/2 {
    bottom: 50%;
  }
  .xxl\:left-1\/2 {
    left: 50%;
  }
  .xxl\:top-1\/3 {
    top: 33.333333%;
  }
  .xxl\:right-1\/3 {
    right: 33.333333%;
  }
  .xxl\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .xxl\:left-1\/3 {
    left: 33.333333%;
  }
  .xxl\:top-2\/3 {
    top: 66.666667%;
  }
  .xxl\:right-2\/3 {
    right: 66.666667%;
  }
  .xxl\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .xxl\:left-2\/3 {
    left: 66.666667%;
  }
  .xxl\:top-1\/4 {
    top: 25%;
  }
  .xxl\:right-1\/4 {
    right: 25%;
  }
  .xxl\:bottom-1\/4 {
    bottom: 25%;
  }
  .xxl\:left-1\/4 {
    left: 25%;
  }
  .xxl\:top-2\/4 {
    top: 50%;
  }
  .xxl\:right-2\/4 {
    right: 50%;
  }
  .xxl\:bottom-2\/4 {
    bottom: 50%;
  }
  .xxl\:left-2\/4 {
    left: 50%;
  }
  .xxl\:top-3\/4 {
    top: 75%;
  }
  .xxl\:right-3\/4 {
    right: 75%;
  }
  .xxl\:bottom-3\/4 {
    bottom: 75%;
  }
  .xxl\:left-3\/4 {
    left: 75%;
  }
  .xxl\:top-full {
    top: 100%;
  }
  .xxl\:right-full {
    right: 100%;
  }
  .xxl\:bottom-full {
    bottom: 100%;
  }
  .xxl\:left-full {
    left: 100%;
  }
  .xxl\:-top-1\/2 {
    top: -50%;
  }
  .xxl\:-right-1\/2 {
    right: -50%;
  }
  .xxl\:-bottom-1\/2 {
    bottom: -50%;
  }
  .xxl\:-left-1\/2 {
    left: -50%;
  }
  .xxl\:-top-1\/3 {
    top: -33.333333%;
  }
  .xxl\:-right-1\/3 {
    right: -33.333333%;
  }
  .xxl\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .xxl\:-left-1\/3 {
    left: -33.333333%;
  }
  .xxl\:-top-2\/3 {
    top: -66.666667%;
  }
  .xxl\:-right-2\/3 {
    right: -66.666667%;
  }
  .xxl\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .xxl\:-left-2\/3 {
    left: -66.666667%;
  }
  .xxl\:-top-1\/4 {
    top: -25%;
  }
  .xxl\:-right-1\/4 {
    right: -25%;
  }
  .xxl\:-bottom-1\/4 {
    bottom: -25%;
  }
  .xxl\:-left-1\/4 {
    left: -25%;
  }
  .xxl\:-top-2\/4 {
    top: -50%;
  }
  .xxl\:-right-2\/4 {
    right: -50%;
  }
  .xxl\:-bottom-2\/4 {
    bottom: -50%;
  }
  .xxl\:-left-2\/4 {
    left: -50%;
  }
  .xxl\:-top-3\/4 {
    top: -75%;
  }
  .xxl\:-right-3\/4 {
    right: -75%;
  }
  .xxl\:-bottom-3\/4 {
    bottom: -75%;
  }
  .xxl\:-left-3\/4 {
    left: -75%;
  }
  .xxl\:-top-full {
    top: -100%;
  }
  .xxl\:-right-full {
    right: -100%;
  }
  .xxl\:-bottom-full {
    bottom: -100%;
  }
  .xxl\:-left-full {
    left: -100%;
  }
  .xxl\:resize-none {
    resize: none;
  }
  .xxl\:resize-y {
    resize: vertical;
  }
  .xxl\:resize-x {
    resize: horizontal;
  }
  .xxl\:resize {
    resize: both;
  }
  .xxl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxl\:shadow,
  .xxl\:shadow-sm {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxl\:shadow-lg,
  .xxl\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxl\:shadow-2xl,
  .xxl\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:shadow-inner,
  .xxl\:shadow-none {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .xxl\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxl\:shadow-bottom-nav,
  .xxl\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxl\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .group:hover .xxl\:group-hover\:shadow-sm,
  .xxl\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .group:hover .xxl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxl\:group-hover\:shadow,
  .group:hover .xxl\:group-hover\:shadow-md {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .group:hover .xxl\:group-hover\:shadow-lg,
  .group:hover .xxl\:group-hover\:shadow-xl {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .group:hover .xxl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .group:hover .xxl\:group-hover\:shadow-2xl,
  .group:hover .xxl\:group-hover\:shadow-inner {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .group:hover .xxl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 transparent;
  }
  .group:hover .xxl\:group-hover\:shadow-none,
  .group:hover .xxl\:group-hover\:shadow-vivid-blue {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-vivid-blue {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .group:hover .xxl\:group-hover\:shadow-bottom-nav {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .group:hover .xxl\:group-hover\:shadow-bottom-nav,
  .group:hover .xxl\:group-hover\:shadow-mobile-buttons {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .group:hover .xxl\:group-hover\:shadow-mobile-buttons {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 transparent;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-vivid-blue:focus-within {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-bottom-nav:focus-within {
    --tw-shadow: 0px -10px 20px #171e34;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus-within\:shadow-mobile-buttons:focus-within {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxl\:hover\:shadow-sm:hover,
  .xxl\:hover\:shadow:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxl\:hover\:shadow-lg:hover,
  .xxl\:hover\:shadow-md:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxl\:hover\:shadow-2xl:hover,
  .xxl\:hover\:shadow-xl:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:hover\:shadow-inner:hover,
  .xxl\:hover\:shadow-none:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 transparent;
  }
  .xxl\:hover\:shadow-vivid-blue:hover {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxl\:hover\:shadow-bottom-nav:hover,
  .xxl\:hover\:shadow-vivid-blue:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:hover\:shadow-bottom-nav:hover {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxl\:hover\:shadow-mobile-buttons:hover {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxl\:focus\:shadow-sm:focus,
  .xxl\:hover\:shadow-mobile-buttons:hover {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .xxl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:focus\:shadow-md:focus,
  .xxl\:focus\:shadow:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .xxl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .xxl\:focus\:shadow-lg:focus,
  .xxl\:focus\:shadow-xl:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .xxl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .xxl\:focus\:shadow-2xl:focus,
  .xxl\:focus\:shadow-inner:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
  .xxl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 transparent;
  }
  .xxl\:focus\:shadow-none:focus,
  .xxl\:focus\:shadow-vivid-blue:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-vivid-blue:focus {
    --tw-shadow: rgba(33, 46, 238, 0.3) 0px 10px 30px;
  }
  .xxl\:focus\:shadow-bottom-nav:focus {
    --tw-shadow: 0px -10px 20px #171e34;
  }
  .xxl\:focus\:shadow-bottom-nav:focus,
  .xxl\:focus\:shadow-mobile-buttons:focus {
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
  .xxl\:focus\:shadow-mobile-buttons:focus {
    --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25098039215686274);
  }
  .xxl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring-0,
  .xxl\:ring-1 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring-2,
  .xxl\:ring-4 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring,
  .xxl\:ring-8 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:ring-inset {
    --tw-ring-inset: inset;
  }
  .xxl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .xxl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-0:focus,
  .xxl\:focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-2:focus,
  .xxl\:focus\:ring-4:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-8:focus,
  .xxl\:focus\:ring:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }
  .xxl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .xxl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .xxl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .xxl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .xxl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .xxl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .xxl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:ring-offset-gray-300 {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .xxl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .xxl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .xxl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .xxl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #6d728b;
  }
  .xxl\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxl\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxl\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .xxl\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxl\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .xxl\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .xxl\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .xxl\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxl\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .xxl\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .xxl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .xxl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .xxl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .xxl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .xxl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .xxl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .xxl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .xxl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .xxl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .xxl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .xxl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .xxl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .xxl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .xxl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .xxl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .xxl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .xxl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .xxl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .xxl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .xxl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .xxl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .xxl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .xxl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .xxl\:ring-offset-vivid-blue {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:ring-offset-vivid-blue-500 {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:ring-offset-background-primary {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxl\:ring-offset-main-background {
    --tw-ring-offset-color: #121729;
  }
  .xxl\:ring-offset-dark-blue {
    --tw-ring-offset-color: #1e2741;
  }
  .xxl\:ring-offset-blue-grey {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxl\:ring-offset-text-grey {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxl\:ring-offset-pale-grey-two {
    --tw-ring-offset-color: #eeebff;
  }
  .xxl\:ring-offset-pale-grey {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxl\:ring-offset-btc {
    --tw-ring-offset-color: #f7931a;
  }
  .xxl\:ring-offset-btc-symbol {
    --tw-ring-offset-color: #f5b300;
  }
  .xxl\:ring-offset-paxg {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxl\:ring-offset-usdc {
    --tw-ring-offset-color: #2775ca;
  }
  .xxl\:ring-offset-black-navy {
    --tw-ring-offset-color: #101239;
  }
  .xxl\:ring-offset-ghost-white {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxl\:ring-offset-spring-green {
    --tw-ring-offset-color: #1be56d;
  }
  .xxl\:ring-offset-light-slate-gray {
    --tw-ring-offset-color: #888b94;
  }
  .xxl\:ring-offset-body-background {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxl\:ring-offset-background {
    --tw-ring-offset-color: var(--background);
  }
  .xxl\:ring-offset-input-background {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxl\:ring-offset-body-text {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxl\:ring-offset-header-text {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxl\:ring-offset-primary {
    --tw-ring-offset-color: var(--primary);
  }
  .xxl\:ring-offset-secondary {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxl\:ring-offset-tertiary {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxl\:ring-offset-primary-hover {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxl\:ring-offset-secondary-hover {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxl\:ring-offset-tertiary-hover {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxl\:ring-offset-input-text {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxl\:ring-offset-success {
    --tw-ring-offset-color: var(--success);
  }
  .xxl\:ring-offset-error {
    --tw-ring-offset-color: var(--error);
  }
  .xxl\:ring-offset-custom-loader {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxl\:ring-offset-violet {
    --tw-ring-offset-color: #9421ee;
  }
  .xxl\:ring-offset-violet-hover {
    --tw-ring-offset-color: #550b8e;
  }
  .xxl\:ring-offset-skeleton-loader-from {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxl\:ring-offset-skeleton-loader-to {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxl\:ring-offset-background50 {
    --tw-ring-offset-color: var(--background50);
  }
  .xxl\:ring-offset-reward-blue-start {
    --tw-ring-offset-color: #626bff;
  }
  .xxl\:ring-offset-reward-blue-end {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:ring-offset-reward-silver-start {
    --tw-ring-offset-color: #484848;
  }
  .xxl\:ring-offset-reward-silver-end {
    --tw-ring-offset-color: #acacac;
  }
  .xxl\:ring-offset-reward-gold-start {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxl\:ring-offset-reward-gold-end {
    --tw-ring-offset-color: #ffc425;
  }
  .xxl\:ring-offset-reward-platinum-start {
    --tw-ring-offset-color: #959595;
  }
  .xxl\:ring-offset-reward-platinum-end {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxl\:ring-offset-reward-vip-start {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:ring-offset-reward-vip-end {
    --tw-ring-offset-color: #c521ee;
  }
  .xxl\:ring-offset-fee-breakdown-bg {
    --tw-ring-offset-color: #182139;
  }
  .xxl\:ring-offset-light-grey {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:ring-offset-disabled-button-background {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxl\:ring-offset-disabled-button-text {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .xxl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .xxl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .xxl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .xxl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .xxl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .xxl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .xxl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .xxl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #6d728b;
  }
  .xxl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .xxl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .xxl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .xxl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .xxl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .xxl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .xxl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .xxl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .xxl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .xxl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .xxl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .xxl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .xxl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .xxl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .xxl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .xxl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .xxl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .xxl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .xxl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .xxl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .xxl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .xxl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .xxl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .xxl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .xxl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .xxl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .xxl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .xxl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .xxl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .xxl\:focus-within\:ring-offset-vivid-blue:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus-within\:ring-offset-vivid-blue-500:focus-within {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus-within\:ring-offset-background-primary:focus-within {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxl\:focus-within\:ring-offset-main-background:focus-within {
    --tw-ring-offset-color: #121729;
  }
  .xxl\:focus-within\:ring-offset-dark-blue:focus-within {
    --tw-ring-offset-color: #1e2741;
  }
  .xxl\:focus-within\:ring-offset-blue-grey:focus-within {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxl\:focus-within\:ring-offset-text-grey:focus-within {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxl\:focus-within\:ring-offset-pale-grey-two:focus-within {
    --tw-ring-offset-color: #eeebff;
  }
  .xxl\:focus-within\:ring-offset-pale-grey:focus-within {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxl\:focus-within\:ring-offset-btc:focus-within {
    --tw-ring-offset-color: #f7931a;
  }
  .xxl\:focus-within\:ring-offset-btc-symbol:focus-within {
    --tw-ring-offset-color: #f5b300;
  }
  .xxl\:focus-within\:ring-offset-paxg:focus-within {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxl\:focus-within\:ring-offset-usdc:focus-within {
    --tw-ring-offset-color: #2775ca;
  }
  .xxl\:focus-within\:ring-offset-black-navy:focus-within {
    --tw-ring-offset-color: #101239;
  }
  .xxl\:focus-within\:ring-offset-ghost-white:focus-within {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxl\:focus-within\:ring-offset-spring-green:focus-within {
    --tw-ring-offset-color: #1be56d;
  }
  .xxl\:focus-within\:ring-offset-light-slate-gray:focus-within {
    --tw-ring-offset-color: #888b94;
  }
  .xxl\:focus-within\:ring-offset-body-background:focus-within {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxl\:focus-within\:ring-offset-background:focus-within {
    --tw-ring-offset-color: var(--background);
  }
  .xxl\:focus-within\:ring-offset-input-background:focus-within {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxl\:focus-within\:ring-offset-body-text:focus-within {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxl\:focus-within\:ring-offset-header-text:focus-within {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxl\:focus-within\:ring-offset-primary:focus-within {
    --tw-ring-offset-color: var(--primary);
  }
  .xxl\:focus-within\:ring-offset-secondary:focus-within {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxl\:focus-within\:ring-offset-tertiary:focus-within {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxl\:focus-within\:ring-offset-primary-hover:focus-within {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxl\:focus-within\:ring-offset-secondary-hover:focus-within {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxl\:focus-within\:ring-offset-tertiary-hover:focus-within {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxl\:focus-within\:ring-offset-input-text:focus-within {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxl\:focus-within\:ring-offset-success:focus-within {
    --tw-ring-offset-color: var(--success);
  }
  .xxl\:focus-within\:ring-offset-error:focus-within {
    --tw-ring-offset-color: var(--error);
  }
  .xxl\:focus-within\:ring-offset-custom-loader:focus-within {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxl\:focus-within\:ring-offset-violet:focus-within {
    --tw-ring-offset-color: #9421ee;
  }
  .xxl\:focus-within\:ring-offset-violet-hover:focus-within {
    --tw-ring-offset-color: #550b8e;
  }
  .xxl\:focus-within\:ring-offset-skeleton-loader-from:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxl\:focus-within\:ring-offset-skeleton-loader-to:focus-within {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxl\:focus-within\:ring-offset-background50:focus-within {
    --tw-ring-offset-color: var(--background50);
  }
  .xxl\:focus-within\:ring-offset-reward-blue-start:focus-within {
    --tw-ring-offset-color: #626bff;
  }
  .xxl\:focus-within\:ring-offset-reward-blue-end:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus-within\:ring-offset-reward-silver-start:focus-within {
    --tw-ring-offset-color: #484848;
  }
  .xxl\:focus-within\:ring-offset-reward-silver-end:focus-within {
    --tw-ring-offset-color: #acacac;
  }
  .xxl\:focus-within\:ring-offset-reward-gold-start:focus-within {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxl\:focus-within\:ring-offset-reward-gold-end:focus-within {
    --tw-ring-offset-color: #ffc425;
  }
  .xxl\:focus-within\:ring-offset-reward-platinum-start:focus-within {
    --tw-ring-offset-color: #959595;
  }
  .xxl\:focus-within\:ring-offset-reward-platinum-end:focus-within {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxl\:focus-within\:ring-offset-reward-vip-start:focus-within {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus-within\:ring-offset-reward-vip-end:focus-within {
    --tw-ring-offset-color: #c521ee;
  }
  .xxl\:focus-within\:ring-offset-fee-breakdown-bg:focus-within {
    --tw-ring-offset-color: #182139;
  }
  .xxl\:focus-within\:ring-offset-light-grey:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:focus-within\:ring-offset-disabled-button-background:focus-within {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxl\:focus-within\:ring-offset-disabled-button-text:focus-within {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .xxl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .xxl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .xxl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .xxl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .xxl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xxl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .xxl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .xxl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .xxl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .xxl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .xxl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #6d728b;
  }
  .xxl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .xxl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .xxl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .xxl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .xxl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .xxl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .xxl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .xxl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .xxl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .xxl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xxl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .xxl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .xxl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .xxl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .xxl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .xxl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .xxl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .xxl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .xxl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .xxl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .xxl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xxl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .xxl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xxl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xxl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .xxl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .xxl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .xxl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .xxl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .xxl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .xxl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .xxl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .xxl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xxl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .xxl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .xxl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .xxl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .xxl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xxl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .xxl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xxl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .xxl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xxl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xxl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xxl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .xxl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .xxl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .xxl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .xxl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .xxl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .xxl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xxl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .xxl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xxl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xxl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .xxl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xxl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .xxl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .xxl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .xxl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .xxl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xxl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .xxl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xxl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xxl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .xxl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .xxl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .xxl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .xxl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .xxl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .xxl\:focus\:ring-offset-vivid-blue:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus\:ring-offset-vivid-blue-500:focus {
    --tw-ring-offset-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:ring-offset-background-primary:focus {
    --tw-ring-offset-color: var(--background-primary);
  }
  .xxl\:focus\:ring-offset-main-background:focus {
    --tw-ring-offset-color: #121729;
  }
  .xxl\:focus\:ring-offset-dark-blue:focus {
    --tw-ring-offset-color: #1e2741;
  }
  .xxl\:focus\:ring-offset-blue-grey:focus {
    --tw-ring-offset-color: #2f3a5c;
  }
  .xxl\:focus\:ring-offset-text-grey:focus {
    --tw-ring-offset-color: #a4a7c1;
  }
  .xxl\:focus\:ring-offset-pale-grey-two:focus {
    --tw-ring-offset-color: #eeebff;
  }
  .xxl\:focus\:ring-offset-pale-grey:focus {
    --tw-ring-offset-color: #a8afcc;
  }
  .xxl\:focus\:ring-offset-btc:focus {
    --tw-ring-offset-color: #f7931a;
  }
  .xxl\:focus\:ring-offset-btc-symbol:focus {
    --tw-ring-offset-color: #f5b300;
  }
  .xxl\:focus\:ring-offset-paxg:focus {
    --tw-ring-offset-color: #ffc54e;
  }
  .xxl\:focus\:ring-offset-usdc:focus {
    --tw-ring-offset-color: #2775ca;
  }
  .xxl\:focus\:ring-offset-black-navy:focus {
    --tw-ring-offset-color: #101239;
  }
  .xxl\:focus\:ring-offset-ghost-white:focus {
    --tw-ring-offset-color: #f6f5ff;
  }
  .xxl\:focus\:ring-offset-spring-green:focus {
    --tw-ring-offset-color: #1be56d;
  }
  .xxl\:focus\:ring-offset-light-slate-gray:focus {
    --tw-ring-offset-color: #888b94;
  }
  .xxl\:focus\:ring-offset-body-background:focus {
    --tw-ring-offset-color: var(--body-background);
  }
  .xxl\:focus\:ring-offset-background:focus {
    --tw-ring-offset-color: var(--background);
  }
  .xxl\:focus\:ring-offset-input-background:focus {
    --tw-ring-offset-color: var(--input-background);
  }
  .xxl\:focus\:ring-offset-body-text:focus {
    --tw-ring-offset-color: var(--body-text);
  }
  .xxl\:focus\:ring-offset-header-text:focus {
    --tw-ring-offset-color: var(--header-text);
  }
  .xxl\:focus\:ring-offset-primary:focus {
    --tw-ring-offset-color: var(--primary);
  }
  .xxl\:focus\:ring-offset-secondary:focus {
    --tw-ring-offset-color: var(--secondary);
  }
  .xxl\:focus\:ring-offset-tertiary:focus {
    --tw-ring-offset-color: var(--tertiary);
  }
  .xxl\:focus\:ring-offset-primary-hover:focus {
    --tw-ring-offset-color: var(--primary-hover);
  }
  .xxl\:focus\:ring-offset-secondary-hover:focus {
    --tw-ring-offset-color: var(--secondary-hover);
  }
  .xxl\:focus\:ring-offset-tertiary-hover:focus {
    --tw-ring-offset-color: var(--tertiary-hover);
  }
  .xxl\:focus\:ring-offset-input-text:focus {
    --tw-ring-offset-color: var(--input-text);
  }
  .xxl\:focus\:ring-offset-success:focus {
    --tw-ring-offset-color: var(--success);
  }
  .xxl\:focus\:ring-offset-error:focus {
    --tw-ring-offset-color: var(--error);
  }
  .xxl\:focus\:ring-offset-custom-loader:focus {
    --tw-ring-offset-color: var(--custom-loader);
  }
  .xxl\:focus\:ring-offset-violet:focus {
    --tw-ring-offset-color: #9421ee;
  }
  .xxl\:focus\:ring-offset-violet-hover:focus {
    --tw-ring-offset-color: #550b8e;
  }
  .xxl\:focus\:ring-offset-skeleton-loader-from:focus {
    --tw-ring-offset-color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:ring-offset-skeleton-loader-to:focus {
    --tw-ring-offset-color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:ring-offset-background50:focus {
    --tw-ring-offset-color: var(--background50);
  }
  .xxl\:focus\:ring-offset-reward-blue-start:focus {
    --tw-ring-offset-color: #626bff;
  }
  .xxl\:focus\:ring-offset-reward-blue-end:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus\:ring-offset-reward-silver-start:focus {
    --tw-ring-offset-color: #484848;
  }
  .xxl\:focus\:ring-offset-reward-silver-end:focus {
    --tw-ring-offset-color: #acacac;
  }
  .xxl\:focus\:ring-offset-reward-gold-start:focus {
    --tw-ring-offset-color: #ffdf8a;
  }
  .xxl\:focus\:ring-offset-reward-gold-end:focus {
    --tw-ring-offset-color: #ffc425;
  }
  .xxl\:focus\:ring-offset-reward-platinum-start:focus {
    --tw-ring-offset-color: #959595;
  }
  .xxl\:focus\:ring-offset-reward-platinum-end:focus {
    --tw-ring-offset-color: #f8f8f8;
  }
  .xxl\:focus\:ring-offset-reward-vip-start:focus {
    --tw-ring-offset-color: #212eee;
  }
  .xxl\:focus\:ring-offset-reward-vip-end:focus {
    --tw-ring-offset-color: #c521ee;
  }
  .xxl\:focus\:ring-offset-fee-breakdown-bg:focus {
    --tw-ring-offset-color: #182139;
  }
  .xxl\:focus\:ring-offset-light-grey:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xxl\:focus\:ring-offset-disabled-button-background:focus {
    --tw-ring-offset-color: #1f2a98;
  }
  .xxl\:focus\:ring-offset-disabled-button-text:focus {
    --tw-ring-offset-color: #8e93a0;
  }
  .xxl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .xxl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .xxl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .xxl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .xxl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .xxl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .xxl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .xxl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .xxl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .xxl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .xxl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .xxl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .xxl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .xxl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .xxl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .xxl\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .xxl\:ring-current {
    --tw-ring-color: currentColor;
  }
  .xxl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-300 {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxl\:ring-vivid-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:ring-vivid-blue-500 {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:ring-background-primary {
    --tw-ring-color: var(--background-primary);
  }
  .xxl\:ring-main-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxl\:ring-dark-blue {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxl\:ring-blue-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxl\:ring-text-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxl\:ring-pale-grey-two {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-pale-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxl\:ring-btc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxl\:ring-btc-symbol {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxl\:ring-paxg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxl\:ring-usdc {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxl\:ring-black-navy {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxl\:ring-ghost-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-spring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxl\:ring-light-slate-gray {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxl\:ring-body-background {
    --tw-ring-color: var(--body-background);
  }
  .xxl\:ring-background {
    --tw-ring-color: var(--background);
  }
  .xxl\:ring-input-background {
    --tw-ring-color: var(--input-background);
  }
  .xxl\:ring-body-text {
    --tw-ring-color: var(--body-text);
  }
  .xxl\:ring-header-text {
    --tw-ring-color: var(--header-text);
  }
  .xxl\:ring-primary {
    --tw-ring-color: var(--primary);
  }
  .xxl\:ring-secondary {
    --tw-ring-color: var(--secondary);
  }
  .xxl\:ring-tertiary {
    --tw-ring-color: var(--tertiary);
  }
  .xxl\:ring-primary-hover {
    --tw-ring-color: var(--primary-hover);
  }
  .xxl\:ring-secondary-hover {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxl\:ring-tertiary-hover {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxl\:ring-input-text {
    --tw-ring-color: var(--input-text);
  }
  .xxl\:ring-success {
    --tw-ring-color: var(--success);
  }
  .xxl\:ring-error {
    --tw-ring-color: var(--error);
  }
  .xxl\:ring-custom-loader {
    --tw-ring-color: var(--custom-loader);
  }
  .xxl\:ring-violet {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:ring-violet-hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxl\:ring-skeleton-loader-from {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxl\:ring-skeleton-loader-to {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxl\:ring-background50 {
    --tw-ring-color: var(--background50);
  }
  .xxl\:ring-reward-blue-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-blue-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-silver-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-silver-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-gold-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-gold-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-platinum-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-platinum-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-vip-start {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:ring-reward-vip-end {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:ring-fee-breakdown-bg {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxl\:ring-light-grey {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:ring-disabled-button-background {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxl\:ring-disabled-button-text {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .xxl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .xxl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-vivid-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-vivid-blue-500:focus-within {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus-within\:ring-background-primary:focus-within {
    --tw-ring-color: var(--background-primary);
  }
  .xxl\:focus-within\:ring-main-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-dark-blue:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-blue-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-text-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pale-grey-two:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-pale-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-btc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-btc-symbol:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-paxg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-usdc:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-black-navy:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-ghost-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-spring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-light-slate-gray:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-body-background:focus-within {
    --tw-ring-color: var(--body-background);
  }
  .xxl\:focus-within\:ring-background:focus-within {
    --tw-ring-color: var(--background);
  }
  .xxl\:focus-within\:ring-input-background:focus-within {
    --tw-ring-color: var(--input-background);
  }
  .xxl\:focus-within\:ring-body-text:focus-within {
    --tw-ring-color: var(--body-text);
  }
  .xxl\:focus-within\:ring-header-text:focus-within {
    --tw-ring-color: var(--header-text);
  }
  .xxl\:focus-within\:ring-primary:focus-within {
    --tw-ring-color: var(--primary);
  }
  .xxl\:focus-within\:ring-secondary:focus-within {
    --tw-ring-color: var(--secondary);
  }
  .xxl\:focus-within\:ring-tertiary:focus-within {
    --tw-ring-color: var(--tertiary);
  }
  .xxl\:focus-within\:ring-primary-hover:focus-within {
    --tw-ring-color: var(--primary-hover);
  }
  .xxl\:focus-within\:ring-secondary-hover:focus-within {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxl\:focus-within\:ring-tertiary-hover:focus-within {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxl\:focus-within\:ring-input-text:focus-within {
    --tw-ring-color: var(--input-text);
  }
  .xxl\:focus-within\:ring-success:focus-within {
    --tw-ring-color: var(--success);
  }
  .xxl\:focus-within\:ring-error:focus-within {
    --tw-ring-color: var(--error);
  }
  .xxl\:focus-within\:ring-custom-loader:focus-within {
    --tw-ring-color: var(--custom-loader);
  }
  .xxl\:focus-within\:ring-violet:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-violet-hover:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-skeleton-loader-from:focus-within {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxl\:focus-within\:ring-skeleton-loader-to:focus-within {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxl\:focus-within\:ring-background50:focus-within {
    --tw-ring-color: var(--background50);
  }
  .xxl\:focus-within\:ring-reward-blue-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-blue-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-silver-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-silver-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-gold-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-gold-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-platinum-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-platinum-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-vip-start:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-reward-vip-end:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-fee-breakdown-bg:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-light-grey:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-disabled-button-background:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxl\:focus-within\:ring-disabled-button-text:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .xxl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .xxl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-300:focus {
    --tw-ring-color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 114, 139, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-vivid-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-vivid-blue-500:focus {
    --tw-ring-color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:ring-background-primary:focus {
    --tw-ring-color: var(--background-primary);
  }
  .xxl\:focus\:ring-main-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(18, 23, 41, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-dark-blue:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 39, 65, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-blue-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(47, 58, 92, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-text-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(164, 167, 193, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pale-grey-two:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 235, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-pale-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(168, 175, 204, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-btc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 147, 26, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-btc-symbol:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 179, 0, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-paxg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 197, 78, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-usdc:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(39, 117, 202, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-black-navy:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 18, 57, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-ghost-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(246, 245, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-spring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 229, 109, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-light-slate-gray:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 139, 148, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-body-background:focus {
    --tw-ring-color: var(--body-background);
  }
  .xxl\:focus\:ring-background:focus {
    --tw-ring-color: var(--background);
  }
  .xxl\:focus\:ring-input-background:focus {
    --tw-ring-color: var(--input-background);
  }
  .xxl\:focus\:ring-body-text:focus {
    --tw-ring-color: var(--body-text);
  }
  .xxl\:focus\:ring-header-text:focus {
    --tw-ring-color: var(--header-text);
  }
  .xxl\:focus\:ring-primary:focus {
    --tw-ring-color: var(--primary);
  }
  .xxl\:focus\:ring-secondary:focus {
    --tw-ring-color: var(--secondary);
  }
  .xxl\:focus\:ring-tertiary:focus {
    --tw-ring-color: var(--tertiary);
  }
  .xxl\:focus\:ring-primary-hover:focus {
    --tw-ring-color: var(--primary-hover);
  }
  .xxl\:focus\:ring-secondary-hover:focus {
    --tw-ring-color: var(--secondary-hover);
  }
  .xxl\:focus\:ring-tertiary-hover:focus {
    --tw-ring-color: var(--tertiary-hover);
  }
  .xxl\:focus\:ring-input-text:focus {
    --tw-ring-color: var(--input-text);
  }
  .xxl\:focus\:ring-success:focus {
    --tw-ring-color: var(--success);
  }
  .xxl\:focus\:ring-error:focus {
    --tw-ring-color: var(--error);
  }
  .xxl\:focus\:ring-custom-loader:focus {
    --tw-ring-color: var(--custom-loader);
  }
  .xxl\:focus\:ring-violet:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-violet-hover:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 11, 142, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-skeleton-loader-from:focus {
    --tw-ring-color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:ring-skeleton-loader-to:focus {
    --tw-ring-color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:ring-background50:focus {
    --tw-ring-color: var(--background50);
  }
  .xxl\:focus\:ring-reward-blue-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 107, 255, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-blue-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-silver-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(72, 72, 72, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-silver-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(172, 172, 172, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-gold-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 223, 138, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-gold-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 37, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-platinum-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 149, 149, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-platinum-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 248, 248, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-vip-start:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 46, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-reward-vip-end:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 33, 238, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-fee-breakdown-bg:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 33, 57, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-light-grey:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-disabled-button-background:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 42, 152, var(--tw-ring-opacity));
  }
  .xxl\:focus\:ring-disabled-button-text:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 147, 160, var(--tw-ring-opacity));
  }
  .xxl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .xxl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .xxl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .xxl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .xxl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .xxl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .xxl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .xxl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .xxl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .xxl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .xxl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .xxl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .xxl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .xxl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .xxl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .xxl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .xxl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .xxl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .xxl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .xxl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .xxl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .xxl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .xxl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .xxl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .xxl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .xxl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .xxl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .xxl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .xxl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .xxl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .xxl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .xxl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .xxl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .xxl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .xxl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .xxl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .xxl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .xxl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .xxl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .xxl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .xxl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .xxl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .xxl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .xxl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .xxl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .xxl\:fill-current {
    fill: currentColor;
  }
  .xxl\:stroke-current {
    stroke: currentColor;
  }
  .xxl\:stroke-0 {
    stroke-width: 0;
  }
  .xxl\:stroke-1 {
    stroke-width: 1;
  }
  .xxl\:stroke-2 {
    stroke-width: 2;
  }
  .xxl\:table-auto {
    table-layout: auto;
  }
  .xxl\:table-fixed {
    table-layout: fixed;
  }
  .xxl\:text-left {
    text-align: left;
  }
  .xxl\:text-center {
    text-align: center;
  }
  .xxl\:text-right {
    text-align: right;
  }
  .xxl\:text-justify {
    text-align: justify;
  }
  .xxl\:text-transparent {
    color: transparent;
  }
  .xxl\:text-current {
    color: currentColor;
  }
  .xxl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxl\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:text-background-primary {
    color: var(--background-primary);
  }
  .xxl\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxl\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxl\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxl\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxl\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxl\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxl\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxl\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxl\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxl\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxl\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxl\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxl\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxl\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxl\:text-body-background {
    color: var(--body-background);
  }
  .xxl\:text-background {
    color: var(--background);
  }
  .xxl\:text-input-background {
    color: var(--input-background);
  }
  .xxl\:text-body-text {
    color: var(--body-text);
  }
  .xxl\:text-header-text {
    color: var(--header-text);
  }
  .xxl\:text-primary {
    color: var(--primary);
  }
  .xxl\:text-secondary {
    color: var(--secondary);
  }
  .xxl\:text-tertiary {
    color: var(--tertiary);
  }
  .xxl\:text-primary-hover {
    color: var(--primary-hover);
  }
  .xxl\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .xxl\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .xxl\:text-input-text {
    color: var(--input-text);
  }
  .xxl\:text-success {
    color: var(--success);
  }
  .xxl\:text-error {
    color: var(--error);
  }
  .xxl\:text-custom-loader {
    color: var(--custom-loader);
  }
  .xxl\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxl\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .xxl\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .xxl\:text-background50 {
    color: var(--background50);
  }
  .xxl\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxl\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxl\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxl\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxl\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxl\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxl\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxl\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxl\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxl\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .xxl\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .xxl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-300 {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .group:hover .xxl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-vivid-blue {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-vivid-blue-500 {
    color: rgba(144, 150, 246, 0.5);
  }
  .group:hover .xxl\:group-hover\:text-background-primary {
    color: var(--background-primary);
  }
  .group:hover .xxl\:group-hover\:text-main-background {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-blue-grey {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-text-grey {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pale-grey-two {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-pale-grey {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-btc {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-btc-symbol {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-paxg {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-usdc {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-black-navy {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-ghost-white {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-spring-green {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-light-slate-gray {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-body-background {
    color: var(--body-background);
  }
  .group:hover .xxl\:group-hover\:text-background {
    color: var(--background);
  }
  .group:hover .xxl\:group-hover\:text-input-background {
    color: var(--input-background);
  }
  .group:hover .xxl\:group-hover\:text-body-text {
    color: var(--body-text);
  }
  .group:hover .xxl\:group-hover\:text-header-text {
    color: var(--header-text);
  }
  .group:hover .xxl\:group-hover\:text-primary {
    color: var(--primary);
  }
  .group:hover .xxl\:group-hover\:text-secondary {
    color: var(--secondary);
  }
  .group:hover .xxl\:group-hover\:text-tertiary {
    color: var(--tertiary);
  }
  .group:hover .xxl\:group-hover\:text-primary-hover {
    color: var(--primary-hover);
  }
  .group:hover .xxl\:group-hover\:text-secondary-hover {
    color: var(--secondary-hover);
  }
  .group:hover .xxl\:group-hover\:text-tertiary-hover {
    color: var(--tertiary-hover);
  }
  .group:hover .xxl\:group-hover\:text-input-text {
    color: var(--input-text);
  }
  .group:hover .xxl\:group-hover\:text-success {
    color: var(--success);
  }
  .group:hover .xxl\:group-hover\:text-error {
    color: var(--error);
  }
  .group:hover .xxl\:group-hover\:text-custom-loader {
    color: var(--custom-loader);
  }
  .group:hover .xxl\:group-hover\:text-violet {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-violet-hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-skeleton-loader-from {
    color: var(--skeleton-loader-from);
  }
  .group:hover .xxl\:group-hover\:text-skeleton-loader-to {
    color: var(--skeleton-loader-to);
  }
  .group:hover .xxl\:group-hover\:text-background50 {
    color: var(--background50);
  }
  .group:hover .xxl\:group-hover\:text-reward-blue-start {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-blue-end {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-silver-start {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-silver-end {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-gold-start {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-gold-end {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-platinum-start {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-platinum-end {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-vip-start {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-reward-vip-end {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-fee-breakdown-bg {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-light-grey {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-disabled-button-background {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .group:hover .xxl\:group-hover\:text-disabled-button-text {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .xxl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .xxl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-300:focus-within {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-vivid-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-vivid-blue-500:focus-within {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus-within\:text-background-primary:focus-within {
    color: var(--background-primary);
  }
  .xxl\:focus-within\:text-main-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-dark-blue:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-blue-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-text-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pale-grey-two:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-pale-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-btc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-btc-symbol:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-paxg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-usdc:focus-within {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-black-navy:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-ghost-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-spring-green:focus-within {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-light-slate-gray:focus-within {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-body-background:focus-within {
    color: var(--body-background);
  }
  .xxl\:focus-within\:text-background:focus-within {
    color: var(--background);
  }
  .xxl\:focus-within\:text-input-background:focus-within {
    color: var(--input-background);
  }
  .xxl\:focus-within\:text-body-text:focus-within {
    color: var(--body-text);
  }
  .xxl\:focus-within\:text-header-text:focus-within {
    color: var(--header-text);
  }
  .xxl\:focus-within\:text-primary:focus-within {
    color: var(--primary);
  }
  .xxl\:focus-within\:text-secondary:focus-within {
    color: var(--secondary);
  }
  .xxl\:focus-within\:text-tertiary:focus-within {
    color: var(--tertiary);
  }
  .xxl\:focus-within\:text-primary-hover:focus-within {
    color: var(--primary-hover);
  }
  .xxl\:focus-within\:text-secondary-hover:focus-within {
    color: var(--secondary-hover);
  }
  .xxl\:focus-within\:text-tertiary-hover:focus-within {
    color: var(--tertiary-hover);
  }
  .xxl\:focus-within\:text-input-text:focus-within {
    color: var(--input-text);
  }
  .xxl\:focus-within\:text-success:focus-within {
    color: var(--success);
  }
  .xxl\:focus-within\:text-error:focus-within {
    color: var(--error);
  }
  .xxl\:focus-within\:text-custom-loader:focus-within {
    color: var(--custom-loader);
  }
  .xxl\:focus-within\:text-violet:focus-within {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-violet-hover:focus-within {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-skeleton-loader-from:focus-within {
    color: var(--skeleton-loader-from);
  }
  .xxl\:focus-within\:text-skeleton-loader-to:focus-within {
    color: var(--skeleton-loader-to);
  }
  .xxl\:focus-within\:text-background50:focus-within {
    color: var(--background50);
  }
  .xxl\:focus-within\:text-reward-blue-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-blue-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-silver-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-silver-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-gold-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-gold-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-platinum-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-platinum-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-vip-start:focus-within {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-reward-vip-end:focus-within {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-fee-breakdown-bg:focus-within {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-light-grey:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-disabled-button-background:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxl\:focus-within\:text-disabled-button-text:focus-within {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-transparent:hover {
    color: transparent;
  }
  .xxl\:hover\:text-current:hover {
    color: currentColor;
  }
  .xxl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-300:hover {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-vivid-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-vivid-blue-500:hover {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:hover\:text-background-primary:hover {
    color: var(--background-primary);
  }
  .xxl\:hover\:text-main-background:hover {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-dark-blue:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-blue-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-text-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pale-grey-two:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-pale-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-btc:hover {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-btc-symbol:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-paxg:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-usdc:hover {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-black-navy:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-ghost-white:hover {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-spring-green:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-light-slate-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-body-background:hover {
    color: var(--body-background);
  }
  .xxl\:hover\:text-background:hover {
    color: var(--background);
  }
  .xxl\:hover\:text-input-background:hover {
    color: var(--input-background);
  }
  .xxl\:hover\:text-body-text:hover {
    color: var(--body-text);
  }
  .xxl\:hover\:text-header-text:hover {
    color: var(--header-text);
  }
  .xxl\:hover\:text-primary:hover {
    color: var(--primary);
  }
  .xxl\:hover\:text-secondary:hover {
    color: var(--secondary);
  }
  .xxl\:hover\:text-tertiary:hover {
    color: var(--tertiary);
  }
  .xxl\:hover\:text-primary-hover:hover {
    color: var(--primary-hover);
  }
  .xxl\:hover\:text-secondary-hover:hover {
    color: var(--secondary-hover);
  }
  .xxl\:hover\:text-tertiary-hover:hover {
    color: var(--tertiary-hover);
  }
  .xxl\:hover\:text-input-text:hover {
    color: var(--input-text);
  }
  .xxl\:hover\:text-success:hover {
    color: var(--success);
  }
  .xxl\:hover\:text-error:hover {
    color: var(--error);
  }
  .xxl\:hover\:text-custom-loader:hover {
    color: var(--custom-loader);
  }
  .xxl\:hover\:text-violet:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-violet-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-skeleton-loader-from:hover {
    color: var(--skeleton-loader-from);
  }
  .xxl\:hover\:text-skeleton-loader-to:hover {
    color: var(--skeleton-loader-to);
  }
  .xxl\:hover\:text-background50:hover {
    color: var(--background50);
  }
  .xxl\:hover\:text-reward-blue-start:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-blue-end:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-silver-start:hover {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-silver-end:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-gold-start:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-gold-end:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-platinum-start:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-platinum-end:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-vip-start:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-reward-vip-end:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-fee-breakdown-bg:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-light-grey:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-disabled-button-background:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxl\:hover\:text-disabled-button-text:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-transparent:focus {
    color: transparent;
  }
  .xxl\:focus\:text-current:focus {
    color: currentColor;
  }
  .xxl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-300:focus {
    color: rgba(98, 107, 255, 0.25098039215686274);
  }
  .xxl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 114, 139, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-vivid-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-vivid-blue-500:focus {
    color: rgba(144, 150, 246, 0.5);
  }
  .xxl\:focus\:text-background-primary:focus {
    color: var(--background-primary);
  }
  .xxl\:focus\:text-main-background:focus {
    --tw-text-opacity: 1;
    color: rgba(18, 23, 41, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-dark-blue:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 65, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-blue-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(47, 58, 92, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-text-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 167, 193, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pale-grey-two:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 235, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-pale-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(168, 175, 204, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-btc:focus {
    --tw-text-opacity: 1;
    color: rgba(247, 147, 26, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-btc-symbol:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 179, 0, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-paxg:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 197, 78, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-usdc:focus {
    --tw-text-opacity: 1;
    color: rgba(39, 117, 202, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-black-navy:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 18, 57, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-ghost-white:focus {
    --tw-text-opacity: 1;
    color: rgba(246, 245, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-spring-green:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 229, 109, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-light-slate-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 139, 148, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-body-background:focus {
    color: var(--body-background);
  }
  .xxl\:focus\:text-background:focus {
    color: var(--background);
  }
  .xxl\:focus\:text-input-background:focus {
    color: var(--input-background);
  }
  .xxl\:focus\:text-body-text:focus {
    color: var(--body-text);
  }
  .xxl\:focus\:text-header-text:focus {
    color: var(--header-text);
  }
  .xxl\:focus\:text-primary:focus {
    color: var(--primary);
  }
  .xxl\:focus\:text-secondary:focus {
    color: var(--secondary);
  }
  .xxl\:focus\:text-tertiary:focus {
    color: var(--tertiary);
  }
  .xxl\:focus\:text-primary-hover:focus {
    color: var(--primary-hover);
  }
  .xxl\:focus\:text-secondary-hover:focus {
    color: var(--secondary-hover);
  }
  .xxl\:focus\:text-tertiary-hover:focus {
    color: var(--tertiary-hover);
  }
  .xxl\:focus\:text-input-text:focus {
    color: var(--input-text);
  }
  .xxl\:focus\:text-success:focus {
    color: var(--success);
  }
  .xxl\:focus\:text-error:focus {
    color: var(--error);
  }
  .xxl\:focus\:text-custom-loader:focus {
    color: var(--custom-loader);
  }
  .xxl\:focus\:text-violet:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-violet-hover:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 11, 142, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-skeleton-loader-from:focus {
    color: var(--skeleton-loader-from);
  }
  .xxl\:focus\:text-skeleton-loader-to:focus {
    color: var(--skeleton-loader-to);
  }
  .xxl\:focus\:text-background50:focus {
    color: var(--background50);
  }
  .xxl\:focus\:text-reward-blue-start:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 107, 255, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-blue-end:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-silver-start:focus {
    --tw-text-opacity: 1;
    color: rgba(72, 72, 72, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-silver-end:focus {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 172, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-gold-start:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 223, 138, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-gold-end:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 37, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-platinum-start:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 149, 149, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-platinum-end:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 248, 248, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-vip-start:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 46, 238, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-reward-vip-end:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 33, 238, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-fee-breakdown-bg:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 33, 57, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-light-grey:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-disabled-button-background:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 42, 152, var(--tw-text-opacity));
  }
  .xxl\:focus\:text-disabled-button-text:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 147, 160, var(--tw-text-opacity));
  }
  .xxl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .xxl\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .xxl\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .xxl\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .xxl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .xxl\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .xxl\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .xxl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .xxl\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .xxl\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .xxl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .xxl\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .xxl\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .xxl\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .xxl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .xxl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .xxl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .xxl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .xxl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .xxl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .xxl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .xxl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .xxl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .xxl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .xxl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .xxl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .xxl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .xxl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .xxl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .xxl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .xxl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .xxl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .xxl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .xxl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .xxl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .xxl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .xxl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .xxl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .xxl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .xxl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .xxl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .xxl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .xxl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .xxl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .xxl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .xxl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .xxl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .xxl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .xxl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .xxl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .xxl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .xxl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .xxl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .xxl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .xxl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .xxl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .xxl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .xxl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .xxl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .xxl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .xxl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .xxl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .xxl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .xxl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .xxl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .xxl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .xxl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .xxl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .xxl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .xxl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .xxl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .xxl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .xxl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .xxl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .xxl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .xxl\:truncate {
    overflow: hidden;
    white-space: nowrap;
  }
  .xxl\:overflow-ellipsis,
  .xxl\:truncate {
    text-overflow: ellipsis;
  }
  .xxl\:overflow-clip {
    text-overflow: clip;
  }
  .group:hover .xxl\:group-hover\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .group:hover .xxl\:group-hover\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .group:hover .xxl\:group-hover\:overflow-clip {
    text-overflow: clip;
  }
  .xxl\:italic {
    font-style: italic;
  }
  .xxl\:not-italic {
    font-style: normal;
  }
  .xxl\:uppercase {
    text-transform: uppercase;
  }
  .xxl\:lowercase {
    text-transform: lowercase;
  }
  .xxl\:capitalize {
    text-transform: capitalize;
  }
  .xxl\:normal-case {
    text-transform: none;
  }
  .xxl\:underline {
    text-decoration: underline;
  }
  .xxl\:line-through {
    text-decoration: line-through;
  }
  .xxl\:no-underline {
    text-decoration: none;
  }
  .group:hover .xxl\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .xxl\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .xxl\:group-hover\:no-underline {
    text-decoration: none;
  }
  .xxl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .xxl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .xxl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .xxl\:hover\:underline:hover {
    text-decoration: underline;
  }
  .xxl\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .xxl\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .xxl\:focus\:underline:focus {
    text-decoration: underline;
  }
  .xxl\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .xxl\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .xxl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .xxl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .xxl\:diagonal-fractions,
  .xxl\:lining-nums,
  .xxl\:oldstyle-nums,
  .xxl\:ordinal,
  .xxl\:proportional-nums,
  .xxl\:slashed-zero,
  .xxl\:stacked-fractions,
  .xxl\:tabular-nums {
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .xxl\:normal-nums {
    font-feature-settings: normal;
    font-variant-numeric: normal;
  }
  .xxl\:ordinal {
    --tw-ordinal: ordinal;
  }
  .xxl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .xxl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .xxl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .xxl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .xxl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .xxl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .xxl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .xxl\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .xxl\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .xxl\:tracking-normal {
    letter-spacing: 0;
  }
  .xxl\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .xxl\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .xxl\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .xxl\:select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .xxl\:select-text {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .xxl\:select-all {
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .xxl\:select-auto {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .xxl\:align-baseline {
    vertical-align: baseline;
  }
  .xxl\:align-top {
    vertical-align: top;
  }
  .xxl\:align-middle {
    vertical-align: middle;
  }
  .xxl\:align-bottom {
    vertical-align: bottom;
  }
  .xxl\:align-text-top {
    vertical-align: text-top;
  }
  .xxl\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .xxl\:visible {
    visibility: visible;
  }
  .xxl\:invisible {
    visibility: hidden;
  }
  .xxl\:whitespace-normal {
    white-space: normal;
  }
  .xxl\:whitespace-nowrap {
    white-space: nowrap;
  }
  .xxl\:whitespace-pre {
    white-space: pre;
  }
  .xxl\:whitespace-pre-line {
    white-space: pre-line;
  }
  .xxl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .xxl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .xxl\:break-words {
    overflow-wrap: break-word;
  }
  .xxl\:break-all {
    word-break: break-all;
  }
  .group:hover .xxl\:group-hover\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .group:hover .xxl\:group-hover\:break-words {
    overflow-wrap: break-word;
  }
  .group:hover .xxl\:group-hover\:break-all {
    word-break: break-all;
  }
  .xxl\:w-0 {
    width: 0;
  }
  .xxl\:w-1 {
    width: 0.25rem;
  }
  .xxl\:w-2 {
    width: 0.5rem;
  }
  .xxl\:w-3 {
    width: 0.75rem;
  }
  .xxl\:w-4 {
    width: 1rem;
  }
  .xxl\:w-5 {
    width: 1.25rem;
  }
  .xxl\:w-6 {
    width: 1.5rem;
  }
  .xxl\:w-7 {
    width: 1.75rem;
  }
  .xxl\:w-8 {
    width: 2rem;
  }
  .xxl\:w-9 {
    width: 2.25rem;
  }
  .xxl\:w-10 {
    width: 2.5rem;
  }
  .xxl\:w-11 {
    width: 2.75rem;
  }
  .xxl\:w-12 {
    width: 3rem;
  }
  .xxl\:w-14 {
    width: 3.5rem;
  }
  .xxl\:w-16 {
    width: 4rem;
  }
  .xxl\:w-20 {
    width: 5rem;
  }
  .xxl\:w-24 {
    width: 6rem;
  }
  .xxl\:w-28 {
    width: 7rem;
  }
  .xxl\:w-32 {
    width: 8rem;
  }
  .xxl\:w-36 {
    width: 9rem;
  }
  .xxl\:w-40 {
    width: 10rem;
  }
  .xxl\:w-44 {
    width: 11rem;
  }
  .xxl\:w-48 {
    width: 12rem;
  }
  .xxl\:w-52 {
    width: 13rem;
  }
  .xxl\:w-56 {
    width: 14rem;
  }
  .xxl\:w-60 {
    width: 15rem;
  }
  .xxl\:w-64 {
    width: 16rem;
  }
  .xxl\:w-72 {
    width: 18rem;
  }
  .xxl\:w-80 {
    width: 20rem;
  }
  .xxl\:w-96 {
    width: 24rem;
  }
  .xxl\:w-150 {
    width: 150px;
  }
  .xxl\:w-199 {
    width: 199px;
  }
  .xxl\:w-286 {
    width: 286px;
  }
  .xxl\:w-512 {
    width: 512px;
  }
  .xxl\:w-560 {
    width: 560px;
  }
  .xxl\:w-650 {
    width: 650px;
  }
  .xxl\:w-auto {
    width: auto;
  }
  .xxl\:w-px {
    width: 1px;
  }
  .xxl\:w-0\.5 {
    width: 0.125rem;
  }
  .xxl\:w-1\.5 {
    width: 0.375rem;
  }
  .xxl\:w-2\.5 {
    width: 0.625rem;
  }
  .xxl\:w-3\.5 {
    width: 0.875rem;
  }
  .xxl\:w-1\/2 {
    width: 50%;
  }
  .xxl\:w-1\/3 {
    width: 33.333333%;
  }
  .xxl\:w-2\/3 {
    width: 66.666667%;
  }
  .xxl\:w-1\/4 {
    width: 25%;
  }
  .xxl\:w-2\/4 {
    width: 50%;
  }
  .xxl\:w-3\/4 {
    width: 75%;
  }
  .xxl\:w-1\/5 {
    width: 20%;
  }
  .xxl\:w-2\/5 {
    width: 40%;
  }
  .xxl\:w-3\/5 {
    width: 60%;
  }
  .xxl\:w-4\/5 {
    width: 80%;
  }
  .xxl\:w-1\/6 {
    width: 16.666667%;
  }
  .xxl\:w-2\/6 {
    width: 33.333333%;
  }
  .xxl\:w-3\/6 {
    width: 50%;
  }
  .xxl\:w-4\/6 {
    width: 66.666667%;
  }
  .xxl\:w-5\/6 {
    width: 83.333333%;
  }
  .xxl\:w-1\/12 {
    width: 8.333333%;
  }
  .xxl\:w-2\/12 {
    width: 16.666667%;
  }
  .xxl\:w-3\/12 {
    width: 25%;
  }
  .xxl\:w-4\/12 {
    width: 33.333333%;
  }
  .xxl\:w-5\/12 {
    width: 41.666667%;
  }
  .xxl\:w-6\/12 {
    width: 50%;
  }
  .xxl\:w-7\/12 {
    width: 58.333333%;
  }
  .xxl\:w-8\/12 {
    width: 66.666667%;
  }
  .xxl\:w-9\/12 {
    width: 75%;
  }
  .xxl\:w-10\/12 {
    width: 83.333333%;
  }
  .xxl\:w-11\/12 {
    width: 91.666667%;
  }
  .xxl\:w-full {
    width: 100%;
  }
  .xxl\:w-screen {
    width: 100vw;
  }
  .xxl\:w-min {
    width: -webkit-min-content;
    width: min-content;
  }
  .xxl\:w-max {
    width: -webkit-max-content;
    width: max-content;
  }
  .xxl\:w-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .xxl\:z-0 {
    z-index: 0;
  }
  .xxl\:z-10 {
    z-index: 10;
  }
  .xxl\:z-20 {
    z-index: 20;
  }
  .xxl\:z-30 {
    z-index: 30;
  }
  .xxl\:z-40 {
    z-index: 40;
  }
  .xxl\:z-50 {
    z-index: 50;
  }
  .xxl\:z-auto {
    z-index: auto;
  }
  .xxl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .xxl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .xxl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .xxl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .xxl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .xxl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .xxl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .xxl\:focus\:z-0:focus {
    z-index: 0;
  }
  .xxl\:focus\:z-10:focus {
    z-index: 10;
  }
  .xxl\:focus\:z-20:focus {
    z-index: 20;
  }
  .xxl\:focus\:z-30:focus {
    z-index: 30;
  }
  .xxl\:focus\:z-40:focus {
    z-index: 40;
  }
  .xxl\:focus\:z-50:focus {
    z-index: 50;
  }
  .xxl\:focus\:z-auto:focus {
    z-index: auto;
  }
  .xxl\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }
  .xxl\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .xxl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .xxl\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .xxl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .xxl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .xxl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .xxl\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
  .xxl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .xxl\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }
  .xxl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .xxl\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }
  .xxl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .xxl\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
  .xxl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .xxl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .xxl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .xxl\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }
  .xxl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }
  .xxl\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }
  .xxl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .xxl\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }
  .xxl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }
  .xxl\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }
  .xxl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }
  .xxl\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }
  .xxl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }
  .xxl\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }
  .xxl\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }
  .xxl\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }
  .xxl\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }
  .xxl\:gap-0\.5 {
    grid-gap: 0.125rem;
    gap: 0.125rem;
  }
  .xxl\:gap-1\.5 {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }
  .xxl\:gap-2\.5 {
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  .xxl\:gap-3\.5 {
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }
  .xxl\:gap-x-0 {
    -webkit-column-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .xxl\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }
  .xxl\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .xxl\:gap-x-3 {
    -webkit-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
  .xxl\:gap-x-4 {
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .xxl\:gap-x-5 {
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .xxl\:gap-x-6 {
    -webkit-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .xxl\:gap-x-7 {
    -webkit-column-gap: 1.75rem;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }
  .xxl\:gap-x-8 {
    -webkit-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
  .xxl\:gap-x-9 {
    -webkit-column-gap: 2.25rem;
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }
  .xxl\:gap-x-10 {
    -webkit-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .xxl\:gap-x-11 {
    -webkit-column-gap: 2.75rem;
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }
  .xxl\:gap-x-12 {
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }
  .xxl\:gap-x-14 {
    -webkit-column-gap: 3.5rem;
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }
  .xxl\:gap-x-16 {
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }
  .xxl\:gap-x-20 {
    -webkit-column-gap: 5rem;
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }
  .xxl\:gap-x-24 {
    -webkit-column-gap: 6rem;
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }
  .xxl\:gap-x-28 {
    -webkit-column-gap: 7rem;
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }
  .xxl\:gap-x-32 {
    -webkit-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }
  .xxl\:gap-x-36 {
    -webkit-column-gap: 9rem;
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }
  .xxl\:gap-x-40 {
    -webkit-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }
  .xxl\:gap-x-44 {
    -webkit-column-gap: 11rem;
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }
  .xxl\:gap-x-48 {
    -webkit-column-gap: 12rem;
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }
  .xxl\:gap-x-52 {
    -webkit-column-gap: 13rem;
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }
  .xxl\:gap-x-56 {
    -webkit-column-gap: 14rem;
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }
  .xxl\:gap-x-60 {
    -webkit-column-gap: 15rem;
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }
  .xxl\:gap-x-64 {
    -webkit-column-gap: 16rem;
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }
  .xxl\:gap-x-72 {
    -webkit-column-gap: 18rem;
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }
  .xxl\:gap-x-80 {
    -webkit-column-gap: 20rem;
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }
  .xxl\:gap-x-96 {
    -webkit-column-gap: 24rem;
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }
  .xxl\:gap-x-px {
    -webkit-column-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }
  .xxl\:gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
    grid-column-gap: 0.125rem;
    column-gap: 0.125rem;
  }
  .xxl\:gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
    grid-column-gap: 0.375rem;
    column-gap: 0.375rem;
  }
  .xxl\:gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
    grid-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
  .xxl\:gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
    grid-column-gap: 0.875rem;
    column-gap: 0.875rem;
  }
  .xxl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }
  .xxl\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }
  .xxl\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .xxl\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
  .xxl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .xxl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }
  .xxl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }
  .xxl\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }
  .xxl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
  .xxl\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }
  .xxl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .xxl\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }
  .xxl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .xxl\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }
  .xxl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }
  .xxl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .xxl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }
  .xxl\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }
  .xxl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }
  .xxl\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }
  .xxl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }
  .xxl\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }
  .xxl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }
  .xxl\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }
  .xxl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }
  .xxl\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }
  .xxl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }
  .xxl\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }
  .xxl\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }
  .xxl\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }
  .xxl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }
  .xxl\:gap-y-0\.5 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }
  .xxl\:gap-y-1\.5 {
    grid-row-gap: 0.375rem;
    row-gap: 0.375rem;
  }
  .xxl\:gap-y-2\.5 {
    grid-row-gap: 0.625rem;
    row-gap: 0.625rem;
  }
  .xxl\:gap-y-3\.5 {
    grid-row-gap: 0.875rem;
    row-gap: 0.875rem;
  }
  .xxl\:grid-flow-row {
    grid-auto-flow: row;
  }
  .xxl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xxl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .xxl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .xxl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xxl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xxl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xxl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xxl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xxl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xxl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xxl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xxl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xxl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xxl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xxl\:grid-cols-none {
    grid-template-columns: none;
  }
  .xxl\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .xxl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }
  .xxl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }
  .xxl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .xxl\:col-auto {
    grid-column: auto;
  }
  .xxl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xxl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xxl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xxl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xxl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xxl\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .xxl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xxl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .xxl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .xxl\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .xxl\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .xxl\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .xxl\:col-span-full {
    grid-column: 1/-1;
  }
  .xxl\:col-start-1 {
    grid-column-start: 1;
  }
  .xxl\:col-start-2 {
    grid-column-start: 2;
  }
  .xxl\:col-start-3 {
    grid-column-start: 3;
  }
  .xxl\:col-start-4 {
    grid-column-start: 4;
  }
  .xxl\:col-start-5 {
    grid-column-start: 5;
  }
  .xxl\:col-start-6 {
    grid-column-start: 6;
  }
  .xxl\:col-start-7 {
    grid-column-start: 7;
  }
  .xxl\:col-start-8 {
    grid-column-start: 8;
  }
  .xxl\:col-start-9 {
    grid-column-start: 9;
  }
  .xxl\:col-start-10 {
    grid-column-start: 10;
  }
  .xxl\:col-start-11 {
    grid-column-start: 11;
  }
  .xxl\:col-start-12 {
    grid-column-start: 12;
  }
  .xxl\:col-start-13 {
    grid-column-start: 13;
  }
  .xxl\:col-start-auto {
    grid-column-start: auto;
  }
  .xxl\:col-end-1 {
    grid-column-end: 1;
  }
  .xxl\:col-end-2 {
    grid-column-end: 2;
  }
  .xxl\:col-end-3 {
    grid-column-end: 3;
  }
  .xxl\:col-end-4 {
    grid-column-end: 4;
  }
  .xxl\:col-end-5 {
    grid-column-end: 5;
  }
  .xxl\:col-end-6 {
    grid-column-end: 6;
  }
  .xxl\:col-end-7 {
    grid-column-end: 7;
  }
  .xxl\:col-end-8 {
    grid-column-end: 8;
  }
  .xxl\:col-end-9 {
    grid-column-end: 9;
  }
  .xxl\:col-end-10 {
    grid-column-end: 10;
  }
  .xxl\:col-end-11 {
    grid-column-end: 11;
  }
  .xxl\:col-end-12 {
    grid-column-end: 12;
  }
  .xxl\:col-end-13 {
    grid-column-end: 13;
  }
  .xxl\:col-end-auto {
    grid-column-end: auto;
  }
  .xxl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .xxl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xxl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xxl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xxl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xxl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xxl\:grid-rows-none {
    grid-template-rows: none;
  }
  .xxl\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .xxl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }
  .xxl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }
  .xxl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .xxl\:row-auto {
    grid-row: auto;
  }
  .xxl\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xxl\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xxl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xxl\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .xxl\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .xxl\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .xxl\:row-span-full {
    grid-row: 1/-1;
  }
  .xxl\:row-start-1 {
    grid-row-start: 1;
  }
  .xxl\:row-start-2 {
    grid-row-start: 2;
  }
  .xxl\:row-start-3 {
    grid-row-start: 3;
  }
  .xxl\:row-start-4 {
    grid-row-start: 4;
  }
  .xxl\:row-start-5 {
    grid-row-start: 5;
  }
  .xxl\:row-start-6 {
    grid-row-start: 6;
  }
  .xxl\:row-start-7 {
    grid-row-start: 7;
  }
  .xxl\:row-start-auto {
    grid-row-start: auto;
  }
  .xxl\:row-end-1 {
    grid-row-end: 1;
  }
  .xxl\:row-end-2 {
    grid-row-end: 2;
  }
  .xxl\:row-end-3 {
    grid-row-end: 3;
  }
  .xxl\:row-end-4 {
    grid-row-end: 4;
  }
  .xxl\:row-end-5 {
    grid-row-end: 5;
  }
  .xxl\:row-end-6 {
    grid-row-end: 6;
  }
  .xxl\:row-end-7 {
    grid-row-end: 7;
  }
  .xxl\:row-end-auto {
    grid-row-end: auto;
  }
  .xxl\:transform {
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xxl\:transform,
  .xxl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxl\:transform-gpu {
    -webkit-transform: translate3d(
        var(--tw-translate-x),
        var(--tw-translate-y),
        0
      )
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xxl\:transform-none {
    -webkit-transform: none;
    transform: none;
  }
  .xxl\:origin-center {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  .xxl\:origin-top {
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .xxl\:origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .xxl\:origin-right {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
  .xxl\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .xxl\:origin-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  .xxl\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  .xxl\:origin-left {
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .xxl\:origin-top-left {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .xxl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxl\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxl\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxl\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxl\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxl\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .xxl\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .xxl\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .xxl\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .xxl\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .xxl\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .xxl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .xxl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .xxl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .xxl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .xxl\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .xxl\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .xxl\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .xxl\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .xxl\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .xxl\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .xxl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .xxl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .xxl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .xxl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .xxl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxl\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxl\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxl\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxl\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .xxl\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .xxl\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .xxl\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .xxl\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .xxl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .xxl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .xxl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .xxl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .xxl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .xxl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .xxl\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .xxl\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .xxl\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .xxl\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .xxl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .xxl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .xxl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .xxl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .xxl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .xxl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xxl\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xxl\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xxl\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xxl\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xxl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xxl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xxl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xxl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xxl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xxl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .xxl\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .xxl\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .xxl\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .xxl\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .xxl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .xxl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .xxl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .xxl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .xxl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .xxl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .xxl\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .xxl\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .xxl\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .xxl\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .xxl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .xxl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .xxl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .xxl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .xxl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .xxl\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .xxl\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .xxl\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .xxl\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .xxl\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .xxl\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .xxl\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .xxl\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .xxl\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .xxl\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .xxl\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .xxl\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .xxl\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .xxl\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .xxl\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .xxl\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .xxl\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .xxl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .xxl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .xxl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .xxl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .xxl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .xxl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .xxl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .xxl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .xxl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .xxl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .xxl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .xxl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .xxl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .xxl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .xxl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .xxl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .xxl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .xxl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .xxl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .xxl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .xxl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .xxl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .xxl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .xxl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .xxl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .xxl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .xxl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .xxl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .xxl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .xxl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .xxl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .xxl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .xxl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .xxl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .xxl\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xxl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .xxl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .xxl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .xxl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .xxl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .xxl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .xxl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .xxl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .xxl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .xxl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .xxl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .xxl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .xxl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .xxl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .xxl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .xxl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .xxl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .xxl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .xxl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .xxl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .xxl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .xxl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .xxl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .xxl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .xxl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .xxl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .xxl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .xxl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .xxl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .xxl\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .xxl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .xxl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .xxl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .xxl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .xxl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xxl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .xxl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .xxl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .xxl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .xxl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .xxl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .xxl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .xxl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .xxl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .xxl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .xxl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .xxl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .xxl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .xxl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .xxl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .xxl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .xxl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .xxl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .xxl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .xxl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .xxl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .xxl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .xxl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .xxl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .xxl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .xxl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .xxl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .xxl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .xxl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .xxl\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .xxl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .xxl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .xxl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .xxl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .xxl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .xxl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .xxl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .xxl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .xxl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .xxl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .xxl\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .xxl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .xxl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .xxl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .xxl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .xxl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .xxl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .xxl\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .xxl\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xxl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .xxl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .xxl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .xxl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .xxl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .xxl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .xxl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .xxl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .xxl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .xxl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .xxl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .xxl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .xxl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .xxl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .xxl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .xxl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .xxl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .xxl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .xxl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .xxl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .xxl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .xxl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .xxl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .xxl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .xxl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .xxl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .xxl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .xxl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .xxl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .xxl\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .xxl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .xxl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .xxl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .xxl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .xxl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xxl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .xxl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .xxl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .xxl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .xxl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .xxl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .xxl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .xxl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .xxl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .xxl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .xxl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .xxl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .xxl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .xxl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .xxl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .xxl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .xxl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .xxl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .xxl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .xxl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .xxl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .xxl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .xxl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .xxl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .xxl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .xxl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .xxl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .xxl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .xxl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .xxl\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .xxl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .xxl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .xxl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .xxl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .xxl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .xxl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .xxl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .xxl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .xxl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .xxl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .xxl\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .xxl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .xxl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .xxl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .xxl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .xxl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .xxl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .xxl\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .xxl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xxl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .xxl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .xxl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .xxl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .xxl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .xxl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .xxl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .xxl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .xxl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .xxl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .xxl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .xxl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .xxl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .xxl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .xxl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .xxl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .xxl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .xxl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .xxl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .xxl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .xxl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .xxl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .xxl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .xxl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .xxl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .xxl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .xxl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .xxl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .xxl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .xxl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .xxl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .xxl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .xxl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .xxl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .xxl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xxl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .xxl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .xxl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .xxl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .xxl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .xxl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .xxl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .xxl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .xxl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .xxl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .xxl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .xxl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .xxl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .xxl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .xxl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .xxl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .xxl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .xxl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .xxl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .xxl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .xxl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .xxl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .xxl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .xxl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .xxl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .xxl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .xxl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .xxl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .xxl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .xxl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .xxl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .xxl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .xxl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .xxl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .xxl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .xxl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .xxl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .xxl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .xxl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .xxl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .xxl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .xxl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .xxl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .xxl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .xxl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .xxl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .xxl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .xxl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .xxl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xxl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .xxl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .xxl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .xxl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .xxl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .xxl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .xxl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .xxl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .xxl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .xxl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .xxl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .xxl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .xxl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .xxl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .xxl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .xxl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .xxl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .xxl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .xxl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .xxl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .xxl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .xxl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .xxl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .xxl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .xxl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .xxl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .xxl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .xxl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .xxl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .xxl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .xxl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .xxl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .xxl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .xxl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .xxl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xxl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .xxl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .xxl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .xxl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .xxl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .xxl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .xxl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .xxl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .xxl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .xxl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .xxl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .xxl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .xxl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .xxl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .xxl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .xxl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .xxl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .xxl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .xxl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .xxl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .xxl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .xxl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .xxl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .xxl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .xxl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .xxl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .xxl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .xxl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .xxl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .xxl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .xxl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .xxl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .xxl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .xxl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .xxl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .xxl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .xxl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .xxl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .xxl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .xxl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .xxl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .xxl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .xxl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .xxl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .xxl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .xxl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .xxl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .xxl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .xxl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xxl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .xxl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .xxl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .xxl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .xxl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .xxl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .xxl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .xxl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .xxl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .xxl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .xxl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .xxl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .xxl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .xxl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .xxl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .xxl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .xxl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .xxl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .xxl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .xxl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .xxl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .xxl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .xxl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .xxl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .xxl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .xxl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .xxl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .xxl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .xxl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .xxl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .xxl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .xxl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .xxl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .xxl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .xxl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xxl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .xxl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .xxl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .xxl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .xxl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .xxl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .xxl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .xxl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .xxl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .xxl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .xxl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .xxl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .xxl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .xxl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .xxl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .xxl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .xxl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .xxl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .xxl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .xxl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .xxl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .xxl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .xxl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .xxl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .xxl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .xxl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .xxl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .xxl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .xxl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .xxl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .xxl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .xxl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .xxl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .xxl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .xxl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .xxl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .xxl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .xxl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .xxl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .xxl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .xxl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .xxl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .xxl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .xxl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .xxl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .xxl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .xxl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .xxl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .xxl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xxl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .xxl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .xxl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .xxl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .xxl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .xxl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .xxl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .xxl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .xxl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .xxl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .xxl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .xxl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .xxl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .xxl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .xxl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .xxl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .xxl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .xxl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .xxl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .xxl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .xxl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .xxl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .xxl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .xxl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .xxl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .xxl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .xxl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .xxl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .xxl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .xxl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .xxl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .xxl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .xxl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .xxl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .xxl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xxl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .xxl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .xxl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .xxl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .xxl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .xxl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .xxl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .xxl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .xxl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .xxl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .xxl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .xxl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .xxl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .xxl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .xxl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .xxl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .xxl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .xxl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .xxl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .xxl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .xxl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .xxl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .xxl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .xxl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .xxl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .xxl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .xxl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .xxl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .xxl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .xxl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .xxl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .xxl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .xxl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .xxl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .xxl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .xxl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .xxl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .xxl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .xxl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .xxl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .xxl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .xxl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .xxl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .xxl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .xxl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .xxl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .xxl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .xxl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .xxl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .xxl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .xxl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .xxl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .xxl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .xxl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .xxl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .xxl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .xxl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .xxl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .xxl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .xxl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .xxl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .xxl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .xxl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .xxl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .xxl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .xxl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .xxl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .xxl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .xxl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .xxl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .xxl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .xxl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .xxl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .xxl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .xxl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .xxl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .xxl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .xxl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .xxl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .xxl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .xxl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .xxl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .xxl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .xxl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .xxl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .xxl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .xxl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .xxl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .xxl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .xxl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .xxl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .xxl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .xxl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .xxl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .xxl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .xxl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .xxl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .xxl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .xxl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .xxl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .xxl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .xxl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .xxl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .xxl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .xxl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .xxl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .xxl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .xxl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .xxl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .xxl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .xxl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .xxl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .xxl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .xxl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .xxl\:transition-none {
    transition-property: none;
  }
  .xxl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .xxl\:ease-linear {
    transition-timing-function: linear;
  }
  .xxl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .xxl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .xxl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .xxl\:duration-75 {
    transition-duration: 75ms;
  }
  .xxl\:duration-100 {
    transition-duration: 0.1s;
  }
  .xxl\:duration-150 {
    transition-duration: 0.15s;
  }
  .xxl\:duration-200 {
    transition-duration: 0.2s;
  }
  .xxl\:duration-300 {
    transition-duration: 0.3s;
  }
  .xxl\:duration-500 {
    transition-duration: 0.5s;
  }
  .xxl\:duration-700 {
    transition-duration: 0.7s;
  }
  .xxl\:duration-1000 {
    transition-duration: 1s;
  }
  .xxl\:delay-75 {
    transition-delay: 75ms;
  }
  .xxl\:delay-100 {
    transition-delay: 0.1s;
  }
  .xxl\:delay-150 {
    transition-delay: 0.15s;
  }
  .xxl\:delay-200 {
    transition-delay: 0.2s;
  }
  .xxl\:delay-300 {
    transition-delay: 0.3s;
  }
  .xxl\:delay-500 {
    transition-delay: 0.5s;
  }
  .xxl\:delay-700 {
    transition-delay: 0.7s;
  }
  .xxl\:delay-1000 {
    transition-delay: 1s;
  }
  .xxl\:animate-none {
    -webkit-animation: none;
    animation: none;
  }
  .xxl\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .xxl\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .xxl\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .xxl\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
  }
}
.custom-loader {
  -webkit-animation: rotate 2s linear infinite normal;
  animation: rotate 2s linear infinite normal;
}
.custom-loader--ball {
  -webkit-animation: move1 1s ease-in-out infinite alternate;
  animation: move1 1s ease-in-out infinite alternate;
}
.custom-loader--ball:nth-of-type(2) {
  -webkit-animation-name: move2;
  animation-name: move2;
}
.custom-loader--ball:nth-of-type(3) {
  -webkit-animation-name: move3;
  animation-name: move3;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes move1 {
  0%,
  5% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes move1 {
  0%,
  5% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-webkit-keyframes move2 {
  0%,
  5% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
}
@keyframes move2 {
  0%,
  5% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
}
@-webkit-keyframes move3 {
  0%,
  15% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
}
@keyframes move3 {
  0%,
  15% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  to {
    -webkit-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
}
[data-tour-elem='navigation'] button[disabled] {
  opacity: 1;
  background: #fff;
}
[data-tour-elem='navigation'] button {
  border: none;
  background: #fff;
}
[data-tour-elem='controls'] {
  position: absolute;
  top: 0;
}
.reactour__dot {
  background-color: #fff;
  opacity: 0.5;
}
.mobile-tour-mask {
  background-color: #fff;
  opacity: 0;
}

.tippy-box[data-placement^='top'] > .tippy-svg-arrow {
  bottom: 0;
}
.tippy-box[data-placement^='top'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='top'] > .tippy-svg-arrow > svg {
  top: 16px;
  transform: rotate(180deg);
}
.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow {
  top: 0;
}
.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg {
  bottom: 16px;
}
.tippy-box[data-placement^='left'] > .tippy-svg-arrow {
  right: 0;
}
.tippy-box[data-placement^='left'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='left'] > .tippy-svg-arrow > svg {
  transform: rotate(90deg);
  top: calc(50% - 3px);
  left: 11px;
}
.tippy-box[data-placement^='right'] > .tippy-svg-arrow {
  left: 0;
}
.tippy-box[data-placement^='right'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='right'] > .tippy-svg-arrow > svg {
  transform: rotate(-90deg);
  top: calc(50% - 3px);
  right: 11px;
}
.tippy-svg-arrow {
  width: 16px;
  height: 16px;
  fill: #333;
  text-align: left;
  text-align: initial;
}
.tippy-svg-arrow,
.tippy-svg-arrow > svg {
  position: absolute;
}
.tippy-box[data-animation='shift-away'][data-state='hidden'] {
  opacity: 0;
}
.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='top'] {
  transform: translateY(10px);
}
.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='bottom'] {
  transform: translateY(-10px);
}
.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='left'] {
  transform: translateX(10px);
}
.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='right'] {
  transform: translateX(-10px);
}


/* input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px transparent !important;
    -webkit-text-fill-color: transparent !important;
    filter: none;
} */
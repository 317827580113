.mainImage {
  position: fixed;
  min-height: 100%;
  min-width: 1024px;
  background-color: #fff;
  width: 100%;
  height: auto;
  top: 0;
  object-fit: cover;
  left: 0;
  overflow-x: hidden;
  z-index: 50;
}
.fadeImg{
  position: fixed;
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  top: 0;
  object-fit: cover;
  left: 0;
  background-color: #aaaaaa;
  overflow-x: hidden;
  z-index: 50;   
}

.mainframe {
  z-index: 52;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.jumperNav {
  width: 100%;
  background-color: #d71e28;
  height: 51.32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0px 18px;
  align-items: center;
}
.normalBrandSide {
  width: 201px;
  height: 20px;
}
.EnrollLock {
  fill: #fff;
  width: 12px;
  height: 18px;
}
.normalLinkSide {
  display: flex;
  height: 100%;
  align-items: center;
}

.normalLinkSide li {
  list-style: none;
  padding-left: 0.5rem;
  box-sizing: border-box;
  margin-left: 6px;
  cursor: pointer;
}

.normalLinkSide li small {
  color: #fff;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-size: 0.8125rem;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
}

.topRightForMSearch {
  width: max-content;
  position: relative;
}

.topRightInPUTSearch {
  border-radius: 8px;
  border: none;
  width: 53px;
  height: 36px;
  padding: 0 36px 0 8px;
  font-size: 0.6875rem;
}

.topRightbtnSearch {
  border: none;
  outline: none;
  position: absolute;
  top: 7px;
  right: 5px;
  background-color: inherit;
}

.topRightbtnSearch svg {
  width: 20px;
  height: 20px;
}

.heroSideLoginSection {
  margin: 56px auto 190px auto; 
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgb(59 51 49 / 15%);
  width: 83%;
  max-width: 92%;
  width: 870px;
  background-color: #fff;
  font-size: 15px;
  position: relative;
  z-index: 50;
}

.navSection{
  display: flex;
  justify-content: space-between;
  width: 93%;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 0px 0px 17px;
  margin: auto;
}
.navSection h2{
    margin: 20px 14px 0px 0px;
    color: #121729;

}

.imageSection{
  margin-top: 50px;
    width: 200px;
}
.forgotDetails{
    color: #00f;
    font-size: 13px;
    font-weight: bold;
}
.salutationTitle {
  margin-top: 26px;
  font-size: 24px;
  color: #3b3331;
  text-align: center;
  font-weight: 400;
  line-height: 3.25rem;
}

.FloatingPage {
  margin: 2px 0 16px;
  font-size: 15px;
  display: block;
  line-height: normal;
  font-family: Wells Fargo Sans, Verdana, Arial, Helvetica, sans-serif;
  color: #3b3331;
}

.mainInputCover {
  width: 428px;
  max-width: 85%;
  margin: 0 auto;
}

.eachMainInputCover {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 63.13px;
  flex: 1 1 auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-sizing: border-box;
}


.onlyBorderInput {
border: none;
border-bottom: 2px solid #e5e7eb;
font-family: Bozon, sans-serif;

font-size: 15px;

line-height: 24px;
background-color: transparent;
color: rgb(59, 51, 49);
height: 2px;
width: 100%;
box-sizing: border-box;
position: relative;
flex: 1 1 auto;
display: inline-block;
background: transparent;
outline: none;
font-size: 18px;
line-height: 23px;
padding: 34px 8px 9px 5px;
}

.onlyBorderLabel {
transition: all 0.2s ease 0s;
display: flex;
border: none;
width: 100%;
flex-flow: row nowrap;
align-items: center;
position: relative;
color: #787070;
font-size: 1.125rem;
font-weight: 400;
line-height: 1.4375rem;
background: transparent;
pointer-events: none;
margin: 32px 0 -32px 8px;
}

.warningCover{
  margin-top: 34px;
  line-height: 18px;
  font-size: 14px;
  background-color: #fff;
  color: #3b3331;
  border: 2px solid #9c9191;
  padding: 16px 16px 16px 12px;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 400;
  display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    align-items: stretch;
    vertical-align: baseline;
    background: transparent;
  max-width: 85%;
}

.ErrorMessageText{
  width: 351px;
  max-width: 100%;
  margin-left: 10px;
  font-size: 13px;
}

.passSVG{
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.saveCheckBox{
  margin-top: 24px;
}

.saveCheckBox label{
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  color: #3b3331;
  line-height: 23px;
}

.signOnBTN{
    width: 100%;
    margin: auto;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fc0;
    color: #000;
    padding: .7em 1.7em;
    border: none;
    outline: none;
    margin-top: 20px;
    border-radius: 0.5rem;
    font-weight: bold;
}

.signOnBTN:disabled{
  background-color: #e3dede;
  color: #999;
}
.navCover{
    margin-top: 1.25rem;
    flex: 1 1;
    background-color: var(--background);
}
.navLinkCover{
    transition-duration: 0.15s; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    width: 100%;
    color: var(--body-text);
    padding-left: 2rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    align-items: center;
    display: flex;
    text-decoration: inherit;
}

.navLinkCover[aria-current="page"]{
    background-color: rgb(255, 204, 0);
    border-right-width: 2px;
    color: #fff;
    border-color: var(--header-text);
}

.navLinkCover svg{
    fill: #575F9B;
}

.navLinkCover[aria-current="page"] svg{
    fill: #fff;
}
.eachMainInputCover {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 63.13px;
  flex: 1 1 auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-sizing: border-box;
}

.onlyBorderInput {
  border: none;
  border-bottom: 2px solid #e5e7eb;
  font-family: Bozon, sans-serif;

  font-size: 15px;

  line-height: 24px;
  background-color: transparent;
  height: 2px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  background: transparent;
  outline: none;
  font-size: 18px;
  line-height: 23px;
  padding: 34px 8px 9px 5px;
}

.onlyBorderLabel {
  transition: all 0.2s ease 0s;
  display: flex;
  border: none;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  color: #787070;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4375rem;
  background: transparent;
  pointer-events: none;
  margin: 32px 0 -32px 8px;
}
.sideTextHere{
  position: absolute;
  left: 18px;
  color: inherit;
  bottom: 2px;
}

.warningCover {
  margin-top: 34px;
  line-height: 18px;
  font-size: 14px;
  background-color: #fff;
  color: #3b3331;
  border: 2px solid #9c9191;
  padding: 16px 16px 16px 12px;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: stretch;
  vertical-align: baseline;
  background: transparent;
  max-width: 85%;
}

.ErrorMessageText {
  width: 351px;
  max-width: 100%;
  margin-left: 10px;
  font-size: 13px;
}

.passSVG {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.saveCheckBox {
  margin-top: 24px;
}

.saveCheckBox label {
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  color: #3b3331;
  line-height: 23px;
}

.hello {
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  background-color: #1e274199;
  width: 58px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.hello:hover {
  background-color: #3c4560;
}
.hello:hover * {
  fill: #fff !important;
  color: #fff;
}

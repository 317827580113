.navLinkCover {
  display: block;
}

.navLinkCover:hover * {
  color: #fff;
}

.navLinkCover[aria-current='page'] * {
  color: #fff;
}

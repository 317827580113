.signOnBTN{
    width: 100%;
    margin: auto;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fc0;
    color: #000;
    padding: .7em 1.7em;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    margin-top: 20px;
    font-weight: bold;
}

.signOnBTN:disabled{
  /* background-color: #e3dede; */
    /* background-color: #ffcc0098; */
    background-color: #dacb59;
  color: #777;
  /* color: #ccc; */
}